var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "dashed-frame",
          rawName: "v-dashed-frame",
          value: _vm.moduleEditActive,
          expression: "moduleEditActive",
        },
        {
          name: "right-click",
          rawName: "v-right-click",
          value: _vm.rightClickMenu,
          expression: "rightClickMenu",
        },
        {
          name: "animate-obsever",
          rawName: "v-animate-obsever",
          value: _vm.animationData,
          expression: "animationData",
        },
      ],
      ref: "moduleFrame",
      class: _vm.moduleCls,
      style: _vm.moduleStyleComp,
      attrs: {
        id: "module" + _vm.id,
        _moduleStyle: _vm.styleId,
        _autoHeight: _vm.autoHeightComp,
        _height: _vm.module.pattern.h || 0,
        _autoWidth: _vm.autoWidthComp,
        _width: _vm.module.pattern.w || 0,
        _inpack: _vm.inpackComp,
        _inPopupZone: _vm.module.inPopup || 0,
        _inTab: _vm.module.inTab || 0,
        _inFold: _vm.module.inFold || 0,
        _infloatzone: _vm.module.inFloatZone || 0,
        _bHeight: _vm.module.pattern.bh || 0,
        _headerHiden: _vm.headerTitle ? "" : "1",
      },
    },
    [
      _vm.isLightModule
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.hiddenTitle,
                  expression: "!hiddenTitle",
                },
              ],
              staticClass: "formBannerTitle",
              class: _vm.formBannerTitleClass,
              style: _vm.bannerStyleComp,
              attrs: { _bannerAutoHeight: _vm.module.pattern.bh > 0 ? 0 : 1 },
            },
            [
              _c("div", {
                staticClass: "titleLeft",
                class: "titleLeft" + _vm.id,
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "clearFix titleCenter",
                  class: "titleCenter" + _vm.id,
                },
                [
                  _c(
                    "div",
                    { staticClass: "titleText", class: "titleText" + _vm.id },
                    [
                      _c(
                        "div",
                        { staticClass: "titleTextIcon icon-titleText" },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.openThemeV3 ? "" : " ") +
                              "\n                "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "textContent" },
                        [
                          _c("span", { staticClass: "textModuleName" }, [
                            _vm._v(_vm._s(_vm.moduleName)),
                          ]),
                          _vm._v(" "),
                          _vm.styleId == 13
                            ? [
                                _c("div", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        !_vm.openThemeV3 &&
                                        _vm.associateLottery &&
                                        _vm.showLotteryType_V1 == 0 &&
                                        _vm.titleStyle_V1 == 1,
                                      expression:
                                        "\n                                !openThemeV3 && associateLottery && showLotteryType_V1 == 0 && titleStyle_V1 == 1\n                            ",
                                    },
                                  ],
                                  staticClass:
                                    "lottery-need-open form-lottery-20201021-style-V1 form-lottery-20201021-style-V1-1",
                                  staticStyle: { visibility: "hidden" },
                                }),
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.showSubTitle
                            ? _c(
                                "div",
                                { staticClass: "textContentSubTitle" },
                                [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(_vm.subTitle.text || "副标题") +
                                      "\n                    "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        2
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.styleId == 13
                    ? [
                        _c("div", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.openThemeV3 &&
                                _vm.associateLottery &&
                                _vm.showLotteryType == 0 &&
                                _vm.titleStyle == 1,
                              expression:
                                "openThemeV3 && associateLottery && showLotteryType == 0 && titleStyle == 1",
                            },
                          ],
                          staticClass:
                            "lottery-need-open form-lottery-20201021-style form-lottery-20201021-style-1",
                          staticStyle: { visibility: "hidden" },
                        }),
                        _vm._v(" "),
                        _c("div", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.openThemeV3 &&
                                _vm.associateLottery &&
                                _vm.showLotteryType == 0 &&
                                _vm.titleStyle == 2,
                              expression:
                                "openThemeV3 && associateLottery && showLotteryType == 0 && titleStyle == 2",
                            },
                          ],
                          staticClass:
                            "lottery-need-open form-lottery-20201021-style form-lottery-20201021-style-2",
                          staticStyle: { visibility: "hidden" },
                        }),
                        _vm._v(" "),
                        _c("div", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                !_vm.openThemeV3 &&
                                _vm.associateLottery &&
                                _vm.showLotteryType_V1 == 0 &&
                                _vm.titleStyle_V1 == 2,
                              expression:
                                "!openThemeV3 && associateLottery && showLotteryType_V1 == 0 && titleStyle_V1 == 2",
                            },
                          ],
                          staticClass:
                            "lottery-need-open form-lottery-20201021-style-V1 form-lottery-20201021-style-V1-2",
                          staticStyle: { visibility: "hidden" },
                        }),
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.options.moduleStyle === 0
                    ? _c(
                        "div",
                        {
                          staticClass: "formBannerMore",
                          class: "formBannerMore" + _vm.id,
                        },
                        [
                          _vm.options.args.bannerMore || ""
                            ? _c(
                                "a",
                                {
                                  staticClass: "titleMoreLink",
                                  class: "titleMoreLink" + _vm.id,
                                  attrs: {
                                    href: _vm._f("wechatChangeLink")(
                                      _vm.options.args.bannerMore
                                    ),
                                    rel: _vm.options.args.nf ? "nofollow" : "",
                                    onclick: _vm.bannerMoreOnclickStr,
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "titleMoreIcon icon-titleMore",
                                      class: "titleMoreIcon" + _vm.id,
                                    },
                                    [
                                      _vm._v(
                                        "\n                        " +
                                          _vm._s(_vm.openThemeV3 ? "" : " ") +
                                          "\n                    "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "titleMore",
                                      class: "titleMore" + _vm.id,
                                    },
                                    [_vm._v(_vm._s(_vm.mobiMore))]
                                  ),
                                  _vm._v(" "),
                                  _vm.openThemeV3
                                    ? _c("span", [_vm._v("‍")])
                                    : _vm._e(),
                                ]
                              )
                            : _vm._e(),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.options.moduleStyle === 1 && _vm.openThemeV3
                    ? _c(
                        "div",
                        {
                          staticClass: "formBannerMore",
                          class: "formBannerMore" + _vm.id,
                          style: _vm.headerTitle ? "" : "display:none;",
                        },
                        [
                          _vm.options.args.bannerMore || ""
                            ? _c(
                                "a",
                                {
                                  staticClass: "titleMoreLink",
                                  class: "titleMoreLink" + _vm.id,
                                  attrs: {
                                    href: _vm._f("wechatChangeLink")(
                                      _vm.options.args.bannerMore
                                    ),
                                    rel: _vm.options.args.nf ? "nofollow" : "",
                                    onclick: _vm.bannerMoreOnclickStr,
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "titleMoreIcon icon-titleMore",
                                      class: "titleMoreIcon" + _vm.id,
                                    },
                                    [
                                      _vm._v(
                                        "\n                        " +
                                          _vm._s(_vm.openThemeV3 ? "" : " ") +
                                          "\n                    "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "titleMore",
                                      class: "titleMore" + _vm.id,
                                    },
                                    [_vm._v(_vm._s(_vm.mobiMore))]
                                  ),
                                  _vm._v(" "),
                                  _vm.openThemeV3
                                    ? _c("span", [_vm._v("‍")])
                                    : _vm._e(),
                                ]
                              )
                            : _vm._e(),
                        ]
                      )
                    : _vm._e(),
                ],
                2
              ),
              _vm._v(" "),
              _c("div", {
                staticClass: "titleRight",
                class: "titleRight" + _vm.id,
              }),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "formMiddle", class: _vm.formMiddleClass }, [
        _c("div", { staticClass: "middleLeft", class: "middleLeft" + _vm.id }),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "middleCenter",
            class: "middleCenter" + _vm.id,
            style: _vm.middleCentStyle,
          },
          [
            _c(
              "div",
              {
                staticClass: "formMiddleContent moduleContent",
                class: "formMiddleContent" + _vm.id,
                attrs: { _innerType: _vm.module.pattern.i.y || 0 },
              },
              [
                _vm.manageMode
                  ? [_vm._t("default")]
                  : [
                      _c(
                        "div",
                        { staticClass: "module_content" },
                        [_vm._t("default")],
                        2
                      ),
                    ],
              ],
              2
            ),
          ]
        ),
        _vm._v(" "),
        _c("div", {
          staticClass: "middleRight",
          class: "middleRight" + _vm.id,
        }),
      ]),
      _vm._v(" "),
      _vm.options.moduleStyle === 1 && !_vm.openThemeV3
        ? _c(
            "div",
            {
              staticClass: "formBannerMore",
              class: "formBannerMore" + _vm.id,
              style: _vm.headerTitle ? "" : "display:none;",
            },
            [
              _vm.options.args.bannerMore || ""
                ? _c(
                    "a",
                    {
                      staticClass: "titleMoreLink",
                      class: "titleMoreLink" + _vm.id,
                      attrs: {
                        href: _vm._f("wechatChangeLink")(
                          _vm.options.args.bannerMore
                        ),
                        rel: _vm.options.args.nf ? "nofollow" : "",
                        onclick: _vm.bannerMoreOnclickStr,
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "titleMoreIcon icon-titleMore",
                          class: "titleMoreIcon" + _vm.id,
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.openThemeV3 ? "" : " ") +
                              "\n            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "titleMore",
                          class: "titleMore" + _vm.id,
                        },
                        [_vm._v(_vm._s(_vm.mobiMore))]
                      ),
                      _vm._v(" "),
                      _vm.openThemeV3 ? _c("span", [_vm._v("‍")]) : _vm._e(),
                    ]
                  )
                : _vm._e(),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }