var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "item_radio_pic", class: _vm.itemRadioBoxPicClass },
    _vm._l(_vm.radioPicSetting.picList, function (pic, index) {
      return _c(
        "div",
        {
          key: pic.id,
          staticClass: "radio_pic_item g_main_color_v3 g_oldThemeColor",
          class: { radio_pic_item_checked: _vm.selectId === pic.id },
          style: _vm.itemStyle,
          on: {
            click: function ($event) {
              return _vm.selectItem(pic)
            },
          },
        },
        [
          _c("div", {
            staticClass: "radio_pic_item_pic",
            style: _vm.getStyle(pic),
            on: _vm._d({}, [
              _vm.previewPicEvent,
              function ($event) {
                $event.stopPropagation()
                return _vm.previewPic(pic, index)
              },
            ]),
          }),
          _vm._v(" "),
          _vm.radioPicSetting.isShowPhotoName
            ? _c(
                "div",
                {
                  staticClass: "radio_pic_item_name",
                  style: _vm.itemNameStyle,
                },
                [_vm._v(_vm._s(pic.name))]
              )
            : _vm._e(),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }