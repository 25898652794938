<template>
    <div :id="_listId" class="fk-productListTitle mProductList" :class="listClass" :mType="module.content.t">
        <template v-for="(info, index) in productList">
            <muti-column-item
                :key="'p_' + info.id"
                :info="info"
                :index="index"
                :row-max-height="rowMaxHeight(info, index)"
                :style="marginFixStyle(index)"
                :class="needMargin(index)"
                :no-lazy-load="noLazyLoad"
            />
        </template>
        <div
            v-if="needBlankDiv"
            class="blankDiv cardWrap mProductTileFormForStyle1 mProductTileForm"
            :style="marginFixStyle(1)"
            :class="needMargin(1)"
        ></div>
        <div
            v-if="needBlankDiv2"
            class="blankDiv cardWrap mProductTileFormForStyle1 mProductTileForm"
            :style="marginFixStyle(false)"
            :class="needMargin(false)"
        ></div>
    </div>
</template>

<script>
import MutiColumnItem from './muti-column-item.vue';
import productMixin from '../../mixins/productMixin';
import { mapFlag } from '@/shared/flag';
import { Img } from '@/shared/img';

export default {
    name: 'MutiColumn',
    components: { MutiColumnItem },
    mixins: [productMixin],
    inject: ['module', 'options'],
    props: ['productList', 'noLazyLoad'],
    data() {
        return {
            imgContainerWidth: 174,
            wrapDivWidth: 0,
        };
    },
    beforeCreate() {
        this.$on('initImgContainerWidth', () => {
            //this.imgContainerWidth = width
        });
    },
    computed: {
        ...mapFlag({
            hasEditTwoColumnStyle: 0x2,
        }),
        proStyle() {
            if (this._isTwoCol && !this.hasEditTwoColumnStyle) {
                return 4;
            }
            return this.module.content.proStyle;
        },
        isCardStyle() {
            return (!this.isNewAddModule && this.proStyle == 5) || (this.isNewAddModule && this.proStyle == 11);
        },
        isBorderStyle() {
            return (!this.isNewAddModule && this.proStyle == 6) || (this.isNewAddModule && this.proStyle == 9);
        },
        isOldModuleProStyle7() {
            return !this.isNewAddModule && this.proStyle == 7 && this.module.content.t == 1; // 宫格样式，宽度选择错误，导致图片真正的缩放宽度有问题。fix【ID1053961】
        },
        listClass() {
            let classes = this._listClass;
            if (this.module.content.bk && this.module.extInfo.bookingOpen) {
                classes += ' jz_bookingOpen ';
            }
            if (this._isTwoCol) {
                if (this.isCardStyle) {
                    classes += ' jz_cardStyle';
                } else if (this.isBorderStyle) {
                    classes += ' jz_borderStyle ';
                } else if (this.proStyle == 7) {
                    classes += ' jz_mergeBorderStyle ';
                }
                if (this.module.content.proStyle == 7) {
                    classes += 'squareStyle';
                }
            }
            return classes;
        },
        rowMaxHeight() {
            // 找出每行中的最大高度
            let chunk = this._isThreeCol ? 3 : 2; //每2个分一组
            return (info, index) => {
                const rowSize = this.rowsImgSize[Math.floor(index / chunk)];
                const imgHeight = rowSize.map((item) => {
                    return item.height;
                });
                return Math.max(...imgHeight);
            };
        },
        rowsImgSize() {
            let chunk = this._isThreeCol ? 3 : 2; //每2个分一组
            let list = [];
            const data = this.picSizeAry;
            for (let i = 0, j = data.length; i < j; i += chunk) {
                list.push(data.slice(i, i + chunk));
            }
            return list;
        },
        picSizeAry() {
            // 计算好每个图片的高宽
            let ary = [];
            this.productList.forEach((item) => {
                if (item.addedTime) {
                    // 鸭子式判断 item 是否是空对象，空对象则不进来。由于分页时会 push 空对象
                    let transHeight = item.extInfo.transHeight || 80;
                    let transWidth = item.extInfo.transWidth || 80;
                    let maxWidth = this.imgContainerWidth;
                    let maxHeight = null;
                    let picSize = Img.calcSize(transWidth, transHeight, maxWidth, maxHeight, 2);
                    ary.push(picSize);
                }
            });

            return ary;
        },
        needBlankDiv2() {
            // 三列少一个和两列少一个的情况  补在最后一位
            var productListLength = this.productList.length;
            return (
                this.isNewAddModule &&
                ((this._isTwoCol && productListLength % 2 != 0) || (this._isThreeCol && productListLength % 3 != 0))
            );
        },
        needBlankDiv() {
            // 三列少两个的情况 补在倒数第二位
            var productListLength = this.productList.length;
            return this.isNewAddModule && this._isThreeCol && productListLength % 3 == 1;
        },
        widthStyle() {
            if (this.module.content.ct != 3) return {};
            var widthStyle = {};
            if (this.module.content.proMargin.proMarginType == 0) return;
            var width = (this.wrapDivWidth - this.module.content.proMargin.proMarginX * 2 - 40) / 3 + 'px';
            widthStyle.width = width;
            return widthStyle;
        },
    },
    watch: {
        'module.content.t': function () {
            this.$nextTick(function () {
                this.imgContainerWidth = this.isOldModuleProStyle7
                    ? document.querySelector(`#module${this.module.id} .imgClass_160`).offsetWidth || 80
                    : document.querySelector(`#module${this.module.id} .mProductTileForm`).offsetWidth || 80;
            });
        },
    },
    mounted() {
        this.wrapDivWidth = jm('#module' + this.module.id + ' .formMiddleContent').width();
        this.imgContainerWidth = this.isOldModuleProStyle7
            ? document.querySelector(`#module${this.module.id} .imgClass_160`).offsetWidth || 80
            : document.querySelector(`#module${this.module.id} .mProductTileForm`).offsetWidth || 80;
    },
    methods: {
        marginFixStyle(index) {
            if (index === false) {
                index = this._isTwoCol ? 1 : false;
            }
            return { ...this.marginStyle(index), ...this.widthStyle };
        },
        marginStyle(index) {
            if (!this.isNewAddModule || (this._isTwoCol && this.module.content.proStyle == 7)) return;
            var marginStyle = {};
            var colmumType = this.module.content.ct;
            var proMarginType = this.module.content.proMargin.proMarginType;
            var proMarginX = proMarginType == 0 ? 13 : this.module.content.proMargin.proMarginX;
            var proMarginY = proMarginType == 0 ? 15 : this.module.content.proMargin.proMarginY;
            var noNeedTopFlag =
                (colmumType == 2 && (index == 0 || index == 1)) ||
                (colmumType == 3 && (index == 0 || index == 1 || index == 2))
                    ? true
                    : false;
            if (!noNeedTopFlag && proMarginType == 1) {
                // marginStyle.marginTop = proMarginY + 'px';
                // 375 为管理态下手机视图宽度，mobi又将该宽度分为16份作为1rem
                marginStyle.marginTop = (proMarginY * 16) / 375 + 'rem'; // 转换为 rem，避免不同分辨率下px问题
            }
            if (this._isTwoCol) {
                if (index % 2 != 0) {
                    // marginStyle.marginLeft = proMarginX + 'px';
                    marginStyle.marginLeft = Number((proMarginX * 16) / 375).toFixed(2) + 'rem'; // 转换为rem
                }
            } else if (this._isThreeCol) {
                if ((index && index == 1) || index % 3 == 1) {
                    // marginStyle.marginLeft = proMarginX + 'px';
                    // marginStyle.marginRight = proMarginX + 'px';
                    marginStyle.marginLeft = (proMarginX * 16) / 375 + 'rem';
                    marginStyle.marginRight = (proMarginX * 16) / 375 + 'rem';
                }
            }
            return marginStyle;
        },
        needMargin(index) {
            return (this._isTwoCol && index % 2 != 0) || (this._isThreeCol && ((index && index == 1) || index % 3 == 1))
                ? 'needMarginClass'
                : '';
        },
    },
};
</script>

<style></style>
