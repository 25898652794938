var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.manageMode
      ? _c(
          "div",
          { staticClass: "empty_tips_panel" },
          [
            _c("div", { staticClass: "text" }, [_vm._v(_vm._s(_vm.text))]),
            _vm._v(" "),
            _c(
              "button-component",
              {
                attrs: { active: "" },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.addType.apply(null, arguments)
                  },
                },
              },
              [_vm._v(_vm._s(_vm.buttonText))]
            ),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    !_vm.manageMode
      ? _c("div", { staticClass: "cus_Empty_tips_panel" }, [
          _c("div", { staticClass: "text2" }, [_vm._v("没有添加分类")]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }