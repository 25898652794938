var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("module-frame", { attrs: { "module-id": _vm.moduleId } }, [
    _c("div", { staticClass: "J_packModule fk-formPack" }, [
      _c(
        "div",
        {
          staticClass: "J_packContentList f-packContentList",
          style: _vm.packContentStyle,
          attrs: { id: _vm.packContentId },
        },
        [
          _c("div", {
            staticClass: "J_packContentBg f-packContentBg",
            class: "f-packContentBg" + _vm.moduleId,
          }),
          _vm._v(" "),
          _vm.manageMode
            ? _c(
                "div",
                {
                  staticClass: "J_packEditBtn f-packEditBtn",
                  style: _vm.addBtnStyle,
                },
                [
                  _c("div", { staticClass: "J_addContent f-addContent" }, [
                    _c("div", {
                      staticClass: "addBtn_Icon",
                      class: { "f-disableEdit": _vm.hasContainerId },
                      attrs: { _id: _vm.moduleId },
                      on: { click: _vm.addBtnClick },
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "f-addText" }, [
                      _c("span", [_vm._v("目前自由容器仅支持放置常用模块")]),
                    ]),
                  ]),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "J_formPackContent f-formPackContent",
              attrs: { id: "formPackContent" + _vm.moduleId },
            },
            [
              _vm.manageMode
                ? _c("div", {
                    staticClass:
                      "fk-elemZoneBg J_zoneContentBg elemZoneBg f-packContentBg",
                    class: ["f-packContentBg" + _vm.moduleId],
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm._l(_vm.children, function (childModule) {
                return _c(
                  _vm.componentsTable[_vm.getModuleById(childModule.id).style],
                  {
                    key: childModule.id,
                    tag: "component",
                    attrs: { "module-id": childModule.id },
                  }
                )
              }),
            ],
            2
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }