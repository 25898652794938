var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "moduleFrame",
      class: _vm.moduleCls,
      attrs: {
        id: "module" + _vm.id,
        _moduleStyle: _vm.styleId,
        _autoHeight: _vm.autoHeightComp,
        _height: _vm.moduleInfo.pattern.h || 0,
        _autoWidth: _vm.autoWidthComp,
        _width: _vm.moduleInfo.pattern.w || 0,
        _inpack: _vm.inpackComp,
        _inPopupZone: _vm.moduleInfo.inPopup || 0,
        _inTab: _vm.moduleInfo.inTab || 0,
        _inFold: _vm.moduleInfo.inFold || 0,
        _infloatzone: _vm.moduleInfo.inFloatZone || 0,
        _bHeight: _vm.moduleInfo.pattern.bh || 0,
        _headerHiden: "1",
      },
    },
    [
      _c("div", { staticClass: "formMiddle", class: "formMiddle" + _vm.id }, [
        _c("div", { staticClass: "middleLeft", class: "middleLeft" + _vm.id }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "middleCenter", class: "middleCenter" + _vm.id },
          [
            _c(
              "div",
              {
                staticClass: "formMiddleContent moduleContent",
                class: "formMiddleContent" + _vm.id,
                attrs: { _innerType: _vm.moduleInfo.pattern.i.y || 0 },
              },
              [
                _c("div", { staticClass: "jz_popupZoneWrap" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "J_formPopupZoneContent f-formPopupZoneContent",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "fk-elemZoneBg J_zoneContentBg",
                          class: _vm.zoneBgClass,
                        },
                        [
                          _vm._l(_vm.childrenList, function (children) {
                            return [
                              _c(_vm.childrenComponent(children), {
                                key: _vm.createKeyTime(children.id),
                                tag: "component",
                                style: _vm.childrenComponentStyle(children),
                                attrs: { "module-id": children.id },
                              }),
                            ]
                          }),
                        ],
                        2
                      ),
                    ]
                  ),
                ]),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c("div", {
          staticClass: "middleRight",
          class: "middleRight" + _vm.id,
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }