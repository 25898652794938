var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("module-frame", { attrs: { "module-id": _vm.moduleId } }, [
    _c(
      "div",
      { staticClass: "newsList", attrs: { id: "newsList" + _vm.moduleId } },
      [
        _vm.searchList.length > 0
          ? _vm._l(_vm.renderList, function (item, index) {
              return _c(_vm.styleComponet, {
                key: `${_vm.pageno}_item_${index}`,
                tag: "component",
                attrs: { item: item, last: index == _vm.renderList.length - 1 },
              })
            })
          : _c("div", { staticClass: "noSearchList" }, [
              _c("img", {
                staticClass: "noSearchListImg",
                attrs: {
                  src: _vm.resRoot + "/image/v2/defaultIcon10.png?v=20190128",
                  alt: "",
                },
              }),
              _vm._v(" "),
              _c("p", { staticClass: "noSearchListText" }, [
                _vm._v(_vm._s(_vm.mobiSearchResult)),
              ]),
            ]),
        _vm._v(" "),
        _vm.showMobiViewer
          ? _c("image-viewer", {
              attrs: {
                "z-index": _vm.zIndex,
                "initial-index": _vm.initialIndex,
                "on-close": _vm.onClose,
                "url-list": _vm.options.swipePhotoList,
                "is-mobi": true,
                "content-terminal": "mobi",
                "manage-mode": _vm.manageMode,
                "document-event-el": _vm.documentEventEl,
                "append-to-container-id": "g_body",
                "append-to-body": false,
                "disable-long-press": _vm.banCopyTextImg,
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.module.content.pi && _vm.searchList.length > 0
          ? _c("pagenation", {
              attrs: {
                "module-id": _vm.moduleId,
                "total-size": _vm.totalSize,
                "page-size": _vm.module.content.sc,
                pageno: _vm.pageno,
                "open-theme-v3": _vm.openThemeV3,
                "ajax-pagenation": true,
              },
              on: { "on-change": _vm.changePage },
              nativeOn: {
                click: function ($event) {
                  $event.stopPropagation()
                },
              },
            })
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }