var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isPicStyle
    ? _c(
        "div",
        { staticClass: "searchResultLine pic", class: _vm.fixedPicConetent },
        [
          _c(
            "a",
            {
              staticClass: "pic",
              class: _vm.picSizeClass,
              attrs: {
                href: _vm.hrefStr,
                onclick: _vm.reqArg,
                hidefocus: "true",
              },
            },
            [
              _vm.showKnowPdLabel
                ? _c("span", { staticClass: "quality_content_label" }, [
                    _vm._v(_vm._s(_vm.knowPdLabelText)),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("img", {
                staticClass: "J_img_lazyload",
                attrs: {
                  width: "100%",
                  height: "100%",
                  alt: "",
                  "src-original": _vm.renderImgOpts.srcOriginal,
                  src: _vm.renderImgOpts.loadingPath,
                  "data-picId": _vm.renderImgOpts.dataPicId,
                },
                on: {
                  click: function ($event) {
                    return _vm.photoSlidesshow(_vm.item.index)
                  },
                },
              }),
              _vm._v(" "),
              _vm.searchFlag == 1
                ? _c("div", { staticClass: "resultDetail" }, [
                    _c("span", {
                      staticClass: "detailSpan proName",
                      attrs: { hidefocus: "true" },
                      domProps: { innerHTML: _vm._s(_vm.getHighLightWord) },
                    }),
                    _vm._v(" "),
                    _vm.showPrice && !_vm.isLinkProduct
                      ? _c(
                          "span",
                          {
                            staticClass: "detailSpan proPrice",
                            class: _vm.priceClass,
                          },
                          [
                            _vm._v(_vm._s(_vm.g_price) + "："),
                            _vm.siteCurrencyRight
                              ? _c(
                                  "span",
                                  {
                                    staticClass: "g_mainColor g_main_color_v3",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.printNewStyleOption.mallPrice
                                      ) +
                                        _vm._s(
                                          _vm.printNewStyleOption
                                            .choiceCurrencyVal
                                        )
                                    ),
                                  ]
                                )
                              : _c(
                                  "span",
                                  {
                                    staticClass: "g_mainColor g_main_color_v3",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.printNewStyleOption
                                          .choiceCurrencyVal
                                      ) +
                                        _vm._s(
                                          _vm.printNewStyleOption.mallPrice
                                        )
                                    ),
                                  ]
                                ),
                          ]
                        )
                      : _vm._e(),
                  ])
                : _vm.searchFlag == 3
                ? _c("div", [
                    _c("div", { staticClass: "resultDetail" }, [
                      _c("span", {
                        staticClass: "detailSpan picName",
                        attrs: { hidefocus: "true" },
                        domProps: { innerHTML: _vm._s(_vm.getHighLightWord) },
                      }),
                    ]),
                  ])
                : _vm.searchFlag == 5
                ? _c("div", [
                    _c("div", { staticClass: "resultDetail picDetailAb" }, [
                      _c("span", {
                        staticClass: "detailSpan detailSpanInline phgName",
                        attrs: { hidefocus: "true" },
                        domProps: { innerHTML: _vm._s(_vm.getHighLightWord) },
                      }),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "detailSpan detailSpanInline phgSize",
                          attrs: { hidefocus: "true" },
                        },
                        [_vm._v(_vm._s(_vm.item.picGrounpSize) + " 张")]
                      ),
                    ]),
                  ])
                : _vm._e(),
            ]
          ),
        ]
      )
    : _c("div", { staticClass: "searchResultLine text" }, [
        _c("div", { staticClass: "textItemContent", class: _vm.textType }, [
          _c(
            "a",
            {
              staticClass: "word",
              attrs: {
                href: _vm.hrefStr,
                hidefocus: "true",
                onclick: _vm.reqArg,
              },
            },
            [
              _c("span", {
                staticClass: "textSpan",
                domProps: { innerHTML: _vm._s(_vm.getHighLightWord) },
              }),
            ]
          ),
        ]),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }