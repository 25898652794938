var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("module-frame", { attrs: { "module-id": _vm.moduleId } }, [
    _c(
      "div",
      {
        staticClass: "jz_mobi_form",
        class: _vm.formClass,
        on: { click: _vm.editItemEnd },
      },
      [
        _vm.module.extInfo.notAllowPrint
          ? [_vm._v(_vm._s(_vm.module.extInfo.noSiteFormTip))]
          : !_vm.formEmpty && !_vm.formItemEmpty
          ? [
              _vm.permissionWarning
                ? _c("module-alert", { attrs: { type: "warning" } }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.permissionWarning) +
                        "\n            "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.module.content.sn
                ? _c(
                    "div",
                    { staticClass: "form_name", style: _vm.formNameStyle },
                    [_vm._v(_vm._s(_vm.formInfo.name))]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.openSubmitLimitTime && _vm.formLimitTimeTips
                ? _c(
                    "p",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.showSubmitLimitTime,
                          expression: "showSubmitLimitTime",
                        },
                      ],
                      staticClass: "form_limit_time_tips",
                      style: _vm.limitTimeTipsStyle,
                    },
                    [
                      _c("svg", { staticClass: "form_limit_time_tips_icon" }, [
                        _c("use", { attrs: { "xlink:href": "#icon_time" } }),
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "form_limit_time_tips_text" }, [
                        _vm._v(_vm._s(_vm.formLimitTimeTips)),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "form_items_wrap",
                  class: _vm.formItemsWrapClass,
                },
                [
                  _vm._l(_vm.filterContentList, function (item) {
                    return _c(
                      "div",
                      {
                        key: "form_" + _vm.currentFormId + "_" + item.id,
                        staticClass: "item_wrap J_item_wrap",
                        style: _vm.itemStyle,
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.editItem($event, item)
                          },
                        },
                      },
                      [
                        item.type != 8
                          ? [
                              _c(
                                "form-item",
                                { attrs: { "form-item": item } },
                                [
                                  _c(_vm.componentMap[item.type], {
                                    tag: "component",
                                    attrs: {
                                      "form-item": item,
                                      "form-info": _vm.formInfo,
                                    },
                                    on: {
                                      selectChange: _vm.handleSelectChange,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          : [
                              _c("FormMultiItem", {
                                attrs: { "form-item": item },
                              }),
                            ],
                      ],
                      2
                    )
                  }),
                  _vm._v(" "),
                  _vm.validation || _vm.needCode
                    ? _c(
                        "div",
                        {
                          staticClass: "item_wrap",
                          style: _vm.itemStyle,
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.openFunEdit.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c(
                            "form-item",
                            { attrs: { "form-item": _vm.validateCodeItem } },
                            [
                              _c("form-validate-code", {
                                attrs: {
                                  "form-item": _vm.validateCodeItem,
                                  module: _vm.module,
                                  "form-info": _vm.formInfo,
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isOpenPay && !_vm.openMultiPdInquiry,
                          expression: "isOpenPay && !openMultiPdInquiry",
                        },
                      ],
                      staticClass: "pay_wrap",
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.openFunEdit(_vm.isOpenPay)
                        },
                      },
                    },
                    [
                      _c("span", { staticClass: "pay_text" }, [
                        _vm._v(_vm._s(_vm.formInfo.payTxt) + ":"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "pay_money_wrap" }, [
                        _c(
                          "span",
                          {
                            class: _vm.themeColorClass,
                            staticStyle: {
                              "font-size": "0.5rem",
                              "font-weight": "bold",
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.module.extInfo.currencySymbol || "￥")
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            class: _vm.themeColorClass,
                            staticStyle: {
                              "font-size": "0.65rem",
                              "font-weight": "bold",
                            },
                          },
                          [_vm._v(_vm._s(_vm.formatedMoney[0]) + ".")]
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            class: _vm.themeColorClass,
                            staticStyle: {
                              "font-size": "0.5rem",
                              "font-weight": "bold",
                            },
                          },
                          [_vm._v(_vm._s(_vm.formatedMoney[1]))]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "pay_type_wrap" }, [
                        _c("svg", { staticClass: "wx_pay_icon" }, [
                          _c("use", {
                            attrs: { "xlink:href": "#jzm-vicon_online_pay" },
                          }),
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "wx_pay_text" }, [
                          _vm._v(_vm._s(_vm.LS.g_onlinePay || "在线支付")),
                        ]),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.manageMode &&
                  _vm.isOpenPay &&
                  !_vm.hasOpenPay &&
                  !_vm.openMultiPdInquiry
                    ? _c("div", { staticClass: "managePayTips" }, [
                        _vm._v("在线支付需设置支付方式"),
                        _c(
                          "span",
                          {
                            staticClass: "redStrong",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.jumpToSet.apply(null, arguments)
                              },
                            },
                          },
                          [_vm._v("前往设置")]
                        ),
                        _vm._v("或"),
                        _c(
                          "span",
                          {
                            staticClass: "redStrong",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.jumptoOpen.apply(null, arguments)
                              },
                            },
                          },
                          [_vm._v("关闭在线支付")]
                        ),
                      ])
                    : _vm._e(),
                ],
                2
              ),
              _vm._v(" "),
              _vm.isOpenFormProtocol && _vm.allowFormProtocol
                ? _c(
                    "div",
                    { staticClass: "form_protocol" },
                    [
                      _c("check-box", {
                        staticClass: "form_protocol_agree",
                        model: {
                          value: _vm.isAgree,
                          callback: function ($$v) {
                            _vm.isAgree = $$v
                          },
                          expression: "isAgree",
                        },
                      }),
                      _vm._v(" "),
                      _c("div", [
                        _c(
                          "span",
                          {
                            staticClass: "form_protocol_text",
                            style: _vm.formProtocolTextStyle,
                            on: {
                              click: function ($event) {
                                _vm.isAgree = !_vm.isAgree
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.formProtocolText))]
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticClass: "form_protocol_title",
                            style: _vm.formProtocolTitleStyle,
                            on: { click: _vm.previewFormProtocol },
                          },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(_vm.formProtocolTitle)
                            ),
                          ]
                        ),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.manageMode &&
              _vm.module.content.ms === 0 &&
              _vm.module.extInfo.submitSuccessTipCustom
                ? _c("div", { staticClass: "managePayTips" }, [
                    _vm._v("自定义样式下的表单提交成功提示正常展示需"),
                    _c(
                      "span",
                      {
                        staticClass: "redStrong",
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            _vm.module.content.ms = 1
                          },
                        },
                      },
                      [_vm._v("切换新样式")]
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "submit_btn",
                  class: _vm.submitBtnClasses,
                  style: _vm.submitBtnStyle,
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.submitForm.apply(null, arguments)
                    },
                  },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.submittingTip ||
                        _vm.module.content.sut ||
                        _vm.formInfo.buttonName
                    )
                  ),
                ]
              ),
            ]
          : _vm.manageMode && !_vm.formEmpty && _vm.formItemEmpty
          ? [
              _c(
                "div",
                { staticClass: "empty_tips_panel" },
                [
                  _c("div", { staticClass: "text" }, [
                    _vm._v("请从“编辑表单”悬浮面板中，添加表单项。"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "button-component",
                    {
                      attrs: { active: "" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.addForm.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("马上添加")]
                  ),
                ],
                1
              ),
            ]
          : _vm.formEmpty
          ? [_vm._v(_vm._s(_vm.notFoundFormTip))]
          : _vm.formItemEmpty
          ? [
              _c("div", { staticClass: "form_item_empty" }, [
                _vm._v("当前表单没有任何表单项"),
              ]),
            ]
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }