import { MobiModule } from '../Module';
import { MODULE_STYLE } from '@/def/module';
import PhotoCarouselView from './module.vue';

class _PhotoCarousel extends MobiModule {
    constructor(options = {}) {
        super(MODULE_STYLE.PHOTO_CAROUSEL, options);
    }

    render() {
        return PhotoCarouselView;
    }
}

export const PhotoCarousel = new _PhotoCarousel();
