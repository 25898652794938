// 图文展示滚动动画，调用Mobi.richMarquee.init
import { createElementByHtml } from '@/shared/util';
const richMarquee = {
    utils: {}, // 工具
    event: {}, // 事件
    dom: {}, // dom对象
    data: {}, // 数据
};
// contentPanel 是指要复制的节点选择器
richMarquee.init = function (moduleId, contentPanel, option) {
    var speed = 5,
        module = document.getElementById('module' + moduleId),
        richContentPanel = module.querySelectorAll(contentPanel + moduleId + '')[0],
        marqueeContainer = module.querySelectorAll('.J_marqueeContainer' + moduleId)[0];

    if (typeof module != 'object' || typeof richContentPanel != 'object' || typeof marqueeContainer != 'object') {
        return;
    }

    var options = {
        s: true, // 是否滚动
        d: 'up', //方向
        sp: 'normal', //速度
        mm: true, //移出
        i: true, //是否连续滚动
    };

    jm.extend(options, option);
    if (options.sp == 'normal') {
        if (options.d == 'up' || options.d == 'down') {
            speed =
                ((5 * richContentPanel.offsetWidth) / module.offsetWidth) *
                (richContentPanel.offsetHeight / richContentPanel.offsetWidth);
        } else {
            speed = (5 * richContentPanel.offsetWidth) / module.offsetWidth;
        }
    } else if (options.sp == 'slow') {
        if (options.d == 'up' || options.d == 'down') {
            speed =
                ((10 * richContentPanel.offsetWidth) / module.offsetWidth) *
                (richContentPanel.offsetHeight / richContentPanel.offsetWidth);
        } else {
            speed = (10 * richContentPanel.offsetWidth) / module.offsetWidth;
        }
    } else {
        if (options.d == 'up' || options.d == 'down') {
            speed =
                ((2.5 * richContentPanel.offsetWidth) / module.offsetWidth) *
                (richContentPanel.offsetHeight / richContentPanel.offsetWidth);
        } else {
            speed = (2.5 * richContentPanel.offsetWidth) / module.offsetWidth;
        }
    }
    jm.extend(options, { sp: speed });
    var contentPanelClass = richContentPanel.className,
        richContentPanelCopy = createElementByHtml(
            "<div class='J_contentPanelCopy" + moduleId + ' f-contentPanel ' + contentPanelClass + "'></div>"
        ),
        richContentPanelParent = richContentPanel.parentNode,
        panelOffset = jm(richContentPanel).offset(),
        animationCount = 'infinite',
        animationMoveFlag = '',
        animationTimeBase = 1,
        ua = navigator.userAgent,
        animationName,
        animationStyle;

    jm(marqueeContainer).css({
        width: panelOffset.width + 'px',
    });

    if (options.d == 'up') {
        animationName = 'buttomToTop';
    } else if (options.d == 'down') {
        animationName = 'topToButtom';
    } else if (options.d == 'left' || options.d == 'right') {
        animationName = options.d == 'left' ? 'rightToLeft' : 'leftToRight';
        var style = {
            width: panelOffset.width + 'px',
            height: 'auto',
            position: 'absolute',
            left: panelOffset.width + 'px',
            top: 0,
        };
        if (options.styleId == 41) {
            Object.assign(style, {
                display: 'flex',
                overflow: 'initial',
            });
        }
        jm(richContentPanelCopy).css(style);
    }

    if (options.i) {
        // 连续滚动才copy一份节点
        richContentPanelCopy.innerHTML = richContentPanel.innerHTML;
        richContentPanelParent.appendChild(richContentPanelCopy);
    }

    if (!options.mm) {
        jm(richContentPanelParent).one(
            'webkitAnimationStart mozAnimationStart MSAnimationStart oanimationstart animationstart',
            function () {
                jm(richContentPanelParent).removeClass('fk-marqueeMoveInStyle');
            }
        );
    }

    if (!options.mm && options.i) {
        // 移入 & 连续
        animationMoveFlag = '_moveIn';
        animationCount = 1; // 只执行一次动画，原因可以看下面移入 & 连续滚动原理
        animationTimeBase = 1; // 设为2主要是因为动画的执行过程减少了一半，详细看base.src.css
    } else if (options.mm && !options.i) {
        // 移出 & 不连续
        animationMoveFlag += '_discontinuous';
        animationCount = 1; // 同上
        animationTimeBase = 1 / 2;
    } else if (options.mm && options.i) {
        // 移出 & 连续
        animationMoveFlag = '';
    } else if (!options.mm && !options.i) {
        // 移入 & 不连续
        animationMoveFlag = '_moveIn_discontinuous';
        animationTimeBase = 1 / 2;
    }

    animationStyle =
        animationName + animationMoveFlag + ' ' + options.sp / animationTimeBase + 's linear ' + animationCount;

    if (options.i) {
        // 连续滚动
        animationStyle =
            animationName + animationMoveFlag + ' ' + options.sp / animationTimeBase + 's linear ' + animationCount;
    } else {
        // 不连续滚动，上、下的情况
        if (options.mm) {
            // 注意 buttomToTop_discontinuous 和 buttomToTop_moveIn_discontinuous 的移动高度问题，同样的时间、不同样的距离会导致速度看起来不一样
            animationStyle = animationName + animationMoveFlag + ' ' + options.sp + 's linear ' + animationCount;
        } else {
            animationStyle =
                animationName + animationMoveFlag + ' ' + options.sp / animationTimeBase + 's linear ' + animationCount;
        }
    }

    if (Fai.top.Fai.isIE10 && options.mm && options.i && (options.d == 'down' || options.d == 'right')) {
        //（向下 || 向右） & 移出 & 连续滚动 在IE10下出错，具体原因未明。这里先延迟执行
        setTimeout(function () {
            _animateRunCtrl(jm(richContentPanelParent), 'animation', animationStyle);
        }, 0);
    } else {
        _animateRunCtrl(jm(richContentPanelParent), 'animation', animationStyle);
    }

    if (!options.mm && options.i) {
        // 移入 & 连续滚动原理：只执行一次移入动画，结束后马上执行移出动画（移入动画的结束状态就是移出动画开始时的状态）。
        jm(richContentPanelParent).off(
            'webkitAnimationEnd.moveIn mozAnimationEnd.moveIn MSAnimationEnd.moveIn oanimationend.moveIn animationend.moveIn'
        );
        jm(richContentPanelParent).one(
            'webkitAnimationEnd.moveIn mozAnimationEnd.moveIn MSAnimationEnd.moveIn oanimationend.moveIn animationend.moveIn',
            function () {
                animationStyle = animationName + ' ' + options.sp + 's linear infinite';
                _animateRunCtrl(jm(richContentPanelParent), 'animation', animationStyle);
            }
        );
    } else if (options.mm && !options.i) {
        // 移出 & 不连续滚动原理：只执行一次移出动画，结束后马上执行移出动画（移出动画的结束状态就是移入动画开始时的状态）。
        jm(richContentPanelParent).off(
            'webkitAnimationEnd.moveOut mozAnimationEnd.moveOut MSAnimationEnd.moveOut oanimationend.moveOut animationend.moveOut'
        );
        jm(richContentPanelParent).one(
            'webkitAnimationEnd.moveOut mozAnimationEnd.moveOut MSAnimationEnd.moveOut oanimationend.moveOut animationend.moveOut',
            function () {
                animationStyle =
                    animationName + '_moveIn_discontinuous ' + options.sp / animationTimeBase + 's linear infinite';
                _animateRunCtrl(jm(richContentPanelParent), 'animation', animationStyle);
            }
        );
    }

    if (!/(iPhone|iPad|iPod|Android)/i.test(ua)) {
        jm(module)
            .on('mouseover', function () {
                _animateRunCtrl(jm(richContentPanelParent), 'animation-play-state', 'paused');
            })
            .on('mouseout', function () {
                _animateRunCtrl(jm(richContentPanelParent), 'animation-play-state', 'running');
            });
    } else {
        jm(module)
            .on('touchstart', function () {
                _animateRunCtrl(jm(richContentPanelParent), 'animation-play-state', 'paused');
            })
            .on('touchend', function () {
                _animateRunCtrl(jm(richContentPanelParent), 'animation-play-state', 'running');
            });
    }
};

/**
 * @desc 解决某些机型下动画不暂停的bug，猜测是jm里的CSS方法内部关于animate属性没有自动添加前缀
 * @date 2017-1-13
 * @time 13:49
 * @author jser
 */
function _animateRunCtrl(obj, attr, value) {
    var prefixs = ['', '-webkit-', '-moz-', '-ms-'],
        prefixsLen = prefixs.length;

    while (prefixsLen) {
        prefixsLen--;
        obj.css(prefixs[prefixsLen] + attr, value);
    }
}

export { richMarquee };
