var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("module-frame", { attrs: { "module-id": _vm.moduleId } }, [
    _c(
      "div",
      {
        staticClass: "new_online_map",
        class: _vm.onlineMapClass,
        style: _vm.baiduMapHeightStyle,
      },
      [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isShowMapView,
                expression: "isShowMapView",
              },
            ],
            staticClass: "mapContainer",
            attrs: {
              id: "mapContainer" + _vm.module.id,
              type: _vm.module.content.mt,
            },
            on: {
              click: function ($event) {
                $event.stopPropagation()
              },
            },
          },
          [
            _vm.module.content.mt === 1
              ? _c("div", { staticClass: "mapLoadingBox" })
              : _vm._e(),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.isShowMapView,
                expression: "!isShowMapView",
              },
            ],
            staticClass: "list",
          },
          _vm._l(_vm.curlocationList, function (curLocation, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "list_item",
                attrs: { id: _vm.genUniqueDOMId(curLocation.id) },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.showMapView(curLocation)
                  },
                  mouseover: function ($event) {
                    return _vm.showAcitonArea(curLocation.id)
                  },
                  mouseout: function ($event) {
                    return _vm.hideActionArea(curLocation.id)
                  },
                },
              },
              [
                _c("div", { staticClass: "name_distance_wrap column_m_b" }, [
                  _c("span", {
                    staticClass: "name",
                    class: { sing_line_ellipsis: !_vm.isWrapAddressName },
                    style: _vm.addressNameStyle,
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.encodeHtml(
                          _vm._fixOverflowClipText(curLocation.name || "")
                        )
                      ),
                    },
                  }),
                  _vm._v(" "),
                  _vm.isShowDistance
                    ? _c(
                        "span",
                        { staticClass: "distance", style: _vm.distanceStyle },
                        [
                          _vm._v(
                            _vm._s(_vm._formatDistance(curLocation.distance))
                          ),
                        ]
                      )
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "location column_m_b",
                    style: _vm.locationStyle,
                  },
                  [
                    _c(
                      "svg",
                      {
                        staticClass: "new_online_map_svg",
                        style: _vm.locationSVGStyle,
                        attrs: { role: "img" },
                      },
                      [_c("use", { attrs: { "xlink:href": "#jzm-v32" } })]
                    ),
                    _vm._v(" "),
                    _c("span", {
                      staticClass: "location_text",
                      class: {
                        sing_line_ellipsis: !_vm.isWrapAddressDetail,
                      },
                      style: _vm.addressDetailStyle,
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.encodeHtml(
                            _vm._fixOverflowClipText(curLocation.detail || "")
                          )
                        ),
                      },
                    }),
                  ]
                ),
                _vm._v(" "),
                _vm.isShowTel
                  ? _c("div", { staticClass: "tel" }, [
                      _c(
                        "svg",
                        {
                          staticClass: "new_online_map_svg",
                          attrs: { role: "img" },
                        },
                        [_c("use", { attrs: { "xlink:href": "#jzm-v33" } })]
                      ),
                      _vm._v(" "),
                      _c("span", { style: _vm.telStyle }, [
                        _vm._v(_vm._s(curLocation.tel)),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", {
                  staticClass: "work_time column_m_b",
                  class: { sing_line_ellipsis: !_vm.isWrapOther },
                  style: _vm.otherMessageStyle,
                  domProps: {
                    innerHTML: _vm._s(_vm.encodeHtml(curLocation.other)),
                  },
                }),
              ]
            )
          }),
          0
        ),
        _vm._v(" "),
        _vm.isShowpagenation
          ? _c("pagenation", {
              attrs: {
                "module-id": _vm.id,
                "total-size": _vm.totalSize,
                "page-size": _vm.pageSize,
                "open-theme-v3": _vm.openThemeV3,
                pageno: _vm.pageno,
                "ajax-pagenation": true,
              },
              on: { "on-change": _vm.changePage },
              nativeOn: {
                click: function ($event) {
                  $event.stopPropagation()
                },
              },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }