var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "head_pic_text_list" },
    [
      !!_vm.headPicItemInfo
        ? _c("head-pic-item", {
            attrs: {
              info: _vm.headPicItemInfo,
              "no-lazy-load": _vm.noLazyLoad,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      !_vm.isNewAddModule
        ? _c("div", { staticClass: "g_separator separatorLine" })
        : _vm._e(),
      _vm._v(" "),
      _c("text-list", { attrs: { "news-list": _vm.newsList2 } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }