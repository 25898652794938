var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showOtherInfo
    ? _c(
        "div",
        {
          staticClass:
            "newsElementsPanel g_newsElementsPanel J_newsElementsPanel g_newsInfo",
          on: { click: _vm.otherInfoClick },
        },
        [
          _vm._showComment
            ? _c(
                "div",
                { staticClass: "ne_newsComment faisco-icons-comment" },
                [
                  !_vm.isNewAddModule
                    ? _c("span", { staticClass: "newsCommentCount" }, [
                        _vm._v(_vm._s(_vm.info.commentCount)),
                      ])
                    : _c(
                        "span",
                        { staticClass: "newsCommentCount g_newsInfo" },
                        [
                          _vm._v(
                            _vm._s(_vm.info.commentCount) +
                              _vm._s(_vm.module.extInfo.commentText)
                          ),
                        ]
                      ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm._showAuthor
            ? _c(
                "div",
                { staticClass: "ne_newsAuthor", style: _vm.authorStyle },
                [
                  _c("span", { staticClass: "newsAuthorName g_newsInfo" }, [
                    _vm._v(_vm._s(_vm.info.author)),
                  ]),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.isNewAddModule &&
          (_vm._showAuthor || _vm._showComment) &&
          (_vm._showSort || _vm._showDate)
            ? _c("div", { staticClass: "ne_separatorLine g_ne_separatorLine" })
            : _vm._e(),
          _vm._v(" "),
          _vm._showSort
            ? _c("div", { staticClass: "ne_newsSort g_newsInfo" }, [
                _vm._v("\n        " + _vm._s(_vm.info.groupName) + "\n    "),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm._showDate
            ? _c("div", { staticClass: "ne_newsTime g_newsInfo" }, [
                _vm._v("\n        " + _vm._s(_vm.newsDate) + "\n    "),
              ])
            : _vm._e(),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }