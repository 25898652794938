var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: _vm.bigItemClasses,
      attrs: {
        id: "newsLine" + _vm.info.id,
        topClassName: _vm._topClassName,
        topSwitch: _vm._topSwitch,
        newsId: _vm.info.id,
        newsName: _vm.info.title,
        picnum: 3,
      },
    },
    [
      !_vm.isNewAddModule ||
      (_vm.isNewAddModule && _vm.module.content.tPosition == 1)
        ? _c(
            "div",
            {
              staticClass: "lineBody",
              class: [_vm.isNewAddModuleClass, _vm.isOnlyTitle],
              attrs: { id: "lineBody" + _vm.info.id },
            },
            [
              _c("div", { staticClass: "mixNewsStyleImgBox1" }, [
                !_vm.isNewAddModule
                  ? _c(
                      "a",
                      {
                        staticClass: "mixNewsStyleImgBoxWrap1",
                        attrs: {
                          hidefocus: "true",
                          href: _vm._newsUrl,
                          target: _vm._jumpTarget,
                          onclick: _vm.onclickStr,
                        },
                      },
                      [
                        _vm.info.realPicId === ""
                          ? _c("div", { staticClass: "icon-noFigureM" })
                          : _c("img", {
                              attrs: {
                                src: _vm.info.pic160Path,
                                alt: _vm.altName,
                              },
                            }),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.isNewAddModule
                  ? _c(
                      "a",
                      {
                        staticClass: "mixNewsStyleImgBoxWrap1",
                        attrs: {
                          hidefocus: "true",
                          href: _vm._newsUrl,
                          target: _vm._jumpTarget,
                          onclick: _vm.onclickStr,
                        },
                      },
                      [
                        _c("div", {
                          directives: [
                            {
                              name: "drag-resize",
                              rawName: "v-drag-resize",
                              value: _vm._resizeOptions,
                              expression: "_resizeOptions",
                            },
                          ],
                          staticClass:
                            "head_word_pic picScaleClass J_img_lazyload",
                          class: _vm.bigPicClasses,
                          style: _vm.picStyle,
                          attrs: {
                            "src-original": _vm.info.realPicId
                              ? _vm.info.bigPicPath
                              : "",
                            alt: _vm.altName,
                          },
                        }),
                      ]
                    )
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "mixNewsStyleTitleContainer" }, [
                _c("div", { class: _vm._topFlagClass }),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "g_news_title news_title newTitle",
                    class: [_vm.titleClasses, _vm.titleLine],
                    style: _vm.news_title_style,
                    attrs: {
                      hidefocus: "true",
                      target: _vm._jumpTarget,
                      href: _vm._newsUrl,
                      onclick: _vm.onclickStr,
                    },
                  },
                  [
                    _vm._topFlagClass != "" && _vm.isNewAddModule
                      ? _c("top-component")
                      : _vm._e(),
                    _vm._v(_vm._s(_vm.info.title)),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _vm.isNewAddModule && !_vm.showDes
                ? _c("new-info", {
                    attrs: { info: _vm.info, index: _vm.index },
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.isNewAddModule
                ? _c(
                    "a",
                    {
                      staticClass: "mixNewsStyleSummary g_text g_news_abstract",
                      style: _vm.news_abstract_style,
                      attrs: {
                        href: _vm._newsUrl,
                        target: _vm._jumpTarget,
                        hidefocus: "true",
                        onclick: _vm.onclickStr,
                      },
                    },
                    [_vm._v(_vm._s(_vm.info.summary || ""))]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isNewAddModule && _vm.showDes
                ? _c(
                    "a",
                    {
                      staticClass:
                        "mixNewsStyleSummary g_text g_news_abstract newDescriptionLine",
                      class: [_vm.descriptionLine, _vm.isOnlyTitleAndDes],
                      style: _vm.news_abstract_style,
                      attrs: {
                        href: _vm._newsUrl,
                        target: _vm._jumpTarget,
                        hidefocus: "true",
                        onclick: _vm.onclickStr,
                      },
                    },
                    [_vm._v(_vm._s(_vm.info.summary || ""))]
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isNewAddModule && _vm.module.content.tPosition == 2
        ? _c(
            "div",
            {
              staticClass: "lineBody imgRight",
              class: [_vm.isNewAddModuleClass, _vm.isOnlyTitle],
              attrs: { id: "lineBody" + _vm.info.id },
            },
            [
              _c(
                "div",
                { staticClass: "imgRightText" },
                [
                  _c("div", { staticClass: "mixNewsStyleTitleContainer" }, [
                    _c("div", { class: _vm._topFlagClass }),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "g_news_title news_title newTitle",
                        class: [_vm.titleClasses, _vm.titleLine],
                        attrs: {
                          hidefocus: "true",
                          target: _vm._jumpTarget,
                          href: _vm._newsUrl,
                          onclick: _vm.onclickStr,
                        },
                      },
                      [
                        _vm._topFlagClass != "" && _vm.isNewAddModule
                          ? _c("top-component", {
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                },
                              },
                            })
                          : _vm._e(),
                        _vm._v(_vm._s(_vm.info.title)),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.isNewAddModule && !_vm.showDes
                    ? _c("new-info", {
                        attrs: { info: _vm.info, index: _vm.index },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.showDes
                    ? _c(
                        "a",
                        {
                          staticClass:
                            "mixNewsStyleSummary g_text g_news_abstract newDescriptionLine",
                          class: [_vm.descriptionLine, _vm.isOnlyTitleAndDes],
                          style: _vm.news_abstract_style,
                          attrs: {
                            href: _vm._newsUrl,
                            target: _vm._jumpTarget,
                            hidefocus: "true",
                            onclick: _vm.onclickStr,
                          },
                        },
                        [_vm._v(_vm._s(_vm.info.summary || ""))]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "mixNewsStyleImgBox1",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                    },
                  },
                },
                [
                  _vm.isNewAddModule
                    ? _c(
                        "a",
                        {
                          staticClass: "mixNewsStyleImgBoxWrap1",
                          attrs: {
                            hidefocus: "true",
                            href: _vm._newsUrl,
                            target: _vm._jumpTarget,
                            onclick: _vm.onclickStr,
                          },
                        },
                        [
                          _c("div", {
                            directives: [
                              {
                                name: "drag-resize",
                                rawName: "v-drag-resize",
                                value: _vm._resizeOptions,
                                expression: "_resizeOptions",
                              },
                            ],
                            staticClass:
                              "head_word_pic picScaleClass J_img_lazyload",
                            class: _vm.bigPicClasses,
                            style: _vm.picStyle,
                            attrs: {
                              "src-original": _vm.info.realPicId
                                ? _vm.info.bigPicPath
                                : "",
                              alt: _vm.altName,
                            },
                          }),
                        ]
                      )
                    : _vm._e(),
                ]
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isNewAddModule && _vm.showDes
        ? _c("new-info", { attrs: { info: _vm.info, index: _vm.index } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }