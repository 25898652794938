<template>
    <module-frame :module-id="moduleId">
        <div class="linkArea">
            <template v-for="(item, index) in dataList">
                <a
                    v-if="isHText"
                    :key="index"
                    class="link-h-container"
                    :style="textStyle"
                    :href="item.linkHref"
                    :target="linkTarget(item)"
                    :onclick="item.onclickStr"
                    v-html="linkText(item)"
                ></a>
                <div v-else-if="isVText" :key="index" class="lineBody">
                    <div class="linkTableCell">
                        <div class="J_lineH j-lineH">
                            <a
                                :style="textStyle"
                                :href="item.linkHref"
                                :target="linkTarget(item)"
                                class="link-v-container"
                                :onclick="item.onclickStr"
                                v-html="linkText(item)"
                            ></a>
                        </div>
                    </div>
                </div>
                <a
                    v-else
                    :key="index"
                    class="link-p-container"
                    :href="item.linkHref"
                    :target="linkTarget(item)"
                    :onclick="item.onclickStr"
                >
                    <div class="imgClass_160">
                        <span class="imageMiddle"></span>
                        <img class="style1Img photoListImg" :src="item.newPath" :alt="linkText(item)" />
                    </div>
                    <div :style="textStyle" v-html="linkText(item)"></div>
                </a>
            </template>
        </div>
    </module-frame>
</template>
<script>
import { LinkType, Link, DataList, TextData } from '../constant';
import ModuleFrame from '@/modules/frame/index.vue';
import { mapGetters } from 'vuex';
export default {
    name: 'ModuleLink',
    components: {
        ModuleFrame,
    },
    props: {
        moduleId: {
            type: Number,
            default: -1,
        },
    },
    computed: {
        ...mapGetters(['getModuleById']),
        module() {
            return this.getModuleById(this.moduleId);
        },
        content() {
            return this.module.content || {};
        },
        dataList() {
            return this.content[Link.DATALIST] || [];
        },
        linkType() {
            return this.content[Link.M_TYPE];
        },
        isHText() {
            return this.linkType == LinkType.H_TEXT;
        },
        isVText() {
            return this.linkType == LinkType.V_TEXT;
        },
        textInfo() {
            return this.content[Link.TEXTINFO] || {};
        },
        textType() {
            return this.textInfo[TextData.T_TYPE];
        },
        underline() {
            return this.textInfo[TextData.UNDERLINE];
        },
        bold() {
            return this.textInfo[TextData.BOLD];
        },
        color() {
            return this.textInfo[TextData.COLOR];
        },
        textStyle() {
            let style = {};
            if (this.textType) {
                if (this.underline) {
                    style['text-decoration'] = 'underline';
                }
                if (this.bold) {
                    style['font-weight'] = 'bold';
                }
                if (this.color != '') {
                    style['color'] = this.color;
                }
            }
            return style;
        },
    },
    mounted() {
        if (this.linkType == LinkType.PIC_TEXT) {
            this.fixImageHeight(`module${this.moduleId}`);
        }
    },
    methods: {
        linkText(item) {
            return item[DataList.TEXT];
        },
        linkTarget(item) {
            let openType = item[DataList.LINK_OPEN_TYPE];
            return openType == '' || openType == 0 ? '_blank' : '_self';
        },
        fixImageHeight(moduleId, scale) {
            if (!moduleId) return;
            var module = document.getElementById(moduleId);
            if (!module) return;

            var imageDom = module.querySelectorAll('img'),
                twoCnt = 1,
                initHeight = 0,
                imgLenght = imageDom.length;
            for (var i = 0; i < imgLenght; i++) {
                (function (i) {
                    var image = new Image();
                    image.src = imageDom[i].src;
                    image.onload = function () {
                        if (image.height > initHeight) {
                            initHeight = image.height;
                        }
                        calImgMaxHeight();
                    };
                    if (image.height != 0) {
                        if (image.height > initHeight) {
                            initHeight = image.height;
                        }
                    }
                    if (twoCnt % 2 === 0 || i === imageDom.length - 1) {
                        if (initHeight > 0 && initHeight < 160) {
                            if (twoCnt % 2 === 1 && i === imageDom.length - 1) {
                                //奇数最后一个
                                imageDom[i].parentNode.style.height = initHeight / window._htmlFontSize + 'rem';
                            }
                            if (twoCnt % 2 === 0) {
                                //偶数
                                for (var j = 0; j < 2; j++) {
                                    imageDom[i + j - 1].parentNode.style.height =
                                        initHeight / window._htmlFontSize + 'rem';
                                }
                            }
                        }

                        initHeight = 0;
                    }
                    ++twoCnt;
                })(i);
            }

            if (scale) {
                calImgMaxHeight();
            }
            function calImgMaxHeight() {
                var maxHeight = 0,
                    imgClassH = 0,
                    photoListImg = jm('#' + moduleId).find('.photoListImg'),
                    imgClass_160 = jm('#' + moduleId).find('.imgClass_160');
                photoListImg.each(function () {
                    imgClassH = parseInt(jm(this).height());
                    if (imgClassH > maxHeight) {
                        maxHeight = imgClassH;
                    }
                });
                if (maxHeight != 0) {
                    imgClass_160.css('height', maxHeight / window._htmlFontSize + 'rem');
                }
            }
        },
    },
};
</script>
<style lang="scss">
.linkArea {
    text-align: left;
}
.linkArea .link-h-container {
    float: left;
    padding: 0.7rem;
    display: inline-block;
}
.linkArea .link-v-container {
    display: block;
    line-height: 1.75rem;
    padding: 0 0.7rem;
}

.linkArea .link-p-container {
    margin-top: 0.25rem;
    display: inline-block;
    text-align: center;
    width: 40%;
    vertical-align: top;
}

.linkArea .link-p-container img {
    display: inline-block;
    vertical-align: bottom;
}

.linkArea .link-p-container {
    width: 45%;
    margin: 0.25rem 2.5%;
}
</style>
