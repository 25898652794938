var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mobi_vote_radio" },
    _vm._l(_vm.voteItem.itemList, function (item) {
      return _c(
        "div",
        {
          key: _vm.radioKey(item),
          staticClass: "choice_box_wrap",
          class: _vm.itemWrapClass,
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.imgType,
                  expression: "imgType",
                },
              ],
              staticClass: "vote_item_content_img",
              on: {
                click: function ($event) {
                  return _vm.imgClick($event, item.id)
                },
              },
            },
            [
              _c("img", {
                staticClass: "style1Img vote_item_img",
                attrs: { src: item.imgPath || _vm.module.extInfo.defaultpPath },
              }),
            ]
          ),
          _vm._v(" "),
          _c(
            "label",
            {
              staticClass: "m_radio_wrap choice_box",
              class: {
                m_radio_checked: _vm.currentValue === item.id,
              },
            },
            [
              _c("span", { staticClass: "m_radio" }, [
                _c("span", {
                  staticClass: "m_vote_radio_inner m_radio_inner icon-",
                  class: { active: _vm.currentValue === item.id },
                }),
                _vm._v(" "),
                _c("input", {
                  staticClass: "m_radio_input",
                  attrs: {
                    id:
                      "voteItem" +
                      _vm.module.content.voteId +
                      "i" +
                      _vm.voteItem.id +
                      item.id +
                      _vm.module.id,
                    type: "radio",
                  },
                  domProps: {
                    checked: _vm.currentValue === item.id,
                    value: item.id,
                  },
                  on: { change: _vm.change },
                }),
              ]),
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(item.name))]),
            ]
          ),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }