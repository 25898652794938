var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "photoModule newCarouselMultiPhoto styleForm8",
      class: _vm.photoSwipeCls,
    },
    [
      _c(
        "div",
        {
          ref: "photoSwipe8",
          staticClass: "J_photoSwipe photoSwipe",
          style: _vm.photoSwipeStyle,
          attrs: { id: "photoSwipe" + _vm.module.id },
          on: {
            touchstart: _vm.touchstart,
            touchmove: _vm.touchmove,
            touchend: _vm.touchend,
          },
        },
        [
          _vm.mhighs.carouselSet.carouselType == 1 &&
          (_vm.carouselAni == 3 || _vm.carouselAni == 4 || _vm.carouselAni == 5)
            ? _c(
                "div",
                {
                  staticClass: "autoSwipeBox",
                  style: _vm.autoSwipeBoxStyle,
                  attrs: { id: "autoSwipeBox" },
                },
                _vm._l(_vm.cycleList, function (item, index) {
                  return _c("new-carousel-image-item", {
                    key: item.picid,
                    ref: "photoImageItem",
                    refInFor: true,
                    class: _vm.autoSwipeClass(index),
                    style: _vm.autoSwipeItemStyle,
                    attrs: {
                      item: item,
                      "item-index": index,
                      "no-lazy-load": _vm.noLazyLoad,
                    },
                  })
                }),
                1
              )
            : _c(
                "div",
                {
                  ref: "photoSwipeBox",
                  staticClass: "photoSwipeBox",
                  class: _vm.photoSwipeReset,
                  style: _vm.photoSwipeBoxStyle,
                  attrs: { id: "photoSwipeBox" },
                },
                [
                  _vm._l(_vm.cycleList, function (item, index) {
                    return _c("new-carousel-image-item", {
                      key: item.picid,
                      ref: "photoImageItem",
                      refInFor: true,
                      style: _vm.itemStyle,
                      attrs: {
                        item: item,
                        "item-index": index,
                        "no-lazy-load": _vm.noLazyLoad,
                      },
                    })
                  }),
                  _vm._v(" "),
                  _c("new-carousel-image-item", {
                    style: _vm.itemStyle,
                    attrs: {
                      item: _vm.cycleList[0],
                      "item-index": 0,
                      "no-lazy-load": _vm.noLazyLoad,
                    },
                  }),
                ],
                2
              ),
          _vm._v(" "),
          _vm.cycleList.length
            ? _c("img-bullet-group", {
                attrs: {
                  "current-index": _vm.currentIndex,
                  "bullet-length": _vm.cycleList.length,
                  "bullet-group-style": _vm.bulletGroupStyle,
                },
                on: { "bullet-click": _vm.upDateSwipeIndex },
              })
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }