<template>
    <div class="m_vote_item" :class="typeClass">
        <div class="vote_item_name" :class="questionWrapClass"
            ><span class="vote_item_name_text g_vote_question" :style="vote_question_style">{{
                voteItem.question
            }}</span></div
        >
        <div class="separator g_dashed"></div>
        <div class="vote_item_content g_vote_Item" :style="vote_item_style">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'VoteItem',
    inject: ['module', 'options'],
    props: {
        voteItem: { type: Object, default: () => ({}) },
    },
    computed: {
        imgType() {
            return this.voteItem.v_type === 'img';
        },
        typeClass() {
            return this.imgType ? 'img_box' : 'text_box';
        },
        questionWrapClass() {
            return this.module.content.qwrap == 2 ? 'voteQuestionNowrap' : '';
        },
        vote_question_style: function () {
            if (!(VITE_APP_MODE !== 'visitor')) {
                return '';
            }
            var voteQuestion = this.module.content.mhighs.vqs;

            var style = '';
            if (voteQuestion.type == 1) {
                if (voteQuestion.nfs) {
                    style += 'font-size:' + voteQuestion.nfs + 'rem;';
                } else {
                    style += 'font-size:12px;';
                }
                if (voteQuestion.nfct == 1) {
                    style += 'color:' + voteQuestion.nfc + ';';
                }
            }
            return style;
        },
        vote_item_style: function () {
            if (!(VITE_APP_MODE !== 'visitor')) {
                return '';
            }
            var voteItem = this.module.content.mhighs.vis;
            var style = '';
            if (voteItem.type == 1) {
                if (voteItem.nfs) {
                    style += 'font-size:' + voteItem.nfs + 'rem;';
                } else {
                    style += 'font-size:12px;';
                }
                if (voteItem.nfct == 1) {
                    style += 'color:' + voteItem.nfc + ';';
                }
            }
            return style;
        },
    },
    mounted() {
        this.dispatch('Vote', 'on-vote-item-add', this.$children[0]);
    },
    methods: {
        dispatch(componentName, eventName, params) {
            let parent = this.$parent || this.$root;
            let name = parent.$options.name;

            while (parent && (!name || name !== componentName)) {
                parent = parent.$parent;

                if (parent) {
                    name = parent.$options.name;
                }
            }
            if (parent) {
                parent.$emit.apply(parent, [eventName].concat(params));
            }
        },
    },
};
</script>

<style></style>
