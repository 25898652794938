var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("module-frame", { attrs: { "module-id": _vm.moduleId } }, [
    _vm.render
      ? _c(
          "div",
          { staticClass: "news_list_wrap", class: _vm.isNewAddModuleClass },
          [
            (!_vm.newsListEmpty && !_vm.choiceNewsEmpty) || !_vm.manageMode
              ? _c(
                  "div",
                  {
                    staticClass: "newsList news_list_module",
                    class: _vm.classes,
                    attrs: { id: "newsList" + _vm.module.id },
                  },
                  [
                    _c(
                      "div",
                      {
                        class: _vm.wrapClasses,
                        attrs: {
                          id: _vm.wrapId,
                          picNum: _vm.module.content.pn,
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            class: _vm.wrapClasses2,
                            attrs: { picNum: _vm.module.content.pn },
                          },
                          [
                            _c(_vm.styleComponent(), {
                              tag: "component",
                              attrs: {
                                "news-list": _vm.newsList,
                                "no-lazy-load": _vm.noLazyLoad,
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]
                )
              : _vm.manageMode && _vm.newsListEmpty
              ? _c(
                  "div",
                  { staticClass: "empty_tips_panel" },
                  [
                    _c("div", { staticClass: "text" }, [
                      _vm._v("您还没有添加任何文章，赶紧添加吧~"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "button-component",
                      {
                        attrs: { active: "" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.addNew.apply(null, arguments)
                          },
                        },
                      },
                      [_vm._v("添加文章")]
                    ),
                  ],
                  1
                )
              : _vm.manageMode && _vm.choiceNewsEmpty
              ? _c(
                  "div",
                  { staticClass: "empty_tips_panel" },
                  [
                    _c("div", { staticClass: "text" }, [
                      _vm._v("请从右侧面板中选择文章~"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "button-component",
                      {
                        attrs: { active: "" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.addNew.apply(null, arguments)
                          },
                        },
                      },
                      [_vm._v("添加文章")]
                    ),
                    _vm._v(" "),
                    _c(
                      "button-component",
                      {
                        staticStyle: { "margin-left": "20px" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.openDesigner.apply(null, arguments)
                          },
                        },
                      },
                      [_vm._v("选择文章")]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.module.content.pageIcon &&
            _vm.module.content.pn != 5 &&
            _vm.module.content.pn != 12
              ? _c("pagenation", {
                  attrs: {
                    "module-id": _vm.module.id,
                    "total-size": _vm.totalSize,
                    "page-size": _vm.module.content.c,
                    pageno: _vm.pageno,
                    "open-theme-v3": _vm.openThemeV3,
                    "ajax-pagenation": true,
                  },
                  on: { "on-change": _vm.changePage },
                  nativeOn: {
                    click: function ($event) {
                      $event.stopPropagation()
                    },
                  },
                })
              : _vm._e(),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }