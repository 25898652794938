var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mobi_form_checkbox choice_box_wrap" },
    _vm._l(_vm.checkboxGroup, function (name) {
      return _c(
        "label",
        {
          key: _vm.radioKey(name),
          staticClass: "m_checkbox_wrap choice_box",
          class: {
            m_checkbox_checked: _vm.currentValue.includes(name),
            form_item_vertical: _vm.module.content.od == 1,
            disabled: _vm.isZeroResidue(name),
          },
        },
        [
          _c("span", { staticClass: "m_checkbox" }, [
            _c("span", {
              staticClass: "m_form_checkbox_inner m_checkbox_inner icon-",
              class: { active: _vm.currentValue.includes(name) },
            }),
            _vm._v(" "),
            _c("input", {
              staticClass: "m_checkbox_input",
              attrs: { type: "checkbox", disabled: _vm.isZeroResidue(name) },
              domProps: {
                checked: _vm.currentValue.includes(name),
                value: name,
              },
              on: { change: _vm.change },
            }),
          ]),
          _vm._v(" "),
          _c("span", { style: _vm.fontStyle }, [_vm._v(" " + _vm._s(name))]),
          _vm._v(" "),
          _vm.getResidueText(name) != ""
            ? _c(
                "span",
                { staticClass: "residue_text", style: _vm.fontStyle },
                [_vm._v(_vm._s(_vm.getResidueText(name)))]
              )
            : _vm._e(),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }