<template>
    <span class="newTextGroup">
        <template v-if="content.po == 1">
            <!-- 如果开启前缀 -->
            <div class="searchResultLine prefixOpenLine">
                <strong class="prefixOpenName">[{{ groupName }}]</strong>
            </div>
        </template>
        <div v-if="isClassifiedWrap" class="classifiedWrap">
            <new-text-item v-for="(list, index) in item.list" :key="`group_${index}`" :item="list" />
        </div>
        <template v-else>
            <new-text-item v-for="(list, index) in item.list" :key="`group_${index}`" :item="list" />
        </template>
        <div v-if="!last" class="searchSeparation"></div>
    </span>
</template>

<script>
import newTextItem from './newTextItem.vue';
import { getGroupNameLs } from '../../util';
import { mapState } from 'vuex';
export default {
    name: 'TextItemGroup',
    components: {
        newTextItem,
    },
    inject: ['content'],
    props: ['item', 'last'],
    data() {
        return {};
    },
    computed: {
        ...mapState('app', ['LS']),
        searchFlag() {
            return this.item.searchFlag;
        },
        groupName() {
            return getGroupNameLs(this.searchFlag, this.LS);
        },
        groupList() {
            return this.item.list;
        },
        isClassifiedWrap() {
            // 有图样式
            return [1, 3, 5].includes(this.searchFlag);
        },
    },
    methods: {},
};
</script>
<style lang="scss">
/***************************************************全站搜索样式二  start***************************************************/
.newsList .prefixOpenLine {
    height: 2rem;
    box-sizing: border-box;
    line-height: 2rem;
    display: flex;
    align-items: center;
    border-bottom: 0.025rem solid #f3f3f3;
    position: relative;
    padding-left: 1.15rem;
    font-size: 0.7rem;
    color: #333333;
}
.newsList .textItemContent {
    width: 100%;
    height: 2rem;
    position: relative;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    border-bottom: 0.025rem solid #f3f3f3;
    padding-left: 0.75rem;
}
.newsList .prefixOpenLine:before {
    content: '';
    position: absolute;
    width: 0.2rem;
    height: 0.2rem;
    top: 50%;
    left: 0.75rem;
    background: #666666;
    transform: translateY(-50%);
    border-radius: 100%;
}
.newsList .classifiedWrap {
    display: flex;
    flex-wrap: wrap;
}
.newsList .searchResultLine.pic {
    width: 50%;
    display: inline-flex;
    align-items: center;
    padding: 0.625rem 0rem 0.75rem 0;
    flex-direction: column;
    box-sizing: border-box;
}
.newsList .searchResultLine.pic.fixedPicConetent {
    padding: 0.625rem 0rem 0.75rem 0;
    box-sizing: border-box;
}

.newsList .searchResultLine .prefixOpenName {
    padding-left: 0.25rem;
    flex-shrink: 0;
}
.newsList .searchResultLine .pic {
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
}
.newsList .searchFlagList {
    padding-left: 0.5rem;
}
.newsList .searchSeparation {
    width: 100%;
    height: 0.5rem;
    background-color: #f5f5f5;
}
.newsList .newTextGroup:last-child .searchSeparation {
    display: none;
}

/*图文区*/
.newsList .searchResultLine .pic img {
    border-radius: 0.2rem;
}
.newsList .smallSizePic img {
    width: 7rem;
    height: 5.5rem;
}
.newsList .bigSizePic img {
    width: 7rem;
    height: 7rem;
}
.newsList .highlightText {
    color: #f23030;
}

/*产品*/
.newsList .resultDetail {
    margin-top: 0.45rem;
    padding-left: 0.125rem;
}
.newsList .picDetailAb {
    width: 100%;
    height: 1.25rem;
    position: absolute;
    box-sizing: border-box;
    bottom: 0;
    color: #ffffff;
    font-size: 0.6rem;
    background: rgba(0, 0, 0, 0.4);
    display: flex;
    align-items: center;
    border-bottom-right-radius: 0.2rem;
    border-bottom-left-radius: 0.2rem;
}
.newsList .resultDetail .detailSpan {
    display: block;
    max-width: 6.75rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.newsList .resultDetail .detailSpanInline {
    display: inline-block;
}
.newsList .resultDetail .proPrice {
    font-size: 0.6rem;
    color: #666666;
    margin-top: 0.5rem;
    max-width: 6.75rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.newsList .resultDetail .proName,
.newsList .resultDetail .picName {
    font-size: 0.7rem;
    color: #333333;
}
.newsList .proSeparation {
    width: 100%;
    height: 0.625rem;
    background-color: #ffffff;
}

/*图册*/
.newsList .picDetailAb .phgName {
    position: absolute;
    left: 0.5rem;
    max-width: 4.625rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.newsList .picDetailAb .phgSize {
    position: absolute;
    right: 0.4rem;
}

/*文章*/
.newsList .textItemContent .word {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}
.newsList .icon-download:after {
    font-family: icomoon;
    content: '\eb1e';
    color: #666;
    position: absolute;
    top: 1em;
    right: 0.4rem;
}
.newsList .textItemContent .textSpan {
    max-width: 90%;
    height: 100%;
    line-height: 2rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
</style>
