var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("module-frame", { attrs: { "module-id": _vm.moduleId } }, [
    _vm.render && (_vm.dataList.length || _vm.manageMode)
      ? _c(
          "div",
          {
            class: _vm.panelCls,
            attrs: { id: "noticeContainer" + _vm.module.id },
          },
          [
            !_vm.dataList.length && _vm.manageMode
              ? [
                  _c(
                    "div",
                    { staticClass: "empty_tips_panel" },
                    [
                      _c("div", { staticClass: "text" }, [
                        _vm._v("请在右侧面板添加公告"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "button-component",
                        {
                          attrs: { active: "" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.addNotice.apply(null, arguments)
                            },
                          },
                        },
                        [_vm._v("添加公告")]
                      ),
                    ],
                    1
                  ),
                ]
              : _vm._e(),
            _vm._v(" "),
            _vm.dataList.length
              ? [
                  _vm.showNewPane
                    ? _c("div", { class: _vm.newPaneCls, style: _vm.newStyle })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.direction === "left" || _vm.direction === "right"
                    ? [
                        _vm.noticeType == 5 && _vm.isFontIcon
                          ? _c("div", {
                              staticClass: "noticeFontIcon",
                              class: _vm.classname,
                              style: "color:" + _vm.color,
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.noticeType == 5 && !_vm.isFontIcon
                          ? _c("img", {
                              staticClass: "J_img_lazyload noticeImg",
                              attrs: {
                                "src-original": _vm.filePath,
                                src: _vm.loadingPath,
                                alt: "",
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.noticeType == 10 && !_vm.isFontIcon
                          ? _c("div", { staticClass: "newNoticeImg" }, [
                              _c("img", {
                                staticClass: "J_img_lazyload",
                                attrs: {
                                  "src-original": _vm.filePath,
                                  src: _vm.loadingPath,
                                  alt: "",
                                },
                              }),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.noticeType == 10 && _vm.isFontIcon
                          ? _c("div", {
                              staticClass: "newNoticeIcon",
                              class: _vm.classname,
                              staticStyle: { "font-size": "1.05rem" },
                              style: "color:" + _vm.color,
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "noticeMarquee",
                            staticStyle: { position: "relative" },
                            attrs: { id: "noticeMarquee" + _vm.module.id },
                          },
                          [
                            _c("div", { staticClass: "noticeMarqueeDiv" }, [
                              _c(
                                "div",
                                { staticClass: "noticeMarqueeRow" },
                                [
                                  _vm._l(_vm.dataList, function (item) {
                                    return [
                                      _c("span", [_vm._v(" ")]),
                                      _vm._v(" "),
                                      item.o === 1
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "g_notice_text",
                                              style: _vm.notice_text_style,
                                              attrs: { nowrap: "" },
                                            },
                                            [_vm._v(_vm._s(item.t))]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      item.o === 0
                                        ? _c(
                                            "span",
                                            { attrs: { nowrap: "" } },
                                            [
                                              _c(
                                                "a",
                                                {
                                                  staticClass: "g_notice_link",
                                                  style: _vm.notice_link_style,
                                                  attrs: {
                                                    hidefocus: "true",
                                                    href: item.jumpUrl,
                                                    target: item.p
                                                      ? "_self"
                                                      : "_blank",
                                                    rel: item.nf
                                                      ? "nofollow"
                                                      : "",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.jumpOnclickStr(
                                                        item
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v(_vm._s(item.t))]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c("span", [_vm._v(" ")]),
                                    ]
                                  }),
                                ],
                                2
                              ),
                            ]),
                          ]
                        ),
                      ]
                    : [
                        _vm.noticeType == 5 && _vm.isFontIcon
                          ? _c("div", {
                              staticClass: "noticeFontIcon",
                              class: _vm.classname,
                              staticStyle: { "margin-left": "-21px" },
                              style: "color:" + _vm.color,
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.noticeType == 5 && !_vm.isFontIcon
                          ? _c("img", {
                              staticClass: "noticeImg J_img_lazyload",
                              staticStyle: { "margin-left": "-21px" },
                              attrs: {
                                "src-original": _vm.filePath,
                                src: _vm.loadingPath,
                                alt: "",
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.noticeType == 10 && !_vm.isFontIcon
                          ? _c("div", { staticClass: "newNoticeImg" }, [
                              _c("img", {
                                staticClass: "J_img_lazyload",
                                attrs: {
                                  "src-original": _vm.filePath,
                                  src: _vm.loadingPath,
                                  alt: "",
                                },
                              }),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.noticeType == 10 && _vm.isFontIcon
                          ? _c("div", {
                              staticClass: "newNoticeIcon",
                              class: _vm.classname,
                              staticStyle: { "font-size": "1.05rem" },
                              style: "color:" + _vm.color,
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "noticeMarqueeUD",
                            class: _vm.newStyleCls,
                            attrs: { id: "noticeMarquee" + _vm.module.id },
                          },
                          [
                            _c(
                              "ul",
                              {
                                staticClass: "noticeScrollbar",
                                attrs: {
                                  id: "noticeScrollbar" + _vm.module.id,
                                },
                              },
                              [
                                _c(
                                  "a",
                                  {
                                    staticStyle: { "text-decoration": "none" },
                                  },
                                  [_vm._v(" ")]
                                ),
                                _vm._v(" \n                        "),
                                _vm.moduleStyle === 2
                                  ? [
                                      _vm._l(_vm.dataList, function (item) {
                                        return [
                                          item.o === 1
                                            ? _c(
                                                "li",
                                                {
                                                  staticClass:
                                                    "scrollbarLi g_notice_text",
                                                  style: _vm.notice_text_style,
                                                },
                                                [
                                                  _vm._v(_vm._s(item.t)),
                                                  _c(
                                                    "a",
                                                    {
                                                      staticStyle: {
                                                        "text-decoration":
                                                          "none",
                                                      },
                                                    },
                                                    [_vm._v(" ")]
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          item.o === 0
                                            ? _c(
                                                "li",
                                                { staticClass: "scrollbarLi" },
                                                [
                                                  _c(
                                                    "a",
                                                    {
                                                      class:
                                                        _vm.noticeType >= 6 &&
                                                        _vm.noticeType <= 10
                                                          ? "noticeNewStyleContentText g_notice_link"
                                                          : "g_notice_link",
                                                      style:
                                                        _vm.notice_link_style,
                                                      attrs: {
                                                        hidefocus: "true",
                                                        href: item.jumpUrl,
                                                        target: item.p
                                                          ? "_self"
                                                          : "_blank",
                                                        rel: item.nf
                                                          ? "nofollow"
                                                          : "",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.jumpOnclickStr(
                                                            item
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("span", {
                                                        directives: [
                                                          {
                                                            name: "show",
                                                            rawName: "v-show",
                                                            value:
                                                              _vm.noticeType >=
                                                                6 &&
                                                              _vm.noticeType <=
                                                                10,
                                                            expression:
                                                              "noticeType >= 6 && noticeType <= 10",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "listStyleCircle",
                                                      }),
                                                      _vm._v(
                                                        _vm._s(item.t) + " "
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(
                                                    " \n                                "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      }),
                                    ]
                                  : _vm.moduleStyle === 1
                                  ? [
                                      _vm.dataList.length === 1
                                        ? [
                                            _vm.dataList[0].o === 1
                                              ? _c(
                                                  "li",
                                                  {
                                                    staticClass:
                                                      "scrollbarLi oneNoticeScrollbar",
                                                  },
                                                  [
                                                    _c("div", {
                                                      staticClass:
                                                        "noticeListTopMargin",
                                                    }),
                                                    _vm._v(" "),
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "noticeNewStyleContentText g_notice_text",
                                                        style:
                                                          _vm.notice_text_style,
                                                      },
                                                      [
                                                        _c("span", {
                                                          staticClass:
                                                            "listStyleCircle",
                                                        }),
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.dataList[0].t
                                                          ) +
                                                            "\n                                    "
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c("div", {
                                                      staticClass:
                                                        "noticeListBottomMargin",
                                                    }),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _vm.dataList[0].o === 0
                                              ? _c(
                                                  "li",
                                                  {
                                                    staticClass:
                                                      "scrollbarLi oneNoticeScrollbar",
                                                  },
                                                  [
                                                    _c("div", {
                                                      staticClass:
                                                        "noticeListTopMargin",
                                                    }),
                                                    _vm._v(" "),
                                                    _c(
                                                      "a",
                                                      {
                                                        staticClass:
                                                          "noticeNewStyleContentText g_notice_link",
                                                        style:
                                                          _vm.notice_link_style,
                                                        attrs: {
                                                          hidefocus: "true",
                                                          href: _vm.dataList[0]
                                                            .jumpUrl,
                                                          target: _vm
                                                            .dataList[0].p
                                                            ? "_self"
                                                            : "_blank",
                                                          rel: _vm.dataList[0]
                                                            .nf
                                                            ? "nofollow"
                                                            : "",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.jumpOnclickStr(
                                                              _vm.dataList[0]
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("span", {
                                                          staticClass:
                                                            "listStyleCircle",
                                                        }),
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.dataList[0].t
                                                          ) +
                                                            "\n                                    "
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c("div", {
                                                      staticClass:
                                                        "noticeListBottomMargin",
                                                    }),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        : _vm._l(
                                            _vm.cloneList,
                                            function (item) {
                                              return _c(
                                                "li",
                                                { staticClass: "scrollbarLi" },
                                                [
                                                  _vm._l(
                                                    item,
                                                    function (child, cIndex) {
                                                      return [
                                                        child.o === 1 &&
                                                        cIndex % 2 === 0
                                                          ? _c(
                                                              "p",
                                                              {
                                                                staticClass:
                                                                  "noticeNewStyleContentText twoNoticeContentText mode1first g_notice_text",
                                                                style:
                                                                  _vm.notice_text_style,
                                                              },
                                                              [
                                                                _c("span", {
                                                                  staticClass:
                                                                    "listStyleCircle",
                                                                }),
                                                                _vm._v(
                                                                  _vm._s(
                                                                    child.t
                                                                  ) +
                                                                    "\n                                        "
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        _vm._v(" "),
                                                        child.o === 1 &&
                                                        cIndex % 2 === 1
                                                          ? _c(
                                                              "p",
                                                              {
                                                                staticClass:
                                                                  "noticeNewStyleContentText twoNoticeContentText mode1sec g_notice_text",
                                                                style:
                                                                  _vm.notice_text_style,
                                                              },
                                                              [
                                                                _c("span", {
                                                                  staticClass:
                                                                    "listStyleCircle",
                                                                }),
                                                                _vm._v(
                                                                  _vm._s(
                                                                    child.t
                                                                  ) +
                                                                    "\n                                        "
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        _vm._v(" "),
                                                        child.o === 0 &&
                                                        cIndex % 2 === 0
                                                          ? _c(
                                                              "a",
                                                              {
                                                                staticClass:
                                                                  "noticeNewStyleContentText twoNoticeContentText mode0first g_notice_link",
                                                                style:
                                                                  _vm.notice_link_style,
                                                                attrs: {
                                                                  hidefocus:
                                                                    "true",
                                                                  href: child.jumpUrl,
                                                                  target:
                                                                    child.p
                                                                      ? "_self"
                                                                      : "_blank",
                                                                  rel: child.nf
                                                                    ? "nofollow"
                                                                    : "",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.jumpOnclickStr(
                                                                        child
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c("span", {
                                                                  staticClass:
                                                                    "listStyleCircle",
                                                                }),
                                                                _vm._v(
                                                                  _vm._s(
                                                                    child.t
                                                                  ) +
                                                                    "\n                                        "
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        _vm._v(" "),
                                                        child.o === 0 &&
                                                        cIndex % 2 === 1
                                                          ? _c(
                                                              "a",
                                                              {
                                                                staticClass:
                                                                  "noticeNewStyleContentText twoNoticeContentText mode0sec g_notice_link",
                                                                style:
                                                                  _vm.notice_link_style,
                                                                attrs: {
                                                                  hidefocus:
                                                                    "true",
                                                                  href: child.jumpUrl,
                                                                  target:
                                                                    child.p
                                                                      ? "_self"
                                                                      : "_blank",
                                                                  rel: child.nf
                                                                    ? "nofollow"
                                                                    : "",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.jumpOnclickStr(
                                                                        child
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c("span", {
                                                                  staticClass:
                                                                    "listStyleCircle",
                                                                }),
                                                                _vm._v(
                                                                  _vm._s(
                                                                    child.t
                                                                  ) +
                                                                    "\n                                        "
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    }
                                                  ),
                                                ],
                                                2
                                              )
                                            }
                                          ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ]
                        ),
                      ],
                ]
              : _vm._e(),
          ],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }