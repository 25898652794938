<template>
    <div class="file_upload_container2">
        <div class="preview_file_container">
            <ul class="preview_file_list">
                <li
                    v-for="fileItem in uploadPreviewFileList"
                    :id="fileItemId(fileItem)"
                    :key="fileItem.key"
                    class="preview_file_item"
                >
                    <div class="preview_file_item_box preview_file_item_content">
                        <img
                            v-if="fileItem.fileType == 'image'"
                            class="preview_file_item_box--img"
                            :src="fileItem.src"
                            :alt="fileItem.title"
                            @click="openPhotoSlidesshow(fileItem.key)"
                        />
                        <a
                            v-else-if="fileItem.fileType == 'pdf'"
                            target="_blank"
                            :class="fileIconClass(fileItem.fileType)"
                            class="preview_file_item_box--pdf preview_file_item_box--icon"
                            :href="fileItem.src"
                        >
                            <svg class="file_icon">
                                <use :xlink:href="currentFileIcon(fileItem.fileType)" />
                            </svg>
                        </a>
                        <i
                            v-else
                            :class="fileIconClass(fileItem.fileType)"
                            class="preview_file_item_box--icon preview_file_item_box--other"
                        >
                            <svg class="file_icon">
                                <use :xlink:href="currentFileIcon(fileItem.fileType)" />
                            </svg>
                        </i>
                    </div>
                    <div class="preview_file_item--container preview_file_item_content">
                        <a
                            v-if="fileItem.fileType == 'pdf'"
                            class="preview_file_item_title preview_file_item_title--pdf"
                            target="_blank"
                            :href="fileItem.src"
                        >
                            {{ fileItem.title || '' }}
                        </a>
                        <p v-else class="preview_file_item_title">{{ fileItem.title || '' }}</p>

                        <p v-show="fileItem.status == 'success'" class="preview_file_item_des">{{
                            previewSize(fileItem.size)
                        }}</p>
                    </div>
                    <div class="preview_file_item--action preview_file_item_content">
                        <svg class="preview_file_item_del action_icon" @click="delUploadFile(fileItem.key)">
                            <use xlink:href="#jzm-vicon_delete" />
                        </svg>
                    </div>
                </li>
            </ul>
        </div>
        <div
            v-show="uploadPreviewFileList.length < fileNumLimit"
            ref="uploadBtn2"
            class="uploadify-button2"
            @click.stop="uploadClick"
        >
            <span>
                <svg class="icon_add" @click="delUploadFile(fileItem.key)">
                    <use xlink:href="#icon_add" />
                </svg>
                {{ extInfo.clickToUpload }}
            </span>
            <span v-if="showFileLimitTips">（{{ fileLimitTips }}）</span>
        </div>
        <image-viewer
            v-if="showMobiViewer"
            :z-index="zIndex"
            :initial-index="initialIndex"
            :on-close="onClose"
            :url-list="urlList"
            :is-mobi="true"
            :content-terminal="'mobi'"
            :manage-mode="manageMode"
            :log-event-config="logEventConfig"
            :document-event-el="documentEventEl"
            append-to-container-id="g_body"
            :append-to-body="false"
        />
    </div>
</template>

<script>
import { slidesShowDataMixins } from '@/modules/moduleMixins';
import { previewFile, getPreviewSize } from '@jz/biz-shared';
import { context } from '@jz/utils';
import { mapState } from 'vuex';
import { format } from '@/shared/fai';
import { JZUpload } from '@jz/biz-shared';

export default {
    name: 'FormUpload',
    mixins: [slidesShowDataMixins],
    props: {
        formItem: {
            type: Object,
            default: () => ({}),
        },
        extInfo: {
            type: Object,
            default: () => ({}),
        },
        logEventConfig: {
            type: Object,
            default: () => ({
                event: 'jz_hdpzj',
                properties: {
                    jz_content_terminal: 'mobi-在线表单',
                    jz_version: context?.jzVersion ?? 0,
                },
            }),
        },
        contentId: {
            type: Number,
            default: 0,
        },
        initialValue: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            currentValue: '',
            uploadPreviewFileList: [],
            sumbitFileList: [],
            showMobiViewer: false,
            tmpFileList: [],
        };
    },
    computed: {
        ...mapState('app', ['LS', 'lcid']),
        showFileLimitTips() {
            return this?.lcid === 2052;
        },
        urlList() {
            return this.uploadPreviewFileList.filter((item) => item.fileType === 'image');
        },
        fileLimitTips() {
            return this.format?.(this?.LS?.siteFormUploadTips ?? '', this?.fileNumLimit, this?.fileLimitSize);
        },
        fileNumLimit() {
            return this?.formItem?.fileNumLimit ?? 1;
        },
        fileLimitSize() {
            let fileSize = this?.formItem?.size;
            let fileLimitSize = this?.extInfo?.fileSizeLimit || 300;

            if (this?.extInfo?.oem) {
                if (this?.extInfo?.siteVer <= 120) {
                    fileLimitSize = 10;
                }
            } else {
                if (this?.extInfo?.siteVer <= 10) {
                    fileLimitSize = 1;
                }
            }

            if (fileSize > fileLimitSize) {
                return fileLimitSize;
            } else {
                return fileSize;
            }
        },
        submitVal() {
            return this.currentValue;
        },
        manageMode() {
            return VITE_APP_MODE !== 'visitor';
        },
    },
    watch: {
        initialValue: {
            deep: true,
            immediate: true,
            handler() {
                this.setInitialValue();
            },
        },
    },
    mounted() {
        this.initUpload();
    },
    methods: {
        openPhotoSlidesshow(key) {
            let initialIndex = 0;
            this.urlList?.forEach((value, index) => {
                if (value?.key === key) {
                    initialIndex = index;
                }
            });
            this.initialIndex = initialIndex;
            this.showMobiViewer = true;
        },
        onClose() {
            this.showMobiViewer = false;
        },
        currentFileIcon(fileType = 'file') {
            let result = '#jzm-v17';
            switch (fileType) {
                case 'pdf':
                    result = '#jzm-v11';
                    break;
                case 'exls':
                    result = '#jzm-v6';
                    break;
                case 'gzip':
                    result = '#jzm-v8';
                    break;
                case 'music':
                    result = '#jzm-v7';
                    break;
                case 'ppt':
                    result = '#jzm-v12';
                    break;
                case 'txt':
                    result = '#jzm-v13';
                    break;
                case 'word':
                    result = '#jzm-v15';
                    break;
                case 'video':
                    result = '#jzm-v14';
                    break;
                case 'image':
                    result = '#jzm-v9';
                    break;

                default:
                    break;
            }
            return result;
        },
        format,
        delUploadFile(key) {
            try {
                this.uploadPreviewFileList.forEach((item, index) => {
                    if (item.key === key) {
                        this.uploadPreviewFileList?.splice?.(index, 1);
                        this.sumbitFileList?.splice?.(index, 1);
                        this.tmpFileList?.splice?.(index, 1);
                    }
                });
                this.currentValue = this.sumbitFileList;
                this.$emit('del-success', this.currentValue);
            } catch (error) {
                console.log(error);
            }
        },
        initUpload() {
            if (VITE_APP_MODE !== 'visitor') {
                return;
            }
            var isOverLimit = this?.extInfo?.isOverLimit;
            var This = this;

            var fileSize = this.fileLimitSize;
            var fileTypeList = '*.*';
            if (!this.formItem.isAllFile) {
                //自定义类型
                fileTypeList = ((this.formItem.dftl || '') + (this.formItem.cftl || ''))
                    .split(' ')
                    .filter((formItem) => formItem)
                    .map((formItem) => `*.${formItem}`)
                    .join(';');
            }
            const { fileNumLimit = 1 } = this.formItem;

            JZUpload.initUploadifyButton({
                dom: this.$refs.uploadBtn2,
                fileTypeExts: fileTypeList,
                onChange: async (e) => {
                    if (isOverLimit) {
                        This.$mobiIng('已超过资源库容量限制，请升级网站版本。');
                        return false;
                    } else {
                        let file = e.target.files[0];

                        let jzUpload = new JZUpload({
                            getAccessKeyUrl: '/api/manage/advanceUpload/genAccessKey',
                            onError(error) {
                                This.$mobiIng('文件:' + file.name + '，' + error.message);
                                jm(This.$refs.uploadBtn2).find('.selectbtn').val('');
                            },
                            // 取决于后台
                            onComplete(data) {
                                previewFile(file, (e, fileUrl, fileOtherData) => {
                                    let previewFileData = {};
                                    let md5 = jm.md5(file.name + file.size + file.type + file.lastModified);
                                    if (
                                        !This.uploadPreviewFileList?.filter((fileDataItem) => fileDataItem.key === md5)
                                            ?.length
                                    ) {
                                        previewFileData = {
                                            src: (data?.fullPath && data?.fullPath?.includes?.('//')) || fileUrl, // 幻灯片key
                                            key: md5,
                                            ...fileOtherData,
                                            title: file.name, // 幻灯片key
                                            size: file.size,
                                            type: file.type,
                                            status: 'success',
                                            originalFile: file,
                                            fileId: data.fileId,
                                        };
                                        if (This.uploadPreviewFileList.length < fileNumLimit) {
                                            This.uploadPreviewFileList.push(previewFileData);
                                            This.sumbitFileList.push(data.fileId);
                                            This.tmpFileList.push({
                                                fileId: data.fileId,
                                                tmpFileName: data.name,
                                                tmpFileId: data.id,
                                                fileType: data.type || 0,
                                                fileWidth: data.width || 0,
                                                fileHeight: data.height || 0,
                                            });
                                        }
                                    } else {
                                        This.uploadPreviewFileList?.forEach((fileDataItem) => {
                                            if (fileDataItem.key === file.md5) {
                                                fileDataItem.status = 'success';
                                                previewFileData = fileDataItem;
                                            }
                                        });
                                    }
                                });

                                This.currentValue = This.sumbitFileList;
                                This.$emit('upload-success', {
                                    currentValue: This.currentValue,
                                    tmpFileList: This.tmpFileList,
                                });
                                setTimeout(function () {
                                    This.$mobiIng(LS.fileUploadSuccess, true);
                                }, 1000); //刚好移除Mobi.ing()组件
                                jm(This.$refs.uploadBtn2).find('.selectbtn').val('');
                            },
                            onNext(info) {
                                const percent = Math.ceil(info.total.percent);
                                if (isNaN(percent)) {
                                    return;
                                }
                                if (percent == 100) {
                                    This.$mobiIng(LS.mobiFormSubmitFileUploadIng + percent + '%', true);
                                } else {
                                    This.$mobiIng(LS.mobiFormSubmitFileUploadIng + percent + '%', 3, true);
                                }
                            },
                            // faiupload的params
                            params: {
                                aid: Fai.top._aid,
                                folderId: Fai.top._siteId,
                                bssInfo: {
                                    siteId: Fai.top._siteId || 0,
                                    fileSizeLimit: fileSize,
                                    uploadType: 'siteFormFile',
                                },
                                accessKeyInfo: {
                                    isTmp: true,
                                },
                            },
                            // 必填，用于内部业务提示
                            tipsFn(string) {
                                This.$mobiIng(string);
                            },
                            fileTypeExts: fileTypeList,
                            uploadConfig: {},
                        });
                        jzUpload.upload(file);
                    }
                },
            });
        },
        fileIconClass(fileType = 'file') {
            return `preview_file_icon--${fileType}`;
        },
        previewSize(size = 0) {
            return getPreviewSize(size);
        },
        fileItemId(previewFileData) {
            const fileItemId = `${previewFileData.key}_${this?.contentId ?? 0}_${this?.formItem?.id ?? 0}`;
            return fileItemId;
        },
        resetValue() {
            this.uploadPreviewFileList = [];
            this.tmpFileList = [];
            this.sumbitFileList = [];
            this.currentValue = '';
        },
        uploadClick() {
            if (VITE_APP_MODE !== 'visitor') {
                Fai.ing('当前为管理状态，提交无效。');
            } else {
                jm(this.$refs.uploadBtn2)?.find?.('.selectbtn')?.[0]?.click?.();
            }
        },
        setInitialValue() {
            this.uploadPreviewFileList = this.initialValue?.previewFiles ?? [];
            this.sumbitFileList = this.uploadPreviewFileList.map((file) => file.fileId);
        },
    },
};
</script>

<style lang="scss">
.file_upload_style2 {
    .m_form_item.form_item_wrap_7 {
        .form_item_content {
            margin-top: 0.55rem;
        }
    }
}
.file_upload_container2 {
    .icon_add {
        width: 0.5rem;
        height: 0.5rem;
        margin-right: 0.3rem;
        color: #666666;
    }
    .preview_file_container {
        .preview_file_list {
            .preview_file_item {
                display: flex;
                cursor: default;
                position: relative;
                width: 100%;
                border-radius: 2px;
                padding: 0.5rem 0.75rem 0.5rem 0;
                box-sizing: border-box;
                align-items: center;
                &:not(:last-of-type) {
                    border-bottom: 1px solid #eee;
                }
                .preview_file_item--container {
                    margin-left: 0.5rem;
                    min-width: 80%;
                    max-width: 80%;
                }
                .preview_file_item_del {
                    width: 0.725rem;
                    height: 0.725rem;
                }
                .preview_file_item_title {
                    font-size: 0.6rem;
                    color: #333333;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    word-wrap: break-word;
                    word-break: break-all;
                    white-space: normal;
                    -webkit-line-clamp: 2;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                }
                .preview_file_item_des {
                    font-size: 0.5rem;
                    color: #999;
                }
                .preview_file_item--action {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                .preview_file_item_box {
                    width: 1.8rem;
                    height: 1.8rem;
                    min-width: 1.8rem;
                    min-height: 1.8rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 4px;
                    &--img {
                        border-radius: 4px;
                        width: 1.8rem;
                        height: 1.8rem;
                        max-width: auto;
                    }
                    &--icon {
                        width: 1.2rem;
                        height: 1.2rem;
                        .file_icon {
                            width: 100%;
                            height: 100%;
                        }
                        &.preview_file_icon {
                            fill: #fff;
                            &--pdf {
                                color: #ff5a5a;
                            }
                            &--ppt {
                                color: #ff9743;
                            }
                            &--video {
                                color: #707ee5;
                            }
                            &--image {
                                color: #23c786;
                            }
                            &--music {
                                color: #1cbe89;
                            }
                            &--gzip {
                                color: #5095ed;
                            }
                            &--txt {
                                color: #3686fd;
                            }
                            &--word {
                                color: #4e99ff;
                            }
                            &--exls {
                                color: #2c9660;
                            }
                            &--file {
                                color: #b9c9d6;
                            }
                        }
                    }
                }
            }
        }
    }
    .uploadify-button2 {
        cursor: pointer;
        height: 1.88rem;
        background: #fff;
        border: 1px dashed #c7c7c7;
        border-radius: 2px;
        line-height: 1.88rem;
        text-align: center;
        color: #666;
        font-size: 0.6rem;
        text-overflow: ellipsis;
        overflow: hidden;
        word-wrap: break-word;
        word-break: break-all;
        white-space: normal;
        -webkit-line-clamp: 1;
        display: -webkit-box;
        -webkit-box-orient: vertical;
    }
}
</style>
