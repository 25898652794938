var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "select_style" }, [
    _c("input", {
      staticClass: "select_input input_style",
      attrs: { readonly: "", unselectable: "on", placeholder: _vm.placeHolder },
      domProps: { value: _vm.currentVal },
      on: {
        click: function ($event) {
          $event.stopPropagation()
          return _vm.toggleList.apply(null, arguments)
        },
      },
    }),
    _vm._v(" "),
    _c("span", {
      staticClass: "arrow faisco-icons-S000112",
      on: {
        click: function ($event) {
          $event.stopPropagation()
          return _vm.toggleList.apply(null, arguments)
        },
      },
    }),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.list.length && _vm.showList,
            expression: "list.length && showList",
          },
        ],
        staticClass: "select_list_wrap",
      },
      [
        _c(
          "ul",
          { staticClass: "select_list" },
          _vm._l(_vm.list, function (item) {
            return _c(
              "li",
              {
                staticClass: "select_item J_noOpenDesigner",
                class: { select_active: _vm.value === item },
                on: {
                  click: function ($event) {
                    return _vm.selectItem(item)
                  },
                },
              },
              [_vm._v(_vm._s(item))]
            )
          }),
          0
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }