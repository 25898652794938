var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "photoModule styleForm7" }, [
    _c(
      "div",
      {
        staticClass: "swipe",
        attrs: { id: "photoModuleImageSwipe" + _vm.module.id },
      },
      [
        _c(
          "div",
          {
            staticClass: "bannerSwipeContainer photoModuleImageSwipeContainer",
          },
          _vm._l(_vm.cycleList, function (item, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "photoImageItem",
                attrs: { "data-picId": item.picId || item.pic },
              },
              [
                _vm.content.link === 0
                  ? _c(
                      "a",
                      {
                        staticStyle: { cursor: "default" },
                        attrs: { href: item.hrefStr2 },
                        on: {
                          click: function ($event) {
                            return _vm.photoSlidesshow(index, item)
                          },
                        },
                      },
                      [
                        _c("span", { staticClass: "imageMiddleSpan" }),
                        _vm._v(" "),
                        _c("img", {
                          class: _vm.stretchImgClass,
                          style: _vm.imgStyle(item),
                          attrs: {
                            id: item.picId,
                            "src-original": item.picPath,
                            src: _vm.imgSrc(item),
                            vwidth: item.rect_640_width,
                            vheight: item.rect_640_height,
                            alt: _vm.imgAlt(item),
                            photoIndex: index,
                          },
                        }),
                      ]
                    )
                  : _vm.content.link === 1
                  ? _c(
                      "a",
                      {
                        attrs: { hidefocus: "true" },
                        on: {
                          click: function ($event) {
                            return _vm.photoSlidesshow(index, item)
                          },
                        },
                      },
                      [
                        _c("span", { staticClass: "imageMiddleSpan" }),
                        _vm._v(" "),
                        _c("img", {
                          staticClass: "J_noOpenDesigner",
                          class: _vm.stretchImgClass,
                          style: _vm.imgStyle(item),
                          attrs: {
                            id: item.picId,
                            "src-original": item.picPath,
                            src: _vm.imgSrc(item),
                            vwidth: item.rect_640_width,
                            vheight: item.rect_640_height,
                            alt: _vm.imgAlt(item),
                            photoIndex: index,
                          },
                        }),
                      ]
                    )
                  : _vm.content.link === 2 &&
                    _vm.content.apw === 1 &&
                    item.linkType == 2 &&
                    item.jumpInfo.u != ""
                  ? _c(
                      "a",
                      {
                        attrs: { hidefocus: "true", href: item.jumpInfo.u },
                        on: {
                          click: function ($event) {
                            return _vm.photoSlidesshow(index, item)
                          },
                        },
                      },
                      [
                        _c("span", { staticClass: "imageMiddleSpan" }),
                        _vm._v(" "),
                        _c("img", {
                          staticClass: "J_noOpenDesigner",
                          class: _vm.stretchImgClass,
                          style: _vm.imgStyle(item),
                          attrs: {
                            id: item.picId,
                            "src-original": item.picPath,
                            src: _vm.imgSrc(item),
                            vwidth: item.rect_640_width,
                            vheight: item.rect_640_height,
                            alt: _vm.imgAlt(item),
                            photoIndex: index,
                          },
                        }),
                      ]
                    )
                  : _vm.content.link === 2 && item.jumpInfo.u != ""
                  ? _c(
                      "a",
                      {
                        attrs: { hidefocus: "true", href: item.jumpInfo.u },
                        on: {
                          click: function ($event) {
                            return _vm.photoSlidesshow(index, item)
                          },
                        },
                      },
                      [
                        _c("span", { staticClass: "imageMiddleSpan" }),
                        _vm._v(" "),
                        _c("img", {
                          staticClass: "J_noOpenDesigner",
                          class: _vm.stretchImgClass,
                          style: _vm.imgStyle(item),
                          attrs: {
                            id: item.picId,
                            "src-original": item.picPath,
                            src: _vm.imgSrc(item),
                            vwidth: item.rect_640_width,
                            vheight: item.rect_640_height,
                            alt: _vm.imgAlt(item),
                            photoIndex: index,
                          },
                        }),
                      ]
                    )
                  : _c(
                      "a",
                      {
                        attrs: { hidefocus: "true" },
                        on: {
                          click: function ($event) {
                            return _vm.photoSlidesshow(index, item)
                          },
                        },
                      },
                      [
                        _c("span", { staticClass: "imageMiddleSpan" }),
                        _vm._v(" "),
                        _c("img", {
                          staticClass: "J_noOpenDesigner",
                          class: _vm.stretchImgClass,
                          style: _vm.imgStyle(item),
                          attrs: {
                            id: item.picId,
                            "src-original": item.picPath,
                            src: _vm.imgSrc(item),
                            vwidth: item.rect_640_width,
                            vheight: item.rect_640_height,
                            alt: _vm.imgAlt(item),
                            photoIndex: index,
                          },
                        }),
                      ]
                    ),
              ]
            )
          }),
          0
        ),
        _vm._v(" "),
        _vm.content.showName === true
          ? _c("div", [
              _c("div", {
                staticClass: "imgName g_carouselPhoto_link",
                style: _vm.link_style,
                domProps: { innerHTML: _vm._s(_vm.imgDesc) },
              }),
            ])
          : _vm._e(),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }