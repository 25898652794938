<template>
    <module-frame :module-id="moduleId">
        <div :id="id" class="sortPanelList">
            <div v-for="(item, index) in list" :key="index" class="line j-lineH J_lineH icon-gline">
                <a class="pgName" :href="`${item.pgrUrl}`" target="_self">
                    <span class="navLineTitle g_photoGroupNav_link" :style="photoGroupNav_link_style">{{
                        item.name
                    }}</span>
                </a>
                <div class="g_separator separatorLine"></div>
            </div>
            <template v-if="!list.length && manageMode">
                <div class="empty_tips_panel">
                    <div class="text">请在右侧面板添加分类</div>
                    <button-component active @click.stop="addPhotoGroupNav">添加分类</button-component>
                </div>
            </template>
            <template v-if="!list.length && !manageMode">
                <div class="cus_Empty_tips_panel">
                    <div class="text">没有添加分类</div>
                </div>
            </template>
        </div>
    </module-frame>
</template>
<script>
import ModuleFrame from '@/modules/frame/index.vue';
import { mapGetters } from 'vuex';

export default {
    name: 'PhotoGroupNav',
    components: {
        ModuleFrame,
    },
    props: {
        moduleId: {
            type: Number,
            default: -1,
        },
    },
    computed: {
        ...mapGetters(['getModuleById']),
        module() {
            return this.getModuleById(this.moduleId);
        },
        options() {
            return this.module.renderOptions;
        },
        styleId: function () {
            return this.module.style;
        },
        id: function () {
            return `pgList${this.module.id}`;
        },
        pgType: function () {
            return this.module.content.type;
        },
        navList: function () {
            return this.module.navList || [];
        },
        idList: function () {
            return this.module.content.idList || [];
        },
        list: function () {
            if (this.pgType == 0) {
                return this.navList;
            } else {
                let result = [];
                this.navList.forEach((item) => {
                    if (this.idList.indexOf(item.id) != -1) {
                        result.push(item);
                    }
                });
                return result;
            }
        },
        photoGroupNav_link_style: function () {
            var style = '';
            if (!(VITE_APP_MODE !== 'visitor')) {
                return style;
            }
            var photoNavTextStyle = this.module.content.mhighs.pnt;
            if (photoNavTextStyle.type == 1) {
                if (photoNavTextStyle.lfs) {
                    style += 'font-size:' + photoNavTextStyle.lfs + 'rem;';
                } else {
                    style += 'font-size:12px;';
                }
                if (photoNavTextStyle.lfct == 1) {
                    style += 'color:' + photoNavTextStyle.lfc + ';';
                }
                if (photoNavTextStyle.lfd == 1) {
                    style += 'text-decoration:underline;';
                } else {
                    style += 'text-decoration:none;';
                }
            }
            return style;
        },
        manageMode: () => VITE_APP_MODE !== 'visitor',
    },
    methods: {
        addPhotoGroupNav(e) {
            e.stopPropagation();
            this.$designer.open({
                panelType: 'pattern photo-group-nav basis',
                moduleId: this.module.id,
                styleId: this.styleId,
            });
        },
    },
};
</script>
<style>
.sortPanelList .pgName {
    display: inline-block;
    float: left;
    text-decoration: none;
    outline: none;
    word-break: keep-all;
    -o-text-overflow: ellipsis;
    height: 1.9rem;
    line-height: 1.9rem;
    /*font-size: 0.7rem;*/
    font-weight: normal;
    width: 90%;
    font-family: '\5FAE\8F6F\96C5\9ED1', Helvetica, '黑体', Arial, Tahoma;
    overflow: hidden;
    white-space: normal;
    text-overflow: ellipsis;
}
.sortPanelList .navLineTitle {
    display: inline-block;
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
