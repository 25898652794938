var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "fk-productListTitle mProductList",
      class: _vm.listClass,
      attrs: { id: _vm._listId, mType: _vm.module.content.t },
    },
    [
      _vm._l(_vm.productList, function (info, index) {
        return [
          _c("muti-column-item", {
            key: "p_" + info.id,
            class: _vm.needMargin(index),
            style: _vm.marginFixStyle(index),
            attrs: {
              info: info,
              index: index,
              "row-max-height": _vm.rowMaxHeight(info, index),
              "no-lazy-load": _vm.noLazyLoad,
            },
          }),
        ]
      }),
      _vm._v(" "),
      _vm.needBlankDiv
        ? _c("div", {
            staticClass:
              "blankDiv cardWrap mProductTileFormForStyle1 mProductTileForm",
            class: _vm.needMargin(1),
            style: _vm.marginFixStyle(1),
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.needBlankDiv2
        ? _c("div", {
            staticClass:
              "blankDiv cardWrap mProductTileFormForStyle1 mProductTileForm",
            class: _vm.needMargin(false),
            style: _vm.marginFixStyle(false),
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }