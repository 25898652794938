<template>
    <div>
        <template v-for="(info, index) in newsList">
            <time-list-item :key="'n_' + info.id" :info="info" :index="index" :no-lazy-load="noLazyLoad" />
        </template>
    </div>
</template>

<script>
import TimeListItem from './time-list-item.vue';
export default {
    name: 'TimeList',
    components: { TimeListItem },
    inject: ['module', 'options'],
    props: ['newsList', 'noLazyLoad'],
};
</script>

<style></style>
