var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "newPhotoCrossedSlideSec",
      style: _vm.slideItemStyle,
      attrs: {
        photoId: _vm.photoItem.picId,
        "data-picid": _vm.photoItem.picId,
      },
    },
    [
      _c(
        "a",
        {
          attrs: {
            href: _vm.imgHref,
            rel: _vm.photoItem.nf ? "nofollow" : "",
            onclick: _vm.jumpOnclickStr,
          },
          on: { click: _vm.photoSlidesshow },
        },
        [
          _c("div", {
            directives: [
              {
                name: "drag-resize",
                rawName: "v-drag-resize",
                value: _vm._resizeOptions,
                expression: "_resizeOptions",
              },
            ],
            staticClass: "slideItemImage J_img_lazyload",
            style: _vm.photoItemStyle,
            attrs: { "src-original": _vm.photoItem.picThumbPath },
          }),
          _vm._v(" "),
          _c("div", { style: _vm.slideTextStyle }, [
            _c("div", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showTitle,
                  expression: "showTitle",
                },
              ],
              style: _vm.slideNameStyle,
              domProps: { innerHTML: _vm._s(_vm.titleData) },
            }),
            _vm._v(" "),
            _c("div", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showDesc,
                  expression: "showDesc",
                },
              ],
              style: _vm.slideDescStyle,
              domProps: { innerHTML: _vm._s(_vm.descData) },
            }),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }