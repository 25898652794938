import { MobiModule } from '../Module';
import { MODULE_STYLE } from '@/def/module';
import CarouselMultiPhotoView from './module/index.vue';

class _CarouselMultiPhoto extends MobiModule {
    constructor(options = {}) {
        super(MODULE_STYLE.CAROUSEL_MULTI_PHOTO, options);
    }

    render() {
        return CarouselMultiPhotoView;
    }
}

export const CarouselMultiPhoto = new _CarouselMultiPhoto();
