var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "up_down_pic_text", class: _vm.column2 },
    [
      _vm._l(_vm.newsList, function (info, index) {
        return [
          _c("up-down-pic-text-item", {
            key: "n_" + info.id,
            attrs: { info: info, index: index, "no-lazy-load": _vm.noLazyLoad },
          }),
          _vm._v(" "),
          !_vm.isColumn2
            ? _c("div", {
                key: "n_col_" + info.id,
                staticClass: "g_separator separatorLine",
              })
            : _vm._e(),
        ]
      }),
      _vm._v(" "),
      _vm.needBlankDiv
        ? _c(
            "div",
            { staticClass: "blankDiv newsLine" },
            [
              _c("up-down-pic-text-item", {
                attrs: { "no-lazy-load": _vm.noLazyLoad },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }