<template>
    <pack :children="children" :module-id="moduleId" :components-table="componentsTable" />
</template>

<script>
import Pack from './pack.vue';
import { componentsTable } from '@/modules/visitor-interface';

export default {
    name: 'PackVisitor',
    components: {
        Pack,
    },
    props: {
        children: {
            type: Array,
            default: () => [],
        },
        moduleId: {
            type: Number,
            default: -1,
            required: true,
        },
    },
    data() {
        return {
            componentsTable,
        };
    },
};
</script>

<style></style>
