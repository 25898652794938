var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "productCrossedSlideSec leftPosition J_prodItem proMarginClass J_product_item",
      class: [_vm._contentStyle, _vm._wrapBorderRadiusClass],
      style: _vm.itemStyle,
      attrs: {
        id: _vm.itemId,
        topClassName: _vm._topInfo.topName,
        topSwitch: _vm._topInfo.topSwitch,
        productId: _vm.info.id,
        productName: _vm.info.name,
        title: _vm._title2,
      },
    },
    [
      _c(
        "a",
        {
          staticClass: "J_prodItem-a",
          attrs: { href: _vm.info.extInfo.href, onclick: _vm.onclickStr },
        },
        [
          _c(
            "div",
            { staticClass: "fk-productListImgWrap" },
            [
              _vm.manageMode && _vm.info.top != 0 && !_vm._productSelect
                ? _c("div", { staticClass: "f-productListTopFlag" }, [
                    _vm._v("置顶"),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.info.extInfo.salePromotionOpen
                ? _c("sale-flag", { attrs: { info: _vm.info } })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "CrossedSlideSecImg",
                  attrs: {
                    id:
                      "CrossedSlideSecImg" +
                      _vm.info.id +
                      "module" +
                      _vm.module.id,
                  },
                },
                [
                  _c("div", {
                    directives: [
                      {
                        name: "drag-resize",
                        rawName: "v-drag-resize",
                        value: _vm._resizeOptions,
                        expression: "_resizeOptions",
                      },
                    ],
                    staticClass: "J_img_lazyload style9Img firstLastPro",
                    class: [_vm._imgScaleClass, _vm._imgBorderRadiusClass],
                    style: _vm.slideImgStyle,
                    attrs: {
                      title: _vm.info.name,
                      "src-original": _vm._imgSrc,
                    },
                  }),
                ]
              ),
              _vm._v(" "),
              _vm._isShowQualityContentLabel
                ? _c("quality-content-label", {
                    attrs: { text: _vm._knowPayLabelText },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _vm.module.content.showProName &&
          _vm.module.content.picSetting.picSizeSet == 1
            ? _c("product-name", { attrs: { info: _vm.info } })
            : _vm._e(),
          _vm._v(" "),
          !_vm.isNewAddModule &&
          _vm._showMallCart.mallCart2 &&
          _vm.module.content.picSetting.picSizeSet == 1
            ? _c("mall-cart", {
                attrs: { info: _vm.info, holder: _vm._showMallCartHolder },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("prop-panel", {
            attrs: {
              info: _vm.info,
              list: _vm._showPropList,
              "ext-info": _vm._extInfo,
              "show-mall-cart": _vm._showMallCart,
              "text-align-class": _vm.textAlignClass,
              "no-name-wrap": _vm.noNameWrap,
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }