import { debounce } from '@jz/utils';
import { getModuleListByStyle } from '@/api/popup-zone';
import { ing } from '@/shared/fai';
import { encodeHtml } from '@/shared/util';

let switchActive = false; // 是否点击过选择弹窗
let currentPopupZoneId = 0;

export const createHoverTabDom = (popupZoneId) => {
    $('.popupZoneHoverEditTab').remove();
    let editTab = `
      <div class='popupZoneHoverEditTab'>
        <div class='popupZoneEditBtn'>
          <i class='popupZoneTabIcon'></i>
          <span class='popupZoneTabText'>编辑</span>
        </div>
        <div class='popupZoneSwitchBtn'>
          <i class='popupZoneTabIcon'></i>
          <span class='popupZoneTabText'>切换弹窗</span>
        </div>
      </div>
    `;

    let $editTab = $(editTab);
    $('#preview').append($editTab);

    currentPopupZoneId = popupZoneId;
};

export const initHoverTabEvent = (popupZone) => {
    // hover显示关闭工具栏
    Fai.top.$('body').off('mouseover.zone').off('mouseout.zone').on('mouseover.zone', debounce(toggleEditTool, 100));

    Fai.top
        .$('.popupZoneEditBtn')
        .off('click.pzTab')
        .on('click.pz', function (e) {
            hideHoverTab();
            e.stopPropagation();
            Mobi._popupZone && Mobi._popupZone.editZone(popupZone.moduleId);
            return false;
        });

    Fai.top
        .$('.popupZoneSwitchBtn')
        .off('click.pzTab')
        .on('click.pz', function (e) {
            e.stopPropagation();
            if (switchActive) {
                exitSwitchPopupZone();
            } else {
                // popupZone.create, popupZone.select, popupZone.moduleId
                enterSwitchPopupZone(popupZone);
            }
            return false;
        });

    // 点击其他地方就隐藏
    $(Fai.top.document).off('click.zone').on('click.zone', hideTab);
    $(document).off('click.zone').on('click.zone', hideTab);
};

//销毁hoverTab
export const destroyHoverTab = function () {
    $('.popupZoneHoverEditTab').remove();
    $(Fai.top.document).off('click.zone');
    $(document).off('click.zone');
};

function hideHoverTab() {
    $('.popupZoneHoverEditTab').hide();
}

function hideTab(e) {
    var el = $('.popupZoneHoverEditTab')[0];
    if (el && el.contains(e.target)) {
        return false;
    }
    exitSwitchPopupZone();
}

// 退出选择弹窗状态
function exitSwitchPopupZone() {
    switchActive = false;
    $('.popupZoneSwitchBtn').removeClass('popupZoneSwitchBtnActive');
    $('.popupZoneSwitchPanel').remove();
    $(`#module${currentPopupZoneId}`).off('click.switchActive');
}

// 进入选择弹窗状态
async function enterSwitchPopupZone(currentPopupZone) {
    let data = {};
    try {
        data = await getModuleListByStyle(62);
    } catch (e) {
        ing('服务繁忙，请稍候重试。', false);
    }

    let popupZoneList = data.info;

    let ListPanel = '',
        content = '';

    if (popupZoneList.length) {
        popupZoneList.forEach((popupZone) => {
            let { key } = popupZone;
            let activeClass = key == currentPopupZone.moduleId ? 'curPopupZoneLi' : '';
            content += `
        <div class='popupZoneLi ${activeClass}' _moduleId='${key}'>
          ${encodeHtml(popupZone.label)}
        </div>
      `;
        });
    } else {
        content = "<div class='noMorePopupZoneList'>没有更多弹窗！</div>";
    }

    ListPanel = `
    <div class='popupZoneSwitchPanel'>
      <div class='popupZoneUl'>${content}</div>
      <div class='add_new_popup_zone'>
        <i></i><span>新建弹窗</span>
      </div>
    </div>
  `;

    $(ListPanel).appendTo($('.popupZoneHoverEditTab'));

    let $popupZoneUI = Fai.top.$('.popupZoneUl');

    $popupZoneUI.mCustomScrollbar({
        mouseWheel: true,
        theme: 'fk-gray',
        scrollbarPosition: 'outside',
        advanced: { updateOnContentResize: true },
        axis: 'y',
    });

    Fai.top
        .$('.popupZoneUl')
        .off('click.pz')
        .on('click.pz', '.popupZoneLi', function () {
            if ($(this).hasClass('curPopupZoneLi')) {
                ing('所选弹窗为当前弹窗！', true);
                return;
            }
            var newPopupZoneId = parseInt($(this).attr('_moduleId'));

            newPopupZoneId
                ? Mobi._popupZone && Mobi._popupZone.selectZone(newPopupZoneId)
                : ing('所选弹窗不存在！', true);
        });

    Fai.top
        .$('.add_new_popup_zone')
        .off('click.pz')
        .on('click.pz', function () {
            Mobi._popupZone && Mobi._popupZone.addNewZone(popupZoneList);
            // currentPopupZone.create(popupZoneList);
        });

    $(`#module${currentPopupZone.moduleId}`)
        .off('click.switchActive')
        .on('click.switchActive', function () {
            exitSwitchPopupZone();
        });

    currentPopupZoneId = currentPopupZone.moduleId;
}

function toggleEditTool(e) {
    var $editTab = $('.popupZoneHoverEditTab');
    if ($(e.target).parents('.preview-area').length > 0) {
        $editTab.data('canClose', false);
        $editTab.show();
    } else {
        $editTab.data('canClose', true);
        $editTab.hide();
    }
}
