<template>
    <div class="mobi_form_checkbox choice_box_wrap">
        <label
            class="m_checkbox_wrap choice_box"
            :class="{
                m_checkbox_checked: value,
            }"
        >
            <span class="m_checkbox">
                <span class="m_form_checkbox_inner m_checkbox_inner icon-" :class="{ active: value }"></span>
                <input class="m_checkbox_input" type="checkbox" :checked="value" :value="value" @change="change" />
            </span>
        </label>
    </div>
</template>
<script>
export default {
    props: ['value'],
    methods: {
        change(event) {
            this.$emit('input', !(event.target.value === 'true'));
        },
    },
};
</script>
<style></style>
