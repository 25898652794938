import { MobiModule } from '../Module';
import { MODULE_STYLE } from '@/def/module';
import CustomSearchView from './module/visitor.vue';

class _CustomSearch extends MobiModule {
    constructor(options = {}) {
        super(MODULE_STYLE.CUSTOM_SEARCH, options);
    }

    render() {
        return CustomSearchView;
    }
}

export const CustomSearch = new _CustomSearch();
