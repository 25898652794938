<template>
    <module-frame :module-id="moduleId">
        <div v-if="render" class="J_foldModule fk-formFold" :class="'fk-foldStyle' + foldStyle">
            <div class="f-formFoldModuleContent">
                <div
                    :id="'formFoldContentList' + id"
                    class="J_formFoldContentList f-formFoldContentList"
                    :_display="displayMethod"
                    :styleId="foldStyle"
                >
                    <template v-for="(item, index) in labelList">
                        <div
                            :id="'formFoldItem' + item.id"
                            :key="'foldLabelItem_' + item.id + '_' + index"
                            :defaultopen="item.do"
                            :pos="index"
                            :class="'foldLabelItem' + item.id"
                            :style="label_title_style"
                            class="J_formFoldItem f-formFoldItem g_label_title"
                        >
                            <div
                                :id="'formFoldButton' + item.id"
                                class="J_formFoldButton f-formFoldButton"
                                :class="genFormFoldButtonClass(item.id)"
                                @click="toggleClickHandle(item.id, index, $event)"
                            >
                                <div
                                    v-if="buttonTextRender"
                                    class="f-formFoldButtonText"
                                    v-html="itemLabelName(item.labelName, item.id)"
                                ></div>
                                <div v-else class="f-formFoldButtonText" v-html="item.labelName"></div>
                                <span class="icon-fold"></span>
                            </div>
                            <div
                                v-if="item.id < 0"
                                :id="'formFoldContent' + item.id"
                                class="J_formFoldContent f-formFoldContent"
                            >
                                <div v-if="manageMode" class="emptyLabel empty_tips_panel">
                                    <div class="text">请将模块拖入标签区域</div>
                                    <button
                                        class="jz_button jz_button__active addBtn_Icon"
                                        @click.stop="addModuleToFold(item.id)"
                                        >添加模块</button
                                    >
                                </div>
                                <div v-else class="emptyLabel cus_Empty_tips_panel">
                                    <div class="text">没有添加内容</div>
                                </div>
                            </div>
                            <template v-else>
                                <fold-child-module
                                    :id="item.id"
                                    :key="'fold_' + moduleId + '_' + item.id"
                                    :style-id="item.style"
                                    :item-data="labelCache['module' + item.id]"
                                    :label-data="item"
                                    :manage-mode="manageMode"
                                ></fold-child-module>
                            </template>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </module-frame>
</template>

<script>
import foldChildModule from '@/modules/foldContainer/module/components/foldChildModule.vue';
import ModuleFrame from '@/modules/frame/index.vue';

import { removeJumpModuleInfo, bindJumpModuleEvent } from '../../comm';
import { mapGetters, mapActions } from 'vuex';
import { inFoldModuleEdit } from '../util';
import { getChildModuleData } from '@/api/module/tabContainer';
import { ing } from '@/shared/tips';
import { getQurl } from '@/manage/url';
const manageMode = VITE_APP_MODE !== 'visitor';
import { initModuleAttr } from '@/store/util';
export default {
    name: 'FoldContainer',
    components: {
        foldChildModule,
        ModuleFrame,
    },
    props: {
        moduleId: {
            type: Number,
            default: -1,
        },
    },
    data() {
        return {
            id: this.moduleId,
            render: true,
            openIdList: [],
            labelCache: {},
            buttonTextRender: manageMode,
            manageMode,
        };
    },
    computed: {
        ...mapGetters(['getModuleById']),
        module() {
            return this.getModuleById(this.moduleId);
        },
        options() {
            return this.module.renderOptions;
        },
        styleId() {
            return this.module.style;
        },
        foldStyle() {
            return this.module.content.foldStyle;
        },
        tabStyleClass() {
            return 'fk-tabStyle' + this.foldStyle;
        },
        displayMethod() {
            return this.module.content.displayMethod;
        },
        moduleIdList() {
            return this.module.content.moduleList;
        },
        labelList() {
            return this.module.content.labelList;
        },
        label_title_style: function () {
            if (!manageMode) {
                return '';
            }
            var labelTitle = this.module.content.mhighs.ltf;
            var style = '';
            if (labelTitle.type == 1) {
                if (labelTitle.nfs) {
                    style += 'font-size:' + labelTitle.nfs + 'rem;';
                } else {
                    style += 'font-size:12px;';
                }
                if (labelTitle.nfct == 1) {
                    style += 'color:' + labelTitle.nfc + ';';
                }
            }
            return style;
        },

        extInfo() {
            return this.module.extInfo || {};
        },

        jumpModuleInfo() {
            return this.extInfo.jumpModuleInfo || {};
        },

        isEmptyJumpModule() {
            return this.jumpModuleInfo.id == -1 || this.jumpModuleInfo.id == null;
        },
    },
    watch: {
        'module.content.labelList': {
            handler: function () {
                if (manageMode) {
                    var self = this;
                    self.render = false;
                    self.$nextTick(() => {
                        self.render = true;
                        self.$nextTick(() => {
                            this.openIdList = [];
                            self.openDefaultItem();
                            bindEvent(this);
                        });
                    });
                }
            },
            deep: true,
        },
        'module.content.displayMethod': {
            handler: function () {
                var self = this;
                self.render = false;
                self.$nextTick(() => {
                    self.render = true;
                    self.$nextTick(() => {
                        this.openIdList = [];
                        bindEvent(this);
                    });
                });
            },
        },
        'module.content.foldStyle': {
            handler: function () {
                var self = this;
                self.render = false;
                self.$nextTick(() => {
                    self.render = true;
                    self.$nextTick(() => {
                        this.openIdList = [];
                        bindEvent(this);
                    });
                });
            },
        },
    },
    mounted() {
        this.labelCache = JSON.parse(JSON.stringify(this.options.args.labelCache));
        if (this.isEmptyJumpModule) {
            this.openDefaultItem();
        } else {
            this.openIdList.push(this.jumpModuleInfo.id);
            this.openItem(this.jumpModuleInfo.id, this.jumpModuleInfo.currentIndex);
        }

        if (manageMode) {
            var self = this;
            self.buttonTextRender = false;
            self.$nextTick(() => {
                self.buttonTextRender = true;
            });
            if (typeof Fai.top.labelModuleData === 'undefined') {
                Fai.top.labelModuleData = {};
            }
            if (!Fai.top.labelModuleData['module' + this.module.id]) {
                Fai.top.labelModuleData['module' + this.module.id] = this.options.args.childModuleList;
            }
        }

        removeJumpModuleInfo(this.module.id);
        bindEvent(this);
    },
    methods: {
        ...mapActions(['setModule']),
        addModuleToFold(labelId) {
            inFoldModuleEdit.addModuleToFoldByAddBtn(this.id, labelId, event);
        },
        itemLabelName(labelName, itemId) {
            if (!manageMode) {
                return '';
            } else {
                if (labelName == '' && this.getModuleById(itemId)) {
                    return this.getModuleById(itemId).name;
                } else {
                    return labelName;
                }
            }
        },
        toggleClickHandle(id, index, $event) {
            if ($event.data) {
                $event.stopPropagation();
            }
            if (this.displayMethod == 0) {
                if (this.openIdList.includes(id)) {
                    this.closeItem(id);
                    this.openIdList = [];
                } else {
                    this.closeAllItem();
                    this.openIdList = [];
                    this.openIdList.push(id);
                    this.openItem(id, index);
                }
            } else if (this.displayMethod == 1) {
                if (this.openIdList.includes(id)) {
                    this.openIdList.splice(this.openIdList.indexOf(id), 1);
                    this.closeItem(id);
                } else {
                    this.openIdList.push(id);
                    this.openItem(id, index);
                }
            }
        },
        openItem(id, index) {
            var currentLabel = this.module.content.labelList[index];
            var tabModuleId = currentLabel.id;
            var _this = this;
            var item = jm('#module' + this.id + ' #formFoldButton' + id);
            // item.addClass('active');
            var itemBox = item.parent();
            var content = itemBox.find('.J_formFoldContent');
            var height = content.find('.J_formFoldContentBox').length
                ? parseInt(content.find('.J_formFoldContentBox')[0].offsetHeight)
                : parseInt(content.find('.emptyLabel')[0].offsetHeight);
            content.css('height', height + 'px');
            // if (this.foldStyle == 2) {
            //     item.addClass('g_main_bgColor_v3 g_bgColor');
            // } else {
            //     item.addClass('g_main_color_v3 g_color');
            // }

            content.one('transitionend', fixHeight);
            content.one('webkitTransitionEnd', fixHeight);
            function fixHeight() {
                if (item.hasClass('active')) {
                    content.off('transitionend', fixHeight);
                    content.off('webkitTransitionEnd', fixHeight);

                    if (tabModuleId && tabModuleId > 0) {
                        var cache = _this.labelCache['module' + currentLabel.id];
                        if (!cache) {
                            _this.$nextTick(function () {
                                _this.activeModule(currentLabel);
                                content.css('height', 'auto');
                            });
                        } else {
                            content.css('height', 'auto');
                        }
                    } else {
                        content.css('height', 'auto');
                    }
                }
            }
        },
        closeItem(id) {
            var item = jm('#module' + this.id + ' #formFoldButton' + id);
            if (!item.hasClass('active')) {
                return;
            }
            var itemBox = item.parent();
            var content = itemBox.find('.J_formFoldContent');
            var height = content.find('.J_formFoldContentBox').length
                ? parseInt(content.find('.J_formFoldContentBox')[0].offsetHeight)
                : parseInt(content.find('.emptyLabel')[0].offsetHeight);

            content.css('height', height + 'px');
            setTimeout(function () {
                // item.removeClass(
                //     'g_main_color_v3 g_main_bgColor_v3 g_color g_bgColor'
                // );
                content.css('height', 0);
            });
        },
        closeAllItem() {
            var _this = this;
            this.openIdList.forEach(function (id) {
                _this.closeItem(id);
            });
        },
        async activeModule(currentLabel) {
            var _this = this;
            var tabModuleId = currentLabel.id;

            var dataQuery = {};
            dataQuery['_colId'] = Fai.top._colInfo.id;
            dataQuery['_extId'] = Fai.top._colInfo.extId;
            dataQuery['moduleId'] = tabModuleId;
            dataQuery['style'] = currentLabel.style;
            dataQuery['qurl'] = getQurl();
            dataQuery['parentId'] = this.module.id;
            dataQuery['parentStyle'] = this.module.style;

            let result = await getChildModuleData(dataQuery);
            let { data, success } = result;
            if (success) {
                var moduleHtml = data.domStr;
                var moduleJs = data.scripts;
                var moduleInfo = data.moduleInfo;
                var tmpCache = {};
                tmpCache.moduleScript = moduleJs;
                tmpCache.sbm = moduleHtml;
                tmpCache.load = true;
                this.revalidateModule(moduleInfo);
                _this.$set(_this.labelCache, 'module' + moduleInfo.id, tmpCache);
            } else {
                ing(LS.js_systemError);
                Monitor.logAlarmMonitor(MonitorDef.AlarmMonitorId.FOLD_CONTAINER_SWUTCH);
            }
        },
        openDefaultItem() {
            var _this = this;
            this.labelList.forEach(function (val, index) {
                if (val.do) {
                    _this.openItem(val.id, index);
                    _this.openIdList.push(val.id);
                } else {
                    _this.closeItem(val.id);
                }
            });
        },
        genFormFoldButtonClass(id) {
            return [
                {
                    active: this.openIdList.includes(id),

                    'f-formFoldButton-text-warp':
                        this.module.content.textWrap === 0 || this.module.content.textWrap === 1,
                },
                this.openIdList.includes(id)
                    ? this.foldStyle == 2
                        ? 'g_main_bgColor_v3 g_bgColor'
                        : 'g_main_color_v3 g_color'
                    : '',
            ];
        },
        revalidateModule(moduleInfo) {
            let moduleId = moduleInfo.id;
            if (!this.getModuleById(moduleId)) {
                this.setModule({
                    moduleId: moduleId,
                    data: moduleInfo,
                });
            }
            if (VITE_APP_MODE !== 'visitor') {
                window.$store.commit('manage/updateModuleAttr', {
                    attr: initModuleAttr(moduleInfo.style, moduleInfo.pattern),
                    id: moduleInfo.id,
                });
            }
            if (Array.isArray(moduleInfo.children)) {
                for (let child of moduleInfo.children) {
                    this.revalidateModule(child);
                }
            }
        },
    },
};

function bindEvent(context) {
    bindJumpModuleEvent(context.id, context.module.style);
}
</script>

<style lang="scss">
.fk-formFold .f-formFoldButton,
.fk-formFold .f-formFoldButton * {
    user-select: auto !important;
}
/**************************** 折叠标签 start ****************************/
.fk-formFold {
    overflow: hidden;
}
.formStyle58 .formMiddleContent {
    margin-bottom: 0 !important;
}
.fk-formFold .f-formFoldItem {
    overflow: hidden;
    height: auto;
}
.fk-formFold .f-formFoldItem .f-formFoldContent {
    transition: height 0.5s;
}
.fk-formFold .f-formFoldItem .f-formFoldContent {
    height: 0;
}
.formStyle58.fk-nullModuleInFoldStyle .formBannerTitle {
    display: none;
}
.fk-formFold .f-formFoldItem .f-formFoldContent .form {
    margin: 0;
    background: none;
}
#webContainerBox .fk-formFold .f-formFoldItem .f-formFoldContent .form .formBannerTitle {
    display: none !important;
}
.fk-formFold .f-formFoldItem .f-formFoldButton {
    cursor: pointer;
    position: relative;
}
.fk-formFold .f-formFoldItem .f-formFoldButton .f-formFoldButtonText {
    width: 92%;
    cursor: default;
    word-break: normal;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.fk-foldStyle1 .f-formFoldItem {
    margin: 0.5rem 0.3rem;
    border: 0.5px solid #e5e5e5;
    border-radius: 0.2rem;
}
.fk-foldStyle1 .f-formFoldItem .f-formFoldButton {
    height: 2.2rem;
    line-height: 2.2rem;
    padding-left: 0.8rem;
    border-bottom: 0.5px solid #e5e5e5;
}
.fk-foldStyle1 .f-formFoldItem .f-formFoldButton .icon-fold {
    display: inline-block;
    width: 0.5rem;
    height: 0.5rem;
    position: absolute;
    right: 0.6rem;
    top: 0.85rem;
    color: inherit;
}
.fk-foldStyle1 .f-formFoldItem .f-formFoldButton .icon-fold:before {
    content: '\b0237';
    display: inline-block;
    transition: all 0.2s;
    font-size: 0.5rem;
    position: absolute;
    top: 0;
}
.fk-foldStyle1 .f-formFoldItem .f-formFoldButton.active .icon-fold:before {
    transform: rotate(90deg);
}
.fk-foldStyle1 .f-formFoldItem .form .productCrossedSlide:nth-child(2n + 1) {
    margin-left: 0.2rem;
}
.fk-foldStyle1 .f-formFoldItem .form .productCrossedSlide:nth-child(2n) {
    margin-right: 0.2rem;
}
.fk-foldStyle1 .f-formFoldItem .form .productWaterFall:nth-child(2n + 1) {
    margin-left: 0.3rem;
}
.fk-foldStyle1 .f-formFoldItem .form .productWaterFall:nth-child(2n) {
    right: 0.3rem;
}
.fk-foldStyle2 .f-formFoldItem {
    margin: 0.5rem 0.3rem;
}
.fk-foldStyle2 .f-formFoldItem .f-formFoldButton .icon-fold {
    display: inline-block;
    width: 0.5rem;
    height: 0.5rem;
    position: absolute;
    left: 0.5rem;
    top: 0.7rem;
}
.fk-foldStyle2 .f-formFoldItem .f-formFoldButton .icon-fold:before {
    content: '\b0237';
    display: inline-block;
    transition: all 0.2s;
    font-size: 0.5rem;
    position: absolute;
    top: 0;
    color: #cccccc;
}
.fk-foldStyle2 .f-formFoldItem .f-formFoldButton.active .icon-fold:before {
    transform: rotate(90deg);
    color: #fff;
}
.fk-foldStyle2 .f-formFoldItem .f-formFoldButton.active {
    color: #fff;
}
.fk-foldStyle2 .f-formFoldItem .form .productCrossedSlide:nth-child(2n + 1) {
    margin-left: 0.2rem;
}
.fk-foldStyle2 .f-formFoldItem .form .productCrossedSlide:nth-child(2n) {
    margin-right: 0.2rem;
}
.fk-foldStyle2 .f-formFoldItem .form .productWaterFall:nth-child(2n + 1) {
    margin-left: 0.3rem;
}
.fk-foldStyle2 .f-formFoldItem .form .productWaterFall:nth-child(2n) {
    right: 0.3rem;
}
.fk-foldStyle3 .f-formFoldItem {
    border-bottom: 1px solid #f0f0f0;
}
.fk-foldStyle3 .f-formFoldItem:last-child {
    border: none;
}
.fk-foldStyle3 .f-formFoldItem .f-formFoldButton {
    height: 2.2rem;
    line-height: 2.2rem;
    padding-left: 0.8rem;
}
.fk-foldStyle3 .f-formFoldItem .f-formFoldButton .icon-fold {
    display: inline-block;
    width: 0.6rem;
    height: 0.6rem;
    position: absolute;
    right: 0.6rem;
    top: 0.85rem;
}
.fk-foldStyle3 .f-formFoldItem .f-formFoldButton .icon-fold:before {
    content: '\b0106';
    display: inline-block;
    transition: all 0.2s;
    font-size: 0.6rem;
    position: absolute;
    top: 0;
}
.fk-foldStyle3 .f-formFoldItem .f-formFoldButton.active .icon-fold:before {
    transform: rotate(90deg);
}
.fk-foldStyle3 .f-formFoldItem .f-formFoldButton.active {
}
.fk-formFold .f-formFoldItem .f-formFoldContent .formStyle2 .formMiddle {
    min-height: 1rem;
}
/**************************** 折叠标签 end ****************************/

.fk-formFold .f-formFoldItem .f-formFoldButton.f-formFoldButton-text-warp {
    height: unset;
    line-height: unset;
    display: flex;
    align-items: center;
    padding-top: 0.652rem;
    padding-bottom: 0.652rem;
    .f-formFoldButtonText {
        overflow: unset;
        text-overflow: unset;
        white-space: unset;
        word-break: unset;
    }
    .icon-fold {
        position: relative;
        top: unset;
        right: unset;
    }
}

.fk-foldStyle2 .f-formFoldItem .f-formFoldButton.f-formFoldButton-text-warp {
    padding-top: 0.502rem;
    padding-bottom: 0.502rem;
    flex-direction: row-reverse;
    justify-content: flex-start;
    padding-left: 0;
    .icon-fold {
        position: relative;
        top: unset;
        left: unset;
        margin-right: 0.5rem;
        margin-left: 0.5rem;
    }
}
</style>
