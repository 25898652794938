var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "p",
    {
      staticClass: "g_link paramName g_productName g_newProductName",
      class: [_vm.classes, _vm._proNameWrapClass],
      style: _vm.productName_style,
    },
    [
      _vm.info.isTimedAdded
        ? _c(
            "span",
            {
              staticClass: "g_oldThemeBgColor g_main_bgColor_v3 m_timedAddTag",
              class: _vm._proNameWrapClass,
            },
            [_vm._v("即将开售")]
          )
        : _vm._e(),
      _vm._v("\n    " + _vm._s(_vm.info.name) + "\n"),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }