var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "form_submit_success_tip_custom_content_wrap",
      attrs: { id: "formSubmitSuccessTipCustom" },
    },
    [
      _c(
        "div",
        { staticClass: "form_submit_success_tip_custom_header webHeaderBg" },
        [
          _c("a", {
            staticClass: "g_close icon-gClose",
            on: { click: _vm.close },
          }),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "form_submit_success_tip_custom_content" }, [
        _c("div", {
          staticClass: "set-line",
          staticStyle: { "overflow-x": "auto" },
          domProps: { innerHTML: _vm._s(_vm.content) },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }