<template>
    <div class="photoModule styleForm7">
        <div :id="'photoModuleImageSwipe' + module.id" class="swipe">
            <div class="bannerSwipeContainer photoModuleImageSwipeContainer">
                <div
                    v-for="(item, index) in cycleList"
                    :key="index"
                    class="photoImageItem"
                    :data-picId="item.picId || item.pic"
                >
                    <a
                        v-if="content.link === 0"
                        :href="item.hrefStr2"
                        style="cursor: default"
                        @click="photoSlidesshow(index, item)"
                    >
                        <span class="imageMiddleSpan"></span>
                        <img
                            :id="item.picId"
                            :class="stretchImgClass"
                            :src-original="item.picPath"
                            :src="imgSrc(item)"
                            :vwidth="item.rect_640_width"
                            :vheight="item.rect_640_height"
                            :alt="imgAlt(item)"
                            :photoIndex="index"
                            :style="imgStyle(item)"
                        />
                    </a>
                    <a v-else-if="content.link === 1" hidefocus="true" @click="photoSlidesshow(index, item)">
                        <span class="imageMiddleSpan"></span>
                        <img
                            :id="item.picId"
                            class="J_noOpenDesigner"
                            :class="stretchImgClass"
                            :src-original="item.picPath"
                            :src="imgSrc(item)"
                            :vwidth="item.rect_640_width"
                            :vheight="item.rect_640_height"
                            :alt="imgAlt(item)"
                            :photoIndex="index"
                            :style="imgStyle(item)"
                        />
                    </a>
                    <a
                        v-else-if="
                            content.link === 2 && content.apw === 1 && item.linkType == 2 && item.jumpInfo.u != ''
                        "
                        hidefocus="true"
                        :href="item.jumpInfo.u"
                        @click="photoSlidesshow(index, item)"
                    >
                        <span class="imageMiddleSpan"></span>
                        <img
                            :id="item.picId"
                            class="J_noOpenDesigner"
                            :class="stretchImgClass"
                            :src-original="item.picPath"
                            :src="imgSrc(item)"
                            :vwidth="item.rect_640_width"
                            :vheight="item.rect_640_height"
                            :alt="imgAlt(item)"
                            :photoIndex="index"
                            :style="imgStyle(item)"
                        />
                    </a>
                    <a
                        v-else-if="content.link === 2 && item.jumpInfo.u != ''"
                        hidefocus="true"
                        :href="item.jumpInfo.u"
                        @click="photoSlidesshow(index, item)"
                    >
                        <span class="imageMiddleSpan"></span>
                        <img
                            :id="item.picId"
                            class="J_noOpenDesigner"
                            :class="stretchImgClass"
                            :src-original="item.picPath"
                            :src="imgSrc(item)"
                            :vwidth="item.rect_640_width"
                            :vheight="item.rect_640_height"
                            :alt="imgAlt(item)"
                            :photoIndex="index"
                            :style="imgStyle(item)"
                        />
                    </a>
                    <a v-else hidefocus="true" @click="photoSlidesshow(index, item)">
                        <span class="imageMiddleSpan"></span>
                        <img
                            :id="item.picId"
                            class="J_noOpenDesigner"
                            :class="stretchImgClass"
                            :src-original="item.picPath"
                            :src="imgSrc(item)"
                            :vwidth="item.rect_640_width"
                            :vheight="item.rect_640_height"
                            :alt="imgAlt(item)"
                            :photoIndex="index"
                            :style="imgStyle(item)"
                        />
                    </a>
                </div>
            </div>
            <div v-if="content.showName === true">
                <div class="imgName g_carouselPhoto_link" :style="link_style" v-html="imgDesc"></div>
            </div>
        </div>
    </div>
</template>

<script>
import CarouselTypeMixins from './mixins';

import { encodeHtml } from '@/shared/util';
export default {
    name: 'CarouselType7',
    mixins: [CarouselTypeMixins],
    inject: ['module', 'options'],
    // eslint-disable-next-line vue/require-prop-types
    props: ['photoSwipeList', 'nameList', 'cycleList', 'noLazyLoad'],
    data() {
        return {
            id: this.module.id,
            styleId: this.module.style,
        };
    },
    computed: {
        content() {
            return this.module.content;
        },
        stretchImgClass() {
            var cls = {};
            //cls["fade-in-for-lazyload"] = true;
            cls['J_img_lazyload'] = true;
            if (this.module.content.picScale != 0) {
                cls['stretchImg'] = true;
            }

            return cls;
        },
        imgDesc() {
            if (this.module.content.apw == 0) {
                return this.encodeHtml(this.module.content.imgDesc);
            } else {
                if (this.cycleList && this.cycleList.length != 0) {
                    return this.encodeHtml(this.cycleList[0].basic);
                } else {
                    return '';
                }
            }
        },
        link_style() {
            var style = '';
            if (!this.content.nameWrap) {
                style = 'white-space:nowrap;';
            }

            if (!(VITE_APP_MODE !== 'visitor')) {
                return style;
            }
            var imgDescFont = this.module.content.mhighs.idf;
            if (imgDescFont.type == 1) {
                if (imgDescFont.lfs) {
                    style += 'font-size:' + imgDescFont.lfs + 'rem;';
                } else {
                    style += 'font-size:12px;';
                }
                if (imgDescFont.lfct == 1) {
                    style += 'color:' + imgDescFont.lfc + ';';
                }
                if (imgDescFont.lfd == 1) {
                    style += 'text-decoration:underline;';
                } else {
                    style += 'text-decoration:none;';
                }
            }
            return style;
        },
    },
    methods: {
        encodeHtml(html) {
            return encodeHtml(html);
        },
        imgAlt(item) {
            if (this.module.content.apw == 0) {
                return this.encodeHtml(item.desc);
            } else {
                return this.encodeHtml(item.basic);
            }
        },
        imgSrc(item) {
            return this.noLazyLoad ? item.picPath : this.options.loadingPath;
        },
        imgStyle(item) {
            var style = {};
            var scale = item.rect_640_height / item.rect_640_width;
            var picScale = this.module.content.picScale;
            if (scale && picScale == 0) {
                style['height'] = 16 * scale + 'rem';
            }
            return style;
        },
    },
};
</script>

<style lang="scss">
.carouselMultiPhoto .photoModule.styleForm7 {
    position: relative;
    margin: 0 auto;
    overflow: hidden;

    width: 100%;
}

.photoModule.styleForm7 ul {
    position: absolute;
    padding: 0px;
    left: 0;
    transition: all 500ms;
}

.formStyle42.form .formMiddle {
    overflow: hidden;
    z-index: 99;
    padding-bottom: 0;
}

.photoModule.styleForm7 ul li {
    width: 15rem;
    list-style-type: none;
    float: left;
    text-align: center;
    overflow: hidden;
    background: #fff;
}

.photoModule.styleForm7 .imgDiv2 {
    overflow: hidden;
    display: inline-block;
    background: #fff;
}

.photoModule.styleForm7 .prevPhoto {
    background: url(/image/PicLeft.png?v=201711250607) rgba(0, 0, 0, 0.4) no-repeat;
    width: 1.75rem;
    height: 1.75rem;
    float: left;
    top: 30%;
    cursor: pointer;
    position: absolute;
    background-size: 80%;
    background-position: 0.25rem;
    border-radius: 4px;
    left: 0.75rem;
}
.photoModule.styleForm7 .nextPhoto {
    background: url(/image/PicRight.png?v=201711250607) rgba(0, 0, 0, 0.4) no-repeat;
    width: 1.75rem;
    height: 1.75rem;
    float: right;
    top: 30%;
    cursor: pointer;
    position: absolute;
    background-size: 80%;
    background-position: 0.25rem;
    border-radius: 4px;
    right: 0.75rem;
}
.photoModule .styleForm7 .imgContainer {
    display: table-cell;
    vertical-align: middle;
}
.photoModule.styleForm7 .imageMiddleSpan {
    display: inline;
    height: 100%;
    vertical-align: middle;
}
.photoModule .photoModuleImageSwipeContainer {
    overflow: hidden;
    position: relative;
}

.photoModule.styleForm7 .imgName {
    position: absolute;
    bottom: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    background: rgba(0, 0, 0, 0.4);
    color: #fff;
    line-height: 1.2rem;
    font-size: 0.7rem;
    width: 100%;
    text-align: center;
}

.swipe {
    overflow: hidden;
    visibility: hidden;
    position: relative;
    margin: 0 auto;
}

.swipe {
    // -moz-perspective: 1600px;
    // -o-perspective: 1600px;
    // -ms-perspective: 1600px;
    transform: rotateX(0deg) rotateY(0deg) translate3d(0, 0, 0);
}
</style>
