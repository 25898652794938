import { getPreviewObject } from '@/shared/getPreviewObject';
import {
    createGraphicPosterPage,
    drawCommCanvasSharePicture,
    commShareTemplateData,
    videoQuit,
} from '@/features/global-mobi-function/methods/share/index';
import { initWebPage } from '@/shared/misc/init-webpage';
import { initScaleImage } from '@/shared/photo/scale';
import { logDog } from '@/shared/log';
import { findParentLinkNode } from '@/shared/html';
import { initPictureSlidesV4 } from '@/shared/photo/slides';

export function initProductSwipe(swipeId) {
    var jqmobi = jm;
    var swipe = getPreviewObject(swipeId);
    if (!swipe) return;

    // 调出分享海报遮罩层
    jm('#graphicPosterItem').bind('click', function () {
        jm('#webPagePanelmallThemeProductDetailShare').hide();
        jm('#webPagePanelshowPromoterPdSharePanel').hide();
    });
    var graphicPosterOption = {
        triggerId: 'graphicPosterItem',
        pageBg: true,
        pageHtml: createGraphicPosterPage(),
        direction: 'bottom',
        closeWebPage: '#graphicPosterPanelBg',
        callback() {
            drawCommCanvasSharePicture(commShareTemplateData('product'));
        },
    };
    initWebPage(graphicPosterOption);

    initScaleImage(swipe);

    jqmobi('#' + swipeId).swipehandle(swipe, {
        //auto: 3000,
        continuous: true,
        bulletsClick: true,
        callback(pos) {
            var liLen = bullets.length;
            while (liLen--) {
                bullets[liLen].className = ' ';
            }
            if (!bullets[pos]) {
                bullets[pos - bullets.length].className = 'on';
            } else {
                bullets[pos].className = 'on';
            }
            //兼容产品视频
            var mobiProductVideoDialog = jm('.mobiProductVideoDialog');
            if (mobiProductVideoDialog.length == 1) {
                //退出产品视频
                videoQuit();
            }
        },
    });

    jm('.shareListSwipe').bind('click', function () {
        logDog(201083, 1);
    });

    if (!swipe.querySelector('.bullets')) return;
    var bullets = swipe.querySelector('.bullets').getElementsByTagName('li');
}

export function bindCommImgShow(images, singalBoolean, number) {
    var options = {
            imageScaleMethod: 'fitNoUpscale',
            enableMouseWheel: false,
            resetToolbarPosition: true,
            loop: false,
            captionAndToolbarAutoHideDelay: 0,
            showCountTextLeft: true,
            getImageCaption(index, length) {
                var captionText, captionEl;
                captionText = index + ' / ' + length;
                captionEl = document.createElement('div');
                captionEl.className = 'ps-comm-page';
                captionEl.appendChild(document.createTextNode(captionText));
                return captionEl;
            },
            getToolbar() {
                return '<div onclick="Mobi.pbSaveBodyStyle()" class="ps-toolbar-close   ps-toolbar-close-style icon-toolbar-close-style"></div>';
            },
        },
        PhotoSwipe = window.Code.PhotoSwipe;

    if (VITE_APP_MODE !== 'visitor') {
        options.target = jm('#g_body')[0];
    }
    if (singalBoolean == null || !singalBoolean || singalBoolean == 'false') {
        // eslint-disable-next-line no-unused-vars
        var instance = PhotoSwipe.attach(images, options);
    } else {
        // 如果存在这样子的显示实例，先解绑，然后过1s 再绑定
        // eslint-disable-next-line no-undef
        if (
            typeof g_showCommImgInstance != 'undefined' &&
            // eslint-disable-next-line no-undef
            g_showCommImgInstance != 'null'
        ) {
            // eslint-disable-next-line no-undef
            if (
                // eslint-disable-next-line no-undef
                typeof g_showCommImgInstance[number] != 'undefined' &&
                // eslint-disable-next-line no-undef
                g_showCommImgInstance[number] != 'null'
            ) {
                // eslint-disable-next-line no-undef
                PhotoSwipe.detatch(g_showCommImgInstance[number]);
                // eslint-disable-next-line no-undef
                g_showCommImgInstance[number] = null;
            } else {
                // eslint-disable-next-line no-undef
                g_showCommImgInstance[number] = {};
            }
        } else {
            window.g_showCommImgInstance = [];
            // eslint-disable-next-line no-undef
            g_showCommImgInstance[number] = {};
        }
        setTimeout(function () {
            // eslint-disable-next-line no-undef
            g_showCommImgInstance[number] = PhotoSwipe.attach(images, options);
        }, 1000);
    }
}

export function updateProductDetailSwipe(selector) {
    var options = {
        fromCurentInx: true,
        contentTerminal: 'mobi-产品展示-产品详情-ue',
    };
    var images = [];
    var length = jm(selector).length;
    jm(selector).forEach(function (el, index) {
        var image = new Image();
        image.src = el.src;
        image.onload = function () {
            let openLink = false;
            let findEl = null;
            let pdInfoPanelEl = jm('.productDetailInfoPanel')[0];
            if (pdInfoPanelEl != null) {
                findEl = findParentLinkNode(el, pdInfoPanelEl);
                if (findEl && findEl.href !== '' && !findEl.href.startsWith('javascript:void(0)')) {
                    openLink = true;
                }
            }

            let imageId = 'photoswipe_' + index;
            var $el = jm(el);
            images.push({
                src: $el.attr('src-original') || el.src,
                width: $el.attr('vwidth') || el.naturalWidth,
                height: $el.attr('vheight') || el.naturalHeight,
                title: '',
                description: '',
                detail: false,
                id: imageId,
                openLink,
                index,
            });

            el.setAttribute('data-picId', imageId);

            if (images.length == length) {
                initPictureSlidesV4(selector, images, options);
            }
        };
    });
}
