var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "variable_select" },
    [
      _vm.isPc()
        ? [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "outsideClick",
                    rawName: "v-outsideClick",
                    value: _vm.handleClose,
                    expression: "handleClose",
                  },
                ],
                staticClass: "variable_input variable_select_input",
                class: _vm.focusCls,
                style: _vm.inputBoxStyle,
              },
              [
                _c("input", {
                  ref: "selectInput",
                  staticClass: "select_input input_style",
                  style: _vm.inputStyle,
                  attrs: {
                    type: "text",
                    readonly: "",
                    unselectable: "on",
                    placeholder: _vm.placeHolder,
                  },
                  domProps: { value: _vm.currentVal.key },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.toggleList.apply(null, arguments)
                    },
                  },
                }),
                _vm._v(" "),
                _c("span", {
                  staticClass: "arrow faisco-icons-S000112",
                  on: { click: _vm.toggleList },
                }),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.list.length && _vm.showList,
                    expression: "list.length && showList",
                  },
                  {
                    name: "dom-portal",
                    rawName: "v-dom-portal",
                    value: _vm.target,
                    expression: "target",
                  },
                ],
                ref: "selectList",
                staticClass: "select_list_wrap",
              },
              [
                _c(
                  "ul",
                  { staticClass: "select_list" },
                  _vm._l(_vm.list, function (item) {
                    return _c(
                      "li",
                      {
                        staticClass:
                          "select_item J_noOpenDesigner g_oldThemeHoverBgColor g_main_hoverbgColor_v3",
                        class: _vm.selectActive(item),
                        style: _vm.optionFontStyle,
                        on: {
                          click: function ($event) {
                            return _vm.selectItem(item)
                          },
                        },
                      },
                      [_vm._v(_vm._s(item.key))]
                    )
                  }),
                  0
                ),
              ]
            ),
          ]
        : [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "outsideClick",
                    rawName: "v-outsideClick",
                    value: _vm.handleClose,
                    expression: "handleClose",
                  },
                ],
                staticClass: "variable_input variable_select_input",
                class: _vm.focusCls,
                style: _vm.inputBoxStyle,
              },
              [
                _c("span", { staticClass: "arrow faisco-icons-S000112" }),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    staticClass:
                      "mobileSelect m_mobi_form_input_text J_mobi_form_select",
                    style: _vm.inputStyle,
                    on: {
                      change: _vm.change,
                      blur: _vm.selectBlurHandle,
                      click: _vm.selectClickHandle,
                    },
                  },
                  [
                    _c(
                      "option",
                      {
                        staticStyle: { display: "none" },
                        attrs: { value: "noneValue" },
                        domProps: { selected: _vm.currentVal === "" },
                      },
                      [_vm._v(_vm._s(_vm.placeHolder))]
                    ),
                    _vm._v(" "),
                    _vm._l(_vm.list, function (item) {
                      return _c(
                        "option",
                        {
                          domProps: {
                            selected: _vm.currentVal.key === item.key,
                            value: item.key,
                          },
                        },
                        [_vm._v("\n                    " + _vm._s(item.key))]
                      )
                    }),
                  ],
                  2
                ),
              ]
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }