<template>
    <module-frame :module-id="moduleId">
        <div :class="manageMode ? 'scriptModuleMinH' : ''">
            <template v-if="code == '' && manageMode">
                <div class="empty_tips_panel">
                    <div class="text">请在右侧面板中添加代码</div>
                    <button-component active @click.stop="addCode"> 添加代码 </button-component>
                </div>
            </template>
            <template v-else-if="code == '' && !manageMode">
                <div class="cus_Empty_tips_panel">
                    <div class="text">没有添加代码</div>
                </div>
            </template>
            <template v-else>
                <div v-if="type == 0 && _allowScript && $isServer" ref="scriptNode" v-html="showCode" />
                <div v-else-if="type == 0 && _allowScript" ref="scriptNode"></div>
            </template>
        </div>
    </module-frame>
</template>

<script>
/* eslint-disable */
import ModuleFrame from '@/modules/frame/index.vue';
import { encodeHtml, decodeHtml } from '@/shared/util';
import { mapGetters, mapState } from 'vuex';
import { MODULE_STYLE } from '@/def/module';
import { showTopTips } from '@/shared/tips';
import { statisticsHttpInCode } from '@/shared/mobile';

export default {
    name: 'InsertCode',
    components: {
        ModuleFrame,
    },
    props: {
        moduleId: {
            type: Number,
            default: -1,
        },
    },
    data() {
        return {
            render: true,
            manageMode: VITE_APP_MODE !== 'visitor',
        };
    },
    computed: {
        ...mapGetters(['getModuleById']),
        module() {
            return this.getModuleById(this.moduleId);
        },
        options() {
            return this.module.renderOptions;
        },
        hasThirdCode: function () {
            return this.module.content.hasThirdCode;
        },
        code: function () {
            return this.module.content.code || '';
        },
        type: function () {
            return this.module.content.type || 0;
        },
        oem: function () {
            return this.module.content.oem;
        },
        hasHttpLinkInCode: function () {
            return this.module.content.hasHttpLinkInCode;
        },
        tisticsHttpInCode: function () {
            return this.module.content.tisticsHttpInCode;
        },
        countRefValue: function () {
            return this.module.content.countRefValue;
        },
        tipData: function () {
            return this.module.content.tipData;
        },
        isFaiHost: function () {
            return this.module.content.isFaiHost;
        },
        existAlimamaAD: function () {
            return this.module.content.existAlimamaAD;
        },
        showCode: function () {
            return this.module.content.showCode;
        },
        encodeHtml: function () {
            return encodeHtml;
        },
        _allowScript: function () {
            return this.module.content._allowScript;
        },
        id() {
            return this.module.id;
        },
        styleId() {
            return this.module.style;
        },
        modulePatternV3() {
            return this.module.patternV3;
        },
        modulePattern() {
            return this.module.pattern;
        },
    },
    watch: {
        module: {
            handler: function () {
                var _this = this;
                Vue.nextTick(() => {
                    if (_this.module.content.type == 0 && _this.module.content.codeChange) {
                        // 模块有数据并且是管理态的时候，手动创建dom节点加载代码，解决管理态代码在v-html中不执行的问题
                        if (typeof _this.$refs.scriptNode != 'undefined' && VITE_APP_MODE !== 'visitor') {
                            // const scriptNode = _this.$refs.scriptNode;
                            var str = _this.showCode;
                            executeScripts(str);
                        }
                    }
                    bindEvent(_this);
                });
            },
            deep: true,
        },
    },
    mounted() {
        //标记初始化的时候执行方法，这时候的代码是原来的代码
        this.module.content.codeChangeInit = true;
        //标记ajax返回的代码是否改变，防止用户改变代码之后，ajax还没返回，就调用了方法，导致获取到的代码还是原来的,标记ajax已经返回数据
        this.module.content.codeChange = true;
        bindEvent(this);
        // 模块有数据并且是管理态的时候，手动创建dom节点加载代码，解决管理态代码在v-html中不执行的问题
        if (typeof this.$refs.scriptNode != 'undefined' && VITE_APP_MODE !== 'visitor') {
            // const scriptNode = this.$refs.scriptNode;
            var str = this.showCode;
            executeScripts(str);
        }
        if (this.type == 0 && this._allowScript) {
            jm(this.$refs.scriptNode).html(this.showCode);
        }
    },
    methods: {
        addCode(e) {
            e.stopPropagation();
            this.$designer.open({
                panelType: 'pattern insert-code',
                styleId: MODULE_STYLE.INSERT_CODE,
                moduleId: this.id,
            });
        },
    },
};

function codeInsert(code, codeInsertLocation) {
    code = decodeHtml(code);
    var reg = /document.write/g;
    code = code.replace(reg, 'Mobi.voidFun');

    // 创建script元素
    var script = document.createElement('script');
    script.type = 'text/javascript';

    // IE不支持DOM文本节点的方法，所以如果是IE的话则使用text属性
    try {
        script.appendChild(document.createTextNode(code));
    } catch (err) {
        script.text = code;
    }
    //插入body底部
    if (codeInsertLocation == 1) {
        document.body.appendChild(script);
    } else if (codeInsertLocation == 2) {
        //插入head标签
        //document.getElementsByTagName("head")[0]是为了兼容IE浏览器
        var head = document.head || document.getElementsByTagName('head')[0];
        head.appendChild(script);
    }
}

function bindEvent(context) {
    if (context.hasThirdCode && context.oem) {
        showTopTips();
    } else if (context.hasThirdCode && !context.oem) {
        showTopTips(3);
    }
    if (context._allowScript) {
        if (context.hasHttpLinkInCode) {
            if (context.tisticsHttpInCode) {
                statisticsHttpInCode(context.id, context.countRefValue);
            }
            showTopTips(1);
        }
    }
    if (
        context.module.content.type != 0 &&
        (context.module.content.codeChange || context.module.content.codeChangeInit)
    ) {
        let content = context.module.content || {};
        let contentRequest = content.request || {};
        if (!contentRequest._safeMode) {
            // 非安全模式下执行插件代码
            codeInsert(context.encodeHtml(context.showCode), context.module.content.type);
        }
    }
    context.module.content.codeChange = false; //调用完方法之后置成false
    context.module.content.codeChangeInit = false; //初始化调用完方法之后置成false
}

function executeScripts(html) {
    const container = document.createElement('div');
    container.innerHTML = html;

    const scripts = container.querySelectorAll('script');

    for (let i = 0; i < scripts.length; i++) {
        const script = document.createElement('script');
        script.type = scripts[i].type || 'text/javascript';
        if (scripts[i].src) {
            script.src = scripts[i].src;
        }
        script.innerHTML = scripts[i].innerHTML;

        document.head.appendChild(script);
        document.head.removeChild(script);
    }
}
</script>

<style lang="scss">
.empty_tips_panel {
    text-align: center;
    padding-top: 1rem;
    padding-bottom: 1.375rem;
}
.empty_tips_panel .text {
    color: #666;
    font-size: 14px;
    margin-bottom: 20px;
}

/*模块2.0访客态空数据提示*/
.cus_Empty_tips_panel {
    height: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.cus_Empty_tips_panel .text {
    color: #666666;
    font-size: 14px;
}

.scriptModuleMinH {
    min-height: 30px;
}
</style>
