<template>
    <div v-if="fileItem.isImage" class="m_custom_search_result_result_value m_custom_search_result_result_photo_wrap">
        <div
            class="m_custom_search_result_result_photo"
            :data-path="fileItem.path"
            :data-name="fileItem.fileName"
            :data-download="fileItem.downloadPath"
            :data-id="fileItem.id"
            :data-picid="fileItem.value"
            :style="imgStyle"
            :class="imgClass"
            @click="imgClick"
        >
        </div>
    </div>

    <a
        v-else-if="fileItem.isPdf"
        class="m_custom_search_result_result_value"
        hidefocus="true"
        rel="nofollow"
        target="_blank"
        :href="fileItem.path"
    >
        {{ fileItem.fileName }}
    </a>
    <a
        v-else-if="!fileItem.isImage && !fileItem.isPdf"
        hidefocus="true"
        rel="nofollow"
        target="_blank"
        :href="fileItem.downloadPath"
        class="m_custom_search_result_result_value"
    >
        {{ fileItem.fileName }}
    </a>
</template>
<script>
export default {
    name: 'FileView',
    props: {
        fileItem: {
            type: Object,
            default() {
                return {};
            },
        },
        imgStyle: {
            type: Object,
            default() {
                return {};
            },
        },
        imgClass: {
            type: String,
            default: '',
        },
    },
    methods: {
        imgClick() {
            this.$emit('img-click');
        },
    },
};
</script>
