<template>
    <div class="photoModule newCarouselMultiPhoto styleForm13" :class="photoSwipeCls">
        <div :id="'photoSwipe' + module.id" class="photoSwipe">
            <div
                id="photoSwipeBox"
                ref="photoSwipeBox"
                class="photoSwipeBox"
                :class="photoSwipeReset"
                :style="photoSwipeBoxStyle"
                @touchstart="touchstart"
                @touchmove="touchmove"
                @touchend="touchend"
            >
                <div v-for="(rowList, pageIndex) in pageList" :key="pageIndex" class="imagePage" :style="pageStyle">
                    <div v-for="(rowOne, rowIndex) in rowList" :key="rowIndex" class="imgRow">
                        <div v-for="(item, index) in rowOne" :key="index" class="imgItem" :style="itemWith">
                            <new-carousel-image-item
                                :key="index"
                                :item-index="item.index"
                                :item="item"
                                :style="itemStyle"
                                :outer-box="true"
                                :text-pos="content.itp"
                                :auto-hide-text="true"
                                :no-lazy-load="noLazyLoad"
                            ></new-carousel-image-item>
                        </div>
                    </div>
                </div>
                <div class="imagePage" :style="pageStyle">
                    <div v-for="(rowTwo, pageIndex) in pageList[0]" :key="pageIndex" class="imgRow">
                        <div v-for="(item, index) in rowTwo" :key="index" class="imgItem" :style="itemWith">
                            <new-carousel-image-item
                                :key="index"
                                :item-index="item.index"
                                :item="item"
                                :style="itemStyle"
                                :outer-box="true"
                                :text-pos="content.itp"
                                :auto-hide-text="true"
                                :no-lazy-load="noLazyLoad"
                            ></new-carousel-image-item>
                        </div>
                    </div>
                </div>
            </div>
            <img-bullet-group
                v-if="pageList.length > 1"
                :bullet-group-style="2"
                :current-index="currentIndex"
                :bullet-length="pageList.length"
                @bullet-click="upDateSwipeIndex"
            ></img-bullet-group>
        </div>
    </div>
</template>

<script>
import NewCarouselImageItem from '../components/NewCarouselImageItem.vue';
import ImgBulletGroup from '../components/ImgBulletGroup.vue';

export default {
    name: 'CarouselType13',
    components: {
        NewCarouselImageItem,
        ImgBulletGroup,
    },
    inject: ['module', 'options'],
    // eslint-disable-next-line vue/require-prop-types
    props: ['photoSwipeList', 'nameList', 'cycleList', 'noLazyLoad'],
    data() {
        return {
            id: this.module.id,
            styleId: this.module.style,
            currentIndex: 0,
            startPos: {},
            endPos: {},
            isScrolling: 0,
            reset: false,
            swipeIndex: 0,
            touchingEnd: true,
        };
    },
    computed: {
        content() {
            return this.module.content;
        },
        mhighs() {
            return this.content.mhighs;
        },
        picScale() {
            return this.content.picScale;
        },
        carouselType() {
            return this.mhighs.carouselSet.carouselType;
        },
        column() {
            return this.content.column;
        },
        row() {
            return this.content.row;
        },
        photoSwipeBoxStyle() {
            var style = {};
            if (this.mhighs.carouselSet.carouselType == 2) {
                style['transition-duration'] = this.mhighs.carouselSet.switchTime + 'ms';
            }
            style['width'] = (this.pageList.length + 1) * 100 + '%';
            style['transform'] = 'translateX(' + (-this.swipeIndex * 100) / (this.pageList.length + 1) + '%)';
            return style;
        },
        photoSwipeReset() {
            return this.reset ? 'photoSwipeReset' : '';
        },
        pageStyle() {
            var style = {};
            style['width'] = 100 / (this.pageList.length + 1) + '%';
            return style;
        },
        photoSwipeCls() {
            var cls = '';
            cls += 'outerBox outerBox' + (parseInt(this.content.itp) + 1);
            cls += ' imgTextType' + (parseInt(this.content.its) + 1);
            return cls;
        },
        itemWith() {
            var style = {};
            style['width'] = 100 / this.column + '%';
            return style;
        },
        pageList() {
            var pageList = [];
            var column = this.column;
            var row = this.row;
            var data = this.cycleList;
            for (let i = 0; i < data.length; i += column * row) {
                let tmpPageList = data.slice(i, i + column * row);
                let tmpPage = [];
                for (let j = 0; j < tmpPageList.length; j += column) {
                    tmpPage.push(tmpPageList.slice(j, j + column));
                }
                pageList.push(tmpPage);
            }
            return pageList;
        },
        itemStyle() {
            if (VITE_APP_MODE !== 'visitor' && this.mhighs.imgMarginSet.t == 1) {
                return 'padding:' + (this.mhighs.imgMarginSet.v + 'rem ') + (this.mhighs.imgMarginSet.h + 'rem');
            } else {
                return '';
            }
        },
    },
    mounted: function () {
        if (this.mhighs.carouselSet.carouselType == 0 || this.mhighs.carouselSet.carouselType == 1) {
            this.setAutoplay();
        }
    },
    methods: {
        upDateSwipeIndex: function (index) {
            if (this.carouselType == 2) {
                var prev = index < this.currentIndex;
                this.switchPhoto(index, prev);
            }
        },
        setAutoplay: function () {
            var _this = this;
            window.clearInterval(this.timer);
            if (this.mhighs.carouselSet.carouselType != 2 && this.pageList.length > 1) {
                var switchTime = this.carouselType == 0 ? 4000 : this.mhighs.carouselSet.aniTime;
                this.timer = window.setInterval(function () {
                    _this.next();
                }, switchTime);
            }
        },
        cleanAutoPlay: function () {
            window.clearInterval(this.timer);
        },
        touchstart(event) {
            var touch = event.targetTouches[0]; //touches数组对象获得屏幕上所有的touch，取第一个touch
            this.isScrolling = 0; //这个参数判断是垂直滚动还是水平滚动
            this.startPos = {
                x: touch.pageX,
                y: touch.pageY,
                time: +new Date(),
            }; //取第一个touch的坐标值
            this.touchingEnd = false;
            this.cleanAutoPlay();
        },
        touchmove(event) {
            //当屏幕有多个touch或者页面被缩放过，就不执行move操作
            if (event.targetTouches.length > 1 || (event.scale && event.scale !== 1)) {
                return;
            }
            var touch = event.targetTouches[0];

            this.endPos = {
                x: touch.pageX - this.startPos.x,
                y: touch.pageY - this.startPos.y,
                time: +new Date(),
            };
            this.isScrolling = Math.abs(this.endPos.x) < Math.abs(this.endPos.y) ? 1 : 0;
            if (this.isScrolling === 0) {
                event.preventDefault();
            }
        },
        touchend() {
            if (this.isScrolling === 0) {
                if (Number(this.endPos.x) > 10 && Number(this.endPos.y) < 40) {
                    this.prev();
                } else if (Number(this.endPos.x) < -10 && Number(this.endPos.y) < 40) {
                    this.next();
                }
                this.endPos.x = 0;
                this.endPos.y = 0;
            }
            this.touchingEnd = true;
            this.setAutoplay();
        },
        next() {
            var tmpIndex = this.currentIndex + 1;
            var _this = this;
            if (tmpIndex == this.pageList.length) {
                tmpIndex = 0;
            }
            if (this.swipeIndex == this.pageList.length) {
                this.swipeIndex = 0;
                this.reset = true;
                this.$nextTick(() => {
                    setTimeout(function () {
                        _this.reset = false;
                        _this.switchPhoto(tmpIndex, false);
                    }, 50);
                });
            } else {
                this.switchPhoto(tmpIndex, false);
            }
        },
        prev() {
            var tmpIndex = this.currentIndex - 1;
            var _this = this;
            if (tmpIndex < 0) {
                tmpIndex = this.pageList.length - 1;
            }
            if (this.swipeIndex == 0) {
                this.swipeIndex = this.pageList.length;
                this.reset = true;
                this.$nextTick(() => {
                    setTimeout(function () {
                        _this.reset = false;
                        _this.switchPhoto(tmpIndex, true);
                    }, 50);
                });
            } else {
                this.switchPhoto(tmpIndex, true);
            }
        },
        switchPhoto(currentIndex, prev) {
            if (currentIndex == 0 && !prev) {
                this.swipeIndex = this.pageList.length;
            } else {
                this.swipeIndex = currentIndex;
            }
            this.currentIndex = currentIndex;
        },
    },
};
</script>

<style lang="scss">
.formStyle42 .styleForm13 {
    width: 100%;
}
.formStyle42 .styleForm13 .photoSwipe {
    text-align: center;
    overflow: hidden;
    position: relative;
    display: block;
    font-size: 0;
    margin: 0.5rem;
}
.formStyle42 .styleForm13 .photoSwipeBox {
    position: relative;
    transition: all 0.5s;
    transition-timing-function: ease;
    width: 100%;
    height: 100%;
    display: flex;
    backface-visibility: hidden;
    transform-style: preserve-3d;
}
.formStyle42 .styleForm13 .photoSwipeBox .imagePage .imgRow {
    width: 100%;
    display: flex;
}
.formStyle42 .styleForm13 .photoSwipeBox .imagePage .imgRow .imgItem {
    display: inline-block;
}
.formStyle42 .styleForm13 .imgItem .photoImageItem .imgLink {
    height: 5.25rem;
}
.formStyle42 .newCarouselMultiPhoto.styleForm13 .photoImageItem {
    padding: 0.25rem;
    display: block;
    width: auto;
    height: 100%;
    box-sizing: border-box;
}
.formStyle42 .newCarouselMultiPhoto.styleForm13 .photoImageItem .imgName {
    font-size: 0.6rem;
}
.formStyle42 .newCarouselMultiPhoto.styleForm13 .photoImageItem .imgDesc {
    font-size: 0.5rem;
    margin: 0;
    margin-top: 0.2rem;
}
.formStyle42 .newCarouselMultiPhoto.styleForm13 .photoImageItem .imgDescBox {
    width: 100%;
    padding: 0.35rem 0.35rem 0.6rem 0.35rem;
    box-sizing: border-box;
    min-height: unset;
}
.formStyle42 .newCarouselMultiPhoto.styleForm13 .photoImageItem .photoImageItemContent {
    border-radius: 0.2rem;
}
</style>
