<template>
    <module-frame :module-id="moduleId">
        <div v-if="render" class="news_list_wrap" :class="isNewAddModuleClass">
            <div
                v-if="(!newsListEmpty && !choiceNewsEmpty) || !manageMode"
                :id="'newsList' + module.id"
                class="newsList news_list_module"
                :class="classes"
            >
                <div :id="wrapId" :class="wrapClasses" :picNum="module.content.pn">
                    <div :class="wrapClasses2" :picNum="module.content.pn">
                        <component :is="styleComponent()" :news-list="newsList" :no-lazy-load="noLazyLoad"></component>
                    </div>
                </div>
            </div>
            <div v-else-if="manageMode && newsListEmpty" class="empty_tips_panel">
                <div class="text">您还没有添加任何文章，赶紧添加吧~</div>
                <button-component active @click.stop="addNew">添加文章</button-component>
            </div>
            <div v-else-if="manageMode && choiceNewsEmpty" class="empty_tips_panel">
                <div class="text">请从右侧面板中选择文章~</div>
                <button-component active @click.stop="addNew">添加文章</button-component>
                <button-component style="margin-left: 20px" @click.stop="openDesigner">选择文章</button-component>
            </div>
            <pagenation
                v-if="module.content.pageIcon && module.content.pn != 5 && module.content.pn != 12"
                :module-id="module.id"
                :total-size="totalSize"
                :page-size="module.content.c"
                :pageno="pageno"
                :open-theme-v3="openThemeV3"
                :ajax-pagenation="true"
                @on-change="changePage"
                @click.native.stop=""
            >
            </pagenation>
        </div>
    </module-frame>
</template>

<script>
import ModuleFrame from '@/modules/frame/index.vue';

import TextList from './components/textList/text-list.vue';
import TimeList from './components/timeList/time-list.vue';
import MaskList from './components/maskList/mask-list.vue';
import HorizonSlideList from './components/horizonSlide/horizon-slide-list.vue';
import BigPicTextList from './components/bigPicTextList/big-pic-text-list.vue';
import PicTextList from './components/picTextList/pic-text-list.vue';
import HeadPicTextList from './components/headPicTextList/head-pic-text-list.vue';
import HeadWordPicTextList from './components/headWordPicTextList/head-word-pic-text-list.vue';
import upAndDownPicTextList from './components/upAndDownPicText/up-down-pic-text-list.vue';
import threePicTextList from './components/threePicList/three-pic-text-list.vue';
import threePicMixTextList from './components/threePicMixText/three-pic-mix-text-list.vue';
import threePicMixPicTextList from './components/threePicMixPicTextList/three-pic-mix-pic-text-list.vue';
import carouselList from './components/carouselList/list.vue';

import Pagenation from '@/components/pagenation.vue';
import { mapFlag, getBitMemory } from '@/shared/flag';

import { getSiteMultiLanguageUrl } from '@/shared/url';

import { ing } from '@/shared/fai';
import { bindImgLazyLoad } from '@/shared/imageLazyload';
import { getSortListForMangae, getSortListForGuest } from '@/api/news';
import {
    setModuleStyleCssList,
    removeModuleStyleCssList,
    setModuleStyleCss,
    removeModuleStyleCss,
} from '@/shared/style';

import { initModuleNewsListItemManage, initModuletimeAxisNewsList, initModuleCardGraphicNewsList } from '../util';

import { removeJumpModuleInfo, bindJumpModuleEvent, getJumpModuleCookieByType } from '../../comm';
import { removeMixNewsStyle, initMixNews, fixNewsJumpLink, fixIphoneMargin } from '../util';
import { MODULE_STYLE, NewsDetailV3ModuleId } from '@/def/module';
import { mapGetters, mapState } from 'vuex';
import { isRecommendedNewsListModule } from '@/modules/type-mapping';
import { COL } from '@/def/col';
const manageMode = VITE_APP_MODE !== 'visitor';
export default {
    name: 'News',
    style: MODULE_STYLE.NEWS_LIST,
    components: {
        TextList,
        MaskList,
        BigPicTextList,
        HeadPicTextList,
        HeadWordPicTextList,
        upAndDownPicTextList,
        threePicTextList,
        threePicMixTextList,
        threePicMixPicTextList,
        HorizonSlideList,
        Pagenation,
        ModuleFrame,
        carouselList,
    },
    provide() {
        return {
            module: this.module,
            options: this.options,
            ndUrl: `/${this.module.extInfo.lanCode}/nd.jsp`,
            nrUrl: `/${this.module.extInfo.lanCode}/nr.jsp`,
            altName: '文章封面图',
            manageMode,
        };
    },
    props: {
        moduleId: {
            type: Number,
            default: -1,
        },
    },
    data() {
        let currentPageModuleIdMap = this.$store.state.currentPageModuleIdMap;
        return {
            render: true,
            isFirstLoad: true, // 是否第一次加载
            pageno: null,
            picNumCash: null,
            manageTotleSize: null,
            successPages: null, // 分页后成功请求回来的page索引
            loadingPath: null,
            noLazyLoad: null,
            manageMode,
            module: currentPageModuleIdMap[this.moduleId],
            options: currentPageModuleIdMap[this.moduleId].renderOptions,
            manageNewsList: [],
        };
    },
    computed: {
        ...mapFlag({
            newSelectCanTopFlag: 0x1,
            isNewAddModule: 0x4,
        }),
        ...mapState('app', ['openThemeV3', 'colId']),
        ...mapGetters(['getModuleById']),
        isNewAddModuleClass() {
            return this.isNewAddModule && this.module.content.pn !== 12 ? ' isNewAddModule' : '';
        },
        classes() {
            return `newsList${this.module.content.pn}`;
        },
        allNewIdList() {
            return this.module.content.nil;
        },
        manageRootNewsList() {
            return this.$store.state.manage?.newsList;
        },
        filterList() {
            // important hack do not delete
            // eslint-disable-next-line no-unused-vars
            const hook = this.module.extInfo.ntlHook;

            const newsTypeList = this.module.content.ntl;

            // important hack do not delete
            // eslint-disable-next-line no-unused-vars
            const pageSize = this.module.content.c;
            let _this = this;
            if (manageMode) {
                let targetList = [];
                let filterType = 0;
                if (this.recommendModule) {
                    filterType = this.module.content.newsSelectType;
                } else {
                    filterType = this.module.content.ns ? 0 : 1;
                }
                if (filterType == 1) {
                    // 使用筛选条件
                    if (this.module.content.ntl.length === 0) {
                        // 没有选中分类,则显示所有文章
                    } else {
                        // 筛选出当前选中的分类下全部文章
                        const noGroup = this.module.content.ntl.includes(0);
                        targetList = this.manageRootNewsList.filter((info) => {
                            const groupIds = JSON.parse(info.groupIds || '[]');
                            if (this.module?.extInfo?.openNewsAudit && info && info.authStatus != 0) {
                                return false;
                            }
                            if (noGroup && groupIds.length == 0) {
                                // 文章无分类，且用户选择了未分类
                                return true;
                            } else if (typeof newsTypeList.find((gid) => groupIds.includes(gid)) != 'undefined') {
                                // 文章的所属分类是用户选择的分类
                                return true;
                            } else if (
                                noGroup &&
                                typeof groupIds.find((gid) => !_this.$store.state.newsGroupList.includes(gid)) ==
                                    'undefined'
                            ) {
                                // 文章的所属分类已经被删掉，且用户选择了未分类
                                return true;
                            } else {
                                return false;
                            }
                        });
                    }
                } else if (filterType == 0) {
                    // 直接添加
                    this.module.content.nil.forEach((id) => {
                        let targetNew = this.manageRootNewsList.filter((info) => info.id === id)[0];
                        !!targetNew && targetList.push(targetNew);
                    });
                } else {
                    targetList = this.module.extInfo.newsList;
                }

                this.$nextTick(() => {
                    this.bindEvent();
                    this.initModuleStyleEvent();
                });
                const nowTime = new Date().getTime();
                targetList = targetList.filter(
                    (value) => new Date(value.date).getTime() <= nowTime || !getBitMemory(value.flag, 0x400)
                );
                return targetList;
            }

            return this.module.extInfo.newsList;
        },
        isSortByDate() {
            return [0, 2].includes(this.module.content.sortKey);
        },
        newsList() {
            // 每篇页数
            const pageSize = this.module.content.c;
            let targetList = this.filterList;
            let start = 0,
                pageno = this.pageno,
                end;

            if (manageMode) {
                return this.manageNewsList; // 直接展示请求回来的列表，涉及到排序，不好处理全局的

                // let copySuccessPages = this.copyData(this.successPages);

                // copySuccessPages.sort((a, b) => {
                //     return a - b;
                // }); // 排出从小到大的页码
                // pageno = copySuccessPages.indexOf(pageno) + 1; // 拿出当前页面在copySuccessPages的下标（可视为当前页码对应的真正下标）

                // targetList = this.copyData(this.filterList);
                // // 1、用 concat 复制的数组会有浏览器差异问题，不同浏览器输出的顺序不一样，改成用 jm.extend 处理
                // // 2、sort 方法也有浏览器差异，用自己的写插入排序算法实现降序排序

                // if (!this.module.content.ns && this.isSortByDate) {
                //     // 通过筛选时则先根据文章时间排序
                //     targetList = this.insertSort(targetList, 'date');
                // }

                // // 根据 top 排序：最新的置顶排在前面
                // targetList = this.insertSort(targetList, 'top');
            }
            start = Math.max(pageSize * (pageno - 1), 0);
            end = start + pageSize;

            targetList = targetList.slice(start, end);
            return targetList.filter((item) => {
                return !!item.id;
            });
        },

        totalSize() {
            return manageMode ? this.manageTotleSize : this.module.extInfo.totalSize;
        },
        wrapClasses() {
            return this.module.content.pn === 5 ? 'J_newsListPanel f-cardGraphicNews' : '';
        },
        wrapId() {
            return this.module.content.pn === 5 ? `J_newsListPanel${this.module.id}` : '';
        },
        wrapClasses2() {
            return this.module.content.pn === 5 ? 'J_newsListSlidePanel f-cardGraphicNewsSlide' : '';
        },
        choiceNewsEmpty() {
            return this.filterList.length < 1;
        },
        newsListEmpty() {
            // 当模块是第一个，且未选中任何文章时，这里会 true，原因是没有实时监听到 manageRootNewsList 的变化，且没法得知 newsList 的实际数量
            return manageMode ? this.allNewsCount < 1 : false;
        },
        allNewsCount() {
            return this.manageRootNewsList.length || this.$store.state.allNewsCount;
        },
        newsInfo() {
            return this.module.content.mhighs.ni;
        },
        newsTime() {
            return this.module.content.mhighs.ntime;
        },
        newsHeadTitle() {
            return this.module.content.mhighs.nht;
        },
        moduleStyle() {
            // 暂时只用于拖动图片的逻辑中
            if (!this.isNewAddModule) {
                const picNum = this.module.content.pn;
                return picNum || 0;
            } else {
                var picStyleNum = this.module.content.pn;
                const headStyle = this.module.content.hs;
                if (picStyleNum === 0) {
                    if (headStyle === 1) {
                        picStyleNum = 3;
                    } else if (headStyle === 2) {
                        picStyleNum = 1;
                    } else if (headStyle === 3) {
                        picStyleNum = 10;
                    } else {
                        picStyleNum = 0;
                    }
                } else if (picStyleNum === 2) {
                    if (headStyle === 1) {
                        picStyleNum = 4;
                    } else if (headStyle === 3) {
                        picStyleNum = 11;
                    } else {
                        picStyleNum = 2;
                    }
                }
                return picStyleNum || 2;
            }
        },
        recommendModule() {
            return isRecommendedNewsListModule(this.module.id);
        },
        smartSelect() {
            if (this.recommendModule) {
                return this.module.content.newsSelectType == 2;
            } else {
                return false;
            }
        },
    },
    watch: {
        'module.content': {
            deep: true,
            handler(newVal) {
                if (manageMode) {
                    this.noLazyLoad = true;
                    if (newVal.pn != this.picNumCash || newVal.pn == 5 || newVal.pn == 12) {
                        // 改变模块样式后要重新渲染
                        this.render = false;
                        this.$nextTick(() => (this.render = true));
                        this.picNumCash = newVal.pn;
                    }

                    if (newVal.mr.s) {
                        // 更多链接
                        if (this.module.content.cid == 103 && this.module.content.curl != '') {
                            this.options.args.bannerMore =
                                'javascript:top.Fai.ing("管理态不支持跳转外部链接，请通过“预览”进行访问", true);';
                        } else {
                            this.options.args.bannerMore = this.module.extInfo.href || 'javascript:;';
                            this.options.args.reqArgs = this.module.extInfo.reqArgs || '';
                        }
                    } else {
                        this.options.args.bannerMore = '';
                    }
                    if (this.isNewAddModule) {
                        this.styleComponent();
                    }

                    this.refreshTotalSize();
                    this.$nextTick(() => this.bindEvent());
                }
            },
        },
        'module.content.ntl': {
            handler: function () {
                if (manageMode) {
                    this.getSortList();
                }
            },
        },
        'module.content.nil': {
            handler() {
                if (manageMode) {
                    this.changePage(this.pageno);
                }
            },
        },
        'module.content.sortKey': {
            handler() {
                this.changePage(this.pageno);
            },
        },
        'module.content.sortType': {
            handler() {
                this.changePage(this.pageno);
            },
        },
        manageRootNewsList: {
            handler() {
                if (manageMode) {
                    let key = `moduleNews${this.moduleId}`;
                    let moduleNews = sessionStorage.getItem(key);
                    if (moduleNews != null) {
                        // 说明是tool点击了添加文章，这里重新获取数据
                        sessionStorage.removeItem(key);
                        this.changePage(this.pageno);
                    }
                }
            },
        },
        render: function () {
            // 重新渲染后绑定时间
            this.$nextTick(() => {
                this.bindEvent();
                this.initModuleStyleEvent();
            });
        },
    },
    created() {
        if (manageMode) {
            this.$store.commit('batchUpdateNewsList', this.module.extInfo.newsList);
            this.manageNewsList = this.module.extInfo.newsList;
        }
        this.pageno = this.module.extInfo.pageno; //curPageno --> extInfo.pageno

        this.picNumCash = this.module.content.pn;
        this.manageTotleSize = this.module.extInfo.totalSize;
        this.successPages = [this.module.extInfo.pageno]; // 分页后成功请求回来的page索引  curPageno --> extInfo.pageno
        this.loadingPath = this.options.loadingPath;
        this.noLazyLoad = this.options.isFirstScreen;
    },
    async mounted() {
        let cookiePage = getJumpModuleCookieByType(this.module.id) || 1;
        const bindingNewsId = this.getNewsDetailBindingNewsId();

        if (isRecommendedNewsListModule(this.module.id)) {
            cookiePage = jm.getCookie(`module${this.module.id}_${bindingNewsId}_page`) || 1;
        }

        if (cookiePage != this.pageno && !manageMode) {
            // 场景：线上访客态页面缓存导致返回时数据是旧的，没走到inc那边，需重新获取数据
            this.changePage(parseInt(cookiePage));
        } else {
            this.bindEvent();
            this.initModuleStyleEvent();
        }

        if (isRecommendedNewsListModule(this.module.id)) {
            removeJumpModuleInfo(`${this.module.id}_${bindingNewsId}`);
        } else {
            removeJumpModuleInfo(this.module.id);
        }

        if (manageMode && this.isNewAddModule) {
            this.initSeparatorline(); // 分割线样式
            this.initNewsCusStyle(); // 自定义文字样式
            this.initPicRadius(); // 图片圆角
            this.initPicScale(); // 图片缩放
            this.initPicSpacing(); // 图片间距
            this.initLineHeight(); // 样式1以及样式1结合样式的行高
        }
    },
    methods: {
        updateLoadingState(data) {
            this.$store.dispatch('manage/updateLoadingState', data);
        },
        async changePage(pageno) {
            // 如果当前页已经是请求成功过，则不请求了
            if (this.successPages.includes(pageno) && !manageMode) {
                this.pageno = pageno;
                this.$nextTick(() => {
                    this.bindEvent();
                    this.initModuleStyleEvent();
                });
                return;
            }

            let param = {
                pageno,
                pageSize: this.module.content.c,
                newsSelect: this.module.content.ns,
                moduleId: this.module.id,
                sortKey: this.module.content.sortKey,
                sortType: this.module.content.sortType,
            };

            if (this.smartSelect) {
                let { seed } = this.module.extInfo;
                if (seed != 0) {
                    param.seed = seed;
                }
            }

            if (this.getNewsDetailBindingNewsId() !== -1) {
                param.newsId = this.getNewsDetailBindingNewsId();
            }

            if (manageMode) {
                if (this.module.content.ns) {
                    param.newsIdList = JSON.stringify(this.module.content.nil);
                } else {
                    param.newsTypeList = JSON.stringify(this.module.content.ntl);
                }
            }

            let result = await (manageMode ? getSortListForMangae : getSortListForGuest)(param);
            let { success, data } = result;

            let pageSize = this.module.content.c;
            let start = Math.max(pageSize * (pageno - 1), 0);

            if (success) {
                if (manageMode) {
                    this.$store.commit('batchUpdateNewsList', data.newsList);
                    this.module.extInfo.ntlHook = !this.module.extInfo.ntlHook;
                    this.manageNewsList = data.newsList;
                } else {
                    // 访客态
                    if (pageno * pageSize > this.module.extInfo.newsList.length) {
                        let addLength = pageno * pageSize - this.module.extInfo.newsList.length;
                        for (let i = 0; i <= addLength; i++) {
                            this.module.extInfo.newsList.push({});
                        }
                    }
                    data.newsList.forEach((info, index) => {
                        this.module.extInfo.newsList.splice(start + index, 1, info);
                    });
                }
                this.successPages.push(pageno);
                this.pageno = pageno;
                this.$nextTick(() => {
                    this.bindEvent();
                    this.initModuleStyleEvent();
                });
            } else {
                ing('系统繁忙，请稍候重试', false);
            }
        },
        styleComponent() {
            if (!this.isNewAddModule) {
                const picStyle = this.module.content.ps;
                const picNum = this.module.content.pn;
                if (picStyle) {
                    switch (picNum) {
                        case 1:
                            return HeadWordPicTextList;
                        case 2:
                            return PicTextList;
                        case 3:
                            return HeadPicTextList;
                        case 4:
                            return BigPicTextList;
                        case 5:
                            return HorizonSlideList;
                        case 6:
                            return MaskList;
                        case 7:
                            return TimeList;
                        default:
                            return TextList;
                    }
                } else {
                    switch (picNum) {
                        case 0:
                            return TextList;
                        default:
                            return TextList;
                    }
                }
            } else {
                var picStyleNum = this.module.content.pn;
                const headStyle = this.module.content.hs;
                if (picStyleNum === 0) {
                    if (headStyle === 1) {
                        picStyleNum = 3;
                    } else if (headStyle === 2) {
                        picStyleNum = 1;
                    } else if (headStyle === 3) {
                        picStyleNum = 10;
                    } else {
                        picStyleNum = 0;
                    }
                } else if (picStyleNum === 2) {
                    if (headStyle === 1) {
                        picStyleNum = 4;
                    } else if (headStyle === 3) {
                        picStyleNum = 11;
                    } else {
                        picStyleNum = 2;
                    }
                }
                switch (picStyleNum) {
                    case 0:
                        return TextList;
                    case 1:
                        return HeadWordPicTextList;
                    case 2:
                        return PicTextList;
                    case 3:
                        return HeadPicTextList;
                    case 4:
                        return BigPicTextList;
                    case 5:
                        return HorizonSlideList;
                    case 6:
                        return MaskList;
                    case 7:
                        return TimeList;
                    case 8:
                        return upAndDownPicTextList;
                    case 9:
                        return threePicTextList;
                    case 10:
                        return threePicMixTextList;
                    case 11:
                        return threePicMixPicTextList;
                    case 12:
                        return carouselList;
                    default:
                        return PicTextList;
                }
            }
        },
        bindEvent() {
            if (manageMode) {
                // 去掉上次的状态
                removeMixNewsStyle({ moduleId: this.module.id });
            }

            if (this.module.content.ps && !this.module.content.sl) {
                initMixNews({ moduleId: this.module.id, imgWidth: 113 });
            }
            fixIphoneMargin({ moduleId: this.module.id });
            if (!this.module.content.ps) {
                fixNewsJumpLink(this.module.id, this.module.extInfo.lanCode);
            }

            if (manageMode && this.options.designMode) {
                const lnUrl = getSiteMultiLanguageUrl();
                initModuleNewsListItemManage({
                    newsParent: `newsList${this.module.id}`,
                    news: 'newsLine',
                    newsChild: 'lineBody',
                    moduleId: this.module.id,
                    frameSrcUrl: `${lnUrl}/manage_v2/newsEdit.jsp?ram=${Math.random()}&mobiSettingState=1`,
                    newsSelect: this.module.content.ns,
                    newSelectCanTop: this.newSelectCanTopFlag,
                    version2: true,
                    smartSelect: this.smartSelect,
                });

                $(`#module${this.module.id} a:not([onclick*="JZ.setUrlArgToCookie"])`)
                    .off('click.n')
                    .on('click.n', (e) => {
                        if (this.$designer.checkSaveBar()) {
                            e.preventDefault();
                            return false;
                        }
                    });
            }

            if (!this.noLazyLoad) {
                bindImgLazyLoad(`module${this.module.id}`);
            }

            bindJumpModuleEvent(this.module.id, this.module.style, {
                ...this,
                newsId: this.getNewsDetailBindingNewsId(),
            });
        },
        initModuleStyleEvent() {
            const picNum = this.module.content.pn;

            if (picNum === 7) {
                initModuletimeAxisNewsList({
                    moduleId: this.module.id,
                    picNum: picNum,
                });
            } else if (picNum === 5) {
                initModuleCardGraphicNewsList({
                    moduleId: this.module.id,
                    picNum: picNum,
                });
            }
        },
        addNew() {
            const lnUrl = getSiteMultiLanguageUrl();
            if (!this.module.content.ns) {
                // 使用筛选条件
                this.$designer.checkSaveBeforePopupWindow(
                    {
                        title: '添加文章',
                        frameSrcUrl: `${lnUrl}/manage_v2/newsEdit.jsp?mGroupTypeIds=${JSON.stringify(
                            this.module.content.ntl
                        )}&mobiSettingState=1&ram=${Math.random()}`,
                        callArgs: 'newsList',
                        width: 920,
                        height: 682,
                        version: 2,
                        closeFunc: this.afterAddNews,
                    },
                    true
                );
            } else {
                // 直接添加
                this.$designer.checkSaveBeforePopupWindow(
                    {
                        title: '添加文章',
                        frameSrcUrl: `${lnUrl}/manage_v2/newsEdit.jsp?_nmid=${
                            this.module.id
                        }&src=1&mobiSettingState=1&ram=${Math.random()}`,
                        callArgs: 'newsList',
                        width: 920,
                        height: 682,
                        version: 2,
                        closeFunc: this.afterAddNews,
                    },
                    true
                );
            }
        },
        afterAddNews(result) {
            if (typeof result != 'undefined') {
                this.updateLoadingState({ loading: true });
                result = $.parseJSON(result);
                let newDataList = result.newDataList || [];
                let addNewsIdList = newDataList.map((item) => {
                    return item.id;
                });
                this.module.content.nil.splice(this.module.content.nil, 0, ...addNewsIdList);
                this.module.content.ntl.splice(this.module.content.ntl, 0, ...addNewsIdList);
                setTimeout(() => {
                    this.updateLoadingState({ loading: false });
                });
            }
        },

        refreshTotalSize() {
            this.module.extInfo.ntlHook = !this.module.extInfo.ntlHook;
            if (this.module.content.ns && !this.smartSelect) {
                this.manageTotleSize = this.filterList.length;
            }
        },

        openDesigner(event, noAnimation = false) {
            if (manageMode) {
                this.$designer.open({
                    panelType: 'pattern news basis',
                    moduleId: this.module.id,
                    styleId: this.module.style,
                    noAnimation,
                });
            }
        },
        copyData(data) {
            var protoToStr = Object.prototype.toString;
            if (protoToStr.call(data) === '[object Object]') {
                return $.extend(true, {}, data);
            } else if (protoToStr.call(data) === '[object Array]') {
                return $.extend(true, [], data);
            } else {
                return data;
            }
        },
        insertSort(arr, key) {
            // 降序
            var len = arr.length;
            for (var i = 1; i < len; i++) {
                var temp = arr[i];
                var j = i - 1; //默认已排序的元素
                while (j >= 0 && arr[j][key] < temp[key]) {
                    //在已排序好的队列中从后向前扫描
                    arr[j + 1] = arr[j]; //已排序的元素小于新元素，将该元素移到一下个位置
                    j--;
                }
                arr[j + 1] = temp;
            }
            return arr;
        },
        initNewsCusStyle: function () {
            this.changeNewsText('newsInfo');
            this.changeNewsText('newsTime');
            this.changeNewsText('newsHeadTitle');
            this.changeNewsTextColor('newsInfo');
            this.changeNewsTextColor('newsTime');
            this.changeNewsTextColor('newsHeadTitle');
        },
        initPicRadius: function () {
            let id = this.module.id;
            var type = this.module.content.prt;
            var radius = this.module.content.pr;
            let classStr = '';
            if (this.module.content.pts != 3) {
                var classStr2 = '.onlyPicRadiusClass';
                var classStr3 = '.picTopRadiusClass';
                var classStr4 = '.picBottomRadiusClass';
                classStr = '.picRadiusClass';
                if (type == 1) {
                    setModuleStyleCssList(id, [
                        {
                            cls: classStr,
                            key: 'border-radius',
                            value: radius + 'px',
                        },
                        {
                            cls: classStr2,
                            key: 'border-radius',
                            value: radius + 'px' + ' ' + radius + 'px 0 0',
                        },
                        {
                            cls: classStr3,
                            key: 'border-radius',
                            value: radius + 'px ' + ' ' + radius + 'px 0 0',
                        },
                        {
                            cls: classStr4,
                            key: 'border-radius',
                            value: '0 0 ' + radius + 'px ' + radius + 'px',
                        },
                    ]);
                } else {
                    removeModuleStyleCssList(id, [
                        { cls: classStr, key: 'border-radius' },
                        { cls: classStr2, key: 'border-radius' },
                        { cls: classStr3, key: 'border-radius' },
                        { cls: classStr4, key: 'border-radius' },
                    ]);
                }
            } else {
                classStr = '.picRadiusClass';
                if (type == 1) {
                    setModuleStyleCssList(id, [
                        {
                            cls: classStr,
                            key: 'border-radius',
                            value: radius + 'px',
                        },
                    ]);
                } else {
                    removeModuleStyleCssList(id, [{ cls: classStr, key: 'border-radius' }]);
                }
            }
        },
        initPicScale: function () {
            var picScaleType = this.module.content.pst;
            var className = '.picScaleClass';
            var picScaleTypeStyle;
            var id = this.module.id;
            switch (picScaleType) {
                case 0:
                    picScaleTypeStyle = 'cover';
                    break;
                case 1:
                    picScaleTypeStyle = 'contain';
                    break;
                case 2:
                    picScaleTypeStyle = '100% 100%';
                    break;
                case 3:
                    picScaleTypeStyle = 'auto';
                    break;
            }

            if (picScaleType != 0) {
                setModuleStyleCssList(id, [
                    {
                        cls: className,
                        key: 'background-size',
                        value: picScaleTypeStyle + '!important',
                    },
                    {
                        cls: className,
                        key: 'background-color',
                        value: '#f2f2f2 !important',
                    },
                ]);
            } else {
                removeModuleStyleCssList(id, [
                    { cls: className, key: 'background-size' },
                    { cls: className, key: 'background-color' },
                ]);
            }
        },
        initLineHeight: function () {
            var id = this.module.id;
            var lineHeightType = this.module.extInfo.lineHeightType;
            var lineHeightValue = this.module.extInfo.lineHeightValue;
            var classStr = '.lineHeightStyle';
            if (lineHeightType == 1) {
                if (!this.openThemeV3) {
                    setModuleStyleCssList(id, [
                        {
                            cls: classStr,
                            key: 'height',
                            value: lineHeightValue + 'px',
                        },
                    ]);
                } else {
                    setModuleStyleCssList(id, [
                        {
                            cls: classStr,
                            key: 'height',
                            value: lineHeightValue + 'rem',
                        },
                    ]);
                }
            } else {
                removeModuleStyleCssList(id, [{ cls: classStr, key: 'height' }]);
            }
        },
        initPicSpacing: function () {
            let id = this.module.id;
            var type = this.module.content.picSpacingType;
            var spacing = this.module.content.picSpacing;
            var classStr = '.isNewAddModule .up_down_pic_text.isColumn2 .newsLine:nth-child(2n)';
            if (type == 1) {
                setModuleStyleCssList(id, [
                    {
                        cls: classStr,
                        key: 'margin-left',
                        value: spacing + 'px',
                    },
                ]);
            } else {
                removeModuleStyleCssList(id, [{ cls: classStr, key: 'margin-left' }]);
            }
        },
        initSeparatorline: function () {
            var borderType = this.module.content.st;
            var className = '.g_separator';
            var borderColor = this.module.content.sc;
            var borderSize = this.module.content.ss;
            var borderStyle = this.module.content.sstyle;
            var borderStyleName = 'solid';
            var id = this.module.id;
            switch (borderStyle) {
                case 0:
                    borderStyleName = 'solid';
                    break;
                case 1:
                    borderStyleName = 'dotted';
                    break;
                case 2:
                    borderStyleName = 'dashed';
                    break;
            }

            if (borderType == 1) {
                setModuleStyleCssList(id, [
                    { cls: className, key: 'background', value: 'none' },
                    {
                        cls: className,
                        key: 'border-top-style',
                        value: borderStyleName,
                    },
                    {
                        cls: className,
                        key: 'border-top-color',
                        value: borderColor,
                    },
                    {
                        cls: className,
                        key: 'border-top-width',
                        value: borderSize + 'px',
                    },
                ]);
            } else {
                removeModuleStyleCssList(id, [
                    { cls: className, key: 'border-top-style' },
                    { cls: className, key: 'border-top-color' },
                    { cls: className, key: 'border-top-width' },
                ]);
            }
        },
        changeNewsText(textType) {
            var classStr = '';
            var data = [];
            switch (textType) {
                case 'newsInfo':
                    classStr = '.g_newsInfo';
                    data = this.newsInfo;
                    break;
                case 'newsTime':
                    classStr = '.mixNewsStyleTime';
                    data = this.newsTime;
                    break;
                case 'newsHeadTitle':
                    classStr = '.mixNewsStyleTitle1';
                    data = this.newsHeadTitle;
                    break;
            }

            this.changeWordStyle(classStr, data);
        },
        changeNewsTextColor(textType) {
            var classStr = '';
            var data = [];
            switch (textType) {
                case 'newsInfo':
                    classStr = '.g_newsInfo';
                    data = this.newsInfo;
                    break;
                case 'newsTime':
                    classStr = '.mixNewsStyleTime';
                    data = this.newsTime;
                    break;
                case 'newsHeadTitle':
                    classStr = '.mixNewsStyleTitle1';
                    data = this.newsHeadTitle;
                    break;
            }
            this.changeColor(classStr, data);
        },
        changeWordStyle(classStr, data) {
            let id = this.module.id;
            if (data.type == 1) {
                setModuleStyleCssList(id, [
                    {
                        cls: classStr,
                        key: 'font-size',
                        value: data.nlfs + 'px',
                    },
                    {
                        cls: classStr,
                        key: 'line-height',
                        value: data.nlflh + 'px',
                    },
                    {
                        cls: classStr,
                        key: 'font-weight',
                        value: data.nlfb == 1 ? 'bold' : 'normal',
                    },
                    {
                        cls: classStr,
                        key: 'text-decoration',
                        value: data.lfd == 1 ? 'underline' : 'none',
                    },
                    {
                        cls: classStr,
                        key: 'font-style',
                        value: data.nlfi == 1 ? 'italic' : 'normal',
                    },
                ]);
            } else {
                removeModuleStyleCssList(id, [
                    { cls: classStr, key: 'font-size' },
                    { cls: classStr, key: 'line-height' },
                    { cls: classStr, key: 'font-weight' },
                    { cls: classStr, key: 'text-decoration' },
                    { cls: classStr, key: 'font-style' },
                ]);
            }
        },
        changeColor(classStr, data) {
            let id = this.module.id;

            if (data.type == 1) {
                setModuleStyleCss(id, classStr, 'color', data.lfc);
            } else {
                removeModuleStyleCss(id, classStr, 'color');
            }
        },
        //获取对应文章分类的文章
        async getSortList() {
            let param = {
                pageno: this.pageno,
                pageSize: this.module.content.c,
                newsSelect: this.module.content.ns,
                moduleId: this.module.id,
                sortKey: this.module.content.sortKey,
                sortType: this.module.content.sortType,
            };

            if (this.recommendModule) {
                let { seed } = this.module.extInfo;
                if (seed != 0) {
                    param.seed = seed;
                }
            }

            if (this.getNewsDetailBindingNewsId() !== -1) {
                param.newsId = this.getNewsDetailBindingNewsId();
            }

            if (manageMode) {
                if (this.module.content.ns) {
                    param.newsIdList = JSON.stringify(this.module.content.nil);
                } else {
                    param.newsTypeList = JSON.stringify(this.module.content.ntl);
                }
            }

            let result = await (manageMode ? getSortListForMangae : getSortListForGuest)(param);
            let { success, data } = result;
            if (success) {
                this.$store.commit('batchUpdateNewsList', data.newsList);
                this.manageTotleSize = data.totalSize;
                if (manageMode) {
                    this.manageNewsList = data.newsList;
                }
            } else {
                ing('系统繁忙，请稍候重试', false);
            }
        },
        // 推荐文章列表模块在文章详情页面下需要获取 文章详情绑定的 newsId 进行分页
        getNewsDetailBindingNewsId() {
            if (isRecommendedNewsListModule(this.module.id) && this.colId === COL.SYS_NEWS_DETAIL_V3) {
                const newsDetailModule = this.getModuleById(NewsDetailV3ModuleId);
                if (newsDetailModule && (newsDetailModule.renderOptions.newsInfo?.id ?? 0)) {
                    return newsDetailModule.renderOptions.newsInfo?.id;
                }
                return -1;
            }
            return -1;
        },
    },
};
</script>

<style>
.newsList .mixNewsStyleLine .mixNewsStyleImgBoxWrap {
    width: auto;
    height: auto;
    overflow: hidden;
    max-width: 100%;
    display: block;
    position: relative;
}
.newsList .mixNewsStyleLine .mixNewsStyleImgBoxWrap1 {
    width: auto;
    height: auto;
}
.newsList .empty_tips_panel {
    text-align: center;
    padding-top: 40px;
    padding-bottom: 55px;
}
.newsList .empty_tips_panel .text {
    color: #666;
    font-size: 14px;
    margin-bottom: 20px;
}
.newsList .newsElementsPanel .ne_newsComment:before,
.newsList .newsElementsPanel .ne_newsAuthor:before {
    margin-right: 0.1rem;
}
.news_list_wrap .newsList .largeImageNewsStyle .mixNewsStyleImgBox6 .newsSortPanel:before {
    margin-right: 0.225rem;
}
.news_list_wrap .newsList .largeImageNewsStyle .mixNewsStyleImgBox6 .newsSortPanel:after {
    margin-right: 0.225rem;
}
.news_list-pic-text .newsElementsPanel .ne_newsComment:before,
.news_list-pic-text .newsElementsPanel .ne_newsAuthor:before {
    margin-right: -0.2rem;
}
.news_list-pic-text .line.newsLine .lineBodyLink .lineBody .g_topFlag.onlyTitle {
    margin-top: 0.25rem;
}
.newsList .newsElementsPanel .ne_newsTime.fix1 {
    line-height: 0.8625rem;
}
.newsList .newsElementsPanel .ne_newsTime.fix2 {
    line-height: 1.55256rem;
}
.isNewAddModule .newsList .newsElementsPanel .ne_newsTime.fix1 {
    line-height: normal;
}
.isNewAddModule .newsList .newsElementsPanel .ne_newsTime.fix2 {
    line-height: normal;
}
.newsList .text_list .line {
    padding-left: 1.3rem;
}
</style>
