import { logDog } from '@/shared/log';
import { getProductListPageData } from '@/modules/shared/moduleProduct';
import { delProductApi, batchSetTopApi } from '@/api/product';
import { isModuleV2 } from '@/manage/module-edit-panel/utils/module';
import { getSiteMultiLanguageUrl } from '@/shared/url';
import { successHandle } from '@/shared/fai';
import { popupWindowVersionTwo } from '@/shared/popupWindow/index';
import { initProductSwipe } from './swipe';
import { getRouterData } from '@/features/router/universal';
import { reloadPage } from '@/features/router/universal';

import { encodeHtmlJs } from '@/shared/util';
import { encodeHtml } from '@/shared/util';

export const editProduct = function (frameSrcUrl, productId) {
    logDog(100073, 0);
    if (Vue.prototype.$designer.checkSaveBar()) {
        return;
    }

    popupWindowVersionTwo.createPopupWindow({
        title: '编辑产品',
        frameSrcUrl: frameSrcUrl + '&id=' + productId + '&mobiSettingState=1',
        width: 840,
        height: 680,
    });
};

//产品的编辑入口(产品展示模块2.0)
export const initModuleProductListItemManageV2 = function (options) {
    if (!options) {
        return;
    }
    if (!options.moduleId) {
        return;
    }

    $('#' + options.productParent)
        .find('.' + options.product)
        .each(function () {
            var that =
                options.productChild == 'mProductTextForm'
                    ? $(this)
                    : $(this)
                          .find('.' + options.productChild)
                          .eq(0);
            if ($(this).hasClass('blankDiv')) return;

            $(this)
                .mouseover(function () {
                    var productId = $(this).attr('productId'),
                        productName = $(this).attr('productName'),
                        topClassName = $(this).attr('topClassName'),
                        topSwitch = $(this).attr('topSwitch');

                    var productJson = [
                        {
                            operationText: '编辑产品',
                            className: 'productListEdit',
                            onClick: () => {
                                logDog(100073, 0);
                                editProductV2(options.frameSrcUrl, productId, options.moduleId);
                            },
                        },
                        {
                            operationText: '删除产品',
                            className: 'productListClose',
                            onClick: () => {
                                delProduct(`${productId}`, `${encodeHtmlJs(productName)}`, '', 0, true);
                            },
                        },
                    ];
                    var topOperat = true;
                    if (options.productSelect) {
                        topOperat = false;
                    }
                    if (topOperat) {
                        productJson.push({
                            operationText: topSwitch == 'off' ? '取消置顶' : '设为置顶',
                            operationTitle: topSwitch == 'off' ? '当前产品已置顶，点击取消' : '设为置顶',
                            display: topSwitch == 'off' ? 'text' : '',
                            className: '' + topClassName + '',
                            onClick: () => {
                                setProductTopV2(productId, `${topSwitch}`, '', options.moduleId);
                            },
                        });
                    }
                    options.extCls = 'productListEditLayer';
                    Vue.prototype.$layer.addEditLayer(that, productJson, 6, options);
                })
                .mouseleave(function () {
                    Vue.prototype.$layer.removeEditLayer(that);
                });
        });
};

export const editProductV2 = function (frameSrcUrl, productId) {
    if (Vue.prototype.$designer.checkSaveBar()) {
        return;
    }
    popupWindowVersionTwo.createPopupWindow({
        title: '编辑产品',
        frameSrcUrl: frameSrcUrl + '&id=' + productId + '&mobiSettingState=1',
        width: 840,
        height: 680,
        closeFunc(val) {
            if (!val) return;
            var data = JSON.parse(val);
            window.$store.dispatch('batchUpdateProductListModule', [data], true);
            window.location.reload();
        },
    });
};

/*
产品删除
*/
export const delProduct = function (id, name, refreshUrl, jumpMode, _isModuleV2) {
    Vue.prototype.$layer.removeAllEditLayer();
    if (Vue.prototype.$designer.checkSaveBar()) {
        return;
    }
    if (name.length > 15) {
        name = name.slice(0, 15) + '...';
    }
    name = encodeHtml(name);
    new Fai.top.Vue().$createDialog(
        {
            confirmButton: { text: '确 定' },
            cancelButton: { text: '取 消' },
            content: '将确定将该产品放入回收站吗？</p>', //'将从网站后台中删除该产品，删除后无法找回，<p style="text-align: left;color:#333;">确定删除？</p>'
            async onConfirm() {
                Fai.ing('正在处理中，请稍候...', false);
                let delImgs = this.delImgs;
                let params = {
                    delImgs,
                    id,
                };
                let result = await delProductApi(params);
                let { success } = result;
                if (success) {
                    successHandle(result, '删除成功', '', refreshUrl, jumpMode, 1);
                    if (isModuleV2(3) && _isModuleV2) {
                        // 2.0模块重刷所有绑定了该产品的产品列表模块
                        Object.values(window.$store.state.currentPageModuleIdMap).forEach(function (module) {
                            if (module.style == 3) {
                                for (var i = module.extInfo.productIdList.length - 1; i >= 0; i--) {
                                    if (module.extInfo.productIdList[i] == id) {
                                        module.extInfo.productIdList.splice(i, 1);
                                        module.extInfo.productHook = !module.extInfo.productHook;
                                        window.$store.commit('updateAllProductCount', --Fai.top._allProductCount);
                                        break;
                                    }
                                }
                                if (module.extInfo.productIdList.length < 1) {
                                    module.extInfo.refreshModuleHook = !module.extInfo.refreshModuleHook;
                                }
                            }
                        });

                        // 在全部产品中删除
                        var productListSimple = window.$store.state.product.productListSimple;
                        for (var i = productListSimple.length - 1; i >= 0; i--) {
                            if (id == productListSimple[i].id) {
                                productListSimple.splice(i, 1);
                                break;
                            }
                        }
                    } else {
                        reloadPage();
                    }
                } else {
                    Fai.ing(result.msg, false);
                }
            },
            data: {
                delImgs: false,
            },
        },
        function (createElement) {
            var self = this;
            return createElement(
                'div',
                {
                    slot: 'description',
                    style: 'height: 26px;text-align: left;',
                },
                [
                    createElement(
                        'checkbox-component',
                        {
                            props: {
                                value: this.delImgs,
                                trueValue: true,
                                falseValue: false,
                            },
                            on: {
                                input(e) {
                                    self.delImgs = e;
                                },
                            },
                        },
                        '包括产品图片'
                    ),
                ]
            );
        }
    );
};

/*
产品置顶
*/
export const setProductTop = async function (id, topSwitch, refreshUrl) {
    Vue.prototype.$layer.removeAllEditLayer();
    if (Vue.prototype.$designer.checkSaveBar()) {
        return;
    }
    Fai.ing('正在设置……', false);
    let result = await batchSetTopApi({
        id,
        top: topSwitch,
    });
    if (result.success) {
        successHandle(result, '修改成功', '', refreshUrl, 0, 1);
        window.location.reload();
    } else {
        Fai.ing(LS.js_systemError, false);
    }
};

/*
产品置顶（产品展示模块2.0）
*/
export const setProductTopV2 = async function (id, topSwitch, refreshUrl, moduleId) {
    Vue.prototype.$layer.removeAllEditLayer();
    if (Vue.prototype.$designer.checkSaveBar()) {
        return;
    }
    top.Fai.ing('正在设置……', false);
    let result = await batchSetTopApi({
        id,
        top: topSwitch,
    });
    if (result.success) {
        successHandle(result, '修改成功', '', refreshUrl, 0, 1);
        refreshProductListModule(moduleId);
    } else {
        Fai.ing(result.msg, false);
    }
};

export const switchNewProductDetail = function () {
    if (Vue.prototype.$designer.checkSaveBar()) {
        return;
    }
    var productNewDeInfo = Fai.top._other.productNewDeInfo;

    if (typeof productNewDeInfo.prevVersion == 'undefined') {
        productNewDeInfo.prevVersion = productNewDeInfo.isProductNewDetail;
    }

    productNewDeInfo.isProductNewDetail = 2;
    Fai.top._other.productNewDeInfo = productNewDeInfo;
    Fai.top._otherChange++;
    Fai.top._changeStyleNum++;

    Vue.prototype.$designer.saveStyle({
        fun() {
            let routerData = getRouterData(Fai.top._colId);
            routerData._npd = productNewDeInfo.isProductNewDetail;
            let data = JSON.stringify(routerData);
            window.$router.push({
                query: { ...window.$router.currentRoute.query, data },
            });

            Fai.top._Global.styleChangeNum = 0;
        },
    });
};

export const switchOldProductDetail = function () {
    if (Vue.prototype.$designer.checkSaveBar()) {
        return;
    }

    var productNewDeInfo = Fai.top._other.productNewDeInfo;
    productNewDeInfo.isProductNewDetail = productNewDeInfo.isNewUser ? 1 : productNewDeInfo.prevVersion;
    Fai.top._other.productNewDeInfo = productNewDeInfo;
    Fai.top._otherChange++;
    Fai.top._changeStyleNum++;

    Vue.prototype.$designer.saveStyle({
        fun() {
            let routerData = getRouterData(Fai.top._colId);

            routerData._npd = productNewDeInfo.isProductNewDetail;

            let data = JSON.stringify(routerData);
            window.$router.push({
                query: { ...window.$router.currentRoute.query, data },
            });

            Fai.top._Global.styleChangeNum = 0;
        },
    });
};

export const initProductDetailManage = function (moduleId, json) {
    var productDetail = Mobi.getPreviewDocument().getElementById('productNewDetailPanel' + moduleId);
    $(productDetail)
        .mousemove(function () {
            Mobi.addModuleLayer('productNewDetailPanel' + moduleId, json, -1, true);
        })
        .mouseleave(function () {
            Mobi.removeModuleLayer('productNewDetailPanel' + moduleId);
        });
};

// 刷新2.0产品展示模块中的产品
export const refreshProductListV2 = function (moduleId) {
    var module = window.$store.getters.currentModuleForId(moduleId);
    if (module) {
        module.extInfo.refreshModuleHook = !module.extInfo.refreshModuleHook;
    }
};

// 刷新2.0产品展示模块中的所有内容
export const ajaxGetProductList = async function (moduleId, recommendFromPid) {
    var module = window.$store.getters.currentModuleForId(moduleId);
    let params = {
        module,
        moduleId: module.id,
    };
    if (recommendFromPid != null) {
        params.pid = recommendFromPid;
    }
    let data = await getProductListPageData(params);

    let { productList, totalSize } = data;
    let pageList = productList;
    window.$store.commit('batchUpdateProductList', pageList);
    // 更新模块的产品
    module.extInfo.productIdList.splice(0, module.extInfo.productIdList.length);
    pageList.forEach(function (item) {
        module.extInfo.productIdList.push(item.id);
    });
    module.extInfo.hook = !module.extInfo.hook;
    module.extInfo.totalSize = totalSize;
};

export const fixParamAreaHeight = function (moduleId) {
    var module = jm('#module' + moduleId);
    var maxHeight = 0;
    var paramWrap = module.find('.paramCollectionWrap');
    paramWrap.each(function (index, el) {
        var height = parseInt(jm(el).height());
        height > maxHeight && (maxHeight = height);
    });

    if (maxHeight > 0) {
        paramWrap.each(function (index, el) {
            jm(el).height(maxHeight + 'px');
        });
    }
};

export const addProductV2 = function (moduleId, entrance) {
    let moduleInfo = window.$store.getters.currentModuleForId(moduleId);
    if (moduleInfo) {
        var param = [];
        param.push('?_pmid=', moduleId);
        param.push('&src=', 1);
        param.push('&_mobi=', 1);
        param.push('&fromMobiProductList=', true);
        param.push('&isFromProductMouleV2=', true);
        if (moduleInfo.content.ma.mf.pt.f.length > 0) {
            param.push('&_fc=', 1);
            param.push('&_fm0=', true);
        }

        var callback = {};
        callback.funRun = true;
        callback.fun = Mobi.styleRecover;
        callback.args = new Array();

        if (entrance != 'basis' && Vue.prototype.$designer.checkSaveBar(callback, true)) {
            return false;
        }

        popupWindowVersionTwo.createPopupWindow({
            title: '添加产品',
            frameSrcUrl: getSiteMultiLanguageUrl() + '/manage_v2/productEdit.jsp' + param.join(''),
            width: '840',
            height: '680',
            saveBeforePopup: false,
            callArgs: 'productListAdd',
            closeFunc(back) {
                if (!back) return;
                var data = $.parseJSON(back);
                var productList = data.newArray;
                let module = window.$store.getters.currentModuleForId(moduleId);
                if (module.content.ma.mt == 0) {
                    for (var i = productList.length - 1; i >= 0; i--) {
                        if (entrance != 'basis') {
                            // basis 面板添加的不需要自动添加，中间面板添加的才需要自动添加
                            module.content.ma.ma.unshift(productList[i].id);
                            //module.extInfo.hook = !module.extInfo.hook;
                        }
                        window.$store.commit('updateProductListSimple', {
                            id: productList[i].id,
                            name: productList[i].name,
                        });
                        window.$store.commit('updateAllProductCount', ++Fai.top._allProductCount);
                    }
                }

                if (entrance != 'basis') {
                    refreshProductListModule(moduleId);
                }
            },
        });
    }
};

export const refreshProductListModule = async function (moduleId) {
    var module = window.$store.getters.currentModuleForId(moduleId);
    let data = await getProductListPageData({
        module,
        moduleId: module.id,
        pid: module.extInfo.recommendFromPid,
    });
    let { productList, totalSize } = data;
    window.$store.commit('batchUpdateProductList', productList);

    let moduleProductIdList = module.extInfo.productIdList || [];
    let productIdList = productList.map((product) => product.id);
    let filterIdList = moduleProductIdList.filter((id) => {
        return !productIdList.includes(id);
    });
    module.extInfo.productIdList = [...productIdList, ...filterIdList];
    module.extInfo.hook = !module.extInfo.hook;
    module.extInfo.totalSize = totalSize;
};

export function bookingPanelClickEvent(targetId, showType) {
    jm('#' + targetId).on('click', function (event) {
        event.stopPropagation();
        event.preventDefault();
        if (showType == 1) {
            logDog(200332, 15);
        } else if (showType == 2) {
            logDog(200332, 16);
        } else if (showType == 3) {
            logDog(200332, 17);
        }
        jm('#bookingPanelTriggle').data('pid', jm(this).attr('_pid')).trigger('click');
    });
}

export function initProductEvent(swipeId) {
    initProductSwipe(swipeId);
}

export function initRepPropValueOfURL() {
    var pat = new RegExp('https?|ftp|file://');
    jm('.paramCollection .propVal').each(function () {
        var srcStr = jm(this).text();
        if (pat.test(srcStr)) {
            jm(this).html(
                srcStr.replace(
                    /((https?|ftp|file):\/\/[-a-zA-Z0-9+&@#/%?=~_|!:,.;]*)/g,
                    '<a href="$1" target="_blank" style="text-decoration:underline">$1</a>'
                )
            );
        }
    });
}

export function setMemberPriceLabelColot2ThemeBackgroundColor() {
    var color = jm('.productImmediatelyBug').css('background');
    jm('.memberPrice').css('background', color);
}

export function hideFooterBox() {
    jm('#webFooterBox').css({
        display: 'none',
    });
}

export function showFooterBox() {
    var $webFooterBox = jm('#webFooterBox');
    $webFooterBox.removeClass('webDetailPage');
    // 关闭顶部不显示
    if (VITE_APP_MODE !== 'visitor' && Fai.top._mobiFooterClose) {
        jm('#webFooter').hide();
    }
}

export function initOptionsStr(oldOptionsStr, productId) {
    if (typeof productId != 'number') {
        productId = '';
    }
    Mobi.optionsStr = {};
    Mobi.optionsStr['oldOptionsStr' + productId] = oldOptionsStr;
}

// 绑定评论图片的预览器
