var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: [
        "p_newsgroup__wrap",
        `${_vm.isShowPanel ? "p_newsgroup__wrap-show" : ""}`,
      ],
    },
    [
      _c("div", { staticClass: "p_newsgroup__header" }, [
        _c("span", {
          staticClass: "p_newsgroup__close p_newsgroup__gClose",
          on: { click: _vm.close },
        }),
        _vm._v(" "),
        _c("span", { staticClass: "p_newsgroup__title" }, [_vm._v("文章分类")]),
      ]),
      _vm._v(" "),
      _vm.oneList.length
        ? _c("div", { ref: "slider", staticClass: "p_newsgroup__sliderwrap" }, [
            _c(
              "div",
              { staticClass: "p_newsgroup__sliderlist" },
              _vm._l(_vm.oneList, function (item, index) {
                return _c(
                  "span",
                  {
                    key: item.id,
                    class: [
                      "p_newsgroup__slideitem",
                      `${
                        _vm.pageStartX == index
                          ? "p_newsgroup__slideitem-active"
                          : ""
                      }`,
                    ],
                    style: _vm.getThemeColor(index),
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.changeCatelog(item, index)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(item.name) +
                        "\n            "
                    ),
                  ]
                )
              }),
              0
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "p_newsgroup__listwrap" },
        [
          !_vm.info.group.isGroup
            ? _c(
                "div",
                { staticClass: "p_newsgroup__listitem p_newsgroup__allitem" },
                [
                  _c(
                    "a",
                    {
                      staticClass: "p_newsgroup__ellipsis",
                      attrs: { href: _vm.info.group.href },
                    },
                    [_vm._v("全部内容")]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "p_newsgroup__split" }),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm._l(_vm.info.group.children, function (item) {
            return _c(
              "div",
              { key: item.id, staticClass: "p_newsgroup__listitem" },
              [
                _c(
                  "a",
                  {
                    staticClass: "p_newsgroup__ellipsis",
                    attrs: { href: item.href },
                  },
                  [_vm._v(_vm._s(item.name))]
                ),
                _vm._v(" "),
                item.children && item.children.length
                  ? _c(
                      "div",
                      { staticClass: "p_newsgroup__children" },
                      _vm._l(item.children, function (child) {
                        return _c(
                          "a",
                          {
                            key: child.id,
                            staticClass:
                              "p_newsgroup_child p_newsgroup__ellipsis",
                            attrs: { href: child.href },
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(child.name) +
                                "\n                "
                            ),
                          ]
                        )
                      }),
                      0
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "p_newsgroup__split" }),
              ]
            )
          }),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }