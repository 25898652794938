var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "m_mobi_form_textArea_wrap" }, [
    _c("textarea", {
      staticClass: "m_mobi_form_textArea m_mobi_form_input_text",
      style: _vm._mixinsInputStyle,
      attrs: {
        maxlength: _vm.module.extInfo.inputMaxLength,
        placeholder: _vm.formItem.placeholder,
      },
      domProps: { value: _vm.currentValue },
      on: { input: _vm.handleInput },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }