import { MODULE_STYLE, RecommendedNewsListModuleId, RecommendProductModuleId } from '@/def/module.js';

export function isBaseModule(type) {
    return type === MODULE_STYLE.SIMPLE_TEXT || type === MODULE_STYLE.FLOAT_BTN || type === MODULE_STYLE.FLOAT_IMG;
}

export function isLabelModule(type) {
    return type === MODULE_STYLE.FOLD || type === MODULE_STYLE.HORIZON_TAB;
}
export function isPopupZoneModule(type) {
    return type == MODULE_STYLE.POPUP_ZONE;
}

export function isPopupZoneChildren(module) {
    return module.inPopup;
}

export function isFloatZoneModule(style) {
    return style === MODULE_STYLE.FLOAT_ZONE;
}

export function isContainerModule(style) {
    return [MODULE_STYLE.FOLD, MODULE_STYLE.HORIZON_TAB, MODULE_STYLE.PACK].some((item) => {
        return item == style;
    });
}

export function isRecommendedNewsListModule(moduleIdOrType) {
    return moduleIdOrType === RecommendedNewsListModuleId;
}

export function isRecommendProductListModule(moduleId) {
    return moduleId == RecommendProductModuleId;
}
