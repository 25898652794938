import { MobiModule } from '../Module';
import { MODULE_STYLE } from '@/def/module';
import PackView from './visitor.vue';

class _Pack extends MobiModule {
    constructor(options = {}) {
        super(MODULE_STYLE.PACK, options);
    }

    render() {
        return PackView;
    }
}

export const Pack = new _Pack();
