<template>
    <module-frame :module-id="moduleId">
        <div class="cubeNavigationAreaBox">
            <div
                v-if="render"
                :id="'cubeNavigation' + id"
                class="cubeNavigationArea"
                :class="cubeNavigationCls"
                :value="cubeLayoutId"
            >
                <div class="cubeNavigationAreaContent">
                    <div
                        class="J_cubeNavigationAreaWrap cubeNavigationAreaWrap"
                        :style="areaWrapStyle"
                        @mouseover="clearCarousel"
                        @mouseout="setCarousel"
                    >
                        <template v-if="column != 0">
                            <div v-for="(rowList, index3) in pageList" class="rows_wrap">
                                <div
                                    v-for="(row, index) in rowList"
                                    :class="cubeLayoutId == 14 && index < 2 ? 'cubeLink_row_head' : 'cubeLink_row'"
                                >
                                    <cubelink
                                        v-for="(link, index2) in row"
                                        :id="link.id"
                                        :key="link.id"
                                        :link="link"
                                        :index="indexCtl(index, index2)"
                                        :page-index="index3"
                                        :href-str="hrefStr"
                                        :manage-mode="manageMode"
                                        :module-manage="moduleManage"
                                        :new-add="newAdd"
                                        :data-length="dataLength"
                                        :module-id="id"
                                        :cube-layout-id="cubeLayoutId"
                                        :has-sub-title="hasSubTitle"
                                        :cube-type="cubeType"
                                        :title-style="module.content.mhighs.ts"
                                        :can-set="canSetCube"
                                        :no-lazy-load="noLazyLoad"
                                        :module="module"
                                        :options="options"
                                    >
                                    </cubelink>
                                </div>
                            </div>
                        </template>
                        <template v-else>
                            <cubelink
                                v-for="(link, index) in showLinkList"
                                :id="link.id"
                                :key="link.id"
                                :link="link"
                                :index="index"
                                :page-index="index"
                                :href-str="hrefStr"
                                :manage-mode="manageMode"
                                :module-manage="moduleManage"
                                :new-add="newAdd"
                                :data-length="dataLength"
                                :module-id="id"
                                :cube-layout-id="cubeLayoutId"
                                :has-sub-title="hasSubTitle"
                                :cube-type="cubeType"
                                :title-style="module.content.mhighs.ts"
                                :can-set="canSetCube"
                                :no-lazy-load="noLazyLoad"
                                :module="module"
                                :options="options"
                            >
                            </cubelink>
                        </template>
                    </div>
                </div>
            </div>
            <div
                v-if="canSetCube.effect && module.content.ef == 2 && pageList.length > 1 && column != 0"
                class="page_wrap"
            >
                <i
                    v-for="(item, index) in pageList.length - 1"
                    class="pageno"
                    :style="pagenoStyle(index)"
                    @click.stop="gotoPage(index + 1)"
                ></i>
            </div>
            <div
                v-if="canSetCube.effect && module.content.ef == 1 && pageList.length > 1 && column != 0"
                class="progress_wrap"
            >
                <div class="progress_wrap2" :style="progressStyle">
                    <div class="progress_thume" :style="thumeStyle"></div>
                </div>
            </div>
        </div>
    </module-frame>
</template>

<script>
import Cubelink from './components/Cubelink.vue';
import { mapFlag } from '@/shared/flag';
import { initModuleCubeNavItemHeight, triggerAllLazyLoad } from './service';
import { bindImgLazyLoad } from '@/shared/imageLazyload';
import { mapGetters } from 'vuex';
import ModuleFrame from '@/modules/frame/index.vue';

export default {
    name: 'CubeNavigation',
    components: {
        Cubelink,
        ModuleFrame,
    },
    props: {
        moduleId: {
            type: Number,
            default: -1,
        },
    },
    data() {
        return {
            render: true,
            pageno: 1,
            timeout: null,
            thumeLeft: 0,
            htmlFontSize: 0,
            themeColor: '#ededed',
            thumeWidth: 0.75, // 滚动条长度 单位：rem
            container: null,
            isScrolling: null, //是否移动
            startPos: null, //触摸开始位置
            endPos: null, //触摸结束位置
            screenWidth: 0,
            scrollWidth: 0,
            reset: false,
            manageMode: VITE_APP_MODE !== 'visitor',
        };
    },
    computed: {
        ...mapGetters(['getModuleById']),
        options() {
            return this.module.renderOptions;
        },
        module() {
            return this.getModuleById(this.moduleId);
        },
        noLazyLoad() {
            return this.options.isFirstScreen;
        },
        id() {
            return this.module.id;
        },
        styleId() {
            return this.module.style;
        },
        ...mapFlag({
            hasEditMainWrap: 0x1,
            hasEditSubWrap: 0x2,
        }),
        canSetCube() {
            const id = this.cubeLayoutId;
            return {
                itemHeight: [9, 1, 2, 12, 10, 4, 3, 16, 18].includes(id),
                itemSpace: [12, 10, 4, 3, 1, 2].includes(id),
                itemRadius: ![13, 7, 17, 16, 9, 14, 18].includes(id),
                effect: [13, 7, 17].includes(id),
                icon: ![12, 15, 18].includes(id),
                pic: [18].includes(id),
            };
        },
        moduleManage() {
            return VITE_APP_MODE !== 'visitor';
        },
        hrefStr() {
            return this.module.content.hrefStr;
        },
        cubeLayoutId() {
            return this.module.content.cubeLayoutId;
        },
        newAdd() {
            return this.module.content.newAdd;
        },
        cubeType() {
            return this.module.content.cubeType;
        },
        hasSubTitle() {
            return this.module.content.hasSubTitle;
        },
        showLinkList() {
            var linkList = this.module.content.linkList || [];
            return linkList.filter(function (item) {
                return !item.linkHide;
            });
        },
        dataLength() {
            return this.showLinkList.length;
        },
        cubeNavigationCls() {
            let classes = '';

            if (this.newAdd == 1) {
                classes += 'newCubeNavigationArea newCubeNavigationArea' + this.cubeLayoutId;
            } else {
                classes += 'cubeNavigationArea' + this.cubeLayoutId;
            }

            classes += ' cubeNavigationBoxContent cubeNavigationBoxContent' + this.cubeLayoutId;

            if (this.module.content.ih.t == 1 && this.canSetCube.itemHeight) {
                // 自定义高度
                classes += ' cus_height';
            }

            if (this.module.content.is.t == 1 && this.canSetCube.itemSpace) {
                // 自定义项间距
                classes += ' cus_space';
            }

            if (this.module.content.ef != 0 && this.canSetCube.effect) {
                classes += ` page_style page_style${this.module.content.ef}`;
            }

            if (this.hasEditMainWrap) {
                if (this.module.content.mw) {
                    classes += ' main_wrap';
                } else {
                    classes += ' main_no_wrap';
                }
            }

            if (this.hasEditSubWrap) {
                if (this.module.content.ew) {
                    classes += ' sub_wrap';
                } else {
                    classes += ' sub_no_wrap';
                }
            }

            return classes;
        },
        column() {
            var cubeType = this.cubeType;
            var cubeLayoutId = this.cubeLayoutId;
            if (cubeType == 2 || cubeType == 3) {
                return cubeType;
            } else if (cubeLayoutId == 7 || cubeLayoutId == 14) {
                return 4;
            } else if (cubeLayoutId == 13) {
                return 3;
            } else if (cubeLayoutId == 17) {
                return 5;
            } else {
                return 0;
            }
        },
        indexCtl() {
            return function (index, index2) {
                if (this.cubeLayoutId == 14) {
                    if (index > 1) {
                        return index * 4 + index2;
                    } else {
                        return 8 + (index - 1) * 2 + index2;
                    }
                }
                return index * this.column + index2;
            };
        },
        pageList() {
            var rowList = [];
            var column = this.column;
            var data = this.showLinkList;
            if (column == 0) {
                return [];
            }
            for (var i = 0; i < data.length; i += column) {
                //特殊处理 cubeLayoutId 14的row组
                if (i == 8 && this.cubeLayoutId == 14) {
                    column = 2;
                }
                rowList.push(data.slice(i, i + column));
            }

            let list = [];
            if (this.module.content.ef != 0 && this.canSetCube.effect) {
                // 滑动或者轮播时，分页，一页两行
                const chunk = 2;
                for (let i = 0, j = rowList.length; i < j; i += chunk) {
                    list.push(rowList.slice(i, i + chunk));
                }
            } else {
                list.push(rowList);
            }

            if (this.module.content.ef == 2 && list.length > 1 && this.canSetCube.effect) {
                list.push(list[0]);
            }

            return list;
        },
        areaWrapStyle() {
            if (!this.canSetCube.effect) return '';
            let style = {};
            const pageList = this.pageList;
            const pageno = this.pageno;

            if (pageList.length > 1) {
                style.width = `${pageList.length * 100}%`;
            }

            if (this.module.content.ef == 2) {
                const speed = this.module.content.cs.t == 1 ? this.module.content.cs.sp : 0.5;
                style.transition = this.reset ? '' : `transform ${speed}s 0s`;
                //style.left = this.reset ? "0" : `${-(pageno - 1) * 100}%`;
                //style.transform = this.reset ? "translateX(0)" : `translateX(${-(pageno - 1) * this.screenWidth}px)`;
                // transform 百分数是以自身的宽度为基准的，因此这里需要换算
                style.transform = this.reset
                    ? 'translateX(0)'
                    : `translateX(${(-(pageno - 1) / pageList.length) * 100}%)`;
            }
            return style;
        },
        title_style() {
            var style = '';
            if (!(VITE_APP_MODE !== 'visitor')) {
                return style;
            }
            var titleStyle = this.module.content.mhighs.ts;
            if (titleStyle.type == 1) {
                if (titleStyle.lfs) {
                    style += 'font-size:' + titleStyle.lfs + 'rem;';
                } else {
                    style += 'font-size:12px;';
                }
                if (titleStyle.lfct == 1) {
                    style += 'color:' + titleStyle.lfc + ';';
                }
                if (titleStyle.lfd == 1) {
                    style += 'text-decoration:underline;';
                } else {
                    style += 'text-decoration:none;';
                }
            }
            return style;
        },
        pagenoStyle() {
            return function (index) {
                let style = {};
                let curPage = false;
                if (this.pageno == this.pageList.length) {
                    curPage = index == 0;
                } else if (this.pageno == index + 1) {
                    curPage = true;
                }

                if (curPage) {
                    style.backgroundColor = this.themeColor;
                    style.width = '0.375rem';
                }
                return style;
            };
        },
        progressStyle() {
            return {
                width: this.thumeWidth * this.pageList.length + 'rem',
            };
        },
        thumeStyle() {
            let left = this.thumeLeft;
            left = Math.min(left, 1);
            left = Math.max(left, 0);
            left = left * (this.thumeWidth * this.htmlFontSize * (this.pageList.length - 1));

            return `background-color:${this.themeColor};transform:translate(${left}px, 0px)`;
        },
        rowWrapStyle() {
            if (this.screenWidth && this.module.content.ef != 0 && this.canSetCube.effect) {
                return `width:${this.screenWidth}px`;
            } else {
                return '';
            }
        },
    },
    watch: {
        'module.content': {
            handler() {
                if (VITE_APP_MODE !== 'visitor') {
                    this.noLazyLoad = true;
                }
                this.clearCarousel();
                this.render = false;
                this.$nextTick(() => {
                    this.render = true;
                    this.$nextTick(() => {
                        bindEvent(this, true);
                    });
                });
            },
            deep: true,
        },
    },
    created() {
        // 新添加的字段，因为先上 node 项目，后台的初始化逻辑还没上，所以前端做保护
        let content = this.module.content;
        if (!content.fi) {
            content.fi = { t: 0, v: 1.1 };
        }

        if (!content.ih) {
            content.ih = { t: 0, v: 4.6 };
        }

        if (!content.is) {
            content.is = { t: 0, h: 0.5, v: 0.5 };
        }

        if (!content.ir) {
            content.ir = { t: 0, v: 0.5 };
        }

        if (typeof content.ef == 'undefined') {
            content.ef = 0;
        }

        if (!content.cs) {
            content.cs = { t: 0, st: 4, sp: 1.5 };
        }

        if (typeof content.mw == 'undefined') {
            content.mw = 0;
        }

        if (typeof content.ew == 'undefined') {
            content.ew = 0;
        }
    },
    mounted() {
        this.themeColor = Fai.top._themeColor;
        this.htmlFontSize = Fai.top._htmlFontSize;
        bindEvent(this);
    },
    methods: {
        nextPage() {
            if (this.module.content.cs.t == 2 || this.module.content.cs.t == 0) {
                this.reset = false;
                if (this.pageno == this.pageList.length - 1) {
                    setTimeout(() => {
                        this.reset = true;
                        this.pageno = 1;
                    }, 500);
                }
            } else if (this.pageno == this.pageList.length) {
                return;
            }

            this.gotoPage(this.pageno + 1);
        },
        prePage() {
            if (this.pageno == 1) return;
            this.gotoPage(this.pageno - 1);
        },
        gotoPage(pageno) {
            this.pageno = pageno;
            triggerAllLazyLoad();
        },
        clearCarousel() {
            this.reset = false;
            this.timeout && clearTimeout(this.timeout);
        },
        setCarousel() {
            if (this.pageList.length > 1 && this.canSetCube.effect && this.module.content.cs.t == 1) {
                this.clearCarousel();
                this.timeout = this.carouselRun();
            }
        },
        carouselRun() {
            if (this.pageno == this.pageList.length) {
                // 动画切换到最后一张后，去掉第一张的动画，立马转到第一张，settimeout是因为要等最后一张动画切换完成后再无缝连接
                return setTimeout(() => {
                    this.pageno = 1;
                    this.reset = true;
                    this.timeout = this.carouselRun();
                }, this.module.content.cs.sp * 1000);
            } else {
                let delay = this.reset
                    ? this.module.content.cs.st
                    : parseInt(this.module.content.cs.st) + parseInt(this.module.content.cs.sp);
                return setTimeout(() => {
                    this.reset = false;
                    this.pageno++;
                    this.timeout = this.carouselRun();
                }, delay * 1000);
            }
        },
        touchStart(event) {
            var touch = event.targetTouches[0]; //touches数组对象获得屏幕上所有的touch，取第一个touch
            this.isScrolling = 0; //这个参数判断是垂直滚动还是水平滚动
            this.startPos = {
                x: touch.pageX,
                y: touch.pageY,
                time: +new Date(),
            }; //取第一个touch的坐标值
            this.container.addEventListener('touchmove', this.touchMove, false);
            this.container.addEventListener('touchend', this.touchEnd, false);
        },
        touchMove(event) {
            //当屏幕有多个touch或者页面被缩放过，就不执行move操作
            if (event.targetTouches.length > 1 || (event.scale && event.scale !== 1)) {
                return;
            }
            var touch = event.targetTouches[0];

            this.endPos = {
                x: touch.pageX - this.startPos.x,
                y: touch.pageY - this.startPos.y,
                time: +new Date(),
            };
            this.isScrolling = Math.abs(this.endPos.x) < Math.abs(this.endPos.y) ? 1 : 0;
            if (this.isScrolling === 0) {
                event.preventDefault();
            }
        },
        touchEnd() {
            // eslint-disable-next-line no-unused-vars
            var duration = +new Date() - this.startPos.time;
            if (this.isScrolling === 0) {
                //remove icon class
                if (Number(this.endPos.x) > 10 && Number(this.endPos.y) < 40) {
                    this.prePage();
                } else if (Number(this.endPos.x) < -10 && Number(this.endPos.y) < 40) {
                    this.nextPage();
                }
            }
            this.container.removeEventListener('touchmove', this, false);
            this.container.removeEventListener('touchend', this, false);
        },
    },
};

function bindEvent(context) {
    initModuleCubeNavItemHeight(context.module.content.cubeLayoutId, context.module.id);
    //context.screenWidth = document.getElementById("webContainerBox").clientWidth;
    context.screenWidth = jm(`#module${context.module.id} .cubeNavigationAreaContent`).width();
    context.pageno = 1;
    context.clearCarousel();
    context.container = document.querySelectorAll(`#cubeNavigation${context.module.id}`)[0];

    if (context.canSetCube.effect) {
        if (context.module.content.ef == 2) {
            // 轮播
            const isTouch =
                !!('ontouchstart' in window) ||
                // eslint-disable-next-line no-undef
                (window.DocumentTouch && document instanceof DocumentTouch);
            if (isTouch) {
                context.container.addEventListener('touchstart', context.touchStart, false); //addEventListener第二个参数可以传一个对象，会调用该对象的handleEvent属性
            }

            if (context.module.content.cs.t == 1) {
                // 自动轮播
                context.setCarousel();
            }
        }

        if (context.module.content.ef == 1) {
            let $areaContent = jm(`#cubeNavigation${context.module.id} .cubeNavigationAreaContent`);
            (function () {
                let areaContentScroll = new iScroll($areaContent[0], {
                    scrollX: true,
                    scrollY: false,
                    vScroll: false,
                    bounce: false,
                    momentum: false,
                    mouseWheel: true,
                    remarkXCoordinate: true,
                    onScrollMove() {
                        context.thumeLeft = Math.abs(areaContentScroll.x) / Math.abs(areaContentScroll.maxScrollX);
                    },
                    onScrollEnd() {
                        context.thumeLeft = Math.abs(areaContentScroll.x) / Math.abs(areaContentScroll.maxScrollX);
                    },
                    onBeforeScrollStart() {},
                });
            })();
        }
    }
    if (!context.noLazyLoad) {
        bindImgLazyLoad(`module${context.module.id}`);
    }
}
</script>

<style>
.cubeNavigationAreaContent {
    -webkit-overflow-scrolling: touch;
}
.cubeNavigationArea.page_style .cubeNavigationAreaContent {
    overflow: hidden;
}
/*.cubeNavigationArea.page_style1 .cubeNavigationAreaContent{overflow-x: auto;}*/
.page_style .cubeNavigationAreaWrap {
    display: flex;
    position: relative;
    left: 0;
    transition-property: left;
    transform: translate3d(0, 0, 0);
}
.cubeNavigationArea.page_style .cubeNavigationAreaContent .rows_wrap {
    width: 100%;
}
.cubeNavigationAreaBox .progress_wrap,
.cubeNavigationAreaBox .page_wrap {
    display: flex;
    justify-content: center;
    margin-top: 0.25rem;
    margin-bottom: 0.75rem;
}
.cubeNavigationAreaBox .progress_wrap {
    margin-top: 0.584rem;
}
.cubeNavigationAreaBox .pageno {
    width: 0.25rem;
    height: 0.075rem;
    background: rgba(237, 237, 237, 1);
    border-radius: 0.05rem;
    margin-left: 0.25rem;
    cursor: pointer;
}
.cubeNavigationAreaBox .pageno:first-child {
    margin-left: 0;
}
.cubeNavigationAreaBox .progress_wrap2 {
    background: rgba(226, 226, 226, 1);
    border-radius: 0.05rem;
    height: 0.075rem;
    position: relative;
}
.cubeNavigationAreaBox .progress_thume {
    width: 0.75rem;
    border-radius: 0.05rem;
    height: 0.075rem;
    position: relative;
    left: 0;
    transition: transform 0.2s linear, -webkit-transform 0.2s linear;
    transform: translate(0px, 0px);
}

.cubeNavigationArea .icon-cube {
    font-size: 2rem;
    color: #fff;
}
.cubeNavigationArea .cubeLink_text_p {
    font-size: 0.7rem;
}
.cubeNavigationArea {
    overflow: hidden;
    min-height: 0.5rem;
    padding-top: 1.5%;
}
.cubeNavigationArea .cubeLink {
    float: left;
    width: 100%;
    padding-bottom: 1.5%;
    padding-left: 1.5%;
    min-height: 4.7rem;
}
.cubeNavigationArea .cubeLink .cubeLink_a {
    width: 100%;
    height: 100%;
    display: inline-block;
    position: relative;
}
.cubeNavigationArea .cubeLink .cubeLink_a .cubeLink_bg {
    width: 100%;
    height: 100%;
    position: absolute;
}
.cubeNavigationArea .cubeLink .cubeLink_a .cubeLink_curtain {
    width: 100%;
    height: 100%;
    position: absolute;
}
.cubeNavigationArea .cubeLink .cubeLink_a .cubeLink_ico {
    width: 2rem;
    height: 2.1rem;
    margin: auto;
    position: absolute;
    -moz-background-size: 40px 40px;
    -o-background-size: 40px 40px;
    background-size: 40px 40px;
}
.cubeNavigationArea .cubeLink .cubeLink_a .cubeLink_text {
    width: 100%;
    position: absolute;
    table-layout: fixed;
}
.cubeNavigationArea .cubeLink .cubeLink_a .cubeLink_text .cubeLink_text_p {
    white-space: nowrap;
    overflow: hidden;
}
/*.cubeNavigationArea .cubeLink_fixWidth_1{width: 97% ;}*/
.cubeNavigationArea .cubeLink_fixWidth_1 {
    width: 97% !important;
}
.cubeNavigationArea .cubeLink_fixWidth_2 {
    width: 47.75% !important;
}

/*====魔方导航各种样式start====*/
.cubeNavigationArea1 .cubeLink {
    width: 31.33%;
    height: auto;
}
.cubeNavigationArea1 .cubeLink .cubeLink_a .cubeLink_bg {
    background: #1e2225;
}
.cubeNavigationArea1 .cubeLink .cubeLink_a .cubeLink_ico {
    left: 0;
    right: 0;
    top: 0.7rem;
    position: relative;
}
.cubeNavigationArea1 .cubeLink .cubeLink_a .cubeLink_text {
    margin-top: 1rem;
    text-align: center;
    color: #ffffff;
    font-size: 14px;
    position: relative;
}
.cubeNavigationArea1 .cubeLink .cubeLink_a .cubeLink_text .cubeLink_text_p {
    overflow: visible;
    white-space: normal;
    word-break: break-all;
}
.cubeNavigationArea1 .cubeLink .cubeLink_a:hover .cubeLink_bg {
    background: #3c3058;
}

.cubeNavigationArea1 .cubeLink_row:nth-child(1) .cubeLink {
    padding-top: 1%;
}

.cubeNavigationArea1 .cubeLink:nth-child(3n + 3) {
    width: 31.34%;
}

.cubeNavigationArea1 .cubeLink_row:nth-child(3n + 1) .cubeLink:nth-child(1) .icon-cube:before {
    content: '\e6aa';
}
.cubeNavigationArea1 .cubeLink_row:nth-child(3n + 1) .cubeLink:nth-child(2) .icon-cube:before {
    content: '\e642';
}
.cubeNavigationArea1 .cubeLink_row:nth-child(3n + 1) .cubeLink:nth-child(3) .icon-cube:before {
    content: '\e65f';
}
.cubeNavigationArea1 .cubeLink_row:nth-child(3n + 2) .cubeLink:nth-child(1) .icon-cube:before {
    content: '\e6ed';
}
.cubeNavigationArea1 .cubeLink_row:nth-child(3n + 2) .cubeLink:nth-child(2) .icon-cube:before {
    content: '\e67a';
}
.cubeNavigationArea1 .cubeLink_row:nth-child(3n + 2) .cubeLink:nth-child(3) .icon-cube:before {
    content: '\e6c0';
}
.cubeNavigationArea1 .cubeLink_row:nth-child(3n + 3) .cubeLink:nth-child(1) .icon-cube:before {
    content: '\e6df';
}
.cubeNavigationArea1 .cubeLink_row:nth-child(3n + 3) .cubeLink:nth-child(2) .icon-cube:before {
    content: '\e6de';
}
.cubeNavigationArea1 .cubeLink_row:nth-child(3n + 3) .cubeLink:nth-child(3) .icon-cube:before {
    content: '\e6b8';
}

/*==========*/
.cubeNavigationArea2 .cubeLink {
    width: 31.33%;
    height: auto;
}

.cubeNavigationArea2 .cubeLink .cubeLink_a .cubeLink_ico {
    left: 0;
    right: 0;
    top: 0.7rem;
    position: relative;
}
.cubeNavigationArea2 .cubeLink .cubeLink_a .cubeLink_text {
    text-align: center;
    color: #ffffff;
    font-size: 16px;
    position: relative;
    margin-top: 0.8rem;
}
.cubeNavigationArea2 .cubeLink .cubeLink_a .cubeLink_text .cubeLink_text_p {
    overflow: visible;
    white-space: normal;
    word-break: break-all;
}

.cubeNavigationArea2 .cubeLink_row:nth-child(1) .cubeLink {
    padding-top: 1%;
}

.cubeNavigationArea2 .cubeLink:nth-child(3n + 3) {
    width: 31.34%;
}

.cubeNavigationArea2 .cubeLink_row:nth-child(3n + 1) .cubeLink:nth-child(1) .cubeLink_a .cubeLink_bg {
    background-color: #d87570;
}
.cubeNavigationArea2 .cubeLink_row:nth-child(3n + 1) .cubeLink:nth-child(2) .cubeLink_a .cubeLink_bg {
    background-color: #bbd1c4;
}
.cubeNavigationArea2 .cubeLink_row:nth-child(3n + 1) .cubeLink:nth-child(3) .cubeLink_a .cubeLink_bg {
    background-color: #febf02;
}
.cubeNavigationArea2 .cubeLink_row:nth-child(3n + 2) .cubeLink:nth-child(1) .cubeLink_a .cubeLink_bg {
    background-color: #bc4040;
}
.cubeNavigationArea2 .cubeLink_row:nth-child(3n + 2) .cubeLink:nth-child(2) .cubeLink_a .cubeLink_bg {
    background-color: #6f9c97;
}
.cubeNavigationArea2 .cubeLink_row:nth-child(3n + 2) .cubeLink:nth-child(3) .cubeLink_a .cubeLink_bg {
    background-color: #ff8b00;
}
.cubeNavigationArea2 .cubeLink_row:nth-child(3n + 3) .cubeLink:nth-child(1) .cubeLink_a .cubeLink_bg {
    background-color: #d87570;
}
.cubeNavigationArea2 .cubeLink_row:nth-child(3n + 3) .cubeLink:nth-child(2) .cubeLink_a .cubeLink_bg {
    background-color: #bbd1c4;
}
.cubeNavigationArea2 .cubeLink_row:nth-child(3n + 3) .cubeLink:nth-child(3) .cubeLink_a .cubeLink_bg {
    background-color: #febf02;
}

.cubeNavigationArea2 .cubeLink_row:nth-child(3n + 1) .cubeLink:nth-child(1) .icon-cube:before {
    content: '\e641';
}
.cubeNavigationArea2 .cubeLink_row:nth-child(3n + 1) .cubeLink:nth-child(2) .icon-cube:before {
    content: '\e6a1';
}
.cubeNavigationArea2 .cubeLink_row:nth-child(3n + 1) .cubeLink:nth-child(3) .icon-cube:before {
    content: '\e66a';
}
.cubeNavigationArea2 .cubeLink_row:nth-child(3n + 2) .cubeLink:nth-child(1) .icon-cube:before {
    content: '\e637';
}
.cubeNavigationArea2 .cubeLink_row:nth-child(3n + 2) .cubeLink:nth-child(2) .icon-cube:before {
    content: '\e687';
}
.cubeNavigationArea2 .cubeLink_row:nth-child(3n + 2) .cubeLink:nth-child(3) .icon-cube:before {
    content: '\e6c1';
}
.cubeNavigationArea2 .cubeLink_row:nth-child(3n + 3) .cubeLink:nth-child(1) .icon-cube:before {
    content: '\e6de';
}
.cubeNavigationArea2 .cubeLink_row:nth-child(3n + 3) .cubeLink:nth-child(2) .icon-cube:before {
    content: '\e682';
}
.cubeNavigationArea2 .cubeLink_row:nth-child(3n + 3) .cubeLink:nth-child(3) .icon-cube:before {
    content: '\e6e0';
}

/*========*/
.cubeNavigationArea3 .cubeLink {
    width: 47.75%;
}
.cubeNavigationArea3 .cubeLink .cubeLink_a .cubeLink_ico {
    left: 10%;
    top: 10%;
}
.cubeNavigationArea3 .cubeLink .cubeLink_a .cubeLink_text {
    bottom: 15%;
    text-align: right;
    color: #ffffff;
    font-size: 16px;
}
.cubeNavigationArea3 .cubeLink .cubeLink_a .cubeLink_text .cubeLink_text_p {
    padding-right: 10%;
}
.cubeNavigationArea3 .cubeLink_row:nth-child(1) .cubeLink {
    padding-top: 1%;
}

.cubeNavigationArea3 .cubeLink_row:nth-child(3n + 1) .cubeLink:nth-child(1) .cubeLink_a .cubeLink_bg {
    background-color: #148cfc;
}
.cubeNavigationArea3 .cubeLink_row:nth-child(3n + 1) .cubeLink:nth-child(2) .cubeLink_a .cubeLink_bg {
    background-color: #99b802;
}
.cubeNavigationArea3 .cubeLink_row:nth-child(3n + 2) .cubeLink:nth-child(1) .cubeLink_a .cubeLink_bg {
    background-color: #00aea5;
}
.cubeNavigationArea3 .cubeLink_row:nth-child(3n + 2) .cubeLink:nth-child(2) .cubeLink_a .cubeLink_bg {
    background-color: #fe8705;
}
.cubeNavigationArea3 .cubeLink_row:nth-child(3n + 3) .cubeLink:nth-child(1) .cubeLink_a .cubeLink_bg {
    background-color: #5a35b8;
}
.cubeNavigationArea3 .cubeLink_row:nth-child(3n + 3) .cubeLink:nth-child(2) .cubeLink_a .cubeLink_bg {
    background-color: #ea0001;
}

.cubeNavigationArea3 .cubeLink_row:nth-child(3n + 1) .cubeLink:nth-child(1) .icon-cube:before {
    content: '\e6aa';
}
.cubeNavigationArea3 .cubeLink_row:nth-child(3n + 1) .cubeLink:nth-child(2) .icon-cube:before {
    content: '\e688';
}
.cubeNavigationArea3 .cubeLink_row:nth-child(3n + 2) .cubeLink:nth-child(1) .icon-cube:before {
    content: '\e674';
}
.cubeNavigationArea3 .cubeLink_row:nth-child(3n + 2) .cubeLink:nth-child(2) .icon-cube:before {
    content: '\e6a0';
}
.cubeNavigationArea3 .cubeLink_row:nth-child(3n + 3) .cubeLink:nth-child(1) .icon-cube:before {
    content: '\e630';
}
.cubeNavigationArea3 .cubeLink_row:nth-child(3n + 3) .cubeLink:nth-child(2) .icon-cube:before {
    content: '\e6dd';
}

/*========*/
.cubeNavigationArea4 .cubeLink {
    width: 47.75%;
}
.cubeNavigationArea4 .cubeLink .cubeLink_a .cubeLink_bg {
    background-color: #f37e39;
    border-radius: 10px;
}
.cubeNavigationArea4 .cubeLink .cubeLink_a .cubeLink_ico {
    right: 10%;
    top: 0;
    bottom: 0;
}
.cubeNavigationArea4 .cubeLink .cubeLink_a .cubeLink_text {
    position: relative;
    height: 100%;
    display: table;
    text-align: left;
    color: #ffffff;
    font-size: 16px;
}
.cubeNavigationArea4 .cubeLink .cubeLink_a .cubeLink_text .cubeLink_text_p {
    padding-left: 10%;
    display: table-cell;
    vertical-align: middle;
}

.cubeNavigationArea4 .cubeLink_row:nth-child(1) .cubeLink {
    padding-top: 1%;
}

.cubeNavigationArea4 .cubeLink_row:nth-child(3n + 1) .cubeLink:nth-child(1) .icon-cube:before {
    content: '\e667';
}
.cubeNavigationArea4 .cubeLink_row:nth-child(3n + 1) .cubeLink:nth-child(2) .icon-cube:before {
    content: '\e686';
}
.cubeNavigationArea4 .cubeLink_row:nth-child(3n + 2) .cubeLink:nth-child(1) .icon-cube:before {
    content: '\e61b';
}
.cubeNavigationArea4 .cubeLink_row:nth-child(3n + 2) .cubeLink:nth-child(2) .icon-cube:before {
    content: '\e6ef';
}
.cubeNavigationArea4 .cubeLink_row:nth-child(3n + 3) .cubeLink:nth-child(1) .icon-cube:before {
    content: '\e6ed';
}
.cubeNavigationArea4 .cubeLink_row:nth-child(3n + 3) .cubeLink:nth-child(2) .icon-cube:before {
    content: '\e636';
}

/*========*/
.cubeNavigationArea5 .cubeLink {
    height: 4.7rem;
}
.cubeNavigationArea5 .cubeLink .cubeLink_a .cubeLink_text {
    color: #ffffff;
    font-size: 16px;
}

.cubeNavigationArea5 .cubeLink:nth-child(1) {
    padding-top: 2%;
}
.cubeNavigationArea5 .cubeLink:nth-child(2) {
    padding-top: 2%;
}
.cubeNavigationArea5 .cubeLink:nth-child(3) {
    padding-top: 2%;
}

.cubeNavigationArea5 .cubeLink:nth-child(8n + 1) {
    width: 38%;
}
.cubeNavigationArea5 .cubeLink:nth-child(8n + 2) {
    width: 28%;
}
.cubeNavigationArea5 .cubeLink:nth-child(8n + 3) {
    width: 28%;
}
.cubeNavigationArea5 .cubeLink:nth-child(8n + 4) {
    width: 47.75%;
    height: 5.7rem;
}
.cubeNavigationArea5 .cubeLink:nth-child(8n + 5) {
    width: 47.75%;
    height: 5.7rem;
}
.cubeNavigationArea5 .cubeLink:nth-child(8n + 6) {
    width: 38%;
}
.cubeNavigationArea5 .cubeLink:nth-child(8n + 7) {
    width: 28%;
}
.cubeNavigationArea5 .cubeLink:nth-child(8n + 8) {
    width: 28%;
}

.cubeNavigationArea5 .cubeLink:nth-child(8n + 1) .cubeLink_a .cubeLink_bg {
    background-color: #f4b613;
}
.cubeNavigationArea5 .cubeLink:nth-child(8n + 2) .cubeLink_a .cubeLink_bg {
    background-color: #ff8a4a;
}
.cubeNavigationArea5 .cubeLink:nth-child(8n + 3) .cubeLink_a .cubeLink_bg {
    background-color: #fc5265;
}
.cubeNavigationArea5 .cubeLink:nth-child(8n + 4) .cubeLink_a .cubeLink_bg {
    background-color: #84d018;
}
.cubeNavigationArea5 .cubeLink:nth-child(8n + 5) .cubeLink_a .cubeLink_bg {
    background-color: #14c761;
}
.cubeNavigationArea5 .cubeLink:nth-child(8n + 6) .cubeLink_a .cubeLink_bg {
    background-color: #36aae7;
}
.cubeNavigationArea5 .cubeLink:nth-child(8n + 7) .cubeLink_a .cubeLink_bg {
    background-color: #688ce2;
}
.cubeNavigationArea5 .cubeLink:nth-child(8n + 8) .cubeLink_a .cubeLink_bg {
    background-color: #8d67e0;
}

.cubeNavigationArea5 .cubeLink:nth-child(8n + 1) .cubeLink_ico {
    bottom: 10%;
    right: 10%;
}
.cubeNavigationArea5 .cubeLink:nth-child(8n + 2) .cubeLink_ico {
    top: 10%;
    right: 0;
    left: 0;
}
.cubeNavigationArea5 .cubeLink:nth-child(8n + 3) .cubeLink_ico {
    bottom: 10%;
    right: 0;
    left: 0;
}
.cubeNavigationArea5 .cubeLink:nth-child(8n + 4) .cubeLink_ico {
    bottom: 10%;
    right: 10%;
}
.cubeNavigationArea5 .cubeLink:nth-child(8n + 5) .cubeLink_ico {
    bottom: 10%;
    right: 10%;
}
.cubeNavigationArea5 .cubeLink:nth-child(8n + 6) .cubeLink_ico {
    bottom: 10%;
    right: 10%;
}
.cubeNavigationArea5 .cubeLink:nth-child(8n + 7) .cubeLink_ico {
    top: 10%;
    right: 0;
    left: 0;
}
.cubeNavigationArea5 .cubeLink:nth-child(8n + 8) .cubeLink_ico {
    bottom: 10%;
    right: 0;
    left: 0;
}

.cubeNavigationArea5 .cubeLink:nth-child(8n + 1) .icon-cube:before {
    content: '\e642';
}
.cubeNavigationArea5 .cubeLink:nth-child(8n + 2) .icon-cube:before {
    content: '\e694';
}
.cubeNavigationArea5 .cubeLink:nth-child(8n + 3) .icon-cube:before {
    content: '\e686';
}
.cubeNavigationArea5 .cubeLink:nth-child(8n + 4) .icon-cube:before {
    content: '\e67c';
}
.cubeNavigationArea5 .cubeLink:nth-child(8n + 5) .icon-cube:before {
    content: '\e630';
}
.cubeNavigationArea5 .cubeLink:nth-child(8n + 6) .icon-cube:before {
    content: '\e6b2';
}
.cubeNavigationArea5 .cubeLink:nth-child(8n + 7) .icon-cube:before {
    content: '\e65f';
}
.cubeNavigationArea5 .cubeLink:nth-child(8n + 8) .icon-cube:before {
    content: '\e6de';
}

.cubeNavigationArea5 .cubeLink:nth-child(8n + 1) .cubeLink_text {
    top: 10%;
    text-align: left;
}
.cubeNavigationArea5 .cubeLink:nth-child(8n + 1) .cubeLink_text .cubeLink_text_p {
    padding-left: 10%;
}
.cubeNavigationArea5 .cubeLink:nth-child(8n + 2) .cubeLink_text {
    bottom: 10%;
    text-align: center;
}
.cubeNavigationArea5 .cubeLink:nth-child(8n + 3) .cubeLink_text {
    top: 10%;
    text-align: center;
}
.cubeNavigationArea5 .cubeLink:nth-child(8n + 4) .cubeLink_text {
    top: 10%;
    text-align: left;
}
.cubeNavigationArea5 .cubeLink:nth-child(8n + 4) .cubeLink_text .cubeLink_text_p {
    padding-left: 10%;
}
.cubeNavigationArea5 .cubeLink:nth-child(8n + 5) .cubeLink_text {
    top: 10%;
    text-align: left;
}
.cubeNavigationArea5 .cubeLink:nth-child(8n + 5) .cubeLink_text .cubeLink_text_p {
    padding-left: 10%;
}
.cubeNavigationArea5 .cubeLink:nth-child(8n + 6) .cubeLink_text {
    top: 10%;
    text-align: left;
}
.cubeNavigationArea5 .cubeLink:nth-child(8n + 6) .cubeLink_text .cubeLink_text_p {
    padding-left: 10%;
}
.cubeNavigationArea5 .cubeLink:nth-child(8n + 7) .cubeLink_text {
    bottom: 10%;
    text-align: center;
}
.cubeNavigationArea5 .cubeLink:nth-child(8n + 8) .cubeLink_text {
    top: 10%;
    text-align: center;
}

/*========*/
.cubeNavigationArea6 .cubeLink {
    box-sizing: border-box;
    height: 3.75rem;
    color: #ffffff;
    min-height: 3.75rem;
}
.cubeNavigationArea6 .cubeLink .cubeLink_a .cubeLink_ico {
    left: 0;
    right: 0;
    top: 6%;
}
.cubeNavigationArea6 .cubeLink .cubeLink_a .cubeLink_text {
    color: #ffffff;
    font-size: 16px;
    bottom: 5%;
}

.cubeNavigationArea6 .cubeLink:nth-child(1) {
    padding-top: 1%;
}
.cubeNavigationArea6 .cubeLink:nth-child(2) {
    padding-top: 1%;
}

.cubeNavigationArea6 .cubeLink:nth-child(7n + 1) {
    width: 60%;
    height: 7.5rem;
}
.cubeNavigationArea6 .cubeLink:nth-child(7n + 2) {
    width: 40%;
    padding-right: 1.5%;
}
.cubeNavigationArea6 .cubeLink:nth-child(7n + 3) {
    width: 40%;
    padding-right: 1.5%;
}
/*@media screen and (max-width: 480px){
	.cubeNavigationArea6 .cubeLink:nth-child(7n+2){height:72.5px;}
	.cubeNavigationArea6 .cubeLink:nth-child(7n+3){height:72.5px;}
}*/
.cubeNavigationArea6 .cubeLink:nth-child(7n + 4) {
    width: 60%;
    clear: left;
}
.cubeNavigationArea6 .cubeLink:nth-child(7n + 5) {
    width: 40%;
    padding-right: 1.5%;
}
.cubeNavigationArea6 .cubeLink:nth-child(7n + 6) {
    width: 60%;
}
.cubeNavigationArea6 .cubeLink:nth-child(7n + 7) {
    width: 40%;
    padding-right: 1.5%;
}

.cubeNavigationArea6 .cubeLink:nth-child(7n + 1) .cubeLink_a .cubeLink_bg {
    background-color: #6abf48;
}
.cubeNavigationArea6 .cubeLink:nth-child(7n + 2) .cubeLink_a .cubeLink_bg {
    background-color: #47b8e0;
}
.cubeNavigationArea6 .cubeLink:nth-child(7n + 3) .cubeLink_a .cubeLink_bg {
    background-color: #e19024;
}
.cubeNavigationArea6 .cubeLink:nth-child(7n + 4) .cubeLink_a .cubeLink_bg {
    background-color: #14b4f0;
}
.cubeNavigationArea6 .cubeLink:nth-child(7n + 5) .cubeLink_a .cubeLink_bg {
    background-color: #27bbd5;
}
.cubeNavigationArea6 .cubeLink:nth-child(7n + 6) .cubeLink_a .cubeLink_bg {
    background-color: #ed9d24;
}
.cubeNavigationArea6 .cubeLink:nth-child(7n + 7) .cubeLink_a .cubeLink_bg {
    background-color: #59ab3f;
}

.cubeNavigationArea6 .cubeLink:nth-child(7n + 1) .icon-cube:before {
    content: '\e606';
}
.cubeNavigationArea6 .cubeLink:nth-child(7n + 2) .icon-cube:before {
    content: '\e642';
}
.cubeNavigationArea6 .cubeLink:nth-child(7n + 3) .icon-cube:before {
    content: '\e62c';
}
.cubeNavigationArea6 .cubeLink:nth-child(7n + 4) .icon-cube:before {
    content: '\e67c';
}
.cubeNavigationArea6 .cubeLink:nth-child(7n + 5) .icon-cube:before {
    content: '\e6c4';
}
.cubeNavigationArea6 .cubeLink:nth-child(7n + 6) .icon-cube:before {
    content: '\e64f';
}
.cubeNavigationArea6 .cubeLink:nth-child(7n + 7) .icon-cube:before {
    content: '\e6f3';
}

.cubeNavigationArea6 .cubeLink:nth-child(7n + 1) .cubeLink_text {
    bottom: 10%;
    text-align: center;
}
.cubeNavigationArea6 .cubeLink:nth-child(7n + 2) .cubeLink_text {
    text-align: center;
}
.cubeNavigationArea6 .cubeLink:nth-child(7n + 3) .cubeLink_text {
    text-align: center;
}
.cubeNavigationArea6 .cubeLink:nth-child(7n + 4) .cubeLink_text {
    text-align: center;
}
.cubeNavigationArea6 .cubeLink:nth-child(7n + 5) .cubeLink_text {
    text-align: center;
}
.cubeNavigationArea6 .cubeLink:nth-child(7n + 6) .cubeLink_text {
    text-align: center;
}
.cubeNavigationArea6 .cubeLink:nth-child(7n + 7) .cubeLink_text {
    text-align: center;
}

/*========*/
/*.formStyle31.moduleStyle7{background:#FFFFFF;}*/
.cubeNavigationArea7 .cubeLink {
    width: 24%;
    height: auto;
    padding-bottom: 4%;
    color: #ffffff;
    display: inline-block;
    float: none;
    vertical-align: top;
    min-height: 3.5rem;
}
.cubeNavigationArea7 .cubeLink .cubeLink_a .cubeLink_ico {
    position: relative !important;
    width: 2.6rem;
    height: 2.6rem;
    top: auto !important;
    bottom: auto !important;
    left: 0 !important;
    right: 0 !important;
    margin: auto !important;
    background-position: center center;
    background-repeat: no-repeat;
    border-radius: 50%;
}
.cubeNavigationArea7 .cubeLink .cubeLink_a .cubeLink_text {
    color: #333;
    font-size: 14px;
}
.cubeNavigationArea7 .cubeLink .cubeLink_a .cubeLink_bg {
    margin: auto;
    position: absolute;
    background-size: 40px 40px;
    width: 2.6rem;
    height: 2.6rem;
    left: 0;
    right: 0;
    background-position: center center;
    background-repeat: no-repeat;
    border-radius: 50%;
}

.cubeNavigationArea7 .cubeLink .cubeLink_a .cubeLink_text {
    left: auto !important;
    right: auto !important;
    top: auto !important;
    bottom: 0 !important;
    text-align: center !important;
    height: auto !important;
    position: relative !important;
    display: block !important;
    margin-top: 2.95rem;
}
.cubeNavigationArea7 .cubeLink .cubeLink_a .cubeLink_text .cubeLink_text_p {
    display: block !important;
    padding: 0 !important;
    font-size: 0.6rem;
    overflow: visible !important;
    white-space: normal !important;
    word-break: break-all !important;
}

.cubeNavigationArea7 .cubeLink_row:nth-child(1) .cubeLink {
    padding-top: 4.5%;
}

.cubeNavigationArea7 .cubeLink:nth-child(4n + 1) {
    padding-left: 2%;
}
.cubeNavigationArea7 .cubeLink:nth-child(4n + 2) {
    padding-left: 0;
}
.cubeNavigationArea7 .cubeLink:nth-child(4n + 3) {
    padding-left: 0;
}
.cubeNavigationArea7 .cubeLink:nth-child(4n + 4) {
    padding-left: 0;
}

.cubeNavigationArea7 .cubeLink_row:nth-child(3n + 1) .cubeLink:nth-child(1) .icon-cube:before {
    content: '\e606';
    font-size: 1.5rem;
    margin-left: 0.55rem;
}
.cubeNavigationArea7 .cubeLink_row:nth-child(3n + 1) .cubeLink:nth-child(2) .icon-cube:before {
    content: '\e688';
    font-size: 1.5rem;
    margin-left: 0.55rem;
}
.cubeNavigationArea7 .cubeLink_row:nth-child(3n + 1) .cubeLink:nth-child(3) .icon-cube:before {
    content: '\e694';
    font-size: 1.5rem;
    margin-left: 0.55rem;
}
.cubeNavigationArea7 .cubeLink_row:nth-child(3n + 1) .cubeLink:nth-child(4) .icon-cube:before {
    content: '\e61b';
    font-size: 1.5rem;
    margin-left: 0.55rem;
}
.cubeNavigationArea7 .cubeLink_row:nth-child(3n + 2) .cubeLink:nth-child(1) .icon-cube:before {
    content: '\e64c';
    font-size: 1.5rem;
    margin-left: 0.55rem;
}
.cubeNavigationArea7 .cubeLink_row:nth-child(3n + 2) .cubeLink:nth-child(2) .icon-cube:before {
    content: '\e67c';
    font-size: 1.5rem;
    margin-left: 0.55rem;
}
.cubeNavigationArea7 .cubeLink_row:nth-child(3n + 2) .cubeLink:nth-child(3) .icon-cube:before {
    content: '\e640';
    font-size: 1.5rem;
    margin-left: 0.55rem;
}
.cubeNavigationArea7 .cubeLink_row:nth-child(3n + 2) .cubeLink:nth-child(4) .icon-cube:before {
    content: '\e652';
    font-size: 1.5rem;
    margin-left: 0.55rem;
}
.cubeNavigationArea7 .cubeLink_row:nth-child(3n + 3) .cubeLink:nth-child(1) .icon-cube:before {
    content: '\e65f';
    font-size: 1.5rem;
    margin-left: 0.55rem;
}
.cubeNavigationArea7 .cubeLink_row:nth-child(3n + 3) .cubeLink:nth-child(2) .icon-cube:before {
    content: '\e686';
    font-size: 1.5rem;
    margin-left: 0.55rem;
}
.cubeNavigationArea7 .cubeLink_row:nth-child(3n + 3) .cubeLink:nth-child(3) .icon-cube:before {
    content: '\e6e1';
    font-size: 1.5rem;
    margin-left: 0.55rem;
}
.cubeNavigationArea7 .cubeLink_row:nth-child(3n + 3) .cubeLink:nth-child(4) .icon-cube:before {
    content: '\e70d';
    font-size: 1.5rem;
    margin-left: 0.55rem;
}

.cubeNavigationArea7 .cubeLink_row:nth-child(3n + 1) .cubeLink:nth-child(1) .cubeLink_bg {
    background-color: #37a1db;
}
.cubeNavigationArea7 .cubeLink_row:nth-child(3n + 1) .cubeLink:nth-child(2) .cubeLink_bg {
    background-color: #678be0;
}
.cubeNavigationArea7 .cubeLink_row:nth-child(3n + 1) .cubeLink:nth-child(3) .cubeLink_bg {
    background-color: #8c66de;
}
.cubeNavigationArea7 .cubeLink_row:nth-child(3n + 1) .cubeLink:nth-child(4) .cubeLink_bg {
    background-color: #83d017;
}
.cubeNavigationArea7 .cubeLink_row:nth-child(3n + 2) .cubeLink:nth-child(1) .cubeLink_bg {
    background-color: #f2b513;
}
.cubeNavigationArea7 .cubeLink_row:nth-child(3n + 2) .cubeLink:nth-child(2) .cubeLink_bg {
    background-color: #14c761;
}
.cubeNavigationArea7 .cubeLink_row:nth-child(3n + 2) .cubeLink:nth-child(3) .cubeLink_bg {
    background-color: #fc5366;
}
.cubeNavigationArea7 .cubeLink_row:nth-child(3n + 2) .cubeLink:nth-child(4) .cubeLink_bg {
    background-color: #3b6ddf;
}
.cubeNavigationArea7 .cubeLink_row:nth-child(3n + 3) .cubeLink:nth-child(1) .cubeLink_bg {
    background-color: #688ce3;
}
.cubeNavigationArea7 .cubeLink_row:nth-child(3n + 3) .cubeLink:nth-child(2) .cubeLink_bg {
    background-color: #36a1da;
}
.cubeNavigationArea7 .cubeLink_row:nth-child(3n + 3) .cubeLink:nth-child(3) .cubeLink_bg {
    background-color: #83cf16;
}
.cubeNavigationArea7 .cubeLink_row:nth-child(3n + 3) .cubeLink:nth-child(4) .cubeLink_bg {
    background-color: #8d65e0;
}

/*========*/
.cubeNavigationArea8 .cubeLink {
    box-sizing: border-box;
    height: 3.75rem;
    color: #ffffff;
    width: 25%;
    min-height: 3.75rem;
}
.cubeNavigationArea8 .cubeLink .cubeLink_a .cubeLink_bg {
    background-color: #0180e1;
}
.cubeNavigationArea8 .cubeLink .cubeLink_a .cubeLink_ico {
    left: 0;
    right: 0;
    top: 10%;
}
.cubeNavigationArea8 .cubeLink .cubeLink_a .cubeLink_text {
    color: #ffffff;
    font-size: 14px;
    text-align: center;
    bottom: 10%;
}

.cubeNavigationArea8 .cubeLink:nth-child(12n + 1) {
    width: 50%;
    height: 7.5rem;
    clear: left;
}
.cubeNavigationArea8 .cubeLink:nth-child(12n + 2) {
    width: 25%;
}
.cubeNavigationArea8 .cubeLink:nth-child(12n + 3) {
    width: 25%;
    padding-right: 1.5%;
}
.cubeNavigationArea8 .cubeLink:nth-child(12n + 4) {
    width: 25%;
}
.cubeNavigationArea8 .cubeLink:nth-child(12n + 5) {
    width: 25%;
    padding-right: 1.5%;
}
.cubeNavigationArea8 .cubeLink:nth-child(12n + 6) {
    width: 50%;
    clear: left;
}
.cubeNavigationArea8 .cubeLink:nth-child(12n + 7) {
    width: 50%;
    padding-right: 1.5%;
}
.cubeNavigationArea8 .cubeLink:nth-child(12n + 8) {
    width: 50%;
    height: 7.5rem;
}
.cubeNavigationArea8 .cubeLink:nth-child(12n + 9) {
    width: 25%;
}
.cubeNavigationArea8 .cubeLink:nth-child(12n + 10) {
    width: 25%;
    padding-right: 1.5%;
}
.cubeNavigationArea8 .cubeLink:nth-child(12n + 11) {
    width: 25%;
}
.cubeNavigationArea8 .cubeLink:nth-child(12n + 12) {
    width: 25%;
    padding-right: 1.5%;
}

.cubeNavigationArea8 .cubeLink:nth-child(12n + 1) .cubeLink_a .cubeLink_ico {
    top: 23%;
}
.cubeNavigationArea8 .cubeLink:nth-child(12n + 1) .cubeLink_a .cubeLink_text {
    bottom: 36%;
}
.cubeNavigationArea8 .cubeLink:nth-child(12n + 8) .cubeLink_a .cubeLink_ico {
    top: 23%;
}
.cubeNavigationArea8 .cubeLink:nth-child(12n + 8) .cubeLink_a .cubeLink_text {
    bottom: 36%;
}
.cubeNavigationArea8 .cubeLink:nth-child(12n + 6) .cubeLink_a .cubeLink_bg {
    background-image: url(/image/styleDesign/cube/cover/1.jpg?v=201711250607);
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.cubeNavigationArea8 .cubeLink:nth-child(12n + 7) .cubeLink_a .cubeLink_bg {
    background-image: url(/image/styleDesign/cube/cover/2.jpg?v=201711250607);
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.cubeNavigationArea8 .cubeLink:nth-child(12n + 1) .icon-cube:before {
    content: '\e694';
}
.cubeNavigationArea8 .cubeLink:nth-child(12n + 2) .icon-cube:before {
    content: '\e62c';
}
.cubeNavigationArea8 .cubeLink:nth-child(12n + 3) .icon-cube:before {
    content: '\e688';
}
.cubeNavigationArea8 .cubeLink:nth-child(12n + 4) .icon-cube:before {
    content: '\e67c';
}
.cubeNavigationArea8 .cubeLink:nth-child(12n + 5) .icon-cube:before {
    content: '\e61f';
}
.cubeNavigationArea8 .cubeLink:nth-child(12n + 8) .icon-cube:before {
    content: '\e642';
}
.cubeNavigationArea8 .cubeLink:nth-child(12n + 9) .icon-cube:before {
    content: '\e648';
}
.cubeNavigationArea8 .cubeLink:nth-child(12n + 10) .icon-cube:before {
    content: '\e636';
}
.cubeNavigationArea8 .cubeLink:nth-child(12n + 11) .icon-cube:before {
    content: '\e63a';
}
.cubeNavigationArea8 .cubeLink:nth-child(12n + 12) .icon-cube:before {
    content: '\e6c6';
}

/*========*/
.cubeNavigationArea9 {
    width: 101%;
}
.cubeNavigationArea9 .cubeLink {
    width: 33.33%;
    padding-left: 0;
    padding-bottom: 0;
    height: auto;
}
.cubeNavigationArea9 .cubeLink .cubeLink_a .cubeLink_ico {
    left: 0;
    right: 0;
    margin-top: 15%;
    position: relative;
}
.cubeNavigationArea9 .cubeLink .cubeLink_a .cubeLink_text {
    text-align: center;
    color: #ffffff;
    font-size: 14px;
    position: relative;
}
.cubeNavigationArea9 .cubeLink .cubeLink_a .cubeLink_text .cubeLink_text_p {
    overflow: visible;
    white-space: normal;
    word-break: break-all;
}

/*.cubeNavigationArea9 .cubeLink:nth-child(3n+2){width: 33.33%;}*/
.cubeNavigationArea9 .cubeLink_row:nth-child(2n + 1) .cubeLink:nth-child(1) .cubeLink_bg {
    background: linear-gradient(to bottom right, #5c5c5d, #111212);
}
.cubeNavigationArea9 .cubeLink_row:nth-child(2n + 1) .cubeLink:nth-child(2) .cubeLink_bg {
    background: linear-gradient(to bottom right, #9bd7d2, #2c7f81);
}
.cubeNavigationArea9 .cubeLink_row:nth-child(2n + 1) .cubeLink:nth-child(3) .cubeLink_bg {
    background: linear-gradient(to bottom right, #5c5c5d, #111212);
}
.cubeNavigationArea9 .cubeLink_row:nth-child(2n + 2) .cubeLink:nth-child(1) .cubeLink_bg {
    background: linear-gradient(to bottom right, #9bd7d2, #2c7f81);
}
.cubeNavigationArea9 .cubeLink_row:nth-child(2n + 2) .cubeLink:nth-child(2) .cubeLink_bg {
    background: linear-gradient(to bottom right, #5c5c5d, #111212);
}
.cubeNavigationArea9 .cubeLink_row:nth-child(2n + 2) .cubeLink:nth-child(3) .cubeLink_bg {
    background: linear-gradient(to bottom right, #9bd7d2, #2c7f81);
}

.cubeNavigationArea9 .cubeLink_row:nth-child(3n + 1) .cubeLink:nth-child(1) .icon-cube:before {
    content: '\e642';
}
.cubeNavigationArea9 .cubeLink_row:nth-child(3n + 1) .cubeLink:nth-child(2) .icon-cube:before {
    content: '\e6a6';
}
.cubeNavigationArea9 .cubeLink_row:nth-child(3n + 1) .cubeLink:nth-child(3) .icon-cube:before {
    content: '\e6ac';
}
.cubeNavigationArea9 .cubeLink_row:nth-child(3n + 2) .cubeLink:nth-child(1) .icon-cube:before {
    content: '\e678';
}
.cubeNavigationArea9 .cubeLink_row:nth-child(3n + 2) .cubeLink:nth-child(2) .icon-cube:before {
    content: '\e638';
}
.cubeNavigationArea9 .cubeLink_row:nth-child(3n + 2) .cubeLink:nth-child(3) .icon-cube:before {
    content: '\e615';
}
.cubeNavigationArea9 .cubeLink_row:nth-child(3n + 3) .cubeLink:nth-child(1) .icon-cube:before {
    content: '\e62c';
}
.cubeNavigationArea9 .cubeLink_row:nth-child(3n + 3) .cubeLink:nth-child(2) .icon-cube:before {
    content: '\e67c';
}
.cubeNavigationArea9 .cubeLink_row:nth-child(3n + 3) .cubeLink:nth-child(3) .icon-cube:before {
    content: '\e6b8';
}
.cubeNavigationArea9 .cubeLink_fixWidth_1 {
    width: 100% !important;
}
.cubeNavigationArea9 .cubeLink_fixWidth_2 {
    width: 50% !important;
}

/*========*/
.cubeNavigationArea10 .icon-cube {
    color: #5c6066;
}
.template349.formStyle31.moduleStyle10:nth-child(1) {
    margin-top: 215px;
    margin-bottom: 50px;
}
.cubeNavigationArea10 .cubeLink {
    width: 45.5%;
    height: auto;
    padding-bottom: 4%;
    padding-left: 3%;
    color: #ffffff;
    min-height: 4.5rem;
}
.cubeNavigationArea10 .cubeLink .cubeLink_a .cubeLink_bg {
    background-color: #ffffff;
    opacity: 0.8;
    border-radius: 5px 5px;
}
.cubeNavigationArea10 .cubeLink .cubeLink_a .cubeLink_ico {
    left: 0;
    right: 0;
    top: 0.9rem;
    position: relative;
}
.cubeNavigationArea10 .cubeLink .cubeLink_a .cubeLink_text {
    color: #333;
    font-size: 16px;
    text-align: center;
    margin-top: 12%;
    position: relative;
}
.cubeNavigationArea10 .cubeLink .cubeLink_a .cubeLink_text .cubeLink_text_p {
    overflow: visible;
    white-space: normal;
    word-break: break-all;
}

.cubeNavigationArea10 .cubeLink_row:nth-child(3n + 1) .cubeLink:nth-child(1) .icon-cube:before {
    content: '\e62e';
}
.cubeNavigationArea10 .cubeLink_row:nth-child(3n + 1) .cubeLink:nth-child(2) .icon-cube:before {
    content: '\e61f';
}
.cubeNavigationArea10 .cubeLink_row:nth-child(3n + 2) .cubeLink:nth-child(1) .icon-cube:before {
    content: '\e688';
}
.cubeNavigationArea10 .cubeLink_row:nth-child(3n + 2) .cubeLink:nth-child(2) .icon-cube:before {
    content: '\e67c';
}
.cubeNavigationArea10 .cubeLink_row:nth-child(3n + 3) .cubeLink:nth-child(1) .icon-cube:before {
    content: '\e65f';
}
.cubeNavigationArea10 .cubeLink_row:nth-child(3n + 3) .cubeLink:nth-child(2) .icon-cube:before {
    content: '\e640';
}

.cubeNavigationArea10 .cubeLink_fixWidth_1 {
    width: 94% !important;
}

/*========*/
.cubeNavigationArea11 .cubeLink {
    width: 31%;
    height: 5rem;
    padding-bottom: 2%;
    color: #ffffff;
    font-size: 16px;
}
.cubeNavigationArea11 .cubeLink .cubeLink_a .cubeLink_bg {
    background-color: #ffffff;
    opacity: 0.8;
    border-radius: 1px 1px;
}
.cubeNavigationArea11 .cubeLink .cubeLink_a .cubeLink_ico {
    left: 0;
    right: 0;
    top: 24%;
}
.cubeNavigationArea11 .cubeLink .cubeLink_a .cubeLink_text {
    color: #333;
    font-size: 14px;
    text-align: center;
    bottom: 20%;
}

.cubeNavigationArea11 .cubeLink:nth-child(6n + 8) {
    padding-left: 2%;
}
.cubeNavigationArea11 .cubeLink:nth-child(6n + 9) {
    padding-left: 11%;
}
.cubeNavigationArea11 .cubeLink:nth-child(6n + 10) {
    padding-left: 2%;
}
.cubeNavigationArea11 .cubeLink:nth-child(6n + 11) {
    padding-left: 11%;
}
.cubeNavigationArea11 .cubeLink:nth-child(6n + 12) {
    padding-left: 2%;
}
.cubeNavigationArea11 .cubeLink:nth-child(6n + 13) {
    padding-left: 11%;
}

.cubeNavigationArea11 .cubeLink:nth-child(6n + 8) .icon-cube:before {
    content: '\e640';
}
.cubeNavigationArea11 .cubeLink:nth-child(6n + 9) .icon-cube:before {
    content: '\e62e';
}
.cubeNavigationArea11 .cubeLink:nth-child(6n + 10) .icon-cube:before {
    content: '\e6dd';
}
.cubeNavigationArea11 .cubeLink:nth-child(6n + 11) .icon-cube:before {
    content: '\e6aa';
}
.cubeNavigationArea11 .cubeLink:nth-child(6n + 12) .icon-cube:before {
    content: '\e67c';
}
.cubeNavigationArea11 .cubeLink:nth-child(6n + 13) .icon-cube:before {
    content: '\e6e1';
}

.cubeNavigationArea11 .icon-cube {
    color: #5c6066;
}
.cubeNavigationArea11 .cubeLink:nth-child(1) .cubeLink_a .cubeLink_ico {
    background-image: none !important;
}
.cubeNavigationArea11 .cubeLink:nth-child(1) .cubeLink_a .cubeLink_ico:before {
    content: none !important;
}
.cubeNavigationArea11 .cubeLink:nth-child(1) .cubeLink_a .cubeLink_text {
    font-size: 22px;
    position: relative !important;
    text-align: center !important;
    display: table !important;
    height: 100% !important;
    left: auto !important;
    right: auto !important;
    top: auto !important;
    bottom: auto !important;
}
.cubeNavigationArea11 .cubeLink:nth-child(1) .cubeLink_a .cubeLink_text .cubeLink_text_p {
    display: table-cell !important;
    vertical-align: middle !important;
    padding: 0 !important;
}
.cubeNavigationArea11 .cubeLink:nth-child(2) .icon-cube:before {
    content: '\e640';
}
.cubeNavigationArea11 .cubeLink:nth-child(3) .icon-cube:before {
    content: '\e62e';
}
.cubeNavigationArea11 .cubeLink:nth-child(4) .icon-cube:before {
    content: '\e6dd';
}
.cubeNavigationArea11 .cubeLink:nth-child(5) .icon-cube:before {
    content: '\e6aa';
}
.cubeNavigationArea11 .cubeLink:nth-child(6) .icon-cube:before {
    content: '\e67c';
}
.cubeNavigationArea11 .cubeLink:nth-child(7) .icon-cube:before {
    content: '\e6e1';
}
.cubeNavigationArea11 .cubeLink:nth-child(1) {
    padding-left: 2%;
    float: right;
    width: 56%;
}
.cubeNavigationArea11 .cubeLink:nth-child(2) {
    padding-left: 11%;
}
.cubeNavigationArea11 .cubeLink:nth-child(3) {
    padding-left: 11%;
}
.cubeNavigationArea11 .cubeLink:nth-child(4) {
    padding-left: 2%;
}
.cubeNavigationArea11 .cubeLink:nth-child(5) {
    padding-left: 11%;
}
.cubeNavigationArea11 .cubeLink:nth-child(6) {
    padding-left: 2%;
}
.cubeNavigationArea11 .cubeLink:nth-child(7) {
    padding-left: 11%;
}

/*========*/
.template350.formStyle31.moduleStyle12:nth-child(1) {
    margin-top: 212px;
    margin-bottom: 50px;
}
.cubeNavigationArea12 .cubeLink {
    width: 40%;
    height: auto;
    color: #ffffff;
    min-height: 1.8rem;
}
.cubeNavigationArea12 .cubeLink .cubeLink_a .cubeLink_bg {
    background-color: #ffffff;
    opacity: 0.5;
    border-radius: 1px;
    box-shadow: 0px 0px 1px 1px #cacaca;
}
.cubeNavigationArea12 .cubeLink .cubeLink_a .cubeLink_ico {
    left: 0;
    right: 0;
    background-image: none !important;
}
.cubeNavigationArea12 .cubeLink .cubeLink_a .cubeLink_ico:before {
    content: none !important;
}
.cubeNavigationArea12 .cubeLink .cubeLink_a .cubeLink_text {
    color: #333;
    font-size: 14px;
    position: relative !important;
    text-align: center !important;
    display: table !important;
    height: 100% !important;
    left: auto !important;
    right: auto !important;
    top: auto !important;
    bottom: auto !important;
    margin-top: 0rem !important;
}
.cubeNavigationArea12 .cubeLink .cubeLink_a .cubeLink_text .cubeLink_text_p {
    display: table-cell !important;
    vertical-align: middle !important;
    padding: 0 !important;
    overflow: visible !important;
    white-space: normal !important;
    word-break: break-all !important;
}

.cubeNavigationArea12 .cubeLink:nth-child(2n + 1) {
    padding-left: 10%;
}

.cubeNavigationArea12 .cubeLink_fixWidth_1 {
    width: 81.5% !important;
}

/*========*/
.cubeNavigationArea13 .icon-cube {
    font-size: 2.2rem;
}
.cubeNavigationArea13 {
    padding-top: 0;
    width: 100%;
}
.cubeNavigationArea13 .cubeLink {
    width: 33%;
    min-height: 5.2rem;
    height: auto;
    padding: 0;
    padding-bottom: 2px;
    color: #ffffff;
    background: transparent;
    box-sizing: border-box;
    float: none;
    display: inline-block;
    vertical-align: top;
}
.cubeNavigationArea13 .cubeLink .cubeLink_a {
    background: #fff;
}
.cubeNavigationArea13 .cubeLink .cubeLink_a .cubeLink_ico {
    position: relative !important;
    width: 2.8rem;
    height: 2.8rem;
    top: 0.5rem !important;
    bottom: auto !important;
    left: 0 !important;
    right: 0 !important;
    margin: auto !important;
    background-position: center center;
    background-repeat: no-repeat;
    border-radius: 50%;
}
.cubeNavigationArea13 .cubeLink .cubeLink_a .cubeLink_text {
    color: #333;
    font-size: 14px;
}
.cubeNavigationArea13 .cubeLink .cubeLink_a .cubeLink_bg {
    margin: auto;
    position: absolute;
    background-size: 40px 40px;
    width: 2.8rem;
    height: 2.8rem;
    left: 0;
    right: 0;
    top: 0.5rem;
    background-position: center center;
    background-repeat: no-repeat;
    border-radius: 50%;
}

.cubeNavigationArea13 .cubeLink .cubeLink_a .cubeLink_text {
    left: auto !important;
    right: auto !important;
    top: auto !important;
    bottom: 0.5rem !important;
    text-align: center !important;
    height: auto !important;
    position: relative !important;
    display: block !important;
    margin-top: 1.4rem !important;
}
.cubeNavigationArea13 .cubeLink .cubeLink_a .cubeLink_text .cubeLink_text_p {
    display: block !important;
    padding: 0 !important;
    overflow: visible !important;
    white-space: normal !important;
    word-break: break-all !important;
}

/*.cubeNavigationArea13 .cubeLink:nth-child(3n+2){border-left:1px solid #F4F4F4;border-right:1px solid #F4F4F4;}
.cubeNavigationArea13 .cubeLink:nth-child(1){border-top:none;}
.cubeNavigationArea13 .cubeLink:nth-child(2){border-top:none;}
.cubeNavigationArea13 .cubeLink:nth-child(3){border-top:none;}*/
.cubeNavigationArea13 .cubeLink_row .cubeLink:nth-child(2) {
    width: 34%;
    padding-left: 2px;
    padding-right: 2px;
}
.cubeNavigationArea13 .cubeLink_row:nth-child(3n + 1) .cubeLink:nth-child(1) .icon-cube:before {
    content: '\e6e4';
    font-size: 1.7rem;
    margin-left: 0.55rem;
}
.cubeNavigationArea13 .cubeLink_row:nth-child(3n + 1) .cubeLink:nth-child(2) .icon-cube:before {
    content: '\e6ea';
    font-size: 1.7rem;
    margin-left: 0.55rem;
}
.cubeNavigationArea13 .cubeLink_row:nth-child(3n + 1) .cubeLink:nth-child(3) .icon-cube:before {
    content: '\e65a';
    font-size: 1.7rem;
    margin-left: 0.55rem;
}
.cubeNavigationArea13 .cubeLink_row:nth-child(3n + 2) .cubeLink:nth-child(1) .icon-cube:before {
    content: '\e6fa';
    font-size: 1.7rem;
    margin-left: 0.55rem;
}
.cubeNavigationArea13 .cubeLink_row:nth-child(3n + 2) .cubeLink:nth-child(2) .icon-cube:before {
    content: '\e6ec';
    font-size: 1.7rem;
    margin-left: 0.55rem;
}
.cubeNavigationArea13 .cubeLink_row:nth-child(3n + 2) .cubeLink:nth-child(3) .icon-cube:before {
    content: '\e613';
    font-size: 1.7rem;
    margin-left: 0.55rem;
}
.cubeNavigationArea13 .cubeLink_row:nth-child(3n + 3) .cubeLink:nth-child(1) .icon-cube:before {
    content: '\e6da';
    font-size: 1.7rem;
    margin-left: 0.55rem;
}
.cubeNavigationArea13 .cubeLink_row:nth-child(3n + 3) .cubeLink:nth-child(2) .icon-cube:before {
    content: '\e614';
    font-size: 1.7rem;
    margin-left: 0.55rem;
}
.cubeNavigationArea13 .cubeLink_row:nth-child(3n + 3) .cubeLink:nth-child(3) .icon-cube:before {
    content: '\e6c3';
    font-size: 1.7rem;
    margin-left: 0.55rem;
}

.cubeNavigationArea13 .cubeLink_row:nth-child(3n + 1) .cubeLink:nth-child(1) .cubeLink_bg {
    background-color: #fdc200;
}
.cubeNavigationArea13 .cubeLink_row:nth-child(3n + 1) .cubeLink:nth-child(2) .cubeLink_bg {
    background-color: #96a3fc;
}
.cubeNavigationArea13 .cubeLink_row:nth-child(3n + 1) .cubeLink:nth-child(3) .cubeLink_bg {
    background-color: #f99161;
}
.cubeNavigationArea13 .cubeLink_row:nth-child(3n + 2) .cubeLink:nth-child(1) .cubeLink_bg {
    background-color: #c697d8;
}
.cubeNavigationArea13 .cubeLink_row:nth-child(3n + 2) .cubeLink:nth-child(2) .cubeLink_bg {
    background-color: #8fa5ad;
}
.cubeNavigationArea13 .cubeLink_row:nth-child(3n + 2) .cubeLink:nth-child(3) .cubeLink_bg {
    background-color: #ed6492;
}
.cubeNavigationArea13 .cubeLink_row:nth-child(3n + 3) .cubeLink:nth-child(1) .cubeLink_bg {
    background-color: #54d0e3;
}
.cubeNavigationArea13 .cubeLink_row:nth-child(3n + 3) .cubeLink:nth-child(2) .cubeLink_bg {
    background-color: #fcbd15;
}
.cubeNavigationArea13 .cubeLink_row:nth-child(3n + 3) .cubeLink:nth-child(3) .cubeLink_bg {
    background-color: #98ce5a;
}

/*.cubeNavigationArea13 .cubeLink_fixWidth_1{width: 100% !important;}
.cubeNavigationArea13 .cubeLink_fixWidth_2{width: 50% !important;}*/

/*========*/
.formStyle31.moduleStyle14 {
    /*background:#FFFFFF;*/
    margin-top: 0;
}
.cubeNavigationArea14 {
    width: 100%;
    padding-top: 0;
}
.cubeNavigationArea14 .icon-cube {
    color: #5c6066;
}
.cubeNavigationArea14 .cubeLink {
    box-sizing: border-box;
    width: 25%;
    padding: 0;
    padding-bottom: 0.5%;
    height: 4.2rem;
    min-height: 4.2rem;
    color: #ffffff;
    background: transparent; /*border-top:1px solid #F4F4F4;box-sizing:border-box;*/
}
.cubeNavigationArea14 .cubeLink .cubeLink_a .cubeLink_bg {
    background: #fff;
}
.cubeNavigationArea14 .cubeLink .cubeLink_a .cubeLink_ico {
    bottom: auto !important;
    left: 0 !important;
    right: 0 !important;
    margin: auto !important;
}
.cubeNavigationArea14 .cubeLink .cubeLink_a .cubeLink_text {
    color: #333;
    font-size: 14px;
    bottom: 0.6rem !important;
    left: auto !important;
    right: auto !important;
    top: auto !important;
    text-align: center !important;
    height: auto !important;
    position: absolute !important;
    display: block !important;
}
.cubeNavigationArea14 .cubeLink .cubeLink_a .cubeLink_text .cubeLink_text_p {
    display: block !important;
    padding: 0 !important;
    font-size: 0.6rem;
    overflow: visible !important;
    white-space: normal !important;
    word-break: break-all !important;
}

.cubeNavigationArea14 .cubeLink_row_head .cubeLink {
    padding-right: 0.5%;
    height: auto;
}
.cubeNavigationArea14 .cubeLink_row_head .cubeLink .cubeLink_a .cubeLink_ico {
    position: relative !important;
    bottom: 0rem !important;
    margin-top: 0.5rem !important;
    top: auto !important;
}
.cubeNavigationArea14 .cubeLink_row_head .cubeLink .cubeLink_a .cubeLink_text {
    position: relative !important;
    bottom: 0rem !important;
    margin-top: 0.2rem !important;
}

.cubeNavigationArea14 .cubeLink_row_head:nth-child(1) .cubeLink:nth-child(1) .icon-cube:before {
    content: '\e6f2';
}
.cubeNavigationArea14 .cubeLink_row_head:nth-child(1) .cubeLink:nth-child(2) .icon-cube:before {
    content: '\e6fa';
}
.cubeNavigationArea14 .cubeLink_row_head:nth-child(1) .cubeLink:nth-child(3) .icon-cube:before {
    content: '\e6f6';
}
.cubeNavigationArea14 .cubeLink_row_head:nth-child(1) .cubeLink:nth-child(4) .icon-cube:before {
    content: '\e6e8';
}
.cubeNavigationArea14 .cubeLink_row_head:nth-child(2) .cubeLink:nth-child(1) .icon-cube:before {
    content: '\e709';
}
.cubeNavigationArea14 .cubeLink_row_head:nth-child(2) .cubeLink:nth-child(2) .icon-cube:before {
    content: '\e70b';
}
.cubeNavigationArea14 .cubeLink_row_head:nth-child(2) .cubeLink:nth-child(3) .icon-cube:before {
    content: '\e6e6';
}
.cubeNavigationArea14 .cubeLink_row_head:nth-child(2) .cubeLink:nth-child(4) .icon-cube:before {
    content: '\e6c3';
}

.cubeNavigationArea14 .cubeLink_row:nth-child(n + 3) .cubeLink:nth-child(1) {
    width: 50%;
    padding-right: 0.5%;
}
.cubeNavigationArea14 .cubeLink_row:nth-child(n + 3) .cubeLink:nth-child(2) {
    width: 50%;
}
.cubeNavigationArea14
    .cubeLink_row:nth-child(n + 3)
    .cubeLink:nth-child(1)
    .cubeLink_a
    .cubeLink_text
    .cubeLink_text_p
    .cubeLink_subText_p {
    display: block;
}
.cubeNavigationArea14
    .cubeLink_row:nth-child(n + 3)
    .cubeLink:nth-child(2)
    .cubeLink_a
    .cubeLink_text
    .cubeLink_text_p
    .cubeLink_subText_p {
    display: block;
}
.cubeNavigationArea14 .cubeLink_row:nth-child(2n + 1) .cubeLink:nth-child(1) .cubeLink_a .cubeLink_text {
    font-size: 20px;
    position: relative !important;
    text-align: center !important;
    display: table !important;
    height: 100% !important;
    left: auto !important;
    right: auto !important;
    top: auto !important;
    bottom: auto !important;
}
.cubeNavigationArea14
    .cubeLink_row:nth-child(2n + 1)
    .cubeLink:nth-child(1)
    .cubeLink_a
    .cubeLink_text
    .cubeLink_text_p {
    display: table-cell !important;
    vertical-align: middle !important;
    padding: 0 !important;
}
.cubeNavigationArea14
    .cubeLink_row:nth-child(2n + 1)
    .cubeLink:nth-child(1)
    .cubeLink_a
    .cubeLink_text
    .cubeLink_text_p
    .cubeLink_subText_p {
    font-size: 0.6rem;
    font-weight: normal;
    color: #333;
    font-family: Arial;
    display: block;
}
.cubeNavigationArea14 .cubeLink_row:nth-child(2n + 1) .cubeLink:nth-child(2) .cubeLink_bg {
    background-image: url(/image/styleDesign/cube/cover/3.jpg?v=201711250607);
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.cubeNavigationArea14 .cubeLink_row:nth-child(2n + 2) .cubeLink:nth-child(1) .cubeLink_bg {
    background-image: url(/image/styleDesign/cube/cover/3.jpg?v=201711250607);
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.cubeNavigationArea14 .cubeLink_row:nth-child(2n + 2) .cubeLink:nth-child(2) .cubeLink_a .cubeLink_text {
    font-size: 20px;
    position: relative !important;
    text-align: center !important;
    display: table !important;
    height: 100% !important;
    left: auto !important;
    right: auto !important;
    top: auto !important;
    bottom: auto !important;
}
.cubeNavigationArea14
    .cubeLink_row:nth-child(2n + 2)
    .cubeLink:nth-child(2)
    .cubeLink_a
    .cubeLink_text
    .cubeLink_text_p {
    display: table-cell !important;
    vertical-align: middle !important;
    padding: 0 !important;
}
.cubeNavigationArea14
    .cubeLink_row:nth-child(2n + 2)
    .cubeLink:nth-child(2)
    .cubeLink_a
    .cubeLink_text
    .cubeLink_text_p
    .cubeLink_subText_p {
    font-size: 0.6rem;
    font-weight: normal;
    color: #333;
    font-family: Arial;
    display: block;
}

/*========*/
.formStyle31.moduleStyle15 {
    /*background:#FFFFFF;*/
    margin-top: 0;
}
.cubeNavigationArea15 {
    width: 100%;
    padding-top: 0;
}
.cubeNavigationArea15 .cubeLink {
    box-sizing: border-box;
    width: 50%;
    padding: 0;
    height: 3.7rem;
    padding-bottom: 0.5%;
    min-height: 3.7rem;
}
.cubeNavigationArea15 .cubeLink .cubeLink_a {
    overflow: hidden;
}
.cubeNavigationArea15 .cubeLink .cubeLink_a .cubeLink_bg {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.cubeNavigationArea15 .cubeLink .cubeLink_a .cubeLink_curtain {
    width: 57%;
    right: -14%;
    transform: skew(-30deg, 0deg);
    background: linear-gradient(to bottom, #fbfbfb, #d2cfd0);
}

@media only screen and (min-width: 480px) {
    .cubeNavigationArea15 .cubeLink .cubeLink_a .cubeLink_curtain {
        width: 55%;
    }
}

.cubeNavigationArea15 .cubeLink .cubeLink_a .cubeLink_ico {
    left: 0;
    right: 0;
    background-image: none !important;
}
.cubeNavigationArea15 .cubeLink .cubeLink_a .cubeLink_ico:before {
    content: none !important;
}
.cubeNavigationArea15 .cubeLink .cubeLink_a .cubeLink_text {
    font-size: 16px;
    color: #333333;
    position: relative !important;
    text-align: right !important;
    display: table !important;
    height: 100% !important;
    left: auto !important;
    right: auto !important;
    top: auto !important;
    bottom: auto !important;
}
.cubeNavigationArea15 .cubeLink .cubeLink_a .cubeLink_text .cubeLink_text_p {
    display: table-cell !important;
    vertical-align: middle !important;
    padding: 0 4% 0 0 !important;
}
.cubeNavigationArea15 .cubeLink .cubeLink_a .cubeLink_text .cubeLink_text_p .cubeLink_subText_p {
    font-size: 0.6rem;
    font-weight: normal;
    color: #333333;
    font-family: Arial;
    display: block;
}

.cubeNavigationArea15 .cubeLink:nth-child(2n + 1) {
    padding-right: 0.5%;
}
.cubeNavigationArea15 .cubeLink:nth-child(8n + 1) .cubeLink_a .cubeLink_bg {
    background-image: url(/image/styleDesign/cube/cover/7.jpg?v=201711250607);
}
.cubeNavigationArea15 .cubeLink:nth-child(8n + 2) .cubeLink_a .cubeLink_bg {
    background-image: url(/image/styleDesign/cube/cover/8.jpg?v=201711250607);
}
.cubeNavigationArea15 .cubeLink:nth-child(8n + 3) .cubeLink_a .cubeLink_bg {
    background-image: url(/image/styleDesign/cube/cover/4.jpg?v=201711250607);
}
.cubeNavigationArea15 .cubeLink:nth-child(8n + 4) .cubeLink_a .cubeLink_bg {
    background-image: url(/image/styleDesign/cube/cover/9.jpg?v=201711250607);
}
.cubeNavigationArea15 .cubeLink:nth-child(8n + 5) .cubeLink_a .cubeLink_bg {
    background-image: url(/image/styleDesign/cube/cover/5.jpg?v=201711250607);
}
.cubeNavigationArea15 .cubeLink:nth-child(8n + 6) .cubeLink_a .cubeLink_bg {
    background-image: url(/image/styleDesign/cube/cover/10.jpg?v=201711250607);
}
.cubeNavigationArea15 .cubeLink:nth-child(8n + 7) .cubeLink_a .cubeLink_bg {
    background-image: url(/image/styleDesign/cube/cover/6.jpg?v=201711250607);
}
.cubeNavigationArea15 .cubeLink:nth-child(8n + 8) .cubeLink_a .cubeLink_bg {
    background-image: url(/image/styleDesign/cube/cover/11.jpg?v=201711250607);
}

/*========*/
.formStyle31.moduleStyle16 {
    margin-top: 0;
}
.cubeNavigationArea16 {
    width: 100%;
    padding-top: 0;
}
.cubeNavigationArea16 .cubeLink {
    width: 50%;
    padding: 0;
    height: 3.7rem;
    min-height: 3.7rem;
}
.cubeNavigationArea16 .cubeLink .cubeLink_a .cubeLink_ico {
    left: 10%;
    top: 0;
    bottom: 0;
}
.cubeNavigationArea16 .cubeLink .cubeLink_a .cubeLink_text {
    font-size: 16px;
    position: relative;
    height: 100%;
    display: table;
    text-align: left;
    color: #ffffff;
}
.cubeNavigationArea16 .cubeLink .cubeLink_a .cubeLink_text .cubeLink_text_p {
    padding-left: 42%;
    display: table-cell;
    vertical-align: middle;
}
.cubeNavigationArea16 .cubeLink .cubeLink_a .cubeLink_text .cubeLink_text_p .cubeLink_subText_p {
    font-size: 0.6rem;
    font-weight: normal;
    color: #ffffff;
    font-family: Arial;
    display: block;
}
.cubeNavigationArea16 .cubeLink_fixWidth_1 .cubeLink_a .cubeLink_ico {
    left: 37%;
}
.cubeNavigationArea16 .cubeLink_fixWidth_1 .cubeLink_a .cubeLink_text .cubeLink_text_p {
    padding-left: 53%;
}

.cubeNavigationArea16 .cubeLink:nth-child(6n + 1) .cubeLink_a .cubeLink_bg {
    background: linear-gradient(to bottom, #f9aa2f, #fc9408);
}
.cubeNavigationArea16 .cubeLink:nth-child(6n + 2) .cubeLink_a .cubeLink_bg {
    background: linear-gradient(to right, #514a4c, #302c33);
}
.cubeNavigationArea16 .cubeLink:nth-child(6n + 3) .cubeLink_a .cubeLink_bg {
    background: linear-gradient(to bottom, #d74428, #ce2302);
}
.cubeNavigationArea16 .cubeLink:nth-child(6n + 4) .cubeLink_a .cubeLink_bg {
    background: linear-gradient(to bottom, #00658a, #014c76);
}
.cubeNavigationArea16 .cubeLink:nth-child(6n + 5) .cubeLink_a .cubeLink_bg {
    background: linear-gradient(to bottom, #5f7285, #3c5f6d);
}
.cubeNavigationArea16 .cubeLink:nth-child(6n + 6) .cubeLink_a .cubeLink_bg {
    background: linear-gradient(to bottom, #3dc4bd, #20b4b5);
}

.cubeNavigationArea16 .cubeLink:nth-child(6n + 1) .icon-cube:before {
    content: '\e686';
}
.cubeNavigationArea16 .cubeLink:nth-child(6n + 2) .icon-cube:before {
    content: '\e6ac';
}
.cubeNavigationArea16 .cubeLink:nth-child(6n + 3) .icon-cube:before {
    content: '\e67c';
}
.cubeNavigationArea16 .cubeLink:nth-child(6n + 4) .icon-cube:before {
    content: '\e6aa';
}
.cubeNavigationArea16 .cubeLink:nth-child(6n + 5) .icon-cube:before {
    content: '\e62c';
}
.cubeNavigationArea16 .cubeLink:nth-child(6n + 6) .icon-cube:before {
    content: '\e6b8';
}

.cubeNavigationArea16 .cubeLink_fixWidth_1 {
    width: 100% !important;
}

/*====新增魔方导航样式在这里上面加=====*/

/*====魔方导航各种样式end====*/

/*必须明确一点，固定布局样式必须放在最后面，否则会被上面的导航样式覆盖。*/

/*====固定布局样式start====*/
.cubeNavigationArea .cubeLink .cubeLink_a.textLayout1 .cubeLink_ico {
    left: 0;
    right: 0;
    top: 10%;
    bottom: auto;
    position: absolute;
}
.cubeNavigationArea .cubeLink .cubeLink_a.textLayout1 .cubeLink_text {
    left: auto;
    right: auto;
    top: auto;
    bottom: 10%;
    text-align: center;
    height: auto;
    position: absolute;
    display: block;
}
.cubeNavigationArea .cubeLink .cubeLink_a.textLayout1 .cubeLink_text .cubeLink_text_p {
    padding: 0;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    word-break: normal;
}

.cubeNavigationArea .cubeLink .cubeLink_a.textLayout2 .cubeLink_ico {
    left: 0;
    right: 0;
    top: auto;
    bottom: 10%;
    position: absolute;
}
.cubeNavigationArea .cubeLink .cubeLink_a.textLayout2 .cubeLink_text {
    left: auto;
    right: auto;
    top: 10%;
    bottom: auto;
    text-align: center;
    height: auto;
    position: absolute;
    display: block;
    margin-top: 0%;
}
.cubeNavigationArea .cubeLink .cubeLink_a.textLayout2 .cubeLink_text .cubeLink_text_p {
    padding-left: 0;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    word-break: normal;
}

.cubeNavigationArea .cubeLink .cubeLink_a.textLayout3 .cubeLink_ico {
    left: 10%;
    right: auto;
    top: 0;
    bottom: 0;
    position: absolute;
    margin: auto;
}
.cubeNavigationArea .cubeLink .cubeLink_a.textLayout3 .cubeLink_text {
    left: auto;
    top: auto;
    bottom: auto;
    text-align: right;
    height: 100%;
    position: relative;
    display: table;
    margin: auto;
}
.cubeNavigationArea .cubeLink .cubeLink_a.textLayout3 .cubeLink_text .cubeLink_text_p {
    padding-right: 10%;
    display: table-cell;
    vertical-align: middle;
    overflow: hidden;
    white-space: nowrap;
    word-break: normal;
}

.cubeNavigationArea .cubeLink .cubeLink_a.textLayout4 .cubeLink_ico {
    left: auto;
    right: 10%;
    top: 0;
    bottom: 0;
    position: absolute;
    margin: auto;
}
.cubeNavigationArea .cubeLink .cubeLink_a.textLayout4 .cubeLink_text {
    right: auto;
    top: auto;
    bottom: auto;
    text-align: left;
    height: 100%;
    position: relative;
    display: table;
    margin: auto;
}
.cubeNavigationArea .cubeLink .cubeLink_a.textLayout4 .cubeLink_text .cubeLink_text_p {
    padding-left: 10%;
    display: table-cell;
    vertical-align: middle;
    overflow: hidden;
    white-space: nowrap;
    word-break: normal;
}

.cubeNavigationArea .cubeLink .cubeLink_a.textLayout5 .cubeLink_ico {
    left: auto;
    right: 10%;
    top: auto;
    bottom: 10%;
    position: absolute;
    margin: auto;
}
.cubeNavigationArea .cubeLink .cubeLink_a.textLayout5 .cubeLink_text {
    right: auto;
    top: 10%;
    bottom: auto;
    text-align: left;
    height: auto;
    position: absolute;
    display: block;
}
.cubeNavigationArea .cubeLink .cubeLink_a.textLayout5 .cubeLink_text .cubeLink_text_p {
    padding-left: 10%;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    word-break: normal;
}

.cubeNavigationArea .cubeLink .cubeLink_a.textLayout6 .cubeLink_ico {
    left: 10%;
    right: auto;
    top: 10%;
    bottom: auto;
    position: absolute;
    margin: auto;
}
.cubeNavigationArea .cubeLink .cubeLink_a.textLayout6 .cubeLink_text {
    left: auto;
    top: auto;
    bottom: 10%;
    text-align: right;
    height: auto;
    position: absolute;
    display: block;
}
.cubeNavigationArea .cubeLink .cubeLink_a.textLayout6 .cubeLink_text .cubeLink_text_p {
    padding-right: 10%;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    word-break: normal;
}

.cubeNavigationArea .cubeLink .cubeLink_a.textLayout7 .cubeLink_ico {
    left: 10%;
    right: auto;
    top: auto;
    bottom: 10%;
    position: absolute;
    margin: auto;
}
.cubeNavigationArea .cubeLink .cubeLink_a.textLayout7 .cubeLink_text {
    left: auto;
    top: 10%;
    bottom: auto;
    text-align: right;
    height: auto;
    position: absolute;
    display: block;
}
.cubeNavigationArea .cubeLink .cubeLink_a.textLayout7 .cubeLink_text .cubeLink_text_p {
    padding-right: 10%;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    word-break: normal;
}

.cubeNavigationArea .cubeLink .cubeLink_a.textLayout8 .cubeLink_ico {
    left: auto;
    right: 10%;
    top: 10%;
    bottom: auto;
    position: absolute;
    margin: auto;
}
.cubeNavigationArea .cubeLink .cubeLink_a.textLayout8 .cubeLink_text {
    right: auto;
    top: auto;
    bottom: 10%;
    text-align: left;
    height: auto;
    position: absolute;
    display: block;
}
.cubeNavigationArea .cubeLink .cubeLink_a.textLayout8 .cubeLink_text .cubeLink_text_p {
    padding-left: 10%;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    word-break: normal;
}
/*====固定布局样式end====*/

/*懒加载情况，不需要先出现图片*/
.formMiddleContent .cubeNavigationArea .cubeLink .cubeLink_a .for-lazyload.cubeLink_bg {
    background-image: none;
}

/*========*/
/*.formStyle31.moduleStyle17{background:#FFFFFF;}*/
.cubeNavigationArea17 .cubeLink {
    width: 19.6%;
    height: auto;
    padding-bottom: 4%;
    color: #ffffff;
    display: inline-block;
    float: none;
    vertical-align: top;
    min-height: 3.5rem;
}
.cubeNavigationArea17 .cubeLink .cubeLink_a .cubeLink_ico {
    position: relative !important;
    width: 2.1rem;
    height: 2.1rem;
    font-size: 0.9rem;
    line-height: 2.1rem;
    top: auto !important;
    bottom: auto !important;
    left: -0.1rem !important;
    right: 0 !important;
    margin: auto !important;
    background-position: center center;
    background-repeat: no-repeat;
    border-radius: 50%;
}
.cubeNavigationArea17 .cubeLink .cubeLink_a .cubeLink_text {
    color: #333;
    font-size: 14px;
}
.cubeNavigationArea17 .cubeLink .cubeLink_a .cubeLink_bg {
    margin: auto;
    position: absolute;
    background-size: 40px 40px;
    width: 2.1rem;
    height: 2.1rem;
    left: 0;
    right: 0;
    background-position: center center;
    background-repeat: no-repeat;
    border-radius: 50%;
}

.cubeNavigationArea17 .cubeLink .cubeLink_a .cubeLink_text {
    left: auto !important;
    right: auto !important;
    top: auto !important;
    bottom: 0 !important;
    text-align: center !important;
    height: auto !important;
    position: relative !important;
    display: block !important;
    margin-top: 2.45rem;
}
.cubeNavigationArea17 .cubeLink .cubeLink_a .cubeLink_text .cubeLink_text_p {
    display: block !important;
    padding: 0 !important;
    font-size: 0.5rem;
    overflow: visible !important;
    white-space: normal !important;
    word-break: break-all !important;
}

.cubeNavigationArea17 .cubeLink_row:nth-child(1) .cubeLink {
    padding-top: 4.5%;
}

.cubeNavigationArea17 .cubeLink:nth-child(5n + 1) {
    padding-left: 2%;
}
.cubeNavigationArea17 .cubeLink:nth-child(5n + 2) {
    padding-left: 0;
}
.cubeNavigationArea17 .cubeLink:nth-child(5n + 3) {
    padding-left: 0;
}
.cubeNavigationArea17 .cubeLink:nth-child(5n + 4) {
    padding-left: 0;
}
.cubeNavigationArea17 .cubeLink:nth-child(5n + 5) {
    padding-left: 0;
}

.cubeNavigationArea17 .cubeLink_row:nth-child(2n + 1) .cubeLink:nth-child(1) .icon-cube:before {
    content: '\a0823';
    font-size: 0.9rem;
    margin-left: 0.75rem;
}
.cubeNavigationArea17 .cubeLink_row:nth-child(2n + 1) .cubeLink:nth-child(2) .icon-cube:before {
    content: '\a0961';
    font-size: 0.9rem;
    margin-left: 0.75rem;
}
.cubeNavigationArea17 .cubeLink_row:nth-child(2n + 1) .cubeLink:nth-child(3) .icon-cube:before {
    content: '\a0102';
    font-size: 0.9rem;
    margin-left: 0.75rem;
}
.cubeNavigationArea17 .cubeLink_row:nth-child(2n + 1) .cubeLink:nth-child(4) .icon-cube:before {
    content: '\a0814';
    font-size: 0.9rem;
    margin-left: 0.75rem;
}
.cubeNavigationArea17 .cubeLink_row:nth-child(2n + 1) .cubeLink:nth-child(5) .icon-cube:before {
    content: '\a0826';
    font-size: 0.9rem;
    margin-left: 0.75rem;
}
.cubeNavigationArea17 .cubeLink_row:nth-child(2n + 2) .cubeLink:nth-child(1) .icon-cube:before {
    content: '\a0045';
    font-size: 0.9rem;
    margin-left: 0.75rem;
}
.cubeNavigationArea17 .cubeLink_row:nth-child(2n + 2) .cubeLink:nth-child(2) .icon-cube:before {
    content: '\a0883';
    font-size: 0.9rem;
    margin-left: 0.75rem;
}
.cubeNavigationArea17 .cubeLink_row:nth-child(2n + 2) .cubeLink:nth-child(3) .icon-cube:before {
    content: '\ec53';
    font-size: 0.9rem;
    margin-left: 0.75rem;
}
.cubeNavigationArea17 .cubeLink_row:nth-child(2n + 2) .cubeLink:nth-child(4) .icon-cube:before {
    content: '\a0477';
    font-size: 0.9rem;
    margin-left: 0.75rem;
}
.cubeNavigationArea17 .cubeLink_row:nth-child(2n + 2) .cubeLink:nth-child(5) .icon-cube:before {
    content: '\a0111';
    font-size: 0.9rem;
    margin-left: 0.75rem;
}

/*.newCubeNavigationArea7 .cubeLink_row:nth-child(2n+1) .cubeLink:nth-child(1) .icon-cube:before{  content: "\a0847";font-size: 1rem; margin-left: 0.75rem;}*/
/*.newCubeNavigationArea7 .cubeLink_row:nth-child(3n+3) .cubeLink:nth-child(1) .icon-cube:before{  content: "\e65f";font-size: 1.5rem; margin-left: 0.55rem;}
.newCubeNavigationArea7 .cubeLink_row:nth-child(3n+3) .cubeLink:nth-child(2) .icon-cube:before{  content: "\e686";font-size: 1.5rem; margin-left: 0.55rem;}
.newCubeNavigationArea7 .cubeLink_row:nth-child(3n+3) .cubeLink:nth-child(3) .icon-cube:before{  content: "\e6e1";font-size: 1.5rem; margin-left: 0.55rem;}
.newCubeNavigationArea7 .cubeLink_row:nth-child(3n+3) .cubeLink:nth-child(4) .icon-cube:before{  content: "\e70d";font-size: 1.5rem; margin-left: 0.55rem;}*/

.cubeNavigationArea17 .cubeLink_row:nth-child(2n + 1) .cubeLink:nth-child(1) .cubeLink_bg {
    background: #26a9fd;
}
.cubeNavigationArea17 .cubeLink_row:nth-child(2n + 1) .cubeLink:nth-child(2) .cubeLink_bg {
    background: #32ce9a;
}
.cubeNavigationArea17 .cubeLink_row:nth-child(2n + 1) .cubeLink:nth-child(3) .cubeLink_bg {
    background: #ffa352;
}
.cubeNavigationArea17 .cubeLink_row:nth-child(2n + 1) .cubeLink:nth-child(4) .cubeLink_bg {
    background: #fd6190;
}
.cubeNavigationArea17 .cubeLink_row:nth-child(2n + 1) .cubeLink:nth-child(5) .cubeLink_bg {
    background: #dc4154;
}
.cubeNavigationArea17 .cubeLink_row:nth-child(2n + 2) .cubeLink:nth-child(1) .cubeLink_bg {
    background: #fe6469;
}
.cubeNavigationArea17 .cubeLink_row:nth-child(2n + 2) .cubeLink:nth-child(2) .cubeLink_bg {
    background: #8e8ffb;
}
.cubeNavigationArea17 .cubeLink_row:nth-child(2n + 2) .cubeLink:nth-child(3) .cubeLink_bg {
    background: #76c951;
}
.cubeNavigationArea17 .cubeLink_row:nth-child(2n + 2) .cubeLink:nth-child(4) .cubeLink_bg {
    background: #6edcfe;
}
.cubeNavigationArea17 .cubeLink_row:nth-child(2n + 2) .cubeLink:nth-child(5) .cubeLink_bg {
    background: #ffc72e;
}
/*.newCubeNavigationArea7 .cubeLink_row:nth-child(3n+3) .cubeLink:nth-child(1) .cubeLink_bg{background-color:#688CE3;}
.newCubeNavigationArea7 .cubeLink_row:nth-child(3n+3) .cubeLink:nth-child(2) .cubeLink_bg{background-color:#36A1DA;}
.newCubeNavigationArea7 .cubeLink_row:nth-child(3n+3) .cubeLink:nth-child(3) .cubeLink_bg{background-color:#83CF16;}
.newCubeNavigationArea7 .cubeLink_row:nth-child(3n+3) .cubeLink:nth-child(4) .cubeLink_bg{background-color:#8D65E0;}*/

/*魔方导航新样式开始*/

/*.newCubeNavigationArea .cubeLink_row_head .cubeLink .cubeLink_a .cubeLink_ico{background-size: 100%;}*/
/*.newCubeNavigationArea .cubeLink .cubeLink_a .cubeLink_text .cubeLink_text_p{font-size: 0.6rem !important;}
.newCubeNavigationArea17 .cubeLink .cubeLink_a .cubeLink_text .cubeLink_text_p{font-size: 0.5rem !important;}
.newCubeNavigationArea .cubeLink .cubeLink_a .cubeLink_text .cubeLink_subText_p{font-size: 0.5rem !important;}*/

.newCubeNavigationArea .icon-cube {
    font-size: 2rem;
    color: #fff;
}
/*.newCubeNavigationArea .cubeLink_text_p {font-size: 0.5rem;}*/
.newCubeNavigationArea {
    overflow: hidden;
    min-height: 0.5rem;
    padding-top: 1.5%;
}
.newCubeNavigationArea .cubeLink {
    float: left;
    width: 100%;
    padding-bottom: 1.5%;
    padding-left: 1.5%;
    min-height: 4.7rem;
}
.newCubeNavigationArea .cubeLink .cubeLink_a {
    width: 100%;
    height: 100%;
    display: inline-block;
    position: relative;
}
.newCubeNavigationArea .cubeLink .cubeLink_a .cubeLink_bg {
    width: 100%;
    height: 100%;
    position: absolute;
}
.newCubeNavigationArea .cubeLink .cubeLink_a .cubeLink_curtain {
    width: 100%;
    height: 100%;
    position: absolute;
}
.newCubeNavigationArea .cubeLink .cubeLink_a .cubeLink_ico {
    width: 2rem;
    height: 2.1rem;
    margin: auto;
    position: absolute;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
}
.newCubeNavigationArea .cubeLink .cubeLink_a .cubeLink_text {
    width: 100%;
    position: absolute;
    table-layout: fixed;
}
.newCubeNavigationArea .cubeLink .cubeLink_a .cubeLink_text .cubeLink_text_p {
    white-space: nowrap;
    overflow: hidden;
}
.newCubeNavigationArea .cubeLink_fixWidth_1 {
    width: 92%;
}
.newCubeNavigationArea .cubeLink_fixWidth_2 {
    width: 45.75% !important;
}

/*====魔方导航各种样式start====*/
.newCubeNavigationArea1 .cubeLink_fixWidth_1 {
    width: 97% !important;
}
.newCubeNavigationArea1 .cubeLink {
    width: 31.33%;
    height: auto;
}
.newCubeNavigationArea1 .cubeLink .cubeLink_a .cubeLink_bg {
    background: #404040;
}
.newCubeNavigationArea1 .cubeLink .cubeLink_a .cubeLink_ico {
    left: 0;
    right: 0;
    top: 0.85rem;
    position: relative;
    width: 1rem;
    height: 1rem;
    font-size: 1rem;
}
.newCubeNavigationArea1 .cubeLink .cubeLink_a .cubeLink_text {
    margin-top: 1.67rem;
    text-align: center;
    color: #ffffff;
    font-size: 0.65rem;
    position: relative;
}
.newCubeNavigationArea1 .cubeLink .cubeLink_a .cubeLink_text .cubeLink_text_p {
    overflow: visible;
    white-space: normal;
    word-break: break-all;
}

.newCubeNavigationArea1 .cubeLink_row:nth-child(1) .cubeLink {
    padding-top: 1%;
}

.newCubeNavigationArea1 .cubeLink:nth-child(3n + 3) {
    width: 31.34%;
}

.newCubeNavigationArea1 .cubeLink_row:nth-child(2n + 1) .cubeLink:nth-child(1) .icon-cube:before {
    content: '\a0851';
}
.newCubeNavigationArea1 .cubeLink_row:nth-child(2n + 1) .cubeLink:nth-child(2) .icon-cube:before {
    content: '\a0869';
}
.newCubeNavigationArea1 .cubeLink_row:nth-child(2n + 1) .cubeLink:nth-child(3) .icon-cube:before {
    content: '\a0103';
}
.newCubeNavigationArea1 .cubeLink_row:nth-child(2n + 2) .cubeLink:nth-child(1) .icon-cube:before {
    content: '\a1066';
}
.newCubeNavigationArea1 .cubeLink_row:nth-child(2n + 2) .cubeLink:nth-child(2) .icon-cube:before {
    content: '\a0341';
}
.newCubeNavigationArea1 .cubeLink_row:nth-child(2n + 2) .cubeLink:nth-child(3) .icon-cube:before {
    content: '\a0872';
}
/*.newCubeNavigationArea1 .cubeLink_row:nth-child(3n+3) .cubeLink:nth-child(1) .icon-cube:before{  content: "\e6df";}
.newCubeNavigationArea1 .cubeLink_row:nth-child(3n+3) .cubeLink:nth-child(2) .icon-cube:before{  content: "\e6de";}
.newCubeNavigationArea1 .cubeLink_row:nth-child(3n+3) .cubeLink:nth-child(3) .icon-cube:before{  content: "\e6b8";}
*/
.newCubeNavigationArea1 .cubeLink_fixWidth_2 {
    width: 47.75% !important;
}
/*==========*/
.newCubeNavigationArea2 .cubeLink .cubeLink_a {
    width: 95.5%;
    min-height: 4rem;
    overflow: hidden;
}

.newCubeNavigationArea2 .cubeLink {
    width: 28.9%;
    min-height: 4rem;
    padding-left: 3.38%;
    padding-bottom: 4%;
    min-height: 0;
}

/*.newCubeNavigationArea2 .cubeLink_a {border-radius:4px;}*/

.newCubeNavigationArea2 .cubeLink .cubeLink_a .cubeLink_ico {
    left: 0;
    right: 0;
    top: 1rem;
    position: relative;
    font-size: 1rem;
    width: 1rem;
    height: 1rem;
    color: #7a85f2;
}
.newCubeNavigationArea2 .cubeLink .cubeLink_a .cubeLink_text {
    text-align: center;
    color: #000;
    font-size: 16px;
    position: relative;
}
.newCubeNavigationArea2 .cubeLink .textLayout0 .cubeLink_text {
    margin-top: 1.5rem;
}
.newCubeNavigationArea2 .cubeLink .cubeLink_a .cubeLink_text .cubeLink_text_p {
    overflow: visible;
    white-space: normal;
    word-break: break-all;
}

.newCubeNavigationArea2 .cubeLink_row:nth-child(1) .cubeLink {
    padding-top: 1%;
}

.newCubeNavigationArea2 .cubeLink:nth-child(2n + 3) {
    width: 30%;
}
/*.newCubeNavigationArea2 .cubeLink:nth-child(2n+3){ width: 31.34%; }*/
.newCubeNavigationArea2 .cubeLink_row .cubeLink .cubeLink_a .cubeLink_bg {
    background-color: #fff;
    border-radius: 4px;
}

/*.newCubeNavigationArea2 .cubeLink_row:nth-child(2n+1) .cubeLink:nth-child(1) .cubeLink_a .cubeLink_bg{background-color:#D87570;}
.newCubeNavigationArea2 .cubeLink_row:nth-child(2n+1) .cubeLink:nth-child(3) .cubeLink_a .cubeLink_bg{background-color:#FEBF02;}
.newCubeNavigationArea2 .cubeLink_row:nth-child(2n+2) .cubeLink:nth-child(1) .cubeLink_a .cubeLink_bg{background-color:#BC4040;}
.newCubeNavigationArea2 .cubeLink_row:nth-child(2n+2) .cubeLink:nth-child(2) .cubeLink_a .cubeLink_bg{background-color:#6F9C97;}
.newCubeNavigationArea2 .cubeLink_row:nth-child(2n+2) .cubeLink:nth-child(3) .cubeLink_a .cubeLink_bg{background-color:#FF8B00;}*/
/*.newCubeNavigationArea2 .cubeLink_row:nth-child(3n+3) .cubeLink:nth-child(1) .cubeLink_a .cubeLink_bg{background-color:#D87570;}
.newCubeNavigationArea2 .cubeLink_row:nth-child(3n+3) .cubeLink:nth-child(2) .cubeLink_a .cubeLink_bg{background-color:#BBD1C4;}
.newCubeNavigationArea2 .cubeLink_row:nth-child(3n+3) .cubeLink:nth-child(3) .cubeLink_a .cubeLink_bg{background-color:#FEBF02;}*/

.newCubeNavigationArea2 .cubeLink_row:nth-child(2n + 1) .cubeLink:nth-child(1) .icon-cube:before {
    content: '\a0622';
}
.newCubeNavigationArea2 .cubeLink_row:nth-child(2n + 1) .cubeLink:nth-child(2) .icon-cube:before {
    content: '\a0625';
}
.newCubeNavigationArea2 .cubeLink_row:nth-child(2n + 1) .cubeLink:nth-child(3) .icon-cube:before {
    content: '\a0631';
}
.newCubeNavigationArea2 .cubeLink_row:nth-child(2n + 2) .cubeLink:nth-child(1) .icon-cube:before {
    content: '\a0682';
}
.newCubeNavigationArea2 .cubeLink_row:nth-child(2n + 2) .cubeLink:nth-child(2) .icon-cube:before {
    content: '\a0676';
}
.newCubeNavigationArea2 .cubeLink_row:nth-child(2n + 2) .cubeLink:nth-child(3) .icon-cube:before {
    content: '\a0667';
}
/*.newCubeNavigationArea2 .cubeLink_row:nth-child(3n+3) .cubeLink:nth-child(1) .icon-cube:before{  content: "\e6de";}
.newCubeNavigationArea2 .cubeLink_row:nth-child(3n+3) .cubeLink:nth-child(2) .icon-cube:before{  content: "\e682";}
.newCubeNavigationArea2 .cubeLink_row:nth-child(3n+3) .cubeLink:nth-child(3) .icon-cube:before{  content: "\e6e0";}*/
.newCubeNavigationArea2 .cubeLink_fixWidth_1 {
    width: 97.5% !important;
}
.cubeNavigationArea2 .cubeLink_fixWidth_1 {
    width: 97%;
}

/*========*/
.formStyle31.moduleStyle3 .cubeLink_row:first-child {
    margin-top: 0.8rem;
}
.newCubeNavigationArea3 .cubeLink {
    width: 45%;
    min-height: 0;
    height: 4.3rem;
    padding-bottom: 5%;
}
.newCubeNavigationArea3 .cubeLink .cubeLink_a .cubeLink_ico {
    right: 10%;
    bottom: 16%;
    font-size: 1rem;
    width: 1rem;
    height: 1rem;
}
.newCubeNavigationArea3 .cubeLink .cubeLink_a .cubeLink_text {
    top: 10%;
    text-align: left;
    color: #ffffff;
    font-size: 16px;
    text-indent: 0.8rem;
}
.newCubeNavigationArea3 .cubeLink .cubeLink_a .cubeLink_text .cubeLink_text_p {
    padding-right: 10%;
}
.newCubeNavigationArea3 .cubeLink_row:nth-child(1) .cubeLink {
    padding-top: 1%;
}

.newCubeNavigationArea3 .cubeLink_row .cubeLink:nth-child(1) {
    padding-left: 2.5%;
}
.newCubeNavigationArea3 .cubeLink_row .cubeLink:nth-child(2) {
    padding-left: 5%;
}

.newCubeNavigationArea3 .cubeLink_row:nth-child(2n + 1) .cubeLink:nth-child(1) .cubeLink_a .cubeLink_bg {
    background-color: #fdb92c;
}
.newCubeNavigationArea3 .cubeLink_row:nth-child(2n + 1) .cubeLink:nth-child(2) .cubeLink_a .cubeLink_bg {
    background-color: #34cfee;
}
.newCubeNavigationArea3 .cubeLink_row:nth-child(2n + 2) .cubeLink:nth-child(1) .cubeLink_a .cubeLink_bg {
    background-color: #fc562e;
}
.newCubeNavigationArea3 .cubeLink_row:nth-child(2n + 2) .cubeLink:nth-child(2) .cubeLink_a .cubeLink_bg {
    background-color: #424446;
}
/*.newCubeNavigationArea3 .cubeLink_row:nth-child(3n+3) .cubeLink:nth-child(1) .cubeLink_a .cubeLink_bg{background-color:#5A35B8;}
.newCubeNavigationArea3 .cubeLink_row:nth-child(3n+3) .cubeLink:nth-child(2) .cubeLink_a .cubeLink_bg{background-color:#EA0001;}*/

.newCubeNavigationArea3 .cubeLink_row:nth-child(2n + 1) .cubeLink:nth-child(1) .icon-cube:before {
    content: '\a0841';
}
.newCubeNavigationArea3 .cubeLink_row:nth-child(2n + 1) .cubeLink:nth-child(2) .icon-cube:before {
    content: '\a0591';
}
.newCubeNavigationArea3 .cubeLink_row:nth-child(2n + 2) .cubeLink:nth-child(1) .icon-cube:before {
    content: '\a0105';
}
.newCubeNavigationArea3 .cubeLink_row:nth-child(2n + 2) .cubeLink:nth-child(2) .icon-cube:before {
    content: '\a0456';
}
/*.newCubeNavigationArea3 .cubeLink_row:nth-child(3n+3) .cubeLink:nth-child(1) .icon-cube:before{  content: "\e630";}
.newCubeNavigationArea3 .cubeLink_row:nth-child(3n+3) .cubeLink:nth-child(2) .icon-cube:before{  content: "\e6dd";}*/
.newCubeNavigationArea3 .cubeLink_fixWidth_1 {
    width: 95% !important;
}

/*========*/
.newCubeNavigationArea4 .cubeLink {
    width: 43.4%;
    padding-bottom: 5.5%;
}
/*.newCubeNavigationArea4 .cubeLink .cubeLink_a .cubeLink_bg{background-color:#F37E39; border-radius:10px;}*/
.newCubeNavigationArea4 .cubeLink .cubeLink_a .cubeLink_ico {
    right: 10%;
    top: 0;
    bottom: 0;
    font-size: 1rem;
    width: 1rem;
    height: 1rem;
}
.newCubeNavigationArea4 .cubeLink .cubeLink_a .cubeLink_text {
    position: relative;
    height: 100%;
    display: table;
    text-align: left;
    color: #ffffff;
    font-size: 16px;
}
.newCubeNavigationArea4 .cubeLink .cubeLink_a .cubeLink_text .cubeLink_text_p {
    padding-left: 10%;
    display: table-cell;
    vertical-align: middle;
}

.newCubeNavigationArea4 .cubeLink_row:nth-child(1) .cubeLink {
    padding-top: 1%;
}
.newCubeNavigationArea4 .cubeLink_row .cubeLink:nth-child(2) {
    padding-left: 4.5%;
}
.newCubeNavigationArea4 .cubeLink_row .cubeLink:nth-child(1) {
    padding-left: 3.5%;
}
.newCubeNavigationArea4 .cubeLink {
    height: 2.8rem;
    min-height: 0;
}

.newCubeNavigationArea4 .cubeLink_row:nth-child(3n + 1) .cubeLink:nth-child(1) .icon-cube:before {
    content: '\a0841';
}
.newCubeNavigationArea4 .cubeLink_row:nth-child(3n + 1) .cubeLink:nth-child(2) .icon-cube:before {
    content: '\a0591';
}
.newCubeNavigationArea4 .cubeLink_row:nth-child(3n + 2) .cubeLink:nth-child(1) .icon-cube:before {
    content: '\a0105';
}
.newCubeNavigationArea4 .cubeLink_row:nth-child(3n + 2) .cubeLink:nth-child(2) .icon-cube:before {
    content: '\a0456';
}
.newCubeNavigationArea4 .cubeLink_row:nth-child(3n + 3) .cubeLink:nth-child(1) .icon-cube:before {
    content: '\a1077';
}
.newCubeNavigationArea4 .cubeLink_row:nth-child(3n + 3) .cubeLink:nth-child(2) .icon-cube:before {
    content: '\a0111';
}

.newCubeNavigationArea4 .cubeLink_row:nth-child(3n + 1) .cubeLink:nth-child(1) .cubeLink_bg {
    background: linear-gradient(to bottom right, #fda931, #fed337);
    border-radius: 5px;
}
.newCubeNavigationArea4 .cubeLink_row:nth-child(3n + 1) .cubeLink:nth-child(2) .cubeLink_bg {
    background: linear-gradient(to bottom right, #1996fc, #22cdfd);
    border-radius: 5px;
}
.newCubeNavigationArea4 .cubeLink_row:nth-child(3n + 2) .cubeLink:nth-child(1) .cubeLink_bg {
    background: linear-gradient(to bottom right, #a163f8, #c196fc);
    border-radius: 5px;
}
.newCubeNavigationArea4 .cubeLink_row:nth-child(3n + 2) .cubeLink:nth-child(2) .cubeLink_bg {
    background: linear-gradient(to bottom right, #39db53, #79e868);
    border-radius: 5px;
}
.newCubeNavigationArea4 .cubeLink_row:nth-child(3n + 3) .cubeLink:nth-child(1) .cubeLink_bg {
    background: linear-gradient(to bottom right, #fd5454, #fa6e6e);
    border-radius: 5px;
}
.newCubeNavigationArea4 .cubeLink_row:nth-child(3n + 3) .cubeLink:nth-child(2) .cubeLink_bg {
    background: linear-gradient(to bottom right, #644deb, #6684e7);
    border-radius: 5px;
}

.newCubeNavigationArea4 .cubeLink_fixWidth_1 {
    width: 92% !important;
}

/*========*/
.newCubeNavigationArea5 .cubeLink {
    height: 4.5rem;
    min-height: 0;
}
.newCubeNavigationArea5 .cubeLink .cubeLink_a .cubeLink_text {
    color: #ffffff;
    font-size: 16px;
}

.newCubeNavigationArea5 .cubeLink:nth-child(1) {
    padding-top: 2%;
}
.newCubeNavigationArea5 .cubeLink:nth-child(2) {
    padding-top: 2%;
}
.newCubeNavigationArea5 .cubeLink:nth-child(3) {
    padding-top: 2%;
}

.newCubeNavigationArea5 .cubeLink:nth-child(8n + 1) {
    width: 38%;
}
.newCubeNavigationArea5 .cubeLink:nth-child(8n + 2) {
    width: 28%;
}
.newCubeNavigationArea5 .cubeLink:nth-child(8n + 3) {
    width: 28%;
}
.newCubeNavigationArea5 .cubeLink:nth-child(8n + 4) {
    width: 47.75%;
    height: 5.7rem;
}
.newCubeNavigationArea5 .cubeLink:nth-child(8n + 5) {
    width: 47.75%;
    height: 5.7rem;
}
.newCubeNavigationArea5 .cubeLink:nth-child(8n + 6) {
    width: 38%;
}
.newCubeNavigationArea5 .cubeLink:nth-child(8n + 7) {
    width: 28%;
}
.newCubeNavigationArea5 .cubeLink:nth-child(8n + 8) {
    width: 28%;
}

.newCubeNavigationArea5 .cubeLink:nth-child(5n + 1) .cubeLink_a .cubeLink_bg {
    background-color: #4791ff;
}
.newCubeNavigationArea5 .cubeLink:nth-child(5n + 2) .cubeLink_a .cubeLink_bg {
    background-color: #ffbf37;
}
.newCubeNavigationArea5 .cubeLink:nth-child(5n + 3) .cubeLink_a .cubeLink_bg {
    background-color: #9284fa;
}
.newCubeNavigationArea5 .cubeLink:nth-child(5n + 4) .cubeLink_a .cubeLink_bg {
    background-color: #26c865;
}
.newCubeNavigationArea5 .cubeLink:nth-child(5n + 5) .cubeLink_a .cubeLink_bg {
    background-color: #86d030;
}
/*.newCubeNavigationArea5 .cubeLink:nth-child(8n+6) .cubeLink_a .cubeLink_bg{background-color:#36AAE7;}
.newCubeNavigationArea5 .cubeLink:nth-child(8n+7) .cubeLink_a .cubeLink_bg{background-color:#688CE2;}
.newCubeNavigationArea5 .cubeLink:nth-child(8n+8) .cubeLink_a .cubeLink_bg{background-color:#8D67E0;}*/

.newCubeNavigationArea5 .cubeLink .cubeLink_a .cubeLink_ico {
    font-size: 1.2rem;
    width: 1.2rem;
    height: 1.2rem;
}
.newCubeNavigationArea5 .cubeLink:nth-child(5n + 1) .cubeLink_ico {
    left: 12%;
    top: 10%;
}
.newCubeNavigationArea5 .cubeLink:nth-child(5n + 2) .cubeLink_ico {
    bottom: 20%;
    right: 0;
    left: 0;
}
.newCubeNavigationArea5 .cubeLink:nth-child(5n + 3) .cubeLink_ico {
    top: 16%;
    right: 0;
    left: 0;
}
.newCubeNavigationArea5 .cubeLink:nth-child(5n + 4) .cubeLink_ico {
    top: 12%;
    left: 12%;
}
.newCubeNavigationArea5 .cubeLink:nth-child(5n + 5) .cubeLink_ico {
    top: 10%;
    left: 10%;
}
/*.newCubeNavigationArea5 .cubeLink:nth-child(8n+6) .cubeLink_ico{bottom:10%;right:10%;}
.newCubeNavigationArea5 .cubeLink:nth-child(8n+7) .cubeLink_ico{top:10%;right:0;left:0;}
.newCubeNavigationArea5 .cubeLink:nth-child(8n+8) .cubeLink_ico{bottom:10%;right:0;left:0;}*/

.newCubeNavigationArea5 .cubeLink:nth-child(5n + 1) .icon-cube:before {
    content: '\a0339';
}
.newCubeNavigationArea5 .cubeLink:nth-child(5n + 2) .icon-cube:before {
    content: '\a0473';
}
.newCubeNavigationArea5 .cubeLink:nth-child(5n + 3) .icon-cube:before {
    content: '\a0038';
}
.newCubeNavigationArea5 .cubeLink:nth-child(5n + 4) .icon-cube:before {
    content: '\a0873';
}
.newCubeNavigationArea5 .cubeLink:nth-child(5n + 5) .icon-cube:before {
    content: '\a1042';
}
/*.newCubeNavigationArea5 .cubeLink:nth-child(8n+6) .icon-cube:before{  content: "\e6b2";}
.newCubeNavigationArea5 .cubeLink:nth-child(8n+7) .icon-cube:before{  content: "\e65f";}
.newCubeNavigationArea5 .cubeLink:nth-child(8n+8) .icon-cube:before{  content: "\e6de";}*/

.newCubeNavigationArea5 .cubeLink:nth-child(5n + 1) .cubeLink_text {
    bottom: 10%;
    text-align: right;
}
.newCubeNavigationArea5 .cubeLink:nth-child(5n + 1) .cubeLink_text .cubeLink_text_p {
    padding-right: 10%;
}
.newCubeNavigationArea5 .cubeLink:nth-child(5n + 2) .cubeLink_text {
    top: 16%;
    text-align: center;
}
.newCubeNavigationArea5 .cubeLink:nth-child(5n + 3) .cubeLink_text {
    bottom: 22%;
    text-align: center;
}
.newCubeNavigationArea5 .cubeLink:nth-child(5n + 4) .cubeLink_text {
    bottom: 14%;
    text-align: right;
}
.newCubeNavigationArea5 .cubeLink:nth-child(5n + 4) .cubeLink_text .cubeLink_text_p {
    padding-right: 10%;
}
.newCubeNavigationArea5 .cubeLink:nth-child(5n + 5) .cubeLink_text {
    bottom: 10%;
    text-align: right;
}
.newCubeNavigationArea5 .cubeLink:nth-child(5n + 5) .cubeLink_text .cubeLink_text_p {
    padding-right: 10%;
}
/*.newCubeNavigationArea5 .cubeLink:nth-child(8n+6) .cubeLink_text{top:10%;text-align:left;}
.newCubeNavigationArea5 .cubeLink:nth-child(8n+6) .cubeLink_text .cubeLink_text_p{padding-left:10%;}
.newCubeNavigationArea5 .cubeLink:nth-child(8n+7) .cubeLink_text{bottom:10%;text-align:center;}
.newCubeNavigationArea5 .cubeLink:nth-child(8n+8) .cubeLink_text{top:10%;text-align:center;}*/

/*========*/
/*.formStyle31.moduleStyle6{margin:0;}*/
.newCubeNavigationArea6 {
    width: 16rem;
    padding-left: 1.5%;
}
.newCubeNavigationArea6 .cubeLink {
    box-sizing: border-box;
    height: 3.75rem;
    color: #ffffff;
    min-height: 3.75rem;
}
.newCubeNavigationArea6 .cubeLink .cubeLink_a .cubeLink_text {
    color: #ffffff;
    font-size: 16px;
}
.newCubeNavigationArea6 .cubeLink:nth-child(5n + 3) .cubeLink_a .cubeLink_text {
    bottom: 20%;
}
.newCubeNavigationArea6 .cubeLink:nth-child(5n + 4) .cubeLink_a .cubeLink_text {
    bottom: 16%;
}
.newCubeNavigationArea6 .cubeLink:nth-child(5n + 5) .cubeLink_a .cubeLink_text {
    bottom: 16%;
}

/*.newCubeNavigationArea6 .cubeLink:nth-child(1){padding-top:1%;}*/
/*.newCubeNavigationArea6 .cubeLink:nth-child(2){padding-top:1%;}*/

.newCubeNavigationArea6 .cubeLink:nth-child(5n + 1) {
    width: 8.75rem;
    height: 9.3rem;
    padding-left: 0;
    padding-bottom: 0;
}
.newCubeNavigationArea6 .cubeLink:nth-child(5n + 2) {
    width: 6.5rem;
    height: 4.5rem;
    padding-left: 0;
    margin-left: 0.25rem;
    padding-bottom: 0;
    margin-bottom: 0.25rem;
}
.newCubeNavigationArea6 .cubeLink:nth-child(5n + 3) {
    width: 6.5rem;
    height: 4.5rem;
    padding-left: 0;
    margin-left: 0.25rem;
    padding-bottom: 0;
    margin-bottom: 0.25rem;
}
/*@media screen and (max-width: 480px){
	.newCubeNavigationArea6 .cubeLink:nth-child(7n+2){height:72.5px;}
	.newCubeNavigationArea6 .cubeLink:nth-child(7n+3){height:72.5px;}
}*/
.newCubeNavigationArea6 .cubeLink:nth-child(5n + 4) {
    width: 8.75rem;
    clear: left;
    padding-left: 0;
    padding-bottom: 0.25rem;
}
.newCubeNavigationArea6 .cubeLink:nth-child(5n + 5) {
    width: 6.5rem;
    margin-left: 0.25rem;
    padding-left: 0;
    padding-bottom: 0.25rem;
}
/*.newCubeNavigationArea6 .cubeLink:nth-child(7n+6){width:60%;}*/
/*.newCubeNavigationArea6 .cubeLink:nth-child(7n+7){width:40%;padding-right:1.5%;}*/

.newCubeNavigationArea6 .cubeLink:nth-child(5n + 1) .cubeLink_a .cubeLink_bg {
    background-color: #ff9f51;
    background-image: url(/image/styleDesign/newcube/newcover/3.png);
    background-repeat: no-repeat;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: bottom;
}
.newCubeNavigationArea6 .cubeLink:nth-child(5n + 2) .cubeLink_a .cubeLink_bg {
    background-color: #ffc140;
}
.newCubeNavigationArea6 .cubeLink:nth-child(5n + 3) .cubeLink_a .cubeLink_bg {
    background-color: #7aa3ff;
}
.newCubeNavigationArea6 .cubeLink:nth-child(5n + 4) .cubeLink_a .cubeLink_bg {
    background-color: #ff6a6a;
}
.newCubeNavigationArea6 .cubeLink:nth-child(5n + 5) .cubeLink_a .cubeLink_bg {
    background-color: #4d4d4d;
}
/*.newCubeNavigationArea6 .cubeLink:nth-child(7n+6) .cubeLink_a .cubeLink_bg{background-color:#ED9D24;}
.newCubeNavigationArea6 .cubeLink:nth-child(7n+7) .cubeLink_a .cubeLink_bg{background-color:#59AB3F;}*/

/*.newCubeNavigationArea6 .cubeLink:nth-child(7n+1) .icon-cube:before{  content: "\e606";}*/
/*shaw 2018.10.31*/
.newCubeNavigationArea6 .cubeLink:nth-child(5n + 1) .icon-cube:before {
    font-size: 2.25rem;
}
.newCubeNavigationArea6 .cubeLink:nth-child(5n + 2) .icon-cube:before {
    content: '\a0607';
}
.newCubeNavigationArea6 .cubeLink:nth-child(5n + 3) .icon-cube:before {
    content: '\a0700';
}
.newCubeNavigationArea6 .cubeLink:nth-child(5n + 4) .icon-cube:before {
    content: '\a0703';
}
.newCubeNavigationArea6 .cubeLink:nth-child(5n + 5) .icon-cube:before {
    content: '\a0201';
}
/*.newCubeNavigationArea6 .cubeLink:nth-child(7n+6) .icon-cube:before{  content: "\e64f";}
.newCubeNavigationArea6 .cubeLink:nth-child(7n+7) .icon-cube:before{  content: "\e6f3";}*/

.newCubeNavigationArea6 .cubeLink:nth-child(5n + 1) .cubeLink_text {
    top: 10%;
    text-align: center;
}
.newCubeNavigationArea6 .cubeLink:nth-child(5n + 2) .cubeLink_text {
    text-align: left;
    top: 0.6rem;
}
.newCubeNavigationArea6 .cubeLink:nth-child(5n + 2) .textLayout0 .cubeLink_text {
    padding-left: 0.5rem;
}
.newCubeNavigationArea6 .cubeLink:nth-child(5n + 3) .cubeLink_text {
    text-align: center;
}
.newCubeNavigationArea6 .cubeLink:nth-child(5n + 4) .cubeLink_text {
    text-align: center;
}
.newCubeNavigationArea6 .cubeLink:nth-child(5n + 5) .cubeLink_text {
    text-align: center;
}
/*.newCubeNavigationArea6 .cubeLink:nth-child(7n+6) .cubeLink_text{text-align:center;}
.newCubeNavigationArea6 .cubeLink:nth-child(7n+7) .cubeLink_text{text-align:center;}*/
.newCubeNavigationArea6 .cubeLink .cubeLink_a .cubeLink_ico {
    font-size: 1.2rem;
    width: 1.2rem;
    height: 1.2rem;
}
/*.newCubeNavigationArea6 .cubeLink:nth-child(5n+1) .cubeLink_a .cubeLink_ico{display:none;}*/
/*shaw 2018.10.31*/
.newCubeNavigationArea6 .cubeLink:nth-child(5n + 1) .cubeLink_a .cubeLink_ico {
    bottom: 2rem;
    width: 2.25rem;
    height: 2.25rem; /*left: 50%; transform: translateX(-50%);*/
}
.newCubeNavigationArea6 .cubeLink:nth-child(5n + 1) .cubeLink_a.textLayout0 .cubeLink_ico {
    left: 0;
    right: 0;
}
.newCubeNavigationArea6 .cubeLink:nth-child(5n + 2) .cubeLink_a .cubeLink_ico {
    right: 0.7rem;
    bottom: 0.6rem;
    font-size: 1.2rem;
    width: 1.2rem;
    height: 1.2rem;
}
.newCubeNavigationArea6 .cubeLink:nth-child(5n + 3) .cubeLink_a .cubeLink_ico {
    left: 0;
    right: 0;
    top: 20%;
    font-size: 1.2rem;
    width: 1.2rem;
    height: 1.2rem;
}
.newCubeNavigationArea6 .cubeLink:nth-child(5n + 4) .cubeLink_a .cubeLink_ico {
    left: 0;
    right: 0;
    top: 13%;
    font-size: 1.2rem;
    width: 1.2rem;
    height: 1.2rem;
}
.newCubeNavigationArea6 .cubeLink:nth-child(5n + 5) .cubeLink_a .cubeLink_ico {
    left: 0;
    right: 0;
    top: 13%;
    font-size: 1.2rem;
    width: 1.2rem;
    height: 1.2rem;
}

/*========*/
/*.formStyle31.moduleStyle7{background:#FFFFFF;}*/
.newCubeNavigationArea7 .cubeLink {
    width: 24%;
    height: auto;
    padding-bottom: 4%;
    color: #ffffff;
    display: inline-block;
    float: none;
    vertical-align: top;
    min-height: 3.5rem;
}
/*修复样式7多行文字图标不居中问题*/
.newCubeNavigationArea7 .cubeLink .cubeLink_a .cubeLink_ico {
    position: relative !important;
    width: 1rem;
    height: 1rem;
    font-size: 1rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-position: center center;
    background-repeat: no-repeat;
}
.newCubeNavigationArea7 .cubeLink .cubeLink_a .cubeLink_text {
    color: #333;
    font-size: 14px;
}
.newCubeNavigationArea7 .cubeLink .cubeLink_a .cubeLink_bg {
    margin: auto;
    position: absolute;
    background-size: 40px 40px;
    width: 2.602rem;
    height: 2.6rem;
    left: 0;
    right: 0;
    background-position: center center;
    background-repeat: no-repeat;
    border-radius: 50%;
}

/*.newCubeNavigationArea7 .cubeLink .cubeLink_a .cubeLink_text{left:auto !important;right:auto !important;top:1.7rem;bottom:0 !important;text-align:center !important;height: auto !important;display:block !important;margin-top: 1.3rem;}*/
.newCubeNavigationArea7 .cubeLink .cubeLink_a .cubeLink_text {
    left: auto !important;
    right: auto !important;
    top: 1.7rem;
    bottom: 0 !important;
    text-align: center !important;
    height: auto !important;
    position: relative !important;
    display: block !important;
    margin-top: 0.3rem;
}
.newCubeNavigationArea7 .cubeLink .cubeLink_a .cubeLink_text .cubeLink_text_p {
    display: block !important;
    padding: 0 !important;
    font-size: 0.6rem;
    overflow: visible !important;
    white-space: normal !important;
    word-break: break-all !important;
}

.newCubeNavigationArea7 .cubeLink_row:nth-child(1) .cubeLink {
    padding-top: 4.5%;
}

.newCubeNavigationArea7 .cubeLink:nth-child(4n + 1) {
    padding-left: 2%;
}
.newCubeNavigationArea7 .cubeLink:nth-child(4n + 2) {
    padding-left: 0;
}
.newCubeNavigationArea7 .cubeLink:nth-child(4n + 3) {
    padding-left: 0;
}
.newCubeNavigationArea7 .cubeLink:nth-child(4n + 4) {
    padding-left: 0;
}
.newCubeNavigationArea7 .cubeLink_row .cubeLink .icon-cube:before {
    position: absolute;
}
.newCubeNavigationArea7 .cubeLink_row:nth-child(2n + 1) .cubeLink:nth-child(1) .icon-cube:before {
    content: '\a0269';
}
.newCubeNavigationArea7 .cubeLink_row:nth-child(2n + 1) .cubeLink:nth-child(2) .icon-cube:before {
    content: '\a0105';
}
.newCubeNavigationArea7 .cubeLink_row:nth-child(2n + 1) .cubeLink:nth-child(3) .icon-cube:before {
    content: '\e694';
}
.newCubeNavigationArea7 .cubeLink_row:nth-child(2n + 1) .cubeLink:nth-child(4) .icon-cube:before {
    content: '\a0133';
}
.newCubeNavigationArea7 .cubeLink_row:nth-child(2n + 2) .cubeLink:nth-child(1) .icon-cube:before {
    content: '\a1043';
}
.newCubeNavigationArea7 .cubeLink_row:nth-child(2n + 2) .cubeLink:nth-child(2) .icon-cube:before {
    content: '\a0343';
}
.newCubeNavigationArea7 .cubeLink_row:nth-child(2n + 2) .cubeLink:nth-child(3) .icon-cube:before {
    content: '\a0874';
}
.newCubeNavigationArea7 .cubeLink_row:nth-child(2n + 2) .cubeLink:nth-child(4) .icon-cube:before {
    content: '\a0847';
}

/*.newCubeNavigationArea7 .cubeLink_row:nth-child(2n+1) .cubeLink:nth-child(1) .icon-cube:before{  content: "\a0847";font-size: 1rem; margin-left: 0.75rem;}*/
/*.newCubeNavigationArea7 .cubeLink_row:nth-child(3n+3) .cubeLink:nth-child(1) .icon-cube:before{  content: "\e65f";font-size: 1.5rem; margin-left: 0.55rem;}
.newCubeNavigationArea7 .cubeLink_row:nth-child(3n+3) .cubeLink:nth-child(2) .icon-cube:before{  content: "\e686";font-size: 1.5rem; margin-left: 0.55rem;}
.newCubeNavigationArea7 .cubeLink_row:nth-child(3n+3) .cubeLink:nth-child(3) .icon-cube:before{  content: "\e6e1";font-size: 1.5rem; margin-left: 0.55rem;}
.newCubeNavigationArea7 .cubeLink_row:nth-child(3n+3) .cubeLink:nth-child(4) .icon-cube:before{  content: "\e70d";font-size: 1.5rem; margin-left: 0.55rem;}*/

.newCubeNavigationArea7 .cubeLink_row:nth-child(2n + 1) .cubeLink:nth-child(1) .cubeLink_bg {
    background: linear-gradient(to bottom right, #ff2517, #ff5451);
}
.newCubeNavigationArea7 .cubeLink_row:nth-child(2n + 1) .cubeLink:nth-child(2) .cubeLink_bg {
    background: linear-gradient(to bottom right, #7063ff, #8c7ff1);
}
.newCubeNavigationArea7 .cubeLink_row:nth-child(2n + 1) .cubeLink:nth-child(3) .cubeLink_bg {
    background: linear-gradient(to bottom right, #ffbc24, #ffe006);
}
.newCubeNavigationArea7 .cubeLink_row:nth-child(2n + 1) .cubeLink:nth-child(4) .cubeLink_bg {
    background: linear-gradient(to bottom right, #1473fb, #1dadfc);
}
.newCubeNavigationArea7 .cubeLink_row:nth-child(2n + 2) .cubeLink:nth-child(1) .cubeLink_bg {
    background: linear-gradient(to bottom right, #33c4f6, #0ae9f1);
}
.newCubeNavigationArea7 .cubeLink_row:nth-child(2n + 2) .cubeLink:nth-child(2) .cubeLink_bg {
    background: linear-gradient(to bottom right, #ff6e00, #ff8a00);
}
.newCubeNavigationArea7 .cubeLink_row:nth-child(2n + 2) .cubeLink:nth-child(3) .cubeLink_bg {
    background: linear-gradient(to bottom right, #f45c6e, #fba867);
}
.newCubeNavigationArea7 .cubeLink_row:nth-child(2n + 2) .cubeLink:nth-child(4) .cubeLink_bg {
    background: linear-gradient(to bottom right, #3edb56, #74ecaf);
}
/*.newCubeNavigationArea7 .cubeLink_row:nth-child(3n+3) .cubeLink:nth-child(1) .cubeLink_bg{background-color:#688CE3;}
.newCubeNavigationArea7 .cubeLink_row:nth-child(3n+3) .cubeLink:nth-child(2) .cubeLink_bg{background-color:#36A1DA;}
.newCubeNavigationArea7 .cubeLink_row:nth-child(3n+3) .cubeLink:nth-child(3) .cubeLink_bg{background-color:#83CF16;}
.newCubeNavigationArea7 .cubeLink_row:nth-child(3n+3) .cubeLink:nth-child(4) .cubeLink_bg{background-color:#8D65E0;}*/

.newCubeNavigationArea7 .cubeLink .cubeLink_a {
    position: relative;
}
.newCubeNavigationArea7 .cubeLink .cubeLink_a .cubeLink_bg {
    position: relative;
}
.newCubeNavigationArea7 .cubeLink .cubeLink_a .cubeLink_ico {
    position: absolute !important;
}
.newCubeNavigationArea7 .cubeLink .cubeLink_a .cubeLink_text {
    position: relative;
    margin-top: 10%;
    top: 0;
}
/*========*/
.newCubeNavigationArea8 .cubeLink {
    box-sizing: border-box;
    height: 3.75rem;
    color: #ffffff;
    width: 25%;
    min-height: 3.75rem;
}
/*.newCubeNavigationArea8 .cubeLink .cubeLink_a .cubeLink_bg{background-color:#0180E1;}*/
.newCubeNavigationArea8 .cubeLink .cubeLink_a .cubeLink_ico {
    left: 0;
    right: 0;
    top: 16%;
    font-size: 1rem;
    width: 1rem;
    height: 1rem;
}
.newCubeNavigationArea8 .cubeLink .cubeLink_a .cubeLink_text {
    color: #ffffff;
    font-size: 14px;
    text-align: center;
    bottom: 16%;
}

.newCubeNavigationArea8 .cubeLink:nth-child(7n + 1) {
    width: 50%;
    height: 7.5rem;
    clear: left;
}
.newCubeNavigationArea8 .cubeLink:nth-child(7n + 2) {
    width: 25%;
}
.newCubeNavigationArea8 .cubeLink:nth-child(7n + 3) {
    width: 25%;
    padding-right: 1.5%;
}
.newCubeNavigationArea8 .cubeLink:nth-child(7n + 4) {
    width: 25%;
}
.newCubeNavigationArea8 .cubeLink:nth-child(7n + 5) {
    width: 25%;
    padding-right: 1.5%;
}
.newCubeNavigationArea8 .cubeLink:nth-child(7n + 6) {
    width: 50%;
    clear: left;
}
.newCubeNavigationArea8 .cubeLink:nth-child(7n + 7) {
    width: 50%;
    padding-right: 1.5%;
}
/*.newCubeNavigationArea8 .cubeLink:nth-child(12n+8){width:50%;height:7.5rem;}
.newCubeNavigationArea8 .cubeLink:nth-child(12n+9){width:25%;}
.newCubeNavigationArea8 .cubeLink:nth-child(12n+10){width:25%;padding-right:1.5%;}
.newCubeNavigationArea8 .cubeLink:nth-child(12n+11){width:25%;}
.newCubeNavigationArea8 .cubeLink:nth-child(12n+12){width:25%;padding-right:1.5%;}*/

.newCubeNavigationArea8 .cubeLink:nth-child(7n + 1) .cubeLink_a .cubeLink_ico {
    top: 23%;
}
.newCubeNavigationArea8 .cubeLink:nth-child(7n + 1) .cubeLink_a .cubeLink_text {
    bottom: 36%;
}
.newCubeNavigationArea8 .cubeLink:nth-child(7n + 1) .cubeLink_a .cubeLink_bg {
    background: #ff4444;
}
.newCubeNavigationArea8 .cubeLink:nth-child(7n + 2) .cubeLink_a .cubeLink_bg {
    background: #ff4444;
}
.newCubeNavigationArea8 .cubeLink:nth-child(7n + 5) .cubeLink_a .cubeLink_bg {
    background: #ff4444;
}
.newCubeNavigationArea8 .cubeLink:nth-child(7n + 7) .cubeLink_a .cubeLink_bg {
    background: #ff4444;
}
.newCubeNavigationArea8 .cubeLink:nth-child(7n + 3) .cubeLink_a .cubeLink_bg {
    background: #3e3e3e;
}
.newCubeNavigationArea8 .cubeLink:nth-child(7n + 4) .cubeLink_a .cubeLink_bg {
    background: #3e3e3e;
}
.newCubeNavigationArea8 .cubeLink:nth-child(7n + 6) .cubeLink_a .cubeLink_bg {
    background: #3e3e3e;
}

/*.newCubeNavigationArea8 .cubeLink:nth-child(12n+8) .cubeLink_a .cubeLink_ico{top:23%;}*/
/*.newCubeNavigationArea8 .cubeLink:nth-child(12n+8) .cubeLink_a .cubeLink_text{bottom:36%;}*/
/*.newCubeNavigationArea8 .cubeLink:nth-child(12n+6) .cubeLink_a .cubeLink_bg{background-image:url(/image/styleDesign/cube/cover/1.jpg?v=201711250607);background-size: cover;background-position: center;background-repeat: no-repeat;}*/
/*.newCubeNavigationArea8 .cubeLink:nth-child(12n+7) .cubeLink_a .cubeLink_bg{background-image:url(/image/styleDesign/cube/cover/2.jpg?v=201711250607);background-size: cover;background-position: center;background-repeat: no-repeat;}*/

.newCubeNavigationArea8 .cubeLink:nth-child(7n + 1) .icon-cube:before {
    content: '\a0853';
}
.newCubeNavigationArea8 .cubeLink:nth-child(7n + 2) .icon-cube:before {
    content: '\a0441';
}
.newCubeNavigationArea8 .cubeLink:nth-child(7n + 3) .icon-cube:before {
    content: '\a0573';
}
.newCubeNavigationArea8 .cubeLink:nth-child(7n + 4) .icon-cube:before {
    content: '\a0111';
}
.newCubeNavigationArea8 .cubeLink:nth-child(7n + 5) .icon-cube:before {
    content: '\a0343';
}
.newCubeNavigationArea8 .cubeLink:nth-child(7n + 6) .icon-cube:before {
    content: '\a0913';
}
.newCubeNavigationArea8 .cubeLink:nth-child(7n + 7) .icon-cube:before {
    content: '\a0102';
}
/*.newCubeNavigationArea8 .cubeLink:nth-child(12n+8) .icon-cube:before{  content: "\e642";}
.newCubeNavigationArea8 .cubeLink:nth-child(12n+9) .icon-cube:before{  content: "\e648";}
.newCubeNavigationArea8 .cubeLink:nth-child(12n+10) .icon-cube:before{  content: "\e636";}
.newCubeNavigationArea8 .cubeLink:nth-child(12n+11) .icon-cube:before{  content: "\e63a";}
.newCubeNavigationArea8 .cubeLink:nth-child(12n+12) .icon-cube:before{  content: "\e6c6";}*/

/*========*/
.newCubeNavigationArea9 {
    width: 101%;
}
.newCubeNavigationArea9 .cubeLink {
    width: 33.33%;
    padding-left: 0;
    padding-bottom: 0;
    height: auto;
}
.newCubeNavigationArea9 .cubeLink .cubeLink_a {
    min-height: 4.7rem;
}
.newCubeNavigationArea9 .cubeLink .cubeLink_a .cubeLink_ico {
    left: 0;
    right: 0;
    margin-top: 20%;
    position: relative;
    width: 1.2rem;
    height: 1.2rem;
    font-size: 1rem;
}
.newCubeNavigationArea9 .cubeLink .cubeLink_a .cubeLink_text {
    text-align: center;
    color: #ffffff;
    font-size: 14px;
    position: relative;
    margin-top: 0.6rem;
}
.newCubeNavigationArea9 .cubeLink .cubeLink_a .cubeLink_text .cubeLink_text_p {
    overflow: visible;
    white-space: normal;
    word-break: break-all;
}

/*.newCubeNavigationArea9 .cubeLink:nth-child(3n+2){width: 33.33%;}*/
.newCubeNavigationArea9 .cubeLink_row:nth-child(2n + 1) .cubeLink:nth-child(1) .cubeLink_bg {
    background: linear-gradient(to bottom right, #5c5c5d, #111212);
}
.newCubeNavigationArea9 .cubeLink_row:nth-child(2n + 1) .cubeLink:nth-child(2) .cubeLink_bg {
    background: #fdaa2a;
}
.newCubeNavigationArea9 .cubeLink_row:nth-child(2n + 1) .cubeLink:nth-child(3) .cubeLink_bg {
    background: linear-gradient(to bottom right, #5c5c5d, #111212);
}
.newCubeNavigationArea9 .cubeLink_row:nth-child(2n + 2) .cubeLink:nth-child(1) .cubeLink_bg {
    background: #fdaa2a;
}
.newCubeNavigationArea9 .cubeLink_row:nth-child(2n + 2) .cubeLink:nth-child(2) .cubeLink_bg {
    background: linear-gradient(to bottom right, #5c5c5d, #111212);
}
.newCubeNavigationArea9 .cubeLink_row:nth-child(2n + 2) .cubeLink:nth-child(3) .cubeLink_bg {
    background: #fdaa2a;
}

.newCubeNavigationArea9 .cubeLink_row:nth-child(2n + 1) .cubeLink:nth-child(1) .icon-cube:before {
    content: '\a0993';
}
.newCubeNavigationArea9 .cubeLink_row:nth-child(2n + 1) .cubeLink:nth-child(2) .icon-cube:before {
    content: '\a0844';
}
.newCubeNavigationArea9 .cubeLink_row:nth-child(2n + 1) .cubeLink:nth-child(3) .icon-cube:before {
    content: '\a0852';
}
.newCubeNavigationArea9 .cubeLink_row:nth-child(2n + 2) .cubeLink:nth-child(1) .icon-cube:before {
    content: '\a1067';
}
.newCubeNavigationArea9 .cubeLink_row:nth-child(2n + 2) .cubeLink:nth-child(2) .icon-cube:before {
    content: '\a0342';
}
.newCubeNavigationArea9 .cubeLink_row:nth-child(2n + 2) .cubeLink:nth-child(3) .icon-cube:before {
    content: '\a0569';
}
/*.newCubeNavigationArea9 .cubeLink_row:nth-child(3n+3) .cubeLink:nth-child(1)  .icon-cube:before{  content: "\e62c";}
.newCubeNavigationArea9 .cubeLink_row:nth-child(3n+3) .cubeLink:nth-child(2)  .icon-cube:before{  content: "\e67c";}
.newCubeNavigationArea9 .cubeLink_row:nth-child(3n+3) .cubeLink:nth-child(3)  .icon-cube:before{  content: "\e6b8";}*/
.newCubeNavigationArea9 .cubeLink_fixWidth_1 {
    width: 100% !important;
}
.newCubeNavigationArea9 .cubeLink_fixWidth_2 {
    width: 50% !important;
}

.newCubeNavigationArea9 .cubeLink_fixWidth_1 .icon-cube {
    margin-top: 8% !important;
}
.newCubeNavigationArea9 .cubeLink_fixWidth_1 .cubeLink_text {
    margin-top: 0.7rem !important;
}

/*========*/
.newCubeNavigationArea10 {
    padding-top: 4.5%;
}
.newCubeNavigationArea10 .icon-cube {
    color: #5c6066;
}
.template349.formStyle31.moduleStyle10:nth-child(1) {
    margin-top: 215px;
    margin-bottom: 50px;
}
.newCubeNavigationArea10 .cubeLink {
    width: 45%;
    height: auto;
    padding-bottom: 4%;
    padding-left: 2.5%;
    color: #ffffff;
    min-height: 4.5rem;
}
.newCubeNavigationArea10 .cubeLink:nth-child(2) {
    padding-left: 5%;
}
.newCubeNavigationArea10 .cubeLink .cubeLink_a .cubeLink_bg {
    background-color: #ffffff;
    opacity: 0.8;
    border-radius: 5px 5px;
}
.newCubeNavigationArea10 .cubeLink .cubeLink_a .cubeLink_ico {
    left: 0;
    right: 0;
    top: 0.9rem;
    position: relative;
    font-size: 1.2rem;
    width: 1.2rem;
    height: 1.2rem;
}
.newCubeNavigationArea10 .cubeLink .cubeLink_a .cubeLink_text {
    color: #333;
    font-size: 16px;
    text-align: center;
    margin-top: 20%;
    position: relative;
}
.newCubeNavigationArea10 .cubeLink .cubeLink_a .cubeLink_text .cubeLink_text_p {
    overflow: visible;
    white-space: normal;
    word-break: break-all;
}

.newCubeNavigationArea10 .cubeLink_row:nth-child(3n + 1) .cubeLink:nth-child(1) .icon-cube:before {
    content: '\a0706';
    color: #333;
}
.newCubeNavigationArea10 .cubeLink_row:nth-child(3n + 1) .cubeLink:nth-child(2) .icon-cube:before {
    content: '\a0703';
    color: #333;
}
.newCubeNavigationArea10 .cubeLink_row:nth-child(3n + 2) .cubeLink:nth-child(1) .icon-cube:before {
    content: '\a0700';
    color: #333;
}
.newCubeNavigationArea10 .cubeLink_row:nth-child(3n + 2) .cubeLink:nth-child(2) .icon-cube:before {
    content: '\a0628';
    color: #333;
}
.newCubeNavigationArea10 .cubeLink_row:nth-child(3n + 3) .cubeLink:nth-child(1) .icon-cube:before {
    content: '\a0710';
    color: #333;
}
.newCubeNavigationArea10 .cubeLink_row:nth-child(3n + 3) .cubeLink:nth-child(2) .icon-cube:before {
    content: '\a0697';
    color: #333;
}

.newCubeNavigationArea10 .cubeLink_fixWidth_1 {
    width: 95% !important;
}
.newCubeNavigationArea10 .cubeLink_fixWidth_1 .cubeLink_a .cubeLink_text {
    margin-top: 11% !important;
}
/*========*/
.formStyle31.moduleStyle11 .cubeLink_row:first-child {
    margin-top: 0.8rem;
}
.newCubeNavigationArea11 .cubeLink {
    width: 5rem;
    height: 4.5rem;
    padding-bottom: 3%;
    color: #ffffff;
    font-size: 16px;
    min-height: 0;
}
.newCubeNavigationArea11 .cubeLink .cubeLink_a .cubeLink_bg {
    background-color: #f7f7f7;
    opacity: 0.8;
    border-radius: 1px 1px;
}
.newCubeNavigationArea11 .cubeLink .cubeLink_a .cubeLink_ico {
    left: 0;
    right: 0;
    top: 24%;
    font-size: 1rem;
    width: 1.2rem;
    height: 1.2rem;
}
.newCubeNavigationArea11 .cubeLink .cubeLink_a .cubeLink_text {
    color: #333;
    font-size: 14px;
    text-align: center;
    bottom: 20%;
}

.newCubeNavigationArea11 .cubeLink:nth-child(6n + 8) {
    padding-left: 3%;
}
.newCubeNavigationArea11 .cubeLink:nth-child(6n + 9) {
    padding-left: 3.5%;
}
.newCubeNavigationArea11 .cubeLink:nth-child(6n + 10) {
    padding-left: 3%;
}
.newCubeNavigationArea11 .cubeLink:nth-child(6n + 11) {
    padding-left: 3.5%;
}
.newCubeNavigationArea11 .cubeLink:nth-child(6n + 12) {
    padding-left: 3%;
}
.newCubeNavigationArea11 .cubeLink:nth-child(6n + 13) {
    padding-left: 3.5%;
}

.newCubeNavigationArea11 .cubeLink:nth-child(6n + 8) .icon-cube:before {
    content: '\e640';
}
.newCubeNavigationArea11 .cubeLink:nth-child(6n + 9) .icon-cube:before {
    content: '\e62e';
}
.newCubeNavigationArea11 .cubeLink:nth-child(6n + 10) .icon-cube:before {
    content: '\e6dd';
}
.newCubeNavigationArea11 .cubeLink:nth-child(6n + 11) .icon-cube:before {
    content: '\e6aa';
}
.newCubeNavigationArea11 .cubeLink:nth-child(6n + 12) .icon-cube:before {
    content: '\e67c';
}
.newCubeNavigationArea11 .cubeLink:nth-child(6n + 13) .icon-cube:before {
    content: '\e6e1';
}

.newCubeNavigationArea11 .icon-cube {
    color: #5c6066;
}
/*shaw 2018.10.31*/
.newCubeNavigationArea11 .cubeLink:nth-child(1) .cubeLink_a.noIcon .cubeLink_ico {
    background-image: none !important;
}
.newCubeNavigationArea11 .cubeLink:nth-child(1) .cubeLink_a.noIcon .cubeLink_ico:before {
    content: none !important;
}
.newCubeNavigationArea11 .cubeLink:nth-child(1) .cubeLink_a.noIcon .cubeLink_text {
    font-size: 22px;
    position: relative !important;
    text-align: center !important;
    display: table !important;
    height: 100% !important;
    left: auto !important;
    right: auto !important;
    top: auto !important;
    bottom: auto !important;
}
.newCubeNavigationArea11 .cubeLink:nth-child(1) .cubeLink_a.noIcon .cubeLink_text .cubeLink_text_p {
    display: table-cell !important;
    vertical-align: middle !important;
    padding: 0 !important;
}
.newCubeNavigationArea11 .cubeLink:nth-child(1) .icon-cube:before {
    content: '';
}
.newCubeNavigationArea11 .cubeLink:nth-child(2) .icon-cube:before {
    content: '\ec53';
}
.newCubeNavigationArea11 .cubeLink:nth-child(3) .icon-cube:before {
    content: '\a0823';
}
.newCubeNavigationArea11 .cubeLink:nth-child(4) .icon-cube:before {
    content: '\a0820';
}
.newCubeNavigationArea11 .cubeLink:nth-child(5) .icon-cube:before {
    content: '\a0991';
}
.newCubeNavigationArea11 .cubeLink:nth-child(6) .icon-cube:before {
    content: '\a0102';
}
.newCubeNavigationArea11 .cubeLink:nth-child(7) .icon-cube:before {
    content: '\e6e1';
}
.newCubeNavigationArea11 .cubeLink:nth-child(1) {
    float: right;
    width: 9rem;
    margin-right: 5.89%;
}
.jz_theme_1028 .newCubeNavigationArea11 .cubeLink:nth-child(1) {
    margin-right: 2.8%;
}
.jz_theme_1030 .newCubeNavigationArea11 .cubeLink:nth-child(1),
.jz_theme_1022 .newCubeNavigationArea11 .cubeLink:nth-child(1) {
    margin-right: 3.3%;
}
.newCubeNavigationArea11 .cubeLink:nth-child(2) {
    padding-left: 3.5%;
}
.newCubeNavigationArea11 .cubeLink:nth-child(3) {
    padding-left: 3.5%;
}
.newCubeNavigationArea11 .cubeLink:nth-child(4) {
    padding-left: 3%;
}
.newCubeNavigationArea11 .cubeLink:nth-child(5) {
    padding-left: 3.5%;
}
.newCubeNavigationArea11 .cubeLink:nth-child(6) {
    padding-left: 3%;
}
.newCubeNavigationArea11 .cubeLink:nth-child(7) {
    padding-left: 3.5%;
}

/*========*/
.formStyle31.moduleStyle12 .cubeLink_row:first-child {
    margin-top: 0.8rem;
}
.template350.formStyle31.moduleStyle12:nth-child(1) {
    margin-top: 212px;
    margin-bottom: 50px;
}
/*.newCubeNavigationArea12 .cubeLink .cubeLink_a{border: 1px solid #dbdbdb; box-sizing: border-box;}*/
.newCubeNavigationArea12 .cubeLink {
    width: 40%;
    height: auto;
    color: #ffffff;
    min-height: 1.8rem;
    padding-bottom: 5.8%;
}
.newCubeNavigationArea12 .cubeLink .cubeLink_a .cubeLink_bg {
    background-color: #ffffff;
    opacity: 0.7;
    border: 1px solid #dbdbdb;
    border-radius: 2px;
}
.newCubeNavigationArea12 .cubeLink .cubeLink_a .cubeLink_ico {
    left: 0;
    right: 0;
    background-image: none !important;
}
.newCubeNavigationArea12 .cubeLink .cubeLink_a .cubeLink_ico:before {
    content: none !important;
}
.newCubeNavigationArea12 .cubeLink .cubeLink_a .cubeLink_text {
    color: #333;
    font-size: 14px;
    position: relative !important;
    text-align: center !important;
    display: table !important;
    height: 100% !important;
    left: auto !important;
    right: auto !important;
    top: auto !important;
    bottom: auto !important;
    margin-top: 0rem !important;
}
.newCubeNavigationArea12 .cubeLink .cubeLink_a .cubeLink_text .cubeLink_text_p {
    display: table-cell !important;
    vertical-align: middle !important;
    padding: 0 !important;
    overflow: visible !important;
    white-space: normal !important;
    word-break: break-all !important;
}

.newCubeNavigationArea12 .cubeLink:nth-child(2n) {
    padding-left: 5.8%;
}
.newCubeNavigationArea12 .cubeLink:nth-child(2n + 1) {
    padding-left: 6.6%;
}

.newCubeNavigationArea12 .cubeLink_fixWidth_1 {
    width: 40% !important;
}

/*========*/
/*.formStyle31.moduleStyle13{background:#FFFFFF;margin:0;}*/
.newCubeNavigationArea13 .icon-cube {
    font-size: 2.2rem;
}
.newCubeNavigationArea13 {
    padding-top: 0;
    width: 100%;
}
.newCubeNavigationArea13 .cubeLink {
    width: 33%;
    min-height: 5.2rem;
    height: auto;
    padding: 0;
    padding-bottom: 2px;
    color: #ffffff;
    background: transparent;
    box-sizing: border-box;
    float: none;
    display: inline-block;
    vertical-align: top;
}
/*.newCubeNavigationArea13 .cubeLink .cubeLink_a{background:#fff;}*/
.newCubeNavigationArea13 .cubeLink .cubeLink_a .cubeLink_ico {
    position: relative !important;
    font-size: 1rem;
    width: 1rem;
    height: 1rem;
    top: 1.5rem !important;
    bottom: auto !important;
    left: 0 !important;
    right: 0 !important;
    margin: auto !important;
    background-position: center center;
    background-repeat: no-repeat;
}
.newCubeNavigationArea13 .cubeLink .cubeLink_a .cubeLink_text {
    color: #333;
    font-size: 14px;
}
.newCubeNavigationArea13 .cubeLink .cubeLink_a .cubeLink_bg {
    margin: auto;
    position: absolute;
    background-size: 40px 40px;
    width: 2.8rem;
    height: 2.8rem;
    left: 0;
    right: 0;
    top: 0.5rem;
    background-position: center center;
    background-repeat: no-repeat;
    border-radius: 50%;
    border: solid 1px #e5e5e5;
    background: #fff;
}

.newCubeNavigationArea13 .cubeLink .cubeLink_a .cubeLink_text {
    left: auto !important;
    right: auto !important;
    top: auto !important;
    bottom: 0.5rem !important;
    text-align: center !important;
    height: auto !important;
    position: relative !important;
    display: block !important;
    margin-top: 3.1rem !important;
}
.newCubeNavigationArea13 .cubeLink .cubeLink_a .cubeLink_text .cubeLink_text_p {
    display: block !important;
    padding: 0 !important;
    overflow: visible !important;
    white-space: normal !important;
    word-break: break-all !important;
}

/*.newCubeNavigationArea13 .cubeLink:nth-child(3n+2){border-left:1px solid #F4F4F4;border-right:1px solid #F4F4F4;}
.newCubeNavigationArea13 .cubeLink:nth-child(1){border-top:none;}
.newCubeNavigationArea13 .cubeLink:nth-child(2){border-top:none;}
.newCubeNavigationArea13 .cubeLink:nth-child(3){border-top:none;}*/

.newCubeNavigationArea13 .cubeLink_row .cubeLink:nth-child(2) {
    width: 34%;
    padding-left: 2px;
    padding-right: 2px;
}
.newCubeNavigationArea13 .cubeLink_row:nth-child(2n + 1) .cubeLink:nth-child(1) .icon-cube:before {
    content: '\a1015';
    color: #fd6e20;
}
.newCubeNavigationArea13 .cubeLink_row:nth-child(2n + 1) .cubeLink:nth-child(2) .icon-cube:before {
    content: '\a1012';
    color: #64abfa;
}
.newCubeNavigationArea13 .cubeLink_row:nth-child(2n + 1) .cubeLink:nth-child(3) .icon-cube:before {
    content: '\a0030';
    color: #e84653;
}
.newCubeNavigationArea13 .cubeLink_row:nth-child(2n + 2) .cubeLink:nth-child(1) .icon-cube:before {
    content: '\a0069';
    color: #fba942;
}
.newCubeNavigationArea13 .cubeLink_row:nth-child(2n + 2) .cubeLink:nth-child(2) .icon-cube:before {
    content: '\a1009';
    color: #35cb96;
}
.newCubeNavigationArea13 .cubeLink_row:nth-child(2n + 2) .cubeLink:nth-child(3) .icon-cube:before {
    content: '\a0045';
    color: #568ee8;
}
/*.newCubeNavigationArea13 .cubeLink_row:nth-child(3n+3) .cubeLink:nth-child(1) .icon-cube:before{  content: "\e6da";font-size: 1.7rem; margin-left: 0.55rem;}
.newCubeNavigationArea13 .cubeLink_row:nth-child(3n+3) .cubeLink:nth-child(2) .icon-cube:before{  content: "\e614";font-size: 1.7rem; margin-left: 0.55rem;}
.newCubeNavigationArea13 .cubeLink_row:nth-child(3n+3) .cubeLink:nth-child(3) .icon-cube:before{  content: "\e6c3";font-size: 1.7rem; margin-left: 0.55rem;}*/

/*.newCubeNavigationArea13 .cubeLink_row:nth-child(2n+1) .cubeLink:nth-child(1) .cubeLink_bg{background-color:#FDC200;}
.newCubeNavigationArea13 .cubeLink_row:nth-child(2n+1) .cubeLink:nth-child(2) .cubeLink_bg{background-color:#96A3FC;}
.newCubeNavigationArea13 .cubeLink_row:nth-child(2n+1) .cubeLink:nth-child(3) .cubeLink_bg{background-color:#F99161;}
.newCubeNavigationArea13 .cubeLink_row:nth-child(2n+2) .cubeLink:nth-child(1) .cubeLink_bg{background-color:#C697D8;}
.newCubeNavigationArea13 .cubeLink_row:nth-child(2n+2) .cubeLink:nth-child(2) .cubeLink_bg{background-color:#8FA5AD;}
.newCubeNavigationArea13 .cubeLink_row:nth-child(2n+2) .cubeLink:nth-child(3) .cubeLink_bg{background-color:#ED6492;}*/
/*.newCubeNavigationArea13 .cubeLink_row:nth-child(3n+3) .cubeLink:nth-child(1) .cubeLink_bg{background-color:#54D0E3;}
.newCubeNavigationArea13 .cubeLink_row:nth-child(3n+3) .cubeLink:nth-child(2) .cubeLink_bg{background-color:#FCBD15;}
.newCubeNavigationArea13 .cubeLink_row:nth-child(3n+3) .cubeLink:nth-child(3) .cubeLink_bg{background-color:#98CE5A;}*/

/*.newCubeNavigationArea13 .cubeLink_fixWidth_1{width: 100% !important;}
.newCubeNavigationArea13 .cubeLink_fixWidth_2{width: 50% !important;}*/

/*========*/
.formStyle31.moduleStyle14 {
    /*background:#FFFFFF;*/
    margin-top: 0;
}
.newCubeNavigationArea14 {
    width: 100%;
    padding-top: 0;
}
.newCubeNavigationArea14 .icon-cube {
    color: #5c6066;
}
.newCubeNavigationArea14 .cubeLink {
    box-sizing: border-box;
    width: 25%;
    padding: 0;
    padding-bottom: 0.5%;
    height: 4.2rem;
    min-height: 4.2rem;
    color: #ffffff;
    background: transparent; /*border-top:1px solid #F4F4F4;box-sizing:border-box;*/
}
/*.newCubeNavigationArea14 .cubeLink .cubeLink_a .cubeLink_bg{background:#fff;}*/
.newCubeNavigationArea14 .cubeLink .cubeLink_a .cubeLink_ico {
    bottom: auto !important;
    left: 0 !important;
    right: 0 !important;
    margin: auto !important;
}
.newCubeNavigationArea14 .cubeLink .cubeLink_a .cubeLink_text {
    color: #333;
    font-size: 14px;
    bottom: 0.6rem !important;
    left: auto !important;
    right: auto !important;
    top: auto !important;
    text-align: center !important;
    height: auto !important;
    position: absolute !important;
    display: block !important;
}
.newCubeNavigationArea14 .cubeLink .cubeLink_a .cubeLink_text .cubeLink_text_p {
    display: block !important;
    font-size: 0.6rem;
    overflow: visible !important;
    white-space: normal !important;
    word-break: break-all !important;
}

.newCubeNavigationArea14 .cubeLink_row_head .cubeLink {
    padding-right: 0.5%;
    height: auto;
}
.newCubeNavigationArea14 .cubeLink_row_head .cubeLink:nth-child(4n + 4) {
    padding-right: 0;
}
.newCubeNavigationArea14 .cubeLink_row_head .cubeLink .cubeLink_a .cubeLink_ico {
    position: relative !important;
    bottom: 0rem !important;
    margin-top: 0.5rem !important;
    top: auto !important;
}
.newCubeNavigationArea14 .cubeLink_row_head .cubeLink .cubeLink_a .cubeLink_text {
    position: relative !important;
    bottom: 0rem !important;
    margin-top: 0.2rem !important;
}

.newCubeNavigationArea14 .cubeLink_row_head:nth-child(1) .cubeLink:nth-child(1) .icon-cube:before {
    content: '\a0406';
}
.newCubeNavigationArea14 .cubeLink_row_head:nth-child(1) .cubeLink:nth-child(2) .icon-cube:before {
    content: '\a0427';
}
.newCubeNavigationArea14 .cubeLink_row_head:nth-child(1) .cubeLink:nth-child(3) .icon-cube:before {
    content: '\a0439';
}
.newCubeNavigationArea14 .cubeLink_row_head:nth-child(1) .cubeLink:nth-child(4) .icon-cube:before {
    content: '\a0699';
}
.newCubeNavigationArea14 .cubeLink_row_head:nth-child(2) .cubeLink:nth-child(1) .icon-cube:before {
    content: '\a0478';
}
.newCubeNavigationArea14 .cubeLink_row_head:nth-child(2) .cubeLink:nth-child(2) .icon-cube:before {
    content: '\a0457';
}
.newCubeNavigationArea14 .cubeLink_row_head:nth-child(2) .cubeLink:nth-child(3) .icon-cube:before {
    content: '\a0490';
}
.newCubeNavigationArea14 .cubeLink_row_head:nth-child(2) .cubeLink:nth-child(4) .icon-cube:before {
    content: '\a0612';
}

.newCubeNavigationArea14 .cubeLink_row:nth-child(n + 3) .cubeLink:nth-child(1) {
    width: 50%;
    padding-right: 0.5%;
}
.newCubeNavigationArea14 .cubeLink_row:nth-child(n + 3) .cubeLink:nth-child(2) {
    width: 50%;
}
.newCubeNavigationArea14
    .cubeLink_row:nth-child(n + 3)
    .cubeLink:nth-child(1)
    .cubeLink_a
    .cubeLink_text
    .cubeLink_text_p
    .cubeLink_subText_p {
    display: block;
}
.newCubeNavigationArea14
    .cubeLink_row:nth-child(n + 3)
    .cubeLink:nth-child(2)
    .cubeLink_a
    .cubeLink_text
    .cubeLink_text_p
    .cubeLink_subText_p {
    display: block;
}
.newCubeNavigationArea14 .cubeLink_row:nth-child(2n + 1) .cubeLink:nth-child(1) .cubeLink_a .cubeLink_text {
    font-size: 20px;
    position: relative !important;
    text-align: center !important;
    display: table !important;
    height: 100% !important;
    left: auto !important;
    right: auto !important;
    top: auto !important;
    bottom: auto !important;
}
.newCubeNavigationArea14
    .cubeLink_row:nth-child(2n + 1)
    .cubeLink:nth-child(1)
    .cubeLink_a
    .cubeLink_text
    .cubeLink_text_p {
    display: table-cell !important;
    vertical-align: middle !important;
    padding: 0 !important;
}
.newCubeNavigationArea14
    .cubeLink_row:nth-child(2n + 1)
    .cubeLink:nth-child(1)
    .cubeLink_a
    .cubeLink_text
    .cubeLink_text_p
    .cubeLink_subText_p {
    font-size: 0.6rem;
    font-weight: normal;
    color: #333;
    font-family: Arial;
    display: block;
}
.newCubeNavigationArea14 .cubeLink_row:nth-child(2n + 1) .cubeLink:nth-child(2) .cubeLink_bg {
    background-image: url(/image/styleDesign/newcube/newcover/1.jpg?v=201711250607);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.newCubeNavigationArea14 .cubeLink_row:nth-child(2n + 2) .cubeLink:nth-child(1) .cubeLink_bg {
    background-image: url(/image/styleDesign/newcube/newcover/2.jpg?v=201711250607);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.newCubeNavigationArea14 .cubeLink_row:nth-child(2n + 2) .cubeLink:nth-child(2) .cubeLink_a .cubeLink_text {
    font-size: 20px;
    position: relative !important;
    text-align: center !important;
    display: table !important;
    height: 100% !important;
    left: auto !important;
    right: auto !important;
    top: auto !important;
    bottom: auto !important;
}
.newCubeNavigationArea14
    .cubeLink_row:nth-child(2n + 2)
    .cubeLink:nth-child(2)
    .cubeLink_a
    .cubeLink_text
    .cubeLink_text_p {
    display: table-cell !important;
    vertical-align: middle !important;
    padding: 0 !important;
}
.newCubeNavigationArea14
    .cubeLink_row:nth-child(2n + 2)
    .cubeLink:nth-child(2)
    .cubeLink_a
    .cubeLink_text
    .cubeLink_text_p
    .cubeLink_subText_p {
    font-size: 0.6rem;
    font-weight: normal;
    color: #333;
    font-family: Arial;
    display: block;
}

/*========*/
/*.formStyle31.moduleStyle15{background:#FFFFFF;margin-top:0;}*/
.formStyle31.moduleStyle15 {
    margin-top: 0.7rem;
}
.newCubeNavigationArea15 {
    width: 100%;
    padding-top: 0.45rem;
}
.newCubeNavigationArea15 .cubeLink {
    box-sizing: border-box;
    width: 7rem;
    padding: 0;
    height: 3rem;
    padding-bottom: 0.5%;
    min-height: 3rem;
    margin-bottom: 0.75rem;
}
.newCubeNavigationArea15 .cubeLink .cubeLink_a {
    overflow: hidden;
    border-radius: 5px;
}
.newCubeNavigationArea15 .cubeLink .cubeLink_a .cubeLink_bg {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 40%;
}
.newCubeNavigationArea15 .cubeLink .cubeLink_a .cubeLink_curtain {
    width: 60%;
    right: 0;
    background: #f2f2f2;
}

@media only screen and (min-width: 480px) {
    .newCubeNavigationArea15 .cubeLink .cubeLink_a .cubeLink_curtain {
        width: 60%;
    }
}

.newCubeNavigationArea15 .cubeLink .cubeLink_a .cubeLink_ico {
    left: 0;
    right: 0;
    background-image: none !important;
}
.newCubeNavigationArea15 .cubeLink .cubeLink_a .cubeLink_ico:before {
    content: none !important;
}
.newCubeNavigationArea15 .cubeLink .cubeLink_a .cubeLink_text {
    font-size: 16px;
    color: #333333;
    position: relative !important;
    text-align: left !important;
    display: table !important;
    height: 100% !important;
    left: 40% !important;
    right: auto !important;
    top: auto !important;
    bottom: auto !important;
    width: 56%;
    text-indent: 17%;
}
.newCubeNavigationArea15 .cubeLink .cubeLink_a .cubeLink_text .cubeLink_text_p {
    display: table-cell !important;
    vertical-align: middle !important;
    padding: 0 10% 0 0 !important;
}
.newCubeNavigationArea15 .cubeLink .cubeLink_a .cubeLink_text .cubeLink_text_p .cubeLink_subText_p {
    font-size: 0.6rem;
    font-weight: normal;
    column-rule: #666;
    font-family: Arial;
    display: block;
}

.newCubeNavigationArea15 .cubeLink:nth-child(2n + 1) {
    margin-left: 0.5rem;
}
.newCubeNavigationArea15 .cubeLink:nth-child(2n + 2) {
    margin-left: 0.75rem;
}
.newCubeNavigationArea15 .cubeLink:nth-child(6n + 1) .cubeLink_a .cubeLink_bg {
    background-image: url(/image/styleDesign/newcube/newcover/4.jpg?v=201711250607);
}
.newCubeNavigationArea15 .cubeLink:nth-child(6n + 2) .cubeLink_a .cubeLink_bg {
    background-image: url(/image/styleDesign/newcube/newcover/5.jpg?v=201711250607);
}
.newCubeNavigationArea15 .cubeLink:nth-child(6n + 3) .cubeLink_a .cubeLink_bg {
    background-image: url(/image/styleDesign/newcube/newcover/6.jpg?v=201711250607);
}
.newCubeNavigationArea15 .cubeLink:nth-child(6n + 4) .cubeLink_a .cubeLink_bg {
    background-image: url(/image/styleDesign/newcube/newcover/7.jpg?v=201711250607);
}
.newCubeNavigationArea15 .cubeLink:nth-child(6n + 5) .cubeLink_a .cubeLink_bg {
    background-image: url(/image/styleDesign/newcube/newcover/8.jpg?v=201711250607);
}
.newCubeNavigationArea15 .cubeLink:nth-child(6n + 6) .cubeLink_a .cubeLink_bg {
    background-image: url(/image/styleDesign/newcube/newcover/9.jpg?v=201711250607);
}
/*.newCubeNavigationArea15 .cubeLink:nth-child(8n+7) .cubeLink_a .cubeLink_bg{ background-image:url(/image/styleDesign/cube/cover/6.jpg?v=201711250607);}
.newCubeNavigationArea15 .cubeLink:nth-child(8n+8) .cubeLink_a .cubeLink_bg{ background-image:url(/image/styleDesign/cube/cover/11.jpg?v=201711250607);}
*/

/*========*/
/*.formStyle31.moduleStyle16{background:#FFFFFF;margin-top:0;}*/
.formStyle31.moduleStyle16 {
    margin-top: 0.3rem;
}
.newCubeNavigationArea16 {
    width: 100%;
    padding-top: 0;
}
.newCubeNavigationArea16 .cubeLink {
    width: 50%;
    padding: 0;
    height: 3.7rem;
    min-height: 3.7rem;
    overflow: hidden;
}
.newCubeNavigationArea16 .cubeLink .cubeLink_a .cubeLink_ico {
    left: 10%;
    top: 0;
    bottom: 0;
    color: #f13a3a;
    font-size: 1.2rem;
    width: 1.2rem;
    height: 1.2rem;
}
.newCubeNavigationArea16 .cubeLink .cubeLink_a .cubeLink_text {
    font-size: 16px;
    position: relative;
    height: 100%;
    display: table;
    text-align: left;
    color: #000;
}
.newCubeNavigationArea16 .cubeLink .cubeLink_a .cubeLink_text .cubeLink_text_p {
    padding-left: 36%;
    display: table-cell;
    vertical-align: middle;
}
.newCubeNavigationArea16 .cubeLink .cubeLink_a .cubeLink_text .cubeLink_text_p .cubeLink_subText_p {
    font-size: 0.6rem;
    font-weight: normal;
    color: #666;
    font-family: Arial;
    display: block;
}
.newCubeNavigationArea16 .cubeLink_fixWidth_1 .cubeLink_a .cubeLink_ico {
    left: 37%;
}
.newCubeNavigationArea16 .cubeLink_fixWidth_1 .cubeLink_a .cubeLink_text .cubeLink_text_p {
    padding-left: 53%;
}

.newCubeNavigationArea16 .cubeLink .cubeLink_a .cubeLink_bg {
    box-sizing: border-box;
}
.newCubeNavigationArea16 .cubeLink:nth-child(2n + 1) .cubeLink_a .cubeLink_bg {
    border-right: 1px solid #f0f0f0;
    border-bottom: 1px solid #f0f0f0;
}
.newCubeNavigationArea16 .cubeLink:nth-child(2n + 2) .cubeLink_a .cubeLink_bg {
    border-bottom: 1px solid #f0f0f0;
}

.newCubeNavigationArea16 .cubeLink:nth-child(6n + 1) .icon-cube:before {
    content: '\a0841';
}
.newCubeNavigationArea16 .cubeLink:nth-child(6n + 2) .icon-cube:before {
    content: '\a0591';
}
.newCubeNavigationArea16 .cubeLink:nth-child(6n + 3) .icon-cube:before {
    content: '\a0105';
}
.newCubeNavigationArea16 .cubeLink:nth-child(6n + 4) .icon-cube:before {
    content: '\a0456';
}
.newCubeNavigationArea16 .cubeLink:nth-child(6n + 5) .icon-cube:before {
    content: '\a0346';
}
.newCubeNavigationArea16 .cubeLink:nth-child(6n + 6) .icon-cube:before {
    content: '\a0910';
}

.newCubeNavigationArea16 .cubeLink_fixWidth_1 {
    width: 100% !important;
}

/*========*/
/*.formStyle31.moduleStyle17{background:#FFFFFF;}*/
.newCubeNavigationArea17 .cubeLink {
    width: 19.6%;
    height: auto;
    padding-bottom: 4%;
    color: #ffffff;
    display: inline-block;
    float: none;
    vertical-align: top;
    min-height: 3.5rem;
}
/*修复样式1多行文字图标不居中的问题*/
.newCubeNavigationArea17 .cubeLink .cubeLink_a .cubeLink_ico {
    position: relative !important;
    width: 0.9rem;
    height: 0.9rem;
    transform: translateY(-50%);
    font-size: 0.9rem;
    left: 1% !important;
    top: 50%;
    background-position: center center;
    background-repeat: no-repeat;
}
.newCubeNavigationArea17 .cubeLink .cubeLink_a .cubeLink_text {
    color: #333;
    font-size: 14px;
}
.newCubeNavigationArea17 .cubeLink .cubeLink_a .cubeLink_bg {
    margin: auto;
    position: absolute;
    background-size: 40px 40px;
    width: 2.1rem;
    height: 2.1rem;
    left: 0;
    right: 0;
    background-position: center center;
    background-repeat: no-repeat;
    border-radius: 50%;
    top: 0;
}
/*修复样式1多行文字图标不居中的问题*/
.newCubeNavigationArea17 .cubeLink .cubeLink_a .cubeLink_text {
    left: auto !important;
    right: auto !important;
    top: auto !important;
    bottom: 0 !important;
    text-align: center !important;
    height: auto !important;
    position: relative !important;
    display: block !important;
    margin-top: 2.4rem;
}
.newCubeNavigationArea17 .cubeLink .cubeLink_a .cubeLink_text .cubeLink_text_p {
    display: block !important;
    padding: 0 !important;
    font-size: 0.5rem;
    overflow: visible !important;
    white-space: normal !important;
    word-break: break-all !important;
}

.newCubeNavigationArea17 .cubeLink_row:nth-child(1) .cubeLink {
    padding-top: 4.5%;
}

.newCubeNavigationArea17 .cubeLink:nth-child(5n + 1) {
    padding-left: 1%;
}
.newCubeNavigationArea17 .cubeLink:nth-child(5n + 2) {
    padding-left: 0;
}
.newCubeNavigationArea17 .cubeLink:nth-child(5n + 3) {
    padding-left: 0;
}
.newCubeNavigationArea17 .cubeLink:nth-child(5n + 4) {
    padding-left: 0;
}
.newCubeNavigationArea17 .cubeLink:nth-child(5n + 5) {
    padding-left: 0;
}

.newCubeNavigationArea17 .cubeLink_row:nth-child(2n + 1) .cubeLink:nth-child(1) .icon-cube:before {
    content: '\a0823';
}
.newCubeNavigationArea17 .cubeLink_row:nth-child(2n + 1) .cubeLink:nth-child(2) .icon-cube:before {
    content: '\a0961';
}
.newCubeNavigationArea17 .cubeLink_row:nth-child(2n + 1) .cubeLink:nth-child(3) .icon-cube:before {
    content: '\a0102';
}
.newCubeNavigationArea17 .cubeLink_row:nth-child(2n + 1) .cubeLink:nth-child(4) .icon-cube:before {
    content: '\a0814';
}
.newCubeNavigationArea17 .cubeLink_row:nth-child(2n + 1) .cubeLink:nth-child(5) .icon-cube:before {
    content: '\a0826';
}
.newCubeNavigationArea17 .cubeLink_row:nth-child(2n + 2) .cubeLink:nth-child(1) .icon-cube:before {
    content: '\a0045';
}
.newCubeNavigationArea17 .cubeLink_row:nth-child(2n + 2) .cubeLink:nth-child(2) .icon-cube:before {
    content: '\a0883';
}
.newCubeNavigationArea17 .cubeLink_row:nth-child(2n + 2) .cubeLink:nth-child(3) .icon-cube:before {
    content: '\ec53';
}
.newCubeNavigationArea17 .cubeLink_row:nth-child(2n + 2) .cubeLink:nth-child(4) .icon-cube:before {
    content: '\a0477';
}
.newCubeNavigationArea17 .cubeLink_row:nth-child(2n + 2) .cubeLink:nth-child(5) .icon-cube:before {
    content: '\a0111';
}

/*.newCubeNavigationArea7 .cubeLink_row:nth-child(2n+1) .cubeLink:nth-child(1) .icon-cube:before{  content: "\a0847";font-size: 1rem; margin-left: 0.75rem;}*/
/*.newCubeNavigationArea7 .cubeLink_row:nth-child(3n+3) .cubeLink:nth-child(1) .icon-cube:before{  content: "\e65f";font-size: 1.5rem; margin-left: 0.55rem;}
.newCubeNavigationArea7 .cubeLink_row:nth-child(3n+3) .cubeLink:nth-child(2) .icon-cube:before{  content: "\e686";font-size: 1.5rem; margin-left: 0.55rem;}
.newCubeNavigationArea7 .cubeLink_row:nth-child(3n+3) .cubeLink:nth-child(3) .icon-cube:before{  content: "\e6e1";font-size: 1.5rem; margin-left: 0.55rem;}
.newCubeNavigationArea7 .cubeLink_row:nth-child(3n+3) .cubeLink:nth-child(4) .icon-cube:before{  content: "\e70d";font-size: 1.5rem; margin-left: 0.55rem;}*/

.newCubeNavigationArea17 .cubeLink_row:nth-child(2n + 1) .cubeLink:nth-child(1) .cubeLink_bg {
    background: #26a9fd;
}
.newCubeNavigationArea17 .cubeLink_row:nth-child(2n + 1) .cubeLink:nth-child(2) .cubeLink_bg {
    background: #32ce9a;
}
.newCubeNavigationArea17 .cubeLink_row:nth-child(2n + 1) .cubeLink:nth-child(3) .cubeLink_bg {
    background: #ffa352;
}
.newCubeNavigationArea17 .cubeLink_row:nth-child(2n + 1) .cubeLink:nth-child(4) .cubeLink_bg {
    background: #fd6190;
}
.newCubeNavigationArea17 .cubeLink_row:nth-child(2n + 1) .cubeLink:nth-child(5) .cubeLink_bg {
    background: #dc4154;
}
.newCubeNavigationArea17 .cubeLink_row:nth-child(2n + 2) .cubeLink:nth-child(1) .cubeLink_bg {
    background: #fe6469;
}
.newCubeNavigationArea17 .cubeLink_row:nth-child(2n + 2) .cubeLink:nth-child(2) .cubeLink_bg {
    background: #8e8ffb;
}
.newCubeNavigationArea17 .cubeLink_row:nth-child(2n + 2) .cubeLink:nth-child(3) .cubeLink_bg {
    background: #76c951;
}
.newCubeNavigationArea17 .cubeLink_row:nth-child(2n + 2) .cubeLink:nth-child(4) .cubeLink_bg {
    background: #6edcfe;
}
.newCubeNavigationArea17 .cubeLink_row:nth-child(2n + 2) .cubeLink:nth-child(5) .cubeLink_bg {
    background: #ffc72e;
}
/*.newCubeNavigationArea7 .cubeLink_row:nth-child(3n+3) .cubeLink:nth-child(1) .cubeLink_bg{background-color:#688CE3;}
.newCubeNavigationArea7 .cubeLink_row:nth-child(3n+3) .cubeLink:nth-child(2) .cubeLink_bg{background-color:#36A1DA;}
.newCubeNavigationArea7 .cubeLink_row:nth-child(3n+3) .cubeLink:nth-child(3) .cubeLink_bg{background-color:#83CF16;}
.newCubeNavigationArea7 .cubeLink_row:nth-child(3n+3) .cubeLink:nth-child(4) .cubeLink_bg{background-color:#8D65E0;}*/

/*====新增魔方导航样式在这里上面加=====*/

/*====魔方导航各种样式end====*/

/*必须明确一点，固定布局样式必须放在最后面，否则会被上面的导航样式覆盖。*/

/*====固定布局样式start====*/
.newCubeNavigationArea .cubeLink .cubeLink_a.textLayout1 .cubeLink_ico {
    text-align: center;
    right: 0;
    top: 10%;
    bottom: auto;
    position: absolute;
}
.newCubeNavigationArea .cubeLink .cubeLink_a.textLayout1 .cubeLink_text {
    left: auto;
    right: auto;
    top: auto;
    bottom: 10%;
    text-align: center;
    height: auto;
    position: absolute;
    display: block;
    padding: 0;
}
.newCubeNavigationArea .cubeLink .cubeLink_a.textLayout1 .cubeLink_text .cubeLink_text_p {
    padding: 0;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    word-break: normal;
    text-indent: 0;
}

.newCubeNavigationArea .cubeLink .cubeLink_a.textLayout2 .cubeLink_ico {
    text-align: center;
    right: 0;
    top: auto;
    bottom: 10%;
    position: absolute;
}
.newCubeNavigationArea .cubeLink .cubeLink_a.textLayout2 .cubeLink_text {
    left: auto;
    right: auto;
    top: 10%;
    bottom: auto;
    text-align: center;
    height: auto;
    position: absolute;
    display: block;
    margin-top: 0%;
    padding: 0;
}
.newCubeNavigationArea .cubeLink .cubeLink_a.textLayout2 .cubeLink_text .cubeLink_text_p {
    padding: 0;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    word-break: normal;
    text-indent: 0;
}

.newCubeNavigationArea .cubeLink .cubeLink_a.textLayout3 .cubeLink_ico {
    left: 10%;
    right: auto;
    top: 0;
    bottom: 0;
    position: absolute;
    margin: auto;
}
.newCubeNavigationArea .cubeLink .cubeLink_a.textLayout3 .cubeLink_text {
    left: auto;
    top: auto;
    bottom: auto;
    text-align: right;
    height: 100%;
    position: relative;
    display: table;
    margin: auto;
}
.newCubeNavigationArea .cubeLink .cubeLink_a.textLayout3 .cubeLink_text .cubeLink_text_p {
    padding-right: 10%;
    display: table-cell;
    vertical-align: middle;
    overflow: hidden;
    white-space: nowrap;
    word-break: normal;
}

.newCubeNavigationArea .cubeLink .cubeLink_a.textLayout4 .cubeLink_ico {
    left: auto;
    right: 10%;
    top: 0;
    bottom: 0;
    position: absolute;
    margin: auto;
}
.newCubeNavigationArea .cubeLink .cubeLink_a.textLayout4 .cubeLink_text {
    right: auto;
    top: auto;
    bottom: auto;
    text-align: left;
    height: 100%;
    position: relative;
    display: table;
    margin: auto;
}
.newCubeNavigationArea .cubeLink .cubeLink_a.textLayout4 .cubeLink_text .cubeLink_text_p {
    padding-left: 10%;
    display: table-cell;
    vertical-align: middle;
    overflow: hidden;
    white-space: nowrap;
    word-break: normal;
}

.newCubeNavigationArea .cubeLink .cubeLink_a.textLayout5 .cubeLink_ico {
    left: auto;
    right: 10%;
    top: auto;
    bottom: 10%;
    position: absolute;
    margin: auto;
}
.newCubeNavigationArea .cubeLink .cubeLink_a.textLayout5 .cubeLink_text {
    right: auto;
    top: 10%;
    bottom: auto;
    text-align: left;
    height: auto;
    position: absolute;
    display: block;
    margin-top: 0;
}
.newCubeNavigationArea .cubeLink .cubeLink_a.textLayout5 .cubeLink_text .cubeLink_text_p {
    padding-left: 10%;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    word-break: normal;
}

.newCubeNavigationArea .cubeLink .cubeLink_a.textLayout6 .cubeLink_ico {
    left: 10%;
    right: auto;
    top: 10%;
    bottom: auto;
    position: absolute;
    margin: auto;
}
.newCubeNavigationArea .cubeLink .cubeLink_a.textLayout6 .cubeLink_text {
    left: auto;
    top: auto;
    bottom: 10%;
    text-align: right;
    height: auto;
    position: absolute;
    display: block;
}
.newCubeNavigationArea .cubeLink .cubeLink_a.textLayout6 .cubeLink_text .cubeLink_text_p {
    padding-right: 10%;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    word-break: normal;
}

.newCubeNavigationArea .cubeLink .cubeLink_a.textLayout7 .cubeLink_ico {
    left: 10%;
    right: auto;
    top: auto;
    bottom: 10%;
    position: absolute;
    margin: auto;
}
.newCubeNavigationArea .cubeLink .cubeLink_a.textLayout7 .cubeLink_text {
    left: auto;
    top: 10%;
    bottom: auto;
    text-align: right;
    height: auto;
    position: absolute;
    display: block;
    margin-top: 0;
}
.newCubeNavigationArea .cubeLink .cubeLink_a.textLayout7 .cubeLink_text .cubeLink_text_p {
    padding-right: 10%;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    word-break: normal;
}

.newCubeNavigationArea .cubeLink .cubeLink_a.textLayout8 .cubeLink_ico {
    left: auto;
    right: 10%;
    top: 10%;
    bottom: auto;
    position: absolute;
    margin: auto;
}
.newCubeNavigationArea .cubeLink .cubeLink_a.textLayout8 .cubeLink_text {
    right: auto;
    top: auto;
    bottom: 10%;
    text-align: left;
    height: auto;
    position: absolute;
    display: block;
}
.newCubeNavigationArea .cubeLink .cubeLink_a.textLayout8 .cubeLink_text .cubeLink_text_p {
    padding-left: 10%;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    word-break: normal;
}

/*修复样式6第一个导航新增图标样式的左右布局*/
.newCubeNavigationArea6 .cubeLink1 .cubeLink_a.textLayout3 .cubeLink_ico {
    margin-left: 1.5rem;
}
.newCubeNavigationArea6 .cubeLink1 .cubeLink_a.textLayout4 .cubeLink_ico {
    margin-right: -0.7rem;
}
.newCubeNavigationArea6 .cubeLink1 .cubeLink_a.textLayout7 .cubeLink_ico {
    margin-left: 2.3rem;
}
.newCubeNavigationArea6 .cubeLink1 .cubeLink_a.textLayout6 .cubeLink_ico {
    margin-left: 2.3rem;
}

/*修复自定义布局下，出现丑陋样式的修复*/
.newCubeNavigationArea3 .cubeLink .cubeLink_a.textLayout5 .cubeLink_text {
    text-indent: 0;
}
.newCubeNavigationArea3 .cubeLink .cubeLink_a.textLayout8 .cubeLink_text {
    text-indent: 0;
}
/*新魔方导航样式*/
.newCubeNavigationArea14 .cubeLink .cubeLink_a .icon-cube {
    font-size: 1.2rem;
    width: 1.2rem;
    height: 1.2rem;
}

.newCubeNavigationArea .cubeLink .cubeLink_a .cubeLink_text .cubeLink_text_p {
    font-size: 0.6rem;
}
.newCubeNavigationArea17 .cubeLink .cubeLink_a .cubeLink_text .cubeLink_text_p {
    font-size: 0.5rem;
}
.newCubeNavigationArea .cubeLink .cubeLink_a .cubeLink_text .cubeLink_subText_p {
    font-size: 0.5rem;
    line-height: 0.8rem;
}

/*====固定布局样式end====*/

/*懒加载情况，不需要先出现图片*/
/*.formMiddleContent .cubeNavigationArea .cubeLink .cubeLink_a .for-lazyload.cubeLink_bg{background-image:none;}*/
.formMiddleContent .newCubeNavigationArea .cubeLink .cubeLink_a .for-lazyload.cubeLink_bg {
    background-image: none;
}
/*魔方导航新样式结束*/

/* 20190128 单词智能换行*/
.cubeNavigationArea .cubeLink .cubeLink_a .cubeLink_text .cubeLink_text_p {
    word-break: break-word !important;
}

/* 自定义样式设置 start @ken */

/* 图标大小 自定义（要加上自定义类控制）*/

/*项间距*/
.newCubeNavigationArea1.cus_space .cubeLink_row {
    display: flex;
    justify-content: center;
}
.newCubeNavigationArea1.cus_space .cubeLink_row .cubeLink:nth-child(1) {
    padding-left: 1.5% !important;
}
.newCubeNavigationArea1.cus_space .cubeLink_row .cubeLink:last-child {
    padding-right: 1.5% !important;
}
.newCubeNavigationArea1.cus_space .cubeLink_row:last-child .cubeLink {
    padding-bottom: 1.5% !important;
}

.newCubeNavigationArea2.cus_space .cubeLink_row {
    display: flex;
    justify-content: center;
}
.newCubeNavigationArea2.cus_space .cubeLink_row .cubeLink:nth-child(1) {
    padding-left: 3% !important;
}
.newCubeNavigationArea2.cus_space .cubeLink_row .cubeLink:last-child {
    padding-right: 3% !important;
}
.newCubeNavigationArea2.cus_space .cubeLink_row:last-child .cubeLink {
    padding-bottom: 4% !important;
}

.newCubeNavigationArea12.cus_space .cubeLink_row {
    display: flex;
    justify-content: center;
}
.newCubeNavigationArea12.cus_space .cubeLink_row .cubeLink:nth-child(1) {
    padding-left: 6.6% !important;
}
.newCubeNavigationArea12.cus_space .cubeLink_row .cubeLink:last-child {
    padding-right: 6.6% !important;
}
.newCubeNavigationArea12.cus_space .cubeLink_row:last-child .cubeLink {
    padding-bottom: 5.8% !important;
}

.newCubeNavigationArea10.cus_space .cubeLink_row {
    display: flex;
    justify-content: center;
}
.newCubeNavigationArea10.cus_space .cubeLink_row .cubeLink:nth-child(1) {
    padding-left: 2.5% !important;
}
.newCubeNavigationArea10.cus_space .cubeLink_row .cubeLink:last-child {
    padding-right: 2.5% !important;
}
.newCubeNavigationArea10.cus_space .cubeLink_row:last-child .cubeLink {
    padding-bottom: 4% !important;
}

.newCubeNavigationArea4.cus_space .cubeLink_row {
    display: flex;
    justify-content: center;
}
.newCubeNavigationArea4.cus_space .cubeLink_row .cubeLink:nth-child(1) {
    padding-left: 3.5% !important;
}
.newCubeNavigationArea4.cus_space .cubeLink_row .cubeLink:last-child {
    padding-right: 3.5% !important;
}
.newCubeNavigationArea4.cus_space .cubeLink_row:last-child .cubeLink {
    padding-bottom: 5.5% !important;
}

.newCubeNavigationArea3.cus_space .cubeLink_row {
    display: flex;
    justify-content: center;
}
.newCubeNavigationArea3.cus_space .cubeLink_row .cubeLink:nth-child(1) {
    padding-left: 2.5% !important;
}
.newCubeNavigationArea3.cus_space .cubeLink_row .cubeLink:last-child {
    padding-right: 2.5% !important;
}
.newCubeNavigationArea3.cus_space .cubeLink_row:last-child .cubeLink {
    padding-bottom: 5% !important;
}

/* 高度  start  为了设置高度能自适应，这里用 绝对定位 百分数*/
.cubeNavigationBoxContent.cus_height .cubeLink {
    min-height: 2rem;
}

.cubeNavigationBoxContent2.cus_height .cubeLink_a.textLayout0 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.cubeNavigationBoxContent2.cus_height .cubeLink .cubeLink_a.textLayout0 .cubeLink_ico {
    position: static;
    z-index: 1;
    margin: 0;
}
.cubeNavigationBoxContent2.cus_height .cubeLink .textLayout0 .cubeLink_text {
    margin-top: 0.73rem;
}

.cubeNavigationBoxContent10.cus_height .cubeLink_a.textLayout0 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.cubeNavigationBoxContent10.cus_height .cubeLink .cubeLink_a.textLayout0 .cubeLink_ico {
    position: static;
    z-index: 1;
    margin: 0;
}
.cubeNavigationBoxContent10.cus_height .cubeLink .textLayout0 .cubeLink_text {
    margin-top: 0.73rem;
}

.cubeNavigationBoxContent9.cus_height .cubeLink_a.textLayout0 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.cubeNavigationBoxContent9.cus_height .cubeLink .cubeLink_a.textLayout0 .cubeLink_ico {
    position: static;
    z-index: 1;
    margin: 0;
}
.cubeNavigationBoxContent9.cus_height .cubeLink .textLayout0 .cubeLink_text {
    margin-top: 0.73rem;
}

.cubeNavigationBoxContent1.cus_height .cubeLink .textLayout0 .cubeLink_text {
    position: relative;
    margin-top: 0;
    top: 56.5%;
}
.cubeNavigationBoxContent1.cus_height .cubeLink .textLayout0 .cubeLink_ico {
    position: absolute;
    top: 18%;
}

/* 标题换行 */
.cubeNavigationBoxContent.main_no_wrap .cubeLink_a .cubeLink_text .cube_text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.cubeNavigationBoxContent.sub_no_wrap .cubeLink_a .cubeLink_text .cubeLink_subText_p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.cubeNavigationBoxContent.main_wrap .cubeLink_a .cubeLink_text .cube_text {
    word-wrap: break-word;
    word-break: normal;
    white-space: normal;
}
.cubeNavigationBoxContent.sub_wrap .cubeLink_a .cubeLink_text .cubeLink_subText_p {
    word-wrap: break-word;
    word-break: normal;
    white-space: normal;
}

.cubeNavigationBoxContent.main_no_wrap .cubeLink_a .cubeLink_text .cubeLink_text_p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
} /* 修复布局6和7不打点*/
.cubeNavigationBoxContent.main_no_wrap .cubeLink_a .cubeLink_text .cubeLink_text_p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.cubeNavigationBoxContent .cubeLink_a {
    overflow: hidden;
} /*换行超出截断处理*/
.cubeNavigationBoxContent12 .cubeLink_a {
    overflow: visible;
}

.cubeNavigationBoxContent8 .cubeLink .cubeLink_a .cubeLink_text {
    top: 60%;
    bottom: initial;
} /*处理文字高度向下增长*/
/*.cubeNavigationBoxContent8 .cubeLink:nth-child(7n+1) .cubeLink_a .cubeLink_text{top:53%;bottom: initial;}*/

.newCubeNavigationArea .cubeNavigationBoxContent15 .cubeLink .cubeLink_a .cubeLink_text {
    text-indent: 0;
    left: 49.5% !important;
} /*换行文字不对齐*/
.cubeNavigationBoxContent3 .cubeLink .cubeLink_a .cubeLink_text {
    text-indent: 0;
}
.cubeNavigationBoxContent3 .cubeLink .cubeLink_a .cubeLink_text .cubeLink_text_p {
    padding-left: 10%;
}
.cubeNavigationBoxContent3 .cubeLink_text .cubeLink_text_p {
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
} /* 修复不换行时的右边距*/

.cubeNavigationBoxContent17.main_no_wrap .cube_text,
.cubeNavigationBoxContent12.main_no_wrap .cube_text,
.cubeNavigationBoxContent10.main_no_wrap .cube_text,
.cubeNavigationBoxContent14.main_no_wrap .cube_text,
.cubeNavigationBoxContent2.main_no_wrap .cube_text,
.cubeNavigationBoxContent7.main_no_wrap .cube_text,
.cubeNavigationBoxContent9.main_no_wrap .cube_text,
.cubeNavigationBoxContent13.main_no_wrap .cube_text,
.cubeNavigationBoxContent15.main_no_wrap .cube_text,
.cubeNavigationBoxContent1.main_no_wrap .cube_text {
    display: block;
}

/* 修复文字太多时的左右间距*/
.cubeNavigationBoxContent .cubeLink .cubeLink_a.textLayout0 .cubeLink_text,
.cubeNavigationBoxContent .cubeLink .cubeLink_a.textLayout1 .cubeLink_text,
.cubeNavigationBoxContent .cubeLink .cubeLink_a.textLayout2 .cubeLink_text {
    box-sizing: border-box;
    max-width: 100%;
}
.cubeNavigationBoxContent .cubeLink_text_p {
    padding: 0 0.3rem;
}
.cubeNavigationBoxContent11 .cubeLink:nth-child(1) .cubeLink_a.noIcon .cubeLink_text .cubeLink_text_p {
    padding: 0 0.3rem !important;
}

.cubeNavigationBoxContent9 .cubeNavigationAreaWrap .cubeLink .cubeLink_a .cubeLink_text .cubeLink_text_p,
.cubeNavigationBoxContent5 .cubeNavigationAreaWrap .cubeLink .cubeLink_a .cubeLink_text .cubeLink_text_p,
.cubeNavigationBoxContent11 .cubeNavigationAreaWrap .cubeLink .cubeLink_a .cubeLink_text .cubeLink_text_p,
.cubeNavigationBoxContent1 .cubeNavigationAreaWrap .cubeLink .cubeLink_a .cubeLink_text .cubeLink_text_p {
    padding: 0 0.3rem;
}
/*.cubeNavigationBoxContent9 .textLayout6 .cubeLink_text_p,
.cubeNavigationBoxContent9 .textLayout3 .cubeLink_text_p,
.cubeNavigationBoxContent10 .textLayout3 .cubeLink_text_p,
.cubeNavigationBoxContent9 .textLayout7 .cubeLink_text_p,
.cubeNavigationBoxContent5 .textLayout6 .cubeLink_text_p,
.cubeNavigationBoxContent6 .textLayout6 .cubeLink_text_p{padding-left: 0.3rem;}
.cubeNavigationBoxContent10 .textLayout8 .cubeLink_text_p,
.cubeNavigationBoxContent10 .textLayout5 .cubeLink_text_p,
.cubeNavigationBoxContent10 .textLayout4 .cubeLink_text_p,
.cubeNavigationBoxContent9 .textLayout8 .cubeLink_text_p,
.cubeNavigationBoxContent9 .textLayout5 .cubeLink_text_p,
.cubeNavigationBoxContent9 .textLayout4 .cubeLink_text_p{padding-right: 0.3rem;}
*/

/* 修复自定义图标大小超出容器截断 */
.cubeNavigationBoxContent .cubeLink_bg {
    overflow: hidden;
}

/* 修复管理态轮播时编辑编辑单个导航项时因为id重复导致元素获取错误，所以插入这个div */
.cubeNavigationBoxContent .cubeLink_a_wrap {
    width: 100%;
    height: 100%;
}

/* 自定义样式设置 end*/

/* 样式18 */
.cubeNavigationBoxContent18 .cubeLink {
    padding: 0;
    height: 2.925rem;
}
.cubeNavigationBoxContent18 .cube_text {
    color: rgba(255, 255, 255, 1);
    font-size: 0.7rem;
    display: block;
    width: 100%;
}
.cubeNavigationBoxContent18 .cubeLink_bg {
    background-color: rgba(22, 30, 34, 1);
}
.cubeNavigationBoxContent18 .cubeLink_subText_p {
    color: rgba(255, 255, 255, 0.7);
    font-size: 0.55rem;
}
.cubeNavigationBoxContent18 .cubeLink .cubeLink_a .cubeLink_bg {
    width: 50%;
    height: 100%;
}
.cubeNavigationBoxContent18 .cubeLink .cubeLink_a .cubeLink_ico {
    width: 50%;
    height: 100%;
    background-size: cover;
    align-items: center;
    display: flex;
    justify-content: center;
}
.cubeNavigationBoxContent18 .cubeLink .cubeLink_a .cubeLink_text {
    width: 50%;
    height: 100%;
    overflow: hidden;
}
.cubeNavigationBoxContent18 .cubeLink_text {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.cubeNavigationBoxContent18 .cubeLink.even .cubeLink_bg,
.cubeNavigationBoxContent18 .cubeLink.even .cubeLink_text {
    right: 0;
}
.cubeNavigationBoxContent18 .cubeLink.odd .icon-cube {
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.cubeNavigationBoxContent18 .cubeLink:nth-child(3n + 1) .cubeLink_ico {
    background-image: url(/image/styleDesign/newcube/newcover/10.jpg?v=201909251255);
}
.cubeNavigationBoxContent18 .cubeLink:nth-child(3n + 2) .cubeLink_ico {
    background-image: url(/image/styleDesign/newcube/newcover/12.jpg?v=201909251255);
}
.cubeNavigationBoxContent18 .cubeLink:nth-child(3n + 3) .cubeLink_ico {
    background-image: url(/image/styleDesign/newcube/newcover/11.jpg?v=201909251255);
}

/* 样式18 end*/

/* 隐藏图标，文字居中*/
.cubeNavigationBoxContent .cubeLink.icon_hide .cubeLink_a {
    display: flex;
    justify-content: center;
    align-items: center;
}
.cubeNavigationBoxContent .cubeLink.icon_hide .cubeLink_a .cubeLink_text {
    z-index: 1;
    position: static;
    text-align: center;
}
.cubeNavigationBoxContent .cubeLink.icon_hide .cubeLink_a .cubeLink_ico {
    display: none;
}
.cubeNavigationBoxContent18 .cubeLink.icon_hide .cubeLink_bg {
    width: 100% !important;
} /* 覆盖 pageFunction 输出时的 id 选择器 */
.cubeNavigationBoxContent18 .cubeLink.icon_hide .cubeLink_text {
    width: 100% !important;
}
.cubeNavigationBoxContent10 .cubeLink.icon_hide .cubeLink_a .cubeLink_text,
.cubeNavigationBoxContent1 .cubeLink.icon_hide .cubeLink_a .cubeLink_text,
.cubeNavigationBoxContent9 .cubeLink.icon_hide .cubeLink_a .cubeLink_text {
    margin-top: 0;
}
.cubeNavigationBoxContent16 .cubeLink.icon_hide .cubeLink_a .cubeLink_text .cubeLink_text_p {
    padding: 0;
}
.cubeNavigationBoxContent2 .cubeLink.icon_hide .cubeLink_text {
    margin: auto;
}

/* 隐藏文字，图标居中*/
.cubeNavigationBoxContent .cubeLink.title_hide .cubeLink_a {
    display: flex;
    justify-content: center;
    align-items: center;
}
.cubeNavigationBoxContent .cubeLink.title_hide .cubeLink_a .cubeLink_ico {
    z-index: 1;
    position: static;
    text-align: center;
}
.cubeNavigationBoxContent .cubeLink.title_hide .cubeLink_a .cubeLink_text {
    display: none;
}
.cubeNavigationBoxContent18 .cubeLink.title_hide .cubeLink_ico {
    width: 100% !important;
}
.cubeNavigationBoxContent14 .cubeLink.title_hide .cubeLink_a .cubeLink_text {
    display: none !important;
}
.cubeNavigationBoxContent14 .cubeLink_row_head .cubeLink.title_hide .cubeLink_a .cubeLink_ico {
    margin-top: auto !important;
}
.cubeNavigationBoxContent9 .cubeLink.title_hide .cubeLink_a .cubeLink_ico {
    margin: auto;
}

/* 修复 moduleStyle14 隐藏最后两个导航图标后布局错位 bug */
.newCubeNavigationArea14 .cubeLink_row_head {
    overflow: hidden;
}
</style>
