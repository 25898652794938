<template>
    <div v-outsideClick="handleClose" class="variable_input" :style="inputBoxStyle">
        <input
            ref="variableInput"
            type="text"
            :maxlength="maxlength"
            :value="value"
            class="jz_focus"
            :placeHolder="placeHolder"
            :style="inputStyle"
            @input="inputItemText"
        />
    </div>
</template>

<script>
export default {
    name: 'VariableInput',
    directives: {
        outsideClick: {
            bind: function (el, binding) {
                function documentHandler(e) {
                    if (el.contains(e.target)) {
                        return false;
                    }
                    if (binding.expression) {
                        binding.value(e.target);
                    }
                }
                var isBubble = binding.modifiers.bubble;
                var useCapture = !isBubble;
                el.__vueClickOutside__ = documentHandler;
                document.addEventListener('click', documentHandler, useCapture);
            },
            unbind: function () {},
        },
    },
    props: {
        // eslint-disable-next-line vue/require-default-prop
        item: Object,
        value: {
            type: [String, Number],
            default: '',
        },
        // eslint-disable-next-line vue/require-default-prop
        maxlength: Number,
        placeHolder: {
            type: String,
            default: '',
        },
        // eslint-disable-next-line vue/require-valid-default-prop
        inputStyle: {
            type: Object,
            default: () => ({}),
        },
        // eslint-disable-next-line vue/require-valid-default-prop
        inputBoxStyle: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            inputText: '',
        };
    },
    methods: {
        handleClose() {
            this.$refs.variableInput && this.$refs.variableInput.blur();
        },
        inputItemText: function (e) {
            var str = e.target.value;
            if (str !== '') {
                var len1 = str.substr(0, 1);
                var len2 = str.substr(1, 1);
                //如果第一位是0，第二位不是点，就用数字把点替换掉
                if (str.length > 1 && len1 == 0 && len2 != '.') {
                    str = str.substr(1, 1);
                }
                //第一位不能是.
                if (len1 == '.') {
                    str = '';
                }
                //限制只能输入一个小数点，只能输入10位数
                if (str.indexOf('.') != -1) {
                    var dotIndex = str.indexOf('.');
                    var str_ = str.substr(dotIndex + 1);
                    if (dotIndex > 10) {
                        str = str.substr(0, 10) + '.' + str_;
                    }
                    if (str_.indexOf('.') != -1) {
                        str = str.substr(0, str.indexOf('.') + str_.indexOf('.') + 1);
                    }
                } else if (str.length > 10) {
                    str = str.substr(0, 10);
                }
                //正则替换，保留数字和小数点
                str = str.replace(/[^\d^.]+/g, '');
                this.$refs.variableInput.value = str;
                this.inputText = str;
                this.emitInput();
            }
        },
        emitInput: function () {
            this.$emit('input', this.inputText);
        },
    },
};
</script>
