var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "photoModule styleForm1" },
    [
      _vm._l(_vm.list, function (item, index) {
        return _c(
          "div",
          {
            key: item.picId,
            staticClass: "g_background photoDiv",
            class: _vm.photoDivClass,
          },
          [
            _c(
              "a",
              {
                attrs: { href: item.href || undefined },
                on: {
                  click: function ($event) {
                    return _vm.imgClick(index)
                  },
                },
              },
              [
                _c("div", { staticClass: "imgClass_160" }, [
                  _c("span", { staticClass: "imageMiddle" }),
                  _vm._v(" "),
                  _c("img", {
                    staticClass: "photoListImg J_img_lazyload",
                    class: _vm.imgClass,
                    attrs: {
                      "data-picid": item.picId,
                      src: item.picThumbPath,
                      photoIndex: index,
                      alt: item.alt,
                      linkType: item.linkType,
                      vwidth: item.vwidth,
                      vheight: item.vheight,
                    },
                  }),
                ]),
                _vm._v(" "),
                _vm.showName
                  ? [
                      _vm.nameWordWrap
                        ? _c("div", { staticClass: "imgName g_link" }, [
                            _vm._v(_vm._s(item.imgName)),
                          ])
                        : _c(
                            "div",
                            {
                              staticClass: "imgName g_link",
                              staticStyle: { "white-space": "nowrap" },
                            },
                            [_vm._v(_vm._s(item.imgName))]
                          ),
                    ]
                  : _vm._e(),
              ],
              2
            ),
          ]
        )
      }),
      _vm._v(" "),
      _c("div", { staticStyle: { clear: "both" } }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }