var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "news_list-pic-text" },
    [
      _vm.isNewModule
        ? [
            _vm._l(_vm.newsList, function (info, index) {
              return [
                _c("pic-text-item-v2", {
                  key: "n_" + info.id,
                  attrs: {
                    info: info,
                    index: index,
                    "no-lazy-load": _vm.noLazyLoad,
                  },
                }),
                _vm._v(" "),
                _c("div", {
                  key: "s_" + info.id,
                  staticClass: "g_separator separatorLine",
                }),
              ]
            }),
          ]
        : [
            _vm._l(_vm.newsList, function (info, index) {
              return [
                _c("pic-text-item-v1", {
                  key: "n_" + info.id,
                  attrs: {
                    info: info,
                    index: index,
                    "no-lazy-load": _vm.noLazyLoad,
                  },
                }),
              ]
            }),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }