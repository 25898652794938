import './dashed-frame.scss';

import { createModuleFrame, getElementId, addDashFrame } from '@/shared/dash';
export default {
    bind(el, binding) {
        if (binding.value && binding.value.showDash === false) {
            return;
        }

        if (binding.value && binding.value.onlyLoadVisitScript) {
            return;
        }
        if (VITE_APP_MODE !== 'visitor') {
            addDashFrame(el, binding.value);
        }
    },
    update(el, binding) {
        if (binding.value && binding.value.showDash === false) {
            return;
        }

        if (binding.value && binding.value.onlyLoadVisitScript) {
            return;
        }
        if (VITE_APP_MODE !== 'visitor') {
            updateDashFrame(el, binding.value);
        }
    },
};

function updateDashFrame(elem, data) {
    if (data.editModuleActive) {
        createModuleFrame(elem, true);
    } else {
        removeActiveDashFrame(elem);
    }
}

function removeActiveDashFrame(elem) {
    var objId = getElementId(elem);
    if (!objId) {
        return;
    }
    var frameTop = document.getElementById(objId + 'newDashedFrameTop');
    var frameRight = document.getElementById(objId + 'newDashedFrameRight');
    var frameBottom = document.getElementById(objId + 'newDashedFrameBottom');
    var frameLeft = document.getElementById(objId + 'newDashedFrameLeft');
    frameTop && frameTop.remove();
    frameRight && frameRight.remove();
    frameBottom && frameBottom.remove();
    frameLeft && frameLeft.remove();
}
