import PopupZone from './PopupZone';
import { EDIT } from './constant';

import { ing, successHandle } from '@/shared/fai';
import { addPopupZoneModule, delPopupZone, setPopupZone } from '@/api/popup-zone';
import { logDog } from '@/shared/log';
import { getPopupZoneContent, getInModuleContent } from './util';

function copyDataToMobi(pZone) {
    Mobi._popupZone.pZone = pZone;
}

function removeZoneDom() {
    if (PZ.pZone) {
        let { moduleId } = PZ.pZone;
        PZ.pZone && PZ.pZone.close && PZ.pZone.close();
        $('.jz_popupZone_wrap').remove();
        $(`#module${moduleId}`).remove();
    }
}

function editAnimate() {
    var $mobiModule = $('.add-panel'), // #mobiModule -> .add-panel
        $g_body = $('#g_body'),
        leftSide = $('#panes .leftSide');

    $mobiModule.addClass('jz_pZoneSetting jz_pZoneSettingAnimate').on('animationend', function () {
        $mobiModule.removeClass('jz_pZoneSetting jz_pZoneSettingAnimate');
    });
    if (window.$store.getters['app/pageTemplateOpen']) {
        // 链接控件进入编辑态动画
        leftSide.addClass('jz_pZoneSetting');
        leftSide.addClass('jz_pZoneSettingAnimate').on('animationend', function () {
            leftSide.removeClass('jz_pZoneSettingAnimate jz_pZoneSetting');
        });
    }

    $g_body.addClass('jz_zone_animate').on('animationend', function () {
        $g_body.removeClass('jz_zone_animate');
    });
}

export const PZ = {
    pZone: {},
    allPopupZone: [],
    open(moduleId) {
        removeZoneDom();
        let pZone = new PopupZone(moduleId);
        if (VITE_APP_MODE !== 'visitor') {
            pZone.preview();
        } else {
            pZone.readOnly();
        }
        PZ.pZone = pZone;
        copyDataToMobi(pZone);
    },
    closeZone() {
        var pZone = PZ.pZone;
        if (!pZone) return;

        if (VITE_APP_MODE !== 'visitor') {
            if (pZone && pZone.enterState == 'edit') return;
            pZone._stateClassDeal();
        }

        removeZoneDom();
        PZ.pZone = null;
        copyDataToMobi(PZ.pZone);
    },
    addNewZone(popupZoneList, linkWinId) {
        removeZoneDom();
        popupZoneList = popupZoneList || [];
        let popupZoneCount = popupZoneList.length;
        if (popupZoneCount >= 1000) {
            ing('已超出弹窗容器限制个数（1000）！', false);
            return;
        }
        let index = popupZoneCount + 1;
        let length = popupZoneList.length;
        if (length > 0 && popupZoneList[length - 1].label == '未命名弹窗' + index) {
            index++;
        }

        // 存储当前打开的面板类型 - 由于新建弹窗后面会把面板关闭了，故存储起来供关闭弹窗时重新打开面板(链接控件)
        if (linkWinId) {
            window.linkWinTempDesigner = JSON.parse(JSON.stringify(window.$store.state.manage.designer));
        }

        let label = `未命名弹窗${index}`;
        ing('正在添加...');
        addPopupZoneModule({
            style: 62,
            colId: window._colId,
            extId: window._extId,
            name: label,
        })
            .catch(() => {
                ing('添加弹窗失败！', false);
            })
            .then((res) => {
                if (successHandle(res, '', '系统错误', '', 3, 1)) {
                    let pZone = new PopupZone(res.id);
                    removeZoneDom();
                    pZone.initCreateData(res.moduleInfo, res.inModuleAttr, res.inZoneModuleInfoList);
                    PZ.pZone = pZone;
                    PZ.editZone(res.id, null, linkWinId, true);
                    PZ.allPopupZone.push({
                        key: res.id,
                        label: res.moduleInfo.name,
                        moduleInfo: res.moduleInfo,
                    });
                    copyDataToMobi(pZone);
                }
            });
    },
    // eslint-disable-next-line no-unused-vars
    editZone(moduleId, dom = null, linkWinId, create = false) {
        // 关闭面板
        Vue.prototype.$designer.close();
        let floatZoneIsEdit = window.floatZone && window.floatZone.isEdit;
        // 存储当前打开的面板类型 - 由于新建弹窗后面会把面板关闭了，故存储起来供关闭弹窗时重新打开面板(链接控件)
        if (linkWinId) {
            let designer = window.$store.state.manage.designer;
            if (designer && designer.moduleId) {
                window.linkWinTempDesigner = JSON.parse(JSON.stringify(window.$store.state.manage.designer));
            }
        }
        if (floatZoneIsEdit) {
            ing('悬浮设计态暂不支持编辑弹窗', false);
            return;
        }
        if (!create) {
            removeZoneDom();
            let pZone = new PopupZone(moduleId);
            PZ.pZone = pZone;
            copyDataToMobi(pZone);
        }

        if (linkWinId) {
            PZ.formLinkWin = linkWinId;
            // 链接控件进入
            PZ.cashLinkWFuc();
            PZ.hideLinkWin(); // 隐藏链接控件和常用模块工具栏

            // 下面是左侧面板动画
            // // 链接控件进入编辑态动画
            // mobiModule.addClass('jz_pZoneSetting');
            // mobiModule
            //     .addClass('jz_pZoneSettingAnimate')
            //     .on('animationend', function () {
            //         $('#mobiModule').removeClass(
            //             'jz_pZoneSettingAnimate jz_pZoneSetting'
            //         );
            //     });
            // if (window.$store.getters["app/pageTemplateOpen"]) {
            //     // 链接控件进入编辑态动画
            //     leftSide.addClass('jz_pZoneSetting');
            //     leftSide
            //         .addClass('jz_pZoneSettingAnimate')
            //         .on('animationend', function () {
            //             leftSide.removeClass(
            //                 'jz_pZoneSettingAnimate jz_pZoneSetting'
            //             );
            //         });
            // }
        }
        editAnimate();
        PZ.pZone.edit(create);
    },
    delPopupZone(moduleId, fromLinkWinId) {
        var idList = JSON.stringify([moduleId]);
        new Vue().$createDialog({
            confirmButton: { text: '确 定' },
            cancelButton: { text: '取 消' },
            content: '删除该弹窗会影响到所有链接了该<br/>弹窗的模块，确定要删除吗？',
            onConfirm() {
                //刷新下拉菜单
                delPopupZone(idList).then((data) => {
                    if (data.success) {
                        if (Mobi._popupZone.allPopupZone) {
                            for (var j = 0; j < idList.length; j++) {
                                for (var i = Mobi._popupZone.allPopupZone.length - 1; i >= 0; i--) {
                                    if (Mobi._popupZone.allPopupZone[i].key == idList[j]) {
                                        Mobi._popupZone.allPopupZone.splice(i, 1);
                                        break;
                                    }
                                }
                            }
                        }

                        $('#module' + moduleId).remove();
                        $('#popupWindowIframe' + fromLinkWinId)[0].contentWindow.refreshPopupZoneList();
                        logDog(200646, 3);
                    }
                });
            },
        });
    },
    selectZone(moduleId) {
        removeZoneDom();
        let pZone = new PopupZone(moduleId);
        PZ.pZone = pZone;
        copyDataToMobi(pZone);
        pZone.preview();
    },
    cancel() {},
    exitEdit() {
        window.$store.dispatch('editMode/exitEditMode');
        window.$store.dispatch('manage/moduleAdd/changeInContainerId', -1); // 添加完重置添加面板的"添加到容器"的状态
        editAnimate();
        if (PZ.formLinkWin) {
            // 打开上次存储的面板
            if (window.linkWinTempDesigner) {
                Vue.prototype.$designer.open(window.linkWinTempDesigner);
                window.linkWinTempDesigner = null;
            }
            PZ.pZone.enterState = '';
            PZ.pZone._stateClassDeal();
            PZ.pZone.close();
            PZ.showLinkWin(); // 恢复中间面板控件状态
            // this.documentScrollBack();
            $('#popupWindowIframe' + PZ.formLinkWin)[0].contentWindow.refreshPopupZoneList(); // 刷新控件下拉选项
            PZ.formLinkWin = null;
            PZ.cashLinkWin = null;
        } else {
            PZ.pZone.exitEdit();
            PZ.pZone.preview();
        }
    },
    save() {
        var pZone = Mobi._popupZone.pZone,
            moduleId = pZone.moduleId,
            content = getPopupZoneContent(pZone),
            inModuleList = getInModuleContent(pZone),
            name = pZone.moduleInfo.name,
            clickNotClose = pZone.clickToClose ? false : true;

        let delModuleList = window.$store.state.editMode.delModules;

        pZone._removePopupZoneExtraEditor();
        logDog(200646, 10);
        ing('正在保存……', false);
        setPopupZone({
            flag1: clickNotClose,
            name: name,
            content: JSON.stringify(content),
            delModuleIdList: JSON.stringify(delModuleList),
            inModuleList: JSON.stringify(inModuleList),
            _fresh: false,
            id: moduleId,
            style: 62,
            colId: window._colId,
            extId: window._extId,
        })
            .catch(function () {
                ing('服务繁忙，请稍候重试', false);
            })
            .then(function (res) {
                if (successHandle(res, '保存成功', '系统错误', '', 3, 1)) {
                    // 打log
                    pZone.nameChange && logDog(200646, 8);
                    pZone.banClickToCloseChange && logDog(200646, 9);
                    pZone.nameChange = false;
                    pZone.banClickToCloseChange = false;
                    window.$store.dispatch('editMode/deactiveBtn');
                }
            });
    },
    cashLinkWFuc() {
        if (!PZ.formLinkWin) return;

        var popupBg = Fai.top.$('.J_popup_bg'), // 链接控件背景
            popupWindow = Fai.top.$('.fk-popupWindowVT'), // 链接控件
            editor = Fai.top.$('.J_fkEditor_tool'), // 常用模块工具条
            editorMask = Fai.top.$('.moduleMaskContainer'), // 常用模块工具条遮罩
            moveFrame = $('.moveFrame'),
            formDialog = Fai.top.$('.formDialog'),
            topbar = Fai.top.$('.toolbarArea'); // topbar
        let modalDom = $('.fa-modal-root');
        let simpleTextToolBar = $('.fk-moduleLayer-v2'); // 文本模块toolBar
        let simpleTextPanel = $('.fkEditor-linkSlideBar'); // 文本模块toolBar上的链接控件

        PZ.cashLinkWin = [
            { dom: editor, cmd: 'fade' },
            { dom: editorMask, cmd: 'fade' },
            { dom: popupBg, cmd: 'fade' },
            { dom: popupWindow, cmd: 'fade' },
            { dom: topbar, cmd: 'hide' },
            { dom: moveFrame, cmd: 'hide' },
            { dom: formDialog, cmd: 'fade' },
            { dom: modalDom, cmd: 'hide' },
            { dom: simpleTextToolBar, cmd: 'hide' },
            { dom: simpleTextPanel, cmd: 'hide' },
        ];
    },
    // 进入编辑态，隐藏中间面板原来的控件（如：链接控件、常用模块工具栏）
    hideLinkWin() {
        if (!PZ.formLinkWin || !PZ.cashLinkWin) return;

        for (var i = 0; i < PZ.cashLinkWin.length; i++) {
            var item = PZ.cashLinkWin[i];

            if (item.dom.length < 1) continue;

            item.dom.each(function (index, el) {
                var $el = $(el);
                if (item.cmd == 'fade') {
                    $el.removeClass('popup_zone_eidt_exit_window_animate popup_zone_eidt_exit_animate');

                    var animateClass = $el.hasClass('popupBg')
                        ? 'popup_zone_eidt_enter_window_animate'
                        : 'popup_zone_eidt_enter_animate';

                    $el.addClass(animateClass);

                    $el.off('animationend.zone').on('animationend.zone', function () {
                        $el.addClass('popup_zone_eidt_mask_out');
                    });
                } else if (item.cmd == 'hide') {
                    $el.hide();
                }
            });
        }
    },
    showLinkWin() {
        for (var i = 0; i < PZ.cashLinkWin.length; i++) {
            var item = PZ.cashLinkWin[i];
            if (item.dom.length < 1) continue;

            item.dom.each(function (index, el) {
                var $el = $(el);
                if (item.cmd == 'fade') {
                    var animateClass = $el.hasClass('popupBg')
                        ? 'popup_zone_eidt_exit_window_animate'
                        : 'popup_zone_eidt_exit_animate';

                    $el.removeClass(
                        'popup_zone_eidt_mask_out popup_zone_eidt_enter_animate popup_zone_eidt_enter_window_animate'
                    );

                    $el.addClass(animateClass);

                    $el.off('animationend.zone');
                } else if (item.cmd == 'hide') {
                    $el.show();
                }
            });
        }
    },
};

export const getPopupZone = () => {
    return PZ.pZone;
};

export const popupZoneIsEdit = () => {
    let pZone = getPopupZone();
    return pZone && pZone.enterState == EDIT;
};
