<template>
    <a :href="imgHref" hidefocus="true" :style="cartItemLinkStyle" :onclick="jumpOnclickStr">
        <div
            v-drag-resize="_resizeOptions"
            class="J_imgContainer f-imgContainer J_img_lazyload"
            :src-original="photoItem.picThumbPath"
            :style="cartItemImgStyle1"
            :data-picId="photoItem.picId"
            @click="photoSlidesshow"
        ></div>
        <div :style="cartTextStyle">
            <div v-show="showTitle" :style="cartNameStyle" v-html="titleData"></div>
            <div v-show="showDesc" :style="cartDescStyle" v-html="descData"></div>
        </div>
    </a>
</template>
<script>
import { photoMixins } from '../../mixins';
import { photoImgScaleStyleMap, photoTitleStyleMap } from '../../utils';
export default {
    name: 'CartItem',
    mixins: [photoMixins],
    computed: {
        nameAndDescIsHidden() {
            return !this.showTitle && !this.showDesc;
        },
        isNewModule() {
            return this.moduleContent.inm;
        },
        cartItemLinkStyle() {
            let style = {};
            if (this.linkType == 0) {
                style['cursor'] = 'default';
            }
            if (!this.isNewModule) {
                style['display'] = 'block';
            }
            // style['display'] = 'block';
            return style;
        },
        cartTextStyle() {
            let style = {};
            if (this.isNewModule == 0) {
                style['margin-left'] = '0.75rem';
                style['margin-right'] = '0.75rem';
                style['padding-top'] = '0.75rem';
                style['padding-bottom'] = '0.75rem';
            } else {
                style['margin-left'] = '0.375rem';
                style['margin-right'] = '0.375rem';
                if (this.textStyleType == 3 && this.isNewModule == 1) {
                    style['margin-left'] = '0.125rem';
                    style['margin-right'] = '0.125rem';
                }

                if (!this.nameAndDescIsHidden) {
                    style['padding-top'] = '0.6rem';
                    style['padding-bottom'] = '0.6rem';
                }
                style['box-sizing'] = 'border-box';
                if (this.isEmpty) {
                    style['display'] = 'none';
                }
            }
            style = { ...style, ...this.picLinkStyle };
            // Object.assign(style, this.picLinkStyle);

            return style;
        },
        cartNameStyle() {
            let style = {};
            let photoTitleStyle = photoTitleStyleMap[this.columnsType] || {};
            let photoTitleWrapStyle = this.wrapStyle(this.titleIsWrap, this.moduleStyle) || {};
            let photoTitleTextLinkStyle = this.textLinkStyle(this.titleLink) || {};
            // Object.assign(style, photoTitleStyleMap[this.columnsType]);
            // Object.assign(style, this.wrapStyle(this.titleIsWrap, this.moduleStyle));
            // Object.assign(style, this.textLinkStyle(this.titleLink));
            style = {
                ...photoTitleStyle,
                ...photoTitleWrapStyle,
                ...photoTitleTextLinkStyle,
            };

            if (this.textStyleType == 3) {
                style['padding-left'] = '0.125rem';
                style['padding-right'] = '0.125rem';
            }
            if (this.titleLink.type == 0) {
                style['color'] = '#222';
            }
            style['text-align'] = this.textAlignStyle;
            if (this.showDesc) style['margin-bottom'] = '0.35rem';
            return style;
        },
        cartDescStyle() {
            let style = {};
            let descWrapStyle = this.wrapStyle(this.descIsWrap, this.moduleStyle) || {};
            let descTextLinkStyle = this.textLinkStyle(this.nameLink) || {};
            style = { ...descWrapStyle, ...descTextLinkStyle };
            // Object.assign(style, this.wrapStyle(this.descIsWrap, this.moduleStyle));
            // Object.assign(style, this.textLinkStyle(this.nameLink));
            if (this.nameLink.type == 0) {
                style['font-size'] = '0.55rem';
                style['line-height'] = '0.75rem';
                style['color'] = '#666';
                if (this.isNewModule == 0) {
                    style['font-size'] == '0.6rem';
                }
            }

            if (this.textStyleType == 3) {
                style['padding-left'] = '0.125rem';
                style['padding-right'] = this.showTitle ? '0.5rem' : '0.375rem';
                style['margin-bottom'] = '0';
            }
            style['text-align'] = this.textAlignStyle;

            return style;
        },
        cartItemImgStyle1() {
            let style = {};
            style['background-image'] = `url(${this.noLazyLoad ? this.photoItem.picThumbPath : this.loadingPath})`;
            // Object.assign(style, photoImgScaleStyleMap[this.picScaleType]);
            let photoImgScaleStyle = photoImgScaleStyleMap[this.picScaleType] || {};
            style = { ...style, ...photoImgScaleStyle };
            if (this.isNewModule == 0) {
                style['background-size'] = 'cover';
                style['background-position'] = 'center';
            }
            // 设置图片高度
            if (this.picSize.htype == 0) {
                style['height'] = this.isNewModule == 1 ? '10.15rem' : '11.25rem';
            } else {
                style['height'] = this.picSize.rh + 'rem';
            }

            style['width'] = '100%';
            return style;
        },
        borderRadiusSize() {
            return this.photoRadius.type == 1 ? this.photoRadius.size / this.htmlFontSize : 0.2;
        },
    },
};
</script>
