import { encodeHtml, loadFile } from '@/shared/util';
import jzFdpLogIns from '@/shared/fdpForJz';
import { logDog } from '@/shared/log';
import { MODULE_STYLE } from '@/def/module';
import { ing } from '@/shared/fai';

const manageMode = VITE_APP_MODE !== 'visitor';
let bMapCache = {};
let mapModuleInit = [];
let googleMapList = []; //需要初始化的谷歌地图列表，api加载成功再渲染谷歌地图
let gMapList = [];
let isFinishLoad = false;
let hasLoadBDMapScript = false;
let hasLoadGoogleApiSuccess = false; //判断是否加载谷歌地图api成功
let LoadingGoogleApi = false; //判断是否正在加载谷歌地图api
if (typeof window != 'undefined') {
    window.mapApi = window.mapApi || {}; // 全局函数。
}

// 初始化百度地图
const initBMap = function (mapContainerId, module) {
    let options = { enableMapClick: !manageMode };
    let map = new BMap.Map(mapContainerId, options);
    bMapCache[mapContainerId] = {
        map,
    };
    //百度地图缩放工具  ----鱼骨
    let bMapNavigation = new BMap.NavigationControl();
    map.addControl(bMapNavigation);

    var container = document.getElementById(mapContainerId);
    //管理台阻止拖拽和点击事件，防止触发模块拖拽和模块编辑
    if (manageMode) {
        jm(container).on('mousedown', function (e) {
            e.stopPropagation();
        });
    }

    map.addEventListener('click', function (e) {
        if (!manageMode) {
            return;
        } else {
            const isActivatedEditPanel =
                Fai.top.vue_utils.findComponentsDownward(Fai.top.mobiDesigner, 'jz-onlineMap-edit-item').length === 0 ||
                Fai.top.vue_utils.findComponentsDownward(Fai.top.mobiDesigner, 'jz-onlineMap-edit-item')[0]._inactive;
            if (isActivatedEditPanel) {
                return;
            }
        }
        e.domEvent.stopPropagation();
        // 访客态禁止点击改变标记

        // 取出当前点击坐标
        let {
            point: { lng: x, lat: y },
        } = e;
        let point = { x, y };

        // 设置到此次编辑的对象上
        let { itemId } = window.$store.state.manage.curOnlineMapItemEdit;
        let { locationList } = module.content;
        let location = locationList.find((location) => location.id === itemId);
        location.bmp = point;

        // 重新渲染标注
        repaintMapIcon(mapContainerId, locationList, module, true);
    });

    jzFdpLogIns.logJzFdp('jz_baidu_maps', {
        jz_content_terminal: 'mobi',
        jz_version: Fai.top.jzVersion,
        jz_free_bool1: !manageMode,
    });

    return map;
};

const getBMapInfoById = function (mapContainerId) {
    // 单例
    if (!bMapCache[mapContainerId]) {
        initBMap(mapContainerId);
    }

    return bMapCache[mapContainerId];
};

// 百度地图搜索
export const bMapSearch = function (mapContainerId, module, province, address) {
    // promise封装返回结果，反转控制
    return new Promise((resolve, reject) => {
        let options = {
            onSearchComplete(results) {
                if (ls.getStatus() == window.BMAP_STATUS_SUCCESS) {
                    if (results.getCurrentNumPois() <= 0) {
                        reject();
                    } else {
                        let poi = results.getPoi(0);
                        //返回POI信息
                        resolve(poi);
                    }
                } else {
                    reject();
                }
            },
        };

        let ls = new BMap.LocalSearch(province, options);
        ls.search(province + address);
    });
};

const genBMapIcon = function (mapContainerId, point) {
    let { map } = getBMapInfoById(mapContainerId);
    let myIcon = new BMap.Icon(
        Fai.top._resRoot + '/image/onlineMap/marker_red_sprite.png?v=201809101152',
        new BMap.Size(22, 25),
        {
            anchor: new BMap.Size(10, 25),
        }
    );
    // 转换为百度坐标，必须
    point = new BMap.Point(point.lng, point.lat);

    let marker = new BMap.Marker(point, {
        icon: myIcon,
    });
    map.addOverlay(marker);
    return marker;
};

export const repaintMapIcon = function (mapContainerId, locationList, module, isFormEdit) {
    let { map } = getBMapInfoById(mapContainerId);
    let bmpPoints = [];
    let ComplexCustomOverlay = addComplexCustomOverlay(BMap);
    let myCompOverlays = [];
    // 删除地图上该标注
    map.clearOverlays();

    locationList.forEach((location, index) => {
        let {
            bmp: { x, y },
        } = location;
        let point = { lng: x, lat: y };
        let bPoint = new BMap.Point(point.lng, point.lat);

        bmpPoints.push(bPoint);

        // 生成marker并绑定事件
        let marker = genBMapIcon(mapContainerId, point);

        // 根据条件生成DOM
        const isNewModule = module.content.isNew;
        const defaultName = '新增地址';
        const newModuleIsShowName = isNewModule && location.name; // 名称非空
        const oldModuleIsShowName = !isNewModule && location.name !== '' && location.name !== defaultName; // 默认名称
        const isShowName = newModuleIsShowName || oldModuleIsShowName;

        let nameDOM = `<div class="name">${encodeHtml(location.name)}</div>`;
        let detailDOM = `<div class="address">${LS.js_onlineMapAddressTip}：${encodeHtml(location.detail)}</div>`;
        let telDOM = ` <div class="tel">${LS.js_onlineMapPhoneTip}：${encodeHtml(location.tel)}</div>`;
        let otherDOM = ` <div class="other">${encodeHtml(location.other)}</div>`;
        // 生成对话框DOM
        let content = `
            ${isShowName ? nameDOM : ''}
            ${location.detail ? detailDOM : ''}
            ${location.tel ? telDOM : ''}
            ${location.other ? otherDOM : ''}
        `;

        let myCompOverlay = new ComplexCustomOverlay({
            point,
            content: '<div class="msg">' + content + '</div>',
            boxClass: 'msgBox',
            arrowClass: 'arrow',
            zIndex: 9,
            offset: {
                x: 0,
                y: -35,
            },
        });

        myCompOverlays[index] = myCompOverlay;

        // 每次点击将当前的信息框清除，在点击的标注上方新加信息框
        marker.addEventListener('click', function (e) {
            // 阻止事件冒泡
            e.domEvent.stopPropagation();
            // 绘制信息框并将地图中心移至此位置
            myCompOverlays.forEach((_, i) => {
                map.removeOverlay(myCompOverlays[i]);
            });
            map.panTo(bPoint);
            map.addOverlay(myCompOverlay);

            // 由于没有id，所以持有当前位置对象，去寻找其最新数组的索引，编辑其对象
            myCompOverlay.addEventListener('click', function (e) {
                e.stopPropagation();
                openOnlineMapEditPanel(module.id, location.id);
            });
        });
    });
    // 从Vuex取出当前编辑的项
    if (isFormEdit) {
        let { moduleId, itemId } = window.$store.state.manage.curOnlineMapItemEdit;
        let index = locationList.findIndex((location) => location.id === itemId);
        let location = locationList.find((location) => location.id === itemId);
        let {
            bmp: { x, y },
        } = location;
        let bPoint = new BMap.Point(x, y);
        map.addOverlay(myCompOverlays[index]);
        // 将此点移至地图中心
        map.panTo(bPoint);
        myCompOverlays[index].addEventListener('click', function (e) {
            e.stopPropagation();
            openOnlineMapEditPanel(moduleId, itemId);
        });
    }

    // 编辑状态不重新缩放地图
    if (!isFormEdit) {
        // 编辑状态默认显示第一个标注
        let myCompOverlay = myCompOverlays[0];
        map.addOverlay(myCompOverlay);
        myCompOverlay.addEventListener('click', function (e) {
            e.stopPropagation();
            // itemId
            let location = locationList[0];
            let { id: itemId } = location;
            openOnlineMapEditPanel(module.id, itemId);
        });

        // // 缩放视图直至显示所有标注
        let view = map.getViewport(bmpPoints);
        let { center, zoom } = view;
        map.centerAndZoom(center, zoom);
    }
};

export const getBMAPCurPositionPoint = function () {
    let geolocation = new BMap.Geolocation();
    let p = new Promise((resolve, reject) => {
        geolocation.getCurrentPosition(function (r) {
            if (this.getStatus() == window.BMAP_STATUS_SUCCESS) {
                resolve({ x: r.point.lng, y: r.point.lat });
            } else {
                reject();
            }
        });
    });
    return p;
};

export const getBMAPtoPointDistance = function (sPoint, ePoint, mapContainerId) {
    sPoint = new BMap.Point(sPoint.x, sPoint.y);
    ePoint = new BMap.Point(ePoint.x, ePoint.y);
    let { map } = getBMapInfoById(mapContainerId);
    let distance = String(map.getDistance(sPoint, ePoint));
    return distance;
};

export const deleteLocationById = function (moduleId, id) {
    logDog(200648, 8);
    // 删除该项
    let module = window.$store.state.currentPageModuleIdMap[moduleId];
    let locationList = module.content.locationList;
    let index = locationList.findIndex((location) => location.id === id);
    locationList.splice(index, 1);
    // 标记为已经编辑
    module.content.isEdit = true;
};

export const sortListByDistanceASC = function (arr) {
    return arr.sort((locationA, locationB) => {
        return Number(locationA.distance) - Number(locationB.distance);
    });
};

export const addComplexCustomOverlay = function (BMap) {
    //自定义overlay，用于替代信息框，其实就等于自定义信息框  --------------zhj
    /*--自定义覆盖物核心代码------------------------------------------------------------------------------------*/
    function ComplexCustomOverlay(param) {
        this.point = param.point; //覆盖物坐标点
        this.content = param.content || ''; //覆盖内容
        this.zIndex = param.zIndex || 1; //覆盖物内容层级
        this.boxClass = param.boxClass || ''; //覆盖物容器class名称
        this.boxStyle = cssText(param.boxStyle); //覆盖物容器样式
        this.arrowClass = param.arrowClass || ''; //覆盖物箭头class名称
        this.arrowStyle = cssText(param.arrowStyle); //覆盖物箭头样式
        this.offset = param.offset || { x: 0, y: 0 }; //覆盖物的偏移量
    }

    //把百度地图遮盖物对象赋值给构造函数原型对象
    ComplexCustomOverlay.prototype = new BMap.Overlay();

    //初始化创建覆盖物
    ComplexCustomOverlay.prototype.initialize = function (map) {
        this.map = map;
        this.box = document.createElement('div');
        this.box.className = this.boxClass;
        this.box.style.cssText = this.boxStyle;
        this.box.innerHTML = this.content;

        this.close = document.createElement('div');
        this.close.className = 'close';

        this.arrow = document.createElement('div');
        this.arrow.className = this.arrowClass;
        this.arrow.style.cssText = this.arrowStyle;
        this.box.appendChild(this.arrow);
        this.box.appendChild(this.close);

        //这个方法是把定义好的盒子 添加到文本标注所在的容器
        map.getPanes().labelPane.appendChild(this.box);

        this.initDefaultEvent(map);
        return this.box;
    };

    ComplexCustomOverlay.prototype.addEventListener = function (event, fun) {
        this.box.addEventListener(event, fun);
    };

    ComplexCustomOverlay.prototype.initDefaultEvent = function (map) {
        var self = this;

        //阻止默认事件
        this.addEventListener('mousedown', function (e) {
            e.stopPropagation();
        });
        this.addEventListener('click', function (e) {
            e.stopPropagation();
        });
        this.addEventListener('touchmove', function (e) {
            e.stopPropagation();
        });

        this.addEventListener('touchstart', function (e) {
            if (e.target.getAttribute('class').indexOf('close') != -1) {
                map.removeOverlay(self);
            }
        });

        this.addEventListener('mousedown', function (e) {
            if (e.target.getAttribute('class').indexOf('close') != -1) {
                map.removeOverlay(self);
            }
        });
    };

    //地图拖动调用事件
    ComplexCustomOverlay.prototype.draw = function () {
        mapDraw(this);
    };

    ComplexCustomOverlay.prototype.hide = function () {
        this.box.style.display = 'none';
    };

    ComplexCustomOverlay.prototype.reDraw = function () {
        mapDraw(this);
    };

    //计算覆盖物在地图上的位置
    function mapDraw(_this) {
        var pixel = _this.map.pointToOverlayPixel(_this.point);
        _this.box.style.left =
            pixel.x -
            parseInt(getStyle(_this.box, 'width')) / 2 -
            (parseInt(getStyle(_this.box, 'padding-left')) + parseInt(getStyle(_this.box, 'padding-right'))) / 2 +
            _this.offset.x +
            'px';
        _this.box.style.bottom = -pixel.y + parseInt(getStyle(_this.arrow, 'height')) - _this.offset.y + 'px';

        _this.arrow.style.left = parseInt(getStyle(_this.box, 'width')) / 2 + 9 + 'px';
    }
    /*--自定义核心代码结束------------------------------------------------------------------------------------*/

    /*--下面为辅助代码------------------------------------------------------------------------------------*/

    //获取元素样式
    function getStyle(node, attr) {
        if (typeof getComputedStyle != 'undefined') {
            var value = getComputedStyle(node, null).getPropertyValue(attr);
            return attr == 'opacity' ? value * 100 : value; //兼容不透明度，如果是不透明度，则返回整数方便计算
        } else if (typeof node.currentStyle != 'undefined') {
            //如果是IE
            if (attr == 'opacity') {
                //兼容不透明度
                return Number(node.currentStyle.getAttribute('filter').match(/(?:opacity[=:])(\d+)/)[1]);
            } else {
                return node.currentStyle.getAttribute(attr);
            }
        }
    }

    //css样式字符串处理
    function cssText(style) {
        var css = '';
        if (typeof style == 'string') {
            css = style;
        } else if (typeof style == 'object') {
            for (var i in style) {
                if (Object.hasOwnProperty.call(style, i)) {
                    if (Object.hasOwnProperty.call()) {
                        css += i + ':' + style[i] + ';';
                    }
                }
            }
            return css;
        }
    }

    return ComplexCustomOverlay;
};

export const addMapModuleInit = function (options) {
    const moduleId = options.id;
    const initFn = options.fn;
    mapModuleInit.push({
        moduleId,
        initFn,
    });
};

export const loadBDMapScript = function () {
    if (hasLoadBDMapScript || typeof document == 'undefined') {
        return;
    }
    let script = document.createElement('script');
    script.src = '//api.map.baidu.com/api?v=2.0&ak=KfNSLxuGpyk9BBYHnSqZsYoKxn7MUGxX&callback=mapApi.runMapModuleInit';
    document.body.appendChild(script);
    hasLoadBDMapScript = true;
};

export const runMapModuleInit = function () {
    isFinishLoad = true;
    mapModuleInit.forEach((item) => {
        item.initFn && item.initFn();
    });
};
if (typeof window != 'undefined') {
    window.mapApi.runMapModuleInit = runMapModuleInit;
}

export const bdMapIsLoad = function () {
    return isFinishLoad;
};

export const bindEvent = function (context) {
    var content = context.module.content;
    var mapType = content.mt;
    if (mapType === 0) {
        initBMap('mapContainer' + context.id, context.module);
    } else if (mapType === 1) {
        initGoogleMap('mapContainer' + context.id, context.module.content.gmp, context.mapInfo, context.module);
    }
};

const initGoogleMap = function (mapContainerId, googleMapPos, noteInfo, module) {
    try {
        let G = window.google;
        if (!G || !G.maps || !G.maps.LatLng) {
            var googleMapData = {
                id: mapContainerId,
                googleMapPos,
                googleNote: noteInfo,
                module,
            };
            //没有加载api成功的时候，把数据加进列表
            googleMapList.push(googleMapData);
            loadGoogleAPi();
            return;
        } else {
            //获取承载谷歌地图的容器
            var container = document.getElementById(mapContainerId);
            var myLatLng = new G.maps.LatLng(googleMapPos.x, googleMapPos.y);
            var myOverLatLng = new G.maps.LatLng(googleMapPos.ox, googleMapPos.oy);
            //创建谷歌地图
            var googleMap = new G.maps.Map(container, {
                draggable: true,
                scrollwheel: false,
                zoom: googleMapPos.z,
                streetViewControl: false,
                center: myOverLatLng,
                scaleControl: true,
                mapTypeId: G.maps.MapTypeId.ROADMAP,
                clickableIcons: manageMode ? false : true, //管理态点击是移动标记，走的逻辑是initGoogleEvent，访客态点击是查看信息
            });
            //创建标记
            var markerImage = {
                url: Fai.top._resRoot + '/image/onlineMap/marker_red_sprite.png?v=201809101152',
                size: new G.maps.Size(22, 25), //定义坐标的宽高
                anchor: new G.maps.Point(10, 25), //调整中心点的位置
                scaledSize: new G.maps.Size(22, 25), //定义图片的宽高
            };

            //管理台阻止拖拽事件
            if (manageMode) {
                jm(container).on('mousedown', function (e) {
                    e.stopPropagation();
                });
            }

            var marker = new G.maps.Marker({
                map: googleMap,
                position: myLatLng,
                animation: G.maps.Animation.DROP,
                icon: markerImage,
            });
            marker.setMap(googleMap);
            var infoWin = new G.maps.InfoWindow({
                content: noteInfo,
                width: 'auto',
                height: 'auto',
            });
            infoWin.open(googleMap, marker);

            // 初始化标记框
            G.maps.event.addListener(marker, 'click', function () {
                var infoWin = new G.maps.InfoWindow({
                    content: noteInfo,
                    width: 'auto',
                    height: 'auto',
                });
                infoWin.open(googleMap, marker);
            });

            var gmapObj = {
                googleMap,
                marker,
                infoWin,
            };
            gMapList[mapContainerId] = gmapObj;
            if (manageMode) {
                initGoogleEvent(googleMap, marker, infoWin, mapContainerId, module); // 点击改变标记，访客态不可点击改变标记
            }

            jzFdpLogIns.logJzFdp('jz_google_maps', {
                jz_content_terminal: 'mobi', // 内容终端 mobi
                free_bool_1: false, //是否在弹窗内 否
            });
        }
    } catch (err) {
        loadMapFailure(mapContainerId, err.name);
        console.log(err);
    }
};

//加载谷歌地图的api，同时初始化谷歌地图
const loadGoogleAPi = function () {
    //正在加载api或者加载api成功时候，不需要重复加载，节流
    if (LoadingGoogleApi || hasLoadGoogleApiSuccess) {
        return;
    }
    LoadingGoogleApi = true;
    //判断文档加载的情况
    var documentState = document.readyState;

    //创建新的地图的时候，直接重现加载api
    if (documentState == 'complete') {
        loadApiAndInit();
    } else {
        jm(window).on('load', function () {
            loadApiAndInit();
        });
    }

    function loadApiAndInit() {
        loadFile(
            document,
            {
                src: '//maps.googleapis.com/maps/api/js?key=AIzaSyCQ8IYEmOy-pXjctSJAYGQsV997OgeXLzU',
                tag: 'script',
                type: 'text/javascript',
                defer: 'defer',
            },
            function () {
                if (googleMapList.length > 0) {
                    initGoogleMapList();
                }
                hasLoadGoogleApiSuccess = true;
                LoadingGoogleApi = false;
                logDog(201508, 2); // 谷歌地图调用情况
            },
            function () {
                if (googleMapList.length > 0) {
                    initGoogleMapFailure();
                }
                LoadingGoogleApi = false;
            }
        );
    }
};

const initGoogleMapList = function () {
    // let googleMapList = googleMapList;
    if (googleMapList.length > 0) {
        jm(googleMapList).each(function (i, v) {
            initGoogleMap(v.id, v.googleMapPos, v.googleNote, v.module);
        });
        googleMapList = [];
    }
};

const initGoogleMapFailure = function () {
    // var googleMapList = googleMapList;
    if (googleMapList.length > 0) {
        $(googleMapList).each(function (i, v) {
            loadMapFailure(v.id, '地图加载超时');
        });
        googleMapList = [];
    }
};

export const doGoogleSearch = function (node, module, province, address, mapZoom, isSearch = true) {
    if (province == '') {
        ing('搜索的省市不能为空。', true);
        return;
    }
    if (address == '') {
        ing('搜索的地址不能为空。', true);
        return;
    }
    if (typeof gMapList[node] == 'undefined') {
        initGoogleMap(node, module.content.gmp, module.content.mi, module); //初始化谷歌地图实例
    }

    // 获取实例
    var googleMap = gMapList[node].googleMap,
        marker = gMapList[node].marker;
    var geocoder = new window.google.maps.Geocoder();
    var searchAddress = province + address;
    geocoder.geocode({ address: searchAddress }, function (result, status) {
        if (status == window.google.maps.GeocoderStatus.OK) {
            result = result[0];
            let bounds = result.geometry.viewport;

            googleMap.fitBounds(bounds);
            marker.setPosition(result.geometry.location);
            marker.setTitle(address);
            setGoogleMap(node, module);
            let info = searchAddress + '\n';
            info += '地址：' + result.formatted_address;
            if (module.content.mi == '-' || module.content.mi.trim() == '') {
                module.content.mi = info;
                // 刷新标记
                refreshGoogleWinInfo(node, module, info);
            }
            isSearch && callSearchEnd();
        } else {
            callSearchFail();
            setGoogleDefaultPoint(module);
        }
    });
};

export const loadingMap = function (mapContainerId) {
    var html = "<div class='mapLoadingBox'></div>";
    $('#' + mapContainerId).html(html);
};

export const openOnlineMapEditPanel = function (moduleId, itemId) {
    logDog(200648, 7);
    Vue.prototype.$designer.close();
    window.$store.commit('manage/updateCurOnlineMapItemEdit', {
        moduleId,
        itemId,
    });

    setTimeout(() => {
        Vue.prototype.$designer.open({
            panelType: 'jzOnlineMapEditItem',
            moduleId,
            styleId: MODULE_STYLE.ONLINE_MAP,
        });
    }, 200);
};

export function callSearchStart() {
    ing('搜索中...', true);
}

function loadMapFailure(mapContainerId, errNAME) {
    if ($('#' + mapContainerId).attr('type') == 0) {
        return;
    }
    var html =
        "<div class='mapLoadError'>" +
        "<div class='errorMsgBox'>" +
        "<div class='errName'>" +
        errNAME +
        '</div>' +
        "<div class='errorImg'></div>" +
        "<div class='errorMsg'>地图加载失败</div>" +
        '</div>' +
        '</div>';
    $('#' + mapContainerId).html(html);
}

// 谷歌地图search结果赋值给对应的地图模块
function setGoogleMap(node, module) {
    var googleMap = gMapList[node].googleMap;
    var marker = gMapList[node].marker;
    var position = marker.getPosition();
    var latLng = googleMap.getCenter();

    if (position) {
        module.content.gmp.ox = latLng.lat();
        module.content.gmp.oy = latLng.lng();
        module.content.gmp.x = position.lat();
        module.content.gmp.y = position.lng();
        module.content.gmp.z = googleMap.getZoom();
    } else {
        module.content.gmp.ox = 23.133158856784426;
        module.content.gmp.oy = 113.33254255767213;
        module.content.gmp.x = 23.133158856784426;
        module.content.gmp.y = 113.33254255767213;
        module.content.gmp.z = 17;
    }
}

function initGoogleEvent(googleMap, marker, infoWin, mapContainerId, module) {
    googleMap.addListener('click', function (e) {
        var marker = Fai.top.Fai.gMapList[mapContainerId].marker;

        marker.setPosition(e.latLng);
        setGoogleMap(mapContainerId, module);
    });

    //管理态阻止拖拽事件
    if (manageMode) {
        jm('#' + mapContainerId).on('mousedown', function (e) {
            e.stopPropagation();
        });
    }
}

// 改变谷歌地图标记的信息
function refreshGoogleWinInfo(node, module, Info) {
    var googleMap = gMapList[node].googleMap;
    var marker = gMapList[node].marker;
    var infoWin = gMapList[node].infoWin;
    infoWin.close();
    infoWin = new window.google.maps.InfoWindow({
        content: encodeHtml(Info),
        width: 'auto',
        height: 'auto',
    });
    infoWin.open(googleMap, marker);
    gMapList[node].infoWin = infoWin;
}

function callSearchEnd() {
    ing('搜索结束。', true);
}

function callSearchFail() {
    ing('未能找到搜索的省市地址。');
}

function setGoogleDefaultPoint(module) {
    module.content.gmp.ox = 23.133158856784426;
    module.content.gmp.oy = 113.33254255767213;
    module.content.gmp.x = 23.133158856784426;
    module.content.gmp.y = 113.33254255767213;
    module.content.gmp.z = 17;
}
