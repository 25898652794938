<template>
    <div class="m_mobi_form_upload_wrap">
        <template v-if="!newFileUploadStyle">
            <div class="m_mobi_form_upload_container">
                <input
                    readonly="readonly"
                    type="text"
                    :placeholder="module.extInfo.placeSelectFileUpload"
                    class="m_mobi_form_upload_input m_mobi_form_input_text"
                    :value="file.tmpFileName"
                    :style="_mixinsInputStyle"
                />
                <div ref="uploadBtn" class="m_mobi_form_upload_btn m_mobi_form_input_text" @click.stop="uploadClick">
                    <div class="uploadify-button"></div>
                    <div class="uploadify_button_decorate">{{ module.extInfo.clickToUpload }}</div>
                </div>
            </div>
            <div v-if="!newFileUploadStyle && manageMode && fileNumLimit > 1" class="auth_tips form_upload_tips">
                <span>上传多个文件需</span>
                <span @click="toNewFileUploadStyle">切换新样式</span>
            </div>
        </template>
        <template v-else>
            <file-upload
                ref="fileUpload"
                :content-id="module.id"
                :form-item="formItem"
                :ext-info="extInfo"
                :log-event-config="logEventConfig"
                :initial-value="initialValue"
                @upload-success="uploadSuccess"
                @del-success="delSuccess"
            ></file-upload>
        </template>
    </div>
</template>

<script>
import { mapFlag } from '@/shared/flag';
import Mixins from '../mixins';
import { context } from '@jz/utils';
import FileUpload from '@/modules/commComponent/FileUpload.vue';
import { JZUpload } from '@jz/biz-shared';
import formDetailEditMixin from '../mixins/formDetailEdit.js';

export default {
    name: 'FormUpload',
    components: {
        FileUpload,
    },
    mixins: [Mixins, formDetailEditMixin],
    inject: ['module', 'options'],
    props: {
        formItem: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            extInfo: this.module.extInfo,
            currentValue: '',
            formInfo: this.module.content.fi,
            file: {
                fileId: '',
                tmpFileName: '',
                tmpFileId: '',
                fileType: 0,
                fileWidth: 0,
                fileHeight: 0,
            },
            tmpFileList: [],
            logEventConfig: {
                event: 'jz_hdpzj',
                properties: {
                    jz_content_terminal: 'mobi-在线表单',
                    jz_version: context?.jzVersion ?? 0,
                },
            },
            initialValue: {
                previewFiles: [],
            },
        };
    },
    computed: {
        fileNumLimit() {
            return this.formItem?.fileNumLimit ?? 1;
        },
        newFileUploadStyle: {
            get() {
                return this.isFormPopup ? this.newFormFileUploadStyle : this.newFormModuleFileUploadStyle;
            },
            set(val) {
                if (this.isFormPopup) {
                    this.newFormFileUploadStyle = val;
                } else {
                    this.newFormModuleFileUploadStyle = val;
                }
            },
        },
        ...mapFlag(
            {
                newFormModuleFileUploadStyle: 0x1,
            },
            'module.flag'
        ),
        ...mapFlag(
            {
                newFormFileUploadStyle: 0x4000,
            },
            'formInfo.flag'
        ),
        fileLimitSize() {
            let fileSize = this.formItem.size;
            let fileLimitSize = this.extInfo.fileSizeLimit || 300;

            if (this.extInfo.oem) {
                if (this.extInfo.siteVer <= 120) {
                    fileLimitSize = 10;
                }
            } else {
                if (this.extInfo.siteVer <= 10) {
                    fileLimitSize = 1;
                }
            }

            if (fileSize > fileLimitSize) {
                return fileLimitSize;
            } else {
                return fileSize;
            }
        },
        submitVal() {
            return this.currentValue;
        },
        manageMode() {
            return VITE_APP_MODE !== 'visitor';
        },
        isFormPopup() {
            return this.module.style === 84;
        },
    },
    watch: {
        'formInfo.flag': function (val) {
            if (VITE_APP_MODE !== 'visitor') {
                let moduleContent = this.module.content;
                let formId = moduleContent.f_id;
                Fai.top._formList.forEach((form) => {
                    if (form.id === formId) {
                        form.change = true;
                        form.flag = val;
                    }
                });
                Fai.top._mobiFormChange = true;
                Fai.top._otherChange++;
                this.$designer.styleChanged();
            }
        },
    },
    mounted() {
        !this.newFileUploadStyle && this.initUpload();
        this.setInitialValue();
    },
    methods: {
        uploadSuccess({ currentValue, tmpFileList }) {
            this.currentValue = currentValue;
            this.tmpFileList = tmpFileList;
        },
        initUpload() {
            if (VITE_APP_MODE !== 'visitor') {
                return;
            }
            var isOverLimit = this.extInfo.isOverLimit;
            var This = this;

            var fileSize = this.fileLimitSize;
            var fileTypeList = '*.*';
            if (!this.formItem.isAllFile) {
                //自定义类型
                fileTypeList = ((this.formItem.dftl || '') + (this.formItem.cftl || ''))
                    .split(' ')
                    .filter((formItem) => formItem)
                    .map((formItem) => `*.${formItem}`)
                    .join(';');
            }

            JZUpload.initUploadifyButton({
                dom: this.$refs.uploadBtn,
                buttonText: ' ',
                fileTypeExts: fileTypeList,
                onChange: async (e) => {
                    if (isOverLimit) {
                        This.$mobiIng('已超过资源库容量限制，请升级网站版本。');
                        return false;
                    } else {
                        let file = e.target.files[0];

                        let jzUpload = new JZUpload({
                            getAccessKeyUrl: '/api/manage/advanceUpload/genAccessKey',
                            onError(error) {
                                This.$mobiIng('文件:' + file.name + '，' + error.message);
                                jm(This.$refs.uploadBtn).find('.selectbtn').val('');
                            },
                            // 取决于后台
                            onComplete(data) {
                                This.file.fileId = data.fileId;
                                This.file.tmpFileName = data.name;
                                This.file.tmpFileId = data.id;
                                This.currentValue = data.fileId;
                                This.file.fileType = data.type || 0;
                                This.file.fileWidth = data.width || 0;
                                This.file.fileHeight = data.height || 0;

                                setTimeout(function () {
                                    This.$mobiIng(LS.fileUploadSuccess, true);
                                }, 2500); //刚好移除Mobi.ing()组件
                                jm(This.$refs.uploadBtn).find('.selectbtn').val('');
                            },
                            onNext(info) {
                                const percent = Math.ceil(info.total.percent);
                                if (isNaN(percent)) {
                                    return;
                                }
                                if (percent == 100) {
                                    This.$mobiIng(LS.mobiFormSubmitFileUploadIng + percent + '%', true);
                                } else {
                                    This.$mobiIng(LS.mobiFormSubmitFileUploadIng + percent + '%', 3, true);
                                }
                            },
                            // faiupload的params
                            params: {
                                aid: Fai.top._aid,
                                folderId: Fai.top._siteId,
                                bssInfo: {
                                    siteId: Fai.top._siteId || 0,
                                    fileSizeLimit: fileSize,
                                    uploadType: 'siteFormFile',
                                },
                                accessKeyInfo: {
                                    isTmp: true,
                                },
                            },
                            // 必填，用于内部业务提示
                            tipsFn(string) {
                                This.$mobiIng(string);
                            },
                            fileTypeExts: fileTypeList,
                            uploadConfig: {},
                        });
                        jzUpload.upload(file);
                    }
                },
            });
        },
        toNewFileUploadStyle() {
            this.newFileUploadStyle = true;
        },
        resetValue() {
            if (this.newFileUploadStyle) {
                this.tmpFileList = [];
                this.currentValue = '';
                this?.$refs?.fileUpload?.resetValue?.();
            }
            this.file.fileId = '';
            this.file.tmpFileName = '';
            this.file.fileType = 0;
            this.file.fileWidth = 0;
            this.file.fileHeight = 0;
        },
        validate() {
            if (this.formItem.must && this.currentValue === '') {
                this.$mobiIng(LS.mobi_notSelectFileTip);
                return false;
            }

            return true;
        },
        uploadClick() {
            if (VITE_APP_MODE !== 'visitor') {
                Fai.ing('当前为管理状态，提交无效。');
            }
        },
        setInitialValue() {
            if (this.inMemberDetailEdit) {
                const previewFiles = this.getEditingFormInitialFiles();
                this.initialValue.previewFiles = previewFiles;
                const currentValue = this.getEditingItemSubmitValue();

                if (Array.isArray(currentValue) && currentValue.filter(Boolean).length > 0) {
                    this.currentValue = currentValue;
                }
            }
        },
        delSuccess(value) {
            this.currentValue = value;
        },
    },
};
</script>

<style lang="scss">
.mobi_form_style_1 .m_mobi_form_upload_wrap .file_upload_container2 .uploadify-button2 {
    background: #fafafa;
}
.m_mobi_form_upload_wrap {
    .m_mobi_form_upload_container {
        display: flex;
        justify-content: space-between;
    }
    .form_upload_tips {
        margin: 5px auto 0;
        display: block;
        font-size: 0px;
        & > span {
            font-size: 13px;
        }
        & > span:nth-of-type(2) {
            color: #f43839;
            cursor: pointer;
        }
    }
}
/* .m_mobi_form_upload_wrap .m_mobi_form_upload_input{width: 9.125rem;} */
.m_mobi_form_upload_wrap .m_mobi_form_upload_btn {
    width: 4.75rem;
    line-height: 1.9rem;
    text-align: center;
    padding: 0;
    cursor: pointer;
    position: relative;
    flex-shrink: 0;
    margin-left: 0.625rem;
}

.m_mobi_form_upload_wrap .uploadify-button {
    position: absolute;
    top: 0;
    left: 0;
    width: 90%;
    height: 90%;
    opacity: 0;
}

.mobi_form_style_1 .m_mobi_form_upload_btn.m_mobi_form_input_text:hover {
    outline: none;
}

.m_mobi_form_upload_wrap .uploadify_button_decorate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
</style>
