var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "mProductList styleForm33",
        class: _vm._listClass,
        attrs: { id: _vm._listId, mType: _vm.module.content.t },
      },
      [
        _vm._l(_vm.productList, function (info, index) {
          return [
            _c("big-pic-list-item", {
              key: "p_" + info.id,
              attrs: {
                info: info,
                index: index,
                "no-lazy-load": _vm.noLazyLoad,
              },
            }),
          ]
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }