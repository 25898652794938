var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("module-frame", { attrs: { "module-id": _vm.moduleId } }, [
    !_vm.isNewModule
      ? _c("div", [
          _c(
            "div",
            {
              class: _vm.searchCls,
              style: _vm.moduleColorStyle,
              attrs: { id: "searchContainer" + _vm.module.id },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.handlePopupSearch.apply(null, arguments)
                },
              },
            },
            [
              _c("div", { staticClass: "icon-mSearchIcon" }),
              _vm._v(" "),
              _c("div", { staticClass: "mSearchInput", style: _vm.textStyle }, [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.searchTip) +
                    "\n            "
                ),
              ]),
              _vm._v(" "),
              _c("div", {
                class: ["icon-mSearchBtn", _vm.colorCls],
                style: _vm.moduleColorStyleIcon,
              }),
            ]
          ),
        ])
      : _c(
          "div",
          [
            !_vm.centerText
              ? [
                  _c(
                    "div",
                    {
                      staticClass: "search newSearch",
                      class: [
                        _vm.styleTypeClass,
                        _vm.contentStyle,
                        _vm.textAlign,
                        _vm.searchFlag,
                      ],
                      style: _vm.moduleColorStyle,
                      attrs: { id: "searchContainer" + _vm.module.id },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.handlePopupSearch.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _vm.showDropMenu
                        ? [
                            _c("div", { staticClass: "hide-flex-block" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "module-defaultTitle sr-defaultTitle searchRange",
                                },
                                [_vm._v(_vm._s(_vm.defaultTitle))]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "module-arrow-svgDiv" },
                                [
                                  _c(
                                    "svg",
                                    { staticClass: "module-icon-arrow" },
                                    [
                                      _c("use", {
                                        attrs: {
                                          "xlink:href":
                                            "#jzm-vicon_arrow_panel",
                                        },
                                      }),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          class: _vm.showDropMenu
                            ? "new-mSearchInput"
                            : "mSearchInput",
                          style: _vm.textStyle,
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.searchTip) +
                              "\n                "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "svgDiv",
                          class: [_vm.svgBgColor, _vm.defaultColor],
                          style: _vm.newModuleColorStyleIcon,
                        },
                        [
                          _c(
                            "svg",
                            {
                              staticClass:
                                "v-searchSvg g_oldThemefillColor g_main_fillColor_v3",
                              style: { fill: _vm.svgIcon },
                            },
                            [_c("use", { attrs: { "xlink:href": "#jzm-v19" } })]
                          ),
                        ]
                      ),
                    ],
                    2
                  ),
                ]
              : [
                  _c(
                    "div",
                    {
                      staticClass: "search newSearch centerText",
                      class: [
                        _vm.styleTypeClass,
                        _vm.contentStyle,
                        _vm.textAlign,
                        _vm.searchFlag,
                      ],
                      style: _vm.moduleColorStyle,
                      attrs: { id: "searchContainer" + _vm.module.id },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.handlePopupSearch.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "svgDiv",
                          class: [_vm.svgBgColor, _vm.defaultColor],
                          style: _vm.newModuleColorStyleIcon,
                        },
                        [
                          _c(
                            "svg",
                            {
                              staticClass:
                                "v-searchSvg g_oldThemefillColor g_main_fillColor_v3",
                              style: { fill: _vm.svgIcon },
                            },
                            [_c("use", { attrs: { "xlink:href": "#jzm-v19" } })]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "mSearchInput", style: _vm.textStyle },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.searchTip) +
                              "\n                "
                          ),
                        ]
                      ),
                    ]
                  ),
                ],
          ],
          2
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }