var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "module-frame",
    { attrs: { "module-id": _vm.moduleId } },
    [
      _vm.manageMode && _vm.photoGroupListEmpty && _vm.render
        ? _c(
            "div",
            { staticClass: "empty_tips_panel" },
            [
              _c("div", { staticClass: "text" }, [
                _vm._v("您还没有添加任何图册，赶紧添加吧~"),
              ]),
              _vm._v(" "),
              _c(
                "button-component",
                {
                  attrs: { active: "" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.addPhotoGroup.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("添加图册")]
              ),
            ],
            1
          )
        : _vm.manageMode && _vm.choicePhotoGroupEmpty && _vm.render
        ? _c(
            "div",
            { staticClass: "empty_tips_panel" },
            [
              _c("div", { staticClass: "text" }, [
                _vm._v("请从右侧面板中选择图册~"),
              ]),
              _vm._v(" "),
              _c(
                "button-component",
                {
                  attrs: { active: "" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.addPhotoGroup.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("添加图册")]
              ),
              _vm._v(" "),
              _c(
                "button-component",
                {
                  staticStyle: { "margin-left": "20px" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.openDesigner.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("选择图册")]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.manageMode && _vm.photoGroupListEmpty && _vm.render
        ? _c(
            "div",
            {
              staticStyle: {
                "text-align": "center",
                "padding-top": "40px",
                "padding-bottom": "40px",
              },
            },
            [_c("div", [_vm._v("没有添加图册")])]
          )
        : !_vm.manageMode && _vm.choicePhotoGroupEmpty && _vm.render
        ? _c(
            "div",
            {
              staticStyle: {
                "text-align": "center",
                "padding-top": "40px",
                "padding-bottom": "40px",
              },
            },
            [_c("div", [_vm._v("没有选择图册")])]
          )
        : _vm.render
        ? _c(
            "div",
            {
              class: _vm.photoGroupAllClass,
              attrs: { id: `photoGroupAll${_vm.moduleId}` },
            },
            [
              _vm.module.photoGroupList.length > 0
                ? _c(
                    "div",
                    {
                      staticClass: "f-photoGroupAll-wrap J_photoGroupAll-wrap",
                    },
                    [
                      !_vm.isNewStyle345
                        ? _vm._l(_vm.module.photoGroupList, function (item) {
                            return _c(
                              "div",
                              {
                                key: item.id,
                                class: _vm.photoGroupClass,
                                style: _vm.cusHeightStyle,
                                attrs: { id: `photoGroup${item.id}` },
                              },
                              [
                                _c(
                                  "a",
                                  {
                                    staticClass: "photoGroup-a",
                                    style: _vm.cusLinkStyle,
                                    attrs: { hidefocus: "true" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "photoGroup-img J_photoGroup-img J_img_lazyload",
                                        style: _vm.photoImgStyle(item),
                                        attrs: {
                                          id: `photoGroup${item.id}module${_vm.moduleId}`,
                                          alt:
                                            item.photoList[0] != null
                                              ? item.photoList[0].name != null
                                                ? item.photoList[0].name
                                                : item.photoList[0].id
                                              : "",
                                          datasrc:
                                            item.photoList[0] == null
                                              ? _vm.picDefaultThumPath
                                              : item.photoList[0].picPath,
                                          "src-original":
                                            item.photoList[0] == null
                                              ? _vm.picDefaultThumPath
                                              : item.photoList[0].picPath,
                                          title: item.name,
                                          _groupid: item.id,
                                          fromMobi2: true,
                                        },
                                      },
                                      [
                                        _c("div", {
                                          class: _vm.defCurtainClass,
                                          style: _vm.cusCurtainStyle,
                                        }),
                                        _vm._v(" "),
                                        _c("span", {
                                          staticClass:
                                            "photoGroup-title J_photoGroup-title",
                                          style: _vm.cusTitleFontStyle,
                                          domProps: {
                                            textContent: _vm._s(item.name),
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("span", {
                                          staticClass: "photoGroup-photoNum",
                                          style: _vm.cusPhotoNumFontStyle,
                                          domProps: {
                                            textContent: _vm._s(
                                              item.photoList.length +
                                                _vm.module.mobiPiece
                                            ),
                                          },
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            )
                          })
                        : _vm._l(_vm.module.photoGroupList, function (item) {
                            return _c(
                              "div",
                              {
                                key: item.id,
                                class: _vm.photoGroupClass,
                                attrs: { id: `photoGroup${item.id}` },
                              },
                              [
                                _c(
                                  "a",
                                  {
                                    staticClass:
                                      "f-photoGroup-a J_photoGroup-a photoGroup-a",
                                    style: _vm.cusLinkStyle,
                                    attrs: { hidefocus: "true" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "f-photoGroup-img J_photoGroup-img photoGroup-img J_img_lazyload",
                                        style: _vm.photoImgStyle(item),
                                        attrs: {
                                          id: `photoGroup${item.id}module${_vm.moduleId}`,
                                          alt:
                                            item.photoList[0] != null
                                              ? item.photoList[0].name != null
                                                ? item.photoList[0].name
                                                : item.photoList[0].id
                                              : "",
                                          datasrc:
                                            item.photoList[0] == null
                                              ? _vm.picDefaultThumPath
                                              : item.photoList[0].picPath,
                                          "src-original":
                                            item.photoList[0] == null
                                              ? _vm.picDefaultThumPath
                                              : item.photoList[0].picPath,
                                          title: item.name,
                                          _groupid: item.id,
                                          fromMobi2: true,
                                        },
                                      },
                                      [
                                        _c("span", {
                                          staticClass: "f-photoGroup-photoNum",
                                          style: _vm.cusPhotoNumFontStyle,
                                          domProps: {
                                            textContent: _vm._s(
                                              item.photoList.length +
                                                _vm.module.mobiPiece
                                            ),
                                          },
                                        }),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "f-photoGroup-descWrap" },
                                      [
                                        _c("h3", {
                                          staticClass:
                                            "f-photoGroup-title photoGroup-title J_photoGroup-title",
                                          style: _vm.cusTitleFontStyle,
                                          domProps: {
                                            textContent: _vm._s(item.name),
                                          },
                                        }),
                                        _vm._v(" "),
                                        _vm.showDetail
                                          ? _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "f-photoGroup-detail J_photoGroup-detail",
                                              },
                                              [
                                                _c("span", {
                                                  staticClass:
                                                    "f-photoGroup-detailText",
                                                  domProps: {
                                                    innerHTML: _vm._s(
                                                      item.groupDetail
                                                    ),
                                                  },
                                                }),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            )
                          }),
                    ],
                    2
                  )
                : _vm._e(),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.pageDisplayType && _vm.isStyle123 && !_vm.choicePhotoGroupEmpty
        ? _c("pagenation", {
            attrs: {
              "module-id": _vm.moduleId,
              "total-size": _vm.module.totalSize,
              "page-size": _vm.module.content.pagePhotoSize,
              pageno: _vm.module.pageno,
              "open-theme-v3": _vm.openThemeV3,
              "ajax-pagenation": true,
            },
            on: { "on-change": _vm.changePage },
            nativeOn: {
              click: function ($event) {
                $event.stopPropagation()
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }