<template>
    <div
        :id="'newsLine' + info.id"
        :topClassName="_topClassName"
        :topSwitch="_topSwitch"
        :newsId="info.id"
        :newsName="info.title"
        :class="[wrapClasses, bigItemClasses]"
        :picnum="8"
    >
        <div :id="'lineBody' + info.id" class="lineBody" :class="isOnlyTitle">
            <a hidefocus="true" :href="_newsUrl" :target="_jumpTarget" :onclick="onclickStr">
                <div class="threePicItem" :class="noDes">
                    <div>
                        <div class="g_news_title newTitle" :class="titleLine" :style="!isMixHead && news_title_style">
                            <div :class="_topFlagClass"></div>
                            <top-component v-if="_topFlagClass != '' && isNewAddModule"></top-component>{{ info.title }}
                        </div>
                        <div class="threePicWrap">
                            <div
                                v-drag-resize="_resizeOptions"
                                class="threePic firstPic picScaleClass J_img_lazyload"
                                :src-original="info.realPicId ? info.bigPicPath : ''"
                                :alt="altName"
                                :style="[picStyle, picIndexPic_01]"
                                :class="[bigPicClasses, picRadiusClass]"
                            ></div>
                            <div
                                v-drag-resize="_resizeOptions"
                                class="threePic secondPic picScaleClass J_img_lazyload"
                                :src-original="info.nlPicturePathTwo"
                                :alt="altName"
                                :style="[picStyle, picIndexPic_02]"
                                :class="[nlPicturePathTwo, picRadiusClass]"
                            ></div>
                            <div
                                v-drag-resize="_resizeOptions"
                                class="threePic thirdPic picScaleClass J_img_lazyload"
                                :src-original="info.nlPicturePathThree"
                                :alt="altName"
                                :style="[picStyle, picIndexPic_03]"
                                :class="[nlPicturePathThree, picRadiusClass]"
                            ></div>
                        </div>
                        <div v-if="!module.content.des" class="felxTextItem">
                            <div
                                class="twoLine articleSummray g_text description g_news_abstract newDescriptionLine"
                                :style="news_abstract_style"
                                :class="[descriptionLine, isOnlyTitleAndDes]"
                            >
                                {{ info.summary }}
                            </div>
                        </div>
                    </div>
                    <new-info :info="info" :index="index" />
                </div>
            </a>
        </div>
    </div>
</template>

<script>
import ListItem from '../../mixins/ListItem';
import NewInfo from '../picTextList/new-info.vue';
import TopComponent from '../topComponent/top-component.vue';
import { mapFlag } from '@/shared/flag';

export default {
    name: 'UpDownPicTextItem',
    components: { NewInfo, TopComponent },
    mixins: [ListItem],
    inject: ['module', 'options', 'altName', 'ndUrl'],
    props: {
        info: {
            type: Object,
            default: function () {
                return {};
            },
        },
        index: { type: Number, default: 0 },
    },
    data() {
        return {
            imgStyle: `clip-path:url(#clippath${this.module.id}${this.index}`,
            isThreeItem: true,
        };
    },
    computed: {
        ...mapFlag(
            {
                isNewAddModule: 0x4,
            },
            'module.flag'
        ),
        bigItemClasses() {
            return `${this._lineClass} threePicStyle newsLine J_picNum`;
        },
        wrapClasses() {
            return `${this._lineClass}`;
        },
        indexPicHeight() {
            // 首图高度
            const picHeightSet = this.module.content.indexPicHeightParam || {};
            const indexPicHeightType = picHeightSet.indexPicHeightType;
            const indexPicHeightRem = picHeightSet.indexPicHeight / 20;
            return indexPicHeightType == 2 ? indexPicHeightRem : 3.5;
        },
        divSize() {
            let divWidth = 4.6;
            let divHeight = 3.5;
            const tSize = this.module.content.tSize;
            const tSizeType = tSize.picSizeType;
            if (tSizeType == 2) {
                divWidth = tSize.picWidth / 20;
                divHeight = tSize.picHeight / 20;
            }
            return { divWidth, divHeight };
        },
        picStyle() {
            let picStyle = {};
            if (this.isNewAddModule) {
                if ((this.module.content.pn == 0 || this.module.content.pn == 2) && this.module.content.hs == 3) {
                    picStyle.height = `${this.indexPicHeight}rem`;
                } else {
                    picStyle.height = `${this.divSize.divHeight}rem`;
                }
            }
            return picStyle;
        },
        picIndexPic_01() {
            let picStyle = {};
            if (this.info.realPicId !== '') {
                picStyle.background = `url(${
                    this.noLazyLoad ? this.info.bigPicPath : this.options.loadingPath
                }) no-repeat center /cover`;
            }
            return picStyle;
        },
        picIndexPic_02() {
            let picStyle = {};
            if (this.info.nlPicturePathTwo !== '') {
                picStyle.background = `url(${
                    this.noLazyLoad ? this.info.nlPicturePathTwo : this.options.loadingPath
                }) no-repeat center /cover`;
            }
            return picStyle;
        },
        picIndexPic_03() {
            let picStyle = {};
            if (this.info.nlPicturePathThree !== '') {
                picStyle.background = `url(${
                    this.noLazyLoad ? this.info.nlPicturePathThree : this.options.loadingPath
                }) no-repeat center /cover`;
            }
            return picStyle;
        },
        descriptionLine() {
            const descriptionLine = this.module.content.dsl;
            const textPosClass = this.module.content.tp == 2 ? 'newsTextPosition2' : '';
            var desClass = '';
            switch (descriptionLine) {
                case 0:
                    desClass = 'defaultDescriptionLine';
                    break;
                case 1:
                    desClass = 'descriptionLine';
                    break;
                case 2:
                    desClass = 'noDescriptionLine';
                    break;
            }
            return `${textPosClass} ${desClass}`;
        },
        titleLine() {
            const descriptionLine = this.module.content.tsl;
            var desClass = '';
            switch (descriptionLine) {
                case 0:
                    desClass = 'defaultDescriptionLine';
                    break;
                case 1:
                    desClass = 'descriptionLine';
                    break;
                case 2:
                    desClass = 'noDescriptionLine';
                    break;
            }
            return ` ${desClass}`;
        },
        bigPicClasses() {
            return this.info.realPicId === '' ? 'newsTitlePic icon-noFigureB' : 'newsTitlePic';
        },
        isMixHead() {
            return this.isNewAddModule &&
                (this.module.content.pn == 0 || this.module.content.pn == 2) &&
                this.module.content.hs == 3
                ? true
                : false;
        },
        picRadiusClass() {
            return !this.isMixHead ? 'picRadiusClass' : '';
        },
        nlPicturePathTwo() {
            return this.info.nlPicturePathTwo === '' ? 'newsTitlePic icon-noFigureB' : 'newsTitlePic';
        },
        nlPicturePathThree() {
            return this.info.nlPicturePathThree === '' ? 'newsTitlePic icon-noFigureB' : 'newsTitlePic';
        },
        noDes() {
            return this.showDes ? '' : 'noDes';
        },
    },
};
</script>

<style></style>
