var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "line icon-gline j-lineH J_lineH" }, [
    (_vm.moduleStyle == 0 || _vm.moduleStyle == 8) && _vm.item.hasChildren
      ? _c(
          "div",
          {
            staticClass: "pgLinkWrap clearFix",
            on: { click: _vm.pgListLv1ItemClick },
          },
          [
            _c("div", {
              staticClass: "pgName g_productSort_name",
              style: _vm.pn,
              domProps: { textContent: _vm._s(_vm.item.name) },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "pgMark" }),
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.moduleStyle != 8 && _vm.moduleStyle != 0 && _vm.item.hasChildren
      ? _c(
          "a",
          {
            staticClass: "pgName",
            attrs: {
              href:
                _vm.newNpgcUrl +
                "?groupId=" +
                _vm.item.id +
                "&pgt=" +
                0 +
                "&pgs=" +
                _vm.moduleStyle,
              target: "_self",
            },
          },
          [
            _c("span", {
              staticClass: "navLineTitle g_productSort_name",
              style: _vm.pn,
              domProps: { textContent: _vm._s(_vm.item.name) },
            }),
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    !_vm.item.hasChildren
      ? _c(
          "a",
          {
            staticClass: "pgLinkWrap",
            attrs: {
              href:
                _vm.prUrl + "?mid=" + _vm.moduleId + "&groupId=" + _vm.item.id,
              target: "_self",
            },
          },
          [
            _c("span", {
              staticClass: "pgName g_productSort_name",
              style: _vm.pn,
              domProps: { textContent: _vm._s(_vm.item.name) },
            }),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }