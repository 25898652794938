<template>
    <module-frame :module-id="moduleId">
        <div class="new_online_map" :style="baiduMapHeightStyle" :class="onlineMapClass">
            <div
                v-show="isShowMapView"
                :id="'mapContainer' + module.id"
                class="mapContainer"
                :type="module.content.mt"
                @click.stop=""
            >
                <div v-if="module.content.mt === 1" class="mapLoadingBox"> </div>
            </div>
            <div v-show="!isShowMapView" class="list">
                <div
                    v-for="(curLocation, index) in curlocationList"
                    :id="genUniqueDOMId(curLocation.id)"
                    :key="index"
                    class="list_item"
                    @click.stop="showMapView(curLocation)"
                    @mouseover="showAcitonArea(curLocation.id)"
                    @mouseout="hideActionArea(curLocation.id)"
                >
                    <div class="name_distance_wrap column_m_b">
                        <span
                            class="name"
                            :class="{ sing_line_ellipsis: !isWrapAddressName }"
                            :style="addressNameStyle"
                            v-html="encodeHtml(_fixOverflowClipText(curLocation.name || ''))"
                        ></span>
                        <span v-if="isShowDistance" class="distance" :style="distanceStyle">{{
                            _formatDistance(curLocation.distance)
                        }}</span>
                    </div>
                    <div class="location column_m_b" :style="locationStyle">
                        <svg role="img" class="new_online_map_svg" :style="locationSVGStyle">
                            <use xlink:href="#jzm-v32"></use>
                        </svg>
                        <span
                            :class="{
                                sing_line_ellipsis: !isWrapAddressDetail,
                            }"
                            :style="addressDetailStyle"
                            class="location_text"
                            v-html="encodeHtml(_fixOverflowClipText(curLocation.detail || ''))"
                        ></span>
                    </div>
                    <div v-if="isShowTel" class="tel">
                        <svg role="img" class="new_online_map_svg">
                            <use xlink:href="#jzm-v33"></use>
                        </svg>
                        <span :style="telStyle">{{ curLocation.tel }}</span>
                    </div>
                    <div
                        class="work_time column_m_b"
                        :class="{ sing_line_ellipsis: !isWrapOther }"
                        :style="otherMessageStyle"
                        v-html="encodeHtml(curLocation.other)"
                    >
                    </div>
                </div>
            </div>
            <pagenation
                v-if="isShowpagenation"
                :module-id="id"
                :total-size="totalSize"
                :page-size="pageSize"
                :open-theme-v3="openThemeV3"
                :pageno="pageno"
                :ajax-pagenation="true"
                @on-change="changePage"
                @click.native.stop=""
            >
            </pagenation>
        </div>
    </module-frame>
</template>

<script>
import ModuleFrame from '@/modules/frame/index.vue';
import Pagenation from '@/components/pagenation.vue';
import { mapGetters, mapState } from 'vuex';
import { encodeHtml } from '@/shared/util';
import { setCtrlStyleCssList } from '@/shared/style';
import { MODULE_STYLE } from '@/def/module';
import mapView from './component/mapView.vue';
import mixin from './mixin';

import {
    repaintMapIcon,
    bdMapIsLoad,
    addMapModuleInit,
    getBMAPtoPointDistance,
    getBMAPCurPositionPoint,
    bindEvent,
    loadBDMapScript,
    sortListByDistanceASC,
    openOnlineMapEditPanel,
    deleteLocationById,
} from '../util';
import { NEW_MANAGE_HTML_FONTSIZE } from '@/shared/constants';

const manageMode = VITE_APP_MODE !== 'visitor';
export default {
    name: 'OnlineMap',
    components: {
        Pagenation,
        ModuleFrame,
    },
    style: MODULE_STYLE.ONLINE_MAP,
    mixins: [mixin],
    props: {
        moduleId: {
            type: Number,
            default: -1,
        },
    },
    data() {
        // 获取map模块API
        return {
            id: this.moduleId,
            manageMode,
            totalSize: 0,
            pageno: 1,
            isLoadingPosition: false,
        };
    },
    computed: {
        ...mapGetters(['getModuleById']),
        ...mapState('app', ['openThemeV3']),
        ...mapState(['htmlFontSize']),
        module() {
            return this.getModuleById(this.moduleId);
        },
        options() {
            return this.module.renderOptions;
        },
        mapType() {
            return this.module.content.mt;
        },
        locationList: {
            get() {
                return this.module.content.locationList;
            },
            set(val) {
                this.module.content.locationList = val;
            },
        },
        isBaiduMap() {
            return this.mapType === 0;
        },
        isGoogleMap() {
            return this.mapType === 1;
        },
        styleType() {
            return this.module.content.t;
        },
        isShowDistance() {
            return this.module.content.isShowDistance;
        },
        isShowTel() {
            return this.module.content.isShowTel;
        },
        isWrapAddressDetail() {
            return this.module.content.isWrapAddressDetail;
        },
        isWrapAddressName() {
            return this.module.content.isWrapAddressName;
        },
        isWrapOther() {
            return this.module.content.isWrapOther;
        },
        addressNameStyle() {
            return this.genStyleObj(this.module.content.ans);
        },
        addressDetailStyle() {
            return this.genStyleObj(this.module.content.ads);
        },
        otherMessageStyle() {
            return this.genStyleObj(this.module.content.oms);
        },
        telStyle() {
            return this.genStyleObj(this.module.content.ts);
        },
        distanceStyle() {
            return this.genStyleObj(this.module.content.ds);
        },
        curlocationList() {
            // 分页数据
            let sIndex = (this.pageno - 1) * this.pageSize;
            let eIndex = sIndex + this.pageSize;
            let curlocationList = this.locationList.slice(sIndex, eIndex);
            // 全部数据
            let orderList = this.isOpenPage && !this.isShowMapView ? curlocationList : this.locationList;
            let newList = null;
            if (this.isDefaultSortRule) {
                newList = this.deepClone(orderList);
                this.sortListByDistanceASC(newList);
            }
            return this.isDefaultSortRule ? newList : orderList;
        },
        isShowpagenation() {
            const islistView = this.styleType === 2;
            const isEmptyList = this.locationList.length === 0;
            return this.isOpenPage && islistView && !isEmptyList;
        },
        isOpenPage() {
            return this.module.content.isOP;
        },
        locationStyle() {
            return {
                'align-items': 'flex-start',
            };
        },
        locationSVGStyle() {
            // 地址详情的font-size
            const LOCATION_DEFAULT_FONT_SIZE = 0.55;

            const LOCATION_FONT_SIZE = this.addressDetailStyle['font-size']
                ? parseFloat(this.addressDetailStyle['font-size'])
                : LOCATION_DEFAULT_FONT_SIZE;

            // 地址详情盒子高度
            const LOCATION_LINE_HEIGHT = 1.5;

            const LOCATION_BOX_HEIGHT = LOCATION_FONT_SIZE * LOCATION_LINE_HEIGHT;

            const LOCATION_ICON_HEIGHT = 0.65;

            // 图标与第一行文字垂直居中需要的marigin-top
            return {
                'margin-top': LOCATION_BOX_HEIGHT / 2 - LOCATION_ICON_HEIGHT / 2 + 'rem',
            };
        },
        pageSize() {
            return this.module.content.ps;
        },
        isDefaultSortRule() {
            return this.module.content.sr === 0;
        },
        isShowMapView() {
            return this.styleType === 1 || this.isGoogleMap;
        },
        mapInfo() {
            return this.encodeHtml(this.module.content.mi);
        },
        isDisabledDelete() {
            return this.locationList.length === 1;
        },
        baiduMapHeightStyle() {
            let style = {};
            // 百度地图下样式一
            if (this.styleType === 1 && this.module.content.ht == 1 && this.isBaiduMap) {
                style.height = `${this.module.content.h / this.htmlFontSize}rem !important`;
            }
            return style;
        },
        onlineMapClass() {
            let classList = [];
            if (this.styleType === 1 && this.module.content.ht == 1 && this.isBaiduMap) {
                classList.push('map_height_setting');
            }
            return classList;
        },
    },
    watch: {
        module: {
            handler() {},
            deep: true,
        },
        styleType() {
            if (this.styleType === 1) {
                this.$nextTick(() => {
                    this.refreshMap();
                });
            }
        },
        'module.content.mt': {
            handler: function () {
                //切换地图类型时重新渲染，谷歌地图暂无实现
                this.refreshMap();
            },
        },
        baiduMapHeightStyle() {
            if (this.isBaiduMap) {
                this.$nextTick(() => {
                    this.refreshMap();
                });
            }
        },
        addressNameStyle() {
            this.updateMapFontStyle();
        },
        addressDetailStyle() {
            this.updateMapFontStyle();
        },
        otherMessageStyle() {
            this.updateMapFontStyle();
        },
        telStyle() {
            this.updateMapFontStyle();
        },
        isWrapAddressName() {
            this.updateMapFontStyle();
        },
        isWrapAddressDetail() {
            this.updateMapFontStyle();
        },
        isWrapOther() {
            this.updateMapFontStyle();
        },
        isShowTel() {
            this.updateMapBaseStyle();
        },
        locationList: {
            handler() {
                this.totalSize = this.locationList.length;
                this.pageno = 1;
            },
            immediate: true,
        },
        pageSize() {
            this.totalSize = this.locationList.length;
            this.pageno = 1;
        },
        isOpenPage() {
            this.totalSize = this.locationList.length;
            this.pageno = 1;
        },
    },
    beforeCreate() {
        loadBDMapScript();
    },
    mounted() {
        // 初始化地图api
        // 视图初始化一共有三种情况   列表  百度地图
        if (this.isBaiduMap) {
            const isBdMapLoad = bdMapIsLoad() || false;
            if (isBdMapLoad) {
                // 初始化地图并绘制对应标注
                this.refreshMap();
                this.updateMapFontStyle();
                // 获取自身定位，同时获取与现有目得地的距离
                this.initListDistance();
            } else {
                addMapModuleInit({
                    id: this.module.id,
                    fn: this.initBDMap,
                });
            }
        } else {
            bindEvent(this);
        }
    },
    methods: {
        showMapView(location) {
            this.createMapView(location);
        },
        // 生成样式对象
        genStyleObj(obj) {
            const IS_DEFAULT = obj.type === 0;
            const MOBI_DESIGNER_ROOT_FONT_SIZE = NEW_MANAGE_HTML_FONTSIZE;
            const convertFontSize = obj.fs / MOBI_DESIGNER_ROOT_FONT_SIZE;

            let configStyle = {
                'font-size': `${convertFontSize}rem`,
                color: obj.color,
                'font-style': obj.isItalic ? 'italic' : 'normal',
                'font-weight': obj.isBlod ? 'bold' : 'normal',
                'text-decoration': obj.isUnderline ? 'underline' : 'none',
                'word-break': 'break-all',
            };
            if (convertFontSize > 1) {
                // 当设置的字号太大时，字体行高跟随字号大小（不然字体会重叠）
                configStyle['line-height'] = `${convertFontSize}rem`;
            }

            return IS_DEFAULT ? {} : configStyle;
        },
        repaintMapIcon() {
            repaintMapIcon(`mapContainer${this.id}`, this.curlocationList, this.module);
        },
        createMapView(location) {
            // 由于curLocationList是深拷贝用于实现排序算法的，所有没有响应的get set，需要找到locationList中对应的响应项
            let { id } = location;
            location = this.locationList.find((location) => location.id === id);

            let $body = jm('#g_web');
            let mapViewWrap = `
            <div class='map_view'>
                <map-view :location='location' :module="module" :options="options"></map-view>
                </div>
            `;
            // 先删除现有节点，因为重新new Vue导致内部组件不响应变化，暂时采用这个办法
            jm('.map_view').remove();
            $body.append(mapViewWrap);

            // 隐藏滚动条
            $body.css('overflow-y', 'hidden');

            let _self = this;
            // 激活视图
            new Vue({
                el: '.map_view',
                components: {
                    mapView,
                },
                data() {
                    return {};
                },
                computed: {
                    location() {
                        return location;
                    },
                    module() {
                        return _self.module;
                    },
                    options() {
                        return _self.options;
                    },
                },
            });
        },
        refreshMap() {
            bindEvent(this);
            this.repaintMapIcon();
        },
        updateModuleStyle(styleObjArr) {
            let styles = [];
            // 生成符合规范的StyleCssItem {cls: class, key: property value: value}
            styleObjArr.forEach(({ cls, obj }) => {
                for (let [key, value] of Object.entries(obj)) {
                    styles.push({
                        cls,
                        key,
                        value,
                    });
                }
            });

            // 添加到文档中去
            setCtrlStyleCssList('mobiOnlineMapStyleModule', `module${this.id}`, styles);
        },
        updateMapFontStyle() {
            const nowrapStyleObj = {
                overflow: 'hidden',
                'white-space': 'nowrap',
                'text-overflow': 'ellipsis',
            };

            const defaultStyleObj = {
                overflow: 'initial',
                'white-space': 'initial',
                'text-overflow': 'initial',
            };

            // obj 按照正常css K-V写即可
            let addressNameStyle = {
                ...this.addressNameStyle,
            };

            // 合并默认样式
            if (this.isEmptyObj(addressNameStyle)) {
                let defaultStyle = {
                    'font-size': `0.6rem`,
                    color: '#333',
                    'font-style': 'normal',
                    'font-weight': 'normal',
                    'text-decoration': 'none',
                    'margin-bottom': '0.2rem',
                };
                Object.assign(addressNameStyle, defaultStyle);
            }

            // 合并换行配置
            if (this.isWrapAddressName) {
                Object.assign(addressNameStyle, defaultStyleObj);
            } else {
                Object.assign(addressNameStyle, nowrapStyleObj);
            }

            let addressDetailStyle = {
                ...this.addressDetailStyle,
            };

            // 合并默认配置
            if (this.isEmptyObj(addressDetailStyle)) {
                let defaultStyle = {
                    'font-size': `0.45rem`,
                    color: '#666',
                    'font-style': 'normal',
                    'font-weight': 'normal',
                    'text-decoration': 'none',
                };
                Object.assign(addressDetailStyle, defaultStyle);
            }

            // 合并换行配置
            if (this.isWrapAddressDetail) {
                Object.assign(addressDetailStyle, defaultStyleObj);
            } else {
                Object.assign(addressDetailStyle, nowrapStyleObj);
            }

            let telStyle = {
                ...this.telStyle,
            };
            // 合并默认配置
            if (this.isEmptyObj(this.telStyle)) {
                telStyle = {
                    'font-size': `0.45rem`,
                    color: '#666',
                    'font-style': 'normal',
                    'font-weight': 'normal',
                    'text-decoration': 'none',
                };
            }

            let otherMessageStyle = {
                ...this.otherMessageStyle,
            };

            // 合并默认配置
            if (this.isEmptyObj(otherMessageStyle)) {
                let defaultStyle = {
                    'font-size': `0.45rem`,
                    color: '#666',
                    'font-style': 'normal',
                    'font-weight': 'normal',
                    'text-decoration': 'none',
                };
                Object.assign(otherMessageStyle, defaultStyle);
            }

            // 合并换行配置
            if (this.isWrapOther) {
                Object.assign(otherMessageStyle, defaultStyleObj);
            } else {
                Object.assign(otherMessageStyle, nowrapStyleObj);
            }

            const styleObjArr = [
                { cls: ' .msgBox .msg .name', obj: addressNameStyle },
                { cls: ' .msgBox .msg .address', obj: addressDetailStyle },
                { cls: ' .msgBox .msg .other', obj: otherMessageStyle },
                { cls: ' .msgBox .msg .tel', obj: telStyle },
            ];

            this.updateModuleStyle(styleObjArr);
        },
        updateMapBaseStyle() {
            const styleObj = {
                display: this.isShowTel ? 'block' : 'none',
            };

            const styleObjArr = [{ cls: ' .msgBox .msg .tel', obj: styleObj }];
            this.updateModuleStyle(styleObjArr);
        },
        genUniqueDOMId(id) {
            return `online_map_module_${this.id}_${id}`;
        },
        showAcitonArea(id) {
            let _this = this;
            if (!(VITE_APP_MODE !== 'visitor')) {
                return;
            }
            let moduleId = this.module.id;
            let linkJson = [
                {
                    operationText: '编辑此项',
                    className: 'online_map_edit',
                    onClick() {
                        openOnlineMapEditPanel(moduleId, id);
                    },
                },
                {
                    operationText: this.isDisabledDelete ? '至少保留一项' : '删除此项',
                    className: this.isDisabledDelete ? 'online_map_delete_disable' : 'online_map_delete',
                    onClick() {
                        if (_this.isDisabledDelete) {
                            return;
                        }
                        deleteLocationById(moduleId, id);
                    },
                },
            ];

            const AREA_INNER_RIGHT_BOTTOM = 106;
            const options = {
                extBoderCls: 'online_map_border',
            };
            this.$layer.addEditLayer(this.genUniqueDOMId(id), linkJson, AREA_INNER_RIGHT_BOTTOM, options);
        },
        hideActionArea(id) {
            if (!(VITE_APP_MODE !== 'visitor')) {
                return;
            }
            const AREA_INNER_RIGHT_BOTTOM = 106;
            this.$layer.removeEditLayer(this.genUniqueDOMId(id), null, AREA_INNER_RIGHT_BOTTOM);
        },
        getCurPositionPoint() {
            return getBMAPCurPositionPoint();
        },

        changePage(pageno) {
            this.pageno = pageno;
        },
        initListDistance() {
            if (this.isLoadingPosition) {
                return;
            }
            let promise = this.getCurPositionPoint();
            this.isLoadingPosition = true;
            promise
                .then((sPoint) => {
                    let ds = this.locationList.map((location) => {
                        let {
                            bmp: { x, y },
                        } = location;
                        let ePoint = { x, y };
                        let distance = getBMAPtoPointDistance(sPoint, ePoint, `mapContainer${this.id}`);
                        location.distance = distance;
                        return location;
                    });
                    this.locationList = [...ds];
                    // 默认情况按距离升序
                    if (this.isDefaultSortRule) {
                        let orderList = sortListByDistanceASC(this.locationList);
                        this.locationList = [...orderList];
                    }
                })
                .catch((err) => {
                    console.log('reject', err);
                });
        },
        encodeHtml,
        isEmptyObj(obj) {
            return JSON.stringify(obj) === '{}';
        },
        deepClone(obj) {
            return JSON.parse(JSON.stringify(obj));
        },
        sortListByDistanceASC(arr) {
            return arr.sort((locationA, locationB) => {
                return Number(locationA.distance) - Number(locationB.distance);
            });
        },

        initBDMap() {
            this.refreshMap();
            this.updateMapBaseStyle();
            this.updateMapFontStyle();
            this.initListDistance();
        },
    },
};
</script>

<style>
/*在线地图模块 start*/
.formStyle64 .mapContainerBox {
    width: 100%;
    height: 100%;
    color: #333;
}
.formStyle64 .mapContainerBox .gm-style {
    font-family: '\5FAE\8F6F\96C5\9ED1', Helvetica, '黑体', Arial, Tahoma;
}
.formStyle64 .mapContainer {
    height: 100%;
    min-height: 12rem;
}
/*.formStyle64 .mapContainer img{max-width: none;max-height: none;}*/
/*自定义覆盖类*/
.formStyle64 .mapContainer .msgBox {
    display: inline-block;
    cursor: default;
    position: absolute;
    width: auto;
    height: auto;
    padding: 0.7rem 0.9rem 0.7rem 0.5rem;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: rgb(0, 0, 0) 0px 0px 6px -3px;
    color: #333;
}
.formStyle64 .mapContainer .msgBox .msg {
    display: inline-block;
    width: auto;
    word-wrap: break-word;
    word-break: break-word;
    font-size: 0.56rem;
    width: 10rem;
}
.formStyle64 .mapContainer .msgBox .arrow {
    position: absolute;
    display: inline-block;
    bottom: -6px;
    left: 3.2rem;
    width: 0;
    height: 0px;
    content: '';
    border-style: solid;
    border-width: 7px;
    border-color: #fff #fff transparent transparent;
    transform: rotate(135deg);
    box-shadow: rgb(0, 0, 0) 1px -1px 3px -2px;
}
.formStyle64 .mapContainer .msgBox .close {
    cursor: pointer;
    display: block;
    position: absolute;
    right: 10px;
    top: 10px;
    width: 10px;
    height: 10px;
    background: url(/image/onlineMap/close.png?v=201811211416);
    background-size: cover;
}
.formStyle64 .mapContainer .mapLoadError {
    width: 100%;
    height: 100%;
    min-height: 12rem;
    position: relative;
}
.formStyle64 .mapContainer .errorMsgBox {
    width: 4.325rem;
    height: 7rem;
    position: absolute;
    left: 0;
    margin: auto;
    top: 0;
    right: 0;
    bottom: 0;
}
.formStyle64 .mapContainer .errorMsgBox .errorImg {
    background: url(/image/v2/defaultIcon10.png?v=201711250607) no-repeat center center;
    background-size: contain;
    width: 4.325rem;
    height: 5.425rem;
}
.formStyle64 .mapContainer .errorMsgBox .errorMsg {
    font-size: 0.65rem;
    color: #333333;
    margin-top: 0.75rem;
    text-align: center;
}
.formStyle64 .mapContainer .errorMsgBox .errName {
    display: none;
}

.formStyle64 .mapContainer .mapLoadingBox {
    min-height: 12rem;
    height: 100%;
    width: 100%;
    background: url(/image/loading/loading_2.gif?v=201610211723) no-repeat center;
}
/*在线地图模块 end */
.new_online_map .list {
    padding: 0 0.475rem 0.5rem 0.35rem;
}
.new_online_map .list_item {
    margin-top: 0.5rem;
    border-bottom: solid 1px #eee;
}
.new_online_map .name_distance_wrap {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}
.new_online_map .name_distance_wrap .name {
    color: #333;
    font-size: 0.65rem;
    font-weight: bold;
    word-break: break-all;
}
.new_online_map .name_distance_wrap .distance {
    color: #999;
    font-size: 0.45rem;
    font-weight: 400;
}
.new_online_map .location_text {
    word-break: break-all;
    line-height: 1.5;
    white-space: pre-wrap;
}
.new_online_map .location {
    color: #333;
    font-size: 0.55rem;
    font-weight: 400;
    display: flex;
    align-items: center;
}
.new_online_map .tel {
    color: #333;
    font-size: 0.55rem;
    font-weight: 400;
    display: flex;
    align-items: center;
    margin-bottom: 0.3rem;
}
.new_online_map .work_time {
    color: #999;
    font-size: 0.55rem;
    font-weight: 400;
    line-height: 1rem;
    word-break: break-all;
}
.new_online_map .column_m_b {
    margin-bottom: 0.45rem;
}

.new_online_map_svg {
    width: 0.65rem;
    height: 0.65rem;
    fill: #333;
    margin-right: 0.375rem;
    flex-shrink: 0;
}

.new_online_map .sing_line_ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.editLayer .online_map_edit {
    background: #557ce1 url(/image/v2/mbg01.png?v=201909251255) -1418px -39px no-repeat;
}

.editLayer .online_map_edit:hover {
    background-color: #618cef;
}

.editLayer .online_map_delete {
    background: #557ce1 url(/image/v2/mbg01.png?v=201909251255) -1468px -39px no-repeat;
}

.editLayer .online_map_delete:hover {
    background-color: #618cef;
}

.editLayer .online_map_delete_disable {
    background: #d5d5d5 url(/image/v2/mbg01.png?v=201909251255) -1468px -39px no-repeat;
    cursor: not-allowed;
}

/* 处理item hover上去的包围框 */
.online_map_border.singleEdit_TB_Border {
    border-top-style: dashed;
}
.online_map_border.singleEdit_LR_Border {
    border-left-style: dashed;
}

.map_height_setting .mapContainer {
    min-height: auto;
}
</style>
