<template>
    <div class="photo_wf_container" :style="photoSpaceStyle">
        <div class="photo_wf_columns" :style="columnsLeftSpaceStyle">
            <WaterFallItem
                v-for="(item, index) in waterFallItemList.oneList"
                :key="item.picId"
                :photo-item="item"
                :link-type="linkType"
                :img-href="imgHref(item.jumpUrl)"
                :module-content="module.content"
                :is-head-or-tail="item.isHeadOrTail"
                :is-empty="isEmptyText"
                :loading-path="options.loadingPath"
                :no-lazy-load="noLazyLoad"
                :is-left="true"
                :img-index="index + 1"
                :slides-index="item.index"
                :module-id="moduleId"
                :list-length="waterFallItemList.oneList.length"
            />
        </div>
        <div class="photo_wf_columns" :style="columnsRightSpaceStyle">
            <WaterFallItem
                v-for="(item, index) in waterFallItemList.twoList"
                :key="item.picId"
                :module-id="moduleId"
                :photo-item="item"
                :link-type="linkType"
                :img-href="imgHref(item.jumpUrl)"
                :module-content="module.content"
                :is-head-or-tail="item.isHeadOrTail"
                :is-empty="isEmptyText"
                :loading-path="options.loadingPath"
                :no-lazy-load="noLazyLoad"
                :is-left="false"
                :slides-index="item.index"
                :img-index="index + 1"
                :list-length="waterFallItemList.twoList.length"
            />
        </div>
    </div>
</template>
<script>
import WaterFallItem from './waterfall-item.vue';
import { optionsMixins } from '../mixins/optionsMixins';
export default {
    name: 'WaterFallList',
    components: {
        WaterFallItem,
    },
    mixins: [optionsMixins],
    inject: ['module', 'options'],
    props: {
        contentList: {
            type: Array,
            default() {
                return [];
            },
        },
        noLazyLoad: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        moduleId() {
            return this.module.id;
        },
        photoSpaceSetting() {
            return this.module.content.mhighs.ispace;
        },
        photoSpaceStyle() {
            let style = {};
            if (this.photoSpaceSetting.type == 0) {
                style.padding = '0.74rem 0.75rem 0';
            } else {
                style.padding = '0.75rem 0.75rem';
            }
            return style;
        },
        waterFallItemList() {
            let obj = {
                oneList: [],
                twoList: [],
            };
            this.contentList.forEach((item, index) => {
                item.isHeadOrTail = index == 0 || index == this.contentList.length - 1;
                if (index % 2 === 0) {
                    obj.oneList.push(item);
                } else {
                    obj.twoList.push(item);
                }
            });
            return obj;
        },

        columnsSpaceStyle() {
            let style = {};
            if (this.photoSpaceSetting.type == 1) {
                style['width'] = '50%';
            }
            return style;
        },

        columnsLeftSpaceStyle() {
            let style = {};
            if (this.photoSpaceSetting.type == 0) {
                style.paddingRight = '0.25rem';
            } else {
                style = { ...style, ...this.columnsSpaceStyle };
            }
            return style;
        },

        columnsRightSpaceStyle() {
            let style = {};
            if (this.photoSpaceSetting.type == 0) {
                style.paddingLeft = '0.25rem';
            } else {
                style = { ...style, ...this.columnsSpaceStyle };
            }
            return style;
        },
    },
};
</script>
<style>
.photo_wf_container {
    display: flex;
    flex-direction: row;
    width: 100%;
    box-sizing: border-box;
}

.photo_wf_container .photo_wf_columns {
    display: flex;
    flex-direction: column;
    width: 50%;
    box-sizing: border-box;
}
</style>
