var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: _vm.wrapClasses,
      attrs: {
        id: "newsLine" + _vm.info.id,
        topClassName: _vm._topClassName,
        topSwitch: _vm._topSwitch,
        newsId: _vm.info.id,
        newsName: _vm.info.title,
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "lineBody",
          class: _vm.isOnlyTitle,
          attrs: { id: "lineBody" + _vm.info.id },
        },
        [
          _c(
            "a",
            {
              attrs: {
                hidefocus: "true",
                href: _vm._newsUrl,
                target: _vm._jumpTarget,
                onclick: _vm.onclickStr,
              },
            },
            [
              _c("div", { staticClass: "mixNewsStyleImgBox7" }, [
                _c("div", { staticClass: "mixNewsStyleImgBox7_left" }, [
                  _c(
                    "div",
                    { staticClass: "mixNewsStyleTime" },
                    [
                      _vm._topFlagClass != "" && _vm.isNewAddModule
                        ? _c("top-component")
                        : _vm._e(),
                      _vm._v(_vm._s(_vm.newsDate) + "\n                    "),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "g_news_title",
                      class: [_vm.titleClasses, _vm.titleLine],
                      style: _vm.news_title_style,
                    },
                    [
                      _c("div", { class: _vm._topFlagClass }),
                      _vm._v(
                        "\n                        " +
                          _vm._s(_vm.info.title) +
                          "\n                    "
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "mixNewsStyleImgBox7_middleCicle" }),
                _vm._v(" "),
                _c("div", { staticClass: "mixNewsStyleImgBox7_right" }, [
                  _c(
                    "div",
                    {
                      staticClass: "newsTitlePic J_newsTitlePic J_img_lazyload",
                      attrs: { alt: _vm.altName, "src-original": _vm.imgPath },
                    },
                    [
                      _c(
                        "svg",
                        { attrs: { xmlns: "http://www.w3.org/2000/svg" } },
                        [
                          _c("defs", [
                            _c(
                              "clipPath",
                              {
                                attrs: {
                                  id:
                                    "clippath" + _vm.module.id + "" + _vm.index,
                                },
                              },
                              [_c("polygon", { attrs: { points: "" } })]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("image", {
                            style: _vm.imgStyle,
                            attrs: {
                              "xmlns:xlink": "http://www.w3.org/1999/xlink",
                              alt: _vm.altName,
                              "xlink:href": _vm.noLazyLoad
                                ? _vm.imgPath
                                : _vm.options.loadingPath,
                              preserveAspectRatio: "none",
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                ]),
              ]),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }