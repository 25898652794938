var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("module-frame", { attrs: { "module-id": _vm.moduleId } }, [
    _c("div", { staticClass: "count_down" }, [
      _vm.content.moduleStyleType !== 3
        ? _c("div", { style: _vm.countDownNormalStyle }, [
            _vm.content.isShowText
              ? _c("div", {
                  style: _vm.countDownTextStyle,
                  domProps: { innerHTML: _vm._s(_vm.curText) },
                })
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "count_down_time_item_wrap" },
              [
                _vm.isShowDay
                  ? _c("time-item", {
                      ref: "timeItem",
                      attrs: {
                        "is-unit-downstyle": _vm.isUnitDownstyle,
                        "open-number-bg": _vm.openNumberBg,
                        "number-bg-color": _vm.numberBgColor,
                        "number-style": _vm.numberStyle,
                        "unit-style": _vm.unitStyle,
                        "unit-text": _vm.extInfo.dayText,
                        "number-text": _vm.day,
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.isShowHour
                  ? _c("time-item", {
                      attrs: {
                        "is-unit-downstyle": _vm.isUnitDownstyle,
                        "open-number-bg": _vm.openNumberBg,
                        "number-bg-color": _vm.numberBgColor,
                        "number-style": _vm.numberStyle,
                        "unit-style": _vm.unitStyle,
                        "unit-text": _vm.extInfo.hourText,
                        "number-text": _vm.hour,
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.isShowMintues
                  ? _c("time-item", {
                      attrs: {
                        "is-unit-downstyle": _vm.isUnitDownstyle,
                        "open-number-bg": _vm.openNumberBg,
                        "number-bg-color": _vm.numberBgColor,
                        "number-style": _vm.numberStyle,
                        "unit-style": _vm.unitStyle,
                        "unit-text": _vm.extInfo.minuteText,
                        "number-text": _vm.minute,
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.isShowSecond
                  ? _c("time-item", {
                      attrs: {
                        "is-unit-downstyle": _vm.isUnitDownstyle,
                        "open-number-bg": _vm.openNumberBg,
                        "number-bg-color": _vm.numberBgColor,
                        "number-style": _vm.numberStyle,
                        "unit-style": _vm.unitStyle,
                        "unit-text": _vm.extInfo.secondText,
                        "number-text": _vm.second,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.content.moduleStyleType === 3
        ? _c("div", { staticClass: "ring_count_down" }, [
            _vm.content.isShowText
              ? _c("div", {
                  staticClass: "ring_count_down_text",
                  style: _vm.curTextStyle,
                  domProps: { innerHTML: _vm._s(_vm.curText) },
                })
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "count_down_progress_ring_wrap" },
              [
                _vm.isShowDay
                  ? _c("progress-ring", {
                      style: _vm.progressStyle,
                      attrs: {
                        progress: _vm.dayProgress,
                        "bottom-ring-stroke-color": _vm.circleColor,
                        "up-ring-stroke-color": _vm.isFinished
                          ? _vm.countDownEndCircleColor
                          : _vm.loadingCircleColors[0],
                        "top-text-style": _vm.numberStyle,
                        "bottom-style": _vm.unitStyle,
                        "unit-text": _vm.extInfo.dayText,
                        "number-text": _vm.day,
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.isShowHour
                  ? _c("progress-ring", {
                      style: _vm.progressStyle,
                      attrs: {
                        progress: _vm.hourProgress,
                        "bottom-ring-stroke-color": _vm.circleColor,
                        "up-ring-stroke-color": _vm.isFinished
                          ? _vm.countDownEndCircleColor
                          : _vm.loadingCircleColors[1],
                        "top-text-style": _vm.numberStyle,
                        "bottom-style": _vm.unitStyle,
                        "unit-text": _vm.extInfo.hourText,
                        "number-text": _vm.hour,
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.isShowMintues
                  ? _c("progress-ring", {
                      style: _vm.progressStyle,
                      attrs: {
                        progress: _vm.minuteProgress,
                        "bottom-ring-stroke-color": _vm.circleColor,
                        "up-ring-stroke-color": _vm.isFinished
                          ? _vm.countDownEndCircleColor
                          : _vm.loadingCircleColors[2],
                        "top-text-style": _vm.numberStyle,
                        "bottom-style": _vm.unitStyle,
                        "unit-text": _vm.extInfo.minuteText,
                        "number-text": _vm.minute,
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.isShowSecond
                  ? _c("progress-ring", {
                      style: _vm.progressStyle,
                      attrs: {
                        progress: _vm.secondProgress,
                        "bottom-ring-stroke-color": _vm.circleColor,
                        "up-ring-stroke-color": _vm.isFinished
                          ? _vm.countDownEndCircleColor
                          : _vm.loadingCircleColors[3],
                        "top-text-style": _vm.numberStyle,
                        "bottom-style": _vm.unitStyle,
                        "unit-text": _vm.extInfo.secondText,
                        "number-text": _vm.second,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }