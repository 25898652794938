import { ing } from '@/shared/fai';
import { addRequestPrefix } from '@/shared/util';
import { bindCommImgShow } from './swipe';
import { bindImgLazyLoad } from '@/shared/imageLazyload';
export function setCommImgBindShow() {
    setTimeout(function () {
        var listNumObject = jm('.ct-s-content-m');
        if (typeof listNumObject != 'undefined' && listNumObject != null) {
            for (var i = 0; i < listNumObject.length; i++) {
                var images = listNumObject.eq(i)[0].querySelectorAll('img');
                if (typeof images != 'undefined' && images != null && images.length > 0) {
                    bindCommImgShow(images, false, 0);
                }
            }
        }
    }, 500);
}

export const getCommentAjaxPagenation = function (pid, pageno) {
    if (!pid) return;
    var r = jm('html').css('font-size');
    r = r.substring(0, r.length - 2);
    var ajax_data =
        'pid=' + pid + '&pageno=' + pageno + '&ajaxPage=' + true + '&rem=' + r + '&loadingPath=' + Fai.top._loadingPath;
    jm.ajax({
        type: 'post',
        url:
            addRequestPrefix({ newPath: '/ajax', oldPath: 'ajax' }) +
            '/product_h.jsp?cmd=getWafNotCk_getProductInfoComment',
        data: ajax_data,
        error() {
            ing(LS.js_systemError);
        },
        success(result) {
            var data = jm.parseJSON(result);
            if (!data.html) data.html = '';
            jm('#tabPanel3 .commentBoxPanel').remove();
            document.getElementById('tabPanel3').insertBefore(jm(data.html)[0], jm('#tabPanel3 .userComment')[0]);
            setTimeout(function () {
                bindImgLazyLoad('tabPanel3');
            }, 0);
            setCommImgBindShow();
        },
    });
};
