var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "mProductTextForm J_product_item",
      attrs: {
        id: _vm.itemId,
        topClassName: _vm._topInfo.topName,
        topSwitch: _vm._topInfo.topSwitch,
        productId: _vm.info.id,
        productName: _vm.info.name,
        title: _vm._title2,
      },
    },
    [
      _c(
        "a",
        { attrs: { href: _vm.info.extInfo.href, onclick: _vm.onclickStr } },
        [
          _c(
            "div",
            {
              staticClass: "paramCollection separatorLineClass",
              style: _vm.separatorLineStyle,
            },
            [
              _vm.module.content.showProName
                ? _c(
                    "div",
                    {
                      staticClass:
                        "g_link paramName g_productName g_newProductName",
                      class: [_vm.pClasses, _vm._proNameWrapClass],
                      style: _vm.productName_style,
                    },
                    [
                      _vm.info.isTimedAdded
                        ? _c(
                            "span",
                            {
                              staticClass:
                                "g_oldThemeBgColor m_timedAddTag g_main_bgColor_v3",
                            },
                            [_vm._v("即将开售")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.info.extInfo.salePromotionOpen &&
                      !_vm.info.isTimedAdded
                        ? _c("sale-flag", { attrs: { info: _vm.info } })
                        : _vm._e(),
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.info.name) +
                          "\n                "
                      ),
                      _vm.manageMode && _vm.info.top != 0 && !_vm._productSelect
                        ? _c("div", { staticClass: "f-productListTopFlag" }, [
                            _vm._v("置顶"),
                          ])
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.isNewAddModule &&
              _vm._showMallCart.mallCart2 &&
              _vm.module.content.picSetting.picSizeSet == 1
                ? _c("mall-cart", {
                    attrs: { info: _vm.info, holder: _vm._showMallCartHolder },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("prop-panel", {
                attrs: {
                  info: _vm.info,
                  list: _vm._showPropList,
                  "ext-info": _vm._extInfo,
                  "show-mall-cart": _vm._showMallCart,
                  "text-align-class": _vm.textAlignClass,
                  "no-name-wrap": _vm.noNameWrap,
                },
              }),
            ],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }