<template>
    <module-frame :module-id="moduleId">
        <div class="fk-productGroup J_style" :_style="moduleStyle" :class="moduleClasses">
            <!-- 分类为空时显示 -->
            <EmptyText
                v-if="emptyShow"
                :text="showText"
                :button-text="showButtonText"
                :manage-mode="manageMode"
                @add-click="handleEmptyClick"
            ></EmptyText>
            <template v-else-if="showFirstLevStyle">
                <!-- 一级 指定 样式 3\4-->
                <GridPhoto
                    v-if="moduleStyle == 9"
                    :list="firstLevList"
                    :no-lazy-load="noLazyLoad"
                    :pr-url="prUrl"
                    :res-root="resRoot"
                ></GridPhoto>
                <GroupText
                    v-if="moduleStyle == 10"
                    :list="firstLevList"
                    :pr-url="prUrl"
                    :res-root="resRoot"
                ></GroupText>
            </template>
            <template v-else>
                <ProductGroupNav
                    v-if="showNav"
                    :module-id="moduleId"
                    :p-type="navSelType"
                    :list="firstLevList"
                    :search-id="searchId"
                    :search-name="searchName"
                    @select="handleHasProductClick"
                ></ProductGroupNav>
                <!-- 二级 样式345-->
                <ProductPhotoGroupingPanel
                    v-if="showGroupPhotoPanel"
                    :module-id="moduleId"
                    :pr-url="prUrl"
                    :list="photoGroupPanelList"
                    :catalog-name="catalogName"
                    :max-product-group="maxProductGroup"
                    :module-style="moduleStyle"
                    :res-root="resRoot"
                    :nav-index="navIndex"
                    :no-lazy-load="noLazyLoad"
                ></ProductPhotoGroupingPanel>
                <!-- 三级 样式345-->
                <template v-if="showLev == 3 && (moduleStyle == 5 || moduleStyle == 6 || moduleStyle == 7)">
                    <ProductPhotoPanel
                        :module-id="moduleId"
                        :pr-url="prUrl"
                        :module-style="moduleStyle"
                        :max-product-group="maxProductGroup"
                        :search-id="searchId"
                        :search-name="searchName"
                        :second-group-map="productPhotoPanelList"
                        :res-root="resRoot"
                        :nav-index="navIndex"
                        :no-lazy-load="noLazyLoad"
                    >
                    </ProductPhotoPanel>
                </template>
                <!-- 样式12-->
                <div :id="'productList' + moduleId" :class="productListClass">
                    <ProductGroupLoading v-if="loading" :height="grouplistHeight"></ProductGroupLoading>
                    <template v-if="!showOldStyle">
                        <div v-if="showProductItem" class="J_groupItem" :gcn="catalogName">
                            <GroupBanner :nav-index="navIndex" :no-lazy-load="noLazyLoad"></GroupBanner>
                            <!-- 一级 指定 样式 1、2-->
                            <template v-if="showLev == 1">
                                <span v-if="!searchList || searchList.length == 0" style="font-size: 0.6rem">{{
                                    noProductText
                                }}</span>
                                <ProductItem
                                    v-for="item in catalogGroupList"
                                    :key="item.id"
                                    :module-id="moduleId"
                                    :mall-open="mallOpen"
                                    :booking-open="bookingOpen"
                                    :product-form-open="productFormOpen"
                                    :booking-btn-text="bookingBtnText"
                                    :product="item"
                                    :pn="product_name_style"
                                    :module-style="moduleStyle"
                                    :no-lazy-load="noLazyLoad"
                                    @clickCart="handleCart"
                                >
                                </ProductItem>
                            </template>
                            <!-- 二级 样式1、2-->
                            <template v-if="showLev == 2">
                                <template v-for="item in catalogGroupingListData">
                                    <div :key="item.name" class="group_name" v-text="item.name"></div>
                                    <div
                                        v-if="!searchList || searchList.length == 0"
                                        :key="item.name"
                                        style="font-size: 0.6rem; margin-top: 0.2rem"
                                    >
                                        {{ noProductText }}
                                    </div>
                                    <ProductItem
                                        v-for="childItem in filterMaxProduct(item.catalogGroupItemList)"
                                        :key="childItem.id"
                                        :module-id="moduleId"
                                        :mall-open="mallOpen"
                                        :booking-open="bookingOpen"
                                        :product-form-open="productFormOpen"
                                        :booking-btn-text="bookingBtnText"
                                        :product="childItem"
                                        :pn="product_name_style"
                                        :module-style="moduleStyle"
                                        :no-lazy-load="noLazyLoad"
                                        @clickCart="handleCart"
                                    >
                                    </ProductItem>
                                </template>
                            </template>
                        </div>
                    </template>
                </div>
                <!-- 旧样式 -->
                <template v-if="showOldStyle">
                    <template v-if="module.content.type == 2">
                        <ProductGroupCatalog
                            v-for="(item, index) in catalogList"
                            :key="index"
                            :item-data="item"
                            :module-id="moduleId"
                            :module-style="moduleStyle"
                            :lan-code="lanCode"
                            :pn="product_sort_style"
                            :npgc-url="npgcUrl"
                            @itemClick="catalogProductItemClick"
                        >
                        </ProductGroupCatalog>
                        <div class="g_separator separatorLine"></div>
                    </template>
                    <template v-if="module.content.type == 0">
                        <ProductGroupAll
                            v-for="item in pgListV1"
                            :key="item.id"
                            :item-data="item"
                            :module-style="moduleStyle"
                            :new-npgc-url="newNpgcUrl"
                            :pr-url="prUrl"
                            :module-id="moduleId"
                            :pn="product_sort_style"
                            @itemClick="pgListLv1ItemClick"
                        >
                        </ProductGroupAll>
                        <div class="g_separator separatorLine"></div>
                    </template>
                    <template v-if="module.content.type == 1">
                        <div v-for="(item, index) in chooseMap" :key="index" class="line j-lineH J_lineH icon-gline">
                            <a
                                class="pgName paNameFillWidth"
                                :href="prUrl + '?mid=' + moduleId + '&groupId=' + item.id"
                                target="_self"
                            >
                                <span
                                    class="navLineTitle g_productSort_name"
                                    :style="product_sort_style"
                                    v-text="item.name"
                                ></span>
                            </a>
                        </div>
                        <div class="g_separator separatorLine"></div>
                    </template>
                </template>
                <!-- 购物车-->
                <template v-if="moduleStyle == 8">
                    <product-group-text-style
                        :data-map="productGrouptextStyleData"
                        @itemClick="pgListLv1ItemClick"
                    ></product-group-text-style>
                </template>
                <div :id="'productMallOptionPanel_mcs_' + moduleId" style="display: none">
                    <div class="productMallOptionPanel" :moduleId="moduleId">
                        <div class="productMallOptionContentPanel">
                            <div class="productMallOptionContent">
                                <div
                                    class="productDetailClose icon-Off g_iconMiddle icon-defaultColor"
                                    style="cursor: default; z-index: 2"
                                ></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div :id="'productCatagoryPdPanle' + moduleId" class="proImgSwipe">
                    <div class="proDetailImgBox">
                        <div class="swipImgBox">
                            <div vwidth="450" vheight="250" class="proDetailImg"></div>
                            <div style="display: none"><img id="J_acct_pic" /></div>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </module-frame>
</template>
<script>
import ModuleFrame from '@/modules/frame/index.vue';
import ProductGroupNav from './components/product-group-nav.vue';
import ProductItem from './components/product-item.vue';
import ProductPhotoGroupingPanel from './components/product-photo-group-panel.vue';
import ProductPhotoPanel from './components/product-photo-panel.vue';
import ProductGroupCatalog from './components/product-group-catalog.vue';
import ProductGroupAll from './components/product-group-all.vue';
import ProductGroupLoading from './components/product-group-loading.vue';
import EmptyText from './components/product-group-empty.vue';
import GroupBanner from './components/group-banner.vue';
import GridPhoto from './components/grid-photo.vue';
import GroupText from './components/group-text.vue';
import ProductGroupTextStyle from './components/product-group-text-style.vue';

import { getJumpModuleInfo, bindJumpModuleEvent, createFullScreenDiv } from '../../comm';
import { mapGetters } from 'vuex';
import { getSiteMultiLanguageUrl } from '@/shared/url';
import { ing } from '@/shared/fai';
import { bindImgLazyLoad } from '@/shared/imageLazyload';
import { decrease2, increase2, initModuleProductListItemManage, productGroupBannerUpload } from './product-group';
import { initModulePhotoSwipe } from '@/modules/shared/photo';
import { mallBuy } from '@/shared/mall';

import { initWebPage } from '@/shared/misc/init-webpage';
import { changeHref } from '@/features/router/universal';
import { ajaxModule } from '@/api/module';
import { setUrlArgToCookieAndJump } from '@/modules/comm';
export default {
    name: 'ProductGroup',
    components: {
        ModuleFrame,
        ProductGroupNav,
        ProductItem,
        ProductPhotoGroupingPanel,
        ProductPhotoPanel,
        ProductGroupCatalog,
        ProductGroupAll,
        ProductGroupLoading,
        EmptyText,
        GroupBanner,
        GridPhoto,
        GroupText,
        ProductGroupTextStyle,
    },
    provide() {
        return {
            module: this.module,
            options: this.options,
        };
    },
    props: {
        moduleId: {
            type: Number,
            default: -1,
        },
    },
    data() {
        return {
            productListClass: ['first_panel', 'productList' + this.moduleId],
            $module: null,
            grouplistHeight: 0,
            panelHeight: 0,
            searchNameIndex: 0,
            productShowCart: null,
            loading: true,
            firstGrouplistDom: null, // 用于横向滑动
            firstGroupItemDom: null,
            firstGroupItemMarginWidth: null,
            firstGroupAllWidth: 0,
            myScroll: null,
            navIndex: 0,
            noLazyLoad: false,
            catalogGroup: [],
            search: [],
            searchId: -1,
            searchName: '',
            catalogGrouping: [],
        };
    },
    computed: {
        ...mapGetters(['getModuleById']),
        module() {
            return this.getModuleById(this.moduleId);
        },
        options() {
            return this.module.renderOptions;
        },
        searchGroupName() {
            let searchName = this.module.other.searchGroupName;
            if (searchName == null && searchName == '') {
                searchName = this.module.content.catalogList.length == 0 ? '' : this.module.content.catalogList[0].name;
            }
            return searchName;
        },
        searchGroupId() {
            return this.module.other.searchGroupId || 0;
        },
        catalogGroupListData() {
            return this.module.cataLogData.catalogGroupListData || [];
        },
        searchList() {
            return this.search;
        },
        isNewModule() {
            return this.module.content.inm == 1;
        },
        showAllGroupProductStyle() {
            return this.realLev == 0 && (this.moduleStyle == 3 || this.moduleStyle == 4);
        },
        showOldStyle() {
            return this.moduleStyle < 3;
        },
        productPhotoPanelList() {
            return this.secondGroupMap;
        },
        showAllGroupLev2() {
            return this.isNewModule && this.realLev == 0 && this.showLev == 2;
        },
        showAllGroupLev3() {
            return this.realLev == 0 && (this.showLev == 3 || !this.isNewModule);
        },
        realLev() {
            return this.module.content.sl;
        },
        topLev() {
            if (VITE_APP_MODE !== 'visitor') {
                var lev = 1;
                if (this.unFirstGroupList.length < 1) {
                    lev = 1;
                } else {
                    lev = 2;
                    for (let i = 0; i < this.unFirstGroupList.length; i++) {
                        const groupInfo = this.unFirstGroupList[i];
                        if (
                            this.module.groupListOptions.productGroupList.find((item) => item.parentId == groupInfo.id)
                        ) {
                            lev = 3;
                            break;
                        }
                    }
                }
                return lev;
            } else {
                return this.module.exctInfo.topLevel;
            }
        },
        showLev() {
            let lev = this.module.content.sl;
            if (lev == 0) {
                // 根据网站的分类最高层级展示
                if (this.moduleStyle == 3 || this.moduleStyle == 4) {
                    // 三级，34样式
                    lev = 1;
                } else if (this.module.content.inm == 0) {
                    // 旧模块的全部分类还是保持展示三级
                    lev = 3;
                } else {
                    lev = this.topLev;
                }
            }
            return lev;
        },
        groupType() {
            return this.module.content.nt;
        },
        showFirstLevStyle() {
            return this.showLev == 1 && [9, 10].includes(this.moduleStyle);
        },
        isGroup() {
            // 是否是分组
            return ![0, 1].includes(this.moduleStyle) && this.groupType == 1;
        },
        isAssign() {
            // 是否是指定分类
            var flag = false;
            if (this.showLev == 1) {
                flag = true;
            } else if ((this.showLev != 0) & (this.groupType == 0)) {
                flag = true;
            }
            return flag;
        },
        isAllGroup() {
            // 全部分类
            return this.module.content.sl == 0;
        },
        showProductItem() {
            if (this.loading) return false;

            if (this.moduleStyle == 3 || this.moduleStyle == 4) {
                return this.showLev == 1 || this.showLev == 2 || this.showLev == 3;
            }
            return false;
        },
        showGroupPhotoPanel() {
            if (this.showLev == 2 && (this.moduleStyle == 5 || this.moduleStyle == 6 || this.moduleStyle == 7)) {
                return true;
            }
            return false;
        },
        firstLevList() {
            return this.groupList;
        },
        groupList: function () {
            if (this.isAllGroup) {
                return this.firstGroupList;
            } else if (this.isGroup) {
                return this.catalogList;
            } else if (this.isAssign) {
                return this.assignGroupList;
            }
            return [];
        },
        catalogList: function () {
            return this.module.content.catalogList || [];
        },
        navSelType() {
            if (this.showLev == 2 || (this.showLev == 3 && this.groupType == 1)) {
                return 'name';
            } else {
                return 'id';
            }
        },
        showNav: function () {
            if (this.moduleStyle < 3 || this.moduleStyle > 7) {
                return false;
            } else if ((this.isAssign || this.isAllGroup || this.showLev == 3) && this.groupList.length < 1) {
                return false;
            }
            return true;
        },
        curGroupBanner: function () {
            return this.module.content.ap[this.navIndex] || {};
        },
        curIsDefaultBanner: function () {
            return !this.curGroupBanner || !this.curGroupBanner.pi;
        },
        groupBannerStyle: function () {
            var style = {},
                adPicInfo = this.curGroupBanner;

            if (this.module.content.apt == 0 || !adPicInfo.s) {
                style.display = 'none';
            } else {
                if (!this.curIsDefaultBanner) {
                    style.backgroundImage = `url(${adPicInfo.p})`;
                }
                switch (this.module.content.apst) {
                    case 1:
                        style.backgroundSize = 'contain';
                        break;
                    case 2:
                        style.backgroundSize = '100% 100%';
                        break;
                    case 3:
                        style.backgroundSize = 'auto';
                        break;
                }
            }
            return style;
        },
        styleId: function () {
            return this.module.style;
        },
        pgType: function () {
            if (this.isAllGroup) {
                return 0;
            } else if (this.isAssign) {
                return 1;
            } else if (this.isGroup) {
                return 2;
            }
            return void 0;
        },
        moduleStyle: function () {
            return this.module.content.s;
        },

        manageMode: function () {
            return VITE_APP_MODE !== 'visitor';
        },

        noProductText: function () {
            return this.module.other.noProductText;
        },

        photoGroupPanelList() {
            if ((this.showLev == 2 && this.groupType == 0) || this.showAllGroupLev2) {
                return this.transferGroupCatalogList;
            } else if (this.isAssign) {
                return this.catalogGroupList;
            } else if (this.isGroup) {
                return this.groupCatalogList;
            }
            return [];
        },
        transferGroupCatalogList: function () {
            // 二级，指定分类，样式3-5数据
            let list = [];
            if (VITE_APP_MODE !== 'visitor') {
                this.firstGroupList.forEach((item) => {
                    let listItem = {};
                    listItem.name = item.name;
                    listItem.groupList = this.module.groupListOptions.productGroupList.filter((gitem) => {
                        return gitem.parentId == item.id;
                    });
                    list.push(listItem);
                });
            } else {
                return this.module.exctInfo.transferGroupCatalogList;
            }
            return list;
        },
        groupCatalogList: function () {
            if (VITE_APP_MODE !== 'visitor') {
                var list = this.catalogList;
                var productList = this.module.groupListOptions.productGroupList || [];
                let result = list.map((item) => {
                    let obj = {};
                    obj.name = item.name;
                    obj.groupList = [];
                    for (let i = 0; i < item.groupList.length; i++) {
                        let id = item.groupList[i];
                        for (let j = 0; j < productList.length; j++) {
                            if (productList[j].id == id) {
                                obj.groupList.push(productList[j]);
                                break;
                            }
                        }
                    }
                    return obj;
                });
                return result;
            } else {
                var tempResult = this.module.exctInfo.groupCatalogList || [];
                return tempResult;
            }
        },

        pgListV1: function () {
            if (VITE_APP_MODE !== 'visitor') {
                let pgList = this.module.groupListOptions.productGroupList || [];
                let groupMap = {};
                let pgListV1 = [];
                pgList.forEach((item) => {
                    let parentId = item.parentId;
                    let data = null;
                    if (item.parentId != 0) {
                        data = groupMap[parentId];
                        if (data == null) {
                            groupMap[parentId] = [];
                        }
                        groupMap[parentId].push(JSON.parse(JSON.stringify(item)));
                    }
                });

                pgList.forEach((item) => {
                    if (!(item.parentId != 0 || item.id == 0)) {
                        let pgInfo = JSON.parse(JSON.stringify(item));
                        let children = groupMap[item.id];
                        if (children != null) {
                            pgInfo.hasChildren = true;
                        } else {
                            pgInfo.hasChildren = false;
                        }
                        pgListV1.push(pgInfo);
                    }
                });
                return pgListV1;
            } else {
                var tempResult = this.module.exctInfo.pgListV1 || [];
                return tempResult;
            }
        },
        pgListV2: function () {
            if (VITE_APP_MODE !== 'visitor') {
                var pgListLv2 = [];
                if (this.showLev != 1 && this.module.content.nt == 1) {
                    // 分组分类
                    for (let i = 0; i < this.module.content.catalogList.length; i++) {
                        let groupCatalog = this.module.content.catalogList[i];
                        let itemInfo = {};
                        let catalogGroupList = groupCatalog.groupList;
                        if (catalogGroupList.length > 0) {
                            itemInfo.hasChildren = true;
                        }

                        itemInfo.id = i;
                        itemInfo.name = groupCatalog.name;
                        itemInfo.groupList = catalogGroupList;
                        pgListLv2.push(itemInfo);
                    }
                } else if (this.module.content.nt == 0) {
                    // 指定分类
                    for (let i = 0; i < this.module.groupListOptions.productGroupList.length; i++) {
                        const pgInfo = this.module.groupListOptions.productGroupList[i];
                        const pgId = pgInfo.id;

                        // 只选择父类
                        if (pgInfo.parentId != 0 && this.showLev != 1) {
                            continue;
                        }

                        if (this.module.content.idList.includes(pgId)) {
                            let itemInfo = {};
                            let catalogGroupList = [];
                            itemInfo.id = pgId;
                            itemInfo.name = pgInfo.name;
                            this.unFirstGroupList.forEach((item) => {
                                if (item.parentId == pgId) {
                                    catalogGroupList.push(item.id);
                                }
                            });

                            if (catalogGroupList.length > 0) {
                                itemInfo.hasChildren = true;
                            }
                            itemInfo.groupList = catalogGroupList;
                            pgListLv2.push(itemInfo);
                        }
                    }
                }
                return pgListLv2;
            } else {
                return this.module.exctInfo.pgListLv2 || [];
            }
        },
        chooseMap: function () {
            if (VITE_APP_MODE !== 'visitor') {
                let pgList = this.module.groupListOptions.productGroupList || [];
                let idList = this.module.content.idList;
                let chooseArr = [];
                let chooseParentId = [];
                if (pgList == null) {
                    pgList = [];
                }
                pgList.forEach((item) => {
                    if (idList.indexOf(item.id) != -1) {
                        chooseArr.push(JSON.parse(JSON.stringify(item)));
                        chooseParentId.push(item.id);
                    }
                });

                pgList.forEach((item) => {
                    if (item.parentId != 0 && item.id != 0) {
                        var index = chooseParentId.indexOf(item.parentId);
                        if (index != -1) {
                            chooseArr[index].hasChildren = true;
                        }
                    }
                });
                return chooseArr;
            } else {
                var tempResult = this.module.exctInfo.chooseMap || [];
                return tempResult;
            }
        },

        assignGroupList: function () {
            if (VITE_APP_MODE !== 'visitor') {
                let productGroupList = this.module.groupListOptions.productGroupList || [];
                let idList = this.module.content.idList;
                let assignList = [];
                if (this.showLev == 1) {
                    productGroupList.forEach((item) => {
                        if (idList.indexOf(item.id) != -1 && item.id != 0 && item.parentId != -1) {
                            assignList.push(JSON.parse(JSON.stringify(item)));
                        }
                    });
                } else if (this.showLev == 2 || this.showLev == 3) {
                    productGroupList.forEach((item) => {
                        if (idList.indexOf(item.id) != -1 && item.id != 0 && item.parentId == 0) {
                            assignList.push(JSON.parse(JSON.stringify(item)));
                        }
                    });
                }
                return assignList;
            } else {
                var tempResult = this.module.exctInfo.assignGroupList || [];
                return tempResult;
            }
        },

        firstGroupList: function () {
            if (VITE_APP_MODE !== 'visitor') {
                let productGroupList = this.module.groupListOptions.productGroupList || [];
                let firstList = [];
                productGroupList.forEach((item) => {
                    if (item.parentId == 0 && item.id != 0) {
                        firstList.push(JSON.parse(JSON.stringify(item)));
                    }
                });
                return firstList;
            } else {
                var tempResult = this.module.exctInfo.firstGroupList;
                return tempResult;
            }
        },
        unFirstGroupList: function () {
            if (VITE_APP_MODE !== 'visitor') {
                let productGroupList = this.module.groupListOptions.productGroupList || [];
                let unFirstList = [];
                productGroupList.forEach((item) => {
                    if (item.parentId != 0 && item.id != 0 && item.parentId != -1) {
                        unFirstList.push(JSON.parse(JSON.stringify(item)));
                    }
                });
                return unFirstList;
            } else {
                var tempResult = this.module.exctInfo.unFirstGroupList || [];
                return tempResult;
            }
        },
        secondGroupList: function () {
            if (VITE_APP_MODE !== 'visitor') {
                let unFirstList = this.unFirstGroupList || [];
                let secondGroup = [];
                unFirstList.forEach((item) => {
                    let arr = this.getGroupList(unFirstList, 'parentId', item.id) || [];
                    let secondItem = JSON.parse(JSON.stringify(item));
                    secondItem['thirdGroupList'] = arr;
                    secondGroup.push(secondItem);
                });
                return secondGroup;
            } else {
                var tempResult = this.module.exctInfo.secondGroupList || [];
                return tempResult;
            }
        },
        secondGroupMap: function () {
            // let map = {};
            if (VITE_APP_MODE !== 'visitor') {
                let map = [];
                let secondList = this.secondGroupList || [];
                let firstGroupList = [];
                let diff = false;
                let _this = this;

                if (this.isAllGroup) {
                    firstGroupList = this.firstGroupList;
                } else if (this.showLev == 3 && this.groupType == 0) {
                    firstGroupList = this.firstLevList;
                } else if (this.showLev == 3 && this.groupType == 1) {
                    diff = true;
                }

                if (!diff) {
                    // 三级分类数据
                    firstGroupList.forEach((item) => {
                        let obj = {};
                        obj.key = item.id;
                        obj.data = [];
                        let secondListFor = this.getGroupList(secondList, 'parentId', item.id) || [];
                        let index = 0;
                        for (let i = 0; i < secondListFor.length; i++) {
                            if (index >= this.maxProductGroup) {
                                break;
                            }
                            index++;
                            let secondItem = JSON.parse(JSON.stringify(secondListFor[i]));
                            let thirdList = secondItem.thirdGroupList || [];
                            let realList = [];
                            for (let j = 0; j < thirdList.length; j++) {
                                if (index < this.maxProductGroup) {
                                    index++;
                                    realList.push(thirdList[j]);
                                } else {
                                    break;
                                }
                            }
                            secondItem.thirdGroupList = realList;
                            obj.data.push(secondItem);
                        }
                        map.push(obj);
                    });
                } else {
                    // 三级分组
                    for (let i = 0; i < this.firstLevList.length; i++) {
                        let info = this.firstLevList[i];
                        let secLevList = [];
                        info.groupList.forEach((childId) => {
                            let child = this.productGroupList.find((item) => item.id == childId);
                            !!child && secLevList.push(child);
                        });

                        secLevList.forEach((item) => {
                            item.thirdGroupList = _this.productGroupList.filter((sitem) => sitem.parentId == item.id);
                        });

                        map.push({ key: info.name, data: secLevList });
                    }
                }

                return map;
            } else {
                var tempResult = this.module.exctInfo.secondGroupMap || [];
                return tempResult;
            }
        },
        prUrl: function () {
            return this.module.other.prUrl;
        },
        resRoot: function () {
            return this.module.other.resRoot;
        },
        productGroupList: function () {
            return this.module.groupListOptions.productGroupList || [];
        },
        productGroupIdList: function () {
            if (VITE_APP_MODE !== 'visitor') {
                return this.productGroupList.map((item) => {
                    return item.id;
                });
            } else {
                return this.module.exctInfo.productGroupIdList;
            }
        },
        catalogName: function () {
            if (this.searchName == null || this.searchName == '') {
                return this.groupCatalogList.length == 0 ? '' : this.groupCatalogList[0].name;
            } else {
                return this.searchName;
            }
        },
        catalogGroupList: function () {
            return this.catalogGroup.filter((item, index) => index < this.maxProduct);
        },
        catalogGroupingListData: function () {
            return this.catalogGrouping;
        },
        bookingOpen: function () {
            return this.module.cataLogData.bookingOpen;
        },
        productFormOpen: function () {
            return this.module.cataLogData.productFormOpen;
        },
        mallOpen: function () {
            return this.module.cataLogData.mallOpen;
        },
        bookingBtnText: function () {
            return this.module.cataLogData.bookingBtnText;
        },
        maxProduct: function () {
            return this.module.content.mp;
        },
        maxProductGroup: function () {
            return this.module.content.mpg;
        },
        lanCode: function () {
            return this.module.other.lanCode;
        },
        npgcUrl: function () {
            return this.module.other.npgcUrl;
        },
        topId: function () {
            if (this.showLev == 3 && this.moduleStyle == 8) {
                return 'pgList' + this.moduleId;
            } else {
                return '';
            }
        },

        moduleClasses: function () {
            let classes = [];
            if (this.moduleStyle == 3) {
                // 纵向
                classes.push('jz_vertical_group_has_product');
                classes.push('J_verticalGroupHasProduct');
            } else if (this.moduleStyle == 4) {
                // 横向
                classes.push('jz_landscape_group_has_product');
            } else if (this.moduleStyle == 5 && (this.showLev == 3 || this.showLev == 2)) {
                // 纵向
                classes.push('jz_vertical_group_has_photo');
                classes.push('J_verticalGroupHasPhoto');
            } else if (this.moduleStyle == 6 && (this.showLev == 3 || this.showLev == 2)) {
                classes.push('jz_landscape_group_has_photo');
            } else if (this.moduleStyle == 7 && (this.showLev == 3 || this.showLev == 2)) {
                classes.push('jz_vertical_group_no_photo');
                classes.push('J_verticalGroupHasPhoto');
            } else {
                classes.push('sortPanelList');
            }

            if ([5, 6].includes(this.moduleStyle)) {
                // 分类图片自定义设置
                if (this.module.content.gps.t == 1) {
                    classes.push('group_cus_pic');
                }

                if (this.module.content.gps.s != 0) {
                    classes.push('cus_pic_scale');
                }

                if (this.module.content.mhighs.fgn.type == 1) {
                    classes.push('cus_first_lev_text');
                }
            }

            if ([3, 4].includes(this.moduleStyle)) {
                // 分类图片自定义设置
                if (this.module.content.pps.t == 1) {
                    classes.push('product_cus_pic');
                }

                if (this.module.content.pps.s != 0) {
                    classes.push('cus_pic_scale');
                }

                // 产品名称换行
                if (this.module.content.pnw == 1) {
                    classes.push('product_name_no_wrap');
                } else if (this.module.content.pnw == 2) {
                    classes.push('product_name_wrap');
                }
            }

            if ([3, 5, 7, 8, 9, 10].includes(this.moduleStyle)) {
                // 一级分类换行
                if (this.module.content.fgnw == 1) {
                    classes.push('first_group_name_no_wrap');
                } else if (this.module.content.fgnw == 2) {
                    classes.push('first_group_name_wrap');
                }
            }

            if ([5, 6, 7, 8].includes(this.moduleStyle)) {
                // 二级分类换行
                if (this.module.content.sgnw == 1) {
                    classes.push('sec_group_name_no_wrap');
                } else if (this.module.content.sgnw == 2) {
                    classes.push('sec_group_name_wrap');
                }

                // 三级级分类换行
                if (this.module.content.tgnw == 1) {
                    classes.push('third_group_name_no_wrap');
                } else if (this.module.content.tgnw == 2) {
                    classes.push('third_group_name_wrap');
                }
            }

            classes.push(`group_style_${this.moduleStyle}`);

            return classes;
        },

        newNpgcUrl: function () {
            if (this.isMallTheme) {
                return 'pg.jsp';
            } else {
                return this.module.cataLogData.newNpgcUrl;
            }
        },

        isMallTheme: function () {
            return this.module.cataLogData.isMallTheme;
        },

        choiceCurrencyVal: function () {
            return this.module.cataLogData.choiceCurrencyVal;
        },

        mallShoppingOptions: function () {
            return this.module.cataLogData.mallShoppingOptions;
        },

        levelDiscount: function () {
            return this.module.cataLogData.levelDiscount;
        },

        mallCartCount: function () {
            return this.module.cataLogData.mallCartCount;
        },
        siteVer: function () {
            return this.module.other._siteVer;
        },
        showText: function () {
            return this.pgType == 2 ? '请在右侧添加分组' : '请在右侧添加分类';
        },
        showButtonText: function () {
            return this.pgType == 2 ? '添加分组' : '添加分类';
        },
        emptyShow: function () {
            if (this.showOldStyle) {
                if (this.module.content.type == 2) {
                    return this.module.content.catalogList.length < 1;
                } else if (this.module.content.type == 0) {
                    return this.pgListV1.length < 1;
                } else if (this.module.content.type == 1) {
                    return this.chooseMap.length < 1;
                }
            } else if (this.isAllGroup && this.moduleStyle == 8) {
                return this.pgListV1.length < 1;
            } else if ((this.showLev == 3 || this.showLev == 2) && this.moduleStyle == 8) {
                return this.pgListV2.length < 1;
            } else if ((this.showLev == 2 || this.showLev == 3) && this.firstLevList.length < 1) {
                return true;
            } else if (this.isAllGroup && this.firstLevList.length > 0) {
                return false;
            } else if (this.showLev == 3 && this.productGroupIdList != null) {
                return this.productGroupIdList.length == 0 ? true : false;
            } else if (this.isAssign) {
                return this.module.content.idList.length == 0 ? true : false;
            } else if (this.isGroup && this.catalogList != null) {
                return this.catalogList.length == 0 ? true : false;
            }
            return false;
        },
        product_name_style: function () {
            var style = '';
            if (!(VITE_APP_MODE !== 'visitor')) {
                return style;
            }
            var productName = this.module.content.mhighs.pn;
            if (productName.type == 1) {
                if (productName.lfs) {
                    style += 'font-size:' + productName.lfs + 'rem;';
                } else {
                    style += 'font-size:12px;';
                }
                if (productName.lfct == 1) {
                    style += 'color:' + productName.lfc + ';';
                }
                if (productName.lfd == 1) {
                    style += 'text-decoration:underline;';
                } else {
                    style += 'text-decoration:none;';
                }
            }
            return style;
        },
        product_sort_style: function () {
            if (!(VITE_APP_MODE !== 'visitor')) {
                return '';
            }
            var productSortName = this.module.content.mhighs.psn;
            var style = '';
            if (productSortName.type == 1) {
                if (productSortName.nfs) {
                    style += 'font-size:' + productSortName.nfs + 'rem;';
                } else {
                    style += 'font-size:12px;';
                }
                if (productSortName.nfct == 1) {
                    style += 'color:' + productSortName.nfc + ';';
                }
            }
            return style;
        },
        productGrouptextStyleData() {
            return {
                groupType: this.groupType,
                gdo: this.module.content.gdo,
                prUrl: this.prUrl,
                newNpgcUrl: this.newNpgcUrl,
                moduleId: this.moduleId,
                moduleStyle: this.moduleStyle,
                idList: this.module.content.idList,
                catalogList: this.module.content.catalogList,
                showLev: this.module.content.sl,
                productGroupList: this.module.groupListOptions.productGroupList, // 全部产品分类内容
                firstGroupNameStyle: this.product_sort_style,
                mheightSetting: this.module.content.mhighs, // 高级设置项内容
            };
        },
    },
    watch: {
        'options.isFirstPaint': {
            handler(val) {
                this.noLazyLoad = val;
            },
            immediate: true,
        },
        showLev: {
            handler: function (val) {
                this.resetNav();
                this.$nextTick(() => {
                    this.initPgTypeChangeData(val);
                    if (this.moduleStyle == 3 || this.moduleStyle == 4) {
                        this.initProduct();
                        this.initPictrue();
                    }

                    if (this.moduleStyle == 5 || this.moduleStyle == 6) {
                        if (this.moduleStyle == 5) {
                            var groupPanel = this.$module.find('#groupList' + this.moduleId);
                            var groupListHeight = this.$module.find('.J_first_grouplist');
                            if (groupPanel[0] != null && groupListHeight[0] != null) {
                                this.panelHeight = groupPanel[0].clientHeight;
                                this.grouplistHeight = groupListHeight[0].clientHeight;
                                this.initFirstGroupHeight(
                                    'jz_vertical_group_has_photo',
                                    this.panelHeight,
                                    this.grouplistHeight
                                );
                            }
                        } else {
                            this.clearFirstGroupStyle();
                        }
                    }

                    if (this.moduleStyle == 4 || this.moduleStyle == 6) {
                        this.initSlider();
                    } else {
                        this.clearMarginStyle();
                    }
                });

                if (VITE_APP_MODE !== 'visitor') {
                    this.noLazyLoad = true;
                }
            },
        },
        groupType() {
            this.resetNav();
        },
        moduleStyle: {
            handler: function (val) {
                this.$nextTick(() => {
                    if (val == 3 || val == 4) {
                        this.ajaxSearchProduct(this.searchId, this.searchName);
                        this.initPictrue();
                    }

                    if (val == 6) {
                        this.clearFirstGroupStyle();
                    } else if (val == 5) {
                        if (this.$module == null) {
                            this.$module = jm('#module' + this.moduleId);
                        }
                        var groupPanel = this.$module.find('#groupList' + this.moduleId);
                        var groupListHeight = this.$module.find('.J_first_grouplist');
                        if (groupPanel[0] != null && groupListHeight[0] != null) {
                            this.panelHeight = groupPanel[0].clientHeight;
                            this.grouplistHeight = groupListHeight[0].clientHeight;
                            this.initFirstGroupHeight(
                                'jz_vertical_group_has_photo',
                                this.panelHeight,
                                this.grouplistHeight
                            );
                        }
                    }

                    if (val == 4 || val == 6) {
                        this.initSlider();
                    } else {
                        this.clearMarginStyle();
                    }

                    setTimeout(() => {
                        this.initItemHeight();
                    }, 20);
                });
                if (VITE_APP_MODE !== 'visitor') {
                    this.noLazyLoad = true;
                }
            },
        },
        'module.content.mpg': {
            handler: function () {
                this.$nextTick(() => {
                    // 重新渲染图片编辑
                    if (this.moduleStyle == 5 || this.moduleStyle == 6) {
                        // this.initPhotoEdit();
                    }
                    if (this.moduleStyle > 2 && this.moduleStyle < 8) {
                        this.refreshHeight();
                    }
                });
                if (VITE_APP_MODE !== 'visitor') {
                    this.noLazyLoad = true;
                }
            },
        },
        'module.content.mp': {
            handler: function () {
                this.$nextTick(() => {
                    // 重新渲染产品图片编辑
                    if (this.moduleStyle == 3 || this.moduleStyle == 4) {
                        this.ajaxSearchProduct(this.searchId, this.searchName);
                        this.initPictrue();
                    }
                    if (this.moduleStyle > 2 && this.moduleStyle < 8) {
                        this.refreshHeight();
                    }
                });
                if (VITE_APP_MODE !== 'visitor') {
                    this.noLazyLoad = true;
                }
            },
        },
        'module.content.idList': {
            handler: function () {
                this.initProduct();
                this.$nextTick(() => {
                    this.init();
                });
                if (VITE_APP_MODE !== 'visitor') {
                    this.noLazyLoad = true;
                }
            },
        },
        'module.content.fgnw'() {
            this.$nextTick(function () {
                this.initItemHeight();
            });
        },
        'module.content.catalogList': {
            handler: function (val, oldVal) {
                let del = oldVal.length - val.length > 0 ? true : false;
                this.$nextTick(() => {
                    if (del) {
                        // 重新更新searchName
                        if (val.length > 0) {
                            this.searchName = val[0].name;
                            this.searchNameIndex = 0;
                            if (this.moduleStyle == 3 || this.moduleStyle == 4) {
                                this.ajaxSearchProduct(this.searchId, this.searchName);
                            }
                        } else {
                            this.searchName = '';
                            this.searchNameIndex = -1;
                            this.catalogGrouping = [];
                        }
                    } else {
                        // 右侧面板改变分组名称时会改变名称，需要index来修正searchName
                        if (val.length > 0) {
                            this.searchName = this.catalogList[this.searchNameIndex].name;
                        }
                        // 判断当前分组是否类别改变
                        if (val.length == 1 && this.searchName == '') {
                            this.searchName = val[0].name;
                            this.searchNameIndex = 0;
                        }
                        if (this.moduleStyle == 3 || (this.moduleStyle == 4 && val.length > 0)) {
                            this.ajaxSearchProduct(this.searchId, this.searchName);
                        }
                    }
                    if (this.moduleStyle > 2 && this.moduleStyle <= 8) {
                        this.refreshHeight();
                    }

                    if (this.moduleStyle == 3 || this.moduleStyle == 4) {
                        this.initPictrue();
                    }

                    if (this.moduleStyle == 4 || this.moduleStyle == 6) {
                        this.initSlider();
                    } else {
                        this.clearMarginStyle();
                    }
                });

                if (VITE_APP_MODE !== 'visitor') {
                    this.noLazyLoad = true;
                }
            },
            deep: true,
        },
        'module.content.apt': function (val) {
            if (val == 1 && VITE_APP_MODE !== 'visitor') {
                this.noLazyLoad = true;
                this.$nextTick(() => {
                    this.refreshHeight();
                });
            }
        },
        firstLevList: function () {
            if (VITE_APP_MODE !== 'visitor') {
                this.noLazyLoad = true;
            }
        },
        'module.content': {
            // 样式修改，则重新绑定dom节点a链接事件
            handler: function () {
                this.$nextTick(() => {
                    bindJumpModuleEvent(this.moduleId, this.module.style, {
                        pType: this.navSelType,
                        id: this.searchId,
                        name: this.searchName,
                    });
                });
            },
            deep: true,
        },
        catalogGroupListData: {
            immediate: true,
            handler(data = []) {
                this.catalogGroup = data;
            },
        },
        searchList: {
            immediate: true,
            handler(list = []) {
                this.search = list;
            },
        },
        searchGroupId: {
            immediate: true,
            handler(id) {
                this.searchId = id;
            },
        },
        searchGroupName: {
            immediate: true,
            handler(name) {
                this.searchName = name;
            },
        },
        'module.cataLogData': {
            handler(catalog) {
                this.search = catalog.searchList;
                this.catalogGrouping = catalog.catalogGroupingListData || [];
            },
        },
        'module.content.sort.key': {
            handler() {
                this.sortChangedHandler();
            },
        },
        'module.content.sort.desc': {
            handler() {
                this.sortChangedHandler();
            },
        },
    },
    mounted() {
        let result = getJumpModuleInfo(this.module.id);
        this.init(result);
        // 点击选择分类，重新绑定a dom 事件
        bindJumpModuleEvent(this.moduleId, this.module.style, {
            pType: this.navSelType,
            id: this.searchId,
            name: this.searchName,
        });
    },
    methods: {
        sortChangedHandler() {
            this.ajaxSearchProduct(this.searchId, this.seachName);
        },
        filterMaxProduct(list) {
            return list.filter((item, i) => i < this.maxProduct);
        },
        copyData(data) {
            var protoToStr = Object.prototype.toString;
            if (protoToStr.call(data) === '[object Object]') {
                return $.extend(true, {}, data);
            } else if (protoToStr.call(data) === '[object Array]') {
                return $.extend(true, [], data);
            } else {
                return data;
            }
        },
        groupBannerClick: function () {
            if (this.curGroupBanner.i) {
                if (VITE_APP_MODE !== 'visitor' && this.$designer.checkSaveBar()) {
                    return;
                } else if (VITE_APP_MODE !== 'visitor' && !!this.curGroupBanner.ji && this.curGroupBanner.ji.t == 103) {
                    Fai.top.Fai.ing(`管理态不支持跳转外部链接，请通过“预览”进行访问`, true);
                } else {
                    if (this.curGroupBanner.ji && this.curGroupBanner.ji.reqArgs) {
                        setUrlArgToCookieAndJump(this.curGroupBanner.h, this.curGroupBanner.ji.reqArgs);
                    } else {
                        changeHref(this.curGroupBanner.h);
                    }
                }
            }
        },
        groupBannerMouseOver: function () {
            if (!(VITE_APP_MODE !== 'visitor')) return;
            const itemJson = [
                {
                    operationText: '编辑产品',
                    className: 'productListEdit',
                    evalScript: `;`,
                    onClick: () => {
                        productGroupBannerUpload(this.module.id, this.navIndex);
                    },
                },
            ];
            const options = { extCls: 'productListEditLayer' };
            this.$layer.addEditLayer($(this.$refs.groupBanner), itemJson, 6, options);
        },
        groupBannerMouseOut: function () {
            if (!(VITE_APP_MODE !== 'visitor')) return;
            this.$layer.removeEditLayer($(this.$refs.groupBanner));
        },
        // 横向菜单栏使用
        initSlider() {
            if (this.$module == null) {
                this.$module = jm('#module' + this.moduleId);
            }
            if (this.$module != null && (this.moduleStyle === 4 || this.moduleStyle === 6)) {
                this.firstGrouplistDom = this.$module.find('.J_first_grouplist');
                this.firstGroupItemDom = this.firstGrouplistDom.find('.J_first_groupitem');
                this.firstGroupItemMarginWidth = parseInt(this.firstGroupItemDom.css('margin-right')) + 1;
                if (this.moduleStyle === 4) {
                    var pg_startX = parseInt(jm.getCookie('pg_startX')) || 0;
                    this.initFirstGroupAllWidth();
                    this.initSlide(pg_startX);
                }
                if (this.moduleStyle === 6) {
                    var pg_startX_photo = parseInt(jm.getCookie('pg_startX_photo')) || 0;
                    this.initFirstGroupAllWidth();
                    this.initSlide(pg_startX_photo);
                }
            }
        },
        // 横向菜单栏使用
        initFirstGroupAllWidth: function () {
            var _this = this;
            this.firstGroupItemDom = this.firstGroupItemDom == null ? [] : this.firstGroupItemDom;

            this.firstGroupItemDom.each(function (index, el) {
                _this.firstGroupAllWidth +=
                    (Math.ceil(jm(el).width()) + _this.firstGroupItemMarginWidth) / window._htmlFontSize;
            });

            this.firstGrouplistDom.css('width', this.firstGroupAllWidth + 'rem');
        },

        // 横向菜单栏使用
        initSlide: function (pg_startX) {
            if (this.myScroll != null) {
                this.myScroll.destroy();
            }

            const flag = (this.moduleStyle == 6 || this.moduleStyle == 4) && this.firstLevList.length > 0;
            if (flag) {
                if (!document.getElementById('J_first_group_' + this.moduleId)) {
                    return;
                }
                this.myScroll = new iScroll('J_first_group_' + this.moduleId, {
                    x: pg_startX || 0,
                    scrollX: true,
                    scrollY: false,
                    mouseWheel: true,
                    remarkXCoordinate: true,
                    onBeforeScrollStart: null,
                    onScrollMove: null,
                    onBeforeScrollEnd: null,
                });
            }
        },

        pgListLv1ItemClick(item) {
            let reqDatas;
            if ((this.showLev == 3 || this.showLev == 2) && !this.isAllGroup && this.moduleStyle > 2) {
                reqDatas =
                    'sl=' +
                    this.showLev +
                    '&nt=1&mid=' +
                    this.moduleId +
                    '&pgList=' +
                    '[' +
                    item.groupList +
                    ']' +
                    '&gcName=' +
                    item.name +
                    '&id=' +
                    item.id +
                    '&rsl=' +
                    this.realLev;
            } else {
                reqDatas = 'nt=0&mid=' + this.moduleId + '&id=' + item.id + '&rsl=' + this.realLev;
            }
            createFullScreenDiv('pgListDiv', 'getWafNotCk_pAllList', reqDatas);
        },

        catalogProductItemClick(item) {
            var reqDatas = 'mid=' + this.moduleId + '&pgList=' + '[' + item.groupList + ']' + '&gcName=' + item.name;
            createFullScreenDiv('pgListDiv', 'pgCatalogList', reqDatas);
        },

        getGroupList(arr, key, value) {
            if (arr == null) return null;
            let newArr = [];
            arr.forEach((item) => {
                if (item[key] == value) {
                    newArr.push(item);
                }
            });
            return newArr;
        },

        init(selectData) {
            selectData = selectData || {};
            this.$module = jm('#module' + this.moduleId);
            this.firstGrouplistDom = this.$module.find('.J_first_grouplist');
            if (
                this.moduleStyle == 3 ||
                this.moduleStyle == 4 ||
                ((this.showLev == 2 || this.showLev == 3) &&
                    (this.moduleStyle == 5 || this.moduleStyle == 6 || this.moduleStyle == 7))
            ) {
                this.initDom();
                this.initPictrue();
                this.$nextTick(() => {
                    this.refreshHeight();
                });
            }
            if (this.moduleStyle > 2 && this.moduleStyle < 8) {
                var groupList = this.$module.find('.J_first_grouplist');
                if (groupList != null && groupList[0] != null) {
                    this.grouplistHeight = groupList[0].clientHeight;
                }
            }

            if (this.moduleStyle == 4 || this.moduleStyle == 6) {
                this.initSlider();
            }

            if (this.firstLevList && this.firstLevList.length > 0) {
                this.searchId = selectData.id || this.firstLevList[0].id;
                this.searchName = selectData.name || this.firstLevList[0].name;
            }

            if (this.showLev == 2 && this.groupType == 0 && this.groupList.length > 0) {
                this.searchId = selectData.id || this.groupList[0].id;
                this.searchName = selectData.name || this.groupList[0].name;
            }
            if (!this.noLazyLoad) {
                bindImgLazyLoad(`module${this.module.id}`);
            }

            this.initItemHeight();

            if (this.moduleStyle == 3 || this.moduleStyle == 4) {
                this.$nextTick(() => {
                    this.handleHasProductClick(
                        selectData.name ? selectData : this.firstLevList[0],
                        selectData.index || 0,
                        true
                    );
                });
            }
            this.closeLoading();
        },

        initProduct() {
            if (this.moduleStyle == 3 || this.moduleStyle == 4) {
                var item = this.firstLevList[0];
                if (item) {
                    this.ajaxSearchProduct(item.id, item.name);
                } else {
                    this.loading = false;
                }
            } else {
                this.loading = false;
            }
        },

        initItemHeight() {
            if (this.moduleStyle == 9 || (this.showLev == 3 && this.moduleStyle == 6)) {
                let maxHeight = 0;
                let needSet = false;
                let itemSel = '';

                if (this.moduleStyle == 9) {
                    needSet = this.module.content.fgnw == 2;
                    itemSel = '.J_img_panel';
                } else {
                    needSet = this.module.content.tgnw == 2;
                    itemSel = '.J_groupItem';
                }

                this.$module = jm('#module' + this.moduleId);
                if (needSet) {
                    this.$module.find(itemSel).forEach((el) => {
                        const offset = jm(el).offset();
                        maxHeight = offset.height > maxHeight ? offset.height : maxHeight;
                    });

                    this.$module.find(itemSel).forEach((el) => {
                        jm(el).height(maxHeight + 'px');
                    });
                } else {
                    this.$module.find(itemSel).forEach((el) => {
                        jm(el).height('auto');
                    });
                }
            }
        },

        initPictrue() {
            if (VITE_APP_MODE !== 'visitor') {
                initModuleProductListItemManage({
                    productSelect: true,
                    productParent: 'productList' + this.moduleId,
                    product: 'J_product_item',
                    productChild: 'img_panel',
                    moduleId: this.moduleId,
                    frameSrcUrl: getSiteMultiLanguageUrl() + '/manage_v2/productEdit.jsp?ram=' + Math.random(),
                });
            }
        },
        initCart() {
            if (this.mallOpen && (this.moduleStyle == 3 || this.moduleStyle == 4)) {
                let cart = `
					<div class='productMallOptionHead'>
						<div class='productMallOptionImage'>
							<div class='productMallOptionImageContent'>
								<span class="imageMiddleSpan"></span>
								<img class='mallOptionImg' src='' />
							</div>
						</div>
						<div class='productDetail'>
							<div class='productDetailName'></div>
							<div class='productDetailMallAmount'>
								<div class='productMallAmount' style='float:left'></div>
								<div class='productMallWeight' style='float:left;margin-left:0.5rem;'></div>
							</div>
						</div>
					</div>
					<div class='g_separator separatorLine'></div>
					<div class='mallOptionAndNumBox' id='mallOptionAndNumContainer'>
						<div id='mallOptionAndNumBox'>
							<div class='mallNewOptionBox mallOptionBox J-op-box'></div>
							<div class='g_separator separatorLine' style='margin-left: 0.5rem;'></div>
							<div class='productNumberPanel g_middleColor'>
								<span>${this.mallCartCount}</span>
								<div id='limitAmountDiv' style='line-height:0.7rem;color:#828282;width:9rem;'></div>
								<div class='productNumberContent'>
									<span id='g_decrease' class='g_decrease g_opacity50 icon-decrease g_iconMiddle'></span>
									<input type='number' class='g_buyNumber' id='productNum' autocomplete='off' value='1' min='1' max='9999999' />
									<span id='g_increase' class='g_increase icon-increase g_iconMiddle'></span>
								</div>
							</div>
						</div>
					</div>
					<div class='productMallShop'></div>
				`;
                let cartV3 = '';

                if (Fai.top._openThemeV3) {
                    cartV3 = `
						<div class='productMallOptionHead'>
							<div class='J_mallOptionImg mallOptionImg'></div>
							<div class='productDetail'>
								<div class='productDetailPrice'></div>
								<div class='productDetailMallAmount'>
									<div class='productMallAmount' style='float:left'></div>
									<div class='productMallWeight' style='float:left;margin-left:0.5rem;'></div>
								</div>
							</div>
						</div>
						<div class='mallOptionAndNumBox' id='mallOptionAndNumContainer'>
							<div id='mallOptionAndNumBox'>
								<div class='mallNewOptionBox mallOptionBox J-op-box'></div>
								<div class='g_separator separatorLine' style='margin: 0 0.75rem;'></div>
								<div class='productNumberPanel g_middleColor'>
									<span class='productNumberName'>${this.mallCartCount}</span>
									<div id='limitAmountDiv'  class='limitAmountDiv'></div>
									<div class='productNumberContent'>
										<span id='g_decrease' class='g_decrease g_opacity50 icon-decrease g_iconMiddle'></span>
										<input type='number' class='g_buyNumber' id='productNum' autocomplete='off' value='1' min='1' max='9999999' />
										<span id='g_increase' class='g_increase icon-increase g_iconMiddle'></span>
									</div>
								</div>
							</div>
						</div>
						<div class='productMallShop'>
							<div class='btnGroup'></div>
					`;
                }
                if (Fai.top._openThemeV3) {
                    return cartV3;
                } else {
                    return cart;
                }
            }
        },

        initWebCartPage() {
            var moduleId = this.moduleId;
            if (this.productShowCart == null) {
                this.productShowCart = initWebPage({
                    triggerId: 'f' + moduleId,
                    panelId: 'productMallOptionPanel_mcs_' + moduleId,
                    pageBg: true,
                    returnSeltFun: true,
                    direction: 'bottom',
                    closeWebPage: '#productMallOptionPanel_mcs_' + moduleId + ' .productDetailClose.icon-Off',
                    closeCallBack: function () {
                        jm('.productMallOptionPanel .productMallOptionContentPanel').removeClass(
                            'mallThemeProductContentPanel'
                        );
                        jm(
                            '#productMallOptionPanel_mcs_' +
                                moduleId +
                                ' .productMallOptionContent .productMallOptionHead'
                        ).remove();
                        jm(
                            '#productMallOptionPanel_mcs_' +
                                moduleId +
                                ' .productMallOptionContent .mallOptionAndNumBox'
                        ).remove();
                        jm(
                            '#productMallOptionPanel_mcs_' + moduleId + ' .productMallOptionContent .productMallShop'
                        ).remove();
                        jm(
                            '#productMallOptionPanel_mcs_' + moduleId + ' .productMallOptionContent .separatorLine'
                        ).remove();
                    },
                });
            }
        },

        initAndShowCart(info, optionHtml, script, otherHtml) {
            var moduleId = this.moduleId;
            var panel = jm('.productMallOptionPanel');
            var pid = info.id;
            panel.attr('id', 'productMallOptionPanel' + pid); //修改panel的id
            var mallAmountHtml = otherHtml.mallAmountHtml || '';
            var mallWeightHtml = otherHtml.mallWeightHtml || '';
            var mallPriceHtml = otherHtml.mallPriceHtml || '';
            var productMallShopHtml = otherHtml.productMallShopHtml || '';
            //s----为了显示价格
            var value = info.mallPrice;
            var propValue = value.toFixed(2);
            var tempSaleProValue = propValue;
            var memberMaxPrice = '';
            var memberMinPrice = '';

            if (info.openPriceCalendar) {
                panel.addClass('withPriceCalendar');
                panel.find('.productMallOptionHead .productMallOptionImage').hide();
                panel.find('.productMallOptionHead .mallOptionImg').hide();
                panel.find('.productMallOptionHead .productDetail').hide();
                panel
                    .find('.productMallOptionHead')
                    .append("<div class='indicator'>" + LS.siteFormPleaseSelect + '</div>');
            } else {
                panel.removeClass('withPriceCalendar');
            }

            if (this.mallShoppingOptions) {
                //存在购物选项时
                var minPrice = propValue;
                var maxPrice = propValue;
                var optionsAmountInfo = info.optionsAmountList || {};
                var optionsAmountList = optionsAmountInfo.d || [];
                jm.each(optionsAmountList, function (i, n) {
                    var data = n;
                    var oPrice = data.oPrice;
                    if (oPrice == null || oPrice == '') {
                        return;
                    }
                    var oFlag = data.flag || 0;
                    if (oFlag == 0) {
                        return;
                    }
                    var tmp = oPrice || 0.0;
                    minPrice = minPrice < tmp ? minPrice : tmp;
                    maxPrice = maxPrice > tmp ? maxPrice : tmp;
                    minPrice = parseFloat(minPrice) || 0.0;
                    maxPrice = parseFloat(maxPrice) || 0.0;
                    if (minPrice == maxPrice) {
                        propValue = minPrice.toFixed(2);
                    } else {
                        propValue = minPrice.toFixed(2) + '~' + maxPrice.toFixed(2);
                        memberMaxPrice = maxPrice.toFixed(2);
                        memberMinPrice = minPrice.toFixed(2);
                    }
                });
            }
            if (memberMinPrice != '' || memberMaxPrice != '') {
                var maxpriceFormat = parseFloat(memberMaxPrice) * this.levelDiscount * 0.01;
                var minpriceFormat = parseFloat(memberMinPrice) * this.levelDiscount * 0.01;
                tempSaleProValue = minpriceFormat + '~' + maxpriceFormat;
            }
            jm('#choiceCurrency').text(this.choiceCurrencyVal);
            jm('#mallPrice').text(tempSaleProValue);

            //e----为了显示价格

            //显示其他简单信息
            if (Fai.top._openThemeV3) {
                panel
                    .find('.mallOptionImg')
                    .css('background-image', 'url(' + info.picPath + ')')
                    .attr('src', info.picPath);
            } else {
                panel.find('.mallOptionImg').attr('src', info.picPath);
            }

            panel.find('.productMallAmount').html(mallAmountHtml);
            panel.find('.productMallWeight').html(mallWeightHtml);

            if (Fai.top._openThemeV3) {
                panel.find('.productDetailPrice').html(mallPriceHtml);
            } else {
                panel
                    .find('.productDetailName')
                    .html(
                        "<div class='productName g_middleColor'><div class='productNameWrap' style='word-break:break-all;white-space: normal;overflow:hidden;text-overflow:ellipsis;'></div></div>" +
                            mallPriceHtml
                    );
                panel.find('.productNameWrap').text(info.name);
            }

            panel.find('.J-op-box').html(optionHtml); //显示购物选项

            if (Fai.top._openThemeV3) {
                panel.find('.btnGroup').html(productMallShopHtml);
            } else {
                panel.find('.productMallShop').html(productMallShopHtml);
            }

            jm('#g_increase')
                .unbind('click.ic')
                .bind('click.ic', function () {
                    decrease2(pid);
                });
            jm('#g_decrease')
                .unbind('click.dc')
                .bind('click.dc', function () {
                    increase2(pid);
                });

            //以生成script标签的方式执行js，完毕就remove掉标签
            var newScript = document.createElement('script');
            newScript.type = 'text/javascript';
            newScript.innerHTML = script;
            newScript.id = 'opScript_' + moduleId;
            document.getElementById('productMallOptionPanel_mcs_' + moduleId).appendChild(newScript);
            jm('#opScript_' + moduleId).remove();

            jm('#productMallOptionPanel_mcs_' + moduleId + ' #productBuy')
                .unbind('click.msc')
                .bind('click.msc', function () {
                    mallBuy(pid, 0, 1, moduleId, null, null, {
                        cb: function () {
                            // 产品分类模块加购 fdp埋点
                        },
                    });
                });
            jm('#productMallOptionPanel_mcs_' + moduleId + ' .J_productImmediatelyBug')
                .unbind('click.msc')
                .bind('click.msc', function () {
                    mallBuy(pid, 1, 1, moduleId);
                });

            jm('#productMallOptionPanel_mcs_' + moduleId + ' .J_productImmeSecKill')
                .unbind('click.msc')
                .bind('click.msc', function () {
                    var secKillId = parseInt(jm(this).attr('secKillId') || 0);
                    mallBuy(pid, 1, null, moduleId, null, null, {
                        isSecKill: true,
                        secKillId: secKillId,
                    });
                });

            jm('#productMallOptionPanel_mcs_' + moduleId + ' .J_productImmeGroupBuy')
                .unbind('click.msc')
                .bind('click.msc', function () {
                    var groupBuyId = parseInt(jm(this).attr('groupBuyId') || 0);
                    mallBuy(pid, 1, null, null, null, null, {
                        isGroupBuy: true,
                        groupBuyId: groupBuyId,
                        groupBuyDetailId: 0,
                    });
                });

            jm('#productMallOptionPanel_mcs_' + moduleId + ' .J_productImmeCutPrice')
                .unbind('click.msc')
                .bind('click.msc', function () {
                    var cutPriceId = parseInt(jm(this).attr('cutPriceId') || 0);
                    Mobi.cutPrice(cutPriceId, pid);
                });

            jm('#productMallOptionPanel_mcs_' + moduleId + ' .J_productImmeIntegralMall')
                .unbind('click.msc')
                .bind('click.msc', function () {
                    var integralMallId = parseInt(jm(this).attr('integralMallId') || 0);
                    mallBuy(pid, 1, null, null, null, null, {
                        isIntegralMall: true,
                        integralMallId: integralMallId,
                    });
                });

            jm('#productMallOptionPanel_mcs_' + moduleId + ' .J_productImmeEarnestSale')
                .unbind('click.msc')
                .bind('click.msc', function () {
                    var earnestSaleId = parseInt(jm(this).attr('earnestSaleId') || 0);
                    mallBuy(pid, 1, null, null, null, null, {
                        isEarnestSale: true,
                        earnestSaleId: earnestSaleId,
                    });
                });

            jm('#productMallOptionPanel_mcs_' + moduleId)
                .find('.mallOptionImg')
                .click(function () {
                    var picSrc = jm(this).attr('src');
                    jm('#module' + moduleId)
                        .find('#J_acct_pic')
                        .attr('src', picSrc)
                        .click();
                });

            if (!Fai.top._openThemeV3) {
                if (Fai.top._templateFrameId >= 2000 || Fai.top._templateFrameId < 3000) {
                    jm('#mallPrice').css({ height: '', lineHeight: '' });
                }
            }

            jm('#productMallOptionPanel_mcs_' + moduleId)
                .find('.productMallOptionPanel')
                .show();

            //对于一些限购的情况，在每次进入该页面时都要调用。
            //有一种情况是起购为3，在点立即购买后返回，数量会变成1。
            //这是因为手机浏览器返回会读取缓存的视图，通过设置pageshow事件可以在每次显示页面都执行想要的代码
            var newScript2 = document.createElement('script');
            newScript2.type = 'text/javascript';
            newScript2.innerHTML = 'jm(window).bind("pageshow", function(){jm("#productNum").change();});';
            newScript2.id = 'opScript2_' + moduleId;
            document.getElementById('productMallOptionPanel_mcs_' + moduleId).appendChild(newScript2);
        },

        initDom() {
            this.$module = jm('#module' + this.moduleId);
            this.initWebCartPage();
            initModulePhotoSwipe('productCatagoryPdPanle' + this.moduleId);
        },

        handleHasProductClick(item, index, fakeClick) {
            if (!item) {
                return;
            }
            this.navIndex = index;
            this.searchName = item.name;
            this.searchId = item.id;
            if (VITE_APP_MODE !== 'visitor') {
                this.noLazyLoad = true;
            }
            // 记录滑动时的位置
            if (this.moduleStyle == 6) {
                let transform = this.firstGrouplistDom.css('transform').match(/translate3d\((.*)\)/);
                if (transform != null && transform[1] != null) {
                    let startX = parseInt(
                        this.firstGrouplistDom
                            .css('transform')
                            .match(/translate3d\((.*)\)/)[1]
                            .split(',')[0]
                    );
                    jm.cookie('pg_startX_photo', startX, { path: '/' });
                } else {
                    jm.cookie('pg_startX_photo', 0, { path: '/' });
                }
            } else if (this.moduleStyle == 4) {
                let transform = this.firstGrouplistDom.css('transform').match(/translate3d\((.*)\)/);
                if (transform != null && transform[1] != null) {
                    let startX = parseInt(
                        this.firstGrouplistDom
                            .css('transform')
                            .match(/translate3d\((.*)\)/)[1]
                            .split(',')[0]
                    );
                    jm.cookie('pg_startX', startX, { path: '/' });
                } else {
                    jm.cookie('pg_startX', 0, { path: '/' });
                }
            }

            if (this.moduleStyle == 5 || this.moduleStyle == 6 || this.moduleStyle == 7) {
                if (this.showLev == 2) {
                    this.searchName = item.name;
                    this.setIndexBySearchName(item.name);
                    if (!this.isfirstLevelGroupHasChild(item)) {
                        this.jumpToProductResult(item.id);
                    }
                    return;
                } else if (this.showLev == 3) {
                    let arr = [];
                    const key = this.groupType == 0 || this.showAllGroupLev3 ? 'id' : 'name';
                    for (let i = 0; i < this.secondGroupMap.length; i++) {
                        if (item[key] == this.secondGroupMap[i].key) {
                            arr = this.secondGroupMap[i].data;
                        }
                    }

                    // let arr = this.secondGroupMap[item.id];
                    if (arr.length == 0 && !fakeClick) {
                        this.jumpToProductResult(item.id);
                        return;
                    } else {
                        // 若不用跳转的，直接赋值为点击的项
                        this.searchId = item.id;
                        this.searchName = item.name;
                    }

                    // return;
                }
                this.$nextTick(() => {
                    this.panelHeight = this.$module.find('#groupList' + this.moduleId)[0].clientHeight;
                    this.grouplistHeight = this.$module.find('.J_first_grouplist')[0].clientHeight;
                    this.initFirstGroupHeight('J_verticalGroupHasPhoto', this.panelHeight, this.grouplistHeight);
                    bindJumpModuleEvent(this.moduleId, this.module.style, {
                        pType: this.navSelType,
                        id: this.searchId,
                        name: this.searchName,
                    });
                });
            }

            if (this.moduleStyle == 3 || this.moduleStyle == 4) {
                this.ajaxSearchProduct(item.id, item.name);
            }
        },

        isfirstLevelGroupHasChild(item) {
            return this.photoGroupPanelList.some((group) => {
                return group.name == item.name && group.groupList.length;
            });
        },

        jumpToProductResult(groupId) {
            let _this = this;
            if (Fai.top._changeStyleNum > 0) {
                Vue.prototype.$designer.popupStyleChangeBodyWindow('您的网站设计已经更改，是否立即保存？', () => {
                    _this.searchId = groupId;
                    changeHref(_this.prUrl + '?groupId=' + groupId);
                });
                return;
            } else {
                _this.searchId = groupId;
                changeHref(_this.prUrl + '?groupId=' + groupId);
            }
        },

        showLoading: function () {
            this.loading = true;
        },

        closeLoading: function () {
            this.loading = false;
        },

        initFirstGroupHeight: function (el, panelHeight, grouplistHeight) {
            if (panelHeight > grouplistHeight) {
                this.$module.find('.' + el + ' .J_first_group').css({
                    height: panelHeight + 'px',
                });
            } else {
                this.$module.find('.' + el + ' .J_first_group').removeAttr('style');
            }
            // 分组删除为空时，去除
            if (this.searchName == '' && this.showLev == 2) {
                this.$module.find('.' + el + ' .J_first_group').removeAttr('style');
            }
        },

        clearFirstGroupStyle: function () {
            this.$nextTick(() => {
                let productDom = this.$module.find('.jz_landscape_group_has_product #J_first_group_' + this.moduleId);
                let photoDom = this.$module.find('.jz_landscape_group_has_photo #J_first_group_' + this.moduleId);
                if (productDom != null) {
                    productDom.removeAttr('style');
                }
                if (photoDom != null) {
                    photoDom.removeAttr('style');
                }
            });
        },

        handleCart(item) {
            if (Fai.top._changeStyleNum > 0) {
                Vue.prototype.$designer.popupStyleChangeBodyWindow('您的网站设计已经更改，是否立即保存？');
                return;
            }

            var dom = this.initCart();
            var _this = this;
            var domHtml =
                dom && dom.replace
                    ? dom
                          .replace(/&nbsp;/gi, ' ')
                          .replace(/&lt;/gi, '<')
                          .replace(/&gt;/g, '>')
                          .replace(/&#92;/gi, '\\')
                          .replace(/&#39;/gi, "'")
                          .replace(/&quot;/gi, '"')
                          .replace(/<br\/>/gi, '\n')
                          .replace(/&amp;/gi, '&')
                    : dom;
            jm('#productMallOptionPanel_mcs_' + this.moduleId + ' .productMallOptionContent').append(domHtml);
            var pid = item.id;
            var isInCache = false; //判断缓存中是否有该产品
            var cacheInfo = {};
            var cacheHtml = '';
            var cacheScript = '';
            var cacheOtherHtml = {};
            jm.each(Mobi.showProductCacheList, function (i, n) {
                if (n.id == pid) {
                    isInCache = true;
                    cacheInfo = n.pinfo;
                    cacheHtml = n.showOptionHtml;
                    cacheScript = n.script;
                    cacheOtherHtml = n.otherHtml;
                    return false;
                }
            });

            this.productShowCart();
            jm('#productMallOptionPanel_mcs_' + this.moduleId)
                .find('.productMallOptionPanel')
                .hide(); //没load出产品数据前先隐藏
            if (isInCache) {
                this.initAndShowCart(cacheInfo, cacheHtml, cacheScript, cacheOtherHtml);
            } else {
                var url = '';
                if (Fai.top._openThemeV3) {
                    url = '/ajax/product_h.jsp?cmd=getWafNotCk_getProductInfoAndDomV3';
                } else {
                    url = '/ajax/product_h.jsp?cmd=getWafNotCk_getProductInfoAndDom';
                }

                jm.ajax({
                    type: 'POST',
                    url: url,
                    data: {
                        pid: pid,
                        moduleId: this.moduleId,
                        mallShoppingOptions: this.mallShoppingOptions,
                    },
                    error: function () {
                        ing(LS.js_systemError);
                    },
                    success: function (result) {
                        var res = jm.parseJSON(result);
                        if (res.success) {
                            var info = res.pinfo;
                            var optionHtml = res.showOptionHtml;
                            var script = res.script;
                            var otherHtml = res.otherHtml;
                            Mobi.showProductCacheList.push({
                                id: pid,
                                pinfo: info,
                                showOptionHtml: optionHtml,
                                script: script,
                                otherHtml: otherHtml,
                            });

                            _this.initAndShowCart(info, optionHtml, script, otherHtml);
                        }
                    },
                });
            }
        },

        initPgTypeChangeData() {
            var moduleStyle = this.moduleStyle;
            if (this.realLev == 3) {
                if (moduleStyle == 3 || moduleStyle == 4 || moduleStyle == 5 || moduleStyle == 6 || moduleStyle == 7) {
                    this.searchId = this.groupList.length == 0 ? 0 : this.groupList[0].id;
                }
            } else if (this.showLev == 2) {
                if (moduleStyle == 3 || moduleStyle == 4) {
                    this.searchId = this.groupList.length == 0 ? 0 : this.groupList[0].id;
                }
            }
        },

        ajaxSearchProduct(id, name) {
            var href = '';
            var _colId = Fai.top._colId;
            var _extId = Fai.top._extId;
            var _this = this;
            var isLoadingSuccess = false;
            var param = [];
            var sl = this.showLev;
            var nt = this.groupType;
            if (this.$module == null) {
                this.$module = jm('#module' + this.moduleId);
            }

            param.push('cmd=getWafNotCk_getProductGroupData');
            param.push(`&_colId=${_colId}`);
            param.push(`&_extId=${_extId}`);
            param.push(`&moduleId=${this.moduleId}`);
            param.push(`&sl=${sl}`);
            param.push(`&nt=${nt}`);
            param.push(`&pgType=${this.pgType}`);
            param.push(`&moduleStyle=${this.moduleStyle}`);
            param.push(`&siteVer=${this.siteVer}`);
            param.push(`&mp=${this.maxProduct}`);

            if (this.showAllGroupProductStyle) {
                href = `index.jsp?groupId=${id}`;
                _this.searchId = id;
                param.push(`&href=${jm.encodeUrl(href)}`);
                param.push(`&sagp=${this.showAllGroupProductStyle}`);
            } else if (this.showLev == 3) {
                href = `index.jsp?groupId=${id}`;
                _this.searchId = id;
                param.push(`&href=${jm.encodeUrl(href)}`);
            } else if (this.isAssign) {
                href = `index.jsp?groupId=${id}`;
                _this.searchId = id;
                _this.searchName = name;
                param.push(`&href=${jm.encodeUrl(href)}`);
                if (this.showLev == 2) {
                    // 展示二级，指定分类，取二级分类下的照片
                    let idList = [];
                    idList.push(id);

                    if (VITE_APP_MODE !== 'visitor') {
                        this.unFirstGroupList
                            .filter((item) => item.parentId == id)
                            .forEach((item) => {
                                if (item.id != -1) {
                                    idList.push(item.id);
                                }
                            });
                    } else {
                        this.module.exctInfo.unFirstGroupListVisit
                            .filter((item) => item.parentId == id)
                            .forEach((item) => {
                                if (item.id != -1) {
                                    idList.push(item.id);
                                }
                            });
                    }

                    param.push(`&idList=${JSON.stringify(idList)}`);
                } else {
                    param.push(`&groupIdList=${JSON.stringify(this.productGroupIdList)}`);
                }
            } else if (this.isGroup) {
                href = `index.jsp?groupName=${name}`;
                _this.searchName = name;
                _this.searchId = id;
                this.setIndexBySearchName(name);
                // 发送当前选定的分组
                let groupIdList = this.getProductGroupIdList();
                if (groupIdList.length == 0) {
                    _this.catalogGrouping = [];
                    return;
                }
                param.push(`&idList=${JSON.stringify(groupIdList)}`);
                param.push(`&href=${jm.encodeUrl(href)}`);
            }

            if (!isLoadingSuccess) {
                this.showLoading();
            }

            if (this.moduleStyle == 3 || this.moduleStyle == 4) {
                param.push(`&sort=${JSON.stringify(this.module.content.sort)}`);
            }

            ajaxModule(param.join(''))
                .then((result) => {
                    // var waf = request.getResponseHeader('wafUpdate');
                    // if (waf && waf == 'true') {
                    //     window.location.reload();
                    // }
                    isLoadingSuccess = true;
                    _this.closeLoading();
                    if (result.success) {
                        _this.search = result.searchList;
                        if (sl == 1 || _this.showAllGroupProductStyle) {
                            _this.catalogGroup = result.catalogData.catalogGroupListData || [];
                        } else if (sl == 2) {
                            _this.catalogGrouping = result.catalogData.catalogGroupingListData || [];
                        }

                        if (VITE_APP_MODE !== 'visitor') {
                            _this.noLazyLoad = true;
                        }

                        _this.$nextTick(() => {
                            let panel = _this.$module.find('#productList' + _this.moduleId)[0];
                            let groupList = _this.$module.find('.J_first_grouplist')[0];
                            if (groupList != null && panel != null) {
                                _this.panelHeight = panel.clientHeight;
                                _this.grouplistHeight = groupList.clientHeight;

                                _this.initFirstGroupHeight(
                                    'J_verticalGroupHasProduct',
                                    _this.panelHeight,
                                    _this.grouplistHeight
                                );

                                if (_this.moduleStyle == 4) {
                                    _this.clearFirstGroupStyle();
                                }
                                _this.initPictrue();
                                if (!_this.noLazyLoad) {
                                    bindImgLazyLoad(`module${_this.module.id}`);
                                }
                            }
                            // 点击选择分类，重新绑定a dom 事件
                            bindJumpModuleEvent(_this.moduleId, _this.module.style, {
                                pType: _this.navSelType,
                                id: _this.searchId,
                                name: _this.searchName,
                            });
                        });
                    } else {
                        ing(result.msg);
                        _this.closeLoading();
                    }
                })
                .catch(() => {
                    ing(LS.js_systemError);
                });
        },

        // 重新刷新高度
        refreshHeight() {
            if (this.$moudle == null) {
                this.$module = jm('#module' + this.moduleId);
            }
            var productListPanel = this.$module.find('#productList' + this.moduleId);
            var groupListPanel = this.$module.find('#groupList' + this.moduleId);
            var first_grouplist = this.$module.find('.J_first_grouplist');

            if (this.moduleStyle == 3) {
                if (productListPanel[0] != null && first_grouplist[0] != null) {
                    this.panelHeight = productListPanel[0].clientHeight;
                    this.grouplistHeight = first_grouplist[0].clientHeight;
                    this.initFirstGroupHeight('J_verticalGroupHasProduct', this.panelHeight, this.grouplistHeight);
                }
            } else if (this.moduleStyle == 5) {
                if (groupListPanel[0] != null && first_grouplist[0] != null) {
                    this.panelHeight = groupListPanel[0].clientHeight;
                    this.grouplistHeight = first_grouplist[0].clientHeight;
                    this.initFirstGroupHeight('J_verticalGroupHasPhoto', this.panelHeight, this.grouplistHeight);
                }
            }
        },

        // 获取当前分组中的id集合
        getProductGroupIdList() {
            let searchGroupIdList = new Set(),
                aim = [];
            for (let i = 0; i < this.catalogList.length; i++) {
                let item = this.catalogList[i];
                if (item.name == this.searchName) {
                    aim = item.groupList || [];
                    break;
                }
            }
            aim.forEach((item) => {
                searchGroupIdList.add(item);
            });

            searchGroupIdList = Array.from(searchGroupIdList);

            searchGroupIdList = searchGroupIdList.filter((item) => {
                return item != null;
            });

            // 根据productList 来排序
            let resultId = [];
            let tempIdList = this.productGroupIdList || [];
            tempIdList.forEach((id) => {
                for (let i = 0; i < searchGroupIdList.length; i++) {
                    if (searchGroupIdList[i] === id) {
                        resultId.push(id);
                        break;
                    }
                }
            });

            return resultId;
        },

        handleEmptyClick() {
            this.$designer.open({
                panelType: 'pattern product-group basis',
                moduleId: this.module.id,
                styleId: this.styleId,
            });
        },

        // 根据searchName 获取当前选中分组下标
        setIndexBySearchName(name) {
            for (let i = 0; i < this.catalogList.lenght; i++) {
                if (this.catalogList[i].name == name) {
                    this.searchNameIndex = i;
                    break;
                }
            }
        },

        // 清除由于样式 2, 4 造成的横向偏移
        clearMarginStyle() {
            if (this.$module == null) {
                this.$module = jm('#module' + this.moduleId);
            }

            var firstGroupList = this.$module.find('.first_grouplist');

            if (firstGroupList != null) {
                firstGroupList.removeAttr('style');
            }
        },
        resetNav() {
            if ([3, 4, 5, 6, 7].includes(this.moduleStyle) && this.firstLevList.length > 0) {
                this.searchId = 0;
                this.searchName = '';
                this.handleHasProductClick(this.firstLevList[0], 0, true);
            }
        },
    },
};
</script>
<style lang="scss">
.categoryModel {
    overflow-x: hidden;
    overflow-y: auto;
}
.categoryModel::-webkit-scrollbar {
    display: none !important;
}
.sortPanelList .pgName {
    display: inline-block;
    float: left;
    text-decoration: none;
    outline: none;
    word-break: keep-all;
    -o-text-overflow: ellipsis;
    height: 1.9rem;
    line-height: 1.9rem;
    /*font-size: 0.7rem;*/
    font-weight: normal;
    width: 90%;
    font-family: '\5FAE\8F6F\96C5\9ED1', Helvetica, '黑体', Arial, Tahoma;
    overflow: hidden;
    white-space: normal;
    text-overflow: ellipsis;
}
.sortPanelList .paNameFillWidth {
    width: 100%;
}
.formStyle47 .sortPanelList .pgName,
.formStyle28 .sortPanelList .pgName {
    width: 100%;
}
.sortPanelList .navLineTitle {
    display: inline-block;
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.sortPanelList .pgMark {
    float: right;
    margin: 0.4rem 0.55rem 0 0;
    font-size: 0.9rem;
}
.pgListDiv {
    background-color: #fff;
}
.pgDetail {
    position: relative;
    background-color: #fff;
    font-size: 0.75rem;
}
.pgDetail .pgBox {
    width: 100%;
    background-color: #fff;
}
.pgDetail .pgBoxCollapse {
    width: 40%;
    transition: all 500ms;
}
.pgDetail .pgBox .g_globalLine {
    margin: 0 0.4rem;
    width: 100%;
    height: 2.5rem;
}
.pgDetail .pgBox .pgName,
.pgDetail .pgBox .pgModeTitle {
    display: inline-block;
    float: left;
    color: black;
    text-decoration: none;
    outline: none;
    word-break: keep-all;
    height: 2.5rem;
    line-height: 2.5rem;
    font-size: 0.7rem;
    font-weight: normal;
    width: 80%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.pgDetail .pgBox .pgBox2Item {
    position: relative;
    z-index: 20;
    height: 0rem;
    transition: all 0.3s;
    overflow-y: scroll;
    overflow-x: hidden;
    width: 100%;
    background-color: #f7f7f7;
}
.pgDetail .pgBox .pgBox2Item .pgName {
    padding: 0 0.5rem 0 1rem;
    border-top: 1px solid #f2f2f2;
    box-sizing: border-box;
}
.pgDetail .pgBox .icon-upIcon {
    display: inline-block;
    float: right;
    height: 2.5rem;
    line-height: 2.5rem;
    margin-right: 0.5rem;
}
.pgBox2Item .pgDetail .pgBox .line .pgBoxItem {
    background-color: #e3e3e3;
}

/*支付*/
.pgDetail .pgBoxItem {
    padding-left: 1rem;
}
.icon-payMode {
    float: left;
    display: inline-block;
    height: 2.5rem;
    line-height: 2.5rem;
    margin-right: 0.75rem;
    font-size: 1rem;
}
.icon-selectedPayMode {
    display: inline-block;
    height: 2.5rem;
    line-height: 2.5rem;
    margin-right: 0.3rem;
    font-size: 0.8rem;
    vertical-align: middle;
}
.payMode1:before {
    content: '\eaa3';
    color: #5bb741;
}
.payMode2:before {
    content: '\eaa7';
    color: #e42a3a;
}
.payMode3:before {
    content: '\eaa8';
    color: #ff8100;
}

.payMode4:before {
    content: '\eaa6';
    color: #00a0e9;
}
.payMode5:before {
    content: '\eaa6';
    color: #00a0e9;
}
.payMode6:before {
    content: '\eaa6';
    color: #00a0e9;
}
.payMode8:before {
    content: '\eaa6';
    color: #00a0e9;
}
.payMode12:before {
    content: '\eaa6';
    color: #00a0e9;
}
.payMode13:before {
    content: '\eaa6';
    color: #00a0e9;
}

.payMode9:before {
    content: '\eaa4';
    color: #0066e4;
}
.payMode7:before {
    content: '\eaa2';
    color: #00a0e9;
}

.payMode10:before {
    content: '\eaa5';
    color: #75d42f;
}
.payMode11:before {
    content: '\eaa5';
    color: #75d42f;
}
.payMode14:before {
    content: '\eaa5';
    color: #75d42f;
}

.payMode50:before {
    content: '\b0369';
    color: #ffb246;
}
.payMode51:before {
    content: '\eaa6';
    color: #00a0e9;
}
.payMode52:before {
    content: '\eaa5';
    color: #75d42f;
}
.payMode53:before {
    content: '\eaa5';
    color: #75d42f;
}
.payMode54:before {
    content: '\eaa5';
    color: #75d42f;
}
.payMode55:before {
    content: '\eaa5';
    color: #75d42f;
}
.payMode58:before {
    content: '\eaa6';
    color: #00a0e9;
}

.payModeOnline:before {
    content: '\b0369';
    color: #ffb246;
}

.icon-weChat:before {
    content: '\b0140';
    color: #74d32d;
}
.icon-apliyPay:before {
    content: '\b0137';
    color: #00a0ea;
}
.icon-return:before {
    content: '\b0133';
    color: #000000;
}
.pgDetail .pgBox .pgSelect {
    background-color: #e3e3e3;
    height: 1.6rem;
    position: absolute;
    left: 0.4rem;
    width: 98%;
}
.pgDetail .pgBox2 {
    position: absolute;
    top: 0;
    width: 60%;
    left: 100%;
    background-color: #e3e3e3;
    height: 100%;
    overflow-x: hidden;
}
.pgDetail .pgBox2Collapse {
    position: absolute;
    top: 0;
    left: 40%;
    transition: all 300ms;
}
.pgDetail .pgBox2 .pgBox2Item {
    position: absolute;
    top: 0;
    width: 100%;
    left: 100%;
    display: none;
}
.pgDetail .pgBox2 .pgName {
    display: block;
    float: left;
    color: black;
    text-decoration: none;
    outline: none;
    word-break: keep-all;
    height: 2rem;
    line-height: 2rem;
    font-size: 0.7rem;
    font-weight: normal;
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    clear: both;
}
.pgDetail .pgBox2 .pgBox2ItemCollapse {
    position: relative;
    top: 0;
    left: 5%;
    display: block;
    transition: all 300ms;
}

.icon-gline:after {
    pointer-events: none;
}
.pgLinkWrap {
    display: block;
    overflow: hidden;
}
/*纵向有产品 begin */
.jz_vertical_group_has_product .first_group,
.jz_vertical_group_has_photo .first_group,
.jz_vertical_group_no_photo .first_group {
    position: relative;
    float: left;
    width: 3.875rem;
    height: 100%;
    background: #f8f8f8;
}

.jz_vertical_group_has_product .first_panel {
    position: relative;
    margin-left: 4.375rem;
    margin-right: 0.5rem;
    overflow: hidden;
}

.jz_landscape_group_has_product .first_groupitem,
.jz_landscape_group_has_photo .first_groupitem {
    height: 2rem;
    line-height: 2rem;
    text-align: center;
    cursor: pointer;
}

.jz_vertical_group_has_product .first_groupitem,
.jz_vertical_group_has_photo .first_groupitem,
.jz_vertical_group_no_photo .first_groupitem {
    height: 2.16rem;
    line-height: 2.16rem;
    text-align: center;
    cursor: pointer;
}

.jz_vertical_group_has_product .first_groupitem.selected,
.jz_vertical_group_has_photo .first_groupitem.selected,
.jz_vertical_group_no_photo .first_groupitem.selected {
    background: #fff;
    cursor: default;
}

.jz_vertical_group_has_product .first_groupitem .first_groupname,
.jz_landscape_group_has_product .first_groupitem .first_groupname,
.jz_vertical_group_has_photo .first_groupitem .first_groupname,
.jz_vertical_group_no_photo .first_groupitem .first_groupname,
.jz_landscape_group_has_photo .first_groupitem .first_groupname {
    position: relative;
    display: inline-block;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
    margin: 0 auto;
    font-size: 0.6rem;
}

.jz_vertical_group_has_product .first_panel .product_item .right_content,
.jz_landscape_group_has_product .first_panel .product_item .right_content {
    display: inline-block;
    position: absolute;
    height: 100%;
    width: calc(100% - 4.5rem);
}

.jz_vertical_group_has_product .first_panel .product_item {
    position: relative;
    height: 4rem;
    padding-bottom: 0.5rem;
    margin: 0.5rem 0 0.5rem 0;
    border-bottom: 1px solid #f5f5f5;
}

.jz_vertical_group_has_product .first_panel a:last-child .product_item {
    border-bottom: none;
}

.jz_vertical_group_has_product .first_panel .product_item .img_panel,
.jz_landscape_group_has_product .first_panel .product_item .img_panel {
    display: inline-block;
    vertical-align: bottom;
    width: 4rem;
    height: 4rem;
    margin-right: 0.5rem;
    position: relative;
}

.jz_vertical_group_has_product .first_panel .product_item .photoListImg,
.jz_landscape_group_has_product .first_panel .product_item .photoListImg {
    border: 1px solid #eee;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    padding-top: 4rem;
    border-radius: 0.15rem;
}

.jz_vertical_group_has_product .first_panel .price_panel,
.jz_landscape_group_has_product .first_panel .price_panel {
    position: absolute;
    bottom: 0.65rem;
}

.jz_vertical_group_has_product .first_panel .price_panel2,
.jz_landscape_group_has_product .first_panel .price_panel2 {
    position: absolute;
    bottom: 0.15rem;
    max-width: 5.33rem;
}

.jz_vertical_group_has_product .first_panel .mall_cart,
.jz_landscape_group_has_product .first_panel .mall_cart {
    position: absolute;
    bottom: 0.65rem;
    right: 0.3rem;
    width: 1rem;
    height: 1rem;
    cursor: pointer;
    border-radius: 50%;
}

.jz_vertical_group_has_product .first_panel .mall_cart span,
.jz_landscape_group_has_product .first_panel .mall_cart span {
    position: absolute;
    font-size: 0.525rem;
    transform: translate(-50%, -50%);
    color: #fff;
    width: 0.525rem;
    height: 0.525rem;
    top: 50%;
    left: 50%;
}

.jz_vertical_group_has_product .first_panel .booking_btn,
.jz_landscape_group_has_product .first_panel .booking_btn {
    position: absolute;
    height: 1.05rem;
    line-height: 1.05rem;
    max-width: 2.6rem;
    min-width: 1.6rem;
    padding: 0 0.25rem;
    width: auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    float: right;
    border-radius: 0.1rem;
    color: #fff;
    text-align: center;
    cursor: pointer;
    font-size: 0.5rem;
    bottom: 0.7rem;
    right: 0;
}

.jz_vertical_group_has_product .first_panel .price_panel .unit,
.jz_landscape_group_has_product .first_panel .price_panel .unit {
    font-size: 0.6rem;
}

.jz_vertical_group_has_product .first_panel .price_panel .num1,
.jz_landscape_group_has_product .first_panel .price_panel .num1 {
    font-size: 0.75rem;
}

.jz_vertical_group_has_product .first_panel .price_panel .num2,
.jz_landscape_group_has_product .first_panel .price_panel .num2 {
    font-size: 0.55rem;
}

.jz_vertical_group_has_product .first_panel .price_panel .vipName,
.jz_landscape_group_has_product .first_panel .price_panel .vipName {
    display: inline-block;
    border-radius: 0.1rem;
    font-size: 0.45rem;
    color: rgba(255, 255, 255, 1);
    text-align: center;
    padding: 0 0.15rem;
}

.jz_vertical_group_has_product .first_panel .price_panel .linePrice,
.jz_landscape_group_has_product .first_panel .price_panel .linePrice {
    font-size: 0.5rem;
    text-decoration: line-through;
    color: rgba(153, 153, 153, 1);
}

.jz_vertical_group_has_product .first_panel .right_content .title,
.jz_landscape_group_has_product .first_panel .right_content .title {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-size: 0.6rem;
    margin-top: 0.15rem;
}

.jz_vertical_group_has_product .first_panel .group_name {
    color: #333;
    font-size: 0.6rem;
    margin-top: 0.5rem;
}

.jz_vertical_group_has_product .first_panel .price_panel2 .price_wrap,
.jz_landscape_group_has_product .first_panel .price_panel2 .price_wrap {
    display: inline-flex;
    align-items: flex-end;
}

.jz_vertical_group_has_product .first_panel .price_panel2 .price_wrap span,
.jz_landscape_group_has_product .first_panel .price_panel2 .price_wrap span {
    margin-right: 0.21rem;
}

.jz_vertical_group_has_product .first_panel .price_panel2 .unit,
.jz_landscape_group_has_product .first_panel .price_panel2 .unit {
    font-size: 0.6rem;
}

.jz_vertical_group_has_product .first_panel .price_panel2 .num1,
.jz_landscape_group_has_product .first_panel .price_panel2 .num1 {
    font-size: 0.75rem;
    margin-left: -0.25rem;
}

.jz_vertical_group_has_product .first_panel .price_panel2 .num2,
.jz_landscape_group_has_product .first_panel .price_panel2 .num2 {
    margin-left: -0.15rem;
    font-size: 0.55rem;
}

.jz_vertical_group_has_product .first_panel .price_panel2 .vipName,
.jz_landscape_group_has_product .first_panel .price_panel2 .vipName {
    display: inline-block;
    border-radius: 0.1rem;
    font-size: 0.45rem;
    color: rgba(255, 255, 255, 1);
    text-align: center;
    padding: 0 0.15rem;
}

.jz_vertical_group_has_product .first_panel .price_panel2 .linePrice,
.jz_landscape_group_has_product .first_panel .price_panel2 .linePrice {
    font-size: 0.5rem;
    text-decoration: line-through;
    color: rgba(153, 153, 153, 1);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.jz_vertical_group_has_product .first_panel .right_content .title,
.jz_landscape_group_has_product .first_panel .right_content .title {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-size: 0.6rem;
    margin-top: 0.15rem;
    word-break: break-word;
}

.jz_vertical_group_has_product .first_panel .group_name {
    color: #333;
    font-size: 0.6rem;
    margin-top: 0.5rem;
}
/*纵向有产品 end */

/*横向有产品 end */
.jz_landscape_group_has_product .first_grouplist,
.jz_landscape_group_has_photo .first_grouplist {
    padding: 0 0.6rem;
    height: 2rem;
}

.jz_landscape_group_has_product .first_group,
.jz_landscape_group_has_photo .first_group {
    height: 2rem;
    border-bottom: 1px solid #f0f0f0;
    overflow: hidden;
}

.jz_landscape_group_has_product .first_groupitem,
.jz_landscape_group_has_photo .first_groupitem {
    position: relative;
    float: left;
    max-width: 6rem;
    margin-right: 1rem;
    user-select: none;
}

.jz_landscape_group_has_product .first_groupitem.selected,
.jz_landscape_group_has_photo .first_groupitem.selected {
    cursor: default;
}

/*.jz_landscape_group_has_product .first_groupitem .first_groupname,
.jz_landscape_group_has_photo .first_groupitem .first_groupname {
  padding: 0 .125rem;
}*/

.jz_landscape_group_has_product .first_panel {
    position: relative;
}

.jz_landscape_group_has_product .first_panel .product_item {
    position: relative;
    height: 4rem;
    padding-bottom: 0.5rem;
    margin: 0.5rem 0.475rem 0.5rem 0.75rem;
    border-bottom: 1px solid #f5f5f5;
}

.jz_landscape_group_has_product .first_panel a:last-child .product_item {
    border-bottom: none;
}

.jz_landscape_group_has_product .first_panel .group_name {
    font-size: 0.6rem;
    color: #333;
    margin: 0.75rem 0 1rem 0.75rem;
}
/*横向有产品 end */

.jz_vertical_group_has_photo .first_panel .second_group_panel,
.jz_vertical_group_no_photo .first_panel .second_group_panel,
.jz_landscape_group_has_photo .first_panel .second_group_panel {
    display: none;
}

.jz_vertical_group_has_photo .first_panel .second_group_panel.selected,
.jz_vertical_group_no_photo .first_panel .second_group_panel.selected,
.jz_landscape_group_has_photo .first_panel .second_group_panel.selected {
    display: block;
    overflow: hidden;
}

.jz_vertical_group_has_photo .first_panel,
.jz_vertical_group_no_photo .first_panel {
    position: relative;
    margin-left: 3.875rem;
    padding: 0 1rem;
    overflow: hidden;
}

.jz_vertical_group_has_photo .first_panel .second_groupitem,
.jz_vertical_group_no_photo .first_panel .second_groupitem,
.jz_landscape_group_has_photo .first_panel .second_groupitem {
    margin: 0.75rem 0;
    overflow: hidden;
}

.jz_vertical_group_has_photo .second_group_panel .second_groupitem .group_img_wrap {
    display: flex;
    flex-wrap: wrap;
}

.jz_vertical_group_has_photo .first_panel .sed_groupname,
.jz_vertical_group_no_photo .first_panel .sed_groupname,
.jz_landscape_group_has_photo .first_panel .sed_groupname {
    font-size: 0.6rem;
    color: #333;
}

.jz_vertical_group_has_photo .group_img_wrap,
.jz_vertical_group_no_photo .group_img_wrap,
.jz_landscape_group_has_photo .group_img_wrap {
    margin-top: 0.75rem;
    border-bottom: 1px solid #f5f5f5;
}

.jz_vertical_group_has_photo .second_groupitem:last-child .group_img_wrap,
.jz_vertical_group_no_photo .second_groupitem:last-child .group_img_wrap,
.jz_landscape_group_has_photo .second_groupitem:last-child .group_img_wrap {
    border-bottom: none;
}

.jz_vertical_group_has_photo .group_img_wrap .f-imgContainer,
.jz_vertical_group_no_photo .group_img_wrap .f-imgContainer,
.jz_landscape_group_has_photo .group_img_wrap .f-imgContainer {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 2.625rem;
    line-height: 2.625rem;
    overflow: hidden;
}

.jz_vertical_group_has_photo .f-groupImgContainer,
.jz_landscape_group_has_photo .f-groupImgContainer {
    position: relative;
    float: left;
    /*width: 2.625rem;*/
    /*margin-right: 1rem;
  margin-bottom: 1rem;*/
}

.jz_vertical_group_has_photo .f-groupImgContainer {
    width: 25.92%;
    margin-right: 10%;
    margin-bottom: 10%;
}
.jz_landscape_group_has_photo .f-groupImgContainer {
    width: 19.45%;
    margin-right: 7.4%;
    margin-bottom: 7.4%;
}
.jz_landscape_group_has_photo .f-less-margin-groupImgContainer {
    margin-right: 6.6%;
}

.jz_vertical_group_has_photo .f-groupImgContainer:nth-child(3n),
.jz_vertical_group_no_photo .f-groupImgContainer:nth-child(3n) {
    margin-right: 0;
}

.jz_landscape_group_has_photo .f-groupImgContainer:nth-child(4n) {
    margin-right: 0;
}

.jz_vertical_group_has_photo .f-groupImgContainer .f-itemImgName,
.jz_landscape_group_has_photo .f-groupImgContainer .f-itemImgName {
    font-size: 0.6rem;
    color: #666;
    margin-top: 0.3rem;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.jz_vertical_group_has_photo .group_img_wrap .f-imgContainer .f-itemImg,
.jz_vertical_group_no_photo .group_img_wrap .f-imgContainer .f-itemImg,
.jz_landscape_group_has_photo .group_img_wrap .f-imgContainer .f-itemImg {
    display: inline-block;
    font-size: 2.625rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    padding-top: 2.625rem;
}

.jz_vertical_group_has_photo .group_img_wrap .f-imgContainer .f-itemImg.isFontIcon,
.jz_vertical_group_no_photo .group_img_wrap .f-imgContainer .f-itemImg.isFontIcon,
.jz_landscape_group_has_photo .group_img_wrap .f-imgContainer .f-itemImg.isFontIcon {
    padding-top: 0;
}

.jz_landscape_group_has_photo .first_panel {
    position: relative;
    margin: 0 1.25rem;
}

.jz_landscape_group_has_photo .quick_in,
.jz_landscape_group_has_product .quick_in {
    top: 1.85rem;
}

.jz_vertical_group_no_photo .f-groupImgContainer .f-itemImgName {
    font-size: 0.6rem;
    color: #666;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.jz_vertical_group_no_photo .f-groupImgContainer {
    position: relative;
    float: left;
    /*width: 2.775rem;*/
    margin-right: 0.75rem;
    margin-bottom: 1rem;
}

.jz_vertical_group_no_photo .f-groupImgContainer {
    width: 27.4%;
}

.jz_vertical_group_no_photo .f-groupImgContainer-7 {
    width: 100%;
    margin: 0;
    padding: 0.75rem 0;
    border-bottom: 1px solid #f5f5f5;
}

.jz_vertical_group_no_photo .first_panel .second_groupitem_7 {
    margin: 0;
}

.jz_vertical_group_has_photo .faisco-icons-pic,
.jz_landscape_group_has_photo .faisco-icons-pic {
    font-size: 2.625rem;
}

.categoryModel {
    overflow-x: hidden;
    overflow-y: auto;
    -ms-overflow-style: none;
}
.categoryModel::-webkit-scrollbar {
    display: none !important;
}
.sortPanelList .pgName {
    display: inline-block;
    float: left;
    text-decoration: none;
    outline: none;
    word-break: keep-all;
    height: 1.9rem;
    line-height: 1.9rem;
    /*font-size: 0.7rem;*/
    font-weight: normal;
    width: 90%;
    font-family: '\5FAE\8F6F\96C5\9ED1', Helvetica, '黑体', Arial, Tahoma;
    overflow: hidden;
    white-space: normal;
    text-overflow: ellipsis;
}
.sortPanelList .paNameFillWidth {
    width: 100%;
}

.sortPanelList .line:before {
    content: '';
    position: absolute;
    width: 0.2rem;
    height: 0.2rem;
    top: 50%;
    top: calc(50% - 0.1rem);
    left: 0.45rem;
    background: #999;
    border-radius: 100%;
}

.separatorLine {
    border-style: none;
    height: 1px;
}

.g_separator {
    background: #f0f0f0;
}

.icon-gline:after {
    content: '\e672';
    font-size: 0.8rem;
    color: #666;
    position: absolute;
    top: 0.6rem;
    right: 0.5rem;
}

.sortPanelList .line {
    padding-left: 1rem;
}

.sortPanelList .pgName {
    height: 2rem;
    line-height: 2rem;
}

.productMallOptionHead .productMallOptionImage .productMallOptionImageContent .imageMiddleSpan {
    /* 备注：购物选项图无法居中 ellen 2020.04.21 */
    display: inline;
}

/* 自定义分类图片尺寸 */
.fk-productGroup.group_cus_pic .f-imgContainer {
    width: auto;
    height: auto;
    line-height: normal;
}
.fk-productGroup.group_cus_pic .f-groupImgContainer {
    width: auto;
    height: auto;
}
.fk-productGroup.group_cus_pic .f-itemImg {
    overflow: hidden;
    text-align: center;
}

/*修复调节尺寸后，右面空隙太大*/
.jz_vertical_group_has_photo.group_cus_pic .first_panel,
.jz_vertical_group_no_photo.group_cus_pic .first_panel {
    padding: 0 0 0 1rem;
}
.jz_vertical_group_has_photo.group_cus_pic .f-groupImgContainer:nth-child(3n),
.jz_vertical_group_no_photo.group_cus_pic .f-groupImgContainer:nth-child(3n) {
    margin-right: 10%;
}
.jz_vertical_group_has_photo.group_cus_pic .sed_groupname,
.jz_vertical_group_no_photo.group_cus_pic .sed_groupname {
    padding-right: 1rem;
}
.jz_landscape_group_has_photo.group_cus_pic .first_panel {
    margin: 0 0 0 1.25rem;
}
.jz_vertical_group_has_photo.group_cus_pic .group_banner,
.jz_landscape_group_has_photo.group_cus_pic .group_banner {
    margin-right: 1.25rem;
}

/* 自定义产品图片尺寸 */
.fk-jz_landscape_group_has_product.product_cus_pic .first_panel .product_item {
    width: auto;
    height: auto;
}
.jz_vertical_group_has_product.product_cus_pic .first_panel .product_item .img_panel,
.jz_landscape_group_has_product.product_cus_pic .first_panel .product_item .img_panel {
    width: auto;
    height: auto;
}
.jz_vertical_group_has_product.product_cus_pic .first_panel .product_item .right_content,
.jz_landscape_group_has_product.product_cus_pic .first_panel .product_item .right_content {
    width: auto;
    position: static;
}
.jz_landscape_group_has_product.product_cus_pic .first_panel .product_item {
    height: auto;
    display: flex;
    min-height: 4rem;
}
.jz_vertical_group_has_product.product_cus_pic .first_panel .right_content .title,
.jz_landscape_group_has_product.product_cus_pic .first_panel .right_content .title {
    word-break: break-all;
}

/*样式一 全部分类*/
.jz_vertical_group_has_product.product_cus_pic .first_panel .product_item {
    display: flex;
    height: auto;
    min-height: 4rem;
}

/* 自定义图片缩放 */
.fk-productGroup.cus_pic_scale {
    background-position: center center;
}

/* 字体 */
.fk-productGroup.cus_first_lev_text .first_groupname {
    text-decoration: inherit;
    font-size: inherit;
}

/* 广告图 */
.fk-productGroup .defaultBannerTip {
    position: absolute;
    bottom: 0.2rem;
    left: 50%;
    transform: translateX(-50%);
    font-size: 0.3rem;
    color: rgba(169, 169, 169, 1);
}
.fk-productGroup .group_banner {
    position: relative;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(/image/v2/pdg_default.jpg);
}
.jz_landscape_group_has_product .first_panel .group_banner {
    height: 3rem;
    margin: 0.75rem;
    border-radius: 0.1rem;
}
.jz_vertical_group_has_product .first_panel .group_banner {
    height: 3.85rem;
    border-radius: 0.2rem;
    margin-top: 0.75rem;
}
.jz_vertical_group_has_photo .first_panel .group_banner {
    height: 3.85rem;
    border-radius: 0.2rem;
    margin-top: 0.75rem;
    margin-bottom: 0.15rem;
}
.jz_landscape_group_has_photo .first_panel .group_banner {
    height: 3.85rem;
    border-radius: 0.2rem;
    margin-top: 0.75rem;
}
.jz_vertical_group_no_photo .first_panel .group_banner {
    height: 3.85rem;
    border-radius: 0.2rem;
    margin-top: 0.75rem;
}

.fk-productGroup.group_style_4 .group_banner {
    width: 14.5rem;
    height: 3.85rem;
    margin-left: auto;
    margin-right: auto;
}
.fk-productGroup.group_style_4 .first_panel .group_banner {
    border-radius: 0.2rem;
}
.fk-productGroup.group_style_5 .group_banner,
.fk-productGroup.group_style_7 .group_banner {
    margin-left: -0.5rem;
    width: 11.125rem;
}
.fk-productGroup.group_style_6 .group_banner {
    width: 14.5rem;
    margin-left: -0.5rem;
}
.fk-productGroup.group_style_4 .group_banner,
.fk-productGroup.group_style_6 .group_banner {
    background-image: url(/image/v2/pdg_default_02.jpg);
}

/* 一级分类换行 */
.fk-productGroup.first_group_name_wrap .first_groupitem {
    height: auto;
}
.fk-productGroup.first_group_name_wrap .first_groupname {
    white-space: normal;
    word-break: break-word;
    vertical-align: top;
}
.fk-productGroup.first_group_name_no_wrap .first_groupname {
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    text-overflow: ellipsis;
}
.fk-productGroup.first_group_name_wrap .pgName {
    white-space: normal;
    height: auto;
    text-overflow: initial;
    word-break: break-all;
}
.fk-productGroup.first_group_name_wrap.group_style_10 .text_item,
.fk-productGroup.first_group_name_wrap.group_style_9 .text_item {
    height: auto;
}

/* 二级分类换行 */
.fk-productGroup.sec_group_name_no_wrap .sed_groupname {
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    text-overflow: ellipsis;
}
.fk-productGroup.sec_group_name_wrap .sed_groupname {
    white-space: normal;
    width: auto;
    text-overflow: initial;
    word-break: break-word;
}

.fk-productGroup.sec_group_name_wrap .f-itemImgName {
    white-space: normal;
    width: auto;
    text-overflow: initial;
    word-break: break-all;
}

/* 三级分类换行 */
.fk-productGroup.third_group_name_wrap .f-thirdGroupName {
    white-space: normal;
    width: auto;
    text-overflow: initial;
    word-break: break-word;
}
.fk-productGroup.third_group_name_no_wrap .f-thirdGroupName {
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    text-overflow: ellipsis;
}

/* 产品名称换行 */
.fk-productGroup.product_name_no_wrap .right_content .title {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    text-overflow: ellipsis;
}
.fk-productGroup.product_name_wrap .right_content .title {
    display: block;
}

.proImgSwipe {
    overflow: hidden;
    position: relative;
    margin: 0 auto;
    text-align: center;
    visibility: visible;
    margin-bottom: 0.5rem;
}
.proDetailImgBox {
    margin: 0 auto;
    margin-bottom: 0.5rem;
    overflow: hidden;
    position: relative;
    height: 100%;
}
.jz_vertical_group_has_product .first_panel .product_item .right_content,
.jz_landscape_group_has_product .first_panel .product_item .right_content {
    display: inline-block;
    position: absolute;
    height: 100%;
    width: calc(100% - 4.5rem);
}
</style>
<style lang="scss" scoped>
.sortPanelList .line {
    padding-right: 0.25rem;
    overflow: hidden;
    cursor: pointer;
}
.line {
    padding-left: 0.5rem;
    clear: both;
    position: relative;
    background-repeat: no-repeat;
    background-position: 0.25rem 1rem;
    padding-left: 0.5rem;
    padding-right: 1.6rem;
}
</style>
