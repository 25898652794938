<template>
    <div :id="'formFoldContent' + id" ref="formFoldContent" class="J_formFoldContent f-formFoldContent">
        <div ref="formFoldContentBox" class="J_formFoldContentBox">
            <component
                :is="componentsTable[childStyle]"
                v-if="itemData.load"
                :module-id="childId"
                :children="children"
            />
            <module-loading v-else></module-loading>
        </div>
    </div>
</template>

<script>
import moduleLoading from './ModuleLoading.vue';
import { mapState, mapGetters } from 'vuex';

export default {
    name: 'FoldChildModule',
    components: {
        moduleLoading,
    },
    inject: ['componentsTable'],
    props: {
        id: {
            type: [String, Number],
            default: '',
        },
        itemData: {
            type: Object,
            default: () => ({}),
        },
        labelData: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        ...mapState(['columnModuleHiddenList']),
        ...mapGetters(['getModuleById']),
        childId() {
            return this.labelData.id;
        },
        child() {
            return this.getModuleById(this.childId);
        },
        childStyle() {
            return this.labelData.style;
        },
        children() {
            if (this.child && Array.isArray(this.child.children)) {
                return this.child.children.filter((child) => this.columnModuleHiddenList.includes(child.id) === false);
            } else {
                return [];
            }
        },
    },
    watch: {
        itemData(val) {
            if (val.load) {
                var _this = this;
                this.$nextTick(() => {
                    _this.initModule();
                });
            }
        },
    },
    mounted() {
        if (this.itemData) {
            this.initModule();
        }
    },
    methods: {
        initModule: function () {
            if (this.itemData.load && this.itemData) {
                if (VITE_APP_MODE !== 'visitor') {
                    if (this.id > 0) {
                        if (this.getModuleById(this.id)) {
                            jm('#module' + this.id).removeClass('fk-unConnectModule');
                        } else {
                            if (this.getModuleById(this.id)) {
                                this.getModuleById(this.id).name = this.labelData.labelName;
                            }
                        }
                    }
                }
            }
        },
    },
};
</script>

<style lang="scss">
.f-formFoldContent .f-fromTabLoading {
    height: 8.5rem;
    position: relative;
}
</style>
