var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: [
        _vm.wrapClasses,
        _vm.picTextStyle,
        _vm.bigItemClasses,
        _vm.contentRadiusClass,
      ],
      attrs: {
        id: "newsLine" + _vm.info.id,
        topClassName: _vm._topClassName,
        topSwitch: _vm._topSwitch,
        newsId: _vm.info.id,
        newsName: _vm.info.title,
        picnum: 8,
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "lineBody",
          class: _vm.isOnlyTitle,
          attrs: { id: "lineBody" + _vm.info.id },
        },
        [
          _c(
            "a",
            {
              class: _vm.hideElem,
              attrs: {
                hidefocus: "true",
                href: _vm._newsUrl,
                target: _vm._jumpTarget,
                onclick: _vm.onclickStr,
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "upDownPicText",
                  class: _vm.picTextPositionFlag,
                },
                [
                  _vm.module.content.ptp == 1
                    ? _c(
                        "div",
                        {
                          class: [_vm.picTextPosition, _vm.textAlignClasses],
                          style: _vm.contentBg,
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "g_news_title newTitle",
                              class: [_vm.titleLine, _vm.noDesClass],
                              style: _vm.news_title_style,
                            },
                            [
                              _c("div", { class: _vm._topFlagClass }),
                              _vm._v(" "),
                              (_vm._topFlagClass != "" &&
                                _vm.isNewAddModule &&
                                _vm.module.content.naw == 2) ||
                              (_vm._topFlagClass != "" &&
                                _vm.isNewAddModule &&
                                _vm.module.content.naw == 1)
                                ? _c("top-component")
                                : _vm._e(),
                              _vm._v(
                                _vm._s(_vm.info.title) +
                                  "\n                    "
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          !_vm.module.content.des
                            ? _c("div", { staticClass: "felxTextItem" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "twoLine articleSummray g_text description g_news_abstract newDescriptionLine",
                                    class: [
                                      _vm.descriptionLine,
                                      _vm.isOnlyTitleAndDes,
                                    ],
                                    style: _vm.news_abstract_style,
                                  },
                                  [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(_vm.info.summary) +
                                        "\n                        "
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "headPicTopWrap" },
                    [
                      _c("div", {
                        directives: [
                          {
                            name: "drag-resize",
                            rawName: "v-drag-resize",
                            value: _vm._resizeOptions,
                            expression: "_resizeOptions",
                          },
                        ],
                        staticClass:
                          "upDownPicText_Pic picScaleClass J_img_lazyload",
                        class: [_vm.bigPicClasses, _vm.picRadiusClass],
                        style: _vm.picStyle,
                        attrs: {
                          "src-original": _vm.info.realPicId
                            ? _vm.info.bigPicPath
                            : "",
                          alt: _vm.altName,
                        },
                      }),
                      _vm._v(" "),
                      _vm._topFlagClass != "" &&
                      _vm.isNewAddModule &&
                      _vm.module.content.naw == 1 &&
                      _vm.module.content.ptp == 0
                        ? _c("top-component")
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.module.content.ptp == 0
                    ? _c(
                        "div",
                        {
                          class: [_vm.picTextPosition, _vm.textAlignClasses],
                          style: _vm.contentBg,
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "g_news_title newTitle",
                              class: [_vm.titleLine, _vm.noDesClass],
                              style: _vm.news_title_style,
                            },
                            [
                              _c("div", { class: _vm._topFlagClass }),
                              _vm._v(" "),
                              _vm._topFlagClass != "" &&
                              _vm.isNewAddModule &&
                              _vm.module.content.naw == 2
                                ? _c("top-component")
                                : _vm._e(),
                              _vm._v(
                                _vm._s(_vm.info.title) +
                                  "\n                    "
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          !_vm.module.content.des
                            ? _c("div", { staticClass: "felxTextItem" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "twoLine articleSummray g_text description g_news_abstract newDescriptionLine",
                                    class: [
                                      _vm.descriptionLine,
                                      _vm.isOnlyTitleAndDes,
                                    ],
                                    style: _vm.news_abstract_style,
                                  },
                                  [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(_vm.info.summary) +
                                        "\n                        "
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      class: [_vm.textAlignClasses, _vm.noDesFlag],
                      style: _vm.contentBg,
                    },
                    [
                      _c("new-info", {
                        attrs: { info: _vm.info, index: _vm.index },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }