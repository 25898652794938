var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isOpenBookingBtn && _vm.canOpenProduct
    ? _c("span", [
        _vm.holder
          ? _c("span", { staticClass: "bookingBtnPlaceholder" })
          : _vm._e(),
        _vm._v(" "),
        _c(
          "span",
          {
            staticClass: "g_main_bgColor_v3 g_button proListBookingBtn",
            attrs: { id: "proListBookingBtn" + _vm.info.id, _pid: _vm.info.id },
          },
          [_vm._v("\n        " + _vm._s(_vm.bookingBtnText) + "\n    ")]
        ),
      ])
    : _vm.isOpenFormBtn && _vm.canOpenProduct
    ? _c("span", [
        _vm.holder
          ? _c("span", { staticClass: "bookingBtnPlaceholder" })
          : _vm._e(),
        _vm._v(" "),
        _c(
          "span",
          {
            staticClass:
              "g_main_bgColor_v3 g_button proListBookingBtn btnColorClass",
            style: _vm.btnStyle,
            attrs: { id: "productFormBtn" + _vm.info.id },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                $event.preventDefault()
                return _vm.openForm.apply(null, arguments)
              },
            },
          },
          [_vm._v("\n        " + _vm._s(_vm.productFormBtnText) + "\n    ")]
        ),
      ])
    : !_vm.isNewAddModule && _vm.canOpenProduct
    ? _c("span", {
        staticClass:
          "J_fk-mallCart f-mallCart faiscoIconsGwc icon-gwc g_color g_main_color_v3",
        style: _vm.cartStyle,
        attrs: { id: _vm.info.id },
      })
    : _vm.isNewAddModule && _vm.canOpenProduct
    ? _c(
        "span",
        {
          staticClass:
            "J_fk-mallCart f-mallCart mallCartSvg g_bgColor g_main_bgColor_v3",
          style: _vm.svgBgStyle,
          attrs: { id: _vm.info.id },
        },
        [
          _c(
            "svg",
            {
              staticClass: "jzm-v43 g_color g_main_color_v3",
              style: _vm.svgFillStyle,
              attrs: { role: "img" },
            },
            [_c("use", { attrs: { "xlink:href": "#jzm-v43" } })]
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }