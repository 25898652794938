var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "m_mobi_form_validate_code" }, [
    _c("input", {
      staticClass: "m_mobi_form_input_text",
      style: _vm._mixinsInputStyle,
      attrs: {
        type: "text",
        maxlength: "4",
        placeholder: _vm.module.extInfo.placeEnterValidateCode,
      },
      domProps: { value: _vm.currentValue },
      on: { input: _vm.handleInput },
    }),
    _vm._v(" "),
    _c("img", {
      staticClass: "validateCode_img",
      attrs: { alt: " ", src: _vm.validateCodeSrc },
      on: { click: _vm.refreshValidateCode },
    }),
    _vm._v(" "),
    _c("i", {
      staticClass: "form_validate_refresh",
      on: { click: _vm.refreshValidateCode },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }