<template>
    <div class="photoModule newCarouselMultiPhoto styleForm10" :class="photoSwipeCls">
        <div :id="'photoSwipe' + module.id" class="photoSwipe" :style="itemWidth">
            <div
                id="photoSwipeBox"
                ref="photoSwipeBox"
                class="photoSwipeBox"
                :style="photoSwipeStyle"
                @touchstart="touchstart"
                @touchmove="touchmove"
                @touchend="touchend"
            >
                <div
                    v-for="(item, index) in cycleList"
                    :key="index"
                    class="itemOuterBox"
                    :class="itemPos(index)"
                    :style="itemOuterStyle(index)"
                >
                    <new-carousel-image-item
                        ref="photoImageItem"
                        :item-index="index"
                        :item="item"
                        :outer-box="content.td == 0"
                        :text-pos="content.itp"
                        :no-lazy-load="noLazyLoad"
                    ></new-carousel-image-item>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import NewCarouselImageItem from '../components/NewCarouselImageItem.vue';
export default {
    name: 'CarouselType10',
    components: {
        NewCarouselImageItem,
    },
    inject: ['module', 'options'],
    // eslint-disable-next-line vue/require-prop-types
    props: ['photoSwipeList', 'nameList', 'cycleList', 'noLazyLoad'],
    data() {
        return {
            id: this.module.id,
            styleId: this.module.style,
            currentIndex: 0,
            startPos: {},
            endPos: {},
            isScrolling: 0,
        };
    },
    computed: {
        content() {
            return this.module.content;
        },
        mhighs() {
            return this.content.mhighs;
        },
        picScale() {
            return this.content.picScale;
        },
        itemWidth() {
            var style = {};
            if (this.mhighs.imgWidthSet.t == 1) {
                style['width'] = this.mhighs.imgWidthSet.wp + '%';
            }
            return style;
        },
        photoSwipeStyle() {
            var style = {};
            style['width'] = 100 * this.cycleList.length + '%';
            return style;
        },
        photoSwipeCls() {
            var cls = '';
            if (this.content.td == 1) {
                cls = 'innerBox';
            } else {
                cls += 'outerBox outerBox' + (parseInt(this.content.itp) + 1);
                cls += ' imgTextType' + (parseInt(this.content.its) + 1);
            }
            return cls;
        },
    },
    mounted: function () {
        this.initDom();
    },
    methods: {
        initDom() {
            if (
                (this.mhighs.carouselSet.carouselType == 0 || this.mhighs.carouselSet.carouselType == 1) &&
                this.cycleList.length > 1
            ) {
                this.setAutoplay();
            }
        },
        itemOuterStyle(index) {
            var style = {};
            if (this.mhighs.carouselSet.carouselType == 2) {
                style['transition-duration'] = this.mhighs.carouselSet.switchTime + 'ms';
            }
            style['width'] = 100 / this.cycleList.length + '%';
            style['left'] = -index * (100 / this.cycleList.length) + '%';
            return style;
        },
        show(index) {
            var currentIndex = this.currentIndex;
            var leftIndex = currentIndex - 1 > -1 ? currentIndex - 1 : this.cycleList.length - 1;
            var rightIndex = currentIndex + 1 < this.cycleList.length ? currentIndex + 1 : 0;
            var leftReadyIndex = leftIndex - 1 > -1 ? leftIndex - 1 : this.cycleList.length - 1;
            var rightReadyIndex = rightIndex + 1 < this.cycleList.length ? rightIndex + 1 : 0;

            if (
                index == currentIndex ||
                index == leftIndex ||
                index == rightIndex ||
                index == leftReadyIndex ||
                index == rightReadyIndex
            ) {
                return true;
            } else {
                return false;
            }
        },
        setAutoplay: function () {
            var _this = this;
            window.clearInterval(this.timer);
            if (this.mhighs.carouselSet.carouselType != 2 && this.cycleList.length > 1) {
                var aniTime = this.carouselType == 0 ? 4000 : this.mhighs.carouselSet.aniTime;
                var switchTime = this.carouselType == 0 ? 500 : this.mhighs.carouselSet.switchTime;
                var delay = aniTime + switchTime;
                this.timer = window.setInterval(function () {
                    _this.next();
                }, delay);
            }
        },
        cleanAutoPlay: function () {
            window.clearInterval(this.timer);
        },
        itemPos(index) {
            var leftIndex = this.currentIndex - 1 > -1 ? this.currentIndex - 1 : this.cycleList.length - 1;
            var rightIndex = this.currentIndex + 1 < this.cycleList.length ? this.currentIndex + 1 : 0;
            var leftReadyIndex = leftIndex - 1 > -1 ? leftIndex - 1 : this.cycleList.length - 1;
            var rightReadyIndex = rightIndex + 1 < this.cycleList.length ? rightIndex + 1 : 0;
            if (index == this.currentIndex) {
                return 'center';
            } else if (index == leftIndex) {
                return 'left';
            } else if (index == rightIndex) {
                return 'right';
            } else if (index == leftReadyIndex || index == rightReadyIndex) {
                return 'off';
            } else {
                return 'hide';
            }
        },
        touchstart(event) {
            var touch = event.targetTouches[0]; //touches数组对象获得屏幕上所有的touch，取第一个touch
            this.isScrolling = 0; //这个参数判断是垂直滚动还是水平滚动
            this.startPos = {
                x: touch.pageX,
                y: touch.pageY,
                time: +new Date(),
            }; //取第一个touch的坐标值
            this.cleanAutoPlay();
        },
        touchmove(event) {
            //当屏幕有多个touch或者页面被缩放过，就不执行move操作
            if (event.targetTouches.length > 1 || (event.scale && event.scale !== 1)) {
                return;
            }
            var touch = event.targetTouches[0];

            this.endPos = {
                x: touch.pageX - this.startPos.x,
                y: touch.pageY - this.startPos.y,
                time: +new Date(),
            };
            this.isScrolling = Math.abs(this.endPos.x) < Math.abs(this.endPos.y) ? 1 : 0;
            if (this.isScrolling === 0) {
                event.preventDefault();
            }
        },
        touchend() {
            if (this.isScrolling === 0) {
                if (Number(this.endPos.x) > 10 && Number(this.endPos.y) < 40) {
                    this.prev();
                } else if (Number(this.endPos.x) < -10 && Number(this.endPos.y) < 40) {
                    this.next();
                }
                this.endPos.x = 0;
                this.endPos.y = 0;
            }
            this.setAutoplay();
        },
        next() {
            var tmpIndex = this.currentIndex + 1;
            if (tmpIndex == this.cycleList.length) {
                tmpIndex = 0;
            }
            this.switchPhoto(tmpIndex, false);
        },
        prev() {
            var tmpIndex = this.currentIndex - 1;
            if (tmpIndex < 0) {
                tmpIndex = this.cycleList.length - 1;
            }
            this.switchPhoto(tmpIndex, true);
        },
        switchPhoto(currentIndex) {
            this.currentIndex = currentIndex;
        },
    },
};
</script>
<style lang="scss">
.formStyle42 .styleForm10 {
    width: 100%;
    text-align: center;
    overflow: hidden;
}
.formStyle42 .styleForm10 .photoSwipe {
    text-align: center;
    position: relative;
    display: block;
    font-size: 0;
    display: inline-block;
    width: 86%;
    perspective: unset;
}
.formStyle42 .styleForm10 .photoSwipeBox {
    margin: 0.75rem 0;
    display: flex;
    perspective: unset;
    font-size: 0;
}
.formStyle42 .newCarouselMultiPhoto.styleForm10 .itemOuterBox {
    width: 100%;
    text-align: center;
    transition: all 0.5s ease-in-out;
    position: relative;
    display: inline-block;
    backface-visibility: hidden;
    transform-style: preserve-3d;
}
.formStyle42 .newCarouselMultiPhoto.styleForm10 .photoImageItem {
    border-radius: 0.2rem;
    height: 100%;
}
.formStyle42 .styleForm10 .photoImageItem.outerBox {
    border-radius: 0.2rem;
}
.formStyle42 .newCarouselMultiPhoto.styleForm10 .photoImageItem .imgLink {
    height: 7.75rem;
}
.formStyle42 .styleForm10 .photoSwipeBox .itemOuterBox.center {
    transform: translate3d(0, 0, 0) scale(1);
    z-index: 9999;
    opacity: 1;
}
.formStyle42 .styleForm10 .photoSwipeBox .itemOuterBox.left {
    transform: translate3d(-93%, 0, -50px) scale(0.8);
    z-index: 2;
    opacity: 1;
}

.formStyle42 .styleForm10 .photoSwipeBox .itemOuterBox.right {
    transform: translate3d(93%, 0, -50px) scale(0.8);
    z-index: 2;
    opacity: 1;
}
.formStyle42 .styleForm10 .photoSwipeBox .itemOuterBox.off {
    transform: translate3d(0, 0, -1000px) scale(0.64);
    z-index: -1;
    opacity: 0;
}
.formStyle42 .styleForm10 .photoSwipeBox .itemOuterBox.hide {
    transform: translate3d(0, 0, -1000px) scale(0.64);
    z-index: -1;
    opacity: 0;
    visibility: hidden;
}
.formStyle42 .styleForm10 .photoSwipeBox .itemOuterBox.hide .imgLink {
    display: none;
}
.formStyle42 .styleForm10.innerBox .photoImageItem .photoImageItemContent {
    border-radius: 0.2rem;
    box-shadow: rgba(0, 0, 0, 0.25) 0 0.4rem 0.48rem -0.2rem;
}
</style>
