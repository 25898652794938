<template>
    <div :style="{ height: height + 'px' }">
        <div class="pageLoading J_pageLoading">
            <div class="fk-circle">
                <div class="fk-circle1 fk-child"></div>
                <div class="fk-circle2 fk-child"></div>
                <div class="fk-circle3 fk-child"></div>
                <div class="fk-circle4 fk-child"></div>
                <div class="fk-circle5 fk-child"></div>
                <div class="fk-circle6 fk-child"></div>
                <div class="fk-circle7 fk-child"></div>
                <div class="fk-circle8 fk-child"></div>
                <div class="fk-circle9 fk-child"></div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'JzProductGroupLoading',
    props: {
        height: {
            type: [String, Number],
            default: '',
        },
    },
};
</script>
<style>
.pageLoading {
    background-color: #fff;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    overflow: hidden;
}
.fk-circle {
    margin: auto;
    width: 40px;
    height: 40px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
.fk-circle .fk-child {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}
.fk-circle .fk-child:before {
    content: '';
    display: block;
    margin: 0 auto;
    width: 20%;
    height: 20%;
    background-color: #bfbfbf;
    border-radius: 100%;
    animation: fk-circleBounceDelay 1.2s infinite ease-in-out both;
}
.fk-circle .fk-circle2 {
    transform: rotate(40deg);
}
.fk-circle .fk-circle3 {
    transform: rotate(80deg);
}
.fk-circle .fk-circle4 {
    transform: rotate(120deg);
}
.fk-circle .fk-circle5 {
    transform: rotate(160deg);
}
.fk-circle .fk-circle6 {
    transform: rotate(200deg);
}
.fk-circle .fk-circle7 {
    transform: rotate(240deg);
}
.fk-circle .fk-circle8 {
    transform: rotate(280deg);
}
.fk-circle .fk-circle9 {
    transform: rotate(320deg);
}
.fk-circle .fk-circle10 {
    transform: rotate(270deg);
}
.fk-circle .fk-circle11 {
    transform: rotate(300deg);
}
.fk-circle .fk-circle12 {
    transform: rotate(330deg);
}
.fk-circle .fk-circle2:before {
    animation-delay: -0.8s;
}
.fk-circle .fk-circle3:before {
    animation-delay: -0.7s;
}
.fk-circle .fk-circle4:before {
    animation-delay: -0.6s;
}
.fk-circle .fk-circle5:before {
    animation-delay: -0.5s;
}
.fk-circle .fk-circle6:before {
    animation-delay: -0.4s;
}
.fk-circle .fk-circle7:before {
    animation-delay: -0.3s;
}
.fk-circle .fk-circle8:before {
    animation-delay: -0.2s;
}
.fk-circle .fk-circle9:before {
    animation-delay: -0.1s;
}
.fk-circle .fk-circle10:before {
    animation-delay: -0.3s;
}
.fk-circle .fk-circle11:before {
    animation-delay: -0.2s;
}
.fk-circle .fk-circle12:before {
    animation-delay: -0.1s;
}

@keyframes fk-circleBounceDelay {
    0%,
    80%,
    100% {
        transform: scale(0);
        background-color: #bfbfbf;
    }
    40% {
        transform: scale(1);
        background-color: #bfbfbf;
    }
}
</style>
