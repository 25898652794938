var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "three_pic_mix_pic_text_list", class: _vm.isOnlyTitle },
    [
      !!_vm.headPicItemInfo
        ? _c("three-pic-text-item", {
            attrs: {
              info: _vm.headPicItemInfo,
              "no-lazy-load": _vm.noLazyLoad,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "g_separator separatorLine" }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "news_list-pic-text" },
        [
          _vm.isNewModule
            ? [
                _vm._l(_vm.newsList2, function (info, index) {
                  return [
                    _c("pic-text-item-v2", {
                      key: "n_" + info.id,
                      attrs: {
                        info: info,
                        index: index,
                        "no-lazy-load": _vm.noLazyLoad,
                      },
                    }),
                    _vm._v(" "),
                    _c("div", {
                      key: "s_" + info.id,
                      staticClass: "g_separator separatorLine",
                    }),
                  ]
                }),
              ]
            : [
                _vm._l(_vm.newsList2, function (info, index) {
                  return [
                    _c("pic-text-item-v1", {
                      key: "n_" + info.id,
                      attrs: {
                        info: info,
                        index: index,
                        "no-lazy-load": _vm.noLazyLoad,
                      },
                    }),
                  ]
                }),
              ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }