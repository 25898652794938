<template>
    <div :class="columnWrapClass" class="text_list">
        <template v-for="(info, index) in newsList">
            <text-list-item
                :key="'n_' + info.id"
                :info="info"
                :index="index"
                :is-column2="isColumn2"
                :list-length="newsList.length"
            />
            <div v-if="isNeedcSeparatorLine(index)" :key="'s_' + info.id" class="c2_separatorLine"></div>
            <div v-if="!isColumn2" :key="'se_' + info.id" class="g_separator separatorLine"></div>
        </template>
    </div>
</template>

<script>
import TextListItem from './text-list-item.vue';

export default {
    name: 'TextList',
    components: { TextListItem },
    inject: ['module', 'options'],
    props: ['newsList'],
    computed: {
        isColumn2() {
            return this.module.content.naw == 2;
        },
        columnWrapClass() {
            return this.isColumn2 ? `newsArrayPanel column${this.module.content.naw}` : '';
        },
    },
    methods: {
        isNeedcSeparatorLine(i) {
            const newsListRealLen = this.newsList.length;
            const isOdd = i % 2 == 1;
            const isLast = i == newsListRealLen - 1;
            return this.isColumn2 && (isLast || isOdd);
        },
    },
};
</script>

<style></style>
