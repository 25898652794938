var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("ModuleFrame", { attrs: { "module-id": _vm.moduleId } }, [
    !_vm.isNewModule
      ? _c("div", [
          _vm.module.type === 22
            ? _c("div", { staticClass: "sysSearch" }, [
                _c("a", {
                  staticClass: "searchBackBtn faisco-icons-back5",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.back.apply(null, arguments)
                    },
                  },
                }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "searchBar",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.handlePopupSearchSys.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("span", { staticClass: "searchTip" }, [
                      _vm._v(_vm._s(_vm.palceholder)),
                    ]),
                    _vm._v(" "),
                    _c("div", { class: ["icon-mSearchBtn", _vm.colorCls] }),
                  ]
                ),
              ])
            : _c(
                "div",
                {
                  class: _vm.searchCls,
                  style: _vm.moduleColorStyle,
                  attrs: { id: "searchContainer" + _vm.module.id },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.handlePopupSearch.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "icon-mSearchIcon" }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "mSearchInput", style: _vm.textStyle },
                    [_vm._v(_vm._s(_vm.searchTip))]
                  ),
                  _vm._v(" "),
                  _c("div", {
                    class: ["icon-mSearchBtn", _vm.colorCls],
                    style: _vm.moduleColorStyleIcon,
                  }),
                ]
              ),
        ])
      : _c(
          "div",
          [
            !_vm.centerText
              ? [
                  _c(
                    "div",
                    {
                      staticClass: "search newProductSearch",
                      class: [
                        _vm.styleTypeClass,
                        _vm.contentStyle,
                        _vm.textAlign,
                        _vm.searchFlag,
                      ],
                      style: _vm.moduleColorStyle,
                      attrs: { id: "searchContainer" + _vm.module.id },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.handlePopupSearch.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "mSearchInput", style: _vm.textStyle },
                        [_vm._v(_vm._s(_vm.searchTip))]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "svgDiv",
                          class: [_vm.svgBgColor, _vm.defaultColor],
                          style: _vm.newModuleColorStyleIcon,
                        },
                        [
                          _c(
                            "svg",
                            {
                              staticClass:
                                "searchSvg g_oldThemefillColor g_main_fillColor_v3",
                              style: { fill: _vm.svgIcon },
                            },
                            [_c("use", { attrs: { "xlink:href": "#jzm-v19" } })]
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              : [
                  _c(
                    "div",
                    {
                      staticClass: "search newProductSearch centerText",
                      class: [
                        _vm.styleTypeClass,
                        _vm.contentStyle,
                        _vm.textAlign,
                        _vm.searchFlag,
                      ],
                      style: _vm.moduleColorStyle,
                      attrs: { id: "searchContainer" + _vm.module.id },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.handlePopupSearch.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "svgDiv",
                          class: [_vm.svgBgColor, _vm.defaultColor],
                          style: _vm.newModuleColorStyleIcon,
                        },
                        [
                          _c(
                            "svg",
                            {
                              staticClass:
                                "searchSvg g_oldThemefillColor g_main_fillColor_v3",
                              style: { fill: _vm.svgIcon },
                            },
                            [_c("use", { attrs: { "xlink:href": "#jzm-v19" } })]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "mSearchInput", style: _vm.textStyle },
                        [_vm._v(_vm._s(_vm.searchTip))]
                      ),
                    ]
                  ),
                ],
          ],
          2
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }