<template>
    <div class="online_map_view">
        <div class="online_map_view_header">
            <svg role="img" class="back_arrow new_online_map_svg" @click="back">
                <use xlink:href="#jzm-v1" />
            </svg>
            <span class="title">{{ otherInfo.title }}</span>
        </div>
        <div id="mapContainer" class="map_container"></div>
        <div class="footer">
            <div class="info">
                <div class="wrap">
                    <span class="name">{{ name }}</span>
                    <span class="distance">{{ distance }}</span>
                </div>
                <div class="location" :style="locationStyle">
                    <svg role="img" class="new_online_map_svg" :style="locationSVGStyle">
                        <use xlink:href="#jzm-v32" />
                    </svg>
                    <span class="address">{{ detail }}</span>
                </div>
            </div>
            <div class="action_area">
                <div class="route" @click="routeNav">
                    <svg role="img" class="new_online_map_svg">
                        <use xlink:href="#jzm-v35" />
                    </svg>
                    <span>{{ otherInfo.route }}</span>
                </div>
                <a v-if="module.content.isShowTel" class="tel" @click="callTel">
                    <svg role="img" class="new_online_map_svg">
                        <use xlink:href="#jzm-v33" />
                    </svg>
                    <span>{{ otherInfo.call }}</span>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import mixin from '../mixin';
import { encodeHtml, decodeHtml } from '@/shared/util';
import { ing } from '@/shared/fai';
import { triggerServiceNumber } from '@/modules/comm';
import { getBMAPCurPositionPoint, addComplexCustomOverlay } from '../../util';

const manageMode = VITE_APP_MODE !== 'visitor';

let map = null;
export default {
    mixins: [mixin],
    props: ['location', 'module', 'options'],
    data() {
        return {
            currentLocation: JSON.parse(JSON.stringify(this.location)),
        };
    },
    computed: {
        tel() {
            return this.location.tel;
        },
        point() {
            let {
                bmp: { x, y },
            } = this.location;
            let point = { lng: x, lat: y };
            return point;
        },
        name() {
            return this.decodeHtml(this.location.name);
        },
        detail() {
            return this.location.detail;
        },
        distance() {
            return this._formatDistance(this.currentLocation.distance);
        },
        isWrapAddressDetail() {
            return this.module.content.isWrapAddressDetail;
        },
        locationStyle() {
            return {
                'align-items': 'start',
            };
        },
        otherInfo() {
            return this.module.otherInfo || {};
        },
        locationSVGStyle() {
            // 地址详情的font-size
            const LOCATION_DEFAULT_FONT_SIZE = 0.5;

            // 地址详情盒子高度
            const LOCATION_LINE_HEIGHT = 1.5;

            const LOCATION_BOX_HEIGHT = LOCATION_DEFAULT_FONT_SIZE * LOCATION_LINE_HEIGHT;

            const LOCATION_ICON_HEIGHT = 0.65;

            // 图标与第一行文字垂直居中需要的marigin-top
            return {
                'margin-top': LOCATION_BOX_HEIGHT / 2 - LOCATION_ICON_HEIGHT / 2 + 'rem',
            };
        },
        manageMode() {
            return manageMode;
        },
    },
    watch: {
        location: {
            handler() {
                this.refreshIcon();
            },
            deep: true,
        },
        'module.content.isShowTel'() {
            this.refreshIcon();
        },
    },
    mounted() {
        if (this.options.isJumpLink) {
            this.initListDistance();
        }
        this.$nextTick(() => {
            this.initBMap();
            this.refreshIcon();
        });
    },
    beforeDestroy() {
        // 隐藏视图
        let $mapView = jm('.map_view');
        $mapView.hide();
        // 恢复滚动条
        let $body = jm('#g_web');
        $body.css('overflow-y', 'auto');
    },
    methods: {
        decodeHtml,
        getCurPositionPoint() {
            return getBMAPCurPositionPoint();
        },
        initListDistance() {
            if (this.isLoadingPosition) {
                return;
            }
            let promise = this.getCurPositionPoint();
            this.isLoadingPosition = true;
            var _this = this;
            promise.then((sPoint) => {
                let {
                    bmp: { x, y },
                } = _this.location;
                let ePoint = { x, y };

                sPoint = new window.BMap.Point(sPoint.x, sPoint.y);
                ePoint = new window.BMap.Point(ePoint.x, ePoint.y);
                _this.currentLocation.distance = String(map.getDistance(sPoint, ePoint));
            });
        },
        encodeHtml,
        back() {
            // 销毁当前实例
            this.$destroy();
        },
        callTel() {
            if (this.tel.length === 0) {
                if (VITE_APP_MODE !== 'visitor') {
                    ing('电话号码为空', false);
                } else {
                    ing('暂无电话');
                }
                return;
            }
            triggerServiceNumber(1, this.tel);
        },
        routeNav() {
            if (VITE_APP_MODE !== 'visitor') {
                ing('管理态不支持跳转外部链接，请通过“预览”进行访问', false);
                return;
            }
            window.location.href = `//api.map.baidu.com/marker?location=${this.point.lat},${
                this.point.lng
            }&title=${encodeURIComponent(this.name)}&content=${encodeURIComponent(this.detail)}&output=html&src=fk`;
        },
        initBMap() {
            const containerId = 'mapContainer';
            map = new window.BMap.Map(containerId);
        },
        refreshIcon() {
            map.clearOverlays();
            const location = this.location;
            let { bmp } = location;
            let point = { lng: bmp.x, lat: bmp.y };
            point = new window.BMap.Point(point.lng, point.lat);
            let view = map.getViewport([point]);
            let { center, zoom } = view;
            map.centerAndZoom(center, zoom);
            let myIcon = new window.BMap.Icon(
                Fai.top._resRoot + '/image/onlineMap/marker_red_sprite.png?v=201809101152',
                new window.BMap.Size(22, 25),
                {
                    anchor: new window.BMap.Size(10, 25),
                }
            );

            let marker = new window.BMap.Marker(point, {
                icon: myIcon,
            });

            // 根据条件生成DOM
            const isNewModule = this.module.content.isNew;
            const defaultName = '新增地址';
            const newModuleIsShowName = isNewModule && location.name; //名称非空
            const oldModuleIsShowName = !isNewModule && location.name !== '' && location.name !== defaultName; // 默认名称
            const isShowName = newModuleIsShowName || oldModuleIsShowName;

            let nameDOM = `<div class="name">${this.encodeHtml(location.name)}</div>`;
            let detailDOM = `<div class="address">${LS.js_onlineMapAddressTip}：${this.encodeHtml(
                location.detail
            )}</div>`;
            let telDOM = ` <div class="tel">${LS.js_onlineMapPhoneTip}：${this.encodeHtml(location.tel)}</div>`;
            let otherDOM = ` <div class="other">${this.encodeHtml(location.other)}</div>`;
            // 生成对话框DOM
            let content = `
          ${isShowName ? nameDOM : ''}
          ${location.detail ? detailDOM : ''}
          ${location.tel && this.module.content.isShowTel ? telDOM : ''}
          ${location.other ? otherDOM : ''}
      `;

            let ComplexCustomOverlay = addComplexCustomOverlay(window.BMap);

            let myCompOverlay = new ComplexCustomOverlay({
                point,
                content: '<div class="msg">' + content + '</div>',
                boxClass: `msgBox`,
                arrowClass: 'arrow',
                zIndex: 1,
                offset: {
                    x: 0,
                    y: -35,
                },
            });
            map.addOverlay(marker);
            map.addOverlay(myCompOverlay);
        },
    },
};
</script>

<style>
.map_view {
    position: fixed;
    height: 100%;
    background-color: #fff;
    width: 100%;
    top: 0;
    z-index: 9999;
}

.online_map_view {
    height: 100%;
    display: flex;
    flex-direction: column;
}
.map_view .online_map_view_header {
    height: 2.25rem;
    display: flex;
    align-items: center;
    position: relative;
}
.map_view .online_map_view_header .title {
    margin: auto;
    color: #333;
    font-weight: 500;
    font-size: 0.75rem;
}
.map_view .back_arrow {
    width: 0.75rem;
    height: 0.75rem;
    fill: #666;
    position: absolute;
    left: 0.5rem;
}

.map_view .map_container {
    flex: 1;
}
.map_view .footer .info {
    padding: 0.75rem;
}
.map_view .footer .location {
    display: flex;
    align-items: center;
    line-height: 1.5;
}
.map_view .footer .wrap {
    margin-bottom: 0.4rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.map_view .footer .wrap .name {
    font-size: 0.7rem;
    font-weight: bold;
    color: #333;
    word-break: break-all;
}

.map_view .footer .wrap .distance {
    font-size: 0.45rem;
    color: #999;
    font-weight: 500;
}

.map_view .location .address {
    color: #333;
    font-size: 0.5rem;
    word-break: break-all;
}

.map_view .footer .action_area {
    border-top: solid 1px #eee;
    display: flex;
    height: 1.9rem;
    margin: 0 0.75rem;
}
.map_view .footer .action_area .route {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #333;
    font-size: 0.55rem;
    flex: 1;
}
.map_view .footer .action_area .tel {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    color: #333;
    font-size: 0.55rem;
}

.map_view .msgBox {
    display: inline-block;
    cursor: default;
    position: absolute;
    width: auto;
    height: auto;
    padding: 0.7rem 0.9rem 0.7rem 0.5rem;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: rgb(0, 0, 0) 0px 0px 6px -3px;
    color: #333;
}
.map_view .msgBox .msg {
    display: inline-block;
    width: auto;
    word-wrap: break-word;
    word-break: break-word;
    font-size: 0.56rem;
    width: 10rem;
}

.map_view .msgBox .msg .name {
    font-size: 0.6rem;
    margin-bottom: 0.2rem;
    word-break: break-all;
}
.map_view .msgBox .msg .address {
    font-size: 0.45rem;
    color: #666;
    word-break: break-all;
}
.map_view .msgBox .msg .tel {
    font-size: 0.45rem;
    color: #666;
}
.map_view .msgBox .msg .other {
    font-size: 0.45rem;
    color: #666;
    word-break: break-all;
}
.map_view .msgBox .arrow {
    position: absolute;
    display: inline-block;
    bottom: -6px;
    left: 3.2rem;
    width: 0;
    height: 0px;
    content: '';
    border-style: solid;
    border-width: 7px;
    border-color: #fff #fff transparent transparent;
    transform: rotate(135deg);
    box-shadow: rgb(0, 0, 0) 1px -1px 3px -2px;
}
.map_view .msgBox .close {
    cursor: pointer;
    display: block;
    position: absolute;
    right: 10px;
    top: 10px;
    width: 10px;
    height: 10px;
    background: url(/image/onlineMap/close.png?v=201811211416);
    background-size: cover;
}
</style>
