var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("module-frame", { attrs: { "module-id": _vm.moduleId } }, [
    _vm.isManageMode && _vm.isLoadAllScriptAndHasModuleManageAuth
      ? _c(
          "div",
          {
            staticClass: "simpleText manageSimpleText",
            class: "J_simpleText_" + _vm.moduleId,
            on: { dblclick: _vm.openDesigner },
          },
          [
            _c("div", {
              staticClass: "fk-editor simpleText fk-editor-break-word",
              class: _vm.writingModeClass,
              style: _vm.sizeStyle,
              attrs: { _autowidth: _vm.autoWidth, _autoheight: _vm.autoHeight },
              domProps: { innerHTML: _vm._s(_vm.preProcessContent) },
            }),
          ]
        )
      : _c("div", {
          class:
            "fk-editor simpleText fk-editor-break-word " +
            _vm.writingModeClass +
            " " +
            _vm.simpleTextCursor,
          style: _vm.sizeStyle,
          domProps: { innerHTML: _vm._s(_vm.preProcessContent) },
        }),
    _vm._v(" "),
    _vm.isManageMode
      ? _c("div", { staticStyle: { display: "none" } }, [
          _c("input", {
            attrs: {
              id: "simpleTextLinkInputVal-" + _vm.moduleId,
              maxlength: "255",
            },
          }),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }