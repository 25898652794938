<template>
    <div
        :id="itemId"
        :topClassName="_topInfo.topName"
        :topSwitch="_topInfo.topSwitch"
        :productId="info.id"
        :productName="info.name"
        class="productCrossedSlideSec leftPosition J_prodItem proMarginClass J_product_item"
        :title="_title2"
        :style="itemStyle"
        :class="[_contentStyle, _wrapBorderRadiusClass]"
    >
        <a :href="info.extInfo.href" class="J_prodItem-a" :onclick="onclickStr">
            <div class="fk-productListImgWrap">
                <div v-if="manageMode && info.top != 0 && !_productSelect" class="f-productListTopFlag">置顶</div>
                <sale-flag v-if="info.extInfo.salePromotionOpen" :info="info"></sale-flag>
                <div :id="'CrossedSlideSecImg' + info.id + 'module' + module.id" class="CrossedSlideSecImg">
                    <div
                        v-drag-resize="_resizeOptions"
                        class="J_img_lazyload style9Img firstLastPro"
                        :title="info.name"
                        :src-original="_imgSrc"
                        :style="slideImgStyle"
                        :class="[_imgScaleClass, _imgBorderRadiusClass]"
                    ></div>
                </div>
                <quality-content-label
                    v-if="_isShowQualityContentLabel"
                    :text="_knowPayLabelText"
                ></quality-content-label>
            </div>
            <product-name
                v-if="module.content.showProName && module.content.picSetting.picSizeSet == 1"
                :info="info"
            ></product-name>
            <mall-cart
                v-if="!isNewAddModule && _showMallCart.mallCart2 && module.content.picSetting.picSizeSet == 1"
                :info="info"
                :holder="_showMallCartHolder"
            ></mall-cart>
            <prop-panel
                :info="info"
                :list="_showPropList"
                :ext-info="_extInfo"
                :show-mall-cart="_showMallCart"
                :text-align-class="textAlignClass"
                :no-name-wrap="noNameWrap"
            >
            </prop-panel>
        </a>
    </div>
</template>

<script>
import productItemMixin from '../../mixins/productItemMixin';
import saleFlag from '../comm/sale-flag.vue';
import propPanel from '../comm/prop-panel.vue';
import mallCart from '../comm/mall-cart.vue';
import qualityContentLabel from '../comm/quality-content-label.vue';

const manageMode = VITE_APP_MODE !== 'visitor';
export default {
    name: 'SlideItem',
    components: { saleFlag, mallCart, propPanel, qualityContentLabel },
    mixins: [productItemMixin],
    inject: ['module', 'options'],
    props: ['info', 'index', 'len'],
    data() {
        return {
            itemId: `productCrossedSlideSec${this.info.id}_module${this.module.id}`,
            imgWrapId: `imgClass${this.info.id}_module${this.module.id}`,
            manageMode,
        };
    },
    computed: {
        //已抢光标志图片
        soldOutImg() {
            return `${this.options.resRoot}/image/soldOut${this.module.extInfo.isCN ? '' : '_en'}.png`;
        },
        slideImgStyle() {
            if (!this.isNewAddModule) {
                return this._imgBgStyle;
            }
            if (this._isTransparentStyle) {
                return { ...this._proRadiusStyle, ...this._imgBgStyle };
            } else {
                return { ...this._fixProRadiusStyle, ...this._imgBgStyle };
            }
        },
        textAlignClass() {
            let textAlign = this.module.content.ta == 0 ? 'textAlign_center' : 'textAlign_left';
            return textAlign;
        },
        wrapClass() {
            return this.module.content.nameWrap ? 'paramWrap' : 'paramNowrap';
        },
        noNameWrap() {
            return 'paramWrap';
        },
        itemStyle() {
            var styles = {};
            if (this.index > 1) {
                styles.display = 'none';
            } else {
                styles.display = 'flex';
            }

            if (this.isNewAddModule && manageMode) {
                let proMargin = this.module.content.proMargin;
                let proMarginType = proMargin.proMarginType;
                let proMarginX = proMargin.proMarginX;
                if (proMarginType == 1) {
                    styles.marginLeft = proMarginX + 'px';
                }
            }
            if (this._isTransparentStyle) {
                return styles;
            } else {
                return { ...styles, ...this._warpProRadiusStyle };
            }
        },
    },
};
</script>
<style lang="scss">
.isNewAddModule .fk-mProductList9 .productCrossedSlideSec {
    border: none;
    flex-shrink: 0;
    display: flex !important;
    flex-wrap: wrap;
}
.isNewAddModule .fk-mProductList9 .productSlideList {
    display: flex;
    flex-wrap: nowrap;
}
.form .isNewAddModule .fk-mProductList9 a {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}
.isNewAddModule .fk-mProductList9 .productCrossedSlideSec .paramCollection {
    width: 100%;
    box-sizing: border-box;
}
</style>
