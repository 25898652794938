<template>
    <div class="mobi_form_radio choice_box_wrap">
        <label
            v-for="name in radioGroup"
            :key="radioKey(name)"
            class="m_radio_wrap choice_box"
            :class="{
                m_radio_checked: currentValue === name,
                form_item_vertical: module.content.od == 1,
                disabled: isZeroResidue(name),
            }"
        >
            <span class="m_radio">
                <span class="m_form_radio_inner m_radio_inner icon-" :class="{ active: currentValue === name }"></span>
                <input
                    class="m_radio_input"
                    type="radio"
                    :checked="currentValue === name"
                    :value="name"
                    :disabled="isZeroResidue(name)"
                    @change="change"
                />
            </span>
            <span :style="fontStyle"> {{ name }}</span>
            <span v-if="getResidueText(name) != ''" :style="fontStyle" class="residue_text">{{
                getResidueText(name)
            }}</span>
        </label>
    </div>
</template>
<script>
import { fontStyle } from '../utils';
import { encodeHtml } from '@/shared/util';
import { residueMixins } from '../mixins/residue.js';
import formDetailEditMixin from '../mixins/formDetailEdit.js';

export default {
    name: 'FormRadio',
    mixins: [residueMixins(), formDetailEditMixin],
    inject: ['module', 'options'],
    props: {
        formItem: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            currentValue: '',
        };
    },
    computed: {
        radioGroup() {
            return (this.formItem.input || '').split('\n').filter((formItem) => formItem);
        },
        fontStyle() {
            return fontStyle(this.module.content.mhighs.oin);
        },
        submitVal() {
            return this.currentValue;
        },
    },
    mounted() {
        this.initDefaultValue();
    },
    methods: {
        change(event) {
            this.currentValue = event.target.value;
            this.$emit('selectChange', {
                value: this.currentValue,
                formItem: this.formItem,
            });
        },
        resetValue() {
            this.initDefaultValue();
        },
        validate(needValidate) {
            let must = needValidate || this.formItem.must;
            if (must && this.currentValue === '') {
                this.$mobiIng(jm.format(LS.siteFormSubmitInputIsEmpty, encodeHtml(this.formItem.name)));
                return false;
            }

            return true;
        },
        radioKey(name) {
            return `${name}`;
        },
        async initDefaultValue() {
            const initialValue = this.inMemberDetailEdit ? this.getEditingItemSubmitValue() : this.formItem.dtr;

            if (typeof initialValue === 'string' && initialValue !== '' && !this.isZeroResidue(initialValue)) {
                this.currentValue = initialValue;
                await this.$nextTick(); // 保证前面的修改完成
                this.$emit('selectChange', {
                    value: this.currentValue,
                    formItem: this.formItem,
                });
            } else {
                this.currentValue = '';
            }
        },
    },
};
</script>

<style>
.m_form_radio_inner {
    background-color: #fff;
}

.m_form_radio_inner.active {
    background-color: #292929;
    color: #fff;
}

.mobi_form_radio .m_radio_wrap {
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    pointer-events: auto;
    margin-right: 1rem;
}

.mobi_form_radio .m_radio {
    display: inline-block;
    margin-right: 0.2rem;
    outline: 0;
    position: relative;
    line-height: 1;
    vertical-align: -0.25em;
    cursor: pointer;
}

.mobi_form_radio .m_radio_inner {
    position: relative;
    display: inline-block;
    width: 0.8rem;
    height: 0.8rem;
    top: 0;
    left: 0;
    border: 1px solid rgba(216, 216, 216, 1);
    border-radius: 50%;
    font-size: 0.375rem;
    box-sizing: border-box;
}

.mobi_form_radio .m_radio_checked .m_radio_inner:after {
    content: '\b0335';
    position: absolute;
    left: 50%;
    top: 50%;
    border-radius: 0;
    transform: translate(-50%, -50%);
    font-size: 0.6rem;
}

.mobi_form_radio .m_radio_input {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}
</style>
