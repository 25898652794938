import { isBrowserEnv, digGet, context } from '@jz/utils';

import { MODULE_STYLE } from '@/def/module';
export const slidesShowDataMixins = {
    data() {
        return {
            initialIndex: 0,
            showMobiViewer: false,
            zIndex: 9999,
            eventSet: false,
            documentEventEl: null,
        };
    },
    created() {
        this.documentEventEl = isBrowserEnv() && VITE_APP_MODE !== 'visitor' ? top.document : null;
    },
    computed: {
        banCopyTextImg() {
            if (VITE_APP_MODE !== 'visitor') {
                return Fai.top.banCopyTextImg;
            } else {
                return context.banCopyTextImg;
            }
        },
    },
};

export const slidesshowMethodsMixins = {
    methods: {
        openPhotoSlidesshow(index = 0, item = {}, flag = false) {
            const { jumpUrl, picHref } = item;
            let url = jumpUrl == undefined ? picHref : jumpUrl;
            if (
                (!url ||
                    (digGet(url, 'includes') &&
                        (url.includes('javascript:;') || url.includes('void(0)')) &&
                        !url.includes('管理态不支持跳转外部链接'))) &&
                !flag
            ) {
                if (this.initialIndex !== index) {
                    this.initialIndex = index;
                }
                this.showMobiViewer = true;
            }
        },
        onClose() {
            this.showMobiViewer = false;
        },
        openPhotoSildeForSearch(index = 0) {
            if (this.initialIndex !== index) {
                this.initialIndex = index;
            }
            this.showMobiViewer = true;
        },
    },
};

export const slidesshowMixins = {
    mixins: [slidesShowDataMixins, slidesshowMethodsMixins],
    mounted() {
        if (this.moduleLink !== 0) {
            this.eventSet = true;
            this.$modulesEventBus.$on(`photo-slidesshow${this.id}`, this.openPhotoSlidesshow);
        }
        if (this.style == MODULE_STYLE.TOTAL_SEARCH_RESULT) {
            this.$modulesEventBus.$on(`searchResult-slidesshow${this.moduleId}`, this.openPhotoSildeForSearch);
        }
        if (
            [MODULE_STYLE.NEWS_DETAIL_V3, MODULE_STYLE.NEWS_DETAIL, MODULE_STYLE.NEWS_NEW_DETAIL].includes(this.style)
        ) {
            this.$modulesEventBus.$on(`newsDetail-slidesshow${this.moduleId}`, this.openPhotoSildeForSearch);
        }
    },
    computed: {
        moduleLink() {
            return this.module.content.link;
        },
    },
    watch: {
        moduleLink(val) {
            if (val !== 0 && !this.eventSet) {
                this.$modulesEventBus.$on(`photo-slidesshow${this.id}`, this.openPhotoSlidesshow);
            } else if (val == 0) {
                this.eventSet = false;
                this.$modulesEventBus.$off(`photo-slidesshow${this.id}`, this.openPhotoSlidesshow);
            }
        },
    },
};
