var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "text_list", class: _vm.columnWrapClass },
    [
      _vm._l(_vm.newsList, function (info, index) {
        return [
          _c("text-list-item", {
            key: "n_" + info.id,
            attrs: {
              info: info,
              index: index,
              "is-column2": _vm.isColumn2,
              "list-length": _vm.newsList.length,
            },
          }),
          _vm._v(" "),
          _vm.isNeedcSeparatorLine(index)
            ? _c("div", {
                key: "s_" + info.id,
                staticClass: "c2_separatorLine",
              })
            : _vm._e(),
          _vm._v(" "),
          !_vm.isColumn2
            ? _c("div", {
                key: "se_" + info.id,
                staticClass: "g_separator separatorLine",
              })
            : _vm._e(),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }