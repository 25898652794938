<template>
    <input
        type="text"
        class="m_mobi_form_input m_mobi_form_input_text"
        :value="currentValue"
        :placeholder="formItem.placeholder"
        :style="_mixinsInputStyle"
        :maxlength="inputMaxLength"
        @input="handleInput"
    />
</template>
<script>
import Mixins from '../mixins';
import { encodeHtml } from '@/shared/util';
import formDetailEditMixin from '../mixins/formDetailEdit.js';

export default {
    name: 'FormInput',
    mixins: [Mixins, formDetailEditMixin],
    inject: ['module', 'options'],
    props: {
        formItem: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            currentValue: '',
            inputMax: 100,
        };
    },
    computed: {
        wordLimit() {
            try {
                return JSON.parse(this.formItem.wordLimit || '{"o":0,"i":1,"a":100}');
            } catch (e) {
                return { o: 0, i: 1, a: 100 };
            }
        },
        submitVal() {
            return this.currentValue;
        },
        inputMaxLength() {
            if (this.wordLimit.o == 1) {
                return this.inputMax < this.wordLimit.a ? this.inputMax : this.wordLimit.a;
            }
            return this.inputMax;
        },
    },
    mounted() {
        this.initDefaultValue();
    },
    methods: {
        handleInput(event) {
            const value = event.target.value;
            this.currentValue = value;
        },
        resetValue() {
            this.initDefaultValue();
        },
        validate() {
            const inputLength = this.currentValue.trim().length;
            if (this.formItem.must && inputLength === 0) {
                this.$mobiIng(jm.format(LS.siteFormSubmitInputIsEmpty, encodeHtml(this.formItem.name)));
                return false;
            } else if (inputLength > this.inputMax) {
                this.$mobiIng(jm.format(LS.siteFormSubmitInputMaxLength, this.formItem.name, this.inputMax));
                return false;
            } else if (!this.must && inputLength === 0) {
                // 不做处理， 非必须，且没有填的话就不用长度限制校验了
                return true;
            } else if (this.wordLimit.o == 1 && (inputLength < this.wordLimit.i || inputLength > this.wordLimit.a)) {
                if (inputLength < this.wordLimit.i) {
                    this.$mobiIng('请输入至少' + this.wordLimit.i + '个字');
                } else if (inputLength > this.wordLimit.a) {
                    this.$mobiIng('请输入最多' + this.wordLimit.a + '个字');
                }
                return false;
            }

            return true;
        },
        initDefaultValue() {
            const initialValue = this.inMemberDetailEdit ? this.getEditingItemSubmitValue() : this.formItem.dtsl;
            if (typeof initialValue === 'string') {
                this.currentValue = initialValue;
            }
        },
    },
};
</script>

<style></style>
