var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: ["first_panel", "groupList" + _vm.moduleId],
      attrs: { id: "groupList" + _vm.moduleId },
    },
    [
      _c("GroupBanner", {
        attrs: { "nav-index": _vm.navIndex, "no-lazy-load": _vm.noLazyLoad },
      }),
      _vm._v(" "),
      _vm._l(_vm.groupMap, function (secondGroupItem, index) {
        return _c(
          "div",
          {
            key: index,
            staticClass: "second_group_panel J_secondGroupPanel",
            class: _vm.panelClasses(index, secondGroupItem),
            attrs: { id: "sed_" + secondGroupItem.key },
          },
          _vm._l(
            _vm.filterMaxProductGroup(secondGroupItem.data),
            function (childItem, childIndex) {
              return _c(
                "div",
                {
                  key: childIndex,
                  staticClass: "second_groupitem",
                  class: { first_item: childIndex == 0 },
                },
                [
                  _c(
                    "a",
                    { attrs: { href: _vm.prUrl + "?groupId=" + childItem.id } },
                    [
                      _c("div", {
                        staticClass: "J_sedGroupName sed_groupname",
                        style: _vm.sedGroupNameStyle,
                        domProps: { textContent: _vm._s(childItem.name) },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "group_img_wrap clearFix" },
                    _vm._l(
                      childItem.thirdGroupList,
                      function (thirdItem, thirdIndex) {
                        return _c(
                          "div",
                          {
                            key: thirdIndex,
                            staticClass: "J_groupItem f-groupImgContainer",
                            class: _vm.secondGroupClass,
                            attrs: { "data-id": thirdItem.id },
                          },
                          [
                            _vm.moduleStyle == 5 || _vm.moduleStyle == 6
                              ? _c(
                                  "a",
                                  {
                                    staticClass:
                                      "J_imgContainer f-imgContainer",
                                    attrs: {
                                      id: "imgContainer" + thirdItem.id,
                                      href:
                                        _vm.prUrl + "?groupId=" + thirdItem.id,
                                      target: "_self",
                                    },
                                    on: {
                                      mouseover: function ($event) {
                                        return _vm.grouItemMouseOver(
                                          $event,
                                          thirdItem
                                        )
                                      },
                                      mouseout: function ($event) {
                                        return _vm.groupItemMouseOut($event)
                                      },
                                    },
                                  },
                                  [
                                    thirdItem.mIcon != "" &&
                                    _vm.isFontIconId(thirdItem.mIcon) &&
                                    thirdItem.other &&
                                    thirdItem.other.mIconColor != ""
                                      ? _c("span", {
                                          staticClass:
                                            "J_itemImage isFontIcon f-itemImg",
                                          class: [thirdItem.iconPath],
                                          style: _vm.fontIconStyle(thirdItem),
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    thirdItem.mIcon != "" &&
                                    _vm.isFontIconId(thirdItem.mIcon) &&
                                    thirdItem.other &&
                                    thirdItem.other.mIconColor == ""
                                      ? _c("span", {
                                          staticClass:
                                            "J_itemImage isFontIcon f-itemImg",
                                          class: [thirdItem.iconPath],
                                          style: _vm.fontIconStyle(thirdItem),
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    thirdItem.mIcon != "" &&
                                    !_vm.isFontIconId(thirdItem.mIcon) &&
                                    thirdItem.fileInfo == null
                                      ? _c("span", {
                                          staticClass:
                                            "J_itemImage notImage faisco-icons-pic",
                                          style: _vm.fontIconStyle(thirdItem),
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    thirdItem.mIcon != "" &&
                                    !_vm.isFontIconId(thirdItem.mIcon) &&
                                    thirdItem.fileInfo != null
                                      ? _c("div", {
                                          staticClass:
                                            "J_itemImage f-itemImg J_img_lazyload",
                                          style: _vm.imgStyle(thirdItem),
                                          attrs: {
                                            "src-original": thirdItem.iconPath,
                                            "data-w": thirdItem.fileInfo.width,
                                            "data-h": thirdItem.fileInfo.height,
                                          },
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    thirdItem.mIcon == ""
                                      ? _c("div", {
                                          staticClass:
                                            "J_itemImage notImage f-itemImg J_img_lazyload",
                                          style: _vm.defautImgStyle,
                                          attrs: {
                                            "src-original": _vm.defaultImg,
                                            "data-w": "326",
                                            "data-h": "326",
                                          },
                                        })
                                      : _vm._e(),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: _vm.prUrl + "?groupId=" + thirdItem.id,
                                  target: "_self",
                                },
                              },
                              [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "f-itemImgName f-thirdGroupName",
                                    style: _vm.thirdGroupNameStyle,
                                  },
                                  [_vm._v(_vm._s(thirdItem.name))]
                                ),
                              ]
                            ),
                          ]
                        )
                      }
                    ),
                    0
                  ),
                ]
              )
            }
          ),
          0
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }