var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("module-frame", { attrs: { "module-id": _vm.moduleId } }, [
    _vm.render
      ? _c(
          "div",
          {
            staticClass: "J_foldModule fk-formFold",
            class: "fk-foldStyle" + _vm.foldStyle,
          },
          [
            _c("div", { staticClass: "f-formFoldModuleContent" }, [
              _c(
                "div",
                {
                  staticClass: "J_formFoldContentList f-formFoldContentList",
                  attrs: {
                    id: "formFoldContentList" + _vm.id,
                    _display: _vm.displayMethod,
                    styleId: _vm.foldStyle,
                  },
                },
                [
                  _vm._l(_vm.labelList, function (item, index) {
                    return [
                      _c(
                        "div",
                        {
                          key: "foldLabelItem_" + item.id + "_" + index,
                          staticClass:
                            "J_formFoldItem f-formFoldItem g_label_title",
                          class: "foldLabelItem" + item.id,
                          style: _vm.label_title_style,
                          attrs: {
                            id: "formFoldItem" + item.id,
                            defaultopen: item.do,
                            pos: index,
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "J_formFoldButton f-formFoldButton",
                              class: _vm.genFormFoldButtonClass(item.id),
                              attrs: { id: "formFoldButton" + item.id },
                              on: {
                                click: function ($event) {
                                  return _vm.toggleClickHandle(
                                    item.id,
                                    index,
                                    $event
                                  )
                                },
                              },
                            },
                            [
                              _vm.buttonTextRender
                                ? _c("div", {
                                    staticClass: "f-formFoldButtonText",
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.itemLabelName(
                                          item.labelName,
                                          item.id
                                        )
                                      ),
                                    },
                                  })
                                : _c("div", {
                                    staticClass: "f-formFoldButtonText",
                                    domProps: {
                                      innerHTML: _vm._s(item.labelName),
                                    },
                                  }),
                              _vm._v(" "),
                              _c("span", { staticClass: "icon-fold" }),
                            ]
                          ),
                          _vm._v(" "),
                          item.id < 0
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "J_formFoldContent f-formFoldContent",
                                  attrs: { id: "formFoldContent" + item.id },
                                },
                                [
                                  _vm.manageMode
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "emptyLabel empty_tips_panel",
                                        },
                                        [
                                          _c("div", { staticClass: "text" }, [
                                            _vm._v("请将模块拖入标签区域"),
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "jz_button jz_button__active addBtn_Icon",
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  return _vm.addModuleToFold(
                                                    item.id
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("添加模块")]
                                          ),
                                        ]
                                      )
                                    : _c(
                                        "div",
                                        {
                                          staticClass:
                                            "emptyLabel cus_Empty_tips_panel",
                                        },
                                        [
                                          _c("div", { staticClass: "text" }, [
                                            _vm._v("没有添加内容"),
                                          ]),
                                        ]
                                      ),
                                ]
                              )
                            : [
                                _c("fold-child-module", {
                                  key: "fold_" + _vm.moduleId + "_" + item.id,
                                  attrs: {
                                    id: item.id,
                                    "style-id": item.style,
                                    "item-data":
                                      _vm.labelCache["module" + item.id],
                                    "label-data": item,
                                    "manage-mode": _vm.manageMode,
                                  },
                                }),
                              ],
                        ],
                        2
                      ),
                    ]
                  }),
                ],
                2
              ),
            ]),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }