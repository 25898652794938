<template>
    <div
        ref="groupBanner"
        class="group_banner J_img_lazyload"
        :style="groupBannerStyle"
        :src-original="curGroupBanner.p"
        @click="groupBannerClick"
        @mouseover="groupBannerMouseOver"
        @mouseout="groupBannerMouseOut"
    >
    </div>
</template>

<script>
import { productGroupBannerUpload } from '../product-group';
import { changeHref } from '@/features/router/universal';
import { setUrlArgToCookieAndJump } from '@/modules/comm';
export default {
    name: 'GroupBanner',
    inject: ['module', 'options'],
    props: {
        navIndex: {
            type: Number,
            default: 0,
        },
        noLazyLoad: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            upDateBanner: false,
        };
    },
    computed: {
        curGroupBanner: function () {
            // let hook = false;
            // if (VITE_APP_MODE !== 'visitor') { hook = this.module.adPicHook; }
            return this.module.content.ap[this.navIndex] || {};
        },
        curIsDefaultBanner: function () {
            return !this.curGroupBanner || !this.curGroupBanner.pi;
        },
        groupBannerStyle: function () {
            var style = {},
                adPicInfo = this.curGroupBanner;

            if (this.module.content.apt == 0 || !adPicInfo.s) {
                style.display = 'none';
            } else {
                if (!this.curIsDefaultBanner) {
                    style.backgroundImage = `url(${
                        this.noLazyLoad || this.upDateBanner ? adPicInfo.p : this.options.loadingPath
                    })`;
                }
                switch (this.module.content.apst) {
                    case 1:
                        style.backgroundSize = 'contain';
                        break;
                    case 2:
                        style.backgroundSize = '100% 100%';
                        break;
                    case 3:
                        style.backgroundSize = 'auto';
                        break;
                }
            }
            return style;
        },
    },
    watch: {
        'curGroupBanner.p'() {
            this.upDateBanner = true;
        },
    },
    methods: {
        groupBannerClick: function () {
            if (this.curGroupBanner.i) {
                if (VITE_APP_MODE !== 'visitor' && this.$designer.checkSaveBar()) {
                    return;
                } else if (VITE_APP_MODE !== 'visitor' && !!this.curGroupBanner.ji && this.curGroupBanner.ji.t == 103) {
                    Fai.ing(`管理态不支持跳转外部链接，请通过“预览”进行访问`, true);
                } else {
                    if (this.curGroupBanner.ji && this.curGroupBanner.ji.reqArgs) {
                        setUrlArgToCookieAndJump(this.curGroupBanner.h, this.curGroupBanner.ji.reqArgs);
                    } else {
                        changeHref(this.curGroupBanner.h);
                    }
                }
            }
        },
        groupBannerMouseOver: function () {
            if (!(VITE_APP_MODE !== 'visitor')) return;
            const itemJson = [
                {
                    operationText: '编辑产品',
                    className: 'productListEdit',
                    onClick: () => {
                        productGroupBannerUpload(this.module.id, this.navIndex);
                    },
                },
            ];
            const options = { extCls: 'productListEditLayer' };
            Vue.prototype.$layer.addEditLayer($(this.$refs.groupBanner), itemJson, 6, options);
        },
        groupBannerMouseOut: function () {
            if (!(VITE_APP_MODE !== 'visitor')) return;
            Vue.prototype.$layer.removeEditLayer($(this.$refs.groupBanner));
        },
    },
};
</script>
