var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "outsideClick",
          rawName: "v-outsideClick",
          value: _vm.handleClose,
          expression: "handleClose",
        },
      ],
      staticClass: "variable_input",
      style: _vm.inputBoxStyle,
    },
    [
      _c("input", {
        ref: "variableInput",
        staticClass: "jz_focus",
        style: _vm.inputStyle,
        attrs: {
          type: "text",
          maxlength: _vm.maxlength,
          placeHolder: _vm.placeHolder,
        },
        domProps: { value: _vm.value },
        on: { input: _vm.inputItemText },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }