var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: [_vm.wrapClasses, _vm.bigItemClasses],
      attrs: {
        id: "newsLine" + _vm.info.id,
        topClassName: _vm._topClassName,
        topSwitch: _vm._topSwitch,
        newsId: _vm.info.id,
        newsName: _vm.info.title,
        picnum: 8,
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "lineBody",
          class: _vm.isOnlyTitle,
          attrs: { id: "lineBody" + _vm.info.id },
        },
        [
          _c(
            "a",
            {
              attrs: {
                hidefocus: "true",
                href: _vm._newsUrl,
                target: _vm._jumpTarget,
                onclick: _vm.onclickStr,
              },
            },
            [
              _c(
                "div",
                { staticClass: "threePicItem", class: _vm.noDes },
                [
                  _c("div", [
                    _c(
                      "div",
                      {
                        staticClass: "g_news_title newTitle",
                        class: _vm.titleLine,
                        style: !_vm.isMixHead && _vm.news_title_style,
                      },
                      [
                        _c("div", { class: _vm._topFlagClass }),
                        _vm._v(" "),
                        _vm._topFlagClass != "" && _vm.isNewAddModule
                          ? _c("top-component")
                          : _vm._e(),
                        _vm._v(
                          _vm._s(_vm.info.title) + "\n                    "
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "threePicWrap" }, [
                      _c("div", {
                        directives: [
                          {
                            name: "drag-resize",
                            rawName: "v-drag-resize",
                            value: _vm._resizeOptions,
                            expression: "_resizeOptions",
                          },
                        ],
                        staticClass:
                          "threePic firstPic picScaleClass J_img_lazyload",
                        class: [_vm.bigPicClasses, _vm.picRadiusClass],
                        style: [_vm.picStyle, _vm.picIndexPic_01],
                        attrs: {
                          "src-original": _vm.info.realPicId
                            ? _vm.info.bigPicPath
                            : "",
                          alt: _vm.altName,
                        },
                      }),
                      _vm._v(" "),
                      _c("div", {
                        directives: [
                          {
                            name: "drag-resize",
                            rawName: "v-drag-resize",
                            value: _vm._resizeOptions,
                            expression: "_resizeOptions",
                          },
                        ],
                        staticClass:
                          "threePic secondPic picScaleClass J_img_lazyload",
                        class: [_vm.nlPicturePathTwo, _vm.picRadiusClass],
                        style: [_vm.picStyle, _vm.picIndexPic_02],
                        attrs: {
                          "src-original": _vm.info.nlPicturePathTwo,
                          alt: _vm.altName,
                        },
                      }),
                      _vm._v(" "),
                      _c("div", {
                        directives: [
                          {
                            name: "drag-resize",
                            rawName: "v-drag-resize",
                            value: _vm._resizeOptions,
                            expression: "_resizeOptions",
                          },
                        ],
                        staticClass:
                          "threePic thirdPic picScaleClass J_img_lazyload",
                        class: [_vm.nlPicturePathThree, _vm.picRadiusClass],
                        style: [_vm.picStyle, _vm.picIndexPic_03],
                        attrs: {
                          "src-original": _vm.info.nlPicturePathThree,
                          alt: _vm.altName,
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    !_vm.module.content.des
                      ? _c("div", { staticClass: "felxTextItem" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "twoLine articleSummray g_text description g_news_abstract newDescriptionLine",
                              class: [
                                _vm.descriptionLine,
                                _vm.isOnlyTitleAndDes,
                              ],
                              style: _vm.news_abstract_style,
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.info.summary) +
                                  "\n                        "
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c("new-info", {
                    attrs: { info: _vm.info, index: _vm.index },
                  }),
                ],
                1
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }