var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("input", {
    staticClass: "m_mobi_form_input m_mobi_form_input_text",
    style: _vm._mixinsInputStyle,
    attrs: {
      type: "text",
      placeholder: _vm.formItem.placeholder,
      maxlength: _vm.inputMaxLength,
    },
    domProps: { value: _vm.currentValue },
    on: { input: _vm.handleInput },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }