import { MobiModule } from '../Module';
import { MODULE_STYLE } from '@/def/module';
import CountDownView from './module/countDown.vue';

class _CountDown extends MobiModule {
    constructor(options = {}) {
        super(MODULE_STYLE.COUNT_DOWN, options);
    }

    render() {
        return CountDownView;
    }
}

export const CountDown = new _CountDown();
