var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("module-frame", { attrs: { "module-id": _vm.moduleId } }, [
    _c(
      "div",
      {
        class: "adsPicture styleForm40 adsPicture" + _vm.module.id,
        attrs: { id: "adsPicture" + _vm.module.id },
        on: { dblclick: _vm.openPattern },
      },
      [
        _c(
          "a",
          {
            class: { adsPictureCusor: _vm.pointer },
            attrs: { href: _vm.href, rel: _vm.nf, onclick: _vm.jumpOnclickStr },
          },
          [
            _vm.renderImg
              ? _c("img", {
                  staticClass: "J_img_lazyload",
                  style: _vm.imgStyle,
                  attrs: {
                    "src-original": _vm.imgSrc,
                    src: _vm.proxySrc,
                    alt: _vm.imgAlt,
                  },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                      return _vm.adsJump.apply(null, arguments)
                    },
                  },
                })
              : _vm._e(),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }