var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "mobi_form_checkbox choice_box_wrap" }, [
    _c(
      "label",
      {
        staticClass: "m_checkbox_wrap choice_box",
        class: {
          m_checkbox_checked: _vm.value,
        },
      },
      [
        _c("span", { staticClass: "m_checkbox" }, [
          _c("span", {
            staticClass: "m_form_checkbox_inner m_checkbox_inner icon-",
            class: { active: _vm.value },
          }),
          _vm._v(" "),
          _c("input", {
            staticClass: "m_checkbox_input",
            attrs: { type: "checkbox" },
            domProps: { checked: _vm.value, value: _vm.value },
            on: { change: _vm.change },
          }),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }