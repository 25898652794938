<template>
    <div class="three_pic_mix_text_list" :class="isOnlyTitle">
        <three-pic-text-item v-if="!!headPicItemInfo" :info="headPicItemInfo" :no-lazy-load="noLazyLoad" />
        <div class="g_separator separatorLine"></div>
        <text-list :news-list="newsList2"></text-list>
    </div>
</template>

<script>
import ListItem from '../../mixins/ListItem';
import TextList from '../textList/text-list.vue';
import threePicTextItem from '../threePicList/three-pic-text-item.vue';

export default {
    name: 'ThreePicMixTextList',
    components: { threePicTextItem, TextList },
    mixins: [ListItem],
    inject: ['module', 'options'],
    props: ['newsList', 'noLazyLoad'],
    computed: {
        headPicItemInfo() {
            return !!this.newsList && this.newsList[0];
        },
        newsList2() {
            return this.newsList ? this.newsList.slice(1, this.newsList.length) : [];
        },
    },
};
</script>

<style></style>
