import { ing } from '@/shared/tips';
import { bindCommImgShow } from '@/modules/shared/product/swipe';

import { encodeHtml } from '@/shared/util';
import { JZUpload } from '@jz/biz-shared';

export function initPbCommUploadImgParam(upImgMaxSize, imgType, siteMainDomain) {
    mobiPbCommUploadImgHtml('comm-img-swfu-placeholder', upImgMaxSize, imgType, siteMainDomain);
}

function mobiPbCommUploadImgHtml(placeHolderId, fileSize, fileTypeString) {
    var fileTypeList = fileTypeString.split(',');

    JZUpload.initUploadifyButton({
        dom: `#${placeHolderId}`,
        buttonText: ' ',
        fileTypeExts: fileTypeList.join(';'),
        onChange: async (e) => {
            let file = e.target.files[0];

            let jzUpload = new JZUpload({
                getAccessKeyUrl: '/api/manage/advanceUpload/genAccessKey',
                onError(error) {
                    ing(LS.mobiFormSubmitFileUploadFile + file.name + '   ' + error.message);
                    jm(`#${placeHolderId}`).find('.selectbtn').val('');
                },
                // 取决于后台
                onComplete(data) {
                    setTimeout(function () {
                        ing(jm.format(LS.mobiFormSubmitFileUploadSucess, encodeHtml(file.name)), true);
                    }, 1800);
                    onPbCommFileUploadEvent('upload', data);
                    jm(`#${placeHolderId}`).find('.selectbtn').val('');
                },
                onNext(info) {
                    const percent = Math.ceil(info.total.percent);
                    if (isNaN(percent)) {
                        return;
                    }
                    if (percent == 100) {
                        ing(LS.mobiFormSubmitFileUploadIng + percent + '%', true);
                    } else {
                        ing(LS.mobiFormSubmitFileUploadIng + percent + '%', false);
                    }
                },
                // faiupload的params
                params: {
                    aid: Fai.top._aid,
                    folderId: Fai.top._siteId,
                    bssInfo: {
                        siteId: Fai.top._siteId || 0,
                        fileSizeLimit: fileSize,
                        uploadType: 'productComment',
                    },
                    accessKeyInfo: {
                        isTmp: true,
                    },
                },
                // 必填，用于内部业务提示
                tipsFn(string) {
                    ing(string);
                },
                fileTypeExts: fileTypeList.join(';'),
                uploadConfig: {
                    forceDirect: file.type.indexOf('image/') >= 0,
                },
            });
            jzUpload.upload(file);
        },
    });

    // 把插件的ID 给修改,防止冲突
    var tempId = 'file_upload_pt_1';
    var tempSelectId = 'select_btn_pt_1';
    jm('#file_upload_1-button').attr('id', tempId);
    jm('#select_btn_1').attr('id', tempSelectId);

    if (VITE_APP_MODE !== 'visitor') {
        jQuery('#select_btn_pt_1').attr('disabled', 'true');
        jQuery('#file_upload_pt_1')
            .attr('disabled', 'true')
            .off('click')
            .on('click', function () {
                ing('管理状态下，不能上传图片。');
                return false;
            });
    }
}

function onPbCommFileUploadEvent(evt, args) {
    if (evt == 'upload') {
        var tmp_file_id = args.id; //返回临时文件ID
        var file_name = args.name; //返回文件名
        var file_size = args.size; //返回文件大小
        var file_path = args.path; //返回文件的临时目录
        var fileId = args.fileId; //返回文件fileId
        var file_type = args.type || 0;
        var file_width = args.width || 0;
        var file_height = args.height || 0;
        pbCommImgTableCtrl(file_path, file_name, tmp_file_id, file_size, fileId, file_type, file_width, file_height);
    }
}

function pbCommImgTableCtrl(path, name, imgId, file_size, fileId, file_type, file_width, file_height) {
    var tr = jm('#pt_add_img_tb').eq(0);
    var tdLength = tr.find('.comm-find-tb').length;
    var lastTd = tr.find('.comm-find-tb').eq(tdLength - 1);
    var upMaxNum = lastTd.attr('maxNum');
    if (parseInt(tdLength - 1) >= upMaxNum) {
        return;
    }
    var tdContent = [];
    tdContent.push("<table style='width:100%; height:100%; table-layout:fixed;'  cellpadding='0'  cellspacing='0'>");
    tdContent.push(' <tr>');
    tdContent.push("    <td valign='middle'   align='center' class='comm-show-td-bd'>");
    tdContent.push(
        "      <img src='" +
            path +
            "' alt='' onclick='Mobi.pbRemoveBodyStyle()'  _name='" +
            name +
            "' _id='" +
            imgId +
            "' _file_size='" +
            file_size +
            "' _file_id='" +
            fileId +
            "'  _fileWidth='" +
            file_width +
            "'  _fileHeight='" +
            file_height +
            "'  _fileType='" +
            file_type +
            "' class='comm-up-set'>"
    );
    tdContent.push('     </td>');
    tdContent.push(' </tr>');
    tdContent.push('</table>');
    tdContent.push("<div class='comm-up-div'>");
    tdContent.push("  <span onclick=Mobi.pbCommTableImgDelete(this) class='comm-up-div-set'/>");
    tdContent.push('</div>');

    var tmptd = jm(document.createElement('td'));
    tmptd.addClass('comm-find-tb comm-find-tb-remove');
    tmptd.append(jm(tdContent.join('')));
    tmptd.insertBefore(lastTd);
    tdLength = tr.find('.comm-find-tb').length;
    if (parseInt(tdLength - 1) >= upMaxNum) {
        lastTd.css('display', 'none');
    }
    showPbSingalInstCommImg();
}

function showPbSingalInstCommImg() {
    var showSingalInsTrImgs = jm('.J_productCommImg').eq(0)[0].querySelectorAll('img');
    if (typeof showSingalInsTrImgs != 'undefined' && showSingalInsTrImgs != null && showSingalInsTrImgs.length > 0) {
        bindCommImgShow(showSingalInsTrImgs, true, 0);
    }
}

export function pbCommTableImgDelete(arg) {
    var tr = jm('#pt_add_img_tb').eq(0);
    var tdLength = tr.find('.comm-find-tb').length;
    for (var i = 0; i < tdLength; i++) {
        if (tr.find('.comm-find-tb').eq(i).find('.comm-up-div-set')[0] === arg) {
            tr.find('.comm-find-tb').eq(i).remove();
            break;
        }
    }
    tdLength = tr.find('.comm-find-tb').length;
    var lastTd = tr.find('.comm-find-tb').eq(tdLength - 1);
    if (tdLength <= lastTd.attr('maxNum')) {
        lastTd.css('display', 'block');
    }
    showPbSingalInstCommImg();
}
