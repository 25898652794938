var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: _vm.bigItemClasses,
      attrs: {
        id: "newsLine" + _vm.info.id,
        topClassName: _vm._topClassName,
        topSwitch: _vm._topSwitch,
        newsId: _vm.info.id,
        newsName: _vm.info.title,
        picnum: 4,
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "lineBody",
          class: _vm.isOnlyTitle,
          attrs: { id: "lineBody" + _vm.info.id },
        },
        [
          _c("div", { staticClass: "mixNewsStyleImgBox3" }, [
            _c(
              "a",
              {
                directives: [
                  {
                    name: "drag-resize",
                    rawName: "v-drag-resize",
                    value: _vm._resizeOptions,
                    expression: "_resizeOptions",
                  },
                ],
                staticClass: "mixNewsStyleImgBoxWrap",
                attrs: {
                  hidefocus: "true",
                  href: _vm._newsUrl,
                  target: _vm._jumpTarget,
                  onclick: _vm.onclickStr,
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "headPicTopWrap" },
                  [
                    _c("div", {
                      staticClass: "picScaleClass J_img_lazyload",
                      class: _vm.bigPicClasses,
                      style: _vm.bigPicStyle,
                      attrs: {
                        id: "newsTitlePic",
                        alt: _vm.altName,
                        "src-original": _vm.info.realPicId
                          ? _vm.info.bigPicPath
                          : "",
                      },
                    }),
                    _vm._v(" "),
                    _vm._topFlagClass != "" && _vm.isNewAddModule
                      ? _c("top-component")
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "mixNewsStyleTitleContainer3",
                    class: _vm.isTextInner,
                  },
                  [
                    !_vm.isNewAddModule
                      ? _c("div", { class: _vm._topFlagClass })
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.isNewAddModule
                      ? _c(
                          "div",
                          {
                            staticClass: "news_title",
                            class: [_vm.titleClasses, _vm.titleLine],
                            style: _vm.news_title_style,
                          },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(_vm.info.title) +
                                "\n                    "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isNewAddModule && _vm.module.content.ts === 0
                      ? _c(
                          "div",
                          {
                            staticClass: "news_title",
                            class: [_vm.titleClasses, _vm.titleLine],
                          },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(_vm.info.title) +
                                "\n                    "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                ),
                _vm._v(" "),
                _vm.isNewAddModule && _vm.module.content.ts === 1
                  ? _c(
                      "div",
                      {
                        staticClass: "news_title textOuter textBottomOuter",
                        class: [_vm.titleClasses, _vm.titleLine],
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.info.title) +
                            "\n                "
                        ),
                      ]
                    )
                  : _vm._e(),
              ]
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _vm.module.content.ts == 1
        ? _c("div", { staticClass: "g_separator separatorLine" })
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }