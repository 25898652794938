var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "m_mobi_form_select_wrap" }, [
    _c("i", { staticClass: "drop_down_icon faisco-icons-S000112" }),
    _vm._v(" "),
    _c(
      "select",
      {
        staticClass:
          "m_mobi_form_select m_mobi_form_input_text J_mobi_form_select",
        style: _vm._mixinsInputStyle,
        on: { change: _vm.change },
      },
      [
        _c(
          "option",
          {
            staticStyle: { display: "none" },
            attrs: { value: "noneValue" },
            domProps: { selected: _vm.currentValue === "" },
          },
          [_vm._v(_vm._s(_vm.formItem.placeholder || _vm.pleaseSelectTips))]
        ),
        _vm._v(" "),
        _vm._l(_vm.selectGroup, function (name) {
          return _c(
            "option",
            {
              key: name,
              attrs: { disabled: _vm.isZeroResidue(name) },
              domProps: { selected: _vm.currentValue === name, value: name },
            },
            [_vm._v("\n            " + _vm._s(name + _vm.getResidueText(name)))]
          )
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }