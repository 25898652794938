export default {
    methods: {
        _formatDistance(distance) {
            let formatStr = '';
            let distanceNum = parseFloat(distance);
            if (distanceNum === -1) {
                formatStr = '当前位置无法识别';
            } else if (distanceNum >= 0 && distanceNum < 1000) {
                formatStr = distanceNum.toFixed(0) + 'm';
            } else if (distanceNum >= 1000) {
                formatStr = (distanceNum / 1000).toFixed(1) + 'km';
            }
            return formatStr;
        },
        _fixOverflowClipText(value) {
            return `${value}  `;
        },
    },
};
