<template>
    <product-list :module-id="moduleId" />
</template>

<script>
import ProductList from './productList.vue';

export default {
    name: 'ProductListVistorView',
    components: {
        ProductList,
    },
    props: {
        moduleId: {
            type: Number,
            default: -1,
        },
    },
};
</script>

<style>
@media only screen and (min-width: 480px) {
    select {
        background-position: 98% 50%;
    }

    .defaultBanner,
    .swipe .systemtBanner {
        background-size: 100%;
        background-repeat: no-repeat;
    }
}
</style>
