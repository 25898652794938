<template>
    <module-frame :module-id="moduleId">
        <div class="count_down">
            <div v-if="content.moduleStyleType !== 3" :style="countDownNormalStyle">
                <div v-if="content.isShowText" :style="countDownTextStyle" v-html="curText"></div>
                <div class="count_down_time_item_wrap">
                    <time-item
                        v-if="isShowDay"
                        ref="timeItem"
                        :is-unit-downstyle="isUnitDownstyle"
                        :open-number-bg="openNumberBg"
                        :number-bg-color="numberBgColor"
                        :number-style="numberStyle"
                        :unit-style="unitStyle"
                        :unit-text="extInfo.dayText"
                        :number-text="day"
                    >
                    </time-item>
                    <time-item
                        v-if="isShowHour"
                        :is-unit-downstyle="isUnitDownstyle"
                        :open-number-bg="openNumberBg"
                        :number-bg-color="numberBgColor"
                        :number-style="numberStyle"
                        :unit-style="unitStyle"
                        :unit-text="extInfo.hourText"
                        :number-text="hour"
                    >
                    </time-item>
                    <time-item
                        v-if="isShowMintues"
                        :is-unit-downstyle="isUnitDownstyle"
                        :open-number-bg="openNumberBg"
                        :number-bg-color="numberBgColor"
                        :number-style="numberStyle"
                        :unit-style="unitStyle"
                        :unit-text="extInfo.minuteText"
                        :number-text="minute"
                    >
                    </time-item>
                    <time-item
                        v-if="isShowSecond"
                        :is-unit-downstyle="isUnitDownstyle"
                        :open-number-bg="openNumberBg"
                        :number-bg-color="numberBgColor"
                        :number-style="numberStyle"
                        :unit-style="unitStyle"
                        :unit-text="extInfo.secondText"
                        :number-text="second"
                    >
                    </time-item>
                </div>
            </div>
            <div v-if="content.moduleStyleType === 3" class="ring_count_down">
                <div
                    v-if="content.isShowText"
                    class="ring_count_down_text"
                    :style="curTextStyle"
                    v-html="curText"
                ></div>
                <div class="count_down_progress_ring_wrap">
                    <progress-ring
                        v-if="isShowDay"
                        :progress="dayProgress"
                        :bottom-ring-stroke-color="circleColor"
                        :up-ring-stroke-color="isFinished ? countDownEndCircleColor : loadingCircleColors[0]"
                        :style="progressStyle"
                        :top-text-style="numberStyle"
                        :bottom-style="unitStyle"
                        :unit-text="extInfo.dayText"
                        :number-text="day"
                    >
                    </progress-ring>
                    <progress-ring
                        v-if="isShowHour"
                        :progress="hourProgress"
                        :bottom-ring-stroke-color="circleColor"
                        :up-ring-stroke-color="isFinished ? countDownEndCircleColor : loadingCircleColors[1]"
                        :style="progressStyle"
                        :top-text-style="numberStyle"
                        :bottom-style="unitStyle"
                        :unit-text="extInfo.hourText"
                        :number-text="hour"
                    >
                    </progress-ring>
                    <progress-ring
                        v-if="isShowMintues"
                        :progress="minuteProgress"
                        :bottom-ring-stroke-color="circleColor"
                        :up-ring-stroke-color="isFinished ? countDownEndCircleColor : loadingCircleColors[2]"
                        :style="progressStyle"
                        :top-text-style="numberStyle"
                        :bottom-style="unitStyle"
                        :unit-text="extInfo.minuteText"
                        :number-text="minute"
                    >
                    </progress-ring>
                    <progress-ring
                        v-if="isShowSecond"
                        :progress="secondProgress"
                        :bottom-ring-stroke-color="circleColor"
                        :up-ring-stroke-color="isFinished ? countDownEndCircleColor : loadingCircleColors[3]"
                        :style="progressStyle"
                        :top-text-style="numberStyle"
                        :bottom-style="unitStyle"
                        :unit-text="extInfo.secondText"
                        :number-text="second"
                    >
                    </progress-ring>
                </div>
            </div>
        </div>
    </module-frame>
</template>

<script>
import { encodeHtml } from '@/shared/util';
import { CountDown } from './service';
import progressRing from './component/progressRing.vue';
import timeItem from './component/timeItem.vue';
import { mapGetters } from 'vuex';
import ModuleFrame from '@/modules/frame/index.vue';
import { getServerTime } from '@/api/serverTime';

const CountDownStatusEnum = {
    BEFORE: 0,
    RUNING: 1,
    FINSHED: 2,
};
Object.freeze(CountDownStatusEnum);

const MixLoadingCircleColorTypeEnum = {
    DEFAULT: 0,
    MATCH_COLOR_ONE: 1,
    MATCH_COLOR_TWO: 2,
    CUSTOM: 3,
};
Object.freeze(MixLoadingCircleColorTypeEnum);
export default {
    name: 'CountDown',
    components: {
        progressRing,
        timeItem,
        ModuleFrame,
    },
    props: {
        moduleId: {
            type: Number,
            default: -1,
        },
    },
    data() {
        return {
            progressStyle: {
                width: '3.25rem',
                hegiht: '3.25rem',
            },
            countDownStatus: 0,
            timeObj: {
                day: 0,
                hour: 0,
                minute: 0,
                second: 0,
                millisecond: 0,
            },
            serverTime: 0,
            isRender: false,
            countDownEndCircleColor: '#f2f2f2',
            beforeCountDown: null,
            runningCountDown: null,
        };
    },
    computed: {
        ...mapGetters(['getModuleById']),
        module() {
            return this.getModuleById(this.moduleId);
        },
        options() {
            return this.module.renderOptions;
        },
        content() {
            return this.module.content;
        },
        isUnitDownstyle() {
            return this.content.timeUnitStyleType === 0;
        },
        openNumberBg() {
            return this.content.moduleStyleType === 2;
        },
        numberBgColor() {
            return this.isFinished ? this.countDownEndCircleColor : this.content.numberBgColor;
        },
        numberStyle() {
            let customStyle = this.transformDataToStyleObj(this.content.numberStyle);
            const defaultStyle = {
                'font-weight': 'bold',
            };
            customStyle = {
                ...defaultStyle,
                ...customStyle,
            };
            return this.isFinished
                ? {
                      ...customStyle,
                      color: '#ccc',
                  }
                : customStyle;
        },
        unitStyle() {
            let customStyle = this.transformDataToStyleObj(this.content.unitStyle);
            const defaultStyle = {
                color: '#999',
            };
            customStyle = {
                ...defaultStyle,
                ...customStyle,
            };
            return this.isFinished
                ? {
                      ...customStyle,
                      color: '#ccc',
                  }
                : customStyle;
        },
        isHorizontalLayout() {
            return this.content.layoutStyleType === 0;
        },
        countDownNormalStyle() {
            if (this.isHorizontalLayout) {
                return {
                    display: 'flex',
                    'justify-content': 'center',
                    'align-items': 'center',
                };
            } else {
                return {
                    display: 'flex',
                    'flex-direction': 'column',
                    'align-items': 'center',
                };
            }
        },
        countDownTextStyle() {
            if (this.isHorizontalLayout) {
                return {
                    'margin-right': '.5rem',
                    ...this.curTextStyle,
                };
            } else {
                return {
                    'margin-bottom': '.35rem',
                    ...this.curTextStyle,
                };
            }
        },
        // eslint-disable-next-line vue/return-in-computed-property
        curText() {
            switch (this.countDownStatus) {
                case CountDownStatusEnum.BEFORE:
                    return encodeHtml(this.content.bsText);
                case CountDownStatusEnum.RUNING:
                    return encodeHtml(this.content.runText);
                case CountDownStatusEnum.FINSHED:
                    return encodeHtml(this.content.finshedText);
            }
        },
        curTextStyle() {
            let customStyle = this.transformDataToStyleObj(this.content.textStyle);
            // 合并默认样式
            customStyle = {
                ...customStyle,
                'word-break': 'break-all',
                'white-space': 'pre-wrap',
            };
            return this.isFinished
                ? {
                      ...customStyle,
                      color: '#ccc',
                  }
                : customStyle;
        },
        isShowDay() {
            return this.content.timePrecisionType >= 0;
        },
        isShowHour() {
            return this.content.timePrecisionType >= 1;
        },
        isShowMintues() {
            return this.content.timePrecisionType >= 2;
        },
        isShowSecond() {
            return this.content.timePrecisionType >= 3;
        },
        circleColor() {
            const IS_DEFAULT = this.content.circleStyleType === 0;
            return this.isFinished || IS_DEFAULT ? this.countDownEndCircleColor : this.content.circleColor;
        },
        isFinished() {
            return this.countDownStatus === CountDownStatusEnum.FINSHED;
        },
        isPureColor() {
            return this.content.colorType === 0;
        },
        loadingCircleColors() {
            if (this.isPureColor) {
                return this.pureLoadingCircleColors;
            } else {
                return this.mixLoadingCircleColors;
            }
        },
        pureLoadingCircleColors() {
            let color = this.content.loadingCircleStyleType === 0 ? this.themeColor : this.content.loadingCircleColor;
            return [color, color, color, color];
        },
        // eslint-disable-next-line vue/return-in-computed-property
        mixLoadingCircleColors() {
            switch (this.content.loadingMixCircleStyleType) {
                case MixLoadingCircleColorTypeEnum.DEFAULT:
                    return this.extInfo.colourMatching0;

                case MixLoadingCircleColorTypeEnum.MATCH_COLOR_ONE:
                    return this.extInfo.colourMatching1;

                case MixLoadingCircleColorTypeEnum.MATCH_COLOR_TWO:
                    return this.extInfo.colourMatching2;

                case MixLoadingCircleColorTypeEnum.CUSTOM:
                    return this.content.loadingMixCircleColor;
            }
        },
        themeColor() {
            if (this.isRender) {
                if (VITE_APP_MODE !== 'visitor') {
                    // eslint-disable-next-line no-undef
                    return this.$store.state.app.themeColor;
                } else {
                    return this.options.themeColor;
                }
            } else {
                return this.options.themeColor;
            }
        },
        day() {
            return this.padStartZero(`${this.timeObj.day}`);
        },
        hour() {
            return this.padStartZero(`${this.timeObj.hour}`);
        },
        minute() {
            return this.padStartZero(`${this.timeObj.minute}`);
        },
        second() {
            return this.padStartZero(`${this.timeObj.second}`);
        },
        startTime() {
            return this.content.sTime;
        },
        endTime() {
            return this.content.fTime;
        },
        dayProgress() {
            const percent = (this.timeObj.day / this.allDay) * 100;
            return 100 - percent;
        },
        hourProgress() {
            const percent = (this.timeObj.hour / 24) * 100;
            return 100 - percent;
        },
        minuteProgress() {
            const percent = (this.timeObj.minute / 60) * 100;
            return 100 - percent;
        },
        secondProgress() {
            const percent = (this.timeObj.second / 60) * 100;
            return 100 - percent;
        },
        setStartTimeToStartTimeAllDay() {
            return CountDown.getTimeObject(`${this.content.stuTime}`).day;
        },
        startTimeToFinshedTimeAllDay() {
            return CountDown.getTimeObject(`${this.content.fTime - this.content.sTime}`).day;
        },
        // eslint-disable-next-line vue/return-in-computed-property
        allDay() {
            switch (this.countDownStatus) {
                case CountDownStatusEnum.BEFORE:
                    return this.setStartTimeToStartTimeAllDay;
                case CountDownStatusEnum.RUNING:
                    return this.startTimeToFinshedTimeAllDay;
                case CountDownStatusEnum.FINSHED:
                    return Infinity;
            }
        },
        extInfo() {
            return this.module.extInfo;
        },
    },
    watch: {
        'content.stuTime'() {
            this.initCountDown();
        },
    },
    mounted() {
        this.isRender = true;
        this.initCountDown();
    },
    methods: {
        transformDataToStyleObj(data) {
            const { t: type } = data;

            const DEFAULT_FONT_SIZE = 0.512;

            const FONT_SIZE = data.fs ? data.fs : DEFAULT_FONT_SIZE;

            let configStyle = {
                'font-size': `${FONT_SIZE}rem`,
                color: data.c,
                'font-style': data.it ? 'italic' : 'normal',
                'font-weight': data.ib ? 'bold' : 'normal',
                'text-decoration': data.iu ? 'underline' : 'none',
                'line-height': data.lh ? `${data.lh}rem` : 'normal',
            };

            const IS_DEFAULT_STYLE = type === 0;

            return IS_DEFAULT_STYLE ? {} : configStyle;
        },
        padStartZero(str, count = 2) {
            str = String(str);
            return str.padStart(count, '0');
        },
        getServerTime() {
            return new Promise((resolve) => {
                if (!(VITE_APP_MODE !== 'visitor')) {
                    getServerTime()
                        .catch(() => {})
                        .then(function (data) {
                            const { timeStamp } = data;
                            resolve({ timeStamp });
                        });
                } else {
                    resolve({ timeStamp: Date.now() });
                }
            });
        },
        initBeforeCountDown() {
            this.countDownStatus = CountDownStatusEnum.BEFORE;
            this.allDay = CountDown.getTimeObject(this.serverTime - this.startTime).day;
            const vm = this;
            const startCountDown = new CountDown({
                endTime: this.startTime,
                oneTick: (ms) => {
                    vm.timeObj = CountDown.getTimeObject(`${ms}`);
                },
                end: () => {
                    vm.beforeCountDown = null;
                    vm.countDownStatus = CountDownStatusEnum.RUNING;
                    vm.allDay = CountDown.getTimeObject(vm.endTime - vm.startTime).day;
                    const runCountDown = new CountDown({
                        nowTime: vm.startTime,
                        endTime: vm.endTime,
                        oneTick: (ms) => {
                            vm.timeObj = CountDown.getTimeObject(`${ms}`);
                        },
                        end: () => {
                            vm.runningCountDown = null;
                            vm.countDownStatus = CountDownStatusEnum.FINSHED;
                        },
                    });
                    vm.runningCountDown = runCountDown;
                    runCountDown.init();
                },
            });
            vm.beforeCountDown = startCountDown;
            startCountDown.init();
        },
        initRunningCountDown() {
            const vm = this;
            this.countDownStatus = CountDownStatusEnum.RUNING;
            this.allDay = CountDown.getTimeObject(this.endTime - this.startTime).day;
            const runCountDown = new CountDown({
                nowTime: vm.serverTime,
                endTime: vm.endTime,
                oneTick: (ms) => {
                    vm.timeObj = CountDown.getTimeObject(`${ms}`);
                },
                end: () => {
                    vm.runningCountDown = null;
                    vm.countDownStatus = CountDownStatusEnum.FINSHED;
                },
            });
            vm.runningCountDown = runCountDown;
            runCountDown.init();
        },
        initFinshedCountDown() {
            this.timeObj = {
                day: 0,
                hour: 0,
                minute: 0,
                second: 0,
                millisecond: 0,
            };
            this.countDownStatus = CountDownStatusEnum.FINSHED;
        },
        initCountDown() {
            this.getServerTime().then(({ timeStamp: serverTime }) => {
                // 只响应最后一次请求，避免连续设置时，网络因素导致最终显示结果异常
                this.serverTime = serverTime;
                if (this.serverTime < this.startTime) {
                    // 先清除现有倒计时，否则会出现两者共存
                    this.stopCountDown();
                    this.initBeforeCountDown();
                } else if (this.serverTime > this.startTime && this.serverTime < this.endTime) {
                    // 先清除现有倒计时，否则会出现两者共存
                    this.stopCountDown();
                    this.initRunningCountDown();
                }
                if (this.serverTime > this.endTime) {
                    this.stopCountDown();
                    this.initFinshedCountDown();
                }
            });
        },
        stopCountDown() {
            this.beforeCountDown && this.beforeCountDown.stopCountDown();
            this.runningCountDown && this.runningCountDown.stopCountDown();
        },
    },
};
</script>

<style scoped>
.count_down {
    padding: 0.75rem 0;
}

.ring_count_down {
    padding: 0 0.75rem;
}
.ring_count_down_text {
    font-size: 0.75rem;
    margin-bottom: 0.7rem;
    text-align: center;
}

.count_down_progress_ring_wrap {
    display: flex;
    align-items: center;
    justify-content: center;
}

.count_down_time_item:last-child .count_down_sign {
    display: none;
}

.progress_ring_wrap:not(:last-child) {
    margin-right: 0.5rem;
}

.count_down_time_item_wrap {
    display: flex;
    justify-content: center;
}
</style>
