var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("module-frame", { attrs: { "module-id": _vm.moduleId } }, [
    _c(
      "div",
      { staticClass: "calculationQuotation", class: _vm.moduleStyle },
      [
        _vm._l(_vm.variableList, function (variable, index) {
          return _c(
            "div",
            {
              staticClass: "variable_item",
              class: _vm.variableCls(variable),
              style: _vm.itemMarginStyle,
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.manageItem(variable, index)
                },
              },
            },
            [
              !_vm.variableHide
                ? _c("div", {
                    staticClass: "variable_name",
                    style: _vm.variableFontStyle,
                    domProps: {
                      innerHTML: _vm._s(_vm.encodeHtml(variable.name)),
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              variable.type == 0
                ? _c(
                    "div",
                    { staticClass: "variable_content" },
                    [
                      _c("variable-input", {
                        attrs: {
                          "place-holder": variable.rt,
                          "input-style": _vm.inputFontStyle,
                          "input-box-style": _vm.inputBoxStyle,
                        },
                        model: {
                          value: variable.value,
                          callback: function ($$v) {
                            _vm.$set(variable, "value", $$v)
                          },
                          expression: "variable.value",
                        },
                      }),
                      _vm._v(" "),
                      variable.uHide == 0
                        ? _c("div", {
                            staticClass: "variable_unit",
                            style: _vm.unitFontStyle,
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.unitText(variable.uType, variable.unit)
                              ),
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              variable.type == 1
                ? _c(
                    "div",
                    { staticClass: "variable_content" },
                    [
                      _c("variable-select", {
                        attrs: {
                          list: variable.ol,
                          "place-holder": variable.rt,
                          "input-style": _vm.inputFontStyle,
                          "input-box-style": _vm.inputBoxStyle,
                          "option-font-style": _vm.optionFontStyle,
                        },
                        model: {
                          value: variable.valueData,
                          callback: function ($$v) {
                            _vm.$set(variable, "valueData", $$v)
                          },
                          expression: "variable.valueData",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          )
        }),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "calculation_submit_wrap",
            attrs: { id: "calculateModuleBtn" + _vm.moduleId },
            on: {
              mousemove: _vm.mousemoveHandler,
              mouseleave: _vm.mouseleaveHandler,
            },
          },
          [
            _c("button", {
              staticClass:
                "calculation_submit g_main_bgColor_v3 g_oldThemeBgColor",
              style: _vm.calcBtnStyle,
              domProps: { innerHTML: _vm._s(_vm.encodeHtml(_vm.btnText)) },
              on: { click: _vm.onSubmit },
            }),
          ]
        ),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }