var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("module-frame", { attrs: { "module-id": _vm.moduleId } }, [
    _c(
      "div",
      { class: _vm.marqueeClass },
      [
        _vm.isEmpty && _vm.manageMode
          ? [
              _c(
                "div",
                { staticClass: "empty_tips_panel" },
                [
                  _c("div", { staticClass: "text" }, [
                    _vm._v("请点击下方按钮或在右侧面板添加内容"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "button-component",
                    {
                      attrs: { active: "" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.addContent.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("添加内容")]
                  ),
                ],
                1
              ),
            ]
          : _vm._e(),
        _vm._v(" "),
        _vm.isEmpty && !_vm.manageMode
          ? [
              _c("div", { staticClass: "cus_Empty_tips_panel" }, [
                _c("div", { staticClass: "text" }, [_vm._v("没有内容")]),
              ]),
            ]
          : _vm._e(),
        _vm._v(" "),
        _vm.showMobiViewer
          ? _c("image-viewer", {
              attrs: {
                "z-index": _vm.zIndex,
                "initial-index": _vm.initialIndex,
                "on-close": _vm.onClose,
                "url-list": _vm.urlList,
                "is-mobi": true,
                "content-terminal": "mobi",
                "manage-mode": _vm.manageMode,
                "log-event-config": _vm.logEventConfig,
                "document-event-el": _vm.documentEventEl,
                "append-to-container-id": "g_body",
                "append-to-body": false,
                "disable-long-press": _vm.banCopyTextImg,
              },
            })
          : _vm._e(),
        _vm._v(" "),
        !_vm.isEmpty
          ? _c("div", { class: _vm.richMarqueeClass }, [
              _c(
                "div",
                {
                  staticClass: "f-contentPanel",
                  class: [
                    "J_richContentPanel" + _vm.moduleId,
                    { richImgMargin: _vm.addBottomStyle },
                  ],
                },
                [
                  _vm.picParam != null &&
                  _vm.picParam != "" &&
                  _vm.richType != 0
                    ? [
                        _c(
                          "div",
                          {
                            class: ["textImg" + _vm.richType],
                            style: _vm.textImgStyle,
                          },
                          [
                            _c(
                              "a",
                              {
                                ref: "richImgLink",
                                style: _vm.textImgHrefStyle,
                                attrs: {
                                  hidefocus: "true",
                                  rel: _vm.nf,
                                  onclick: _vm.imageJumpOnclick,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.photoSlidesshow(0)
                                  },
                                },
                              },
                              [
                                _c("img", {
                                  staticClass: "J_img_lazyload",
                                  style: _vm.richImgStyle,
                                  attrs: {
                                    alt: _vm.picParam.i,
                                    "data-source": _vm.other.picPath,
                                    "src-original": _vm.other.picPath,
                                    src: _vm.noLazyLoad
                                      ? _vm.other.picPath
                                      : _vm.options.loadingPath,
                                    vheight: _vm.picParam.oh,
                                    picWidth: _vm.picParam.ow,
                                    picHeight: _vm.picParam.oh,
                                  },
                                  on: { click: _vm.imgLinkClick },
                                }),
                              ]
                            ),
                          ]
                        ),
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.richContentText != null && _vm.richContentText != ""
                    ? [
                        _c("html-render", {
                          staticClass: "richContent g_rich_content",
                          class: [
                            "richContent" + _vm.richType,
                            "richContent" + _vm.moduleId,
                          ],
                          attrs: { html: _vm.richContentText },
                        }),
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "clear" }),
                ],
                2
              ),
            ])
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }