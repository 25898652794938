var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "item_checkBox_pic", class: _vm.itemCheckBoxPicClass },
    _vm._l(_vm.checkBoxPicSetting.picList, function (pic, index) {
      return _c(
        "div",
        {
          key: pic.id,
          staticClass: "checkBox_pic_item g_main_color_v3 g_oldThemeColor",
          class: { checkBox_pic_item_checked: _vm.isChecked(pic) },
          style: _vm.itemStyle,
          on: {
            click: function ($event) {
              return _vm.selectItem(pic)
            },
          },
        },
        [
          _c("div", {
            staticClass: "checkBox_pic_item_pic",
            style: _vm.getStyle(pic),
            on: _vm._d({}, [
              _vm.previewPicEvent,
              function ($event) {
                $event.stopPropagation()
                return _vm.previewPic(pic, index)
              },
            ]),
          }),
          _vm._v(" "),
          _vm.checkBoxPicSetting.isShowPhotoName
            ? _c(
                "div",
                {
                  staticClass: "checkBox_pic_item_name",
                  style: _vm.itemNameStyle,
                },
                [_vm._v(_vm._s(pic.name))]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isChecked(pic)
            ? _c(
                "svg",
                {
                  staticClass: "checkBox_flag",
                  attrs: {
                    xmlns: "http://www.w3.org/2000/svg",
                    viewBox: "0 0 32 32",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      d: "M4,28H24a4,4,0,0,0,4-4V4Zm14-3a27.36,27.36,0,0,0-4-5l4,2a79.74,79.74,0,0,1,8-6A105.29,105.29,0,0,0,18,25Z",
                      fill: "currentColor",
                    },
                  }),
                ]
              )
            : _vm._e(),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }