<template>
    <span v-if="isOpenBookingBtn && canOpenProduct">
        <span v-if="holder" class="bookingBtnPlaceholder"></span>
        <span
            :id="'proListBookingBtn' + info.id"
            class="g_main_bgColor_v3 g_button proListBookingBtn"
            :_pid="info.id"
        >
            {{ bookingBtnText }}
        </span>
    </span>
    <span v-else-if="isOpenFormBtn && canOpenProduct">
        <span v-if="holder" class="bookingBtnPlaceholder"></span>
        <span
            :id="'productFormBtn' + info.id"
            class="g_main_bgColor_v3 g_button proListBookingBtn btnColorClass"
            :style="btnStyle"
            @click.stop.prevent="openForm"
        >
            {{ productFormBtnText }}
        </span>
    </span>
    <span
        v-else-if="!isNewAddModule && canOpenProduct"
        :id="info.id"
        class="J_fk-mallCart f-mallCart faiscoIconsGwc icon-gwc g_color g_main_color_v3"
        :style="cartStyle"
    >
    </span>
    <span
        v-else-if="isNewAddModule && canOpenProduct"
        :id="info.id"
        class="J_fk-mallCart f-mallCart mallCartSvg g_bgColor g_main_bgColor_v3"
        :style="svgBgStyle"
    >
        <svg
            role="img"
            class="jzm-v43 g_color g_main_color_v3"
            :style="svgFillStyle"
        >
            <use xlink:href="#jzm-v43"></use>
        </svg>
    </span>
</template>

<script>
// 抽离该组件的原因：1、每个样式这个组件输出的位置不同 2、复用
// 输出的条件交给外部控制，组件只控制单纯的样式和交互
import { mapFlag } from '@/shared/flag';

import { bookingPanelClickEvent } from '@/modules/shared/product/product';
import { productFormBtnClick } from '@/modules/shared/product/btn';
const manageMode = VITE_APP_MODE !== 'visitor';
export default {
    name: 'MallCart',
    inject: ['module', 'options'],
    props: {
        info: Object,
        holder: {
            default: false,
        },
    },
    data() {
        return {
            manageMode,
        };
    },
    computed: {
        ...mapFlag({
            bookingBtnCusName: 0x1,
            isNewAddModule: 0x4,
        }),
        bookingBtnText() {
            return this.bookingBtnCusName
                ? this.module.content.bookingBN
                : this.module.extInfo.sysBookingBtnText;
        },
        productFormBtnText() {
            return this.module.content.pf.cft == 1
                ? this.module.content.pf.pft
                : this.module.extInfo.productFormText;
        },
        cartStyle() {
            let type = this.module.content.t;
            let cart = {
                fontSize: '1rem',
                width: 'auto',
                height: 'auto',
                float: 'right',
            };
            if (
                !this.isNewAddModule &&
                !(
                    type == '9' ||
                    type == '8' ||
                    type == '32' ||
                    type == '7' ||
                    this._isThreeCol ||
                    (type == '1' && this.module.content.proStyle == 7)
                )
            ) {
                // 除了上面样式，其他都加 margin
                cart.marginRight = '0.5rem';
            }
            return cart;
        },
        cartType() {
            let cartType = 'mallCart';
            if (this.module.content.bk && this.module.extInfo.bookingOpen) {
                cartType = 'bookCart';
            } else if (
                this.module.content.pf.s &&
                this.module.extInfo.productFormOpen
            ) {
                // 知识付费产品隐藏表单按钮
                cartType = 'productFormBtn';
            }
            return cartType;
        },
        btnStyle() {
            if (!manageMode || !this.isNewAddModule) return;
            var btnStyle = {};
            var btnColorType = this.module.content.btnColor.pbct;
            var btnBgColor = this.module.content.btnColor.pbbc;
            var btnColor = this.module.content.btnColor.pbtc;
            if (btnColorType == 1) {
                btnStyle.background = btnBgColor;
                btnStyle.color = btnColor;
            }
            return btnStyle;
        },
        svgFillStyle() {
            var svgFillStyle = {};
            if (this.module.content.carColor.pcct == 1) {
                var fillColor = this.module.content.carColor.pcic;
                svgFillStyle.fill = fillColor;
            }
            return svgFillStyle;
        },
        svgBgStyle() {
            var svgBgStyle = {};
            if (this.module.content.carColor.pcct == 1) {
                var bgColor = this.module.content.carColor.pcbc;
                svgBgStyle.background = bgColor;
            }
            return svgBgStyle;
        },
        isKnowPayProduct() {
            return this.info.productType == 2;
        },
        isOpenKnowPay() {
            return this.module.extInfo.openKnowPayment || false;
        },
        canOpenProduct() {
            return !(this.isKnowPayProduct && this.isOpenKnowPay);
        },
        isOpenBookingBtn() {
            return this.cartType == 'bookCart';
        },
        isOpenFormBtn() {
            return this.cartType == 'productFormBtn';
        },
    },
    watch: {
        cartType: function (newVal) {
            if (newVal == 'bookCart' && manageMode) {
                this.bindBookingEvent();
            }
        },
    },
    mounted() {
        this.bindBookingEvent();
    },

    methods: {
        bindBookingEvent() {
            if (this.cartType == 'bookCart') {
                bookingPanelClickEvent(`proListBookingBtn${this.info.id}`, 2);
            }
        },
        openForm() {
            let productFormId =
                this.info.pf.productFormWay == 1
                    ? this.info.pf.productFormId
                    : this.module.extInfo.productFormId;
            productFormBtnClick(productFormId, this.info.id);
        },
    },
};
</script>
<style lang="scss">
.isNewAddModule .mProductList .proListBookingBtn {
    border-radius: 0.5rem;
    padding: 0 0.5rem;
    top: 0;
    font-size: 0.5rem;
    height: 1rem;
    line-height: 1rem;
    margin-top: 0;
    margin-bottom: 0;
    min-width: unset;
}
.isNewAddModule .mProductList .mallCartSvg {
    border-radius: 50%;
    width: 0.95rem;
    height: 0.95rem;
}
.isNewAddModule .fk-mProductList3 .textAlign_center .f-mallCart,
.isNewAddModule .fk-mProductList7 .textAlign_center .f-mallCart,
.isNewAddModule .fk-mProductList8 .textAlign_center .f-mallCart,
.isNewAddModule .fk-mProductList32 .textAlign_center .f-mallCart,
.isNewAddModule .mProductList .jz_productScr .textAlign_center .f-mallCart {
    position: static;
}
</style>
