<template>
    <div class="photoModule styleForm6 g_background">
        <div :id="'photoSwipe' + moduleId" class="photoSwipe">
            <div class="photoSwipeBox">
                <div v-for="(item, index) in list" v-show="index == 0" :key="index" class="swipImgArea imageDiv">
                    <a :href="item.href || undefined" @click="imgClick(index)">
                        <span class="imageMiddleSpan"></span>
                        <img
                            :data-picid="item.picId"
                            class="photoImageItem"
                            :src="item.picThumbPath"
                            :vwidth="item.vwidth"
                            :vheight="item.vheight"
                            :alt="item.alt"
                            :photoIndex="index"
                            :linkType="item.pLinkType"
                        />
                    </a>
                </div>
            </div>
            <div
                v-if="showName"
                :id="'photoBullet' + moduleId"
                class="photoBullet"
                style="height: auto !important"
                :style="photoBulletStyle"
            >
                <table class="tableFix">
                    <tbody>
                        <tr>
                            <td>
                                <div :id="'imgName' + moduleId" class="imgName"></div>
                            </td>
                            <td class="tdUl">
                                <ul :id="'bullets' + moduleId" class="bullets">
                                    <li v-for="(item, index) in list" :key="index" :class="index == 0 ? 'on' : ''"></li>
                                </ul>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import { photoListMixins } from '../mixins';
export default {
    name: 'Type1',
    mixins: [photoListMixins],
    inject: ['moduleId', 'manageMode'],
    props: {
        list: {
            type: Array,
            default() {
                return [];
            },
        },
    },
    computed: {
        photoDivClass() {
            return this.showName ? 'photoBg' : '';
        },
        showName() {
            return this.content.showName == 0 ? true : false;
        },
        nameWordWrap() {
            return this.content.newNameWrap == 0 ? true : false;
        },
        scale() {
            return this.content.picScale;
        },
        imgClass() {
            return this.scale == 0 ? '' : 'stretchImg';
        },
        groupId() {
            return this.content.groupId;
        },
        photoBulletStyle() {
            if (this.nameWordWrap) {
                return {
                    wordWrap: 'break-word',
                };
            } else {
                return {
                    whiteSpace: 'nowrap',
                };
            }
        },
        rect() {
            return this.module.extInfo.rect;
        },
    },
    methods: {
        imgClick(index) {
            this.$emit('imgClick', index);
        },
    },
};
</script>
