import { ing } from '@/shared/tips';
import { debounce } from '@/shared/util';
import { getChildModuleData } from '@/api/module/tabContainer';
import { panelDisableArea } from '@/modules/shared/panelDisableArea';
import { addModuleAnmaition, resetModuleAddState } from '@/modules/comm';
import { globalSortDataApi } from '@/features/sortable/sortData';
import { moveSpecialModuleInNewPack } from '@/manage/utils/pack';

export let inTabModuleEdit = {};

inTabModuleEdit.inTabModuleSortable = function (module) {
    var tabSortable = module.find('.J_tabSortContainer');
    tabSortable.sortable('destroy');
    tabSortable.sortable({
        distance: 3,
        connectWith: '#webModuleContainer',
        cursorAt: { top: 0, left: 0 },
        create() {},
        beforeStart() {},
        start() {},
        over() {
            Vue.prototype.$layer.removeAllEditLayer();
            var nowParent = $(this),
                nowParentId = nowParent.attr('id');
            globalSortDataApi.setOverParentId(nowParentId);
            globalSortDataApi.setOverParent(nowParent);
        },
        out() {},
        sort() {},
        receive(event, ui) {
            sortable_receive_normal(ui, event, this);
        },
        update() {},
        beforeStop() {},
        stop() {
            globalSortDataApi.reset();
        },
        remove() {},
    });
};

// 向模块组内添加模块
inTabModuleEdit.addModuleToTab = async function (tabId, moduleId) {
    let tabModule = window.$store.getters.currentModuleForId(tabId);
    let moduleInfo;

    $(`#module${moduleId}`).hide();

    let params = {
        moduleId,
        _colId: Fai.top._colId,
        _extId: Fai.top._extId,
    };
    let result = await getChildModuleData(params);
    let { data, success, msg } = result;
    if (success) {
        moduleInfo = data.moduleInfo;
    } else {
        ing(msg, false);
        return;
    }
    if (Fai.top._tabChildModulePos) {
        if (Fai.top._tabChildModulePos.indexOf('label') != -1) {
            var labelId = Fai.top._tabChildModulePos.replace('label_', '');
            $(tabModule.content.labelList).each(function (index, value) {
                if (value.id == labelId) {
                    value.id = moduleInfo.id;
                    value.labelName = value.labelName || moduleInfo.name;
                    value.style = moduleInfo.style;
                    value.do = false;
                }
            });
            Vue.prototype.$designer.onContentChange(tabId);
        } else if (Fai.top._tabChildModulePos.indexOf('pos') != -1) {
            var pos = Fai.top._tabChildModulePos.replace('pos_', '');
            var labelListClone = JSON.parse(JSON.stringify(tabModule.content.labelList));
            var tmpLabel = {};
            tmpLabel = {
                id: moduleInfo.id,
                labelName: moduleInfo.name,
                do: false,
                style: moduleInfo.style,
            };

            labelListClone.splice(pos, 0, tmpLabel);
            tabModule.content.labelList = labelListClone;
            Vue.prototype.$designer.onContentChange(tabId);
        }
    } else {
        let labelListClone = JSON.parse(JSON.stringify(tabModule.content.labelList));
        let tmpLabel = {};
        tmpLabel = {
            id: moduleInfo.id,
            labelName: moduleInfo.name,
            do: false,
            style: moduleInfo.style,
        };

        labelListClone.push(tmpLabel);
        tabModule.content.labelList = labelListClone;
        Vue.prototype.$designer.onContentChange(tabId);
    }

    window.$store.dispatch('manage/addPanel/addMyModule', {
        module: moduleInfo,
        parentId: tabId,
    });
    window.$store.dispatch('setModule', {
        moduleId,
        data: {
            inTab: tabId,
        },
    });

    updateLabelModule(moduleInfo.id, moduleInfo.style, tabId, 'add');

    setTimeout(function () {
        jm('#module' + tabId)
            .find('.formTabButton' + moduleInfo.id)
            .trigger('click', true);
        jm('#module' + moduleInfo.id).attr('_intab', tabId);
    }, 300);

    Fai.top._tabChildModulePos = 0;
};

// 删除模块组内模块
inTabModuleEdit.removeModuleInTab = function (tabId, moduleId, hideModule, moduleStyle) {
    if (typeof tabId == 'undefined' || moduleId == 'undefined') {
        return;
    }

    var tabModule = window.$store.state.currentPageModuleIdMap[tabId];
    var labelList = JSON.parse(JSON.stringify(tabModule.content.labelList));

    // qjie 该模块为2.0则设置属性 _inTabOfFold 用于面板动态响应标题栏隐藏
    var module = window.$store.state.currentPageModuleIdMap[moduleId];
    var style = moduleStyle || 0;
    if (module != null) {
        module._inTabOfFold = false;
        style = module.style;
    }

    $(labelList).each(function (index, value) {
        if (value.id == moduleId) {
            if (labelList.length > 1) {
                labelList.splice(index, 1);
            } else {
                value.id = -1;
                value.labelName = '标签1';
                value.style = -1;
                value.do = false;
            }
        }
    });
    tabModule.content.labelList = labelList;
    updateLabelModule(moduleId, style, tabId, 'hide');
    if (!hideModule) {
        setTimeout(function () {
            jm('#module' + moduleId).attr('_intab', 0);
        }, 200);
    }

    Vue.prototype.$designer.onContentChange(tabId);
};

// 通过标签模块内的添加按钮添加模块d
inTabModuleEdit.addModuleToTabByAddBtn = function (tabId, labelId, event) {
    var options = {
        btnClass: 'addBtn_Icon',
        disableSeveralModuleList: ['horizonTab', 'fold'],
    };
    // 切换添加模块的面板
    let { addPanel } = window.$store.state.manage;
    let { activeTab, activeModuleTab } = addPanel;
    let addModulePanelActive = activeTab === 'module' && activeModuleTab === 'add';
    if (activeTab !== 'module') {
        window.$store.dispatch('manage/addPanel/changeActiveTab', 'module');
    }
    if (activeModuleTab !== 'add') {
        window.$store.dispatch('manage/addPanel/changeActiveModuleTab', 'add');
    }

    let addBtn = $('#formTabContent' + labelId).find('.addBtn_Icon');
    let refreshModuleAddState = false;
    if (addModulePanelActive) {
        // 禁止某些模块拖入标签模块
        panelDisableArea.disableFunc('horizonTab', event, tabId, options, labelId);
        if (addBtn.length > 0 && !!addBtn.attr('_status')) {
            // 此时是添加状态，需要记录位置
            // Fai.top.preModulePosition = 'inTab_' + tabId;
            // Fai.top._tabChildModulePos = 'label_' + labelId;
            refreshModuleAddState = true;
            addModuleAnmaition();
        }
    } else {
        setTimeout(function () {
            // 禁止某些模块拖入标签模块
            panelDisableArea.disableFunc('horizonTab', event, tabId, options, labelId);
        }, 100);
        if (addBtn.length > 0 && !!addBtn.attr('_status')) {
            // 此时是添加状态，需要记录位置
            // Fai.top.preModulePosition = 'inTab_' + tabId;
            // Fai.top._tabChildModulePos = 'label_' + labelId;
            refreshModuleAddState = true;
        }
    }
    if (refreshModuleAddState) {
        window.$store.dispatch('manage/moduleAdd/changeModulePosition', 'inTab');
        window.$store.dispatch('manage/moduleAdd/changeInContainerId', tabId);
        window.$store.dispatch('manage/moduleAdd/changeChildPosIndex', labelId);
    }
};

// 通过模块面板添加模块
inTabModuleEdit.addModuleToTabByModulePanel = function (moduleInfo) {
    let { moduleAdd } = window.$store.state.manage;
    let { inContainerId, childPosIndex } = moduleAdd;
    var tabId = inContainerId;
    var tabModule = window.$store.getters.currentModuleForId(tabId);
    var { renderOptions } = tabModule;

    let labelIds = tabModule.content.labelList.map((item) => item.id);
    if (childPosIndex != null) {
        let labelId = childPosIndex;
        //已有的标签
        if (labelIds.includes(labelId)) {
            $(tabModule.content.labelList).each(function (index, value) {
                if (value.id == labelId) {
                    value.id = moduleInfo.id;
                    value.labelName = value.labelName || moduleInfo.name;
                    value.style = moduleInfo.style;
                    value.do = false;
                }
            });
        } else {
            //需要新增标签
            var pos = labelId;
            var labelListClone = JSON.parse(JSON.stringify(tabModule.content.labelList));
            var tmpLabel = {};
            tmpLabel = {
                id: moduleInfo.id,
                labelName: moduleInfo.name,
                do: false,
                style: moduleInfo.style,
            };
            labelListClone.splice(pos, 0, tmpLabel);
            tabModule.content.labelList = labelListClone;
        }
        Vue.prototype.$designer.onContentChange(tabId);
    } else {
        let labelListClone = JSON.parse(JSON.stringify(tabModule.content.labelList));
        let tmpLabel = {};
        tmpLabel = {
            id: moduleInfo.id,
            labelName: moduleInfo.name,
            do: false,
            style: moduleInfo.style,
        };

        labelListClone.push(tmpLabel);
        tabModule.content.labelList = labelListClone;
        Vue.prototype.$designer.onContentChange(tabId);
    }
    //新增模块缓存到标签模块里面
    var tmpCache = {
        //sbm: $('<div></div>').append(moduleHtml)[0].innerHTML,
        sbm: `<div id='module${moduleInfo.id}'></div>`,
        //moduleScript: moduleJs,
        load: true,
    };
    // renderOptions.args.$set(
    //     renderOptions.args.labelCache,
    //     'module' + moduleInfo.id,
    //     tmpCache
    // );
    renderOptions.args.labelCache[`module${moduleInfo.id}`] = tmpCache;
    if (moduleInfo.style == 51) {
        var packModuleList = moduleInfo.content.moduleList;
        var childModuleList = [];
        if (packModuleList.length) {
            packModuleList.forEach(function (val) {
                var data = $.parseJSON(val);
                childModuleList.push(data.id);
            });
        }
        updateLabelModule(moduleInfo.id, moduleInfo.style, tabId, 'add', childModuleList);
    } else {
        updateLabelModule(moduleInfo.id, moduleInfo.style, tabId, 'add');
    }

    setTimeout(function () {
        jm('#module' + tabId)
            .find('.formTabButton' + moduleInfo.id)
            .trigger('click', true);
        jm('#module' + moduleInfo.id).attr('_intab', tabId);
    }, 300);

    resetModuleAddState();
};

inTabModuleEdit.addTabEmptyLabel = function (tabId) {
    var tabModule = window.$store.state.currentPageModuleIdMap[tabId];
    var minLabelId = 0;
    $(tabModule.content.labelList).each(function (index, value) {
        if (minLabelId > value.id) {
            minLabelId = value.id;
        }
    });
    var labelId = (minLabelId -= 1);
    var labelListClone = JSON.parse(JSON.stringify(tabModule.content.labelList));
    var tmpLabel = {};
    tmpLabel = {
        id: labelId,
        labelName: '标签' + Math.abs(parseInt(labelId)),
        do: false,
        style: -1,
    };

    labelListClone.push(tmpLabel);
    tabModule.content.labelList = labelListClone;
    Vue.prototype.$designer.onContentChange(tabId);
    tabModule.extInfo.currentIndex = labelListClone.length - 1;
    setTimeout(function () {
        jm('#module' + tabId)
            .find('.formTabButton' + labelId)
            .trigger('click', true);
    }, 300);
};

// 生成标签模块的Sortable容器
inTabModuleEdit.createTabSortContainer = function ($container) {
    $container.find('.formStyle50').each(function (index, el) {
        var module = $(el),
            moduleId = module.attr('id').replace('module', ''),
            sortContainer = module.find('.J_tabSortContainer'); // 这里可以用elem.data()来缓存sortContainer，提高性能

        if (sortContainer.length < 1) {
            module.append("<div moduleId='" + moduleId + "' class='J_tabSortContainer fk-tabSortContainer'></div>");
            sortContainer = module
                .find('.J_tabSortContainer')
                .append(module.find('.formBannerTitle' + moduleId).clone());
        }

        // 是否创建了sortable（属性检测）
        if (typeof sortContainer.sortable('option').disabled == 'undefined') {
            inTabModuleEdit.inTabModuleSortable(module); // 绑定模块标签Sortable
        }
    });
};

function sortable_receive_normal(ui, event, This) {
    // 标签模块接收模块
    var module = ui.item,
        moduleId = module.attr('id').replace('module', ''),
        tabId = $(This).attr('moduleId'),
        msg;

    globalSortDataApi.setReceiveId(`module${tabId}`);
    globalSortDataApi.setReceive('tab');
    globalSortDataApi.setReceiveParent($('#module' + tabId));

    var isCommonModule =
        module.hasClass('formStyle52') || module.hasClass('formStyle53') || module.hasClass('formStyle54') || false;
    if (module.hasClass('formStyle50') || module.hasClass('formStyle58')) {
        msg = '添加失败，横向标签模块内无法添加排版模块';
        msg = isCommonModule ? '添加失败，横向标签模块内无法添加常用模块' : msg;
        Fai.ing(msg, true);
        ui.sender.sortable('cancel');
        return;
    }

    $('#webModuleContainer').find('>.form').removeClass('fk-sortModuleStyle');
    if (isCommonModule) {
        Fai.top.preModulePosition = 'inTab_' + tabId;
        moveSpecialModuleInNewPack(moduleId, tabId, {
            originalParentId: parseInt(module.attr('_inpack')),
        }).then(({ parent }) => {
            inTabModuleEdit.addModuleToTab(tabId, parent.id);
        });
    } else {
        inTabModuleEdit.addModuleToTab(tabId, moduleId, null);
    }
}

function updateLabelModule(id, style, labelModuleId, type, childModuleList) {
    if (type == 'add') {
        let labelModuleData = Fai.top.labelModuleData['module' + labelModuleId];
        labelModuleData.push({
            id,
            inTab: labelModuleId,
        });
        if (style == 51) {
            if (childModuleList && childModuleList.length) {
                childModuleList.forEach(function (val) {
                    labelModuleData.push({
                        id: val,
                        inPack: id,
                    });
                });
            } else {
                $('#module' + id)
                    .find('.form')
                    .each(function (index, val) {
                        var childId = parseInt($(val).attr('id').replace('module', ''));
                        labelModuleData.push({
                            id: childId,
                            inPack: id,
                        });
                    });
            }
        }
    } else if (type == 'hide') {
        let labelModuleData = Fai.top.labelModuleData['module' + labelModuleId];
        Fai.top.labelModuleData['module' + labelModuleId] = labelModuleData.filter(function (val) {
            if (val.id == id) {
                return false;
            } else if (style == 51 && val.inPack && val.inPack == id) {
                return false;
            } else {
                return true;
            }
        });
    }
}

// ***
// from mobi/mobi_tab
// ***

export const initTabModuleStyle = function (moduleId, labelLayout) {
    var module = jm('#module' + moduleId),
        buttonWrap = module.find('.J_formTabButtonWrap'),
        buttonList = buttonWrap.find('.J_formTabButtonList'),
        buttonListContent = buttonWrap.parent(),
        formTabButton = buttonList.find('.J_formTabButton'),
        formTabModuleContent = module.find('.J_formTabModuleContent'),
        showArrow = module.find('.J_tabModule').attr('_arrowopen') === 'true' || !jm.os.supportsTouch,
        maxButtonWidth = 0, // 最长那个按钮的宽度
        buttonWidth = 0,
        butsWidth = 0,
        btnWrapWidth,
        wrapOffset,
        btnOffset,
        nextBtn;

    // 锚点,tab会跳到锚点模块（分页）
    if (window.URL.length > 0) {
        var anchor = window.location.search,
            paramIdx = anchor.indexOf('pageno'),
            moduleIdx,
            paramStr,
            initClickId,
            reverseStr;

        if (paramIdx > -1) {
            paramStr = anchor.substring(0, paramIdx);
            reverseStr = paramStr.split('').reverse().join('');
            moduleIdx = reverseStr.indexOf('m'); // 反转字符串，找到第一个“m”,  例如"?m494pageno=2"，先找到pageno，分割后变成?m494，在找到紧贴的m
            initClickId = parseInt(reverseStr.substring(0, moduleIdx).split('').reverse().join(''));

            if (initClickId > 0) {
                formTabButton.each(function () {
                    if (parseInt(jm(this).attr('tabmoduleid')) == initClickId) {
                        return false;
                    }
                });
            }
        }
    }

    if (!buttonListContent.hasClass('wrapOpen') && labelLayout != 2) {
        formTabModuleContent.find('.J_preBtn').show();
        formTabModuleContent.find('.J_nextBtn').show();
    } else {
        formTabModuleContent.find('.J_preBtn').hide();
        formTabModuleContent.find('.J_nextBtn').hide();
        buttonList.css('width', '100%');
        buttonList.css('left', '');
        return;
    }

    // 计算按钮区域的宽度
    formTabButton.each(function (index, el) {
        buttonWidth = el.offsetWidth;
        butsWidth += buttonWidth + 1; // +1是因为当DOM实际宽度为72.1时，el.offsetWidth会四舍五入，变成72，就会有0.1的误差，导致容器宽度不够，tab被挤下去
        if (buttonWidth > maxButtonWidth) {
            maxButtonWidth = buttonWidth;
        }
    });
    buttonList.width(butsWidth + 'px');
    btnWrapWidth = buttonWrap.width();
    if (butsWidth <= btnWrapWidth) {
        // 如果可视区大于按钮区宽度，则去掉切换按钮
        buttonList.css('width', '100%');
        formTabModuleContent.find('.J_preBtn').hide();
        formTabModuleContent.find('.J_nextBtn').hide();
        return;
    }

    if (formTabModuleContent.find('.J_preBtn').length > 0 || !showArrow) {
        return;
    }

    // PC端出现左右移动按钮
    jm(
        "<div class='J_preBtn f-preBtn faisco-icons'></div><div class='J_nextBtn f-nextBtn faisco-icons'></div>"
    ).appendTo(formTabModuleContent);
    wrapOffset = buttonWrap.offset();

    formTabModuleContent.find('.J_preBtn').on('click', function (e) {
        e.stopPropagation();
        let preBtn = jm(this);

        if (preBtn.hasClass('disable')) return;

        nextBtn = preBtn.siblings('.J_nextBtn');
        formTabButton = buttonList.find('.J_formTabButton');
        btnOffset = formTabButton.eq(0).offset();
        if (btnOffset.left >= wrapOffset.left) {
            return;
        }

        // 这里turnLeft的宽度可以调，暂时取最大的那个Tab的宽度
        var nowScroll = buttonWrap[0].scrollLeft,
            turnLeft = nowScroll - maxButtonWidth,
            timer;

        timer = setInterval(function () {
            if (turnLeft < nowScroll) {
                nowScroll -= 2;
                buttonWrap[0].scrollLeft = nowScroll;
            } else {
                clearInterval(timer);
            }
        }, 1);

        // 防止用户在滑动时点击
        preBtn.addClass('disable');
        nextBtn.addClass('disable');
        setTimeout(function () {
            preBtn.removeClass('disable');
            nextBtn.removeClass('disable');
            handleArrow();
        }, 450);
    });

    formTabModuleContent.find('.J_nextBtn').on('click', function (e) {
        e.stopPropagation();
        nextBtn = jm(this);
        let preBtn = nextBtn.siblings('.J_preBtn');

        if (nextBtn.hasClass('disable')) return;

        formTabButton = buttonList.find('.J_formTabButton');
        btnOffset = formTabButton.eq(formTabButton.length - 1).offset();
        if (btnOffset.right <= wrapOffset.right) {
            return;
        }

        var nowScroll = buttonWrap[0].scrollLeft,
            turnLeft = buttonWrap[0].scrollLeft + maxButtonWidth,
            timer;

        timer = setInterval(function () {
            if (nowScroll < turnLeft) {
                nowScroll += 2;
                buttonWrap[0].scrollLeft = nowScroll;
            } else {
                clearInterval(timer);
            }
        }, 1);

        // 防止用户在滑动时点击
        preBtn.addClass('disable');
        nextBtn.addClass('disable');
        setTimeout(function () {
            preBtn.removeClass('disable');
            nextBtn.removeClass('disable');
            handleArrow();
        }, 450);
    });

    handleArrow();
    buttonWrap.on('scroll', debounce(handleArrow, 300)); // 防抖

    function handleArrow() {
        var tabButtons = buttonList.find('.J_formTabButton'),
            lastTabBtnOffset = tabButtons.eq(tabButtons.length - 1).offset(),
            firstTabBtnOffset = tabButtons.eq(0).offset(),
            visualArea = buttonWrap.offset(),
            nextBtn = formTabModuleContent.find('.J_nextBtn'),
            preBtn = formTabModuleContent.find('.J_preBtn');

        lastTabBtnOffset.right > visualArea.right ? nextBtn.show() : nextBtn.hide();
        firstTabBtnOffset.left < visualArea.left ? preBtn.show() : preBtn.hide();
    }
};

// 记录标签模块内子模块的动画执行函数
export const inTabMaqueeHelper = {
    // 记录动画到动画队列中
    setInTabMarquee(options) {
        var moduleId = options.moduleId,
            funcName = options.funcName,
            args = options.args || [],
            runTime = options.runTime || 1; // 可以执行几次。-1代表可以无限执行

        if (typeof funcName != 'string') {
            return;
        }

        var marqueeHelper = Fai.top.inTabMaqueeHelper,
            moduleMaquee =
                marqueeHelper[moduleId] instanceof Array ? marqueeHelper[moduleId] : (marqueeHelper[moduleId] = []);

        moduleMaquee.push({
            funcName,
            args,
            runTime,
            hasRunTime: 0,
        });
    },

    // 运行动画队列的某个模块动画
    runInTabMarquee(moduleId) {
        var marqueeHelper = Fai.top.inTabMaqueeHelper || (Fai.top.inTabMaqueeHelper = {});
        if (!(moduleId in marqueeHelper)) {
            return;
        }

        var moduleMaquee = marqueeHelper[moduleId],
            funcName,
            args,
            i;
        for (i = 0; i < moduleMaquee.length; i++) {
            funcName = moduleMaquee[i].funcName;
            args = moduleMaquee[i].args;
            if (
                typeof Mobi[funcName] == 'function' &&
                (moduleMaquee[i].hasRunTime < moduleMaquee[i].runTime || moduleMaquee.runTime == -1)
            ) {
                Mobi[funcName].apply(Mobi[funcName], args);
                moduleMaquee[i].hasRunTime++;
            }
        }
    },
};

// 横向标签辅助工具，一些杂方法可以写在这个命名空间中
let tabModuleHelper = {};
// 初始化tab后调用
tabModuleHelper.initTabCallBack = function (module) {
    var formTabContents = module.find('.J_formTabContent');
    formTabContents.each(function (index, el) {
        var $elem = jm(el);
        setTimeout(function () {
            $elem.addClass('f-formTabContent').removeClass('f-hideTabModule');
        }, 500);
    });
};

// 检测tab的标题是否被遮挡，是的话则按向左（右）移动
tabModuleHelper.autoMoveTabPos = function (This, module) {
    var buttonWrap = module.find('.J_formTabButtonWrap'),
        offset = This.offset(),
        width = parseInt(This.width()),
        left = offset.left,
        right = left + width,
        leftEdge = 0,
        rightEdge = buttonWrap[0].offsetWidth,
        timer,
        turnRight;

    if (right >= rightEdge) {
        turnRight = -Math.ceil(right - rightEdge);
        timer = setInterval(function () {
            if (turnRight < 0) {
                turnRight += 2;
                buttonWrap[0].scrollLeft += 2;
            } else {
                clearInterval(timer);
            }
        }, 1);
    } else if (left <= leftEdge) {
        turnRight = buttonWrap[0].scrollLeft >= width ? width : buttonWrap[0].scrollLeft;
        timer = setInterval(function () {
            if (turnRight > 0) {
                turnRight -= 2;
                buttonWrap[0].scrollLeft -= 2;
            } else {
                clearInterval(timer);
            }
        }, 1);
    }
};
