import { MobiModule } from '../Module';
import { MODULE_STYLE } from '@/def/module';
import ProductSearchView from './module/ProductSearch.vue';

class _ProductSearch extends MobiModule {
    constructor(options = {}) {
        super(MODULE_STYLE.PRODUCT_SEARCH, options);
    }

    render() {
        return ProductSearchView;
    }
}

export const ProductSearch = new _ProductSearch();
