import { browser } from '@jz/utils';
import { logDog } from '@/shared/log';
import { encodeUrl, addRequestPrefix } from '@/shared/util';
import { getSiteMultiLanguageUrl, genAjaxUrl } from '@/shared/url';
import { delNewsApi, setNewsApi } from '@/api/news';
import popupWindow from '@/shared/popupWindow';
import { ing } from '@/shared/fai';
import { bindTouchAndClickEvent } from '@/shared/event';
import { successHandle } from '@/shared/fai';
import { isModuleV2 } from '@/manage/module-edit-panel/utils/module';
import { sharePage, showCommSharePanel } from '@/features/global-mobi-function/methods/share/index';
import { scrollFadeInV3, scrollFadeIn } from '@/modules/comm';
import { postUserComment } from '@/modules/shared/comment';
import { hidePlaceholder } from '@/shared/misc/placeholder';
import { initWebPage } from '@/shared/misc/init-webpage';
import { getRouterData } from '@/features/router/universal';
import { changeHref } from '@/features/router/universal';
import { getQurl } from '@/manage/url';
import { refreshModule, refreshNewsListModule } from '@/features/global-mobi-function/methods/module';
import { reloadPreview } from '@/features/router/universal';
import { encodeHtmlJs } from '@/shared/util';
import { getBitMemory } from '@/shared/flag';
export const removeMixNewsStyle = function (option) {
    var $newsList = $('#newsList' + option.moduleId);
    var $firstChild = $newsList.children().eq(0);
    var $title = $firstChild.find('.mixNewsStyleTitleContainer  a');
    $title.css({ width: '' });
};
/*
 * 文章列表图文样式
 * 不换行时计算标题宽度
 *
 */
export const initMixNews = function (option) {
    var $newsList = $('#newsList' + option.moduleId);
    var $firstChild = $newsList.children().eq(0);
    var $title = $firstChild.find('.mixNewsStyleTitleContainer  a');
    var newsListWidth = parseInt($firstChild.width());
    var imgWidth = parseInt(option.imgWidth);
    var titleWidth = newsListWidth - imgWidth - 48;
    $title.css({ width: titleWidth + 'px' });
};

export const fixNewsJumpLink = function (moduleId, lanCode) {
    var module = $('#module' + moduleId);
    module
        .find('.newsLine')
        .off('click.link')
        .on('click.link', function (e) {
            if ($(e.target).hasClass('J_linkH')) {
                return;
            }
            // 这里要对外链以及内链进行判断，不然外链会报错 @qjie
            var href = $(this).find('.J_linkH').attr('href');
            if (href.indexOf('http:') != -1 || href.indexOf('https:') != -1) {
                // 外链，直接跳转 -- 针对访客态
                changeHref(href);
            } else if (href.indexOf('Fai.ing') != -1) {
                ing('管理态不支持跳转外部链接，请通过“预览”进行访问'); // 外链，提示 -- 针对管理态
            } else {
                changeHref('/' + lanCode + '/' + href);
            }
        });
};

export const initModuleNewsListItemManage = function (options) {
    if (options.version2) {
        initModuleNewsListItemManageV2(options);
    } else {
        initModuleNewsListItemManageV1(options);
    }
};

// 旧文章模块编辑入口
const initModuleNewsListItemManageV1 = function (options) {
    if (!options) {
        return;
    }
    if (!options.moduleId) {
        return;
    }
    $('#' + options.newsParent)
        .find('.' + options.news)
        .each(function () {
            var that = $(this); //.find('.'+options.newsChild).eq(0);
            $(this)
                .mouseenter(function () {
                    var newsId = $(this).attr('newsId'),
                        newsName = $(this).attr('newsName'),
                        topClassName = $(this).attr('topClassName'),
                        topSwitch = $(this).attr('topSwitch');

                    let canEditNews = true;
                    const module = window.$store.state.currentPageModuleIdMap[options.moduleId];
                    if (Fai.top.onlineVoteAuthInfo.allow === false) {
                        // 共用一个权限
                        const newsInfo = module.extInfo.newsList.find((item) => item.id === Number(newsId));
                        if (newsInfo && getBitMemory(newsInfo.flag, 0x80)) {
                            // 不够推广版，外链文章无法编辑
                            canEditNews = false;
                        }
                    }
                    const editItem = {
                        operationText: '编辑文章',
                        className: 'edit',
                        onClick: () => {
                            popupWindow({
                                title: '编辑文章',
                                frameSrcUrl: `${options.frameSrcUrl}&id=${newsId}&mobiSettingState=1`,
                                width: 920,
                                height: 682,
                                version: 2,
                                closeFunc: () => {
                                    refreshModule(options.moduleId);
                                },
                            });
                        },
                    };
                    const delItem = {
                        operationText: '删除文章',
                        className: 'close',
                        onClick: () => {
                            delNews(newsId, encodeHtmlJs(newsName), '', 0, true, () => {
                                refreshModule(options.moduleId);
                            });
                        },
                    };
                    var newsJson = [];
                    if (canEditNews) {
                        newsJson.push(editItem);
                    }
                    newsJson.push(delItem);
                    if ((options.newSelectCanTop || !options.newsSelect) && !options.smartSelect) {
                        newsJson.push({
                            operationText: topSwitch == 'off' ? '取消置顶' : '设为置顶',
                            className: '' + topClassName + '',
                            display: topSwitch == 'off' ? 'text' : '',
                            onClick: () => {
                                setNewsTop(newsId, `${topSwitch}`, '', options.moduleId);
                            },
                        });
                    }
                    Vue.prototype.$layer.addEditLayer(that, newsJson, 1, options);
                })
                .mouseleave(function () {
                    Vue.prototype.$layer.removeEditLayer(that);
                });
        });
};

// 模块2.0 入口
const initModuleNewsListItemManageV2 = function (options) {
    if (!options) {
        return;
    }
    if (!options.moduleId) {
        return;
    }

    $('#' + options.newsParent)
        .find('.' + options.news)
        .each(function () {
            var that = $(this);
            if ($(this).hasClass('blankDiv')) return;
            $(this)
                .off('mouseenter.news')
                .on('mouseenter.news', function () {
                    var newsId = $(this).attr('newsId'),
                        newsName = $(this).attr('newsName'),
                        topClassName = $(this).attr('topClassName'),
                        topSwitch = $(this).attr('topSwitch');

                    let canEditNews = true;
                    const module = window.$store.state.currentPageModuleIdMap[options.moduleId];
                    if (Fai.top.onlineVoteAuthInfo.allow === false) {
                        // 共用一个权限
                        const newsInfo = module.extInfo.newsList.find((item) => item.id === Number(newsId));
                        if (newsInfo && getBitMemory(newsInfo.flag, 0x80)) {
                            // 不够推广版，外链文章无法编辑
                            canEditNews = false;
                        }
                    }

                    const editItem = {
                        operationText: '编辑文章',
                        className: 'edit',
                        onClick() {
                            editNews(options.frameSrcUrl, newsId);
                        },
                    };
                    const delItem = {
                        operationText: '删除文章',
                        className: 'close',
                        onClick() {
                            delNews(newsId, encodeHtmlJs(newsName), '', 0, true);
                        },
                    };
                    var newsJson = [];
                    if (canEditNews) {
                        newsJson.push(editItem);
                    }
                    newsJson.push(delItem);
                    if ((options.newSelectCanTop || !options.newsSelect) && !options.smartSelect) {
                        newsJson.push({
                            operationText: topSwitch == 'off' ? '取消置顶' : '设为置顶',
                            className: '' + topClassName + '',
                            display: topSwitch == 'off' ? 'text' : '',
                            onClick() {
                                setNewsTop(newsId, topSwitch, '', options.moduleId);
                            },
                        });
                    }
                    Vue.prototype.$layer.addEditLayer(that, newsJson, 1, options);
                })
                .off('mouseleave.news')

                .on('mouseleave.news', function () {
                    Vue.prototype.$layer.removeEditLayer(that);
                });
        });
};

const editNews = function (frameSrcUrl, newsId) {
    popupWindow({
        title: '编辑文章',
        frameSrcUrl: frameSrcUrl + '&id=' + newsId + '&mobiSettingState=1&unload=true',
        width: 920,
        height: 682,
        version: 2,
    });
};

/*
文章删除
*/
const delNews = function (id, name, refreshUrl, jumpMode, useV2, callback) {
    Vue.prototype.$layer.removeAllEditLayer();
    if (Vue.prototype.$designer.checkSaveBar()) {
        return;
    }
    var msg = '确定将该文章放入回收站吗？'; //  '将从网站后台中删除该文章，删除后无法找回，<p style="text-align: left;color:#333;">确定删除？</p>'
    new Fai.top.Vue().$createDialog({
        content: msg,
        confirmButton: { text: '确 定' },
        cancelButton: { text: '取 消' },
        async onConfirm() {
            ing('正在删除……', false);
            let result = await delNewsApi({
                id,
            });
            if (result.success) {
                if (typeof callback === 'function') {
                    callback();
                }
                if (useV2) {
                    window.$store.commit('delNew', parseInt(id));
                } else {
                    reloadPreview();
                }
                successHandle(result, '删除成功', '', refreshUrl, jumpMode, 1);
            }
        },
    });
};

export const addNew = function (moduleId, unSelect) {
    var module = window.$store.getters.currentModuleForId(moduleId);
    const ntl = module.content.ntl.filter(function (gid) {
        return gid != 0;
    });

    if (!module.content.ns) {
        logDog(100008, 3);
        popupWindow({
            title: '添加文章',
            frameSrcUrl:
                getSiteMultiLanguageUrl() +
                '/manage_v2/newsEdit.jsp?mGroupTypeIds=' +
                ntl +
                '&mobiSettingState=1&reload=false&ram=' +
                Math.random(),
            callArgs: 'newsList',
            width: 920,
            height: 682,
            version: 2,
            closeFunc: closeEdit,
        });
    } else {
        popupWindow({
            title: '添加文章',
            frameSrcUrl:
                getSiteMultiLanguageUrl() +
                '/manage_v2/newsEdit.jsp?_nmid=0&src=1&mobiSettingState=1&reload=false&ram=' +
                Math.random(),
            callArgs: 'newsList',
            width: 920,
            height: 682,
            version: 2,
            closeFunc: closeEdit,
        });
    }
    logDog(100008, 3);

    function closeEdit(val) {
        if (!val) return;
        var data = JSON.parse(val);
        data.newDataList.forEach(function (item) {
            item.top = 0;
        });

        sessionStorage.setItem(`moduleNews${moduleId}`, '1');
        window.$store.commit('batchAddNews', data.newDataList);
        if (module.content.ns && !unSelect) {
            data.newDataList.forEach(function (item) {
                module.content.nil.unshift(item.id);
            });
        }

        if (isModuleV2(6)) {
            Vue.prototype.$designer.onContentChange(moduleId);
        }
    }
};

/*
文章置顶
*/
const setNewsTop = async function (id, topSwtich, refreshUrl, moduleId) {
    if (Vue.prototype.$designer.checkSaveBar()) {
        return;
    }
    ing('正在设置……', false);
    let result = await setNewsApi({
        id,
        top: topSwtich,
    });
    let { success, ms } = result;
    if (success) {
        let newLists = window.$store.state.manage.newsList;
        let list = newLists.filter((item) => item.id == id);
        list[0].top = ms;
        successHandle(result, '设置成功', '', refreshUrl, 0, 1);
        const lnUrl = getSiteMultiLanguageUrl();
        Vue.nextTick(() => {
            initModuleNewsListItemManage({
                newsParent: `newsList${moduleId}`,
                news: 'newsLine',
                newsChild: 'lineBody',
                moduleId: moduleId,
                frameSrcUrl: `${lnUrl}/manage_v2/newsEdit.jsp?ram=${Math.random()}&mobiSettingState=1`,
                newSelectCanTop: true,
                version2: true,
            });
            refreshNewsListModule(moduleId);
        });
    }
};

/*
    文章详情页切换新版
    @param moduleId --- 模块 id
    @param isDataCompatible --- 切换新版是否数据兼容了，true数据兼容了，false数据还没兼容
 */
export const switchNewNewsDetail = function (moduleId, isDataCompatible) {
    if (Vue.prototype.$designer.checkSaveBar()) {
        return;
    }

    var newsNewDefInfo = Fai.top._other.newsNewDefInfo;

    // 只有第一次切换版本和 2.0 版本切换到新版时，才需要数据兼容
    if (!isDataCompatible && newsNewDefInfo.isNewsNewDetail == 1) {
        dataCompatible(moduleId);
    }
    // 首次切换的时候，先前版本号这个字段是空的，所以讲当前的版本号赋予给先前版本号，用于切换回旧版的版本判断
    if (typeof newsNewDefInfo.prevVersion == 'undefined') {
        newsNewDefInfo.prevVersion = newsNewDefInfo.isNewsNewDetail;
    }

    newsNewDefInfo.isNewsNewDetail = 2; // 0表示旧文章详情    1表示新文章详情	2表示文章详情3.0版本
    Fai.top._other.newsNewDefInfo = newsNewDefInfo;
    Fai.top._otherChange++;
    Fai.top._changeStyleNum++;
    Vue.prototype.$designer.styleChanged();

    Vue.prototype.$designer.saveStyle({
        fun() {
            let routerData = getRouterData(Fai.top._colId);
            routerData._nnd = newsNewDefInfo.isNewsNewDetail;
            let data = JSON.stringify(routerData);
            window.$router.push({
                query: { ...window.$router.currentRoute.query, data },
            });
            Fai.top._Global.styleChangeNum = 0;
        },
    });
};

// 文章详情页切换旧版
export const switchOldNewsDetail = function () {
    if (Vue.prototype.$designer.checkSaveBar()) {
        return;
    }

    var newsNewDefInfo = Fai.top._other.newsNewDefInfo;

    newsNewDefInfo.isNewsNewDetail = newsNewDefInfo.isNewUser ? 1 : newsNewDefInfo.prevVersion;
    Fai.top._other.newsNewDefInfo = newsNewDefInfo;

    Fai.top._otherChange++;
    Fai.top._changeStyleNum++;
    Vue.prototype.$designer.styleChanged();
    Vue.prototype.$designer.saveStyle({
        fun() {
            let routerData = getRouterData(Fai.top._colId);
            routerData._nnd = newsNewDefInfo.isNewsNewDetail;
            let data = JSON.stringify(routerData);
            window.$router.push({
                query: { ...window.$router.currentRoute.query, data },
            });
            Fai.top._Global.styleChangeNum = 0;
        },
    });
};

// 文章详情页 2.0 版本第一次切换到 3.0 版本需要数据兼容
function dataCompatible(moduleId) {
    ing('首次切换，正在初始化数据……', false);
    // qurl
    var params = new Array();
    var thisUrl = getQurl();
    params.push('&qurl=' + encodeUrl(thisUrl));
    params.push('&cmd=setWafCk_newsDetailV3');
    params.push('&id=' + moduleId);
    $.ajax({
        type: 'post',
        async: false,
        url: genAjaxUrl('mobimodule_h.jsp'),
        data: params.join(''),
        error() {
            ing(LS.js_systemError, false);
        },
        success(result) {
            successHandle(result, '切换成功', '', '', 0, 1);
        },
    });
}

export const editNewsDetail = function (id) {
    popupWindow({
        title: '编辑文章',
        frameSrcUrl:
            getSiteMultiLanguageUrl() + '/manage_v2/newsEdit.jsp?id=' + id + '&mobiSettingState=1&ram=' + Math.random(),
        width: '920',
        height: '682',
        version: 2,
    });
};

/***
 *
 * 迁移from \\dev3.faidev.cc\dev3\dev-git\json\res\mobi\src\business\mobi\mobi_moduleNews.js
 *
 * **/

export const initNewsEvent = function ({ moduleId, pageStyle, returnUrl, templateDesignType }) {
    if (!moduleId) return;

    var headDom = document.getElementById('productDetailHeader' + moduleId),
        prodcutGoBack = headDom?.querySelector('.icon-gClose'),
        triggerID;
    if (!Fai.top._openThemeV3) {
        if (templateDesignType === 2) {
            triggerID = 'newsDetailV3Share';
        } else {
            triggerID = 'newsDetailShare';
        }
    } else {
        triggerID = 'newsDetailShare';
    }

    /*  share start */
    var shareOption = {
        triggerId: triggerID,
        pageBg: true,
        pageHtml: sharePage(Fai.top._shareData),
        direction: 'bottom',
        callback() {
            showCommSharePanel('shareListShowSwipe', 'news');
        },
        closeWebPage: '.shareListPanelBg',
    };
    initWebPage(shareOption);
    /*  share end */

    /*  go back */
    prodcutGoBack &&
        jm(prodcutGoBack).bind('click', function () {
            Fai.top._isExternalLinks = false; //重置为false，为了跳过外链判断逻辑 allen 2017-10-16
            if (window.history.length <= 1) {
                changeHref('/');
            } else {
                //这里改为 如果是由登录页过来的就要跳到 主页
                if (returnUrl && returnUrl.length > 0) {
                    changeHref(returnUrl);
                } else {
                    var userAgentStr = navigator.userAgent.toLocaleLowerCase();
                    if (userAgentStr.includes('ucbrowser') && history.length <= 2) {
                        // 修复UC浏览器直接进入文章详情后点击返回键时，页面没有返回首页
                        changeHref('/');
                        return;
                    }

                    history.back();
                }
            }
            return false;
        });

    // --bug=1081391 --user=陈奖 【文章详情】3.0主题，页面样式切换时，出现按钮消失、头图被遮挡的情况。 https://www.tapd.cn/42954297/s/1374147
    //  从样式2切换到样式1会残留样式2的color，临时用JQ处理，最好改写成Vue驱动这部分样式
    if (pageStyle === 1) {
        $('.jz_themeV3 .newsDetailV3Panel .newsBack, .jz_themeV3 .newsNewDetailPanel .newsHome').css('color', '');
    }

    /* header fadeIn and FadeOut */
    if (Fai.top._openThemeV3) {
        scrollFadeInV3('productDetailHeader' + moduleId, 'newsFigure' + moduleId, pageStyle);
    } else {
        scrollFadeIn('productDetailHeader' + moduleId, 'newsFigure' + moduleId, pageStyle);
    }

    /* user post a comment*/
    var urlStr =
        addRequestPrefix({ newPath: '/ajax', oldPath: 'ajax' }) + '/newsComment_h.jsp?cmd=addWafCk_submitNewComment';
    var commentOption = {
        triggerId: 'postComment',
        panelId: 'userCommentPanel',
        callback() {
            postUserComment(urlStr, 1);
        },
        closeWebPage: '#userCommentPanel .userCommentGoBack',
    };
    /*   */
    initWebPage(commentOption);

    // 20170509安卓系统浏览器的video标签点击播放后会置顶,导致覆盖了评论的页面
    // 处理方案,点击评论的时候就隐藏video,返回文章就显示video
    // 文章点击评论,则暂停视频播放
    var $newsDetailPanel = jm('#newsDetailPanel');
    var $video = $newsDetailPanel.find('video');
    var len = $video.length;
    if (len > 0) {
        jm('#postComment').on('click', function () {
            for (var i = 0; i < len; i++) {
                $video[i].pause();
            }
            if (jm.os.android) {
                $video.hide();
                //关闭评论页面
                bindTouchAndClickEvent(jm('#userCommentPanel .userCommentGoBack'), function () {
                    $video.show();
                });
            }
        });
    }

    /* show and hide placeholder */
    hidePlaceholder();

    if (Fai.top._openThemeV3 && Fai.top._colId == 28) {
        let headIsHidden = jm('#webHeader_v3').hasClass('J_header_is_hide');
        if (jm('#g_body').hasClass('regularHeadStyle') && !headIsHidden) {
            // 3.0，文章详情，常规头部，顶部无隐藏
            jm('#newsDetailPanel').find('.title-textStyle').css('margin-top', '0');
        }

        var newsDetailHead = jm('.productDetailHeader');
        if (headIsHidden && (newsDetailHead.html() == '' || newsDetailHead.length == 0)) {
            // 头部没有内容时，隐藏头部。
            newsDetailHead.hide();
            jm('#newsDetailPanel').find('.title-textStyle').css('margin-top', '0');
        }
    }
};

// 3.0常规顶部样式一添加下边框
export const newsGroupHeaderBorder = function () {
    if (jm('body').find('.jz_themeV3 .newsDetailV3Panel .title-textStyle').length > 0) {
        $('.jz_themeV3 .productDetailHeader').css('border-bottom', '0.025rem solid #eeeeee');
    }
};

//判断是否为iphone客户端打开
export const changeTheUrlToAdaptApple = function (linkUrl, subUrl) {
    var destUrl;
    if (linkUrl != '' && linkUrl != null) {
        var u = navigator.userAgent.toLowerCase();
        if (/iphone|ipad|ipod/.test(u)) {
            if (subUrl != '' && subUrl != null) {
                destUrl = subUrl;
            }
        } else {
            destUrl = linkUrl;
        }
        window.location.href = destUrl;
    }
};
//初始化卡片图文的样式及js交互
export const initModuleCardGraphicNewsList = function (options) {
    var moduleId = options.moduleId, //模块id
        module = jm('#newsList' + moduleId), //模块
        // moduleWidth = module.width(),
        picNum = options.picNum, //卡片图文的id
        maxHeight = 0, //卡片图文的最大高度
        $newsItem, //卡片项
        newsItemHeight, //卡片项的高度
        newsItemWidth, //卡片项的宽度
        newsTitleContainerHeight, //卡片图文的标题和摘要的高度
        maxNewsTitleContainerHeight = 0, //卡片图文的标题和摘要的最大高度
        newsListSlidePanelWidth, //卡片图文滑动容器的宽度
        newsListSlidePanelTotalWidth, //文章滑动容器的总宽度
        newsListPanelWidth, //可视区的宽度
        newsListBodyPaddingLeft,
        len, //卡片的个数
        start, //开始滑动的x坐标
        end, //结束滑动的x坐标
        $window = $(window), //window 对象
        oldX,
        oldY,
        currX,
        currY, //记录新旧坐标
        isVer, //记录手指滑动状态
        $body = jm('body'); //获取文档

    /*site 函数*/
    function newsListSlideMouseDown(event) {
        event = event || window.event;
        start = event.pageX;
        $window.off('mousemove.newsListSlide').on('mousemove.newsListSlide', newsListSlideMouseMove);
        $window.off('mouseup.newsListSlide').on('mouseup.newsListSlide', newsListSlideMouseStop);
        event.preventDefault();
    }
    function newsListSlideMouseMove(event) {
        event = event || window.event;
        end = event.pageX;
        var diff = end - start,
            left = parseInt(newsListSlidePanel.css('left') || 0) + diff,
            leftBoundary = newsListPanelWidth - newsListSlidePanelTotalWidth; //边界值
        //var offsetLeft = parseInt(module.offset().left);

        if (left > 0) {
            left = 0;
        } else if (left < leftBoundary) {
            left = leftBoundary;
        }
        if (module.find('.J_newsListSlideCover').length == 0) {
            newsListPanel.append(
                "<div class='J_newsListSlideCover' style='position:absolute;left:0;width:" +
                    newsListSlidePanelWidth +
                    'px;height:' +
                    maxHeight +
                    "px;z-index:2;'></div>"
            );
        } else {
            module.find('.J_newsListSlideCover').show();
        }
        newsListSlidePanel.css('left', left + 'px');
        start = end;
        //当鼠标移出可视容器newsListPanel时。
        //以下好像不禁止也是可以的。
        // if (parseInt(moduleWidth) == 640) {
        //     if (end <= offsetLeft || end >= newsListPanelWidth + offsetLeft) {
        //         $window.off('mousemove.newsListSlide');
        //         $window.off('mouseup.newsListSlide');
        //         start = null;
        //         end = null;
        //         module.find('.J_newsListSlideCover').hide();
        //     }
        // } else {
        //     if (end <= 0 || end >= newsListPanelWidth) {
        //         $window.off('mousemove.newsListSlide');
        //         $window.off('mouseup.newsListSlide');
        //         start = null;
        //         end = null;
        //         module.find('.J_newsListSlideCover').hide();
        //     }
        // }
        event.preventDefault();
    }
    function newsListSlideMouseStop(event) {
        event = event || window.event;
        $window.off('mousemove.newsListSlide');
        $window.off('mouseup.newsListSlide');
        start = null;
        end = null;
        module.find('.J_newsListSlideCover').hide();
        event.preventDefault();
    }

    /*mobi touch逻辑函数*/
    //获取坐标点,兼容touchstart和touchend
    function getTouchPos(event) {
        event = event || window.event;
        let touch = event.changedTouches.length > 0 ? event.changedTouches[0] : event.touches[0];

        return {
            pageX: touch.pageX,
            pageY: touch.pageY,
        };
    }

    /*mobi 函数*/
    /* 判断滑动方向的状态
     * @return 	true 垂直滑动
     * 			false 水平滑动
     */
    function isVertical(x1, y1, x2, y2) {
        var dx = Math.abs(x1 - x2),
            dy = Math.abs(y1 - y2);

        //避免分母为0
        if (dx === 0 && dy === 0) {
            return;
        }
        if (dx === 0 && dy > 0) {
            return true;
        }
        if (dy === 0 && dx > 0) {
            return false;
        }
        return dy / dx > 1 ? true : false;
    }
    //touchstart
    function newListTouchStart(event) {
        //获取坐标点
        var pos = getTouchPos(event);
        //获取当前的坐标
        currX = oldX = pos.pageX;
        currY = oldY = pos.pageY;

        //添加移动事件和结束事件
        newsListSlidePanel.off('touchmove.newlist').on('touchmove.newlist', newListTouchMove);
        newsListSlidePanel.off('touchend.newlist').on('touchend.newlist', newListTouchEnd);
    }
    //touchmove
    function newListTouchMove(event) {
        var pos = getTouchPos(event);
        //获取当前坐标
        currX = pos.pageX;
        currY = pos.pageY;
        //在没有判断过滑动状态的情况下进行获取
        if (isVer === undefined) {
            if (oldX !== undefined && oldY !== undefined && oldX !== undefined) {
                isVer = isVertical(oldX, oldY, currX, currY); //获取当前滑动状态
            }
        }
        if (isVer !== undefined) {
            newsListSlidePanel.off('touchmove.newlist');
        }
        //记录上一个点的坐标
        oldX = pos.pageX;
        oldY = pos.pageY;
    }
    //touchend
    function newListTouchEnd() {
        //恢复状态标志位
        isVer = undefined;
        //进行事件解绑
        newsListSlidePanel.off('touchmove.newlist');
        newsListSlidePanel.off('touchend.newlist');
    }

    if (module.length == 0) {
        return;
    }
    if (picNum != 5) {
        return;
    }

    var newsListPanel = jm(module).find('.J_newsListPanel'),
        newsListSlidePanel = jm(module).find('.J_newsListSlidePanel'),
        newsItemList = jm(module).find('.J_cardGraphicNewsStyle'),
        newsTitleContainer = jm(module).find('.J_mixNewsStyleTitleContainer5');
    len = newsItemList.length;
    for (var i = 0; i < newsItemList.length; i++) {
        $newsItem = jm(newsItemList[i]);
        let itemHeight = $newsItem.height();
        if (itemHeight == 'auto') {
            itemHeight = $newsItem[0].clientHeight;
            newsItemHeight = itemHeight;
        } else {
            newsItemHeight = parseInt($newsItem.height());
        }
        newsItemWidth = parseInt($newsItem.width());
        newsListSlidePanelWidth = len * newsItemWidth;
        newsListBodyPaddingLeft = parseInt(newsItemList.find('.lineBody').eq(0).css('padding-left').replace('px', ''));
        newsListSlidePanelTotalWidth = newsListSlidePanelWidth + newsListBodyPaddingLeft;
        if (len == 1) {
            newsListSlidePanel.css({
                right: '0',
                margin: '0 auto',
                width: newsListSlidePanelTotalWidth + 'px',
            });
            newsListPanel.css('height', newsItemHeight + 'px');
            return;
        }
        if (newsItemHeight > maxHeight) {
            maxHeight = newsItemHeight;
        }
        newsTitleContainerHeight = parseInt($newsItem.find('.J_mixNewsStyleTitleContainer5').height());
        if (newsTitleContainerHeight > maxNewsTitleContainerHeight) {
            maxNewsTitleContainerHeight = newsTitleContainerHeight;
        }
    }
    newsListPanel.css('height', maxHeight + 'px');
    newsItemList.css('height', maxHeight + 'px');
    newsListSlidePanel.css('height', maxHeight + 'px').css('width', newsListSlidePanelTotalWidth + 'px');
    newsTitleContainer.css('height', maxNewsTitleContainerHeight + 'px');

    newsListPanelWidth = newsListPanel.width();
    if (newsListPanelWidth < newsListSlidePanelWidth) {
        //如果可视区的宽度小于卡片图文容器的宽度才需要去滚动
        if (browser.isPc()) {
            //mobi的管理态用iScroll有坑，及容器里的item有a标签，会很难拖，而且拖完会跳转到页面。体验不好，不如自己写。。。
            newsListSlidePanel.off('mousedown.newsListSlide').on('mousedown.newsListSlide', newsListSlideMouseDown);
        } else {
            /*jser 修复iScroll 横向滑动导致网页整体滚动的bug 2017-2-9*/
            new window.iScroll('J_newsListPanel' + moduleId, {
                onBeforeScrollStart: null,
            });

            //水平滑动状态下禁止文档拖动
            $body.off('touchmove.newlist' + moduleId).on('touchmove.newlist' + moduleId, function (event) {
                if (isVer === false) {
                    event.preventDefault();
                }
            });
            //垂直滑动状态下禁止文章列表iscroll拖动
            newsItemList.off('touchmove.newlist').on('touchmove.newlist', function (event) {
                if (isVer === true) {
                    event.stopPropagation();
                }
            });
            //绑定touchstart事件
            newsListSlidePanel.off('touchstart.newlist').on('touchstart.newlist', newListTouchStart);
        }
    }
};
//时间列表样式使用svg裁剪图片 //2016.12.27 wjq
export const initModuletimeAxisNewsList = function (options) {
    var moduleId = options.moduleId; //模块id
    var module = jm('#newsList' + moduleId); //模块
    var picNum = options.picNum; //时间列表的id

    if (module.length == 0) {
        return;
    }
    if (picNum != 7) {
        return;
    }
    var newsTitlePic = module.find('.J_newsTitlePic');
    var picWidth = parseInt(newsTitlePic.width());
    var picHeight = parseInt(newsTitlePic.height());

    //clip-path: 3% 0, 100% 0%, 100% 100%, 3% 100%, 3% 38%, 1% 33%, 3% 27%  下面的点根据clip-path去算出来
    //依据每个点的宽高可以画出任意图形 可以借助工具 http://bennettfeely.com/clippy/
    var onePoint = Math.ceil(0.04 * picWidth) + ' 0';
    var twoPoint = picWidth + ' 0';
    var threePoint = picWidth + ' ' + picHeight;
    var fourPoint = Math.ceil(0.04 * picWidth) + ' ' + picHeight;
    var fivePoint = Math.ceil(0.04 * picWidth) + ' ' + Math.ceil(0.38 * picHeight);
    var sixPoint = Math.ceil(0.01 * picWidth) + ' ' + Math.ceil(0.33 * picHeight);
    var sevenPoint = Math.ceil(0.04 * picWidth) + ' ' + Math.ceil(0.27 * picHeight);
    var points =
        onePoint +
        ',' +
        twoPoint +
        ',' +
        threePoint +
        ',' +
        fourPoint +
        ',' +
        fivePoint +
        ',' +
        sixPoint +
        ',' +
        sevenPoint;

    for (var i = 0; i < newsTitlePic.length; i++) {
        jm(newsTitlePic[i]).find('polygon').attr('points', points);
        jm(newsTitlePic[i]).find('svg').attr('width', picWidth).attr('height', picHeight);
        jm(newsTitlePic[i]).find('image').attr('width', picWidth).attr('height', picHeight);
    }
};

export const fixIphoneMargin = function (options) {
    var moduleId = options.moduleId; //模块id
    var module = jm('#newsList' + moduleId); //模块
    if (module.length == 0) {
        return;
    }
    if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        module
            .find('.J_newsElementsPanel .ne_separatorLine')
            .css('vertical-align', 'middle')
            .css('padding-top', '0.05rem');
        module.find('.J_newsElementsPanel .ne_newsSort').css('vertical-align', 'middle');
        module.find('.J_newsElementsPanel .ne_newsTime').css('vertical-align', 'middle');
    }
};
