import { getVideoDialog } from '@/api/video';
import { getVideoResourceHandler } from '@/shared';

/*********** iframeToEmbed Start *********/
//IE10、IE11下优酷等视频网站的iframe元素里的flash元素会遮挡其他DOM元素，此时将<iframe>动态替换为<embed>即可解决此问题
function iframeToEmbed(videoModuleId, urlId, urlDomain) {
    var iframe = $('#' + videoModuleId + ' iframe');
    var embedSrc;

    if (jm.os.ie || navigator.userAgent.toLocaleLowerCase().indexOf('chrome/63.0') > -1) {
        if (urlDomain == 'v.youku.com' || urlDomain == 'player.youku.com') {
            embedSrc = '//player.youku.com/player.php/sid/' + urlId + '/v.swf';
        } else if (urlDomain == 'v.qq.com' || urlDomain == 'static.video.qq.com') {
            embedSrc = '//static.video.qq.com/TPout.swf?vid=' + urlId + '&auto=0';
        } else {
            return false;
        }
        var embedDOM =
            "<embed class='mobiVideoOnlineIframe' src='" +
            embedSrc +
            "' allowFullScreen='true' wmode='Opaque' quality='high'  align='middle' allowScriptAccess='always' type='application/x-shockwave-flash'></embed>";
        $(embedDOM).insertBefore(iframe);
        $(iframe).remove(); // node.remove 方法不兼容 ie
    }
    return;
}
/*********** iframetoEmbed End *********/

/* video生成后，attr图片, 可控高度*/
function videoPoster(id) {
    var $video = $('#' + id);

    $video[0].addEventListener('loadedmetadata', function () {
        var videoW = $video.width(),
            videoMaxW = videoW / (window._htmlFontSize || window.$store.state.htmlFontSize);

        $video
            .css('max-height', '20rem') //强制最大高度，只对高度超出的视频起作用
            .css('max-width', videoMaxW + 'rem');
    });
}

async function videoDialog(moduleId, content, moduleV2) {
    let result = await getVideoDialog(moduleId, content, moduleV2);
    let { success, data } = result;
    if (success) {
        let bodyObj = document.getElementById('g_body');
        let { t, vs = {} } = content;
        data = {
            ...data,
            moduleId,
            videoSrcType: t,
            videoSetting: vs,
        };
        if ($('#videoDialog').length > 0) {
            $('#videoDialog').remove();
        }
        let videoDialogHtml = getVideoDialogHtml(data);
        let videoDialog = `<div class='videoDialog' id='videoDialog'>
            <div class="cancelVideo faisco-icons-"></div>
            ${videoDialogHtml}
        </div>`;

        const isJzCdnFlowLimit = window.$store.state.app.isJzCdnFlowLimit;
        if (data.videoSrcType === 0 && isJzCdnFlowLimit) {
            const VideoResourceHandler = getVideoResourceHandler();
            VideoResourceHandler.delegate(videoDialog);
        }

        $('#g_body').append(videoDialog);
        $('#videoDialog .cancelVideo')
            .off('click.cancel')
            .on('click.cancel', function () {
                cancelVideoDialog();
            });
        bodyObj.style.overflowY = 'hidden';
    }
}

function getVideoDialogHtml({
    isFrame,
    isNoMainDomainMp4,
    showTipForHttp,
    urlId,
    videoNetUrl, // 普通iframe链接
    videoPicturePath, // 头图
    videoSrc, // 视频地址
    videoTypeStr, // 主流视频类型（优酷、qq、爱奇艺）
    videoSrcType, // 视频类型
    videoSetting,
    moduleId,
}) {
    let dialogHtml = '';
    if (videoSrcType == 0) {
        let isAutoStart = videoSetting.as || false,
            isLoop = videoSetting.ls || false;

        const src = ` src=${videoSrc} `;

        dialogHtml = `
            <div class='mobiVideoDialog' id='video${moduleId}'>
                <video id='video${moduleId}Tag'
                    style='object-fit:scale-down;height:9rem;'
                    class='mobiVideo'
                    poster='${videoPicturePath}'  controls ${isAutoStart ? "autoplay='true'" : ''} ${
            isLoop ? "loop='true'" : ''
        }
                    ${src}
                >
                    抱歉，您的浏览器不能查看该视频。
                </video>
            </div>
        `;
    } else {
        let httpTipHtml = `<div class='moduleHttpTip'>在线视频存在非HTTPS链接，该链接无法正常加载显示，请替换成HTTPS链接或使用非HTTPS方式访问</div>`;
        dialogHtml = `
            <div class='mobiVideoOnline mobiVideoDialogOl' id='video${moduleId}'>
                ${showTipForHttp ? httpTipHtml : ''}
                ${getOutsideVideo({
                    isFrame,
                    isNoMainDomainMp4,
                    videoSrc,
                    videoTypeStr,
                    urlId,
                    videoPicturePath,
                    moduleId,
                    videoNetUrl,
                })}
            </div>
        `;
    }

    return dialogHtml;
}

function getOutsideVideo({
    isFrame,
    isNoMainDomainMp4,
    videoSrc,
    videoTypeStr,
    urlId,
    videoPicturePath,
    moduleId,
    videoNetUrl,
}) {
    let videoHtml = '';
    if (isFrame) {
        videoHtml = `<iframe frameborder='0'  src='${videoNetUrl}' allowfullscreen='true' style='width:100%'></iframe>`;
    } else if (isNoMainDomainMp4) {
        videoHtml = `
            <video
                id='new_video_${moduleId}'
                src='${videoSrc}'
                data-original='${videoSrc}'
                class='video-js vjs-default-skin vjs-big-play-centered'
                controls preload='meta'
                data-setup='{
                    controlBar:{
                        volumeMenuButton:{inline: false},
                        currentTimeDisplay:true,
                        timeDivider:true,
                        durationDisplay:true,
                        remainingTimeDisplay:false
                    }
                }'
            ></video>
        `;
    } else {
        let frameSrc = '';
        switch (videoTypeStr) {
            case 'vYouku':
            case 'playerYouku':
                frameSrc = `//player.youku.com/embed/${urlId}`;
                break;

            case 'qq':
                frameSrc = `//v.qq.com/iframe/player.html?vid=${urlId}&tiny=0&auto=0' `;
                break;

            case 'iqiyi':
                frameSrc = `urlId`;
                break;
        }
        if (frameSrc) {
            videoHtml = `<iframe class='mobiVideoOnlineIframe' frameborder='0' src='${frameSrc}' allowfullscreen></iframe>`;
        } else {
            videoHtml = `
                <video id='video${moduleId}Tag' style='object-fit:scale-down;' class='mobiVideo' poster='${videoPicturePath}'  controls>
                    <source src='${videoSrc}' type='video/mp4'>
                    抱歉，您的浏览器不能查看该视频。
                </video>
            `;
        }
    }

    return videoHtml;
}

function cancelVideoDialog() {
    $('#videoDialog .cancelVideo').off('click.cancel');
    $('#videoDialog').remove();
    var bodyObj = document.getElementById('g_body');
    bodyObj.style.overflowY = '';
}

function bindEvent(context) {
    var content = context.module.content;
    if (!content.vs.nt) {
        if (content.vs.iu != '') {
            if (content.t === 0) {
                videoPoster('video' + context.id + 'Tag', content.vs.pi);
            } else {
                //IE10、IE11下iframe里的flash元素会遮挡其他元素，此时要使用JS将<iframe>动态替换为<embed>
                if (context.module.content.needTurnFrame) {
                    iframeToEmbed('video' + context.id, content.urlId, content.urlDomain);
                }
            }
        }
    }
}

export { videoDialog, bindEvent };
