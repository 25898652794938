<template>
    <div :class="['p_newsgroup__wrap', `${isShowPanel ? 'p_newsgroup__wrap-show' : ''}`]">
        <!-- header -->
        <div class="p_newsgroup__header">
            <span class="p_newsgroup__close p_newsgroup__gClose" @click="close"></span>
            <span class="p_newsgroup__title">文章分类</span>
        </div>
        <!-- 一级 -->
        <div v-if="oneList.length" ref="slider" class="p_newsgroup__sliderwrap">
            <div class="p_newsgroup__sliderlist">
                <span
                    v-for="(item, index) in oneList"
                    :key="item.id"
                    :class="['p_newsgroup__slideitem', `${pageStartX == index ? 'p_newsgroup__slideitem-active' : ''}`]"
                    :style="getThemeColor(index)"
                    @click.stop="changeCatelog(item, index)"
                >
                    {{ item.name }}
                </span>
            </div>
        </div>
        <!-- 多级 -->
        <div class="p_newsgroup__listwrap">
            <div v-if="!info.group.isGroup" class="p_newsgroup__listitem p_newsgroup__allitem">
                <a class="p_newsgroup__ellipsis" :href="info.group.href">全部内容</a>
                <div class="p_newsgroup__split"></div>
            </div>
            <div v-for="item in info.group.children" :key="item.id" class="p_newsgroup__listitem">
                <a class="p_newsgroup__ellipsis" :href="item.href">{{ item.name }}</a>
                <div v-if="item.children && item.children.length" class="p_newsgroup__children">
                    <a
                        v-for="child in item.children"
                        :key="child.id"
                        :href="child.href"
                        class="p_newsgroup_child p_newsgroup__ellipsis"
                    >
                        {{ child.name }}
                    </a>
                </div>
                <div class="p_newsgroup__split"></div>
            </div>
        </div>
    </div>
</template>
<script>
import { setCtrlStyleCssList } from '@/shared/style';
export default {
    name: 'NewsGroupPanel',
    props: ['info'],
    data() {
        return {
            manageMode: VITE_APP_MODE !== 'visitor',
        };
    },
    computed: {
        pageStartX: {
            get() {
                return this.info.pg_startX;
            },
            set(val) {
                this.info.pg_startX = val;
            },
        },
        isShowPanel() {
            return this.info.isShowPanel || false;
        },
        oneList() {
            return this.info.oneList || [];
        },
        themeColor() {
            return this.info.openThemeV3 ? Fai.top._themeColor : Fai.top._colorFulThemeColor;
        },
    },
    watch: {
        ['info.pg_startX']() {
            this.pageStartX = this.info.pg_startX;
            this.initScrollEvent();
        },
    },
    mounted() {
        if (VITE_APP_MODE !== 'visitor') {
            setCtrlStyleCssList('mobiFoldStyleCss', '', [
                {
                    cls: '.p_newsgroup__sliderlist .p_newsgroup__slideitem-active',
                    key: 'color',
                    value: this.themeColor,
                },
            ]);
        }
        this.initScrollEvent();
    },
    methods: {
        initScrollEvent() {
            setTimeout(() => {
                let targetLeft = 0;
                let _this = this;
                jm('.p_newsgroup__slideitem').each(function (index, dom) {
                    if (index < _this.pageStartX) {
                        targetLeft += $(dom).outerWidth();
                    }
                });
                new iScroll(this.$refs.slider, {
                    x: -targetLeft,
                    scrollX: true,
                    scrollY: false,
                    mouseWheel: true,
                    remarkXCoordinate: true,
                    onBeforeScrollStart: null,
                    onScrollMove: null,
                    onBeforeScrollEnd: null,
                });
            });
        },
        close() {
            this.$emit('on-close');
        },
        changeCatelog(item = {}, index) {
            this.info.group = item;
            this.pageStartX = index;
        },
        getThemeColor(index) {
            if (index !== this.pageStartX || VITE_APP_MODE !== 'visitor') {
                return {};
            }
            return {
                color: this.themeColor,
            };
        },
    },
};
</script>
<style lang="scss">
.p_newsgroup__wrap {
    position: fixed;
    left: 100%;
    top: 0;
    width: 100%;
    height: 100%;
    transition: 0.5s;
    background: #fff;
    z-index: 109;
    .p_newsgroup__gClose {
        font-family: icomoon !important;
        font-style: normal;
        font-weight: normal;
        font-feature-settings: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        font-size: 1.6rem;
        cursor: pointer;
    }
    .p_newsgroup__gClose:before {
        font-size: 1.2rem;
        content: '\e60e';
        display: table-cell;
        vertical-align: middle;
    }
}
.p_newsgroup__wrap-show {
    left: 0;
}
.p_newsgroup__header {
    position: relative;
    width: 100%;
    height: 2.275rem;
    line-height: 2.275rem;
    border-bottom: 0.025rem solid #eee;
    text-align: center;
}
.p_newsgroup__close {
    position: absolute;
    left: 0.3rem;
    top: 50%;
    color: #666;
    transform: translateY(-50%);
}
.p_newsgroup__title {
    font-size: 0.8rem;
    color: #333333;
    font-weight: bold;
}
.p_newsgroup__sliderwrap {
    position: relative;
    cursor: pointer;
    border-bottom: 0.025rem solid #eee;
}
.p_newsgroup__sliderlist {
    display: inline-block;
    height: 100%;
    line-height: 2.125rem;
    height: 2.125rem;
    white-space: nowrap;
}
.p_newsgroup__slideitem {
    display: inline-block;
    padding: 0 0.375rem;
    font-size: 0.6rem;
    font-weight: 500;
    color: #333;
    max-width: 14rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: normal;
    vertical-align: middle;
}
.p_newsgroup__listwrap {
    overflow: hidden;
    overflow-y: scroll;
    padding: 0 1.25rem 0;
    height: calc(100% - 4.45rem);
    box-sizing: border-box;
    &::-webkit-scrollbar {
        width: 0;
    }
}
.p_newsgroup__listitem {
    width: 100%;
    color: #333;
    font-weight: 500;
    font-size: 0.6rem;
    cursor: pointer;
    padding-top: 0.675rem;
}
.p_newsgroup__split {
    margin-top: 0.4rem;
    border-bottom: 0.025rem solid #eee;
}
.p_newsgroup__ellipsis {
    display: inline-block;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #333;
}
.p_newsgroup__children {
    display: flex;
    flex-wrap: wrap;
    margin-top: 0.125rem;
    .p_newsgroup_child:nth-child(even) {
        padding-left: 0.75rem;
    }
}
.p_newsgroup_child {
    display: inline-block;
    width: 50%;
    box-sizing: border-box;
    color: #666;
    font-weight: 400;
    font-size: 0.5rem;
    padding-top: 0.575rem;
}
.p_newsgroup__allitem {
    padding-top: 0.65rem;
}
</style>
