import { getPopupZoneModule } from '@/api/popup-zone';

import { ing, checkBit, fkEval } from '@/shared/fai';
import { initModuleAttr } from '@/store/util';
import { initHoverTabEvent, createHoverTabDom, destroyHoverTab } from './hoverTab';
import { EDIT, PREVIEW, READ_ONLY } from './constant';
import { setCtrlStyleCssList } from '@/shared/style';
import { initHistoryModules } from './util';
import { logDog } from '@/shared/log';
import { MODULE_STYLE } from '@/def/module';
import { addFontFaceToHead2 } from '@/shared/fontFace';
import { updatePopupThemeModules } from '@/features/global-mobi-function/methods/theme-module';

function execScript(moduleInfo = []) {
    let updateThemeModule = [];
    let children = moduleInfo.children || [];
    children.forEach((module) => {
        let { style } = module;
        let { fontList = [] } = module.content;
        if (style == MODULE_STYLE.FLOAT_BTN || style == MODULE_STYLE.SIMPLE_TEXT) {
            addFontFaceToHead2(fontList);
        }
        if (style == MODULE_STYLE.FLOAT_BTN) {
            let border = module.content.b || {};
            let shadow = module.content.sh || {};
            let contentLink = module.pattern.cl || {};
            if (contentLink.ftc || border.btn || shadow.stc) {
                updateThemeModule.push(module);
            }
        }
        if (style == MODULE_STYLE.SIMPLE_TEXT) {
            let themeColorCount = module.content.tcc;
            if (themeColorCount) {
                updateThemeModule.push(module);
            }
        }
    });

    updatePopupThemeModules(updateThemeModule);
}

class PopupZone {
    constructor(moduleId, option = {}) {
        this.moduleId = moduleId;
        this.id = moduleId;
        this.option = option;

        this.inModuleListInfo = []; // 子模块数据
        this.moduleInfo = {}; // 弹窗容器模块数据
        this.enterState = '';

        this.popupIns = null;
        this.cashChangeNumber = window._changeStyleNum; // 默认跟全体变化一样
        this.clickToClose = false;
        if (VITE_APP_MODE !== 'visitor') {
            window.$store.commit('popupZone/updatePopupZoneId', moduleId);
        }
    }

    // 初始化添加数据以及vuex数据
    initCreateData(moduleInfo, inModuleAttr = {}, inZoneModuleInfoList = []) {
        this.moduleInfo = moduleInfo;
        this.inModuleListInfo = inZoneModuleInfoList;
        execScript(moduleInfo);
        this._commitPopupZoneData(moduleInfo, inModuleAttr, inZoneModuleInfoList);
    }

    // 编辑
    async edit(create = false) {
        this.enterState = EDIT;
        window.$store.dispatch('editMode/enterEditMode', { isPopupZone: true });
        window.$store.dispatch('manage/moduleAdd/changeModuleAddInfo', {
            modulePosition: 'inPopup',
            inContainerId: this.moduleId,
            containerStyle: MODULE_STYLE.POPUP_ZONE,
        });
        this.cashChangeNumber = window._changeStyleNum;
        // 更新节点
        this.destoryVueDom();
        if (!create) {
            let data = await this._initDataByModuleId();
            if (!data.success) {
                return;
            }
        }
        this._stateClassDeal();
        this._cashDocumentScroll();
        this._goEditDomDeal(); // 编辑态dom
        this._initInModuleEdit(); // 子模块编辑事件
        this._initAddModuleDragable(); // 弹窗sortable
        this._dealAddModuleTip(); // “拖拽添加”提醒
        this._initCloseBtnEvent(); // “关闭按钮”
        this.interceptClickEvent();
    }

    // 退出编辑
    exitEdit() {
        window.$store.dispatch('editMode/exitEditMode');
        destroyHoverTab(); // 销毁hovertab
        this._bindClikckMaskToClose();
        this._removePopupZoneExtraEditor();
        this.destoryVueDom();
        logDog(200646, 12);
    }

    // 只读模式 - 访客态
    async readOnly() {
        this.enterState = READ_ONLY;
        let data = await this._initDataByModuleId();
        if (data.success) {
            this._initDom();
            this._initCloseBtnEvent();
            this._initClickMaskToClose();
            this.bindBeforeUnload();
        }
    }

    // 保存
    save() {}

    select(moduleId) {
        this.moduleId = moduleId;
        this.destroy();
        this.preview();
    }

    // 取消
    async cancel() {
        if (!this._popupZoneHasChange()) return;
        if (!confirm('取消后不会保存修改的内容，确定“取消”？')) return;
        // 关闭面板
        // Vue.prototype.$designer && Vue.prototype.$designer.close();
        this._removePopupZoneExtraEditor();
        // this.destoryVueDom();
        // 移除节点
        // $(`#module${this.moduleId}`).remove();
        // 重置数据
        window.$store.commit('editMode/resetModule');
        window.$store.commit('editMode/resetDeleteModule');
        window.$store.commit('editMode/resetSortModule');
        window.$store.dispatch('editMode/deactiveBtn');
        window.$store.commit('editMode/resetHideModules');
        // 更新 currentPageModuleIds，移除弹窗里的模块id
        let childrenIdList = this.moduleInfo.children.map((item) => item.id);
        window.$store.commit(
            'loadCurrentPageModuleIds',
            window.$store.state.currentPageModuleIds.filter((id) => {
                return !childrenIdList.includes(id);
            })
        );
        // 重置数据
        this._initDataByModuleId();

        // 取消后回到原先编辑前
        // this.edit();
    }

    // 预览模式 - 管理态
    async preview() {
        this.enterState = PREVIEW;
        this.destoryVueDom();
        let data = await this._initDataByModuleId();
        if (data.success) {
            this._initDom();
            this._initCloseBtnEvent();
            this._stateClassDeal();
            this._initHoverTab();
            this._initClickMaskToClose();
            this._previewExtDeal();
            this.bindBeforeUnload();
        }
    }

    destoryVueDom() {
        this.popupIns && this.popupIns.$destroy && this.popupIns.$destroy();
        // 移除节点
        $(`#module${this.moduleId}`).remove();
        $('.jz_popupZone_wrap').remove();
    }

    // 销毁
    destroy() {
        window.$store.commit('editMode/resetDeleteModule');
        window.$store.commit('editMode/resetModule');
        window.$store.commit('editMode/resetSortModule');
        this.destoryVueDom();
        $('#g_body').removeClass('jz_popupZoneShow');
        $('html').removeClass('jz_popupZoneShow2');
        if (this.enterState == PREVIEW) {
            destroyHoverTab();
        }
    }

    // 关闭
    close() {
        if (VITE_APP_MODE !== 'visitor') {
            if (this.enterState == 'edit') return;
            this._stateClassDeal();
        }
        this.destroy();
    }

    _commitPopupZoneData(moduleInfo, inModuleAttr = {}, inZoneModuleInfoList = []) {
        let moduleAttrMap = {};
        // 初始化孩子模块的 moduleAttr 等
        moduleInfo.children.forEach((module) => {
            let id = module.id;
            let moduleAttr = inModuleAttr[`module${id}`] || {};
            Object.assign(moduleAttr, initModuleAttr(module.style));

            module.allAttrV3 = inModuleAttr[`module${id}`] || {};
            module.moduleAttr = moduleAttr;
            moduleAttrMap[`module${id}`] = moduleAttr;
        });

        let childrenIdList = moduleInfo.children.map((child) => child.id);
        let hiddenIdList = inZoneModuleInfoList
            .filter((item) => !childrenIdList.includes(item.id))
            .map((item) => item.id);

        let historyModules = initHistoryModules(moduleInfo, inZoneModuleInfoList);
        window.$store.commit('addPopupZoneModule', [moduleInfo, ...moduleInfo.children]);
        if (VITE_APP_MODE !== 'visitor') {
            window.$store.commit('manage/addPopupZoneModuleAttr', moduleAttrMap);
            window.$store.dispatch('editMode/loadModules', historyModules);
            window.$store.commit('editMode/addHideModule', hiddenIdList);
        }

        window.$store.commit(
            'editMode/initSortModules',
            moduleInfo.children.map((child) => child.id)
        );
    }

    async _initDataByModuleId() {
        let paramsOption = {
            _fresh: false,
            _colId: Fai.top._pageId,
            _extId: Fai.top._extId,
            popupZoneId: this.moduleId,
            manageMode: VITE_APP_MODE !== 'visitor',
            onlyLoadVisitScript: this.enterState == PREVIEW || this.enterState == READ_ONLY,
        };
        if (this.option.lType) {
            paramsOption.lType = this.option.lType;
        }
        if (this.option.lName) {
            paramsOption.lName = this.option.lName;
        }
        try {
            let data = await getPopupZoneModule(paramsOption);
            let { success, msg } = data;
            if (success) {
                let { inModuleAttr, moduleInfo = {}, inZoneModuleInfoList, styleScripts = '' } = data.rtInfo || {};
                window.$store.commit('popupZone/updatePopupZoneRenderCount');
                this._commitPopupZoneData(moduleInfo, inModuleAttr, inZoneModuleInfoList);
                this.moduleInfo = moduleInfo;
                this.inZoneModuleInfoList = inZoneModuleInfoList;
                this.clickToClose = !checkBit(moduleInfo.flag, 0x1);
                if (styleScripts != '') {
                    fkEval(styleScripts);
                }
                execScript(moduleInfo);
                return Promise.resolve({ success, msg });
            } else {
                ing(msg, true);
                return Promise.reject({ success, msg });
            }
        } catch (e) {
            ing('系统异常，请稍候重试', true);
        }
    }

    // 初始化节点
    _initDom() {
        let html = `
        <div class='jz_popupZone_wrap'>
            <div class='jz_popupZone_content'>
            <div id='popupZoneContainer'></div>
            </div>
        </div>
        `;
        $('#g_web').append(html);
        $('#g_body').addClass('jz_popupZoneShow');
        $('html').addClass('jz_popupZoneShow2');

        this.popupIns = window.Vue.prototype.$createPopupZone(this.moduleId, '#popupZoneContainer', window.$store);
    }
    // 初始化关闭按钮
    _initCloseBtnEvent() {
        var closeBtnId = this.moduleInfo.content.cmi;
        $('#module' + this.moduleId)
            .find('#module' + closeBtnId)
            .removeClass('popupZoneCloseBtn')
            .addClass('popupZoneCloseBtn');
    }
    // 初始化点击遮罩关闭事件
    _initClickMaskToClose() {
        let _this = this;
        var clickMaskToClose = !checkBit(this.moduleInfo.flag, 0x1);
        var $jz_popupZone_wrap = jm('.jz_popupZone_wrap');

        $jz_popupZone_wrap.off('click.close');

        if (!clickMaskToClose) return;

        $jz_popupZone_wrap.on('click.close', function (e) {
            var target = $(e.target);

            if (!target.hasClass('J_formPopupZoneContent') && target.closest('.J_formPopupZoneContent').length > 0)
                return; // 避免通过冒泡关闭弹层
            _this.close();
        });
    }

    // 编辑条
    _initHoverTab() {
        createHoverTabDom();
        initHoverTabEvent(this);
    }

    // 预览态其他处理
    _previewExtDeal() {
        // 清除右键点击事件
        $('.jz_popupZone_wrap')
            .find('.form')
            .each(function (index, el) {
                Fai.top.$(el).contextmenu(false);
            });
    }

    // class 标明状态
    _stateClassDeal() {
        var $g_body = $('#g_body'),
            $mobiReviewPage = $('#preview'),
            $mobiStyleDesign = $('#mobiStyleDesign');

        $g_body.removeClass('jz_popupZoneEdit jz_popupZonePreview');
        $mobiReviewPage.removeClass('jz_popupZoneEdit1 jz_popupZonePreview1');
        $mobiStyleDesign.removeClass('jz_popupZoneEdit2 jz_popupZonePreview2');

        if (this.enterState == PREVIEW) {
            $g_body.addClass('jz_popupZonePreview');
            $mobiReviewPage.addClass('jz_popupZonePreview');
            $mobiStyleDesign.addClass('jz_popupZonePreview2');
        } else if (this.enterState == EDIT) {
            $g_body.addClass('jz_popupZoneEdit');
            $mobiReviewPage.addClass('jz_popupZoneEdit1');
            $mobiStyleDesign.addClass('jz_popupZoneEdit2');
            $(`#module${this.moduleId}`).addClass('jz_popupZone_module');
        }
    }

    _bindClikckMaskToClose() {
        let clickMaskToClose = !checkBit(this.moduleInfo.flag, 0x1);
        let $jz_popupZone_wrap = $('.jz_popupZone_wrap');

        let This = this;

        $jz_popupZone_wrap.off('click.close');

        if (!clickMaskToClose) return;

        $jz_popupZone_wrap.on('click.close', function (e) {
            var target = $(e.target);

            if (!target.hasClass('J_formPopupZoneContent') && target.closest('.J_formPopupZoneContent').length > 0)
                return; // 避免通过冒泡关闭弹层
            This.close();
        });
    }

    // 编辑 - 处理编辑节点
    _goEditDomDeal() {
        var closeModuleId = this.moduleInfo && this.moduleInfo.content.cmi;
        $('.jz_popupZone_wrap').remove();

        this._initEditPopupZoneDom();

        setCtrlStyleCssList(
            'jz_popupZoneModuleStyle',
            'fkEditor-toolBar-' + closeModuleId,
            [
                { cls: '.tool-linkNew', key: 'display', value: 'none' },
                { cls: '.tool-deleteNew', key: 'display', value: 'none' },
            ],
            null,
            window
        );
        $('.toolbarArea').hide();
        $('.popupZoneHoverEditTab').remove(); // hover工具栏去掉，避免从其他入口进入编辑态时没删掉（如导航）
    }

    // 编辑 - 处理子模块事件
    _initInModuleEdit() {
        // fkEval.call(window, this.script);
    }

    // 编辑 - 弹窗添加 sortable 类
    _initAddModuleDragable() {
        let moduleEl = $(`#module${this.moduleId}`);
        if (moduleEl.find('J_popupZoneSortContainer').length < 1) {
            let dom = `<div moduleId='${this.moduleId}' class='J_popupZoneSortContainer fk-popupZoneSortContainer'></div>`;
            moduleEl.append(dom);
        }
    }
    // 编辑 - 记录滚动位置
    _cashDocumentScroll() {
        this.documentScrollY = window.scrollY;
    }
    // 编辑 - 空白弹窗tips
    // 添加模块tip
    _dealAddModuleTip() {
        let module = $('#module' + this.moduleId),
            html = '';

        let forms = Array.from(module.find('.form'));
        forms = forms.filter((form) => {
            return !$(form).find('.f-fonticon').length;
        });
        let hasModuleShow = forms.some((form) => {
            let display = $(form).css('display');
            return display != 'none';
        });

        if (hasModuleShow) {
            module.find('.J_addContent').remove();
            return;
        }

        html += "<div class='J_addContent popupAddContent'>";
        html += '<span>拖动模块到这里<span>';
        html += '</div>';

        $(html).appendTo(module);
    }

    // 编辑 - 模块展示
    _initEditPopupZoneDom() {
        this.popupIns && this.popupIns.$destroy && this.popupIns.$destroy();

        let html = `<div id="popupZone${this.moduleId}"></div>`;
        $('#webModuleContainer').append(html);
        this.popupIns = Vue.prototype.$createPopupZone(this.moduleId, `#popupZone${this.moduleId}`);
        $(`#module${this.moduleId}`).addClass('jz_popupZone_module');
    }

    // 退出编辑 - 移除多余的editor工具条
    _removePopupZoneExtraEditor() {
        $('#module' + this.moduleId)
            .find('.form')
            .each(function (index, el) {
                var inModuleId = $(el).attr('id').replace('module', '');
                $('#fkEditor-toolBar-' + inModuleId).remove();
            });
    }

    // 弹窗内容是否发生过变化
    _popupZoneHasChange() {
        // return this.cashChangeNum < window._changeStyleNum;
        return window.$store.state.editMode.isActiveSaveBtn;
    }

    editChange(val) {
        if (val) {
            window.$store.dispatch('editMode/activeBtn');
        } else {
            window.$store.dispatch('editMode/deactiveBtn');
        }
    }

    bindBeforeUnload() {
        if (VITE_APP_MODE !== 'visitor') return;
        var module = $('#module' + this.id);
        let _this = this;
        module
            .find('a')
            .off('click.zone')
            .on('click.zone', function () {
                var href = $(this).attr('href');

                if (
                    !href ||
                    href == '' ||
                    href == 'javascript:void(0)' ||
                    href == 'javascript: void(0)' ||
                    href == 'javascript:;'
                ) {
                    return;
                }

                _this.close();
            });
    }

    interceptClickEvent() {
        $('.jz_popupZoneWrap').delegate(
            'a[href]:not([href*="mailto\\:"],[href*="sms\\:"], [href="\\#"], [href*="tel\\:"],[href*="javascript\\:\\;"], [onclick*="JZ.setUrlArgToCookie"], [_reqargs*="{"])',
            'click',
            function () {
                var hrefStr = $(this).attr('href');
                if (hrefStr.indexOf('Mobi.showBookingPanel') > 0) return;

                var pZone = Mobi.getPopupZone();
                if (pZone) {
                    if (pZone.enterState == EDIT) {
                        Fai.ing('弹窗编辑态不支持跳转链接', true);
                        return false;
                    }
                }
            }
        );
    }
}

export default PopupZone;
