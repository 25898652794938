import { MobiModule } from '../Module';
import { MODULE_STYLE } from '@/def/module';
import ProductListView from './module/visitor.vue';

class _ProductList extends MobiModule {
    constructor(options = {}) {
        super(MODULE_STYLE.PRODUCT_LIST, options);
    }

    render() {
        return ProductListView;
    }
}

export const ProductList = new _ProductList();
