import { triggerServiceNumber } from '@/modules/comm';
import { logDog } from '@/shared/log';
export function consultImmediatelyBtnClick(btnClass, usedAdvisoryMethod, advisoryPhone, advisoryQrCode, popupId) {
    jm('#' + btnClass)
        .off('click.consultBtn')
        .on('click.consultBtn', function () {
            logDog(201121, 5);
            if (usedAdvisoryMethod == 0) {
                triggerServiceNumber(1, advisoryPhone);
                return false;
            } else if (usedAdvisoryMethod == 1) {
                consultImmediately.render(advisoryQrCode);
                return false;
            } else if (usedAdvisoryMethod == 2) {
                Mobi._popupZone.open(popupId);
                return false;
            }
        });
}

const consultImmediately = {
    component: {
        el: '#weChatConsult',
        template: `
            <div id="weChatConsult" class="weChatConsult">
                <div class="weChatConsultBg" @click="closeHandle"></div>
                <div class="weChatConsultBox consultWechatQrCodeBounceIn">
                    <svg class="weChatConsultCloseBtn" @click="closeHandle"><use xlink:href="#jzm-v26" /></svg>
                    <img class="weChatConsultImg" :src="imgSrc">
                    <div class="weChatConsultTextBox">
                        <span class="centerText">{{scanWeChatCodeTip}}</span>
                    </div>
                </div>
            </div>
         `,
        computed: {},
        methods: {
            closeHandle() {
                consultImmediately.destroy();
            },
        },
        mounted() {},
    },
    render(imgSrc) {
        if (this.vm) {
            this.vm.imgSrc = imgSrc;
        }
        $("<div id='weChatConsult'></div>").appendTo($('#g_body'));
        this.component.data = {
            imgSrc,
            scanWeChatCodeTip: LS.scanWeChatCodeTip,
        };
        this.vm = new Vue(this.component);
    },
    destroy() {
        if (this.vm) {
            $(this.vm.$el).remove();
            this.vm && this.vm.$destroy();
            this.vm = null;
        }
    },
};
