var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.fileItem.isImage
    ? _c(
        "div",
        {
          staticClass:
            "m_custom_search_result_result_value m_custom_search_result_result_photo_wrap",
        },
        [
          _c("div", {
            staticClass: "m_custom_search_result_result_photo",
            class: _vm.imgClass,
            style: _vm.imgStyle,
            attrs: {
              "data-path": _vm.fileItem.path,
              "data-name": _vm.fileItem.fileName,
              "data-download": _vm.fileItem.downloadPath,
              "data-id": _vm.fileItem.id,
              "data-picid": _vm.fileItem.value,
            },
            on: { click: _vm.imgClick },
          }),
        ]
      )
    : _vm.fileItem.isPdf
    ? _c(
        "a",
        {
          staticClass: "m_custom_search_result_result_value",
          attrs: {
            hidefocus: "true",
            rel: "nofollow",
            target: "_blank",
            href: _vm.fileItem.path,
          },
        },
        [_vm._v("\n    " + _vm._s(_vm.fileItem.fileName) + "\n")]
      )
    : !_vm.fileItem.isImage && !_vm.fileItem.isPdf
    ? _c(
        "a",
        {
          staticClass: "m_custom_search_result_result_value",
          attrs: {
            hidefocus: "true",
            rel: "nofollow",
            target: "_blank",
            href: _vm.fileItem.downloadPath,
          },
        },
        [_vm._v("\n    " + _vm._s(_vm.fileItem.fileName) + "\n")]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }