var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "m_vote_item", class: _vm.typeClass }, [
    _c("div", { staticClass: "vote_item_name", class: _vm.questionWrapClass }, [
      _c(
        "span",
        {
          staticClass: "vote_item_name_text g_vote_question",
          style: _vm.vote_question_style,
        },
        [_vm._v(_vm._s(_vm.voteItem.question))]
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "separator g_dashed" }),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "vote_item_content g_vote_Item",
        style: _vm.vote_item_style,
      },
      [_vm._t("default")],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }