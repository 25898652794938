<template>
    <div class="hoverContainer">
        <HoverItem
            v-for="(item, index) in contentList"
            :key="item.picId"
            :photo-item="item"
            :module-id="module.id"
            :link-type="linkType"
            :img-href="imgHref(item.jumpUrl)"
            :module-content="module.content"
            :loading-path="options.loadingPath"
            :no-lazy-load="noLazyLoad"
            :img-index="index + 1"
            :slides-index="item.index"
            :list-length="contentList.length"
        />
    </div>
</template>
<script>
import { optionsMixins } from '../mixins/optionsMixins';
import HoverItem from './hover-item.vue';
export default {
    name: 'HoverList',
    components: {
        HoverItem,
    },
    mixins: [optionsMixins],
    inject: ['module', 'options'],
    props: {
        contentList: {
            type: Array,
            default() {
                return [];
            },
        },
        noLazyLoad: {
            type: Boolean,
            default: false,
        },
    },
};
</script>
<style>
.hoverContainer {
    padding: 0 0.75rem;
}
</style>
