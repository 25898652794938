<template>
    <div
        :id="itemId"
        :topClassName="_topInfo.topName"
        :topSwitch="_topInfo.topSwitch"
        :productId="info.id"
        :productName="info.name"
        :title="_title2"
        class="mProductTextForm J_product_item"
    >
        <a :href="info.extInfo.href" :onclick="onclickStr">
            <div class="paramCollection separatorLineClass" :style="separatorLineStyle">
                <div
                    v-if="module.content.showProName"
                    :class="[pClasses, _proNameWrapClass]"
                    :style="productName_style"
                    class="g_link paramName g_productName g_newProductName"
                >
                    <span v-if="info.isTimedAdded" class="g_oldThemeBgColor m_timedAddTag g_main_bgColor_v3"
                        >即将开售</span
                    >
                    <sale-flag v-if="info.extInfo.salePromotionOpen && !info.isTimedAdded" :info="info"></sale-flag>
                    {{ info.name }}
                    <div v-if="manageMode && info.top != 0 && !_productSelect" class="f-productListTopFlag">置顶</div>
                </div>

                <mall-cart
                    v-if="!isNewAddModule && _showMallCart.mallCart2 && module.content.picSetting.picSizeSet == 1"
                    :info="info"
                    :holder="_showMallCartHolder"
                ></mall-cart>
                <prop-panel
                    :info="info"
                    :list="_showPropList"
                    :ext-info="_extInfo"
                    :show-mall-cart="_showMallCart"
                    :text-align-class="textAlignClass"
                    :no-name-wrap="noNameWrap"
                >
                </prop-panel>
            </div>
        </a>
    </div>
</template>

<script>
import productItemMixin from '../../mixins/productItemMixin';
import saleFlag from '../comm/sale-flag.vue';
import propPanel from '../comm/prop-panel.vue';
import mallCart from '../comm/mall-cart.vue';

const manageMode = VITE_APP_MODE !== 'visitor';
export default {
    name: 'TextItem',
    components: { saleFlag, mallCart, propPanel },
    mixins: [productItemMixin],
    inject: ['module', 'options'],
    props: ['info', 'index', 'len'],
    data() {
        return {
            itemId: `mProductTextForm${this.info.id}_module${this.module.id}`,
            manageMode,
        };
    },
    computed: {
        textAlignClass() {
            let textAlign = this.module.content.ta == 0 ? 'textAlign_center' : 'textAlign_left';
            if (!(this.module.extInfo.newUser || this.module.extInfo.isEdit)) {
                textAlign = '';
            }
            return textAlign;
        },
        wrapClass() {
            return this.module.content.nameWrap ? 'paramWrap' : 'paramNowrap';
        },
        noNameWrap() {
            let classes = this.module.content.nameWrap ? 'paramWrap' : 'paramNowrap';
            return classes;
        },
        pClasses() {
            return `${this.wrapClass} ${this.textAlignClass}`;
        },
        productName_style() {
            var style = '';
            if (!manageMode) {
                return style;
            }
            if (this.isNewAddModule) {
                var newProductNameStyle = this.module.content.mhighs.npn;
                if (newProductNameStyle.type == 1) {
                    var fontWeight = newProductNameStyle.nlfb == 1 ? 'bold' : 'normal';
                    var fontStyle = newProductNameStyle.nlfi == 1 ? 'italic' : 'normal';
                    var textDecoration = newProductNameStyle.lfd == 1 ? 'underline' : 'none';
                    style += 'font-size:' + newProductNameStyle.lfs + 'rem;';
                    style += 'line-height:' + newProductNameStyle.nlflh + 'px;';
                    style += 'font-weight:' + fontWeight + ';';
                    style += 'text-decoration:' + textDecoration + ';';
                    style += 'font-style:' + fontStyle + ';';
                    style += 'color:' + newProductNameStyle.lfc + ';';
                }
            } else {
                var productNameStyle = this.module.content.mhighs.pn;
                if (productNameStyle.type == 1) {
                    if (productNameStyle.lfs) {
                        style += 'font-size:' + productNameStyle.lfs + 'rem;';
                    } else {
                        style += 'font-size:12px;';
                    }
                    if (productNameStyle.lfct == 1) {
                        style += 'color:' + productNameStyle.lfc + ';';
                    }
                    if (productNameStyle.lfd == 1) {
                        style += 'text-decoration:underline;';
                    } else {
                        style += 'text-decoration:none;';
                    }
                }
            }
            return style;
        },
        separatorLineStyle() {
            var separatorLineStyleObj = {};
            let separatorLine = this.module.content.separatorLine;
            let separatorLineType = separatorLine.st;
            let separatorLineColor = separatorLine.sc;
            let separatorLineStyle = separatorLine.ss;
            let separatorLineWidth = separatorLine.sw;
            switch (separatorLineStyle) {
                case 0:
                    separatorLineStyle = 'solid';
                    break;
                case 1:
                    separatorLineStyle = 'dotted';
                    break;
                case 2:
                    separatorLineStyle = 'dashed';
                    break;
            }
            if (separatorLineType == 1) {
                separatorLineStyleObj.borderBottomColor = separatorLineColor;
                separatorLineStyleObj.borderBottomStyle = separatorLineStyle;
                separatorLineStyleObj.borderBottomWidth = separatorLineWidth + 'px';
            }
            return separatorLineStyleObj;
        },
    },
};
</script>
<style lang="scss">
.form .isNewAddModule .fk-mProductList32 .mProductTextForm .paramCollection {
    margin: 0.75rem 0.75rem 0;
    padding: 0 0 0.6rem;
}
</style>
