var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: _vm.wrapClasses,
      attrs: {
        id: "newsLine" + _vm.info.id,
        topClassName: _vm._topClassName,
        topSwitch: _vm._topSwitch,
        newsId: _vm.info.id,
        newsName: _vm.info.title,
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "lineBody",
          class: [_vm.isNewAddModuleClass, _vm.shadowWrap, _vm.isOnlyTitle],
          attrs: { id: "lineBody" + _vm.info.id },
        },
        [
          _c(
            "a",
            {
              staticClass:
                "J_mixNewsStyleImgBox5_link f_mixNewsStyleImgBox5_link picRadiusClass",
              attrs: {
                hidefocus: "true",
                href: _vm._newsUrl,
                target: _vm._jumpTarget,
                onclick: _vm.onclickStr,
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "mixNewsStyleImgBox5",
                  class: [_vm.picTextStyle, _vm.picRadiusBigClass],
                },
                [
                  _c("div", [
                    _c("div", {
                      directives: [
                        {
                          name: "drag-resize",
                          rawName: "v-drag-resize",
                          value: _vm._resizeOptions,
                          expression: "_resizeOptions",
                        },
                      ],
                      staticClass: "picScaleClass J_img_lazyload",
                      class: [_vm.titlePicClass, _vm.picRadiusClass],
                      style: _vm.picStyle,
                      attrs: {
                        id: "newsTitlePic" + _vm.info.id,
                        alt: _vm.altName,
                        "src-original": _vm.info.realPicId
                          ? _vm.info.bigPicPath
                          : "",
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "J_mixNewsStyleTitleContainer5 mixNewsStyleTitleContainer5",
                        style: _vm.contentBg,
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "g_news_title news_title newTitle",
                            class: [_vm.titleClasses, _vm.titleLine],
                            style: _vm.news_title_style,
                          },
                          [
                            _c("div", { class: _vm._topFlagClass }),
                            _vm._v(" "),
                            _vm._topFlagClass != "" && _vm.isNewAddModule
                              ? _c("top-component")
                              : _vm._e(),
                            _vm._v(
                              _vm._s(_vm.info.title) +
                                "\n                        "
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        !_vm.isNewAddModule
                          ? _c("div", { staticClass: "separatorLine" })
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.isNewAddModule
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "mixNewsStyleSummary g_news_abstract",
                                class: _vm.textAlignClasses,
                                style: _vm.news_abstract_style,
                              },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(_vm.info.summary) +
                                    "\n                        "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.isNewAddModule && !_vm.module.content.des
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "mixNewsStyleSummary g_news_abstract newDescriptionLine",
                                class: [
                                  _vm.textAlignClasses,
                                  _vm.descriptionLine,
                                  _vm.isOnlyTitleAndDes,
                                ],
                                style: _vm.news_abstract_style,
                              },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(_vm.info.summary) +
                                    "\n                        "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.showOtherInfo
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "newsElementsPanel g_newsElementsPanel J_newsElementsPanel g_newsInfo",
                                class: _vm.textAlignClasses,
                                on: { click: _vm.otherInfoClick },
                              },
                              [
                                _vm._showComment
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "ne_newsComment faisco-icons-comment",
                                      },
                                      [
                                        !_vm.isNewAddModule
                                          ? _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "newsCommentCount g_newsInfo",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.info.commentCount)
                                                ),
                                              ]
                                            )
                                          : _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "newsCommentCount g_newsInfo",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.info.commentCount
                                                  ) +
                                                    _vm._s(
                                                      _vm.module.extInfo
                                                        .commentText
                                                    )
                                                ),
                                              ]
                                            ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm._showAuthor
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "ne_newsAuthor",
                                        style: _vm.authorStyle,
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "newsAuthorName g_newsInfo",
                                          },
                                          [_vm._v(_vm._s(_vm.info.author))]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                (_vm._showAuthor || _vm._showComment) &&
                                (_vm._showDate || _vm._showSort)
                                  ? _c("div", {
                                      staticClass:
                                        "ne_separatorLine g_ne_separatorLine",
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm._showSort
                                  ? _c(
                                      "div",
                                      { staticClass: "ne_newsSort g_newsInfo" },
                                      [
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(_vm.info.groupName) +
                                            "\n                            "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm._showDate
                                  ? _c(
                                      "div",
                                      { staticClass: "ne_newsTime g_newsInfo" },
                                      [
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(_vm.newsDate) +
                                            "\n                            "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            )
                          : _vm._e(),
                      ]
                    ),
                  ]),
                ]
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }