<template>
    <div :id="'groupList' + moduleId" :class="['first_panel', 'groupList' + moduleId]">
        <GroupBanner :nav-index="navIndex" :no-lazy-load="noLazyLoad"></GroupBanner>
        <div
            v-for="(secondGroupItem, index) in groupMap"
            :id="'sed_' + secondGroupItem.key"
            :key="index"
            class="second_group_panel J_secondGroupPanel"
            :class="panelClasses(index, secondGroupItem)"
        >
            <div
                v-for="(childItem, childIndex) in filterMaxProductGroup(secondGroupItem.data)"
                :key="childIndex"
                class="second_groupitem"
                :class="{ first_item: childIndex == 0 }"
            >
                <a :href="prUrl + '?groupId=' + childItem.id">
                    <div class="J_sedGroupName sed_groupname" :style="sedGroupNameStyle" v-text="childItem.name"></div>
                </a>
                <div class="group_img_wrap clearFix">
                    <div
                        v-for="(thirdItem, thirdIndex) in childItem.thirdGroupList"
                        :key="thirdIndex"
                        class="J_groupItem f-groupImgContainer"
                        :class="secondGroupClass"
                        :data-id="thirdItem.id"
                    >
                        <a
                            v-if="moduleStyle == 5 || moduleStyle == 6"
                            :id="'imgContainer' + thirdItem.id"
                            :href="prUrl + '?groupId=' + thirdItem.id"
                            target="_self"
                            class="J_imgContainer f-imgContainer"
                            @mouseover="grouItemMouseOver($event, thirdItem)"
                            @mouseout="groupItemMouseOut($event)"
                        >
                            <span
                                v-if="
                                    thirdItem.mIcon != '' &&
                                    isFontIconId(thirdItem.mIcon) &&
                                    thirdItem.other &&
                                    thirdItem.other.mIconColor != ''
                                "
                                class="J_itemImage isFontIcon f-itemImg"
                                :class="[thirdItem.iconPath]"
                                :style="fontIconStyle(thirdItem)"
                            >
                            </span>
                            <span
                                v-if="
                                    thirdItem.mIcon != '' &&
                                    isFontIconId(thirdItem.mIcon) &&
                                    thirdItem.other &&
                                    thirdItem.other.mIconColor == ''
                                "
                                class="J_itemImage isFontIcon f-itemImg"
                                :class="[thirdItem.iconPath]"
                                :style="fontIconStyle(thirdItem)"
                            >
                            </span>
                            <span
                                v-if="
                                    thirdItem.mIcon != '' &&
                                    !isFontIconId(thirdItem.mIcon) &&
                                    thirdItem.fileInfo == null
                                "
                                class="J_itemImage notImage faisco-icons-pic"
                                :style="fontIconStyle(thirdItem)"
                            >
                            </span>
                            <div
                                v-if="
                                    thirdItem.mIcon != '' &&
                                    !isFontIconId(thirdItem.mIcon) &&
                                    thirdItem.fileInfo != null
                                "
                                class="J_itemImage f-itemImg J_img_lazyload"
                                :style="imgStyle(thirdItem)"
                                :src-original="thirdItem.iconPath"
                                :data-w="thirdItem.fileInfo.width"
                                :data-h="thirdItem.fileInfo.height"
                            >
                            </div>
                            <div
                                v-if="thirdItem.mIcon == ''"
                                class="J_itemImage notImage f-itemImg J_img_lazyload"
                                :style="defautImgStyle"
                                :src-original="defaultImg"
                                data-w="326"
                                data-h="326"
                            >
                            </div>
                        </a>
                        <a :href="prUrl + '?groupId=' + thirdItem.id" target="_self">
                            <p class="f-itemImgName f-thirdGroupName" :style="thirdGroupNameStyle">{{
                                thirdItem.name
                            }}</p>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import GroupBanner from './group-banner.vue';

import { productGroupBannerUpload, productGroupItemUpload } from '../product-group';
import { changeHref } from '@/features/router/universal';
import { setUrlArgToCookieAndJump } from '@/modules/comm';
export default {
    name: 'ProductPhotoPanel',
    components: {
        GroupBanner,
    },
    inject: ['module', 'options'],
    props: {
        moduleId: {
            type: [String, Number],
            require: true,
            default: '',
        },
        navIndex: {
            type: [Number],
            default: 0,
        },
        prUrl: {
            type: String,
            default: '',
        },
        // secondGroupMap: {
        // 	type: Object,
        // 	default: function() {
        // 		return {}
        // 	}
        // },
        secondGroupMap: {
            type: Array,
            default: function () {
                return [];
            },
        },
        searchId: {
            type: [String, Number],
            require: true,
            default: '',
        },
        searchName: {
            type: [String, Number],
            require: true,
            default: '',
        },
        maxProductGroup: {
            type: Number,
            default: 20,
        },
        moduleStyle: {
            type: Number,
            require: true,
            default: -1,
        },
        resRoot: {
            type: String,
            default: '',
        },
        noLazyLoad: {
            type: Boolean,
        },
    },
    computed: {
        curGroupBanner: function () {
            return this.module.content.ap[this.navIndex] || {};
        },
        curIsDefaultBanner: function () {
            return !this.curGroupBanner || !this.curGroupBanner.pi;
        },
        groupBannerStyle: function () {
            var style = {},
                adPicInfo = this.curGroupBanner;
            // picPath = !!adPicInfo && !!adPicInfo.pi ? adPicInfo.p : ``;

            if (this.module.content.apt == 0 || !adPicInfo.s) {
                style.display = 'none';
            } else {
                if (!this.curIsDefaultBanner) {
                    style.backgroundImage = `url(${this.noLazyLoad ? adPicInfo.p : this.options.loadingPath})`;
                }
                switch (this.module.content.apst) {
                    case 1:
                        style.backgroundSize = 'contain';
                        break;
                    case 2:
                        style.backgroundSize = '100% 100%';
                        break;
                    case 3:
                        style.backgroundSize = 'auto';
                        break;
                }
            }
            return style;
        },
        defaultImg() {
            return this.resRoot + '/image/module/productGroup/defaultImg.jpg';
        },
        defautImgStyle: function () {
            let style = {
                backgroundImage: 'url(' + (this.noLazyLoad ? this.defaultImg : this.options.loadingPath) + ')',
            };

            if (this.moduleStyle == 5 || this.moduleStyle == 6) {
                if (this.module.content.gps.t == 1) {
                    style.width = `${this.module.content.gps.w}rem`;
                    style.paddingTop = `${this.module.content.gps.h}rem`;
                }
            }
            return style;
        },

        groupMap: function () {
            return this.secondGroupMap;
        },
        sedGroupNameStyle: function () {
            if (!(VITE_APP_MODE !== 'visitor')) {
                return;
            }
            let style = {};
            if (this.module.content.s == 5 || this.module.content.s == 6 || this.module.content.s == 7) {
                const textSetting = this.module.content.mhighs.sgn;
                if (textSetting.type == 1) {
                    style.fontSize = textSetting.nlfs + 'rem';
                    style.color = textSetting.nlfc;

                    if (textSetting.nlfi == 1) {
                        style.fontStyle = 'italic';
                    }

                    if (textSetting.nlfb == 1) {
                        style.fontWeight = 'bold';
                    }

                    if (textSetting.nlfd == 1) {
                        style.textDecoration = 'underline';
                    }
                }
            }
            return style;
        },
        thirdGroupNameStyle: function () {
            let style = {};
            if (this.moduleStyle == 5 || this.moduleStyle == 6 || this.module.content.s == 7) {
                // 自定义图片尺寸时，文字宽度固定
                if (this.module.content.gps.t == 1) {
                    style.width = `${this.module.content.gps.w}rem`;
                }
            }

            if (!(VITE_APP_MODE !== 'visitor')) {
                return style;
            }

            // 以下的访客态在 pageFunction.jsp.inc 输出
            if (this.module.content.s == 5 || this.module.content.s == 6 || this.module.content.s == 7) {
                const textSetting = this.module.content.mhighs.tgn;
                if (textSetting.type == 1) {
                    style.fontSize = textSetting.nlfs + 'rem';
                    style.color = textSetting.nlfc;

                    if (textSetting.nlfi == 1) {
                        style.fontStyle = 'italic';
                    }

                    if (textSetting.nlfb == 1) {
                        style.fontWeight = 'bold';
                    }

                    if (textSetting.nlfd == 1) {
                        style.textDecoration = 'underline';
                    }
                }
            }
            return style;
        },

        secondGroupClass() {
            let classList = [];
            if (this.moduleStyle == 6) {
                if (this.module.content.gps.t == 1 && this.module.content.gps.w > 6) {
                    // 超出宽度，减少margin-right过大导致换行
                    classList.push('f-less-margin-groupImgContainer');
                }
            }
            return classList;
        },
    },
    mounted() {},
    methods: {
        filterMaxProductGroup(list) {
            return list.filter((item, i) => i < this.maxProductGroup);
        },
        panelClasses(index, secondGroupItem) {
            var classes = [];
            if (this.module.content.sl == 3 && this.module.content.nt == 1) {
                if (
                    (this.searchName == '' && index == 0) ||
                    (this.searchName != '' && this.searchName == secondGroupItem.key)
                ) {
                    classes.push('selected');
                }
            } else {
                if ((this.searchId == 0 && index == 0) || (this.searchId > 0 && this.searchId == secondGroupItem.key)) {
                    classes.push('selected');
                }
            }
            return classes;
        },
        groupBannerClick: function () {
            if (VITE_APP_MODE !== 'visitor' && Vue.prototype.$designer.checkSaveBar()) {
                return;
            } else if (this.curGroupBanner.i) {
                if (VITE_APP_MODE !== 'visitor' && !!this.curGroupBanner.ji && this.curGroupBanner.ji.t == 103) {
                    Fai.top.Fai.ing(`管理态不支持跳转外部链接，请通过“预览”进行访问`, true);
                } else {
                    if (this.curGroupBanner.ji && this.curGroupBanner.ji.reqArgs) {
                        setUrlArgToCookieAndJump(this.curGroupBanner.h, this.curGroupBanner.ji.reqArgs);
                    } else {
                        changeHref(this.curGroupBanner.h);
                    }
                }
            }
        },
        groupBannerMouseOver: function () {
            if (!(VITE_APP_MODE !== 'visitor')) return;
            const itemJson = [
                {
                    operationText: '编辑产品',
                    className: 'productListEdit',
                    onClick: () => {
                        productGroupBannerUpload(this.module.id, this.navIndex);
                    },
                },
            ];
            const options = { extCls: 'productListEditLayer' };

            Vue.prototype.$layer.addEditLayer($(this.$refs.groupBanner), itemJson, 6, options);
        },
        groupBannerMouseOut: function () {
            if (!(VITE_APP_MODE !== 'visitor')) return;
            Vue.prototype.$layer.removeEditLayer($(this.$refs.groupBanner));
        },
        grouItemMouseOver: function ($event, item) {
            if (!(VITE_APP_MODE !== 'visitor')) return;
            const itemJson = [
                {
                    operationText: '编辑图片',
                    className: 'productListEdit',
                    onClick: () => {
                        productGroupItemUpload(this.module.id, item.id);
                    },
                },
            ];
            const options = { extCls: 'productListEditLayer' };
            Vue.prototype.$layer.addEditLayer($($event.currentTarget), itemJson, 6, options);
        },
        groupItemMouseOut: function ($event) {
            if (!(VITE_APP_MODE !== 'visitor')) return;
            Vue.prototype.$layer.removeEditLayer($($event.currentTarget));
        },
        isFontIconId(iconId) {
            if (!iconId || iconId.length == 0 || iconId.length < 'FontIcon_'.length) {
                return false;
            }

            return (
                iconId.substring(0, 'NewFontIcon_'.length) == 'NewFontIcon_' ||
                iconId.substring(0, 'FontIcon_'.length) == 'FontIcon_'
            );
        },
        imgStyle(childItem) {
            let style = '';
            style += `background-image:url(${this.noLazyLoad ? childItem.iconPath : this.options.loadingPath});`;

            if (this.moduleStyle == 5 || this.moduleStyle == 6) {
                if (this.module.content.gps.t == 1) {
                    style += `width:${this.module.content.gps.w}rem;padding-top:${this.module.content.gps.h}rem;`;
                    style += `font-size:${this.module.content.gps.w}rem;`;
                }

                let backgroundSize;
                switch (this.module.content.gps.s) {
                    case 1:
                        backgroundSize = 'contain';
                        break;
                    case 2:
                        backgroundSize = '100% 100%';
                        break;
                    case 3:
                        backgroundSize = 'auto';
                        break;
                }
                if (backgroundSize) {
                    style += `background-size:${backgroundSize};`;
                }
            }
            return style;
        },
        fontIconStyle(childItem) {
            let style = {};

            if (this.moduleStyle == 5 || this.moduleStyle == 6) {
                style.color = childItem.other && childItem.other.mIconColor;
            }

            if (this.moduleStyle == 5 || this.moduleStyle == 6) {
                if (this.module.content.gps.t == 1) {
                    style.fontSize = `${Math.min(this.module.content.gps.w, this.module.content.gps.h)}rem`;
                    style.width = `${this.module.content.gps.w}rem`;
                    style.height = `${this.module.content.gps.h}rem`;
                }
            }
            return style;
        },
    },
};
</script>
