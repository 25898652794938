import { MobiModule } from '../Module';
import { MODULE_STYLE } from '@/def/module';
import FoldContainerView from './module/visitor.vue';

class _FoldContainer extends MobiModule {
    constructor(options = {}) {
        super(MODULE_STYLE.FOLD, options);
    }

    render() {
        return FoldContainerView;
    }
}

export const FoldContainer = new _FoldContainer();
