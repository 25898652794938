import { MobiModule } from '../Module';
import { MODULE_STYLE } from '@/def/module';
import RichView from './module/rich.vue';

class _Rich extends MobiModule {
    constructor(options = {}) {
        super(MODULE_STYLE.RICH, options);
    }

    render() {
        return RichView;
    }
}

export const Rich = new _Rich();
