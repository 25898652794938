var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "calculationModalFrame",
      attrs: { id: "calculationModalFrame" },
    },
    [
      _c(
        "div",
        {
          staticClass: "calculationModalFrameBg",
          on: { click: _vm.frameBgClickHandler },
        },
        [
          _c(
            "div",
            { staticClass: "content_box consultWechatQrCodeBounceIn" },
            [
              _c(
                "svg",
                { staticClass: "close_btn", on: { click: _vm.closeHandle } },
                [_c("use", { attrs: { "xlink:href": "#jzm-v26" } })]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "result_content" },
                [
                  _vm._l(_vm.calculateReultList, function (item) {
                    return _c("div", { staticClass: "formula_result" }, [
                      _c("div", {
                        staticClass: "formula_result_text",
                        domProps: {
                          innerHTML: _vm._s(_vm.encodeHtml(item.name)),
                        },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "formula_result_money" }, [
                        _vm._v(_vm._s(_vm.formatMoney(item.value))),
                        item.uHide == 0
                          ? _c("span", { staticClass: "formula_result_unit" }, [
                              _vm._v(_vm._s(item.unit)),
                            ])
                          : _vm._e(),
                      ]),
                    ])
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "variable_list" },
                    _vm._l(_vm.variableData, function (item) {
                      return _c("div", { staticClass: "vaiable_item" }, [
                        _c("div", {
                          staticClass: "variable_name",
                          domProps: {
                            innerHTML: _vm._s(_vm.encodeHtml(item.name)),
                          },
                        }),
                        _vm._v(" "),
                        item.type == 0
                          ? _c("div", { staticClass: "variable_value" }, [
                              _vm._v(_vm._s(_vm.formatMoney(item.value))),
                              item.uHide == 0
                                ? _c("span", { staticClass: "variable_unit" }, [
                                    _vm._v(_vm._s(item.unit)),
                                  ])
                                : _vm._e(),
                            ])
                          : _c("div", { staticClass: "variable_value" }, [
                              _vm._v(_vm._s(item.valueKey)),
                            ]),
                      ])
                    }),
                    0
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "result_img",
                      attrs: { href: _vm.imgHref, onclick: _vm.jumpOnclickStr },
                    },
                    [_c("img", { attrs: { src: _vm.imgSrc } })]
                  ),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "result_note_text",
                    domProps: {
                      innerHTML: _vm._s(_vm.encodeHtml(_vm.resultText)),
                    },
                  }),
                ],
                2
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }