<template>
    <div
        :id="'productPicListForm' + info.id + '_module' + module.id"
        :topClassName="_topInfo.topName"
        :topSwitch="_topInfo.topSwitch"
        :productId="info.id"
        :productName="info.name"
        class="productPicListForm J_product_item"
        :title="_title2"
    >
        <a :href="info.extInfo.href" :onclick="onclickStr">
            <div :id="'tableBox' + info.id + '_module' + module.id" class="tableBox picAndTextWrap">
                <div class="tableCell tableCell-30 picSizeClass J_img_wrap" :style="picTextListSize">
                    <div class="fk-productListImgWrap">
                        <div v-if="manageMode && info.top != 0 && !_productSelect" class="f-productListTopFlag"
                            >置顶</div
                        >
                        <sale-flag v-if="info.extInfo.salePromotionOpen" :info="info"></sale-flag>
                        <img
                            v-if="!isNewAddModule"
                            v-drag-resize="_resizeOptions"
                            v-ban-copy
                            :alt="info.name"
                            class="J_img_lazyload style3Img imgBorderRadius picSizeClass"
                            :src-original="_imgSrc"
                            :style="proPhotoStyle"
                            :src="noLazyLoad ? _imgSrc : options.loadingPath"
                        />
                        <div
                            v-else
                            v-drag-resize="_resizeOptions"
                            :alt="info.name"
                            :src-original="_imgSrc"
                            class="J_img_lazyload style33Img J_style33Img imgBorderRadius picSizeClass"
                            :style="proPhotoStyle"
                            :class="_imgScaleClass"
                        ></div>
                        <quality-content-label
                            v-if="_isShowQualityContentLabel"
                            :text="_knowPayLabelText"
                        ></quality-content-label>
                    </div>
                </div>
                <div v-if="!isNewAddModule" class="tableCell tableCell-70">
                    <div :class="proNameClasses">
                        <p class="g_link paramName g_productName" :style="productName_style">
                            <template v-if="module.content.showProName">
                                <span v-if="info.isTimedAdded" class="g_oldThemeBgColor m_timedAddTag g_main_bgColor_v3"
                                    >即将开售</span
                                >
                                {{ info.name }}
                            </template>
                            <mall-cart v-if="!_extInfo.hasMallPrice && _showMallCart.mCart" :info="info"></mall-cart>
                        </p>
                    </div>
                    <prop-panel
                        :info="info"
                        :list="_showPropList"
                        :ext-info="_extInfo"
                        :show-mall-cart="_showMallCart"
                        :text-align-class="textAlignClass"
                        :no-name-wrap="noNameWrap"
                    >
                    </prop-panel>
                </div>
                <div v-if="isNewAddModule" class="textWrap separatorLineClass" :style="textWrapStyle">
                    <div>
                        <div class="tableCell tableCell-70">
                            <div :class="proNameClasses">
                                <p
                                    class="g_link paramName g_productName g_newProductName"
                                    :class="_proNameWrapClass"
                                    :style="productName_style"
                                >
                                    <template v-if="module.content.showProName">
                                        <span
                                            v-if="info.isTimedAdded"
                                            class="g_oldThemeBgColor m_timedAddTag g_main_bgColor_v3"
                                            >即将开售</span
                                        >
                                        {{ info.name }}
                                    </template>
                                    <mall-cart
                                        v-if="!isNewAddModule && !_extInfo.hasMallPrice && _showMallCart.mCart"
                                        :info="info"
                                    ></mall-cart>
                                </p>
                            </div>
                            <prop-panel
                                :info="info"
                                :list="_showPropList"
                                :ext-info="_extInfo"
                                :show-mall-cart="_showMallCart"
                                :text-align-class="textAlignClass"
                                :no-name-wrap="noNameWrap"
                            >
                            </prop-panel>
                        </div>
                    </div>
                </div>
            </div>
        </a>
    </div>
</template>

<script>
import productItemMixin from '../../mixins/productItemMixin';
import saleFlag from '../comm/sale-flag.vue';
import propPanel from '../comm/prop-panel.vue';
import mallCart from '../comm/mall-cart.vue';
import qualityContentLabel from '../comm/quality-content-label.vue';
import { Img } from '@/shared/img';

const manageMode = VITE_APP_MODE !== 'visitor';
export default {
    name: 'PicTextListItem',
    components: { saleFlag, mallCart, propPanel, qualityContentLabel },
    mixins: [productItemMixin],
    inject: ['module', 'options'],
    props: ['info', 'index'],
    data() {
        return {
            manageMode,
        };
    },
    computed: {
        //已抢光标志图片
        soldOutImg() {
            return `${this.options.resRoot}/image/soldOut${this.module.extInfo.isCN ? '' : '_en'}.png`;
        },
        proPhotoStyle() {
            return { ...this.imgStyle, ...this._proRadiusStyle };
        },
        textWrapStyle() {
            if (!manageMode) {
                return;
            }
            var textWrapStyle = {};
            if (this.isNewAddModule) {
                let proPhotoSize = this.module.content.proPhotoSize;
                let photoSizeType = proPhotoSize.pt;
                let photoSizeHeight = proPhotoSize.ph;
                if (photoSizeType == 1) {
                    textWrapStyle.minHeight = photoSizeHeight + 'px';
                }
                let separatorLine = this.module.content.separatorLine;
                let separatorLineType = separatorLine.st;
                let separatorLineColor = separatorLine.sc;
                let separatorLineStyle = separatorLine.ss;
                let separatorLineWidth = separatorLine.sw;
                switch (separatorLineStyle) {
                    case 0:
                        separatorLineStyle = 'solid';
                        break;
                    case 1:
                        separatorLineStyle = 'dotted';
                        break;
                    case 2:
                        separatorLineStyle = 'dashed';
                        break;
                }
                if (separatorLineType == 1) {
                    textWrapStyle.borderBottomColor = separatorLineColor;
                    textWrapStyle.borderBottomStyle = separatorLineStyle;
                    textWrapStyle.borderBottomWidth = separatorLineWidth + 'px';
                }
            }
            return textWrapStyle;
        },
        picTextListSize() {
            if (this.isNewAddModule) {
                if (!manageMode) {
                    return;
                }
                var style = {};
                let proPhotoSize = this.module.content.proPhotoSize;
                let photoSizeType = proPhotoSize.pt;
                let photoSizeWidth = proPhotoSize.pw;
                let photoSizeHeight = proPhotoSize.ph;
                if (photoSizeType == 1) {
                    style.width = photoSizeWidth + 'px';
                    style.height = photoSizeHeight + 'px';
                }
                return style;
            }
            return {};
        },
        imgStyle() {
            let styles = {};
            if (this.isNewAddModule) {
                var style = {};
                style.backgroundImage = `url(${this.noLazyLoad ? this._imgSrc : this.options.loadingPath})`;
                return { ...style, ...this.picTextListSize };
            } else {
                if (this.module.content.picSetting.picSizeSet == 1) {
                    let picHeight = this.module.content.picSetting.picSize.picHeight || 80;
                    let picWidth = this.module.content.picSetting.picSize.picWidth || 80;
                    if (this.module.content.picSetting.picCompress == 0) {
                        let transHeight = this.info.transHeight || 80;
                        let transWidth = this.info.transWidth || 80;
                        if (!this.info.picId) {
                            let picSize = Img.calcSize(
                                transWidth,
                                transHeight,
                                picWidth,
                                picHeight,
                                Img.Mode.SCALE_FILL
                            );
                            picWidth = picSize.width + 'px';
                            picHeight = picSize.height + 'px';
                        }
                    }
                    styles.width = picWidth + 'px';
                    styles.height = picHeight + 'px';
                }
            }
            return styles;
        },
        nameWrapClass() {
            return this.module.content.nameWrap ? 'paramWrap' : 'paramNowrap';
        },
        proNameClasses() {
            return `${this.wrapClass} ${this.textAlignClass}`;
        },
        wrapClass() {
            let wrap = this.module.content.nameWrap ? 'paramWrap' : 'paramNowrap';
            if (this.module.content.proStyle == 3) {
                wrap = 'paramNowrap'; // 名称打点
            }
            return wrap;
        },
        textAlignClass() {
            let textAlign = this.module.content.ta == 0 ? 'textAlign_center' : 'textAlign_left';

            if (!(this.module.extInfo.newUser || this.module.extInfo.isEdit)) {
                textAlign = '';
            }
            return textAlign;
        },
        noNameWrap() {
            return 'paramWrap';
        },
        productName_style() {
            var style = '';
            if (!manageMode) {
                return style;
            }
            if (this.isNewAddModule) {
                var newProductNameStyle = this.module.content.mhighs.npn;
                if (newProductNameStyle.type == 1) {
                    var fontWeight = newProductNameStyle.nlfb == 1 ? 'bold' : 'normal';
                    var fontStyle = newProductNameStyle.nlfi == 1 ? 'italic' : 'normal';
                    var textDecoration = newProductNameStyle.lfd == 1 ? 'underline' : 'none';
                    style += 'font-size:' + newProductNameStyle.lfs + 'rem;';
                    style += 'line-height:' + newProductNameStyle.nlflh + 'px;';
                    style += 'font-weight:' + fontWeight + ';';
                    style += 'text-decoration:' + textDecoration + ';';
                    style += 'font-style:' + fontStyle + ';';
                    style += 'color:' + newProductNameStyle.lfc + ';';
                }
            } else {
                var productNameStyle = this.module.content.mhighs.pn;
                if (productNameStyle.type == 1) {
                    if (productNameStyle.lfs) {
                        style += 'font-size:' + productNameStyle.lfs + 'rem;';
                    } else {
                        style += 'font-size:12px;';
                    }
                    if (productNameStyle.lfct == 1) {
                        style += 'color:' + productNameStyle.lfc + ';';
                    }
                    if (productNameStyle.lfd == 1) {
                        style += 'text-decoration:underline;';
                    } else {
                        style += 'text-decoration:none;';
                    }
                }
            }
            return style;
        },
    },
};
</script>

<style lang="scss">
.isNewAddModule .mProductList.fk-mProductList3 .picAndTextWrap {
    box-sizing: border-box;
    padding: 0 0.5rem;
    display: flex;
    margin-bottom: 0;
}
.form .isNewAddModule .mProductList.fk-mProductList3 .productPicListForm:last-child .g_separator {
    display: none;
}

.form .isNewAddModule .mProductList.fk-mProductList3 .productPicListForm {
    margin: 0.5rem 0;
}

.isNewAddModule .fk-mProductList3 .separatorLine {
    position: absolute;
    bottom: 0;
    width: 100%;
}
.isNewAddModule .fk-mProductList3 .tableBox .tableCell-30 {
    width: 4.25rem;
    padding: 0;
    vertical-align: top;
}

.isNewAddModule .fk-mProductList3 .tableBox .tableCell-30 .style33Img {
    width: 4.25rem;
    height: 4.25rem;
}

.isNewAddModule .fk-mProductList3 .tableBox .tableCell {
    padding-left: 0;
}
.isNewAddModule .fk-mProductList3 .textWrap {
    position: relative;
    min-height: 4.25rem;
    padding-bottom: 0.5rem;
    margin-left: 0.5rem;
    width: 0;
}

.isNewAddModule .productPicListForm .separatorLineClass {
    border-bottom: 1px solid #f0f0f0;
}
.isNewAddModule .mProductTextForm .separatorLineClass {
    border-bottom: 1px solid #eeeeee;
}

.isNewAddModule .productPicListForm:last-child .separatorLineClass,
.isNewAddModule .mProductTextForm:last-child .separatorLineClass {
    border-bottom: none !important;
}
.isNewAddModule .fk-mProductList3 .tableBox .tableCell {
    display: block;
}
.isNewAddModule .fk-mProductList3 .textWrap {
    flex-grow: 1;
}
.isNewAddModule .fk-mProductList3 .tableBox .tableCell-70 {
    width: 100%;
}
.isNewAddModule .fk-mProductList7,
.isNewAddModule .fk-mProductList9 {
    padding-top: 0.75rem;
}
.isNewAddModule .productCrossedSlide {
    width: 45%;
}
.webContainerBox .isNewAddModule .mProductList .productBullet {
    margin-top: 0.5rem;
}
</style>
