<template>
    <div
        :id="'newsLine' + item.id"
        class="photoImageItem photoSwipeItem J_also_resize newsLine"
        :data-picId="item.picId || item.pic"
        :topClassName="_topClassName"
        :topSwitch="_topSwitch"
        :newsId="item.id"
        :newsName="item.title"
    >
        <div :id="'lineBody' + item.id" class="photoImageItemContent lineBody" :style="itemContentStyle">
            <a
                :href="imgHref"
                class="imgLink J_img_lazyload"
                :src-original="item.realPicId ? item.bigPicPath : module.extInfo.noPicPath"
                :style="imgStyle"
                hidefocus="true"
                :class="imgCls"
                :onclick="jumpOnclickStr"
            >
                <div>
                    <div class="imgDescBox" :class="imgDescBoxCls">
                        <div class="imgName g_carouselPhoto_nameLink" :style="imgNameStyle" v-html="itemName"></div>
                    </div>
                </div>
            </a>
        </div>
    </div>
</template>

<script>
import { createUrlArgsOnclickStr } from '@/modules/comm';

import { encodeHtml } from '@/shared/util';
import { mapGetters } from 'vuex';
export default {
    name: 'NewCarouselImageItem',
    inject: ['module', 'options'],
    props: {
        item: {
            type: Object,
            default: () => {
                return {};
            },
        },
        itemIndex: { type: Number, default: 0 },
        noLazyLoad: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            id: this.module.id,
            styleId: this.module.style,
            oldIndex: 0,
            moduleWidth: 0,
        };
    },
    computed: {
        ...mapGetters('app', ['hashRemoved']),
        content() {
            return this.module.content;
        },
        mhighs() {
            return this.content.mhighs;
        },
        picScale() {
            return this.module.content.pst;
        },
        imgHref() {
            return VITE_APP_MODE !== 'visitor'
                ? this.module.extInfo.isOpenNewsLinksExtArticle && this.item && 0x80 == (this.item.flag & 0x80)
                    ? 'javascript:top.Fai.ing("管理态不支持跳转外部链接，请通过“预览”进行访问", true)'
                    : this.item.href
                : this.item.href;
        },
        jumpOnclickStr() {
            const { ndReqArgs } = this.item;
            return createUrlArgsOnclickStr(ndReqArgs, this.module._openRemoveUrlArgs, this.hashRemoved);
        },
        imgNameStyle() {
            var style = '';
            var imgNameFont = this.module.content.mhighs.nt;
            if (imgNameFont.type == 1) {
                if (imgNameFont.lfs) {
                    style += 'font-size:' + imgNameFont.lfs + 'rem;';
                } else {
                    style += 'font-size:12px;';
                }
                if (imgNameFont.lfct == 1) {
                    style += 'color:' + imgNameFont.lfc + ';';
                }
                if (imgNameFont.lfd == 1) {
                    style += 'text-decoration:underline;';
                } else {
                    style += 'text-decoration:none;';
                }
            }
            return style;
        },
        imgStyle() {
            var style = {},
                item = this.item;
            style['background-image'] = 'url(' + (this.noLazyLoad ? item.bigPicPath : this.options.loadingPath) + ')';

            if (this.module.content.tSize.picSizeType == 2) {
                style['height'] =
                    this.module.content.tSize.picHeight > 0
                        ? `${this.module.content.tSize.picHeight / 20}rem`
                        : '10rem';
            }

            return style;
        },
        imgCls() {
            return 'picScale' + (this.picScale + 1);
        },
        itemContentStyle() {
            var style = {};
            if (this.module.content.prt == 1) {
                style['border-radius'] = `${this.module.content.pr / 23.1875}rem`;
            }
            return style;
        },

        itemName() {
            return this.encodeHtml(this.item.title);
        },
        imgDescBoxCls() {
            var cls = '';
            if (!this.content.sin) {
                cls += ' noName';
            }

            if (!this.content.showName) {
                cls += ' noDesc';
            }
            return cls;
        },
        resizeOptions() {
            var handles = 's';
            var maxWidth = 323; // 323 中间手机宽度
            var minWidth = null;
            var notAllow = false;
            var alsoResizeParentSelector = null;
            var alsoResizeChildSelector = null;

            alsoResizeParentSelector = '.J_photoSwipe';

            return {
                notAllow: notAllow,
                handles: handles,
                alsoResizeParentSelector: alsoResizeParentSelector,
                alsoResizeChildSelector: alsoResizeChildSelector,
                fixPosition: true,
                outlineInBody: true,
                maxWidth: maxWidth,
                minWidth: minWidth,
                beforeStart: this._resizeBeforeStart,
                stop: this._resizeStop,
                resize: this._resizeing,
            };
        },
        _topClassName() {
            return this.item.top != 0 ? 'top2' : 'top1';
        },
        _topSwitch() {
            return this.item.top != 0 ? 'off' : 'on';
        },
    },
    mounted: function () {
        if (VITE_APP_MODE !== 'visitor') {
            let moduleContent = $(`#module${this.module.id} .carouselMultiPhoto`)[0];
            if (moduleContent) {
                this.moduleWidth = moduleContent.offsetWidth;
            }
        }
    },
    methods: {
        encodeHtml(html) {
            return encodeHtml(html);
        },
        _resizeBeforeStart() {
            const carouselType = this.module.content.mhighs.carouselSet.carouselType;
            if (carouselType == 1 || carouselType == 0) {
                const styleCom = vue_utils.findComponentsUpward(this, `CarouselType${this.module.content.type}`)[0];
                !!styleCom && styleCom.cleanAutoPlay();
            }
        },
        _resizeStop() {
            this.$designer.onContentChange(this.module.id);

            const carouselType = this.module.content.mhighs.carouselSet.carouselType;
            if (carouselType == 1 || carouselType == 0) {
                const styleCom = vue_utils.findComponentsUpward(this, `CarouselType${this.module.content.type}`)[0];
                !!styleCom && styleCom.setAutoplay();
            }
        },
        // _resizeing(event, ui) {
        // if (false) {
        //     ui.originalElement
        //         .closest('.J_photoSwipe')
        //         .css(
        //             'height',
        //             ui.originalElement
        //                 .closest('.J_also_resize')
        //                 .css('height')
        //         );
        // }
        // },
    },
};
</script>
<style lang="scss">
.newCarouselMultiPhoto .photoImageItem {
    display: inline-block;
    border-radius: 0.2rem;
    position: relative;
    width: 100%;
    vertical-align: top;
}
.newCarouselMultiPhoto .photoImageItem .photoImageItemContent {
    width: 100%;
    margin: auto;
    overflow: hidden;
    position: relative;
}
.newCarouselMultiPhoto .photoImageItem .imgLink {
    height: 7.75rem;
    width: 100%;
    display: block;
}
.newCarouselMultiPhoto .photoImageItem .imgLink.picScale1 {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.newCarouselMultiPhoto .photoImageItem .imgLink.picScale2 {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}
.newCarouselMultiPhoto .photoImageItem .imgLink.picScale3 {
    background-size: 100% 100%;
    background-repeat: no-repeat;
}
.newCarouselMultiPhoto .photoImageItem .imgLink.picScale4 {
    background-size: unset;
    background-position: center;
    background-repeat: no-repeat;
}

.newCarouselMultiPhoto.outerBox2 .photoImageItem .imgLink {
    width: auto;
}
.newCarouselMultiPhoto .photoImageItem img {
    vertical-align: middle;
    width: auto;
    height: auto;
}

.newCarouselMultiPhoto.innerBox .photoImageItem .imgDescBox {
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 1;
    padding: 0.5rem 0.5rem 0.4rem 0.6rem;
    box-sizing: border-box;
    bottom: 0;
}

.newCarouselMultiPhoto.innerBox .photoImageItem .imgCoverAuto .imgDescBox {
    position: relative;
    height: auto;
}
.newCarouselMultiPhoto .photoImageItem .imgName {
    font-size: 0.65rem;
    color: #fff;
    text-align: inherit;
    font-weight: bold;
}
.newCarouselMultiPhoto .photoImageItem .imgDesc {
    font-size: 0.5rem;
    margin: 0;
    color: #fff;
    margin-top: 0.1rem;
    text-align: inherit;
    overflow: hidden;
    text-overflow: ellipsis;
}
.newCarouselMultiPhoto.innerBox .photoImageItem .imgDesc,
.newCarouselMultiPhoto.innerBox .photoImageItem .imgName {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.newCarouselMultiPhoto.outerBox1 .photoImageItem .imgDescBox {
    padding: 0.4rem 0.5rem 0.65rem 0.5rem;
    width: 100%;
    min-height: 3.4rem;
    box-sizing: border-box;
    flex: 1;
}
.newCarouselMultiPhoto.outerBox2 .photoImageItem .imgDescBox {
    padding: 0.65rem 0.5rem 0.4rem 0.5rem;
}

.outerBox2 .photoImageItem .photoImageItemContent {
    justify-content: space-between;
}
.photoImageItem .photoImageItemContent.textCenter {
    text-align: center;
}
.photoImageItem .photoImageItemContent.textLeft {
    text-align: left;
}
.photoImageItem .photoImageItemContent.textRight {
    text-align: right;
}

.newCarouselMultiPhoto.outerBox1.imgTextType4 .photoImageItem .imgDescBox {
    padding: 0.4rem 0 0.25rem 0;
    min-height: 3rem;
}

.newCarouselMultiPhoto.outerBox2.imgTextType4 .photoImageItem .imgDescBox {
    padding: 0.65rem 0 0.4rem 0;
}
</style>
