import { deepExtendClone } from '@jz/utils';

import { mapGetters, mapState } from 'vuex';
import CustomSearchItem from './commComponent/CustomSearchItem.vue';
// const CustomSearchItem = () => import('./commComponent/CustomSearchItem.vue');
export const customSearchMixin = {
    data() {
        return {
            validatorText: '',
            validatorImgSrc: `validateCode.jsp?random=${parseInt(Math.random() * 1000)}&vCodeId=${this.moduleId}`,
        };
    },
    props: {
        moduleId: {
            type: Number,
            required: true,
        },
    },
    components: {
        CustomSearchItem,
    },
    computed: {
        ...mapState('app', ['openThemeV3', 'oem']),
        ...mapGetters(['getModuleById']),
        useManageMode() {
            return VITE_APP_MODE !== 'visitor';
        },
        module() {
            return this.getModuleById(this.moduleId);
        },
        id() {
            return this.moduleId;
        },
        renderOptionsArgs() {
            return this?.module?.renderOptions?.args ?? {};
        },
        styleId() {
            return this.style;
        },
        project() {
            return this.module?.args?.project || this.args?.projectInfo || {};
        },
        lanConf() {
            return this.module?.args?.lanConf || this.args?.lanConf || {};
        },
        mobileCtList() {
            return this?.renderOptionsArgs?.mobileCtNameList || [];
        },
        formInfo() {
            return this?.renderOptionsArgs?.formInfo || this.args?.formInfo || this?.project?.formInfo || {};
        },
        formContentList() {
            return this.formInfo.contentList || [];
        },
        // 表单查询设置项数据
        funcSetting() {
            return this.project.funcSetting || {};
        },
        // 查询项
        itemList() {
            if (this.funcType) {
                const list = this.formContentList.filter(
                    (value) =>
                        (this.isEsayMode && value.id === this.formItemId) ||
                        (!this.isEsayMode && this.advancedFormItemId.includes(value.id))
                );

                list.forEach((item) => {
                    item.name && (item.itemName = item.name);
                    item.value = item.type == 3 ? item.value || [] : item.value || '';
                    item.options = item.input.split('\n');
                });
                return list || [];
            } else {
                return this.project.itemList.map(function (item) {
                    item.value = item.type == 2 ? item.value || [] : item.value || '';
                    return item;
                });
            }
        },
        // 表单id
        funcId() {
            return this.project.funcId || 0;
        },
        // 查询类型
        funcType() {
            return this.project.funcType || 0;
        },
        // 表单查询模式
        mode() {
            return this.funcSetting.mode || 0;
        },
        // 是否是简单查询
        isEsayMode() {
            return this.mode == 0;
        },
        // 表单查询 简单查询 表单项id
        formItemId() {
            return this.funcSetting.itemId;
        },
        // 表单高级查询项
        advancedFormItemId() {
            return this.flat(deepExtendClone(this.funcSetting.conditionList)) || [];
        },
        itemListCondited() {
            return this.itemList.filter(({ isCondition }) => isCondition || this.funcType);
        },
        formConditionItemList() {
            return this.funcSetting.itemList || [];
        },
        formTextDescriptionItemList() {
            return this.formContentList.filter((value) => value.type === 5) || [];
        },
    },
    methods: {
        onInput({ item, key, value }) {
            item[key] = value;
        },
        format() {
            var s = arguments[0];
            for (var i = 0; i < arguments.length - 1; i++) {
                var reg = new RegExp('\\{' + i + '\\}', 'gm');
                s = s.replace(reg, arguments[i + 1]);
            }
            return s;
        },
        flat(arr) {
            const result = [];
            const stack = [].concat(arr); // 将数组元素拷贝至栈，直接赋值会改变原数组
            //如果栈不为空，则循环遍历
            while (stack.length !== 0) {
                const val = stack.pop();
                if (Array.isArray(val)) {
                    stack.push(...val); //如果是数组再次入栈，并且展开了一层
                } else {
                    result.unshift(val); //如果不是数组就将其取出来放入结果数组中
                }
            }
            return result;
        },
        getSearchType(t) {
            let text = '';
            if (this.funcType) {
                switch (t) {
                    case 0:
                        text = 'singleLine';
                        break;
                    case 1:
                        text = 'multiLine';
                        break;
                    case 2:
                        text = 'radio';
                        break;
                    case 3:
                        text = 'multipleChoice';
                        break;
                    case 4:
                        text = 'dropdown';
                        break;
                    case 6:
                        text = 'date';
                        break;
                    case 8:
                        text = 'phone';
                        break;
                    case 9:
                        text = 'mail';
                        break;
                    case 10:
                        text = 'identity';
                        break;
                    case 11:
                        text = 'address';
                        break;
                    case 12:
                        text = 'multiDropdown';
                        break;
                    case 13:
                        text = 'number';
                        break;
                    default:
                        text = '-';
                        break;
                }
            } else {
                switch (t) {
                    case 0:
                        text = 'singleLine';
                        break;
                    case 1:
                        text = 'radio';
                        break;
                    case 2:
                        text = 'multipleChoice';
                        break;
                    case 3:
                        text = 'dropdown';
                        break;
                    case 4:
                        text = 'photo';
                        break;
                    default:
                        text = 'singleLine';
                }
            }
            return text;
        },
    },
};
