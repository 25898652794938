var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "formFoldContent",
      staticClass: "J_formFoldContent f-formFoldContent",
      attrs: { id: "formFoldContent" + _vm.id },
    },
    [
      _c(
        "div",
        { ref: "formFoldContentBox", staticClass: "J_formFoldContentBox" },
        [
          _vm.itemData.load
            ? _c(_vm.componentsTable[_vm.childStyle], {
                tag: "component",
                attrs: { "module-id": _vm.childId, children: _vm.children },
              })
            : _c("module-loading"),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }