import { NEW_MANAGE_HTML_FONTSIZE } from '@/shared/constants';

export const optionsMixins = {
    data() {
        return {
            htmlFontSize: NEW_MANAGE_HTML_FONTSIZE,
        };
    },
    computed: {
        hrefStr2() {
            return this.options.args.hrefStr2;
        },

        linkType() {
            return this.module.content.link;
        },

        titleIsHide() {
            return !this.module.content.showTitle;
        },

        descIsHide() {
            return !this.module.content.showName;
        },

        isEmptyText() {
            return this.contentList.every((item) => {
                let titleIsHide = item.imgTitle == '' || this.titleIsHide;
                let descIsHide = item.imgName == '' || this.descIsHide;
                return titleIsHide && descIsHide;
            });
        },
    },
    methods: {
        imgHref(url) {
            if (this.linkType == 0) {
                return this.hrefStr2;
            } else if (url != '' && this.linkType == 2) {
                return url;
            } else {
                return undefined;
            }
        },
    },
    mounted() {
        this.htmlFontSize = window._htmlFontSize;
    },
};
