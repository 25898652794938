var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "f-fromTabLoading" }, [
      _c("div", { staticClass: "f-new-spinner f-spinner" }, [
        _c("div", { staticClass: "loading-circle-item" }),
        _vm._v(" "),
        _c("div", { staticClass: "loading-circle-item" }),
        _vm._v(" "),
        _c("div", { staticClass: "loading-circle-item" }),
        _vm._v(" "),
        _c("div", { staticClass: "loading-circle-item" }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }