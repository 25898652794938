<template>
    <div
        :id="'module' + id"
        ref="moduleFrame"
        v-dashed-frame="moduleEditActive"
        v-right-click="rightClickMenu"
        v-animate-obsever="animationData"
        :class="moduleCls"
        :_moduleStyle="styleId"
        :_autoHeight="autoHeightComp"
        :_height="module.pattern.h || 0"
        :_autoWidth="autoWidthComp"
        :_width="module.pattern.w || 0"
        :_inpack="inpackComp"
        :_inPopupZone="module.inPopup || 0"
        :_inTab="module.inTab || 0"
        :_inFold="module.inFold || 0"
        :_infloatzone="module.inFloatZone || 0"
        :_bHeight="module.pattern.bh || 0"
        :_headerHiden="headerTitle ? '' : '1'"
        :style="moduleStyleComp"
    >
        <div
            v-if="isLightModule"
            v-show="!hiddenTitle"
            :style="bannerStyleComp"
            class="formBannerTitle"
            :class="formBannerTitleClass"
            :_bannerAutoHeight="module.pattern.bh > 0 ? 0 : 1"
        >
            <div class="titleLeft" :class="'titleLeft' + id" />
            <div class="clearFix titleCenter" :class="'titleCenter' + id">
                <div class="titleText" :class="'titleText' + id">
                    <div class="titleTextIcon icon-titleText">
                        {{ openThemeV3 ? '' : '&nbsp;' }}
                    </div>
                    <div class="textContent">
                        <span class="textModuleName">{{ moduleName }}</span>
                        <template v-if="styleId == 13">
                            <div
                                v-show="
                                    !openThemeV3 && associateLottery && showLotteryType_V1 == 0 && titleStyle_V1 == 1
                                "
                                style="visibility: hidden"
                                class="lottery-need-open form-lottery-20201021-style-V1 form-lottery-20201021-style-V1-1"
                            />
                        </template>
                        <div v-if="showSubTitle" class="textContentSubTitle">
                            {{ subTitle.text || '副标题' }}
                        </div>
                    </div>
                </div>
                <template v-if="styleId == 13">
                    <div
                        v-show="openThemeV3 && associateLottery && showLotteryType == 0 && titleStyle == 1"
                        style="visibility: hidden"
                        class="lottery-need-open form-lottery-20201021-style form-lottery-20201021-style-1"
                    />
                    <div
                        v-show="openThemeV3 && associateLottery && showLotteryType == 0 && titleStyle == 2"
                        style="visibility: hidden"
                        class="lottery-need-open form-lottery-20201021-style form-lottery-20201021-style-2"
                    />
                    <div
                        v-show="!openThemeV3 && associateLottery && showLotteryType_V1 == 0 && titleStyle_V1 == 2"
                        style="visibility: hidden"
                        class="lottery-need-open form-lottery-20201021-style-V1 form-lottery-20201021-style-V1-2"
                    />
                </template>
                <div v-if="options.moduleStyle === 0" class="formBannerMore" :class="'formBannerMore' + id">
                    <a
                        v-if="options.args.bannerMore || ''"
                        class="titleMoreLink"
                        :class="'titleMoreLink' + id"
                        :href="options.args.bannerMore | wechatChangeLink"
                        :rel="options.args.nf ? 'nofollow' : ''"
                        :onclick="bannerMoreOnclickStr"
                    >
                        <span class="titleMoreIcon icon-titleMore" :class="'titleMoreIcon' + id">
                            {{ openThemeV3 ? '' : '&nbsp;' }}
                        </span>
                        <span class="titleMore" :class="'titleMore' + id">{{ mobiMore }}</span>
                        <span v-if="openThemeV3">&zwj;</span>
                    </a>
                </div>
                <div
                    v-if="options.moduleStyle === 1 && openThemeV3"
                    class="formBannerMore"
                    :class="'formBannerMore' + id"
                    :style="headerTitle ? '' : 'display:none;'"
                >
                    <a
                        v-if="options.args.bannerMore || ''"
                        class="titleMoreLink"
                        :class="'titleMoreLink' + id"
                        :href="options.args.bannerMore | wechatChangeLink"
                        :rel="options.args.nf ? 'nofollow' : ''"
                        :onclick="bannerMoreOnclickStr"
                    >
                        <span class="titleMoreIcon icon-titleMore" :class="'titleMoreIcon' + id">
                            {{ openThemeV3 ? '' : '&nbsp;' }}
                        </span>
                        <span class="titleMore" :class="'titleMore' + id">{{ mobiMore }}</span>
                        <span v-if="openThemeV3">&zwj;</span>
                    </a>
                </div>
            </div>
            <div class="titleRight" :class="'titleRight' + id" />
        </div>
        <div class="formMiddle" :class="formMiddleClass">
            <div class="middleLeft" :class="'middleLeft' + id" />
            <div class="middleCenter" :class="'middleCenter' + id" :style="middleCentStyle">
                <div
                    class="formMiddleContent moduleContent"
                    :class="'formMiddleContent' + id"
                    :_innerType="module.pattern.i.y || 0"
                >
                    <template v-if="manageMode">
                        <slot />
                    </template>
                    <template v-else>
                        <div class="module_content">
                            <slot />
                        </div>
                    </template>
                </div>
            </div>
            <div class="middleRight" :class="'middleRight' + id" />
        </div>
        <div
            v-if="options.moduleStyle === 1 && !openThemeV3"
            class="formBannerMore"
            :class="'formBannerMore' + id"
            :style="headerTitle ? '' : 'display:none;'"
        >
            <a
                v-if="options.args.bannerMore || ''"
                class="titleMoreLink"
                :class="'titleMoreLink' + id"
                :href="options.args.bannerMore | wechatChangeLink"
                :rel="options.args.nf ? 'nofollow' : ''"
                :onclick="bannerMoreOnclickStr"
            >
                <span class="titleMoreIcon icon-titleMore" :class="'titleMoreIcon' + id">
                    {{ openThemeV3 ? '' : '&nbsp;' }}
                </span>
                <span class="titleMore" :class="'titleMore' + id">{{ mobiMore }}</span>
                <span v-if="openThemeV3">&zwj;</span>
            </a>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import dashedFrameDirective from './v-dashed-frame';
import { getMenuList } from '@/manage/contextmenu/index';

import { createUrlArgsOnclickStr } from '../comm';
import { memberModuleStyle, MODULE_STYLE, productDetailModuleStyle } from '@/def/module';
import { getModuleAttr, getModuleAttrPatternStg } from '@/features/global-mobi-function/methods/util';
import { isWebDetailPage } from '@/def/col';
import { isRecommendedNewsListModule } from '../type-mapping';
import { isNewsDetailModule } from '@/def/module.js';

export default {
    name: 'ModuleFrame',
    directives: {
        'dashed-frame': dashedFrameDirective,
    },
    filters: {
        wechatChangeLink(val) {
            if (val) {
                if (val.indexOf('mp.weixin.qq.com') > 0) {
                    if (val.indexOf('#rd') > 0) {
                        val = val.replace('#rd', '#wechat_redirect');
                    }
                }
            }
            return val;
        },
    },
    inject: {
        moduleInfo: { default: void 0 },
        renderOptions: { default: void 0 },
    },
    props: {
        moduleId: {
            type: Number,
            default: -1,
            required: true,
        },
    },
    data() {
        return {
            styleId: -1,
            mobiMore: false,
            modulePatternV3: {},
            compSkinPattern: null,
            animationData: {},
        };
    },
    computed: {
        ...mapGetters('app', ['isDependentCol', 'hashRemoved']),
        ...mapState(['components']),
        ...mapState('app', ['openThemeV3', 'colId', 'templateDesignType']),
        ...mapGetters(['getModuleById']),
        ...mapState(['htmlFontSize', 'showSubTitleSkinIdList', 'fullSiteSubTitleType']),
        ...mapState('manage', {
            allModuleAttr: 'moduleAttr',
        }),
        formMiddleClass() {
            const classes = [`formMiddle${this.id}`];
            if (this.isDependentCol) {
                classes.push('formMiddleClear');
            }
            if (isWebDetailPage(this.colId, this.templateDesignType, this.openThemeV3)) {
                classes.push('detailPageFormMiddle');
            }

            return classes;
        },
        editModuleActive() {
            return VITE_APP_MODE !== 'visitor'
                ? window.$store.getters['manage/getModuleActiveById']?.(this.moduleId)
                : false;
        },
        formBannerTitleClass() {
            if (this.isDependentCol) {
                return `formBannerTitle${this.id} formBannerTitleHide`;
            }
            return `formBannerTitle${this.id}`;
        },
        module() {
            return this.moduleInfo ?? this.getModuleById(this.moduleId);
        },
        moduleAttr() {
            return getModuleAttr(this.moduleId);
        },
        moduleAttrV3() {
            return this.module.allAttrV3 ?? {};
        },
        manageMode() {
            return VITE_APP_MODE !== 'visitor';
        },
        options() {
            return this.renderOptions || this.module.renderOptions || {};
        },
        moduleName() {
            // 这里需要处理模块标题不跟随默认模块名称
            let { name, style } = this.module;
            if (style == MODULE_STYLE.PRODUCT_RESULT) {
                // 模块标题跟随当前条件（面板设置）
                name = this.getProductResultName();
            } else if (style == MODULE_STYLE.SYS_PHOTOGROUP_RESULT) {
                name = this.getPhotoGroupResultName();
            } else if (style == MODULE_STYLE.NEWS_RESULT) {
                name = this.getNewsResultName();
            } else if (isNewsDetailModule(style)) {
                name = '文章详情';
            }
            return name;
        },
        middleCentStyle() {
            let style = {};
            if (memberModuleStyle.includes(this.styleId) && this.openThemeV3) {
                style['background'] = '#fff';
                style['height'] = '100%';
            }
            return style;
        },
        titleStyle_V1() {
            return this.module.pattern.ts?.t;
        },
        titleStyle() {
            return this.module.patternV3.ts?.t;
        },
        associateLottery() {
            if (this.styleId == 13) {
                let formData = this.module.content.fi;
                return formData && formData.associateLottery;
            }
            return false;
        },
        showLotteryType() {
            return this.module.patternV3.lts?.t;
        },
        showLotteryType_V1() {
            return this.module.pattern.lts?.t;
        },
        bannerMoreOnclickStr() {
            const { reqArgs } = this.options.args;
            return createUrlArgsOnclickStr(reqArgs, this.module._openRemoveUrlArgs, this.hashRemoved);
        },
        moduleEditActive() {
            var data = {};

            data.editModuleActive = this.editModuleActive;

            data.showDash = true;

            // 常用模块：文本、按钮、图片弹窗中预览不需要边框线
            if (VITE_APP_MODE !== 'visitor') {
                if (this.styleId == 53 && this.$moduleUtils.isModuleV2(53)) {
                    let otherInfo = this.module.otherInfo || {};
                    data.onlyLoadVisitScript = otherInfo.onlyLoadVisitScript || false;
                }
                if (this.styleId == 52 || this.styleId == 54) {
                    let extInfo = this.module.extInfo || {};
                    data.onlyLoadVisitScript = extInfo.onlyLoadVisitScript || false;
                }
            }

            if (
                [
                    ...[MODULE_STYLE.NEWS_DETAIL, MODULE_STYLE.NEWS_NEW_DETAIL, MODULE_STYLE.NEWS_DETAIL_V3],
                    ...productDetailModuleStyle,
                    ...memberModuleStyle,
                    MODULE_STYLE.FLOAT_BTN,
                ].includes(this.styleId)
            ) {
                data.showDash = false;
            }

            if (this.openThemeV3 === false) {
                if (this.styleId === MODULE_STYLE.SYS_PRODUCT_NEW_GROUP) {
                    data.showDash = false;
                }
            }

            return data;
        },
        //增加时要确定拖拽模块时是否需要显示模块标题
        // 不显示 isLightModule处理
        // 显示 hiddenTitle处理
        isLightModule() {
            return ![
                MODULE_STYLE.SIMPLE_TEXT,
                MODULE_STYLE.FLOAT_IMG,
                MODULE_STYLE.FLOAT_BTN,
                MODULE_STYLE.FLOAT_ZONE,
                MODULE_STYLE.POPUP_ZONE,
                // MODULE_STYLE.PRODUCT_NEW_DETAIL,
                MODULE_STYLE.SYS_FORM_POPUP,
            ].includes(this.styleId);
        },
        // 模块隐藏逻辑
        hiddenTitle() {
            let style = this.styleId;

            let openThemeV3 = this.openThemeV3;

            if (
                !this.headerTitle &&
                !(
                    (style == 23 || style == 26 || style == 31 || style == 50 || style == 51 || style == 58) &&
                    openThemeV3
                )
            ) {
                return true;
            }

            // 自定义标题隐藏
            if (!(VITE_APP_MODE !== 'visitor')) {
                if (openThemeV3) {
                    return this.module.patternV3?.t === 1;
                } else {
                    return this.module.pattern?.t === 1;
                }
            }
            if (openThemeV3) {
                return this.moduleAttrV3.patternV3?.t === 1;
            } else {
                return this.moduleAttr.pattern.bannerType === 1;
            }
        },
        moduleCls() {
            let moduleSkinPattern = '';
            let style = this.styleId;
            let moduleSkinId = (this.module.patternV3 && this.module.patternV3.skinId) || 0;
            let openThemeV3 = this.openThemeV3;

            let pattern = this.module.pattern;
            let patternV3 = this.module.patternV3;

            if (
                openThemeV3 &&
                moduleSkinId > 0 &&
                style != 12 &&
                style != 14 &&
                style != 15 &&
                style != 17 &&
                style != 18 &&
                style != 19 &&
                style != 20 &&
                style != 29 &&
                style != 35 &&
                style != 36 &&
                style != 38 &&
                style != 43 &&
                style != 45 &&
                style != 46 &&
                style != 49 &&
                style != 55 &&
                style != 57 &&
                style != 61 &&
                style != 62 &&
                style != 63 &&
                style != 65 &&
                style != 66 &&
                style != 67 &&
                style != 68 &&
                style != 69 &&
                style != 70 &&
                style != 71 &&
                style != 73 &&
                style != 84
            ) {
                moduleSkinPattern = `jz-modulePattern${moduleSkinId}`;
            }

            let classList = [
                'form',
                'Handle',
                `module${this.moduleId}`,
                `${moduleSkinPattern}`,
                `formStyle${this.styleId}`,
            ];

            if (this.isDependentCol) {
                classList.push('form-position-static');
                classList.push('form-handle-zero-margin');
            }

            if (style == 31) {
                classList.push(`moduleStyle${this.module.content.cubeLayoutId}`);
            }
            // 表单弹窗的表单模块不受模块皮肤的控制
            if (style == 84) {
                classList = [];
            }

            if (style === 103) {
                classList.push('jz_float_zone_module');
            }

            // 添加动画类名
            if (!(VITE_APP_MODE !== 'visitor')) {
                let animation = '';
                if (this.openThemeV3) {
                    if (patternV3 != null) {
                        animation = patternV3.a || {};
                    }
                } else {
                    if (pattern != null) {
                        animation = pattern.a || {};
                    }
                }

                if (animation.t != null && animation.t != 0) {
                    classList.push('animateModule');
                }
            }

            return classList;
        },
        bannerStyleComp() {
            let retStyle = {};

            let style = this.styleId;
            let openThemeV3 = this.openThemeV3 || false;

            if (!this.headerTitle) {
                if (
                    !(
                        (style == 23 || style == 26 || style == 31 || style == 50 || style == 51 || style == 58) &&
                        openThemeV3
                    )
                ) {
                    retStyle.display = 'none';
                }
            }

            if (!isRecommendedNewsListModule(this.moduleId) && VITE_APP_MODE !== 'visitor') {
                retStyle.cursor = 'move';
            }

            return retStyle;
        },
        autoHeightComp() {
            let autoHeight = 1;
            let height = this.module.pattern.h || 0;

            if (height > 0) {
                autoHeight = 0; // 0 为固定值，1 为自动
            }

            return autoHeight;
        },
        autoWidthComp() {
            let autoWidth = 1;
            let width = this.module.pattern.w || 0;

            if (width > 0) {
                autoWidth = 0;
            }

            return autoWidth;
        },
        inpackComp() {
            let moduleInPack = this.module.ip || 0;

            if (moduleInPack) {
                return moduleInPack;
            }

            return false;
        },
        headerTitle() {
            let style = this.styleId;
            let headerTitle = this.module.headerTitle;

            if (this.module.ip || style == 31 || style == 52 || style == 53 || style == 54 || style == 62) {
                headerTitle = false;
            }

            return headerTitle;
        },
        moduleStyleComp() {
            const moduleInPack = this.options.moduleInPack;
            const moduleInPopupZone = this.options.moduleInPopupZone;
            const moduleInFloatZone = this.options.moduleInFloatZone;

            let retStyle = {};
            let width = this.module.pattern.w ?? 0;
            let height = this.module.pattern.h ?? 0;

            // let frameWidth = 375; // 宽度 为管理态中间视图宽度
            // let isMobiClient = true;

            if (moduleInPack) {
                let left = moduleInPack.position.l ?? 0;
                let top = moduleInPack.position.t ?? 0;
                let isPackChange = moduleInPack.isPackChange;
                let htmlFontSize = moduleInPack.htmlFontSize ?? this.htmlFontSize;
                let packHtmlFontSize = moduleInPack.packHtmlFontSize;

                retStyle.position = 'absolute';
                // 开放自动容器左上角对齐
                // if (left >= 0 || top >= 0) {
                // 【【工单反馈】拖动模块时，松开鼠标后模块会自动发生一小段位移】https://www.tapd.cn/42954297/bugtrace/bugs/view/1142954297001095420
                const NEW_DATA_TIME = 1650011400000; // 2022/04/15/16:30
                if (this.module.updateTime > NEW_DATA_TIME) {
                    // 兼容旧数据，新数据都用 this.htmlFontSize
                    retStyle.left = left / this.htmlFontSize + 'rem';
                    retStyle.top = top / this.htmlFontSize + 'rem';
                } else {
                    retStyle.left = left / (isPackChange ? packHtmlFontSize : htmlFontSize) + 'rem';
                    retStyle.top = top / (isPackChange ? packHtmlFontSize : htmlFontSize) + 'rem';
                }
                // }
                if (width) retStyle.width = width / htmlFontSize + 'rem';
                if (height) retStyle.height = height / htmlFontSize + 'rem';
            } else if (moduleInPopupZone) {
                let left = moduleInPopupZone.position.l ?? 0;
                let top = moduleInPopupZone.position.t ?? 0;
                let htmlFontSize = moduleInPopupZone.htmlFontSize ?? this.htmlFontSize;

                if (left >= 0 || top >= 0) {
                    retStyle.position = 'absolute';
                    retStyle.left = left / htmlFontSize + 'rem';
                    retStyle.top = top / htmlFontSize + 'rem';
                }

                if (width) retStyle.width = width / htmlFontSize + 'rem';

                if (height) retStyle.height = height / htmlFontSize + 'rem';
            }
            if (moduleInFloatZone) {
                let left = moduleInFloatZone.position.l ?? 0;
                let top = moduleInFloatZone.position.t ?? 0;
                let htmlFontSize = moduleInFloatZone.htmlFontSize ?? this.htmlFontSize;

                if (left >= 0 || top >= 0) {
                    // 高度采用比例
                    retStyle.position = 'fixed';
                    retStyle.left = left / htmlFontSize + 'rem';
                    retStyle.top = top / 100 + '%';
                }

                if (width) retStyle.width = width / htmlFontSize + 'rem';

                if (height) retStyle.height = height / htmlFontSize + 'rem';
                // 要求在弹窗、返回顶部之下
                retStyle['z-index'] = 99;
            }

            if (this.styleId == 54) {
                // 按钮模块处理
                if (VITE_APP_MODE !== 'visitor') {
                    if (this.openThemeV3) {
                        retStyle.opacity = (100 - this.module.patternV3.o) / 100;
                    } else {
                        retStyle.opacity = this.module.pattern.tran / 100;
                    }
                }

                // 以前的 Mobi.adjustFlBtnPos 改成用 vue 實現
                retStyle.border = '10px solid transparent';
            }

            return retStyle;
        },
        subTitle() {
            if (this.modulePatternV3 == null) return {};
            return this.modulePatternV3.s || {};
        },
        showSubTitle() {
            if (VITE_APP_MODE == 'visitor') {
                if (this.openThemeV3) {
                    let modulePatternV3 = this.module.patternV3 || {};
                    let skinId = modulePatternV3.skinId;
                    let globalShowSubTitle = this.showSubTitleSkinIdList.includes(skinId);
                    let moduleShowSubTitleType = parseInt(this.subTitle.t);
                    let show = false;
                    switch (moduleShowSubTitleType) {
                        case 0:
                            show = globalShowSubTitle || this.fullSiteSubTitleType == 2;
                            break;
                        case 1:
                            show = false;
                            break;
                        case 2:
                            show = true;
                            break;
                    }
                    return show;
                } else {
                    return true;
                }
            } else {
                return true;
            }
        },
        rightClickMenu() {
            let obj = {};

            if (VITE_APP_MODE !== 'visitor' && this.$store.state.manage.auth.siteDesign) {
                obj = getMenuList(this.module.id, this.styleId, this.colId);
            }
            obj.manageMode = VITE_APP_MODE !== 'visitor';
            let onlyLoadVisitScript = false;
            if (this.styleId == 53) {
                let otherInfo = this.module.otherInfo || {};
                onlyLoadVisitScript = otherInfo.onlyLoadVisitScript || false;
            }
            if (this.styleId == 52 || this.styleId == 54) {
                let extInfo = this.module.extInfo || {};
                onlyLoadVisitScript = extInfo.onlyLoadVisitScript || false;
            }
            if (onlyLoadVisitScript) {
                obj.manageMode = false;
            }
            obj.systemModule = !this.module.valid;
            return obj;
        },
        isHidden() {
            let ret = false;

            switch (this.styleId) {
                case 44: //优惠券列表
                    ret = !(VITE_APP_MODE !== 'visitor') && this.module.couponList.length === 0;
                    break;
            }

            return ret;
        },
        id() {
            return this.moduleId;
        },
    },
    watch: {
        $route() {
            function deleteWatcher(vueComponent) {
                if (vueComponent.$children) {
                    for (let childComponent of vueComponent.$children) {
                        deleteWatcher(childComponent);
                    }
                }
                if (vueComponent._wathcer) {
                    delete vueComponent._watcher;
                }
                if (vueComponent._watchers) {
                    vueComponent._watchers = [];
                }
            }
            deleteWatcher(this);
        },
    },
    mounted() {
        if (VITE_APP_MODE !== 'visitor') {
            this.updateEditModuleActive({
                moduleId: this.moduleId,
                editModuleActive: false,
            });
        }
        this.handleSubTitleInfo();
        // 这里是处理2.0主题下2.0模块因为SSR，统一设置背景高度时，Vue还没渲染，所以在这里统一hack @prize
        if (!this.openThemeV3 && VITE_APP_MODE !== 'visitor' && !memberModuleStyle.includes(this.styleId)) {
            let $previewModule = jm('#module' + this.id, window.document);
            let moduleAttrPatternStg = getModuleAttrPatternStg(this.id);
            let initHeight = parseInt(moduleAttrPatternStg.h) || parseInt($previewModule.height()) || 1;
            let height = initHeight > 2000 ? 2000 : initHeight;
            let $module = jm('#module' + this.id);
            const heightType = +$module.attr('_autoHeight');
            if (heightType === 0) {
                this.$moduleUtils.setModuleHeight(this.id, height);
                $module.attr({
                    _height: height,
                    _autoHeight: '0',
                });
            } else {
                this.$moduleUtils.autoModuleHeight(this.id);
                $module.attr('_autoHeight', '1');
            }
        }
        // 新增表单模块副标题实时更新
        if (VITE_APP_MODE !== 'visitor') {
            let editModule = '#module' + this.module.id;
            Vue.prototype.$modulesEventBus.$on(`${editModule}subTitleChange`, (obj) => {
                $(`${obj.changeModule} .textContentSubTitle`).text(obj.subTitle || '副标题');
            });
            if (this.animationData.animation && this.animationData.animation.t) {
                jm(editModule).addClass('animateModule');
            }
        }
    },
    created() {
        if (this.module.style != 52 && this.module.style != 53 && this.module.style != 54) {
            if (!(VITE_APP_MODE !== 'visitor')) {
                this.modulePatternV3 = this.module.patternV3 || {};
            }
        }
        const animationData = {
            id: this.moduleId,
            animation: this.openThemeV3 ? this.module.patternV3.a : this.module.pattern.a,
            style: this.module.style,
        };
        this.animationData = animationData;

        this.styleId = this.module.style;
        this.mobiMore = this.openThemeV3 ? '' : this.options.mobiMore;
    },
    methods: {
        ...mapMutations('manage', ['updateEditModuleActive']),
        handleSubTitleInfo() {
            if (
                VITE_APP_MODE !== 'visitor' &&
                this.module.style != 52 &&
                this.module.style != 53 &&
                this.module.style != 54
            ) {
                this.modulePatternV3 = this.moduleAttrV3.patternV3 || {};
            }
        },
        getProductResultName() {
            // 标题是否使用当前条件
            let { name, content } = this.module;
            if (content.cs.op) {
                name = this.options.name;
            }
            return name;
        },
        getPhotoGroupResultName() {
            // 标题是否使用当前条件
            let { name, content, extInfo } = this.module;
            if (content.cs) {
                name = extInfo.groupNavName;
            }
            return name;
        },
        getNewsResultName() {
            let { name, content } = this.module;
            if (content.op) {
                name = this.options.args.name;
            }
            return name;
        },
    },
};
</script>

<style lang="scss">
/* 副标题修改 */
.titleCenter .titleText .textContent:after {
    display: none;
}
.textContent .textContentSubTitle {
    display: none;
    color: #a9a9a9;
    margin-left: 0rem;
    margin-top: 0.25876rem;
    font-size: 0.51752rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-weight: normal;
}
.titleText .textContent .textContentSubTitle {
    text-align: inherit;
}
.formMiddle {
    display: inherit;
    flex-grow: 1;
    overflow: hidden;
    padding-bottom: 0.25rem;
}

.formMiddleClear {
    padding: 0px;
    background: none;
    border: none;
    box-shadow: none;
    box-sizing: border-box;
    margin-top: 0 !important;
}

.middleCenter {
    display: inherit;
    width: 100%;
}

.moduleContent {
    overflow: hidden;
}

.form-position-static {
    position: static;
}

.form.form-handle-zero-margin {
    margin: 0px;
    padding: 0px;
}

.form .formBannerTitleHide {
    display: none;
}

.webContainerBox .form .detailPageFormMiddle {
    background: #fff;
}
</style>
