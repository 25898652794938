var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "m_form_item", class: _vm.itemClass }, [
    !_vm.hidden
      ? _c(
          "label",
          {
            staticClass: "form_item_label",
            class: { form_item_require: _vm.isRequired },
            style: _vm.formItemStyle,
          },
          [_vm._v("\n        " + _vm._s(_vm.formItem.name) + "\n    ")]
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "form_item_content", style: _vm.formItemContentStyle },
      [_vm._t("default")],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }