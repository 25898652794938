var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "module-frame",
    { attrs: { "module-id": _vm.moduleId } },
    [
      _vm.styleType == 1
        ? _c("Type1", {
            attrs: { list: _vm.list },
            on: {
              imgClick: function ($event) {
                return _vm.photoSlidesshow(_vm.index)
              },
            },
          })
        : _vm.styleType == 6
        ? _c("Type6", {
            attrs: { list: _vm.list },
            on: {
              imgClick: function ($event) {
                return _vm.photoSlidesshow(_vm.index)
              },
            },
          })
        : _vm.styleType == 7
        ? _c("Type7", {
            attrs: { list: _vm.list },
            on: {
              imgClick: function ($event) {
                return _vm.photoSlidesshow(_vm.index)
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showMobiViewer
        ? _c("image-viewer", {
            attrs: {
              "z-index": _vm.zIndex,
              "initial-index": _vm.initialIndex,
              "on-close": _vm.onClose,
              "url-list": _vm.photoSwipeList,
              "is-mobi": true,
              "content-terminal": "mobi",
              "manage-mode": _vm.manageMode,
              "document-event-el": _vm.documentEventEl,
              "append-to-container-id": "g_body",
              "append-to-body": false,
              "disable-long-press": _vm.banCopyTextImg,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showPageComponent
        ? _c("pagenation", {
            attrs: {
              "module-id": _vm.moduleId,
              "total-size": _vm.totalSize,
              "page-size": _vm.content.count,
              pageno: _vm.pageno,
              "open-theme-v3": _vm.openThemeV3,
              "ajax-pagenation": true,
            },
            on: { "on-change": _vm.changePage },
            nativeOn: {
              click: function ($event) {
                $event.stopPropagation()
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }