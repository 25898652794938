var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "m_mobi_form_select_comp_wrap" },
    [
      _c("input", {
        ref: "input",
        staticClass: "select_input m_mobi_form_select m_mobi_form_input_text",
        style: _vm.itemInputStyle,
        attrs: {
          readonly: "",
          placeholder: _vm.placeholder,
          disabled: _vm.disabled,
        },
        domProps: { value: _vm.getContentText(_vm.value) },
        on: {
          click: function ($event) {
            $event.stopPropagation()
            return _vm.toggleList.apply(null, arguments)
          },
        },
      }),
      _vm._v(" "),
      _c("i", {
        staticClass: "faisco-icons-S000112",
        class: _vm.iconClass,
        on: {
          click: function ($event) {
            $event.stopPropagation()
            return _vm.toggleList.apply(null, arguments)
          },
        },
      }),
      _vm._v(" "),
      _c(
        "PopupLayerComponent",
        {
          attrs: { "is-link-to-form": _vm.isLinkToForm },
          model: {
            value: _vm.mobiShowList,
            callback: function ($$v) {
              _vm.mobiShowList = $$v
            },
            expression: "mobiShowList",
          },
        },
        [
          _c("div", { staticClass: "p_mobi_picker" }, [
            _c("div", { staticClass: "header" }, [
              _c(
                "div",
                { staticClass: "btn cancel", on: { click: _vm.mobiCancel } },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "btn confirm", on: { click: _vm.mobiConfirm } },
                [_vm._v("确定")]
              ),
            ]),
            _vm._v(" "),
            _c(
              "ul",
              {
                ref: "list",
                staticClass: "list",
                on: {
                  touchstart: _vm.onTouchStart,
                  mousedown: _vm.onTouchStart,
                },
              },
              _vm._l(_vm.list, function (item, index) {
                return _c("li", { key: index, staticClass: "item" }, [
                  _vm._v(_vm._s(_vm.getContentText(item))),
                ])
              }),
              0
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }