import { ing } from '@/shared/tips';
import { productKnowPayBtnClick } from '@/modules/shared/product/knowPay/btn';
import { logDog } from '@/shared/log';
import { storageReturnUrl } from '@/shared/url';
import { preprocessUrl } from '@/shared/url';
import { addRequestPrefix } from '@/shared/util';
import { changeHref } from '@/features/router/universal';

export function checkKnowPayVideo(productId, openTry) {
    if (productId == null || productId < 1) return;
    if (VITE_APP_MODE !== 'visitor') {
        if (openTry) {
            ing('当前为管理态，不支持试看');
        } else {
            ing('当前为管理态，不支持购买');
        }
    } else {
        jm.ajax({
            type: 'post',
            url:
                addRequestPrefix({ newPath: '/ajax', oldPath: 'ajax' }) +
                '/product_h.jsp?cmd=getWafNotCk_getKnowProductPayStatus',
            data: 'productId=' + productId,
            success(res) {
                let result = jm.parseJSON(res);
                if (result.success) {
                    playKnowPayVideoByPath(productId, result);
                } else {
                    if (result.isLogin != null && result.isLogin === false) {
                        storageReturnUrl(Fai.top.location.href);
                        changeHref(preprocessUrl({ path: '', oldPath: '' }) + 'login.jsp');
                        return;
                    }
                    ing(result.msg);
                }
            },
            error() {},
        });
    }
}

function playKnowPayVideoByPath(productId, options) {
    var videoMask = document.getElementById('knowproductVideoMask');
    var knowPayVideo = document.getElementById('knowPayVideo');
    if (options.isConvertToM3u8Success) {
        if (options.useM3u8) {
            jm('.productBullet').hide();
            jm('.productDetailHeader').hide();
            jm('.productDetailHeaderV3').hide();

            videoMask.style.display = 'block';

            if (Hls.isSupported()) {
                let hls = new Hls();
                hls.loadSource(options.mediaPath);
                hls.attachMedia(knowPayVideo);
                hls.on(Hls.Events.MANIFEST_PARSED, function () {
                    knowPayVideo.play();
                });
            } else if (knowPayVideo.canPlayType('application/vnd.apple.mpegurl')) {
                knowPayVideo.src = options.mediaPath;
                jm(knowPayVideo)
                    .unbind('loadedmetadata.knowPayVideo')
                    .bind('loadedmetadata.knowPayVideo', function () {
                        knowPayVideo.play();
                    });
            }

            if (!options.isPay) {
                let timeout = null;
                jm(knowPayVideo)
                    .unbind('ended.knowPayVideo')
                    .bind('ended.knowPayVideo', function () {
                        // 处理用户直接拖拽到最后，导致生成很多个弹窗
                        if (timeout != null) clearTimeout(timeout);
                        timeout = setTimeout(function () {
                            createKnowPayPopup(productId);
                        }, 100);
                    });
            }
        } else {
            ing('视频加密大概需要1-5分钟，请加密完成后再播放观看');
        }
    } else {
        ing('视频格式有误，请联系管理员更新视频');
    }
    if (options.isPay) {
        logDog(201356, 1);
    } else {
        logDog(201356, 2);
    }
}

function createKnowPayPopup(productId) {
    let template = `
        <div id="knowPayPopupContainer" class="knowPayPopupContainer">
            <div class="knowPayPopupBg"></div>
            <div class="knowPayPopupContent">
                <div class="knowPayPopupTip">
                    <div class="knowPayPopupTitle">试看结束</div>
                    <div class="knowPayPopupTips">试看已结束，如需要观看完整视频请单独购买</div>
                </div>
                <div class="knowPayPopupBtnGroup">
                    <div class="knowPayPopupCancel">取消</div>
                    <div class="knowPayPopupClip"></div>
                    <div class="knowPayPopupConfirm">购买</div>
                </div>
            </div>
        </div>
    `;
    if (jm('#knowPayPopupContainer').length) {
        jm('#knowPayPopupContainer').remove();
    }
    jm('#g_body').append(template);
    jm('body').css('overflow', 'hidden'); // 阻止滚动穿透
    jm('.knowPayPopupCancel')
        .unbind('click.knowPayPopup')
        .bind('click.knowPayPopup', function () {
            jm('.knowPayPopupConfirm').unbind('click.knowPayPopupConfirm');
            jm('.knowPayPopupCancel').unbind('click.knowPayPopup');
            jm('.knowPayPopupBg').unbind('touchmove.knowPayPopup');
            jm('.knowPayPopupContent').unbind('touchmove.knowPayPopup');
            jm('.knowPayPopupContainer').unbind('touchmove.knowPayPopup');
            jm('#knowPayPopupContainer').remove();
            jm('body').css('overflow', 'initial');
            return false;
        });
    jm('.knowPayPopupConfirm')
        .unbind('click.knowPayPopupConfirm')
        .bind('click.knowPayPopupConfirm', function () {
            if (productId != null) {
                productKnowPayBtnClick(productId);
            }
            return false;
        });
    jm('.knowPayPopupBg')
        .unbind('touchmove.knowPayPopup')
        .bind('touchmove.knowPayPopup', function (e) {
            e.preventDefault();
        });
    jm('.knowPayPopupContent')
        .unbind('touchmove.knowPayPopup')
        .bind('touchmove.knowPayPopup', function (e) {
            e.preventDefault();
        });
    jm('.knowPayPopupContainer')
        .unbind('touchmove.knowPayPopup')
        .bind('touchmove.knowPayPopup', function (e) {
            e.preventDefault();
        });
}

export function productKnowPayVideoQuit() {
    document.getElementById('knowproductVideoMask').style.display = 'none';
    document.getElementById('knowPayVideo').load();
    jm('.productBullet').show();
    if (Fai.top._other.templateInfo.templateDesignType != 2) {
        jm('.productDetailHeader').show();
    }
    if (Fai.top._openThemeV3) {
        jm('.productDetailHeaderV3').show();
    }
}
