<template>
    <div>
        <div :class="[iconClass]" class="line">
            <div class="lineBody" cellpadding="0" cellspacing="0">
                <a
                    :href="hrefStr"
                    :sf="sfStr"
                    hidefocus="true"
                    class="word"
                    :onclick="reqArg === '' ? null : reqArg"
                    @click="clickThis"
                    v-html="itemStr"
                >
                </a>
            </div>
        </div>
        <div class="separatorLine g_separator"></div>
    </div>
</template>

<script>
import { highLightKeyword, getGroupNameLs } from '../../util';
import { mapFlag } from '@/shared/flag';
import { mapState } from 'vuex';
import { encodeHtml } from '@/shared/util';
export default {
    name: 'TextItem',
    inject: ['isSpider', 'keyword', 'content', 'module'],
    props: ['item'],
    data() {
        let { searchFlag, href = '', groupName = '', groupNameStr = '', photo = {}, reqArg, hrefUrl } = this.item;
        return {
            searchFlag,
            href,
            groupName,
            groupNameStr,
            isSearchFlag3: searchFlag == 3,
            iconClass: searchFlag == 6 ? 'icon-download' : 'icon-gline',
            sfStr: this.isLowLevel ? this.searchFlag : null,
            photo,
            reqArg,
            hrefUrl,
        };
    },
    computed: {
        ...mapState('app', ['LS']),
        ...mapFlag({
            groupPrefixNews: 0x1,
            groupPrefixProduct: 0x2,
            groupPrefixPhotogroup: 0x4,
        }),
        contentText() {
            let name = this.item.title;
            if (this.searchFlag == 6) {
                let { info } = this.item;
                let { file } = info;
                name = file.name;
            }
            return name;
        },
        prefixOpen() {
            return this.content.po;
        },
        groupNameWrap() {
            // 优先输出groupName的情况下，就不需要groupNameStr了
            return this.groupNameStr && !this.groupName ? this.getStrongHtml(this.groupNameStr) : '';
        },
        groupPrefix() {
            return this.isLowLevel ? '' : this.groupNameWrap;
        },
        titleType() {
            let str = '';
            if (this.prefixOpen == 0) {
                str = '';
            } else {
                str = this.getStrongHtml(getGroupNameLs(this.searchFlag, this.LS));
                str += this.isSearchFlag3 ? highLightKeyword(this.groupName, this.keyword) + '--' : '';
            }
            return str;
        },
        fixGroupPrefix() {
            // 前端过滤分类前缀
            let showGroup = false;
            switch (this.searchFlag) {
                case 2: // 新闻
                    if (this.groupPrefixNews) {
                        showGroup = true;
                    }
                    break;
                case 1: // 产品
                    if (this.groupPrefixProduct) {
                        showGroup = true;
                    }
                    break;
                case 5: // 产品
                    if (this.groupPrefixPhotogroup) {
                        showGroup = true;
                    }
                    break;

                default:
                    break;
            }
            return showGroup ? this.groupPrefix : '';
        },
        itemStr() {
            let htmlStr = this.titleType + this.fixGroupPrefix + highLightKeyword(this.contentText, this.keyword);
            if (this.isSearchFlag3) {
                htmlStr = `${htmlStr}<img width='100%' height='100%' lazysrc='${this.photo.picPath}' data-picId='${this.photo.pic}' style=' position: absolute; left:0; top:-0.03rem; opacity:0;'/>`;
            }
            return htmlStr;
        },
        isLowLevel() {
            return this.searchFlag == 6 && this.href.startsWith('lowLevel');
        },
        hrefStr() {
            if (this.isLowLevel) {
                return this.isSpider ? null : 'javascript:void(0)';
            } else {
                if (
                    VITE_APP_MODE !== 'visitor' &&
                    (this.hrefUrl.indexOf('http') != -1 || this.hrefUrl.indexOf('https') != -1)
                ) {
                    return "javascript:top.Fai.ing('管理态不支持跳转外部链接，请通过“预览”进行访问', true)";
                }
                return this.hrefUrl == '' ? null : this.hrefUrl;
            }
        },
        photoStyle() {
            return {
                position: 'absolute',
                left: 0,
                top: '-0.03rem',
                opacity: 0,
            };
        },
    },
    methods: {
        clickThis() {
            if (!this.isLowLevel) {
                return;
            }
            const levelData = this.href.split(',');
            this.$ing(jm.format(this.LS.memberDownloadLevelLimit, encodeHtml(levelData[1]), levelData[2]));
        },
        getStrongHtml(name) {
            return "<strong style='padding-right:0.25rem;'>[" + name + ']</strong>';
        },
    },
};
</script>
