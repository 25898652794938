var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "count_down_time_item" }, [
    _c("div", { style: _vm.textStyle }, [
      _c("div", { ref: "numberText", style: _vm.lastNumberStyle }, [
        _vm._v(_vm._s(_vm.numberText)),
      ]),
      _vm._v(" "),
      _c("div", { style: _vm.lastUnitStyle }, [_vm._v(_vm._s(_vm.unitText))]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "count_down_sign", style: _vm.signStyle }, [
      _vm._v(":"),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }