var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "photoImageItem photoSwipeItem J_also_resize newsLine",
      attrs: {
        id: "newsLine" + _vm.item.id,
        "data-picId": _vm.item.picId || _vm.item.pic,
        topClassName: _vm._topClassName,
        topSwitch: _vm._topSwitch,
        newsId: _vm.item.id,
        newsName: _vm.item.title,
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "photoImageItemContent lineBody",
          style: _vm.itemContentStyle,
          attrs: { id: "lineBody" + _vm.item.id },
        },
        [
          _c(
            "a",
            {
              staticClass: "imgLink J_img_lazyload",
              class: _vm.imgCls,
              style: _vm.imgStyle,
              attrs: {
                href: _vm.imgHref,
                "src-original": _vm.item.realPicId
                  ? _vm.item.bigPicPath
                  : _vm.module.extInfo.noPicPath,
                hidefocus: "true",
                onclick: _vm.jumpOnclickStr,
              },
            },
            [
              _c("div", [
                _c(
                  "div",
                  { staticClass: "imgDescBox", class: _vm.imgDescBoxCls },
                  [
                    _c("div", {
                      staticClass: "imgName g_carouselPhoto_nameLink",
                      style: _vm.imgNameStyle,
                      domProps: { innerHTML: _vm._s(_vm.itemName) },
                    }),
                  ]
                ),
              ]),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }