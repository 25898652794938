<template>
    <div
        :id="photoItem.picId"
        class="multi_photo_item multi_vertical_item"
        :data-picid="photoItem.picId"
        :style="photoItemStyle"
    >
        <div :style="photoItemLinkStyle">
            <a
                :hidefocus="linkFocus"
                :href="imgHref"
                :rel="photoItem.nf ? 'nofollow' : ''"
                :onclick="jumpOnclickStr"
                @click="photoSlidesshow"
            >
                <div
                    v-drag-resize="_resizeOptions"
                    class="J_img_lazyload"
                    :src-original="photoItem.picThumbPath"
                    :style="photoItemImgStyle"
                ></div>
                <!-- <img :class="photoListImgClass" :src='photoItem.picThumbPath' :alt='photoItem.picId' :style='photoItemImgStyle'> -->
                <div :style="verticalTextStyle">
                    <div v-show="showTitle" class="vertical_title" :style="titleWrapStyle" v-html="titleData"></div>
                    <div v-show="showDesc" class="vertical_desc" :style="descWrapStyle" v-html="descData"></div>
                </div>
            </a>
        </div>
    </div>
</template>
<script>
import { photoTextAndImgStyleMap, photoTitleStyleMap, photoImgScaleStyleMap } from '../../utils';

import { photoMixins } from '../../mixins';

export default {
    name: 'VerticalItem',
    mixins: [photoMixins],
    props: {
        maxHeight: {
            type: Number,
        },
        listLength: {
            type: Number,
            default: 0,
        },
    },
    computed: {
        photoItemStyle() {
            let style = {};
            if (this.isNewModule) {
                if (this.picSpace.type == 0) {
                    // Object.assign(style, photoItemStyleMap[this.columnsType]);	// 默认宽高
                    // if(!this.showTitle && !this.showDesc) style['margin-bottom'] = "0.5rem"
                } else {
                    style['box-sizing'] = 'border-box';
                    style['margin-bottom'] = '0';
                }
                let photoItemStyle = {};
                switch (this.columnsType) {
                    // case 0: Object.assign(style, this.photoItemStyleOne());break;
                    // case 1: Object.assign(style, this.photoItemStyleTwo());break;
                    // case 2: Object.assign(style, this.photoItemStyleThree());break;
                    case 0:
                        photoItemStyle = this.photoItemStyleOne();
                        break;
                    case 1:
                        photoItemStyle = this.photoItemStyleTwo();
                        break;
                    case 2:
                        photoItemStyle = this.photoItemStyleThree();
                        break;
                }
                style = { ...style, ...photoItemStyle };
            } else {
                let mol = this.imgIndex % 2;
                style['width'] = '50%';
                if (mol == 1) {
                    style['padding-left'] = '3.25%';
                    style['padding-right'] = '1.75%';
                } else {
                    style['padding-left'] = '1.75%';
                    style['padding-right'] = '3.25%';
                }
                style['box-sizing'] = 'border-box';
                style['margin-bottom'] = '0.5rem';
            }

            return style;
        },
        photoItemLinkStyle() {
            let style = {};
            style['display'] = 'block';
            style['width'] = '100%';
            style['height'] = '100%';
            style['box-sizing'] = 'border-box';
            // Object.assign(style, photoTextAndImgStyleMap[this.textStyleType]);
            // Object.assign(style, this.picLinkStyle);
            style = {
                ...style,
                ...photoTextAndImgStyleMap[this.textStyleType],
                ...this.picLinkStyle,
            };

            // 设置radius
            style['border-radius'] =
                this.photoRadius.type == 1 ? this.photoRadius.size / this.htmlFontSize + 'rem' : '0.2rem';
            // style['padding'] = '1px';	// 处理边框border可能被消除情况
            return style;
        },
        photoItemImgStyle() {
            let style = {};
            style['background-image'] = `url(${this.noLazyLoad ? this.photoItem.picThumbPath : this.loadingPath})`;

            // Object.assign(style, photoImgScaleStyleMap[this.picScaleType]);
            style = { ...style, ...photoImgScaleStyleMap[this.picScaleType] };

            style['background-position'] = 'bottom center';

            if (this.picSize.htype == 1) {
                style['height'] = this.picSize.rh + 'rem';
            } else {
                if (this.picScaleType == 1) {
                    style['height'] = '8rem'; // 拉伸原先是固定 8rem
                } else {
                    style['height'] = this.maxHeight + 'rem';
                }
            }

            if ((this.moduleStyle == 3 || this.moduleStyle == 5) && this.picSize.wtype == 1) {
                style['width'] = this.picSize.width / this.htmlFontSize + 'rem';
            }

            if (this.photoRadius.type == 1) {
                style['border-radius'] = `${this.photoRadius.size / this.htmlFontSize + 'rem'} ${
                    this.photoRadius.size / this.htmlFontSize + 'rem'
                } 0 0`;
            } else {
                style['border-radius'] = '0.2rem 0.2rem 0 0';
            }

            if (this.textStyleType == 3) {
                style['border-radius'] =
                    this.photoRadius.type == 1 ? this.photoRadius.size / this.htmlFontSize + 'rem' : '0.2rem';
            }
            // 隐藏名称以及描述
            if (!this.showTitle && !this.showDesc) {
                style['border-radius'] =
                    this.photoRadius.type == 1 ? this.photoRadius.size / this.htmlFontSize + 'rem' : '0.2rem';
            }

            if (this.isEmpty) {
                style['border-radius'] =
                    this.photoRadius.type == 1 ? this.photoRadius.size / this.htmlFontSize + 'rem' : '0.2rem';
            }

            return style;
        },
        titleWrapStyle() {
            let style = {};
            style['box-sizing'] = 'border-box';
            let wrapStyle = this.wrapStyle(this.titleIsWrap, this.moduleStyle);
            let linkStyle = this.textLinkStyle(this.titleLink);
            // Object.assign(style, photoTitleStyleMap[this.columnsType]);
            // Object.assign(style, this.wrapStyle(this.titleIsWrap, this.moduleStyle));
            // Object.assign(style, this.textLinkStyle(this.titleLink));
            style = {
                ...photoTitleStyleMap[this.columnsType],
                ...wrapStyle,
                ...linkStyle,
            };
            if (this.columnsType == 2 && this.titleLink.type == 0) {
                style['font-size'] = '0.6rem';
            }
            style['text-align'] = this.textAlignStyle;
            return style;
        },
        descWrapStyle() {
            let style = {};
            style['box-sizing'] = 'border-box';
            // Object.assign(style, this.wrapStyle(this.descIsWrap, this.moduleStyle));
            // Object.assign(style, this.textLinkStyle(this.nameLink));
            let wrapStyle = this.wrapStyle(this.descIsWrap, this.moduleStyle);
            let linkStyle = this.textLinkStyle(this.nameLink);

            style = { ...style, ...wrapStyle, ...linkStyle };

            if (this.nameLink.type == 0) {
                style['font-size'] = '0.55rem';
                style['line-height'] = '0.75rem';
                style['color'] = '#666';
                if (!this.isNewModule) {
                    style['font-size'] = '0.6rem';
                }
            }

            style['text-align'] = this.textAlignStyle;

            if (this.showTitle) {
                switch (this.columnsType) {
                    case 0:
                        style['margin-top'] = '0.25rem';
                        break;
                    case 1:
                        style['margin-top'] = '0.25rem';
                        break;
                    case 2:
                        style['margin-top'] = '0.25rem';
                        break;
                }
            }

            return style;
        },
        verticalTextStyle() {
            let style = {};
            style['box-sizing'] = 'border-box';
            style['padding-top'] = '0.4rem';
            switch (this.textStyleType) {
                case 0:
                    style['padding-left'] = '0.375rem';
                    style['padding-right'] = '0.375rem';
                    break;
                case 1:
                    style['padding-left'] = '0.4rem';
                    style['padding-right'] = '0.4rem';
                    break;
                case 2:
                    style['padding-left'] = '0.375rem';
                    style['padding-right'] = '0.375rem';
                    break;
            }
            // Object.assign(style, this.spacePaddingStyle);
            style = { ...style, ...this.spacePaddingStyle };
            if (this.isEmpty) {
                style['display'] = 'none';
            }
            return style;
        },
        spacePaddingStyle() {
            let style = {};
            if (this.textStyleType != 3) style['padding-bottom'] = '0.65rem';

            if (this.columnsType == 2) {
                style['padding-bottom'] = '0.525rem';
            }

            if (!this.showDesc && !this.showTitle) {
                style['padding-bottom'] = 0;
            }

            return style;
        },
    },
    methods: {
        photoItemStyleOne() {
            let style = {};
            if (this.picSpace.type == 0) {
                style['margin-bottom'] = '1rem';
            } else {
                if (this.imgIndex != this.listLength) {
                    style['paddingBottom'] = `${this.picSpace.vertical / this.htmlFontSize}rem`;
                }
                style['paddingLeft'] = ` ${(this.picSpace.horizontal * 0.5) / this.htmlFontSize}rem`;
                style['paddingRight'] = `${(this.picSpace.horizontal * 0.5) / this.htmlFontSize}rem}`;
            }
            return style;
        },
        photoItemStyleTwo() {
            let style = {};
            let isRight = this.imgIndex % 2 == 0;
            style['width'] = '50%';

            if (this.picSpace.type == 0) {
                style['box-sizing'] = 'border-box';
                if (!isRight) {
                    style['padding-right'] = '0.25rem';
                } else {
                    style['padding-left'] = '0.25rem';
                }

                style['margin-bottom'] = '0.75rem';
            } else {
                let horizontal = this.picSpace.horizontal / this.htmlFontSize;
                let vertical = this.picSpace.vertical / this.htmlFontSize;

                if (!isRight) {
                    // style['padding-left'] = horizontal * 3 + 'rem';
                    style['padding-right'] = horizontal * 0.5 + 'rem';
                } else {
                    // style['padding-right'] = horizontal * 3 + 'rem';
                    style['padding-left'] = horizontal * 0.5 + 'rem';
                }

                let mol = this.listLength % 2;
                if (mol == 0) {
                    if (this.imgIndex < this.listLength - 1) {
                        style['padding-bottom'] = vertical + 'rem';
                    }
                } else {
                    if (this.imgIndex < this.listLength) {
                        style['padding-bottom'] = vertical + 'rem';
                    }
                }
            }
            return style;
        },
        photoItemStyleThree() {
            let style = {};
            let mol = this.imgIndex % 3;

            style['width'] = '33.33%';
            if (this.picSpace.type == 0) {
                switch (mol) {
                    case 0:
                        style['padding-left'] = '0.25rem';
                        break; // 最后一列
                    case 1:
                        style['padding-right'] = '0.25rem';
                        break; // 第一列
                    case 2:
                        style['padding-left'] = '0.25rem';
                        style['padding-right'] = '0.25rem';
                        break; // 中间列
                }
                // style['padding-left'] = '0.25rem';
                // style['padding-right'] = '0.25rem';
                style['box-sizing'] = 'border-box';
                style['margin-bottom'] = '0.5rem';
            } else {
                let horizontal = this.picSpace.horizontal / this.htmlFontSize;
                let vertical = this.picSpace.vertical / this.htmlFontSize;

                switch (mol) {
                    case 0:
                        style['padding-left'] = horizontal * (2 / 3) + 'rem';
                        break; // 最后一列
                    case 1:
                        style['padding-right'] = horizontal * (2 / 3) + 'rem';
                        break; // 第一列
                    case 2:
                        style['padding-left'] = horizontal * (1 / 3) + 'rem';
                        style['padding-right'] = horizontal * (1 / 3) + 'rem';
                        break; // 中间列
                }

                let listMol = this.listLength % 3;
                if (listMol == 1) {
                    if (this.imgIndex < this.listLength) {
                        style['padding-bottom'] = vertical + 'rem';
                    }
                } else if (listMol == 2) {
                    if (this.imgIndex < this.listLength - 1) {
                        style['padding-bottom'] = vertical + 'rem';
                    }
                } else {
                    if (this.imgIndex < this.listLength - 2) {
                        style['padding-bottom'] = vertical + 'rem';
                    }
                }
            }
            return style;
        },
    },
};
</script>
<style>
.multi_photo_list .multi_photo_item {
    border-radius: 0.2rem;
}
.multi_photo_list .multi_photo_item .vertical_title {
    color: #333;
    font-weight: bold;
}

.multi_photo_list .multi_photo_item .vertical_desc {
    color: #222222;
}

.multi_photo_list .multi_photo_item .wrapText {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}
</style>
