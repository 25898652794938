var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.index === 0
    ? _c(
        "div",
        {
          class: _vm.bigItemClasses,
          attrs: {
            id: "newsLine" + _vm.info.id,
            topClassName: _vm._topClassName,
            topSwitch: _vm._topSwitch,
            newsId: _vm.info.id,
            newsName: _vm.info.title,
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "lineBody",
              class: [_vm.isNewAddModuleClass, _vm.isOnlyTitle],
              attrs: { id: "lineBody" + _vm.info.id },
            },
            [
              _c("div", { staticClass: "mixNewsStyleImgBox4" }, [
                _c(
                  "a",
                  {
                    directives: [
                      {
                        name: "drag-resize",
                        rawName: "v-drag-resize",
                        value: _vm._resizeOptions,
                        expression: "_resizeOptions",
                      },
                    ],
                    staticClass: "mixNewsStyleImgBoxWrap",
                    attrs: {
                      hidefocus: "true",
                      href: _vm._newsUrl,
                      target: _vm._jumpTarget,
                      onclick: _vm.onclickStr,
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "headPicTopWrap" },
                      [
                        _c("div", {
                          staticClass: "picScaleClass J_img_lazyload",
                          class: _vm.bigPicClasses,
                          style: _vm.bigPicStyle,
                          attrs: {
                            id: "newsTitlePic",
                            "src-original": _vm.info.realPicId
                              ? _vm.info.bigPicPath
                              : "",
                            alt: _vm.altName,
                          },
                        }),
                        _vm._v(" "),
                        _vm._topFlagClass != "" && _vm.isNewAddModule
                          ? _c("top-component")
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "mixNewsStyleTitleContainer4",
                        class: _vm.isTextInner,
                      },
                      [
                        !_vm.isNewAddModule
                          ? _c("div", { class: _vm._topFlagClass })
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.isNewAddModule
                          ? _c(
                              "div",
                              {
                                staticClass: "news_title",
                                class: [_vm.titleClasses, _vm.titleLine],
                                style: _vm.news_title_style,
                              },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(_vm.info.title) +
                                    "\n                    "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.isNewAddModule && _vm.module.content.ts === 0
                          ? _c(
                              "div",
                              {
                                staticClass: "news_title",
                                class: [_vm.titleClasses, _vm.titleLine],
                              },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(_vm.info.title) +
                                    "\n                    "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.titleBottom
                      ? _c(
                          "div",
                          {
                            staticClass: "news_title textOuter",
                            class: [
                              _vm.titleClasses,
                              _vm.titleLine,
                              _vm.descriptionLine,
                            ],
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.info.title) +
                                "\n                "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _vm.module.content.ts == 1
            ? _c("div", { staticClass: "g_separator separatorLine" })
            : _vm._e(),
        ]
      )
    : _c(
        "div",
        {
          class: _vm.itemClasses,
          attrs: {
            id: "newsLine" + _vm.info.id,
            topClassName: _vm._topClassName,
            topSwitch: _vm._topSwitch,
            newsId: _vm.info.id,
            newsName: _vm.info.title,
          },
        },
        [
          _c(
            "a",
            {
              staticClass: "lineBodyLink",
              class: _vm.isOnlyTitle,
              staticStyle: { width: "auto" },
              attrs: {
                href: _vm._newsUrl,
                hidefocus: "true",
                target: _vm._jumpTarget,
                onclick: _vm.onclickStr,
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "lineBody lineBodyPic",
                  attrs: { id: "lineBody" + _vm.info.id },
                },
                [
                  _vm._topFlagClass != "" && !_vm.isNewAddModule
                    ? _c("div", { staticClass: "topFlagTableCell" }, [
                        _c("div", { class: _vm._topFlagClass }),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.module.content.tPosition === 1
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "picTableCell picTableCellLeft J_img_wrap",
                          style: _vm.picTextStyle,
                          attrs: { alt: _vm.altName },
                        },
                        [
                          _c("div", {
                            directives: [
                              {
                                name: "drag-resize",
                                rawName: "v-drag-resize",
                                value: _vm._resizeOptions,
                                expression: "_resizeOptions",
                              },
                            ],
                            staticClass:
                              "picRadiusClass picScaleClass J_img_lazyload",
                            class: _vm.picTextClasses,
                            style: _vm.picItemStyle,
                            attrs: {
                              id: "newsImg" + _vm.info.id,
                              alt: _vm.altName,
                              "src-original": _vm.info.realPicId
                                ? _vm.info.pic160Path
                                : "",
                            },
                          }),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "fontTableCell" }, [
                    _c("div", { staticClass: "fontHiddenTable" }, [
                      !_vm.isNewAddModule
                        ? _c(
                            "div",
                            {
                              staticClass: "twoLineHidden g_link g_news_title",
                              style: _vm.news_title_style,
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(_vm.info.title) +
                                  "\n                    "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isNewAddModule
                        ? _c(
                            "div",
                            {
                              staticClass: "g_link g_news_title newTitle",
                              class: _vm.titleLine,
                              style: _vm.news_title_style,
                            },
                            [
                              _vm._topFlagClass != "" && _vm.isNewAddModule
                                ? _c("top-component")
                                : _vm._e(),
                              _vm._v(
                                _vm._s(_vm.info.title) +
                                  "\n                    "
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isNewAddModule && !_vm.module.content.des
                        ? _c("div", { staticClass: "felxTextItem" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "twoLine articleSummray g_text description g_news_abstract newDescriptionLine",
                                class: [
                                  _vm.descriptionLine,
                                  _vm.isOnlyTitleAndDes,
                                ],
                                style: _vm.news_abstract_style,
                              },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(_vm.info.summary) +
                                    "\n                        "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _vm.isNewAddModule &&
                            _vm.showOtherInfo &&
                            _vm.textPositin == 2
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "newsElementsPanel g_newsElementsPanel J_newsElementsPanel",
                                    on: { click: _vm.otherInfoClick },
                                  },
                                  [
                                    _vm._showComment
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "ne_newsComment faisco-icons-comment",
                                          },
                                          [
                                            !_vm.isNewAddModule
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "newsCommentCount",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.info.commentCount
                                                      )
                                                    ),
                                                  ]
                                                )
                                              : _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "newsCommentCount g_newsInfo",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.info.commentCount
                                                      ) +
                                                        _vm._s(
                                                          _vm.module.extInfo
                                                            .commentText
                                                        )
                                                    ),
                                                  ]
                                                ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm._showAuthor
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "ne_newsAuthor",
                                            style: _vm.authorStyle,
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "newsAuthorName g_newsInfo",
                                              },
                                              [_vm._v(_vm._s(_vm.info.author))]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    (_vm._showAuthor || _vm._showComment) &&
                                    (_vm._showSort || _vm._showDate)
                                      ? _c("div", {
                                          staticClass:
                                            "ne_separatorLine g_ne_separatorLine",
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm._showSort
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "ne_newsSort g_newsInfo",
                                          },
                                          [
                                            _vm._v(
                                              "\n                                " +
                                                _vm._s(_vm.info.groupName) +
                                                "\n                            "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm._showDate
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "ne_newsTime g_newsInfo",
                                          },
                                          [
                                            _vm._v(
                                              "\n                                " +
                                                _vm._s(_vm.newsDate) +
                                                "\n                            "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                )
                              : _vm._e(),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isNewAddModule &&
                      _vm.showOtherInfo &&
                      _vm.textPositin == 2 &&
                      _vm.module.content.des
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "newsElementsPanel g_newsElementsPanel J_newsElementsPanel",
                              on: { click: _vm.otherInfoClick },
                            },
                            [
                              _vm._showComment
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "ne_newsComment faisco-icons-comment",
                                    },
                                    [
                                      !_vm.isNewAddModule
                                        ? _c(
                                            "span",
                                            { staticClass: "newsCommentCount" },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.info.commentCount)
                                              ),
                                            ]
                                          )
                                        : _c(
                                            "span",
                                            {
                                              staticClass:
                                                "newsCommentCount g_newsInfo",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.info.commentCount) +
                                                  _vm._s(
                                                    _vm.module.extInfo
                                                      .commentText
                                                  ) +
                                                  "\n                            "
                                              ),
                                            ]
                                          ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm._showAuthor
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "ne_newsAuthor",
                                      style: _vm.authorStyle,
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "newsAuthorName g_newsInfo",
                                        },
                                        [_vm._v(_vm._s(_vm.info.author))]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              (_vm._showAuthor || _vm._showComment) &&
                              (_vm._showSort || _vm._showDate)
                                ? _c("div", {
                                    staticClass:
                                      "ne_separatorLine g_ne_separatorLine",
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm._showSort
                                ? _c(
                                    "div",
                                    { staticClass: "ne_newsSort g_newsInfo" },
                                    [
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(_vm.info.groupName) +
                                          "\n                        "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm._showDate
                                ? _c(
                                    "div",
                                    { staticClass: "ne_newsTime g_newsInfo" },
                                    [
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(_vm.newsDate) +
                                          "\n                        "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          )
                        : _vm._e(),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm.module.content.tPosition === 2
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "picTableCell picTableCellRight J_img_wrap",
                          style: _vm.picTextStyle,
                          attrs: { alt: _vm.altName },
                        },
                        [
                          _c("div", {
                            directives: [
                              {
                                name: "drag-resize",
                                rawName: "v-drag-resize",
                                value: _vm._resizeOptions,
                                expression: "_resizeOptions",
                              },
                            ],
                            staticClass:
                              "picRadiusClass picScaleClass J_img_lazyload",
                            class: _vm.picTextClasses,
                            style: _vm.picItemStyle,
                            attrs: {
                              id: "newsImg" + _vm.info.id,
                              alt: _vm.altName,
                              "src-original": _vm.info.realPicId
                                ? _vm.info.pic160Path
                                : "",
                            },
                          }),
                        ]
                      )
                    : _vm._e(),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          (!_vm.isNewAddModule && _vm.showOtherInfo) ||
          (_vm.isNewAddModule && _vm.showOtherInfo && _vm.textPositin == 1)
            ? _c(
                "div",
                {
                  staticClass:
                    "newsElementsPanel g_newsElementsPanel J_newsElementsPanel",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.otherInfoClick.apply(null, arguments)
                    },
                  },
                },
                [
                  _vm._showComment
                    ? _c(
                        "div",
                        { staticClass: "ne_newsComment faisco-icons-comment" },
                        [
                          !_vm.isNewAddModule
                            ? _c("span", { staticClass: "newsCommentCount" }, [
                                _vm._v(_vm._s(_vm.info.commentCount)),
                              ])
                            : _c(
                                "span",
                                { staticClass: "newsCommentCount g_newsInfo" },
                                [
                                  _vm._v(
                                    _vm._s(_vm.info.commentCount) +
                                      _vm._s(_vm.module.extInfo.commentText) +
                                      "\n            "
                                  ),
                                ]
                              ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._showAuthor
                    ? _c(
                        "div",
                        {
                          staticClass: "ne_newsAuthor",
                          style: _vm.authorStyle,
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "newsAuthorName g_newsInfo" },
                            [_vm._v(_vm._s(_vm.info.author))]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  (_vm._showAuthor || _vm._showComment) &&
                  (_vm._showSort || _vm._showDate)
                    ? _c("div", {
                        staticClass: "ne_separatorLine g_ne_separatorLine",
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._showSort
                    ? _c("div", { staticClass: "ne_newsSort g_newsInfo" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.info.groupName) +
                            "\n        "
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._showDate
                    ? _c("div", { staticClass: "ne_newsTime g_newsInfo" }, [
                        _vm._v(
                          "\n            " + _vm._s(_vm.newsDate) + "\n        "
                        ),
                      ])
                    : _vm._e(),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "g_separator separatorLine" }),
        ]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }