import { bindImgLazyLoad } from '@/shared/imageLazyload';
import { browser } from '@jz/utils';

import { richMarquee } from '../../shared/richMarquee';
const { isWeChat, isIE10, isSafari } = browser;

export const photoItemStyleMap = [
    {
        width: '14.5rem',
        margin: '0 auto 1rem',
    },
    {
        width: '7rem',
        marginBottom: '0.75rem',
    },
    {
        width: '4.5rem',
        marginBottom: '0.5rem',
    },
];

export const photoTextAndImgStyleMap = {
    0: {
        boxShadow: '0 0.025rem 0.3rem 0 rgba(0, 0, 0, 0.1)',
    },
    1: {
        border: '1px solid #E5E5E5',
        boxSizing: 'border-box',
    },
    2: {
        background: '#F6F6F6',
    },
    3: {},
};

export const photoTitleStyleMap = {
    0: {
        fontSize: '0.7rem',
    },
    1: {
        fontSize: '0.65rem',
    },
};

export const photoImgScaleStyleMap = {
    2: {
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
    },
    0: {
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
    },
    1: {
        backgroundSize: '100% 100%',
        backgroundRepeat: 'no-repeat',
    },
    3: {
        backgroundSize: 'unset',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
    },
};

//pc模拟手机touch事件
const bindTouchEvent = function (select, downOpt, upOpt) {
    var selector = jm(select),
        ua = window.navigator.userAgent,
        isPhone = /(iPhone|iPad|iPod|iOS|Android)/i.test(ua),
        isMQQBrowser = /(MQQBrowser)/i.test(ua),
        isApple = /(iPhone|iPad|iPod|iOS)/i.test(ua),
        isWechat = isWeChat(),
        moveXDefined = window._htmlFontSize, // 超过多少认为是向左向右滑
        moveYDefined = window._htmlFontSize, // 超过多少认为是向上向下滑
        touchVertical = false, // touchmove时垂直滑手势
        defaultOption,
        obj,
        pos,
        canTouch,
        touchStart,
        touchEnd;

    if (isMQQBrowser) {
        moveYDefined = 8;
    }

    if (isApple) {
        moveYDefined = 4;
    }

    if (selector.length < 1) {
        return;
    }

    canTouch = 'ontouchstart' in window;
    touchStart = canTouch ? 'touchstart' : 'mousedown';
    touchEnd = canTouch ? 'touchend' : 'mouseup';

    obj = {
        touchMove: false, // 是否滑动
        toTop: false, // 向上
        toLeft: false, // 向左
        toRight: false, // 向右
        toBottom: false, // 向下
        mouseDownX: 0,
        mouseDownY: 0,
        mouseUpX: 0,
        mouseUpY: 0,
    };

    defaultOption = {
        stopPropagation: true, // 是否阻止冒泡
        callBack: '', // 回调方法
        args: [], // 传入回调方法里的参数
    };

    downOpt = jm.extend({}, defaultOption, downOpt);
    upOpt = jm.extend({}, defaultOption, upOpt);
    selector.each(function (index, el) {
        jm(el)
            .on(touchStart, function (event) {
                obj = reset(obj);
                pos = getTouchPos(event);
                obj.mouseDownX = pos.pageX;
                obj.mouseDownY = pos.pageY;
                if (!downOpt) {
                    return;
                }

                triggerCallBack(this, downOpt, event);
                if (downOpt.stopPropagation && !isPhone) {
                    //
                    event.stopPropagation(); // 取消sortable
                    event.preventDefault(); // 滑动时让图片不被选中
                }
            })
            .on(touchEnd, function (event) {
                pos = getTouchPos(event);
                obj.mouseUpX = pos.pageX;
                obj.mouseUpY = pos.pageY;

                if (!(obj.mouseDownX == obj.mouseUpX && obj.mouseDownY == obj.mouseUpY)) {
                    obj.touchMove = true;
                }

                if (obj.mouseDownX > obj.mouseUpX + moveXDefined) {
                    obj.toLeft = true;
                } else if (obj.mouseDownX + moveXDefined < obj.mouseUpX) {
                    obj.toRight = true;
                }

                if (obj.mouseUpY - obj.mouseDownY > moveYDefined) {
                    obj.toBottom = true;
                } else if (obj.mouseDownY - obj.mouseUpY > moveYDefined) {
                    obj.toTop = true;
                }

                triggerCallBack(this, upOpt, event);
                if (upOpt.stopPropagation && !isPhone) {
                    event.stopPropagation(); // 取消sortable
                    event.preventDefault(); // 滑动时让图片不被选中
                }
            });

        // 取消手机左右滑前进后退的功能
        if (isPhone) {
            jm(el).on('touchmove', function () {
                pos = getTouchPos(event);
                touchVertical = Math.abs(pos.pageY - obj.mouseDownY) >= moveYDefined;
                if (upOpt.stopPropagation && !touchVertical && !(isApple && isWechat)) {
                    event.preventDefault();
                }
            });
        }
    });

    function reset(obj) {
        obj.touchMove = false;
        obj.toTop = false;
        obj.toLeft = false;
        obj.toRight = false;
        obj.toBottom = false;
        obj.mouseDownX = 0;
        obj.mouseDownY = 0;
        obj.mouseUpX = 0;
        obj.mouseUpY = 0;
        return obj;
    }

    function triggerCallBack(This, opt, event) {
        var args = [],
            i;
        if (typeof opt.callBack != 'function') {
            return;
        }

        for (i = 0; i < opt.args.length; i++) {
            args.push(opt.args[i]);
        }

        args.push(obj);
        args.push(event);
        opt.callBack.apply(This, args);
    }

    //获取坐标点,兼容touchstart和touchend
    function getTouchPos(event) {
        var touch = event || window.event;
        if ('ontouchstart' in window) {
            touch =
                !!event.changedTouches && event.changedTouches.length > 0 ? event.changedTouches[0] : event.touches[0];
        }

        return {
            pageX: touch.pageX,
            pageY: touch.pageY,
        };
    }
};

const photoCardInit = function (moduleId) {
    var module = jm('#module' + moduleId),
        photoListCard = module.find('.J_photoListCard'),
        cardContainer = photoListCard.find('.J_cardContainer'),
        cardItem = photoListCard.find('li'),
        cardLength = cardItem.length,
        maxHeight = 0,
        itemH = 0,
        href,
        imgA,
        elem,
        img,
        imgNorW,
        imgContain,
        imgNorH,
        imgContainW,
        imgContainH,
        rate;

    if (cardLength > 1 && cardLength <= 3) {
        cardContainer.append(cardItem.clone(true, true));
        cardItem = photoListCard.find('li');
        cardLength = cardItem.length;
        for (var i = cardLength / 2; i < cardLength; i++) {
            jm(photoListCard.find('img').get(i)).addClass('garbage');
        }
    }

    imgContain = jm(cardItem.get(0)).find('.J_imgContainer');
    imgContainW = imgContain.width();
    imgContainH = imgContain.height();
    cardItem.each(function (index, el) {
        itemH = jm(el).height();
        maxHeight = maxHeight < itemH ? itemH : maxHeight;
    });
    cardItem.each(function (index, el) {
        elem = jm(el);
        img = elem.find('img');
        imgNorW = parseInt(img.attr('faiWidth'));
        imgNorH = parseInt(img.attr('faiHeight'));
        rate = scaleImg(imgNorW, imgNorH, imgContainW, imgContainH);
        img.css({
            height: rate.height + 'px',
            width: rate.width + 'px',
            marginTop: -parseFloat(rate.height / 2) + 'px',
            marginLeft: -parseFloat(rate.width / 2) + 'px',
            position: 'absolute',
        });

        elem.height(maxHeight + 'px');

        if (cardLength <= 1) return false;

        elem.on('webkitAnimationStart mozAnimationStart MSAnimationStart oanimationstart animationstart', function () {
            module.data('animated', 'running'); // 标记动画开始执行
        }).on('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function () {
            jm(this).removeClass('f-cardAnimation_right f-cardAnimation_left').addClass('f-showPic_0');
            // 标记动画执行结束
            module.data('animated', '');
            // 如果图片有超链接，则补上,避免滑动后触发链接
            imgA = jm(this).find('a');
            href = imgA.data('href');
            if (href) {
                imgA.attr('href', href);
            }
        });

        switch (index) {
            case 0:
                jm(el).addClass('f-showPic_1');
                break;
            case 1:
                jm(el).removeClass('f-showPic_1 f-showPic_2 f-showPic_3').addClass('f-showPic_2');
                break;
            case 2:
                jm(el).removeClass('f-showPic_1 f-showPic_2 f-showPic_3').addClass('f-showPic_3');
                break;
            default:
                jm(el).removeClass('f-showPic_1 f-showPic_2 f-showPic_3').addClass('f-showPic_0');
        }
    });

    // 这里设置容器 ul的高度，文字设置不换行或者内容过多时ul高度没被撑开 qjie --> 40px 代表第一张与第二张之间的间隔高度
    cardContainer.height(parseInt(maxHeight) + 40 + 'px');

    bindTouchEvent(cardItem, null, {
        callBack: upCallBack,
        args: [cardItem, module],
        stopPropagation: !(VITE_APP_MODE !== 'visitor'), // manangeMode 不阻止，fix 图片高度拖拽 mousedown 无法触发问题
    });

    // mouseUp的callBack函数
    function upCallBack(cardItem, module, result) {
        if (VITE_APP_MODE !== 'visitor' && (isIE10() || isSafari())) {
            Fai.top.Fai.ing('当下浏览器不支持查看效果，请在手机上查看，或更换最新浏览器', true);
            return;
        }

        var This = jm(this),
            cardLength = cardItem.length,
            showImgLength = 3,
            idx = [],
            i,
            href,
            animationClass;

        if (!result.touchMove) {
            This.find('img').trigger('click');
            return;
        }

        if (!(result.toRight || result.toLeft || module.data('animated') == 'running')) {
            return;
        }

        if (cardLength <= 1) {
            return false;
        }
        // 记录图片的超链接
        href = This.find('a').attr('href');
        if (href) {
            This.find('a').data('href', href).attr('href', 'javascript:void(0);');
        }

        animationClass = result.toRight ? 'f-cardAnimation_right' : 'f-cardAnimation_left';
        This.addClass(animationClass);
        cardItem.each(function (index, el) {
            jm(el).removeClass('f-showPic_0 f-showPic_1 f-showPic_2 f-showPic_3');
        });

        idx[0] = This.index();
        for (i = 1; i <= showImgLength; i++) {
            idx[i] = i <= cardLength ? (idx[0] + i) % cardLength : -1;
        }

        cardItem.each(function (index, el) {
            switch (index) {
                case idx[1]:
                    jm(el).addClass('f-showPic_1');
                    break; // 第一张   ----------------------trigger appear 触发图片懒加载  zhj
                case idx[2]:
                    jm(el).addClass('f-showPic_2');
                    break; // 第二张
                case idx[3]:
                    jm(el).addClass('f-showPic_3');
                    break; // 第三张
                case idx[0]:
                    break; // 正在滑动的
                default:
                    jm(el).addClass('f-showPic_0');
            }
        });
    }

    // img模拟background-size:cover效果
    function scaleImg(width, height, maxWidth, maxHeight) {
        var size = { width, height },
            maxRate = maxWidth / maxHeight,
            rate = width / height;

        if (rate < maxRate) {
            var rateWidth = width / maxWidth;
            size.width = maxWidth;
            size.height = height / rateWidth;
        } else if (rate > maxRate) {
            var rateHeight = height / maxHeight;
            size.width = width / rateHeight;
            size.height = maxHeight;
        }
        return size;
    }
};

const copyModulePhotoDetailSwipe = function (moduleId, queryClass) {
    var className = queryClass == null ? '.style2Img.photoListImg' : queryClass;
    var id = moduleId.substring(moduleId.lastIndexOf('e') + 1);
    var copyImages = $('#' + moduleId)
        .find('.J_contentPanelCopy' + id)
        .find(className);
    var trueImages = $('#' + moduleId)
        .find('#photoSlideList' + id)
        .find(className);
    $(copyImages).each(function (i, o) {
        $(o).on('click', function () {
            $(trueImages[i]).trigger('click');
        });
    });
};

const photoCrossedSlideSecSwipe = function (moduleId, photoSlideList, photoSlide, queryClass, options) {
    var itemContainerDom = document.getElementById(photoSlideList),
        photoDom = document.getElementById(photoSlide),
        itemContainer = itemContainerDom,
        centerWidth = parseInt(photoDom.offsetWidth),
        phoItemsWidth = 0,
        queryClassName = queryClass == null ? '.photoCrossedSlideSec' : queryClass,
        $phoItemArray = jm(queryClassName, photoDom);
    //fix outerWidth bug by jser 2017-3-29 推测是宽度计算上的差异，导致换行
    function getOuterWidth($obj) {
        var width = 0;
        var objWidth = $obj.css('width'); // 设置了rem的话，获取到的是 xxrem;
        if (objWidth.indexOf('rem') != -1) {
            width += $obj[0].offsetWidth; // 1rem = 40
        } else {
            width += parseFloat($obj.width()); //计算宽度 居然会获取到字符串。。。。
        }
        width += parseFloat($obj.css('padding-left')) + parseFloat($obj.css('padding-right')); //计算内边距
        width += parseFloat($obj.css('border-left-width')) + parseFloat($obj.css('border-right-width')); //计算边框
        width += parseFloat($obj.css('margin-left')) + parseFloat($obj.css('margin-right')); //计算外边距

        return width;
    }
    //计算每一个的宽度
    //$phoItemArray.each(function(index, phoItem){
    //  phoItemsWidth += getOuterWidth( $(phoItem) );
    //});

    //由于这里每个块都是等宽的，不依赖于每一个的宽，只取第一个即可
    if ($phoItemArray.length > 0) {
        phoItemsWidth = getOuterWidth(jm($phoItemArray[0])) * $phoItemArray.length;
        if (queryClass == '.newPhotoCrossedSlideSec') {
            let interval = 11;
            if (options != null && options.intervalOptions.type == 1) {
                interval = options.intervalOptions.horizontal;
            }
            phoItemsWidth += interval * ($phoItemArray.length - 1);
        }
    }

    if (phoItemsWidth > centerWidth) {
        if (navigator.appVersion.match(/MSIE [\d.]+/)) {
            if (parseInt(navigator.appVersion.match(/MSIE ([\d.]+)/)[1]) === 10) {
                phoItemsWidth = phoItemsWidth + $phoItemArray.length * 2;
            }
        }

        phoItemsWidth = phoItemsWidth + 10;
        jm(itemContainer).css('width', phoItemsWidth + 'px');
        if (jm.os.supportsTouch) {
            new iScroll(photoDom, {
                scrollX: true,
                scrollY: false,
                vScroll: false,
                mouseWheel: true,
                remarkXCoordinate: true,
                onBeforeScrollStart() {},
            });
            // 水平时候禁止侧滑，返回上下页   ------zhj
            var isVer,
                oldX,
                oldY,
                currX,
                currY,
                $photoSlide = $('#' + photoSlide),
                $photoSlideList = $('#' + photoSlideList);

            //水平滑动，禁止文档移动
            $photoSlide.off('touchstart.photoCross').on('touchstart.photoCross', function (event) {
                var touch = event.touches.length > 0 ? event.touches[0] : event.chengedTouches[0];
                oldX = touch.pageX;
                oldY = touch.pageY;

                $photoSlide.off('touchmove.photoCross').on('touchmove.photoCross', photoListTouchMove);
                $photoSlide.off('touchend.photoCross').on('touchend.photoCross', photoListTouchEnd);
            });

            // 依赖了if中的变量，不好迁移，下次一定
            // eslint-disable-next-line no-inner-declarations
            function photoListTouchMove(event) {
                var touch = event.touches.length > 0 ? event.touches[0] : event.chengedTouches[0];
                currX = touch.pageX;
                currY = touch.pageY;

                if (isVer === undefined) {
                    if (oldX !== undefined && oldY !== undefined) {
                        isVer = isVertical(oldX, oldY, currX, currY); //获取当前滑动状态
                        if (isVer === false) {
                            event.preventDefault();
                        }
                        $photoSlide.off('touchmove.prod');
                    }
                }

                oldX = currX;
                oldY = currY;
            }

            // 依赖了if中的变量，不好迁移，下次一定
            // eslint-disable-next-line no-inner-declarations
            function photoListTouchEnd() {
                isVer = oldX = oldY = currX = currY = undefined;
                //事件解绑
                $photoSlide.off('touchmove.photoCross', photoListTouchMove);
                $photoSlide.off('touchend.photoCross', photoListTouchEnd);
            }

            // 依赖了if中的变量，不好迁移，下次一定
            // eslint-disable-next-line no-inner-declarations
            function isVertical(x1, y1, x2, y2) {
                var dx = Math.abs(x1 - x2),
                    dy = Math.abs(y1 - y2);

                //避免分母为0
                if (dx === 0 && dy === 0) {
                    return false;
                }
                if (dx === 0 && dy > 0) {
                    return true;
                }
                if (dy === 0 && dx > 0) {
                    return false;
                }
                return dy / dx > 1 ? true : false;
            }

            //在子节点上添加事件，利用事件冒泡在垂直滑动的时候，阻止iscroll滑动
            $photoSlideList
                .children()
                .off('touchmove.banIScroll')
                .on('touchmove.banIScroll', function (event) {
                    if (isVer === true) {
                        event.stopPropagation();
                    }
                });
        }
    }
    $phoItemArray.show(); //宽度未初始化完，隐藏掉后面dom节点。初始化最新宽度之后，要全部显示。（防止页面跳动）
};

export function bindLazyLoad(context) {
    var moduleContent = context.module.content;
    let lazyLoadOptions = {};
    if (moduleContent.m.s) {
        //如果图片滚动是快速，为了使图片滚动图片能完全加载出来，扩大交叉区margin，让图片能够预加载一屏的图片
        let marqueeContainer = jm(`.J_marqueeContainer${context.module.id}`);
        let marqueeContainerWidth = 0;
        if (marqueeContainer.length) {
            marqueeContainerWidth = parseInt(marqueeContainer.width());
        }
        lazyLoadOptions.threshold = 0.0;
        lazyLoadOptions.rootMargin = `0% ${marqueeContainerWidth}px 0% 0px`;
        lazyLoadOptions.root = document.getElementById(`photoSlide${context.module.id}`);
    }
    if (!context.noLazyLoad) {
        bindImgLazyLoad(`module${context.module.id}`, lazyLoadOptions);
    }
}

export function bindEvent(context) {
    var moduleContent = context.module.content;
    var ispace = moduleContent.mhighs.ispace;

    if (!context.picDataList.length) {
        return;
    }
    if (context.type == 2) {
        photoCrossedSlideSecSwipe(
            context.id,
            'photoSlideList' + context.id,
            'photoSlide' + context.id,
            '.newPhotoCrossedSlideSec',
            { intervalOptions: ispace }
        );

        if (moduleContent.m.s) {
            var option = { ...moduleContent.m };
            option.styleId = context.module.style;
            richMarquee.init(context.id, '#photoSlideList', option);
        }

        copyModulePhotoDetailSwipe('module' + context.id, '.slideItemImage');
    }
    if (context.type == 3) {
        // 卡片层叠
        photoCardInit(context.id);
    }
    bindLazyLoad(context);
}
