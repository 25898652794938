var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "photoModule newCarouselMultiPhoto styleForm13",
      class: _vm.photoSwipeCls,
    },
    [
      _c(
        "div",
        {
          staticClass: "photoSwipe",
          attrs: { id: "photoSwipe" + _vm.module.id },
        },
        [
          _c(
            "div",
            {
              ref: "photoSwipeBox",
              staticClass: "photoSwipeBox",
              class: _vm.photoSwipeReset,
              style: _vm.photoSwipeBoxStyle,
              attrs: { id: "photoSwipeBox" },
              on: {
                touchstart: _vm.touchstart,
                touchmove: _vm.touchmove,
                touchend: _vm.touchend,
              },
            },
            [
              _vm._l(_vm.pageList, function (rowList, pageIndex) {
                return _c(
                  "div",
                  {
                    key: pageIndex,
                    staticClass: "imagePage",
                    style: _vm.pageStyle,
                  },
                  _vm._l(rowList, function (rowOne, rowIndex) {
                    return _c(
                      "div",
                      { key: rowIndex, staticClass: "imgRow" },
                      _vm._l(rowOne, function (item, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass: "imgItem",
                            style: _vm.itemWith,
                          },
                          [
                            _c("new-carousel-image-item", {
                              key: index,
                              style: _vm.itemStyle,
                              attrs: {
                                "item-index": item.index,
                                item: item,
                                "outer-box": true,
                                "text-pos": _vm.content.itp,
                                "auto-hide-text": true,
                                "no-lazy-load": _vm.noLazyLoad,
                              },
                            }),
                          ],
                          1
                        )
                      }),
                      0
                    )
                  }),
                  0
                )
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "imagePage", style: _vm.pageStyle },
                _vm._l(_vm.pageList[0], function (rowTwo, pageIndex) {
                  return _c(
                    "div",
                    { key: pageIndex, staticClass: "imgRow" },
                    _vm._l(rowTwo, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "imgItem",
                          style: _vm.itemWith,
                        },
                        [
                          _c("new-carousel-image-item", {
                            key: index,
                            style: _vm.itemStyle,
                            attrs: {
                              "item-index": item.index,
                              item: item,
                              "outer-box": true,
                              "text-pos": _vm.content.itp,
                              "auto-hide-text": true,
                              "no-lazy-load": _vm.noLazyLoad,
                            },
                          }),
                        ],
                        1
                      )
                    }),
                    0
                  )
                }),
                0
              ),
            ],
            2
          ),
          _vm._v(" "),
          _vm.pageList.length > 1
            ? _c("img-bullet-group", {
                attrs: {
                  "bullet-group-style": 2,
                  "current-index": _vm.currentIndex,
                  "bullet-length": _vm.pageList.length,
                },
                on: { "bullet-click": _vm.upDateSwipeIndex },
              })
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }