var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "J_first_group first_group",
      attrs: { id: "J_first_group_" + _vm.moduleId },
    },
    [
      _c(
        "ul",
        { staticClass: "first_grouplist J_first_grouplist clearFix" },
        _vm._l(_vm.groupList, function (item, index) {
          return _c(
            "li",
            {
              key: index,
              staticClass: "J_first_groupitem first_groupitem",
              class: {
                selected: _vm.isSelect(item, index),
                g_main_color_v3: _vm.isSelect(item, index),
              },
              style: _vm.firstGroupStyle,
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.handleHasProductClick(item, index)
                },
              },
            },
            [
              _c("p", {
                staticClass: "first_groupname",
                domProps: { textContent: _vm._s(item.name) },
              }),
            ]
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }