var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "grid_photo_panel" },
    _vm._l(_vm.list, function (item) {
      return _c(
        "a",
        {
          key: item.id,
          staticClass: "img_panel_wrap",
          attrs: { href: _vm.prUrl + "?groupId=" + item.id },
        },
        [
          _c("div", { staticClass: "img_panel J_img_panel" }, [
            _c("div", {
              staticClass: "img J_img_lazyload",
              style: _vm.imgStyle(item),
              attrs: {
                id: "pg_img_item" + item.id,
                "src-original": item.iconPath,
              },
              on: {
                mouseover: function ($event) {
                  return _vm.grouItemMouseOver($event, item)
                },
                mouseout: function ($event) {
                  return _vm.groupItemMouseOut($event)
                },
              },
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "text first_groupname g_productSort_name",
                style: _vm.textStyle(item),
              },
              [_vm._v(_vm._s(item.name))]
            ),
          ]),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }