import { createUrlArgsOnclickStr } from '../../../comm';
import { logDog } from '@/shared/log';

import { mapGetters, mapState } from 'vuex';
import { handleSetUrlArgToCookie } from '@/modules/comm';
export default {
    props: {
        noLazyLoad: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            useManageMode: VITE_APP_MODE !== 'visitor',
        };
    },
    computed: {
        ...mapState(['htmlFontSize']),
        ...mapGetters('app', ['hashRemoved']),
        isNewAddModule() {
            return this.module.extInfo.isNewAddModule;
        },
        _lineClass() {
            return this.module.content.sl ? 'wWLine' : ' ';
        },
        _lineHeightClass() {
            return !this.isNewAddModule ? 'j-lineH' : ' ';
        },
        _topClassName() {
            return this.info.top != 0 ? 'top2' : 'top1';
        },
        _topSwitch() {
            return this.info.top != 0 ? 'off' : 'on';
        },
        _topFlagClass() {
            let topFlag = '';
            const isTopFlagShow = this.module.content.tfs && !this.module.content.tfs.ih;
            const topFlagType = this.module.content.tfs && this.module.content.tfs.tt ? this.module.content.tfs.tt : 0;
            const top = this.info.top;
            const newsSelect = this.module.content.ns;

            if (this.module.extInfo.newSelectCanTop) {
                if (!this.isNewAddModule) {
                    topFlag = !!top && isTopFlagShow ? `g_topFlag g_topFlag${topFlagType} ` : '';
                } else {
                    topFlag = !!top && isTopFlagShow ? `newModuleTop ` : '';
                }
            } else {
                if (!this.isNewAddModule) {
                    topFlag = !!top && isTopFlagShow && !newsSelect ? `g_topFlag g_topFlag${topFlagType} ` : '';
                } else {
                    topFlag = !!top && isTopFlagShow && !newsSelect ? `newModuleTop ` : '';
                }
            }

            return topFlag;
        },
        newsDate() {
            return this.info && this._dateFormate(new Date(this.info.date), 'yyyy/MM/dd');
        },
        _showComment() {
            return this.module.content.ncs && this.module.extInfo.isOpenNewsComment;
        },
        _showAuthor() {
            return this.module.content.nas && this.info && this.info.author !== '';
        },
        _showSort() {
            return !this.module.content.t && this.info && this.info.groupName !== '';
        },
        _showDate() {
            return !this.module.content.d && this.newsDate != '';
        },
        _jumpTarget() {
            return '_self';
        },
        _newsUrl() {
            //0x80=NewsDef.Flag.LINKS_EXTERNAL_ARTICLE
            // fix: 修复管理态不是静态化链接
            return VITE_APP_MODE !== 'visitor'
                ? this.module.extInfo.isOpenNewsLinksExtArticle && this.info && 0x80 == (this.info.flag & 0x80)
                    ? 'javascript:top.Fai.ing("管理态不支持跳转外部链接，请通过“预览”进行访问", true)'
                    : this.info.href
                : this.info.href;
        },
        _topFlagLineClass() {
            let topFlagLine = '';
            const isTopFlagShow = this.module.content.tfs && !this.module.content.tfs.ih;
            const top = this.info.top;
            const newsSelect = this.module.content.ns;

            if (this.module.extInfo.newSelectCanTop) {
                topFlagLine = top != 0 && isTopFlagShow ? 'topFlagLine ' : ' ';
            } else {
                topFlagLine = top != 0 && isTopFlagShow && !newsSelect ? 'topFlagLine ' : ' ';
            }

            return topFlagLine;
        },
        news_title_style() {
            var style = '';
            if (!(VITE_APP_MODE !== 'visitor')) {
                return style;
            }
            var newsTitle = this.module.content.mhighs.nt;
            if (newsTitle.type == 1) {
                if (newsTitle.lfs) {
                    style += 'font-size:' + newsTitle.lfs + 'rem;';
                } else {
                    style += 'font-size:12px;';
                }
                if (newsTitle.lfct == 1) {
                    style += 'color:' + newsTitle.lfc + ';';
                }
                if (newsTitle.lfd == 1) {
                    style += 'text-decoration:underline;';
                } else {
                    style += 'text-decoration:none;';
                }
            }
            return style;
        },
        news_abstract_style() {
            if (!(VITE_APP_MODE !== 'visitor')) {
                return '';
            }
            var newsAbstract = this.module.content.mhighs.na;
            var style = '';
            if (newsAbstract.type == 1) {
                if (newsAbstract.nfs) {
                    style += 'font-size:' + newsAbstract.nfs + 'rem;';
                } else {
                    style += 'font-size:12px;';
                }
                if (newsAbstract.nfct == 1) {
                    style += 'color:' + newsAbstract.nfc + ';';
                }
            }
            return style;
        },
        showOtherInfo() {
            return this._showDate || this._showSort || this._showAuthor || this._showComment;
        },
        showDes() {
            return !this.module.content.des && this.info && this.info.summary != '';
        },
        isOnlyTitle() {
            return !this.showOtherInfo && !this.showDes ? 'onlyTitle' : '';
        },
        isOnlyTitleAndDes() {
            return this.showDes && !this.showOtherInfo ? 'onlyTitleAndDes' : '';
        },
        _resizeOptions() {
            if (!(VITE_APP_MODE !== 'visitor')) {
                return;
            }

            var parentCompontnet = vue_utils.findComponentUpward(this, 'News');
            var moduleStyle = parentCompontnet.moduleStyle;
            var handles = 's';
            var notAllow = false;
            var alsoResizeParentSelector = null;
            var alsoResizeChildSelector = null;
            var maxWidth = null;
            var maxHeight = null;
            var tPosition = this.module.content.tPosition;
            var forceInit = false;
            var outlineInBody = true;

            if (!this.isNewAddModule && (moduleStyle == 5 || moduleStyle == 6)) {
                notAllow = true;
            }

            if (moduleStyle == 2) {
                handles = tPosition == 1 ? 's,e' : 's,w';
                maxWidth = 119;
            } else if ((moduleStyle == 4 && this.isNewAddModule) || moduleStyle == 11 || moduleStyle == 1) {
                handles = tPosition == 1 ? 's,e' : 's,w';
                maxWidth = 100;
            } else if (moduleStyle == 4 && !this.isNewAddModule) {
                if (this.index != 0) {
                    handles = tPosition == 1 ? 's,e' : 's,w';
                    maxWidth = 100;
                    maxHeight = 100;
                }
            }

            if (moduleStyle == 2 || moduleStyle == 11) {
                alsoResizeParentSelector = '.J_img_wrap';
            } else if (moduleStyle == 4 && this.isNewAddModule) {
                if (this.module.content.hs == 1 && this.index == 0) {
                    handles = 's';
                    alsoResizeChildSelector = '.J_img_lazyload';
                } else {
                    alsoResizeParentSelector = '.picTableCell';
                }
            } else if (moduleStyle == 6) {
                alsoResizeChildSelector = '.newsTitlePic';
            } else if (moduleStyle == 5) {
                alsoResizeParentSelector = '.J_newsListPanel';
            } else if (moduleStyle == 3) {
                alsoResizeChildSelector = '.J_img_lazyload';
            }

            if (moduleStyle == 4) {
                forceInit = true;
            }

            if (moduleStyle == 6) {
                outlineInBody = false;
            }

            return {
                notAllow: notAllow,
                handles: handles,
                alsoResizeParentSelector: alsoResizeParentSelector,
                alsoResizeChildSelector: alsoResizeChildSelector,
                fixPosition: true,
                outlineInBody: outlineInBody,
                maxWidth: maxWidth,
                maxHeight: maxHeight,
                beforeStart: this._resizeBeforeStart,
                stop: this._resizeStop,
                forceInit: forceInit,
            };
        },
        onclickStr() {
            const { ndReqArgs } = this.info;
            return createUrlArgsOnclickStr(ndReqArgs, this.module._openRemoveUrlArgs, this.hashRemoved);
        },
    },
    methods: {
        _dateFormate(date, fmt) {
            var o = {
                'M+': date.getMonth() + 1, //月份
                'd+': date.getDate(), //日
                'h+': date.getHours(), //小时
                'm+': date.getMinutes(), //分
                's+': date.getSeconds(), //秒
                'q+': Math.floor((date.getMonth() + 3) / 3), //季度
                S: date.getMilliseconds(), //毫秒
            };
            if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
            for (var k in o)
                if (new RegExp('(' + k + ')').test(fmt))
                    fmt = fmt.replace(
                        RegExp.$1,
                        RegExp.$1.length == 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length)
                    );
            return fmt;
        },
        _resizeBeforeStart(event) {
            var parentCompontnet = vue_utils.findComponentUpward(this, 'News');
            var moduleStyle = parentCompontnet.moduleStyle;
            if (
                (moduleStyle == 10 && this.module.content.hs == 3) ||
                (moduleStyle == 11 && this.module.content.hs == 3) ||
                moduleStyle == 9
            ) {
                jm(event.target).parent().css('align-items', 'initial');
            }
        },
        _resizeStop(event, ui) {
            const { width, height } = { ...ui.size };
            var parentCompontnet = vue_utils.findComponentUpward(this, 'News');
            var moduleStyle = parentCompontnet.moduleStyle;
            let transWidth = Math.max((width / this.htmlFontSize) * 20, 1);
            let transHeight = Math.max((height / this.htmlFontSize) * 20, 1);

            if (
                moduleStyle == 10 ||
                moduleStyle == 3 ||
                (moduleStyle == 4 && this.index == 0) ||
                (moduleStyle == 11 && this.isThreeItem)
            ) {
                // if (moduleStyle == 4) {
                //     const wrap = ui.originalElement.find(".drag_also_resize_wrap");
                //     if (wrap.length > 0) {
                //         imgHeigh = wrap.height();
                //     }
                // }
                this.module.content.indexPicHeightParam.indexPicHeight =
                    Math.max((height / this.htmlFontSize) * 20, 1) + '';
                ui.originalElement.css('height', '');
            } else if (moduleStyle == 1) {
                this.module.content.indexPicHeightParam.indexPicHeight = transHeight + '';
                this.module.content.indexPicHeightParam.indexPicWidth = transWidth + '';
            } else if (moduleStyle == 4 && !this.isNewAddModule) {
                this.module.content.tSize.picWidth = width + '';
                this.module.content.tSize.picHeight = height + '';
            } else {
                this.module.content.tSize.picWidth = transWidth + '';
                this.module.content.tSize.picHeight = transHeight + '';
            }

            if (this.module.content.pn == 6) {
                event.target.style.height = '';
            }

            if ((moduleStyle == 11 && this.module.content.hs == 3) || moduleStyle == 9) {
                jm(event.target).parent().css('align-items', '');
            }

            // 自定义设置
            if (
                (moduleStyle == 4 && this.index == 0) ||
                (moduleStyle == 11 && this.isThreeItem) ||
                [10, 3, 1].includes(moduleStyle)
            ) {
                this.module.content.indexPicHeightParam.indexPicHeightType = 2;
            } else {
                this.module.content.tSize.picSizeType = 2;
            }

            this.$designer.onContentChange(this.module.id);
            logDog(201218, 2);
        },
        otherInfoClick() {
            if (this.module._openRemoveUrlArgs) {
                handleSetUrlArgToCookie(this.info.ndReqArgs).then(() => {
                    window.location.href = this._newsUrl;
                });
            } else {
                window.location.href = this._newsUrl;
            }
        },
    },
};
