<template>
    <div class="mobi_vote_radio">
        <div v-for="item in voteItem.itemList" :key="radioKey(item)" class="choice_box_wrap" :class="itemWrapClass">
            <div v-show="imgType" class="vote_item_content_img" @click="imgClick($event, item.id)"
                ><img class="style1Img vote_item_img" :src="item.imgPath || module.extInfo.defaultpPath"
            /></div>
            <label
                class="m_radio_wrap choice_box"
                :class="{
                    m_radio_checked: currentValue === item.id,
                }"
            >
                <span class="m_radio">
                    <span
                        class="m_vote_radio_inner m_radio_inner icon-"
                        :class="{ active: currentValue === item.id }"
                    ></span>
                    <input
                        :id="'voteItem' + module.content.voteId + 'i' + voteItem.id + item.id + module.id"
                        class="m_radio_input"
                        type="radio"
                        :checked="currentValue === item.id"
                        :value="item.id"
                        @change="change"
                    />
                </span>
                <span>{{ item.name }}</span>
            </label>
        </div>
    </div>
</template>
<script>
export default {
    name: 'VoteRadio',
    inject: ['module', 'options'],
    props: {
        voteItem: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            currentValue: -1,
        };
    },
    computed: {
        submitVal() {
            return [this.currentValue];
        },
        imgType() {
            return this.voteItem.v_type === 'img';
        },
        itemWrapClass() {
            return this.module.content.iwrap == 1 ? 'voteItemWrap' : '';
        },
    },
    methods: {
        change(event) {
            this.currentValue = parseInt(event.target.value);
        },
        resetValue() {
            this.currentValue = -1;
        },
        radioKey(radio) {
            return `radio${radio.id}`;
        },
        imgClick(event, value) {
            this.currentValue = value;
        },
        validate() {
            if (this.currentValue === -1) {
                this.$mobiIng(LS.voteNotSelect);
                return false;
            }
            return true;
        },
    },
};
</script>

<style lang="scss" scoped>
.m_vote_radio_inner {
    background-color: #fff;
}

.m_vote_radio_inner.active {
    background-color: #292929;
    color: #fff;
}

.mobi_vote_radio .m_radio_wrap {
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    pointer-events: auto;
}

.mobi_vote_radio .m_radio {
    display: inline-block;
    margin-right: 0.2rem;
    outline: 0;
    position: relative;
    line-height: 1;
    vertical-align: -0.25em;
    cursor: pointer;
}

.mobi_vote_radio .m_radio_inner {
    position: relative;
    display: inline-block;
    width: 0.8rem;
    height: 0.8rem;
    top: 0;
    left: 0;
    border: 1px solid rgba(216, 216, 216, 1);
    border-radius: 50%;
    font-size: 0.375rem;
    box-sizing: border-box;
}

.mobi_vote_radio .m_radio_checked .m_radio_inner {
    border: none;
}
.mobi_vote_radio .m_radio_checked .m_radio_inner:after {
    content: '\b0335';
    position: absolute;
    left: 50%;
    top: 50%;
    border-radius: 0;
    transform: translate(-50%, -50%);
    font-size: 0.6rem;
}

.mobi_vote_radio .m_radio_input {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}
</style>
