<template>
    <module-frame :module-id="moduleId">
        <div class="J_packModule fk-formPack">
            <div :id="packContentId" class="J_packContentList f-packContentList" :style="packContentStyle">
                <div class="J_packContentBg f-packContentBg" :class="'f-packContentBg' + moduleId"></div>
                <div v-if="manageMode" class="J_packEditBtn f-packEditBtn" :style="addBtnStyle">
                    <div class="J_addContent f-addContent">
                        <div
                            class="addBtn_Icon"
                            :class="{ 'f-disableEdit': hasContainerId }"
                            :_id="moduleId"
                            @click="addBtnClick"
                        ></div>
                        <div class="f-addText">
                            <span>目前自由容器仅支持放置常用模块</span>
                        </div>
                    </div>
                </div>
                <div :id="'formPackContent' + moduleId" class="J_formPackContent f-formPackContent">
                    <div
                        v-if="manageMode"
                        class="fk-elemZoneBg J_zoneContentBg elemZoneBg f-packContentBg"
                        :class="['f-packContentBg' + moduleId]"
                    ></div>
                    <component
                        :is="componentsTable[getModuleById(childModule.id).style]"
                        v-for="childModule in children"
                        :key="childModule.id"
                        :module-id="childModule.id"
                    ></component>
                </div>
            </div>
        </div>
    </module-frame>
</template>

<script>
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex';
import ModuleFrame from '@/modules/frame/index.vue';

import { MODULE_STYLE } from '@/def/module';
import { NEW_MANAGE_HTML_FONTSIZE } from '@/shared/constants';

export default {
    name: 'Pack',
    components: {
        ModuleFrame,
    },
    props: {
        children: {
            type: Array,
            default: () => [],
        },
        moduleId: {
            type: Number,
            default: -1,
            required: true,
        },
        componentsTable: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            manageMode: VITE_APP_MODE !== 'visitor',
            moduleManageFontSize: 0,
        };
    },
    computed: {
        ...mapState('app', ['openThemeV3']),
        ...mapState(['components']),
        ...mapGetters(['getModuleById']),
        module() {
            return this.getModuleById(this.moduleId);
        },
        moduleContent() {
            return this.module.content;
        },
        packContentId() {
            return `fk-packContent${this.moduleId}`;
        },
        addBtnStyle() {
            let style = {};
            if (this.children.length > 0) {
                style.display = 'none';
            }
            return style;
        },
        hasContainerId() {
            let manage = this.$store.state.manage || {};
            let { moduleAdd = {} } = manage;
            let { inContainerId, containerStyle } = moduleAdd;
            return inContainerId && inContainerId !== -1 && containerStyle === MODULE_STYLE.PACK;
        },
        packContentStyle() {
            let style = {};
            const height = this.module.pattern.h || 0;
            if (height === 0 || this.moduleManageFontSize === 0) {
                return style;
            }
            let packHeight = height / this.moduleManageFontSize;
            if (height > 0 && !this.openThemeV3) {
                style.height = `${packHeight}rem`;
            }
            return style;
        },
    },
    mounted() {
        this.moduleManageFontSize = window.moduleManageFontSize['module' + this.moduleId] || NEW_MANAGE_HTML_FONTSIZE;
        if (!this.manageMode) {
            const height = this.module.pattern.h || 0;
            const heightV3 = this.module.extInfo.heightFromPatternV3 || {};
            let fixHeight = this.openThemeV3 ? heightV3.t == 0 : height == 0;
            if (fixHeight) {
                // 文本模块高度是否会被自由容器覆盖，找到遮住最多的文本模块，并修改自由容器高度
                let max = 0;
                let packModule = jm(`#module${this.module.id}`);
                let packModuleClientRect = packModule[0].getBoundingClientRect();
                try {
                    this.module.children.forEach(({ id }) => {
                        let $module = jm(`#module${id}`);
                        let module = this.getModuleById(id);
                        if (module.style == MODULE_STYLE.SIMPLE_TEXT) {
                            let textModuleClientRect = $module[0].getBoundingClientRect();
                            max = Math.max(max, textModuleClientRect.bottom - packModuleClientRect.bottom);
                        }
                    });
                    if (max > 0) {
                        packModule.css('height', `${parseFloat(jm(`#module${this.module.id}`).height()) + max}px`);
                    }
                } catch (err) {
                    console.log(err);
                }
            }
        }
    },
    methods: {
        ...mapMutations(['addChildModule']),
        ...mapActions('manage/addPanel', ['changeActiveTab', 'changeActiveModuleTab']),
        addBtnClick() {
            if (this.hasContainerId) {
                this.$emit('cancel-add');
            } else {
                this.$emit('add');
            }
            this.changeActiveTab('module');
            this.changeActiveModuleTab('add');
        },
        canceAdd() {
            if (this.hasContainerId) {
                this.$emit('cancel-add');
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.pack-module {
    width: 100%;
    min-height: 100px;
    border: 1px solid #eee;
    position: relative;
}
</style>

<style lang="scss">
.fk-tabSortContainer,
.fk-packSortContainer,
.fk-foldSortContainer {
    display: none;
    height: 50px;
    line-height: 50px;
    position: relative;
}
.fk-canConnectModule .formTab2 .fk-dragPlaceholder,
.fk-canConnectModule .formTab2 .g_middlePlaceholder {
    height: auto;
    line-height: unset;
    width: unset;
}
.fk-canConnectModule .fk-packSortContainer .g_middlePlaceholder,
.fk-canConnectModule .fk-popupZoneSortContainer .g_middlePlaceholder {
    height: 100%;
    margin: 0;
    background-color: rgba(233, 237, 247, 0.7);
    color: #5874d8;
    border: dashed 1px #618cef;
    box-sizing: border-box;
}
.fk-canConnectModule .fk-packSortContainer .fk-dragPlaceholder,
.fk-canConnectModule .fk-popupZoneSortContainer .fk-dragPlaceholder {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    z-index: 999;
    line-height: 200px;
    background-color: rgba(233, 237, 247, 0.7);
    color: #5874d8;
    border: dashed 1px #618cef;
    box-sizing: border-box;
}
.fk-canConnectModule .formTab2 .f-formTabButton .fk-dragPlaceholder,
.fk-canConnectModule .formTab2 .f-formTabButton .g_middlePlaceholder {
    width: 100%;
}
.fk-canConnectModule .fk-packSortContainer,
.fk-canConnectModule .fk-floatZoneSortContainer {
    z-index: 88;
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    background: rgba(255, 255, 255, 0);
}
.fk-canConnectModule .fk-packSortContainer .fk-dragPlaceholder,
.fk-canConnectModule .fk-floatZoneSortContainer .fk-dragPlaceholder {
    width: 100%;
    height: 100%;
}
.fk-canConnectModule .fk-packSortContainer .g_middlePlaceholder,
.fk-canConnectModule .fk-popupZoneSortContainer .g_middlePlaceholder {
    height: 100%;
    margin: 0;
    background-color: rgba(233, 237, 247, 0.7);
    color: #5874d8;
    border: dashed 1px #618cef;
    box-sizing: border-box;
}
.fk-canConnectModule .fk-packSortContainer .fk-dragPlaceholder,
.fk-canConnectModule .fk-popupZoneSortContainer .fk-dragPlaceholder {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    z-index: 999;
    line-height: 200px;
    background-color: rgba(233, 237, 247, 0.7);
    color: #5874d8;
    border: dashed 1px #618cef;
    box-sizing: border-box;
}
.fk-canConnectModule .formTab2 .f-formTabButton .fk-dragPlaceholder,
.fk-canConnectModule .formTab2 .f-formTabButton .g_middlePlaceholder {
    width: 100%;
}
#webContainerBox .fk-tabSortContainer .formBannerTitle,
#webContainerBox .fk-packSortContainer .formBannerTitle {
    display: none !important;
}

.g_middlePlaceholder {
    width: 100%;
    height: 44px;
    line-height: 44px;
    background-color: rgba(233, 237, 247, 0.7);
    color: #5874d8;
    border: dashed 1px #618cef;
    font-size: 13px;
    text-align: center;
    box-sizing: border-box;
}

.fk-canConnectModule .fk-packSortContainer .g_middlePlaceholder {
    height: 100%;
    margin: 0;
    background-color: rgba(233, 237, 247, 0.7);
    color: #5874d8;
    border: dashed 1px #618cef;
    box-sizing: border-box;
}

.f-formPackContent {
    height: 100%;
}

.f-packContentList {
    position: relative;
    overflow: hidden;
    height: 9.75rem;
}

.fk-formPack {
    display: inherit;
    height: inherit;
    width: 100%;
}

.formStyle51 .moduleContent {
    display: inherit;
}

.f-addContent {
    height: 200px;
    width: 98%;
    position: relative;
    text-align: center;
}

.f-addContent .addBtn_Icon {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    background: url(/image/bg05.png?v=201711250607) -1450px -149px no-repeat;
    height: 37px;
    width: 129px;
    cursor: pointer;
    z-index: 1;
}

.f-addContent .f-addText {
    position: relative;
    top: 50%;
    padding-top: 25px;
    cursor: pointer;
    color: #b8b8b8;
}

.f-packEditBtn {
    height: 200px;
    position: relative;
}

.fk-canConnectModule .fk-packSortContainer .g_middlePlaceholder {
    height: 100%;
    margin: 0;
    background-color: rgba(233, 237, 247, 0.7);
    color: #5874d8;
    border: dashed 1px #618cef;
    box-sizing: border-box;
}
</style>
