<template>
    <div class="photoModule styleForm1">
        <div v-for="(item, index) in list" :key="item.picId" class="g_background photoDiv" :class="photoDivClass">
            <a :href="item.href || undefined" @click="imgClick(index)">
                <div class="imgClass_160">
                    <span class="imageMiddle"></span>
                    <img
                        :data-picid="item.picId"
                        class="photoListImg J_img_lazyload"
                        :class="imgClass"
                        :src="item.picThumbPath"
                        :photoIndex="index"
                        :alt="item.alt"
                        :linkType="item.linkType"
                        :vwidth="item.vwidth"
                        :vheight="item.vheight"
                    />
                </div>
                <template v-if="showName">
                    <div v-if="nameWordWrap" class="imgName g_link">{{ item.imgName }}</div>
                    <div v-else class="imgName g_link" style="white-space: nowrap">{{ item.imgName }}</div>
                </template>
            </a>
        </div>
        <div style="clear: both"></div>
    </div>
</template>

<script>
import { photoListMixins } from '../mixins';
export default {
    name: 'Type1',
    mixins: [photoListMixins],
    inject: ['moduleId', 'manageMode'],
    props: {
        list: {
            type: Array,
            default() {
                return [];
            },
        },
    },
    computed: {
        photoDivClass() {
            return this.showName ? 'photoBg' : '';
        },
        showName() {
            return this.content.showName == 0 ? true : false;
        },
        nameWordWrap() {
            return this.content.newNameWrap == 0 ? true : false;
        },
        scale() {
            return this.content.picScale;
        },
        imgClass() {
            return this.scale == 0 ? '' : 'stretchImg';
        },
        groupId() {
            return this.content.groupId;
        },
    },
    methods: {
        imgClick(index) {
            this.$emit('imgClick', index);
        },
        refreshModuleData() {},
    },
};
</script>
