<template>
    <module-frame :module-id="moduleId">
        <div :id="'newsList' + moduleId" class="newsList">
            <template v-if="searchList.length > 0">
                <component
                    :is="styleComponet"
                    v-for="(item, index) in renderList"
                    :key="`${pageno}_item_${index}`"
                    :item="item"
                    :last="index == renderList.length - 1"
                />
            </template>
            <div v-else class="noSearchList">
                <img class="noSearchListImg" :src="resRoot + '/image/v2/defaultIcon10.png?v=20190128'" alt="" />
                <p class="noSearchListText">{{ mobiSearchResult }}</p>
            </div>
            <image-viewer
                v-if="showMobiViewer"
                :z-index="zIndex"
                :initial-index="initialIndex"
                :on-close="onClose"
                :url-list="options.swipePhotoList"
                :is-mobi="true"
                :content-terminal="'mobi'"
                :manage-mode="manageMode"
                :document-event-el="documentEventEl"
                append-to-container-id="g_body"
                :append-to-body="false"
                :disable-long-press="banCopyTextImg"
            />
            <pagenation
                v-if="module.content.pi && searchList.length > 0"
                :module-id="moduleId"
                :total-size="totalSize"
                :page-size="module.content.sc"
                :pageno="pageno"
                :open-theme-v3="openThemeV3"
                :ajax-pagenation="true"
                @on-change="changePage"
                @click.native.stop=""
            >
            </pagenation>
        </div>
    </module-frame>
</template>

<script>
import ModuleFrame from '@/modules/frame/index.vue';
import textItem from './components/textItem.vue';
import newTextGroup from './components/newTextGroup.vue';
import pagenation from '@/components/pagenation.vue';

import { mapGetters, mapState } from 'vuex';
import { groupIndexArr } from '../util';
import { getPageSearchResultData } from '@/api/module/searchResult';
import { slidesshowMixins } from '@/modules/moduleMixins';

import { bindImgLazyLoad } from '@/shared/imageLazyload';
import { getRouterData } from '@/features/router/universal';
import { newSearchResultSlide } from '../util';

const manageMode = VITE_APP_MODE !== 'visitor';
export default {
    name: 'SearchResult',
    components: {
        ModuleFrame,
        textItem,
        newTextGroup,
        pagenation,
    },
    mixins: [slidesshowMixins],
    provide() {
        return {
            content: this.module.content,
            isSpider: this.options.isSpider,
            keyword: this.keyword,
            moduleId: this.moduleId,
            module: this.module,
        };
    },
    props: {
        moduleId: {
            type: Number,
            default: -1,
        },
    },
    data() {
        let routerData = getRouterData(this.$store.state.app.colId);
        let { searchword, searchType, nSL } = routerData;

        return {
            keyword: searchword,
            searchType,
            nSL,
            resRoot: '',
            manageMode,
            pageno: 1,
        };
    },
    computed: {
        ...mapGetters(['getModuleById']),
        ...mapState('app', ['openThemeV3', 'LS']),
        module() {
            return this.getModuleById(this.moduleId);
        },
        options() {
            return this.module.renderOptions;
        },
        style() {
            return this.module.style;
        },
        searchList() {
            return this.options.searchList;
        },
        totalSize() {
            return this.options.totalSize;
        },
        mobiSearchResult() {
            return this.LS.mobiSearchResult;
        },
        searchGroup() {
            // 如果是样式二，需要先分组再展示
            let groupForResult = {};
            if (this.moduleStyle != 2) {
                return groupForResult;
            }
            this.searchList.forEach((element) => {
                let { searchFlag } = element;
                if (searchFlag > 0 && Object.keys(groupIndexArr).includes(searchFlag + '')) {
                    let groupTmp = groupForResult[groupIndexArr[searchFlag]];
                    if (typeof groupTmp == 'undefined') {
                        groupForResult[groupIndexArr[searchFlag]] = {
                            searchFlag,
                            list: [],
                        };
                        groupTmp = groupForResult[groupIndexArr[searchFlag]];
                    }
                    groupTmp.list.push(element);
                }
            });
            let { sbProduct, sbNews, sbPhoto, sbPhotoGroup, sbFileList, sbCol, sbColRich } = groupForResult;
            return [sbProduct, sbNews, sbPhoto, sbPhotoGroup, sbFileList, sbCol, sbColRich].filter(
                (ele) => typeof ele != 'undefined'
            );
        },
        moduleStyle() {
            return this.module.content.ms;
        },
        styleComponet() {
            return this.moduleStyle == 1 ? textItem : newTextGroup;
        },
        renderList() {
            return this.moduleStyle == 1 ? this.searchList : this.searchGroup;
        },
        renderOptions() {
            return this.module.renderOptions || {};
        },
    },
    watch: {
        searchList: {
            handler() {
                this.bindLazyLoadEvent();
            },
            deep: true,
        },
    },
    mounted() {
        this.resRoot = Fai.top._resRoot;
        this.bindLazyLoadEvent();
        newSearchResultSlide(this.moduleId, this.renderOptions.swipePhotoList || []);
    },
    methods: {
        async changePage(pageno) {
            var self = this;
            let { moduleId, searchType } = this;
            let routerData = getRouterData(this.$store.state.app.colId);
            let { searchModuleId, searchword, nSL } = routerData;
            let param = {
                pageno,
                pageSize: this.module.content.sc,
                searchModuleId,
                moduleId,
                searchword,
                searchType,
                nSL,
            };
            param[`m${moduleId}pageno`] = pageno;

            let result = await getPageSearchResultData(param);
            let { success, searchList } = result;
            if (success) {
                this.$nextTick(() => {
                    self.pageno = parseInt(pageno);
                    self.options.searchList = searchList;
                });
            }
        },
        bindLazyLoadEvent() {
            if (this.moduleStyle == 2) {
                this.$nextTick(() => {
                    bindImgLazyLoad(`module${this.moduleId}`);
                });
            }
        },
    },
};
</script>

<style lang="scss">
#newsList15 {
    .line {
        padding-left: 1.3rem;
        cursor: pointer;
    }
}
.noSearchList {
    display: flex;
    align-items: center;
    flex-direction: column;
}
.noSearchListText {
    font-size: 0.6rem;
    color: #999999;
    margin: 1rem auto;
}
.noSearchListImg {
    width: 4.325rem;
    height: 5.325rem;
    margin-top: 1.25rem;
}
</style>
