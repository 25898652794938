var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "g_main_bgColor_v3 g_bgColor f-productListTopPromotion",
      style: _vm.textStyle,
    },
    [_vm._v(_vm._s(_vm.info.extInfo.saleFlagName))]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }