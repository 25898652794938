var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "photoModule styleForm7 g_background" }, [
    _c(
      "div",
      {
        staticClass: "swipe",
        attrs: { id: "photoModuleImageSwipe" + _vm.moduleId },
      },
      [
        _c(
          "div",
          {
            staticClass: "bannerSwipeContainer photoModuleImageSwipeContainer",
          },
          _vm._l(_vm.list, function (item, index) {
            return _c("div", { key: index, staticClass: "photoImageItem" }, [
              _c(
                "a",
                {
                  attrs: { href: item.href || undefined },
                  on: {
                    click: function ($event) {
                      return _vm.imgClick(index)
                    },
                  },
                },
                [
                  _c("span", { staticClass: "imageMiddleSpan" }),
                  _vm._v(" "),
                  _c("img", {
                    staticClass: "photoImageItem J_img_lazyload",
                    attrs: {
                      "data-picid": item.picId,
                      vwidth: item.vwidth,
                      vheight: item.vheight,
                      "src-original": item.picThumbPath,
                      src: item.picThumbPath,
                      alt: item.alt,
                      photoIndex: index,
                      linkType: item.pLinkType,
                    },
                  }),
                ]
              ),
            ])
          }),
          0
        ),
        _vm._v(" "),
        _vm.list.length > 0 && _vm.showName
          ? _c("div", { staticClass: "imgName", style: _vm.imgNameStyle }, [
              _vm._v(_vm._s(_vm.list[0].imgName)),
            ])
          : _vm._e(),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }