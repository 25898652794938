<template>
    <div class="m_mobi_form_message_code" :style="_mixinsInputStyle1" :class="classes">
        <input
            v-model="msgCode"
            type="number"
            :placeholder="inputPlaceHolder"
            class="m_mobi_form_input_text m_mobi_form_message_code_input"
            :style="_mixinsInputStyle2"
            @input="handleMsgCodeChange"
        />
        <div
            class="form_message_code_cn_warp"
            :class="messageCodeColor"
            :style="canClick"
            @click.prevent="getMessageCode"
            v-text="showText"
        >
        </div>
    </div>
</template>
<script>
import Mixins from '../mixins';
import { sendValidateCodeApi } from '@/api/form';

import { ing } from '@/shared/tips';
import { mapState } from 'vuex';
const manageMode = VITE_APP_MODE !== 'visitor';
import { customPopup } from '@/shared/popup';

export default {
    name: 'FormMessageCode',
    mixins: [Mixins],
    inject: ['module', 'options'],
    props: {
        formItem: {
            type: Object,
            default: function () {
                return {};
            },
        },

        disabled: {
            type: Boolean,
            default: true,
        },

        phone: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            second: 0,
            interval: null,
            showText: this.module.extInfo.clickToGetCode,
            msgCode: '',
            imgCode: '',
            vCodeId: parseInt(Math.random() * 10000),
            inputPlaceHolder: this.module.extInfo.inputPlaceHolder,
        };
    },
    computed: {
        ...mapState('app', ['openThemeV3']),
        classes() {
            return this.module.content.ms === 0 ? '' : 'm_mobi_form_input_text';
        },
        _mixinsInputStyle2() {
            return this.module.content.ms === 0 ? this._mixinsInputStyle : '';
        },
        _mixinsInputStyle1() {
            return this.module.content.ms === 0 ? '' : this._mixinsInputStyle;
        },
        moduleStyle() {
            return this.module.content.ms;
        },
        messageCodeColor() {
            let classes = [];
            let themeColor = this.openThemeV3 ? 'g_main_bgColor_v3' : 'g_oldThemeBgColor';
            if (this.moduleStyle == 1) {
                classes = [themeColor, 'form_message1'];
            } else if (this.moduleStyle == 2) {
                classes = ['form_message2'];
            } else if (this.moduleStyle == 3) {
                classes = [themeColor, 'form_message3'];
            }
            return classes;
        },

        canClick() {
            let obj = {};

            obj.cursor = 'pointer';
            if (this.disabled || this.second != 0) {
                obj.cursor = 'not-allowed';
                obj.opacity = 0.7;
            } else {
                obj.opacity = 1;
                obj.cursor = 'pointer';
                if (this.moduleStyle == 2) {
                    obj.color = top.Fai.top._themeColor;
                }
            }

            return obj;
        },
    },
    methods: {
        showTip(str) {
            ing(str, false);
        },

        getMessageCode() {
            // 非11位数字号码以及非发送短信
            let self = this;
            if (this.disabled || this.second != 0) return;

            if (manageMode) {
                this.showTip('管理态不支持获取验证码');
                return;
            }

            if (this.phone == '') {
                this.showTip('手机号不能为空');
                return;
            }

            if (this.phone.length < 11) {
                this.showTip('手机号格式有误，请输入11位的大陆地区手机号');
                return;
            }
            if (this.formItem.smsImgVerify) {
                this.initImgCodePopup();
            } else {
                this.sendMsgCodeAjax(function (err) {
                    let aimTip = 'formCodeTips' + err.tipNum;
                    self.$ing(LS[aimTip]);
                }, this.setTimeCode);
            }
        },

        async sendMsgCodeAjax(reject, resolve) {
            try {
                let formId = this.module.content.fi.id;
                let validateCode = this.imgCode;
                let mobile = this.phone;
                let contentId = this.formItem.id;
                let vCodeId = this.vCodeId;

                let params = {
                    formId,
                    contentId,
                    validateCode,
                    mobile,
                    vCodeId,
                };

                let result = await sendValidateCodeApi(params);
                let { success, data } = result;
                if (success) {
                    resolve(data);
                } else {
                    reject(result);
                }
            } catch (err) {
                console.log(err);
            }
        },

        setTimeCode() {
            this.second = 60;
            this.showText = `(${this.second}s)${LS.formPhoneCodeSec}`;
            if (this.interval != null) clearInterval(this.interval);
            this.interval = setInterval(() => {
                this.second--;
                this.showText = `(${this.second}s)${LS.formPhoneCodeSec}`;
                if (this.second == 0) {
                    clearInterval(this.interval);
                    this.showText = this.module.extInfo.clickToGetCode;
                }
            }, 1000);
        },

        resetValue() {
            this.msgCode = '';
            if (this.interval != null) {
                clearInterval(this.interval);
            }
            this.second = 0;
            this.showText = this.module.extInfo.clickToGetCode;
        },

        validate() {
            if (this.msgCode == '') {
                this.$mobiIng('请输入手机验证码');
            }
            return this.msgCode != '';
        },

        refreshPopupClick() {
            var self = this;
            var closePopup = jm('#img_code_close');
            var sureBtn = jm('#img_code_sure');
            var codeInput = jm('#form_img_code');

            function errorFunc(err) {
                self.$mobiIng(err.msg || '未知错误');
                sureBtn.removeClass('img_code_disabled_button');
            }

            function successFunc() {
                sureBtn.removeClass('img_code_disabled_button');

                closePopup.trigger('click');
                self.setTimeCode(); // 倒计时开始
            }

            codeInput.unbind('input').bind('input', function () {
                let value = jm(this).val();
                if (value != null && value.length > 0) {
                    sureBtn.removeClass('img_code_disabled_button');
                    sureBtn.addClass('img_code_sure_button');
                } else {
                    sureBtn.addClass('img_code_disabled_button');
                }
            });

            jm('#img_code_sure')
                .unbind('click')
                .bind('click', function () {
                    self.imgCode = jm('#form_img_code').val(); // 获取输入的验证码
                    let isDisabled = jm(this).hasClass('img_code_disabled_button');
                    if (isDisabled) return; // 避免重复点击
                    jm(this).addClass('img_code_disabled_button');
                    if (self.imgCode != '') {
                        self.sendMsgCodeAjax(errorFunc, successFunc);
                    }
                });

            jm('#img_code').click(function () {
                self.vCodeId = parseInt(Math.random() * 10000);
                this.src = `/validateCode.jsp?vCodeId=${self.vCodeId}&validateCodeRegType=3`;
            });
        },

        initImgCodePopup() {
            var options = {};
            var themeColor = this.openThemeV3 ? 'g_main_bgColor_v3' : 'g_oldThemeBgColor';
            this.vCodeId = parseInt(Math.random() * 10000);
            var content = `<div class="tip_form_message_code">
					<div id="img_code_close" class="J-prompt-cancel close_icon"></div>
					<div class="img_code_title">${LS.formImgCodeTips}</div>
					<div class="img_code_container">
						<span style="margin-right: 0.75rem">验证码</span>
						<input class="img_code_input" id="form_img_code" maxlength="4"/>
						<img id="img_code" class="img_code" src="/validateCode.jsp?vCodeId=${this.vCodeId}&validateCodeRegType=3" />
					</div>
					<div id="img_code_sure" class="${themeColor} img_code_disabled_button J-prompt-callback" >${LS.memberOtherLgnSure}</div>
				</div>`;
            options.content = content;
            customPopup(options);
            this.refreshPopupClick();
        },

        handleMsgCodeChange() {
            this.$emit('change', this.msgCode);
        },
    },
};
</script>
<style>
.m_mobi_form_message_code {
    display: flex;
    justify-content: space-around;
}
.m_mobi_form_message_code.m_mobi_form_input_text {
    padding: 0;
}
.m_mobi_form_message_code .m_mobi_form_message_code_input {
    border: 0;
}
.m_mobi_form_message_code .m_mobi_form_input_text.m_mobi_form_message_code_input {
    background-color: transparent;
}

.m_mobi_form_message_code_input::-webkit-inner-spin-button,
.m_mobi_form_message_code_input::-webkit-outer-spin-button {
    -moz-appearance: textfield;
    -webkit-appearance: none;
}

.mobi_form_style_2 .m_mobi_form_message_code .m_mobi_form_input_text:focus {
    border: 0px;
}

.form_message_code_cn_warp {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    text-align: center;
}

.form_message1 {
    border-radius: 0.1726rem;
    width: 6.753rem;
}

.form_message2 {
    color: #b5b5b5;
    width: 6.767rem;
}

.form_message3 {
    border-radius: 0.1726rem;
    width: 5.62rem;
    height: 1.45rem;
    line-height: 0.6rem;
    margin: auto;
    font-size: 0.55rem;
}

.form_disable_message {
    opacity: 0.7;
    cursor: not-allowed;
}

.tip_form_message_code {
    width: 13rem;
    border-radius: 0.1726rem;
    background-color: #fff;
    position: relative;
    padding-bottom: 0.875rem;
    margin: 0 auto;
}

.tip_form_message_code .close_icon {
    display: inline-block;
    position: absolute;
    top: 0.875rem;
    right: 0.625rem;
    width: 0.65rem;
    height: 0.65rem;
    background: url(/image/v2/mbg01.png) no-repeat -1234px -44px;
}

.tip_form_message_code .img_code_title {
    height: 2.45rem;
    font-size: 0.725rem;
    line-height: 2.45rem;
    color: #333;
    font-weight: 500;
    border-bottom: 1px solid #e6e6e6;
    box-sizing: border-box;
}

.tip_form_message_code .img_code_container {
    width: 11.75rem;
    margin: 0 0.625rem;
    padding: 0.65rem 0;
    font-size: 0.7rem;
    text-align: left;
    border-bottom: 1px solid #e6e6e6;
    position: relative;
}

.tip_form_message_code .img_code_container .img_code {
    float: right;
    width: 3.25rem;
    height: 1.05rem;
}

.tip_form_message_code .img_code_sure_button {
    height: 1.875rem;
    line-height: 1.875rem;
    color: #fff;
    margin: 0.875rem 0.625rem 0;
    font-size: 0.75rem;
    text-align: center;
    border-radius: 0.1rem;
}

.tip_form_message_code .img_code_disabled_button {
    height: 1.875rem;
    line-height: 1.875rem;
    color: #fff;
    margin: 0.875rem 0.625rem 0;
    font-size: 0.75rem;
    text-align: center;
    border-radius: 0.1rem;
    opacity: 0.7;
    cursor: not-allowed;
}

.tip_form_message_code .img_code_input {
    width: 2rem;
    height: 1rem;
    margin: 0 auto;
    border: 0px;
    padding: 0;
    outline: none;
    font-size: 0.7rem;
}

.tip_form_message_code .img_code_error_tips {
    color: red;
    font-size: 0.4375rem;
    margin-top: 0.313rem;
}

#img_code_close {
    display: inline-block;
}
</style>
