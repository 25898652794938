var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "item_type_radio" }, [
    _c(
      "ul",
      { staticClass: "radio_style" },
      _vm._l(_vm.options, function (item) {
        return _c(
          "li",
          {
            key: item,
            staticClass: "radio_item J_noOpenDesigner",
            class: { radio_active: _vm.currentVal === item },
            on: {
              click: function ($event) {
                return _vm.selectItem(item)
              },
            },
          },
          [
            _c("span", { staticClass: "radio_inner" }),
            _vm._v(" "),
            _c("span", { staticClass: "radio_text" }, [_vm._v(_vm._s(item))]),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }