import { MobiModule } from '../Module';
import { MODULE_STYLE } from '@/def/module';
import ModuleLinkView from './module/index.vue';

class _ModuleLink extends MobiModule {
    constructor(options = {}) {
        super(MODULE_STYLE.LINK, options);
    }

    render() {
        return ModuleLinkView;
    }
}

export const ModuleLink = new _ModuleLink();
