var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.render
    ? _c(
        "div",
        {
          staticClass: "cubeLink",
          class: _vm.cubeLinkCls,
          style: _vm.cubeLinkWrapStyle,
          attrs: { id: _vm.cube_link_id, linkid: _vm.id },
        },
        [
          _c(
            "div",
            {
              staticClass: "cubeLink_a_wrap",
              attrs: { id: _vm.cubeLink_wrap_a_id },
              on: {
                mouseover: function ($event) {
                  return _vm.mouseOverHandle($event)
                },
                mouseleave: function ($event) {
                  return _vm.mouseLeaveHandle($event)
                },
              },
            },
            [
              _c(
                "a",
                {
                  staticClass: "cubeLink_a",
                  class: _vm.cubeLinkAClass,
                  style: _vm.tmpHrefStyle,
                  attrs: {
                    id: _vm.cubeLink_a_id,
                    href: _vm.href,
                    rel: _vm.rel,
                    onclick: _vm.jumpOnclickStr,
                  },
                  on: { click: _vm.linkItemClick },
                },
                [
                  _vm.link.coverType == 2 && _vm.bgUrl != ""
                    ? [
                        _c(
                          "div",
                          {
                            staticClass:
                              "cubeLink_bg J_cubeLink_bg J_img_lazyload",
                            style: _vm.cubeLink_bg_style,
                            attrs: { "src-original": _vm.srcOriginal },
                          },
                          [
                            _vm.cubeLayoutId == 7 || _vm.cubeLayoutId == 17
                              ? _c("div", {
                                  staticClass:
                                    "cubeLink_ico icon-cube J_img_lazyload",
                                  style: _vm.iconStyle,
                                  attrs: { "src-original": _vm.incoUrl },
                                })
                              : _vm._e(),
                          ]
                        ),
                      ]
                    : [
                        _vm.cubeLayoutId == 15
                          ? _c("div", {
                              staticClass: "cubeLink_bg_style J_img_lazyload",
                              style: _vm.cubeLink_bg_style,
                              attrs: { "src-original": _vm.srcOriginal },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.cubeLayoutId == 7 || _vm.cubeLayoutId == 17
                          ? _c(
                              "div",
                              {
                                staticClass: "cubeLink_bg J_img_lazyload",
                                style: _vm.cubeLink_bg_style,
                                attrs: { "src-original": _vm.srcOriginal },
                              },
                              [
                                _c("div", {
                                  staticClass:
                                    "cubeLink_ico icon-cube J_img_lazyload",
                                  style: _vm.iconStyle,
                                  attrs: { "src-original": _vm.incoUrl },
                                }),
                              ]
                            )
                          : _c("div", {
                              staticClass: "cubeLink_bg J_img_lazyload",
                              style: _vm.cubeLink_bg_style,
                              attrs: { "src-original": _vm.srcOriginal },
                            }),
                      ],
                  _vm._v(" "),
                  _vm.cubeLayoutId != 7 && _vm.cubeLayoutId != 17
                    ? _c("div", {
                        staticClass: "cubeLink_ico icon-cube J_img_lazyload",
                        style: _vm.iconStyle,
                        attrs: { "src-original": _vm.incoUrl },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "cubeLink_curtain" }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "cubeLink_text g_link",
                      style: _vm.cubeLink_text_wrap_style,
                    },
                    [
                      _c("div", { staticClass: "cubeLink_text_p" }, [
                        _c(
                          "span",
                          {
                            staticClass: "cube_text g_cube_link",
                            style: _vm.cubeLink_text_style,
                          },
                          [_vm._v(_vm._s(_vm.linkText))]
                        ),
                        _vm._v(" "),
                        _c(
                          "p",
                          {
                            staticClass:
                              "cubeLink_subText_p cube_subtext g_cube_link",
                            style: _vm.cubeLink_subText_style,
                          },
                          [_vm._v(_vm._s(_vm.linkSubText))]
                        ),
                      ]),
                    ]
                  ),
                ],
                2
              ),
            ]
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }