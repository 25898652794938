export function setPdMallPriceHeight(saleDLength) {
    var pruHeight = jm('.J_saleMallPrice').css('height');
    if (pruHeight.indexOf('rem') < 0) {
        if (pruHeight.indexOf('px') < 0) {
            pruHeight = parseFloat(pruHeight) / window._htmlFontSize;
        } else {
            pruHeight = parseFloat(pruHeight.substring(0, pruHeight.length - 2)) / window._htmlFontSize;
        }
    } else {
        pruHeight = parseFloat(pruHeight.substring(0, pruHeight.length - 3));
    }

    pruHeight = pruHeight + parseFloat(saleDLength) * 2.3;
    // jm(".J_saleMallPrice").css("height", pruHeight + "rem");
    // jm(".J_saleMallPrice").css("line-height", pruHeight + "rem");
    jm('.J_salePrePrice').css('height', pruHeight + 'rem');
    jm('.J_salePrePrice').css('line-height', pruHeight + 'rem');
}

export function showNewMarketPrice(showNewMarketPrice) {
    if (showNewMarketPrice == true) {
        jm('.marketPriceNew').css('display', 'block');
        jm('.marketMemberPrice').css('display', 'none');
    }
}

export function setMarketingProPdStyle(proSelSignValue) {
    if (proSelSignValue == 'style1') {
        jm('#memberPricePd').css('background', '#fff');
        jm('#memberPricePd').find('.p1').css('background', '#fff');
        jm('#memberPricePd').find('.p3').css('background', '#fff');
    }
    if (proSelSignValue == 'style3') {
        jm('#memberPricePd').find('.p1').css('display', 'none');
        jm('#memberPricePd').find('.p3').css('display', 'none');
        jm('#memberPricePd').css('border', 'none');
        jm('.productPrice .backMoneyBg').css('height', '18.8px');
        // fix 会员标签、推广标签和产品价格没有在同一水平线展示
        jm('.productPrice .backMoneyBg').css('top', '-2px');
        jm('.productPrice .backMoney').css({
            top: '-2px',
            position: 'absolute',
            padding: '3px 4px',
        });
        jm('.memberPricePd').css('left', '2px');

        jm('.productPrice .memberPricePd').css('position', 'relative');
        jm('.productPrice .memberPricePd').css('text-align', 'center');
        jm('.productPrice .memberPricePd').css('white-space', 'nowrap');
        jm('.productPrice .memberPricePd').css('padding', '6px 5px');
    }
    // if(isV3){
    // 	jm(".productPrice .backMoneyBg").css("top", "0px");
    // }
}
