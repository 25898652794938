import { MobiModule } from '../Module';
import { MODULE_STYLE } from '@/def/module';
import SearchResultView from './module/searchResult.vue';

class _SearchResult extends MobiModule {
    constructor(options = {}) {
        super(MODULE_STYLE.TOTAL_SEARCH_RESULT, options);
    }

    render() {
        return SearchResultView;
    }
}

export const SearchResult = new _SearchResult();
