var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("input", {
    staticClass: "m_mobi_form_input_text m_mobi_form_email",
    style: _vm._mixinsInputStyle,
    attrs: { type: "text", placeholder: _vm.formItem.placeholder },
    domProps: { value: _vm.currentValue },
    on: { input: _vm.handleInput },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }