var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "waterfall_item",
      style: _vm.waterFallItemContainerStyle,
      attrs: { id: _vm.photoItem.picId, "data-picid": _vm.photoItem.picId },
    },
    [
      _c(
        "a",
        {
          staticClass: "photo_wf_item",
          style: _vm.waterFallItemStyle,
          attrs: {
            href: _vm.imgHref,
            rel: _vm.photoItem.nf ? "nofollow" : "",
            onclick: _vm.jumpOnclickStr,
          },
          on: { click: _vm.photoSlidesshow },
        },
        [
          _vm.waterFallHeightType == 0
            ? [
                _c("div", { style: _vm.photoItemStyle }, [
                  _c("img", {
                    staticClass: "J_img_lazyload",
                    style: _vm.photoItemImgStyle,
                    attrs: {
                      "src-original": _vm.photoItem.picThumbPath,
                      src: _vm.noLazyLoad
                        ? _vm.photoItem.picThumbPath
                        : _vm.loadingPath,
                      width: "100%",
                    },
                  }),
                ]),
              ]
            : [
                _c("div", {
                  directives: [
                    {
                      name: "drag-resize",
                      rawName: "v-drag-resize",
                      value: _vm._resizeOptions,
                      expression: "_resizeOptions",
                    },
                  ],
                  ref: "itemFix",
                  staticClass: "J_img_lazyload",
                  style: _vm.photoItemFixStyle,
                  attrs: { "src-original": _vm.photoItem.picThumbPath },
                }),
              ],
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "photo_wf_item_text",
              style: _vm.waterFallTextStyle,
            },
            [
              _c("div", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showTitle,
                    expression: "showTitle",
                  },
                ],
                staticClass: "photo_wf_item_name",
                style: _vm.waterFallTitleStyle,
                domProps: { innerHTML: _vm._s(_vm.titleData) },
              }),
              _vm._v(" "),
              _c("div", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showDesc,
                    expression: "showDesc",
                  },
                ],
                staticClass: "photo_wf_item_desc",
                style: _vm.waterFallDescStyle,
                domProps: { innerHTML: _vm._s(_vm.descData) },
              }),
            ]
          ),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }