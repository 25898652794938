<template>
    <div class="mobi_vote_checkbox">
        <div v-for="item in voteItem.itemList" :key="radioKey(item)" class="choice_box_wrap" :class="itemWrapClass">
            <div v-show="imgType" class="vote_item_content_img" @click="imgClick($event, item.id)"
                ><img class="style1Img vote_item_img" :src="item.imgPath || module.extInfo.defaultpPath"
            /></div>
            <label
                class="m_checkbox_wrap choice_box"
                :class="{
                    m_checkbox_checked: currentValue.includes(item.id),
                }"
            >
                <span class="m_checkbox">
                    <span
                        class="m_vote_checkbox_inner m_checkbox_inner icon-"
                        :class="{ active: currentValue.includes(item.id) }"
                    ></span>
                    <input
                        class="m_checkbox_input"
                        type="checkbox"
                        :checked="currentValue.includes(item.id)"
                        :value="item.id"
                        @change="change"
                    />
                </span>
                <span> {{ item.name }}</span>
            </label>
        </div>
    </div>
</template>
<script>
export default {
    name: 'VoteCheckbox',
    inject: ['module', 'options'],
    props: {
        voteItem: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            currentValue: [],
        };
    },
    computed: {
        submitVal() {
            return this.currentValue;
        },
        imgType() {
            return this.voteItem.v_type === 'img';
        },
        itemWrapClass() {
            return this.module.content.iwrap == 1 ? 'voteItemWrap' : '';
        },
    },
    methods: {
        change(event) {
            let value = parseInt(event.target.value);
            let valueIndex = this.currentValue.indexOf(value);
            if (valueIndex > -1) {
                this.currentValue.splice(valueIndex, 1);
            } else {
                this.currentValue.push(value);
            }
        },
        validate() {
            if (this.currentValue.length === 0) {
                this.$mobiIng(LS.voteNotSelect);
                return false;
            }
            return true;
        },
        radioKey(radio) {
            return `radio${radio.id}`;
        },
        resetValue() {
            this.currentValue = [];
        },
        imgClick(event, value) {
            let valueIndex = this.currentValue.indexOf(value);
            if (valueIndex > -1) {
                this.currentValue.splice(valueIndex, 1);
            } else {
                this.currentValue.push(value);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.m_vote_checkbox_inner {
    background-color: #fff;
}

.m_vote_checkbox_inner.active {
    background-color: #292929;
    color: #fff;
}

.mobi_vote_checkbox .m_checkbox_wrap {
    vertical-align: middle;
    cursor: pointer;
    pointer-events: auto;
    margin-right: 1rem;
}

.mobi_vote_checkbox .m_checkbox {
    display: inline-block;
    margin-right: 0.2rem;
    white-space: nowrap;
    outline: 0;
    position: relative;
    line-height: 1;
    vertical-align: -0.2em;
    cursor: pointer;
}

.mobi_vote_checkbox .m_checkbox_inner {
    position: relative;
    display: inline-block;
    width: 0.8rem;
    height: 0.8rem;
    top: 0;
    left: 0;
    border: 1px solid rgba(216, 216, 216, 1);
    border-radius: 0.13rem;
    font-size: 0.375rem;
    box-sizing: border-box;
}

.mobi_vote_checkbox .m_checkbox_checked .m_checkbox_inner {
    border: none;
}

.mobi_vote_checkbox .m_checkbox_checked .m_checkbox_inner:after {
    content: '\b0335';
    position: absolute;
    left: 50%;
    top: 50%;
    border-radius: 0;
    transform: translate(-50%, -50%);
    font-size: 0.6rem;
}

.mobi_vote_checkbox .m_checkbox_input {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}
</style>
