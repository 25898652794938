<template>
    <div>
        <div :id="_listId" class="mProductList styleForm33" :class="_listClass" :mType="module.content.t">
            <template v-for="(info, index) in productList">
                <big-pic-list-item :key="'p_' + info.id" :info="info" :index="index" :no-lazy-load="noLazyLoad" />
            </template>
        </div>
    </div>
</template>

<script>
import BigPicListItem from './big-pic-list-item.vue';
import productMixin from '../../mixins/productMixin';

export default {
    name: 'BigPicList',
    components: { BigPicListItem },
    mixins: [productMixin],
    inject: ['module', 'options'],
    props: ['productList', 'noLazyLoad'],
};
</script>

<style></style>
