var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("module-frame", { attrs: { "module-id": _vm.moduleId } }, [
    _c(
      "div",
      {
        staticClass: "photoModule styleForm6 g_background",
        staticStyle: { margin: "0" },
      },
      [
        _c(
          "div",
          { staticClass: "photoSwipe", attrs: { id: _vm.photoSwipeId } },
          [
            _c(
              "div",
              { staticClass: "photoSwipeBox" },
              _vm._l(_vm.photoList, function (photo, index) {
                return _c(
                  "div",
                  { key: photo.id, staticClass: "swipImgArea imageDiv" },
                  [
                    _c("a", { attrs: { hidefocus: "true" } }, [
                      _c("span", { staticClass: "imageMiddleSpan" }),
                      _vm._v(" "),
                      _c("img", {
                        attrs: {
                          src: photo.picThumbPath,
                          vwidth: photo.rect640Width,
                          vheight: photo.rect640Height,
                          alt: photo.id,
                          photoIndex: index,
                          linkType: _vm.pLinkType,
                        },
                      }),
                    ]),
                  ]
                )
              }),
              0
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "photoBullet noBG",
                attrs: { id: _vm.photoBulletId },
              },
              [
                _c(
                  "ul",
                  {
                    staticClass: "bullets",
                    attrs: { id: "bullets" + _vm.moduleId },
                  },
                  [
                    _c("div", {
                      staticClass: "imgName",
                      attrs: { id: "imgName" + _vm.moduleId },
                    }),
                    _vm._v(" "),
                    _vm._l(
                      _vm.photoList.slice(0, _vm.maxCount),
                      function (photo, j) {
                        return _c("li", {
                          key: photo.id,
                          class: { on: j === 0 },
                        })
                      }
                    ),
                  ],
                  2
                ),
              ]
            ),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }