var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ModuleFrame",
    { attrs: { "module-id": _vm.moduleId } },
    [_c("Entrance", { attrs: { "module-id": _vm.moduleId } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }