import { initPhotoSwipe, initPhotoImageSwipe } from '@/modules/shared/photo';

import { initPictureSlidesV4 } from '@/shared/photo/slides';

export function bindEvent(context) {
    let moduleId = context.moduleId;
    let content = context.module.content;
    let scale = content.picScale;
    if (content.type === 1) {
        fixImageHeightPhoList('module' + context.moduleId, scale, context.module.extInfo.imgBoxScale);
        context.module.extInfo.tmpWidth <= 150 &&
            unifiedAttrVal(jm('#module' + context.moduleId), '.photoModule .photoDiv', 'height');
    } else if (content.type == 6) {
        let extInfo = context.module.extInfo;
        initPictureSlidesV4(
            `#module${context.moduleId} .photoImageItem`,
            extInfo.photoSwipeList,
            extInfo.photoSwipeOption,
            false
        );
        let nameList = extInfo.list.map((i) => i.imgName);

        initPhotoSwipe(moduleId, nameList, content.interval, content.animate, scale);
    } else if (content.type == 7) {
        let extInfo = context.module.extInfo;
        initPictureSlidesV4(
            `#module${context.moduleId} .photoImageItem`,
            extInfo.photoSwipeList,
            extInfo.photoSwipeOption,
            false
        );
        initPhotoImageSwipe('photoModuleImageSwipe' + moduleId, scale);
    }
}

function fixImageHeightPhoList(moduleId, scale, imgBoxScale) {
    if (!moduleId) return;
    var module = document.getElementById(moduleId);
    if (!module) return;
    var photoDiv = jm('#' + moduleId).find('.photoDiv');
    var imgClass_160 = jm('#' + moduleId).find('.imgClass_160');
    var imgClassWidth = parseInt(imgClass_160.eq(0).width()); //存储初始化时候盒子的宽度
    var imageDom = module.querySelectorAll('img'),
        imgLenght = imageDom.length;
    var imageMiddle = jm('#' + moduleId).find('.imageMiddle');
    //计算父盒子的高度
    calImgMaxHeight();
    for (var i = 0; i < imgLenght; i++) {
        var imageWidth = jm(imageDom[i]).attr('vwidth');
        var imageHeight = jm(imageDom[i]).attr('vheight');
        if (scale == 2) {
            //等到图片外层高度初始化稳定后，再初始化图片
            for (var j = 0; j < imgLenght; j++) {
                //这里不能依靠i，load是异步调用，此时i可能是任何一个数
                var divWidth = imgClassWidth;
                var divHeight = imgClass_160.eq(0).height();
                var scaleInit = imageWidth / imageHeight;
                var scaleLast = divWidth / divHeight;
                // var imgNameHeight = jm(imgName[j]);
                if (scaleLast <= scaleInit) {
                    jm(imageDom[i]).css('width', scaleInit * divHeight + 'px');
                    jm(imageDom[i]).css('height', divHeight + 'px');
                    jm(imageDom[i]).css('maxWidth', 'initial');
                    jm(imgClass_160[i]).css('marginLeft', (divWidth - scaleInit * divHeight) / 2 + 'px');
                    jm(photoDiv[i]).css({
                        position: 'relative',
                        overflow: 'hidden',
                    });
                    jm(imageMiddle[i]).css('display', 'none');
                } else {
                    jm(imageDom[i]).css('height', divWidth / scaleInit + 'px');
                    jm(imageDom[i]).css('width', divWidth + 'px');
                    jm(imageDom[i]).css('marginTop', (divHeight - divWidth / scaleInit) / 2 + 'px');
                    jm(imgClass_160[i]).css('overflow', 'hidden');
                    jm(imageMiddle[i]).css('display', 'none');
                }
            }
        }
    }

    function calImgMaxHeight() {
        var imgClass_160 = jm('#' + moduleId).find('.imgClass_160'); //图片外的div
        if (scale != 1) {
            imgClass_160.css('height', imgClassWidth * imgBoxScale + 'px');
        }
    }
}

function unifiedAttrVal(parentE, childE, attr) {
    var ce = parentE.find(childE);
    var maxV = 0;
    ce.each(function () {
        var tmp = jm(this).css(attr);
        tmp = parseInt(tmp);
        if (tmp > maxV) {
            maxV = tmp;
        }
    });
    ce.each(function () {
        jm(this).css(attr, maxV + 'px');
    });
}
