import { initWebPage } from '@/shared/misc/init-webpage';
import { hidePlaceholder } from '@/shared/misc/placeholder';

import { scrollFadeInV3 } from '@/modules/comm';
import { initAjaxTab, couponPage } from './detail';
import { checkWindowSeStgValue } from '@/modules/shared/product/storage';
import { preprocessUrl } from '@/shared/url';
import { deviceTypeIsMobi } from '@/features/global-mobi-function/methods/linkSetting';
import { mallBuy, cutPrice } from '@/shared/mall';
import { createMallServicePanel } from './btn';
import { customPopup } from '@/shared/popup';
import { initProductSwipe } from '@/modules/shared/product/swipe';
import { sharePage } from '@/features/global-mobi-function/methods/share';
import { logMallFdp } from '@/shared/log';
import { changeHref, getRouterHistory, goBack } from '@/features/router/universal';
import { addRequestPrefix } from '@/shared/util';

import { bindImgLazyLoad } from '@/shared/imageLazyload';
import { setCommImgBindShow } from '@/modules/shared/product/comment';

import { ServiceFactory } from '@/services';
import { logJZFdp } from '@/api/logDog';

export const productNewDetailEventInitV3 = function (options) {
    if (jm('.J_salePrePrice').css('display') == 'inline-block') {
        //显示促销价隐藏市场价
        jm('.marketPriceV3').css('display', 'none');
    }
    if (!options) return;
    let {
        moduleId,
        productId,
        mallOptionsOpen,
        pageStyle,
        returnUrl = '',
        isGroupBuy = false,
        groupBuyId = 0,
        groupBuyDetailId = 0,
        groupPriceStr = '',
        isCutPrice = false,
        cutPriceId = 0,
        cpPriceStr = ' ',
        isSecKill = false,
        secKillId = 0,
        isPtLimitBuy = 0,
        isIntegralMall = false,
        integralMallId = 0,
        integtalMallType = 0,
        imMinPrice = {},
        isImLimitBuy = 0,
        isEarnestSale = false,
        earnestSaleId = 0,
        pd_optionsAmountInfo = {},
        pd_showOptionList = [],
        mkt_optionsAmountInfo = {},
        mkt_showOptionList = [],
        openPriceCalendar,
        themeV3 = false,
        isUseSwipe,
        isHotelProduct = false,
    } = options;
    if (typeof isUseSwipe === 'undefined') {
        isUseSwipe = true;
    }
    window.isGroupBuy = isGroupBuy;
    var initGroupBuyDetailId = groupBuyDetailId;
    scrollFadeInV3('productDetailHeader' + moduleId, 'productNewDetailSwipe' + moduleId, pageStyle);
    initAjaxTab({ pid: productId, moduleId, themeV3, isUseSwipe });

    var headDom = document.getElementById('productDetailHeader' + moduleId),
        productNewDetailPanelDom = document.getElementById('productNewDetailPanel' + moduleId),
        prodcutGoBack = headDom.querySelector('.icon-gClose.proBack'),
        prodcutOther = headDom.querySelector('.icon-gOther.proOther'),
        proOtherPanel = headDom.querySelector('.proOtherPanel'),
        productImmediatelyBug = productNewDetailPanelDom.querySelector('.J_productImmediatelyBug'),
        groupBuyBtn = productNewDetailPanelDom.querySelector('.J_groupBuyBtn'),
        cutPriceBtn = productNewDetailPanelDom.querySelector('.J_cpButton'),
        integralMallBtn = productNewDetailPanelDom.querySelector('.J_integralMallBtn'),
        earnestSaleBtn = productNewDetailPanelDom.querySelector('.J_earnestSaleBtn'),
        productNewDetailSwipe = document.getElementById('productNewDetailSwipe' + moduleId),
        productDetailV3_title = jm('#productDetailV3_title' + moduleId);

    var _isJumpFromMlist = false;
    if (window.sessionStorage) {
        _isJumpFromMlist = checkWindowSeStgValue(window.sessionStorage._isJumpFromMlist);
        try {
            //修复在无痕模式下导致的无法插入sessionStorage产生的问题
            window.sessionStorage._isJumpFromMlist = false;
        } catch (result) {
            console.warn(result);
        }
    }
    var _isJumpFromCollect = false;
    _isJumpFromCollect = jm.getCookie('_fromCollect');
    jm.cookie('_fromCollect', '', { expires: -1, path: '/' });
    const histories = getRouterHistory();

    jm(prodcutGoBack).bind('click', function () {
        if (window.sessionStorage) {
            // 这里判断是不是从订单评论跳过来的，如果是的话，还原订单评论的状态
            if (_isJumpFromMlist) {
                if (window.sessionStorage._jumpMlistUrl.indexOf('?') <= -1) {
                    changeHref(window.sessionStorage._jumpMlistUrl + '?commHideFlag=true');
                } else {
                    if (window.sessionStorage._jumpMlistUrl.indexOf('commHideFlag') <= -1) {
                        changeHref(window.sessionStorage._jumpMlistUrl + '&commHideFlag=true');
                    } else {
                        changeHref(window.sessionStorage._jumpMlistUrl);
                    }
                }
                window.sessionStorage._backFromProDetail = true;
            } else if (_isJumpFromCollect) {
                //返回时打开收藏页面
                jm.cookie('_openCollect', true, { path: '/' });
                changeHref(preprocessUrl({ path: '', oldPath: '' }) + 'profile.jsp');
            } else {
                // 如果是直接扫二维码到详情页,hostory.length为1，左上方的后退没用，所以默认调到首页
                if (histories.length <= 1) {
                    changeHref(preprocessUrl({ path: '', oldPath: '' }) + 'index.jsp');
                } else {
                    if (returnUrl.length > 0) {
                        changeHref(returnUrl);
                    } else {
                        if (VITE_APP_MODE !== 'visitor') {
                            if (histories.length > 0) {
                                goBack();
                            } else {
                                changeHref(preprocessUrl({ path: '', oldPath: '' }) + 'index.jsp');
                            }
                        } else {
                            var userAgentStr = navigator.userAgent.toLocaleLowerCase();
                            if (userAgentStr.includes('ucbrowser') && history.length <= 2) {
                                // 修复UC浏览器直接进入文章详情后点击返回键时，页面没有返回首页
                                changeHref('/');
                                return;
                            }

                            goBack();
                        }
                    }
                }
            }
            return false;
        } else {
            if (histories.length <= 1) {
                changeHref(preprocessUrl({ path: '', oldPath: '' }) + 'index.jsp');
            } else {
                changeHref(document.referrer); //数据刷新
                // 为了防止返回无效
                setTimeout(function () {
                    changeHref(document.referrer); //数据刷新
                    setTimeout(function () {
                        changeHref(preprocessUrl({ path: '', oldPath: '' }) + 'index.jsp');
                    }, 500);
                }, 300);
            }
            return false;
        }
    });

    jm(productNewDetailPanelDom).bind('click', function () {
        if (jm(proOtherPanel).hasClass('proOtherPanelOpen')) {
            jm(proOtherPanel).removeClass('proOtherPanelOpen');
        }
    });
    jm(prodcutOther).bind('click', function (e) {
        if (jm(proOtherPanel).hasClass('proOtherPanelOpen')) {
            jm(proOtherPanel).removeClass('proOtherPanelOpen');
        } else {
            jm(proOtherPanel).addClass('proOtherPanelOpen');
        }
        e.stopPropagation();
    });
    if (productDetailV3_title) {
        const change = function () {
            if (
                document.body.scrollTop > productNewDetailSwipe.clientHeight ||
                document.documentElement.scrollTop > productNewDetailSwipe.clientHeight
            ) {
                productDetailV3_title.show();
            } else {
                productDetailV3_title.hide();
            }
        };
        //适配苹果手机，苹果手机的scroll事件是不能实时监听的，所有要添加多一个touchmove事件,但是目前还是存在一定的问题
        document.addEventListener('touchmove', change);
        //监听滚动事件
        document.addEventListener('scroll', change);
    }

    //---------------
    var btnHtml = jm('.productMallShop').html(),
        pmbpHtml = jm('.productMallBuyPanel').html(),
        productInfoWebPage = initWebPage({
            triggerId: 'productInfoSize',
            panelId: 'productMallOptionPanel',
            callback: productInfo,
            pageBg: true,
            returnSeltFun: true,
            direction: 'bottom',
            closeWebPage: '#productMallOptionPanel .productDetailClose.icon-Off',
            closeCallBack() {
                jm('.productMallOptionPanel .productMallOptionContentPanel').removeClass(
                    'mallThemeProductContentPanel'
                );
                jm('.productMallBuyPanel').css('display', 'flex');
                var videoTags = jm('video');
                videoTags.css('display', 'block');
            },
        });
    function productInfo() {
        if (deviceTypeIsMobi() && jm('#mallOptionAndNumContainer').length > 0) {
            if (Mobi.productNewDetailIScrollObj) return;
            Mobi.productNewDetailIScrollObj = new iScroll('mallOptionAndNumContainer', {
                onBeforeScrollStart(e) {
                    var target = e.target;
                    while (target.nodeType != 1) target = target.parentNode;
                    if (target.tagName != 'INPUT') e.preventDefault();
                },
            });
        }
        if (!isSetContentHeight) {
            contentHeight();
        }
        return false;
    }

    function setGroupBuyRestrict() {
        if (isPtLimitBuy) {
            if (Mobi.isPtBtn) {
                //拼团限购有效，并且当前是点击了拼团按钮。处理拼团限购相关
                jm('#limitAmountDiv').hide();
                jm('.J_productNumberContent_simple').hide();

                jm('#limitAmountDiv_pt').show();
                jm('.J_productNumberContent_pt').show();
            } else {
                jm('#limitAmountDiv_pt').hide();
                jm('.J_productNumberContent_pt').hide();

                jm('#limitAmountDiv').show();
                jm('.J_productNumberContent_simple').show();
            }
        }
    }

    function setIntegralMallRestrict() {
        if (isImLimitBuy) {
            if (Mobi.isImBtn) {
                //积分商城限购有效，并且当前是点击了积分商城按钮。处理积分商城限购相关
                jm('#limitAmountDiv').hide();
                jm('.J_productNumberContent_simple').hide();

                jm('#limitAmountDiv_im').show();
                jm('.J_productNumberContent_im').show();
            } else {
                jm('#limitAmountDiv_im').hide();
                jm('.J_productNumberContent_im').hide();

                jm('#limitAmountDiv').show();
                jm('.J_productNumberContent_simple').show();
            }
        }
    }

    function setEarnestSaleRestrict() {
        if (Mobi.isEsBtn) {
            jm('.productName').hide();
        }
    }

    jm('#productInfoSize').bind('click', function () {
        var $productMallBuyPanel = jm('.productMallBuyPanel');
        if (!isHotelProduct) {
            if (btnHtml == null || btnHtml == '') return;
            $productMallBuyPanel.html(btnHtml);
        } else {
            if (pmbpHtml == null || pmbpHtml == '') return;
            $productMallBuyPanel.html(pmbpHtml);
            mallBuyType = 1;
        }

        jm('.productMallBuyPanel .productAddMall')
            .css('color', jm('.productNewDetailPanel .productAddMall').css('color'))
            .css('border-color', jm('.productNewDetailPanel .productAddMall').css('border-left-color'));
        jm('.productMallBuyPanel .productImmediatelyBug')
            .css('background-color', jm('.productNewDetailPanel .productImmediatelyBug').css('background-color'))
            .css('border-color', jm('.productNewDetailPanel .productImmediatelyBug').css('border-left-color'));

        jm('.productMallOptionPanel .productMallOptionContentPanel')
            .css('overflow', 'hidden')
            .addClass('mallThemeProductContentPanel'); //修复火狐这里出现滚动的导致按钮位置不够而换行

        jm('.productMallBuyPanel .J_productAddMall').bind('click', function () {
            mallBuy(productId, 0);
        });
        jm('.productMallBuyPanel .J_productImmediatelyBug')
            .unbind('click')
            .bind('click', function () {
                if (!isSecKill) {
                    mallBuy(productId, 1);
                } else {
                    mallBuy(productId, 1, null, null, null, null, {
                        isSecKill,
                        secKillId,
                    });
                }
            });
        jm('.productMallBuyPanel .J_groupBuyBtn')
            .unbind('click')
            .bind('click', function () {
                mallBuy(productId, 1, null, null, null, null, {
                    isGroupBuy,
                    groupBuyId,
                    groupBuyDetailId,
                });
            });
        jm('.productMallBuyPanel .J_cpButton')
            .unbind('click')
            .bind('click', function () {
                Mobi.logDog(200535, 0);
                cutPrice(cutPriceId, productId);
            });
        jm('.productMallBuyPanel .serviceBtn')
            .off('click.pp')
            .on('click.pp', function () {
                createMallServicePanel();
            });
        jm('.productMallBuyPanel .J_integralMallBtn')
            .unbind('click')
            .bind('click', function () {
                mallBuy(productId, 1, null, null, null, null, {
                    isIntegralMall,
                    integralMallId,
                });
            });
        jm('.productMallBuyPanel .J_earnestSaleBtn')
            .unbind('click')
            .bind('click', function () {
                mallBuy(productId, 1, null, null, null, null, {
                    isEarnestSale,
                    earnestSaleId,
                });
            });
    });

    var isSetContentHeight = false;
    function contentHeight() {}

    //-----------------
    var mallBuyType;
    jm('#productNewDetailPanel' + moduleId + ' .J_productAddMall').bind('click', function () {
        jm('.productMallBuyPanel').html(pmbpHtml);
        mallBuyType = 0;
        Mobi.isPtBtn = false;
        Mobi.isImBtn = false;
        Mobi.isMarketingBtn = false;
        productInfoWebPage();
    });

    jm('.J_pdJoinGroupBuyBtn').bind('click', function () {
        if (Fai.top.sessionMemberId == 0) {
            changeHref(
                preprocessUrl({ path: '', oldPath: '' }) + 'login.jsp?returnUrl=' + jm.encodeUrl(Fai.top.location.href)
            );
        }
        groupBuyDetailId = jm(this).attr('_groupBuyDetailId');
        var block = jm(this).attr('_oldMemberBlock');

        if (block === 'true') {
            var html = '';
            html +=
                "<div style='width: 11.9rem; height: 10.476rem; background: rgba(255,255,255,1); border-radius: .357rem; margin: 0 auto; position: relative; top: -3rem; display: flex; flex-direction: column; align-items: center; padding: 1.05rem; box-sizing: border-box;'>";
            html += "<div style='width: 8.12rem; height: 4.69rem; overflow: hidden; margin-bottom: .84rem;'>";
            html +=
                "<img src='" +
                Fai.top._resImageRoot +
                "/image/gbNewMemOnly.png' style='width: 8.12rem; height: 4.69rem;'>";
            html += '</div>';
            html +=
                "<div style='font-size: .62rem; color:rgba(0,0,0,1); margin-bottom: .857rem;'>仅限新客参团，老客可以开团</div>";
            html +=
                "<div class='J-prompt-callback' style='width: 5.714rem; height: 1.43rem; background: rgba(242,48,48,1); border-radius: .1429rem; color: #fff; margin: 0 auto; font-size: .62rem; display: flex; align-items: center; justify-content: center; cursor: pointer;'>去开新团</div>";
            html += '</div>';
            html +=
                "<div class='J-prompt-cancel' style='display: inline-block; width: 1.67rem; height: 1.67rem; z-index: 9999; margin: 0 auto; cursor: pointer;'>";
            html += "<img src='" + Fai.top._resImageRoot + "/image/hdCouponClose.png'>";
            html += '</div>';

            customPopup({
                content: html,
                callback() {
                    if (isGroupBuy) {
                        jm('#mallPrice').html(groupPriceStr);
                    }
                    jm('.productMallBuyPanel').html(pmbpHtml);
                    mallBuyType = 2;
                    groupBuyDetailId = 0;
                    Mobi.isPtBtn = true;
                    Mobi.isImBtn = false;
                    Mobi.isMarketingBtn = true;
                    productInfoWebPage();
                },
            });
            return;
        }

        mallBuyType = 2;
        productInfoWebPage();
        setGroupBuyRestrict();
        jm('#mallPrice').html(groupPriceStr); //参团按钮，肯定显示拼团价啦
        Mobi.isPtBtn = true;
        Mobi.isImBtn = false;
        Mobi.isMarketingBtn = true;
    });

    jm('.productMallBuyPanel').delegate('.productMallBuy', 'click', function () {
        if (Mobi._isCommunityGroup) {
            //社区团购拦截普通加入购物车操作
            mallBuy(productId, 0, null, null, null, null, {
                cb: (optionList, count) => {
                    Mobi._communityPdDetailVm && Mobi._communityPdDetailVm.confirmPd2Cart(productId, optionList, count);
                },
                hideAddCartTips: true,
                fromCommunityGroup: true,
            });
            return;
        }
        if (mallBuyType == 1) {
            if (isSecKill) {
                mallBuy(productId, 1, null, null, null, null, {
                    isSecKill,
                    secKillId,
                });
            } else {
                mallBuy(productId, mallBuyType);
            }
        } else if (mallBuyType == 2) {
            mallBuy(productId, 1, null, null, null, null, {
                isGroupBuy,
                groupBuyId,
                groupBuyDetailId,
            });
        } else if (mallBuyType == 3) {
            cutPrice(cutPriceId, productId);
        } else if (mallBuyType == 4) {
            if (Fai.top.sessionMemberId == 0) {
                changeHref(
                    preprocessUrl({ path: '', oldPath: '' }) +
                        'login.jsp?returnUrl=' +
                        jm.encodeUrl(Fai.top.location.href)
                );
            }
            mallBuy(productId, 1, null, null, null, null, {
                isIntegralMall,
                integralMallId,
            });
        } else if (mallBuyType == 5) {
            mallBuy(productId, 1, null, null, null, null, {
                isEarnestSale,
                earnestSaleId,
            });
        } else {
            mallBuy(productId, mallBuyType, null, null, null, null, {
                cb: function () {
                    // 秒杀埋点
                    if (isSecKill) {
                        logMallFdp(
                            'mall_seckill_shoppingcart',
                            {
                                mall_free_bool_0: mallBuyType == 0, // 秒杀产品是否加入购物车
                                mall_free_bool_1: false, // 是否与其他产品共同结算
                            },
                            true
                        );
                    }
                },
            });
        }
    });

    var oldMallPrice = jm('#mallPrice').html() || '';
    jm(productImmediatelyBug).bind('click', function () {
        jm('.productMallBuyPanel').html(pmbpHtml);
        mallBuyType = 1;
        Mobi.isPtBtn = false;
        Mobi.isImBtn = false;
        Mobi.isMarketingBtn = false;
        productInfoWebPage();
        setGroupBuyRestrict();
        setIntegralMallRestrict();
        setEarnestSaleRestrict();
        resetOptionContent();
        if (isGroupBuy) {
            //拼团情况下，恢复原来价格显示，并触发购物选项改变价格
            jm('#mallPrice').html(oldMallPrice);
            jm(jm('#productMallOptionPanel .optionSelected')[0]).click().click();
        }
        if (isIntegralMall) {
            //积分商城情况下，恢复原来价格显示，并触发购物选项改变价格
            jm('#mallPrice').html(oldMallPrice);
            jm(jm('#productMallOptionPanel .optionSelected')[0]).click().click();
            jm('#integral').hide();
            jm('#itgName').hide();
            jm('#plus').hide();
        }
        if (isEarnestSale) {
            //定金预售情况下，恢复原来价格显示，并触发购物选项改变价格
            jm('#mallPrice').html(oldMallPrice);
            jm(jm('#productMallOptionPanel .optionSelected')[0]).click().click();
        }
    });
    jm(groupBuyBtn).bind('click', function () {
        if (Fai.top.sessionMemberId == 0) {
            changeHref(
                preprocessUrl({ path: '', oldPath: '' }) + 'login.jsp?returnUrl=' + jm.encodeUrl(Fai.top.location.href)
            );
        }
        var block = jm(this).find('._oldMemberBlock').length > 0;
        if (block) {
            var html = '';
            html +=
                "<div style='width: 11.9rem; height: 10.476rem; background: rgba(255,255,255,1); border-radius: .357rem; margin: 0 auto; position: relative; top: -3rem; display: flex; flex-direction: column; align-items: center; padding: 1.05rem; box-sizing: border-box;'>";
            html += "<div style='width: 8.12rem; height: 4.69rem; overflow: hidden; margin-bottom: .84rem;'>";
            html +=
                "<img src='" +
                Fai.top._resImageRoot +
                "/image/gbNewMemOnly.png' style='width: 8.12rem; height: 4.69rem;'>";
            html += '</div>';
            html +=
                "<div style='font-size: .62rem; color:rgba(0,0,0,1); margin-bottom: .857rem;'>仅限新客参团，老客可以开团</div>";
            html +=
                "<div class='J-prompt-callback' style='width: 5.714rem; height: 1.43rem; background: rgba(242,48,48,1); border-radius: .1429rem; color: #fff; margin: 0 auto; font-size: .62rem; display: flex; align-items: center; justify-content: center; cursor: pointer;'>去开新团</div>";
            html += '</div>';
            html +=
                "<div class='J-prompt-cancel' style='display: inline-block; width: 1.67rem; height: 1.67rem; z-index: 9999; margin: 0 auto; cursor: pointer;'>";
            html += "<img src='" + Fai.top._resImageRoot + "/image/hdCouponClose.png'>";
            html += '</div>';

            customPopup({
                content: html,
                callback() {
                    if (isGroupBuy) {
                        jm('#mallPrice').html(groupPriceStr);
                    }
                    jm('.productMallBuyPanel').html(pmbpHtml);
                    mallBuyType = 2;
                    groupBuyDetailId = 0;
                    Mobi.isPtBtn = true;
                    Mobi.isImBtn = false;
                    Mobi.isMarketingBtn = true;
                    productInfoWebPage();
                },
            });
            return;
        }

        if (isGroupBuy) {
            jm('#mallPrice').html(groupPriceStr);
        }
        jm('.productMallBuyPanel').html(pmbpHtml);
        mallBuyType = 2;
        groupBuyDetailId = initGroupBuyDetailId;
        Mobi.isPtBtn = true;
        Mobi.isImBtn = false;
        Mobi.isMarketingBtn = true;
        productInfoWebPage();
        setGroupBuyRestrict();
        resetOptionContent();
    });
    jm(cutPriceBtn).bind('click', function () {
        if (mallOptionsOpen) {
            if (isCutPrice) {
                jm('#mallPrice').html(cpPriceStr);
            }
            jm('.productMallBuyPanel').html(pmbpHtml);
            mallBuyType = 3;
            Mobi.isPtBtn = false;
            Mobi.isImBtn = false;
            Mobi.isMarketingBtn = false;
            productInfoWebPage();
        } else {
            cutPrice(cutPriceId, productId);
        }
    });
    jm(integralMallBtn).bind('click', function () {
        jm('.productMallBuyPanel').html(pmbpHtml);
        mallBuyType = 4;
        Mobi.isImBtn = true;
        Mobi.isMarketingBtn = true;
        productInfoWebPage();
        setIntegralMallRestrict();
        resetOptionContent();
        if (isIntegralMall) {
            var optionlines = jm('.J-op-box').find('.J-op'),
                optionsSelect = jm('.J-op-box').find('.optionSelected');
            if (optionlines.length == 0 || (optionlines.length > 0 && optionsSelect.length < optionlines.length)) {
                jm('#integral').html(imMinPrice.minIntegral);
                jm('#mallPrice').html(parseFloat(imMinPrice.minPrice).toFixed(2));
            }

            if (integtalMallType == 1) {
                jm('#choiceCurrencyVal').hide();
                jm('#mallPrice').hide();
            }

            jm('#integral').show();
            jm('#itgName').show();
            jm('#plus').show();
            jm(jm('#productMallOptionPanel .optionSelected')[0]).click().click(); //积分商城情况下，恢复积分商城的最小价格显示，并触发购物选项改变价格
        }
    });
    jm(earnestSaleBtn).bind('click', function () {
        jm('.productMallBuyPanel').html(pmbpHtml);
        mallBuyType = 5;
        Mobi.isImBtn = false;
        Mobi.isPtBtn = false;
        Mobi.isEsBtn = true;
        Mobi.isMarketingBtn = true;
        productInfoWebPage();
        setEarnestSaleRestrict();
    });

    //Mobi.prodcutUserComment();

    /*  share start */
    var shareListForPromoWhenCloseShare = JSON.parse(JSON.stringify(Fai.top._shareDataProdectDetail));
    var prepareList = '[{"url":"?","icon":"Weixin"}]';
    shareListForPromoWhenCloseShare.shareLinkList = prepareList;

    let shareToService = ServiceFactory.get('ShareTo');

    function bindShareEvent() {
        // pinterest click binding
        jm('#shareLinkPinterest')
            .off('click')
            .on('click', () => {
                const activeIndex = jm('#productBullet #bullets').find('.on').index();
                const containerVisible =
                    jm('.proDetailImgBox').length > 0 && jm('.proDetailImgBox').css('display') !== 'none';
                const $img = jm('.proDetailImgBox').find('.swipImgBox').eq(activeIndex).find('.proDetailImg');
                if (containerVisible && $img && $img.length > 0) {
                    const parsedUrlInfo = $img.css('background-image').match(/^url\((['"]?)(.*)\1\)$/);
                    if (parsedUrlInfo && parsedUrlInfo[2]) {
                        const url = parsedUrlInfo[2];
                        shareToService.pinterest('one', {
                            url: window.location.href,
                            media: url,
                        });
                    }
                } else {
                    shareToService.pinterest('one', {
                        url: window.location.href,
                        media: '',
                    });
                }
            });
    }

    var shareOption = {
        triggerId: 'productDetailShare',
        pageBg: true,
        pageHtml: sharePage(Fai.top._shareDataProdectDetail),
        direction: 'bottom',
        callback() {
            initProductSwipe('shareListShowSwipe');
            bindShareEvent();
        },
        closeWebPage: '#shareListPanelBg',
    };
    initWebPage(shareOption);

    shareOption = {
        triggerId: 'productDetailShare2',
        pageBg: true,
        pageHtml: sharePage(Fai.top._shareDataProdectDetail),
        direction: 'bottom',
        callback() {
            initProductSwipe('shareListShowSwipe');
            bindShareEvent();
        },
        closeWebPage: '#shareListPanelBg',
    };
    initWebPage(shareOption);

    shareOption = {
        triggerId: 'promoShareIconForNotOpenShare',
        pageBg: true,
        pageHtml: sharePage(shareListForPromoWhenCloseShare),
        direction: 'bottom',
        callback() {
            initProductSwipe('shareListShowSwipe');
            bindShareEvent();
        },
        closeWebPage: '#shareListPanelBg',
    };
    initWebPage(shareOption);

    // 埋点
    const PRODUCT_V3_ID = 18;
    const moduleInfo = window.$store.state.currentPageModuleIdMap[PRODUCT_V3_ID];
    const NAME_MAPPING = {
        Weixin: '微信',
        douban: '豆瓣网',
        baidu_tieba: '百度贴吧',
        sina_weibo: '新浪微博',
        qq_share: 'QQ分享',
        qq_zone: 'QQ空间',
        copy_url: '复制链接',
        kaixin001: '开心网',
        FaceBook: '脸书',
        Twitter: '推特',
        LinkedIn: '领英',
        graphic_poster: '二维码海报',
        Pinterest: 'Pinterest',
    };
    let shares = JSON.parse(Fai.top._shareDataProdectDetail.shareLinkList);
    if (shares) {
        shares = shares.map((item) => NAME_MAPPING[item.icon]);
    } else {
        shares = [];
    }
    if (moduleInfo && VITE_APP_MODE === 'visitor') {
        logJZFdp('jz_product_pages_visit', {
            jz_content_terminal: 'mobi',
            jz_free_int1: moduleInfo.content.pageStyle,
            jz_free_text1: JSON.stringify(moduleInfo.extInfo.openShare === 0 ? shares : []),
        });
    }

    /*  share end */
    //优惠券领取弹窗start
    var couponPanelOption = {
        triggerId: 'showCouponList',
        pageBg: true,
        pageHtml: couponPage(Fai.top._couponInfo),
        direction: 'bottom',
        closeWebPage: '#contentBottomPanel,#closeCouponListBottomPanel',
        callbackAlways: true,
        callback() {
            jm('#couponListBottomPanel')
                .unbind('click mousedown touchstart')
                .bind('click mousedown touchstart', function (e) {
                    e.stopPropagation();
                });
            jm('#contentBottomPanel').show();
            jm('.toUseCoupon')
                .unbind('click')
                .bind('click', function (e) {
                    var couponId = jm(this).parents('.couponItem').attr('couponid');
                    changeHref(preprocessUrl({ path: '', oldPath: '' }) + 'pr.jsp?couponId=' + couponId);
                    e.stopPropagation();
                });
            Mobi.logDog(200474, 4);
            for (let i = 0; i < Fai.top._couponInfo.length; i++) {
                Mobi.logDog(200474, 5);
            }
        },
    };
    initWebPage(couponPanelOption);
    //优惠券领取弹窗end
    /* show and hide placeholder */
    hidePlaceholder();

    //重新渲染购物选项列表
    function resetOptionContent() {
        var opAmountInfo = {};
        var showOptionList = [];
        if (Mobi.isMarketingBtn) {
            opAmountInfo = mkt_optionsAmountInfo;
            showOptionList = mkt_showOptionList;
        } else {
            opAmountInfo = pd_optionsAmountInfo;
            showOptionList = pd_showOptionList;
        }

        if (showOptionList.length <= 0) {
            return;
        }

        var oldOptionsStr = opAmountInfo.t || '';
        var oldOptionsArr = oldOptionsStr.split('_');
        var optionsAmountList = opAmountInfo.d || [];

        var index = 0;

        for (var i = 0; i < showOptionList.length; i++) {
            var showOption = showOptionList[i];
            var tmpShowItems = showOption.items || [];
            var tmpOptionFieldKey = showOption.fieldKey || '';

            for (var j = 0; j < oldOptionsArr.length; j++) {
                if (tmpOptionFieldKey == oldOptionsArr[j]) {
                    index = j;
                }
            }
            if (showOption.type == 4) {
                //独立
                tmpOptionFieldKey = 'opIndept' + tmpOptionFieldKey;
            }
            // 打印出列表
            if (tmpShowItems.length > 0 && !openPriceCalendar) {
                jm.each(tmpShowItems, function (tmpIndex, tmpItem) {
                    var tmpItemId = tmpItem.i;
                    var flag = false;

                    jm.each(optionsAmountList, function (tmpIndex2, data) {
                        var t2 = data.t2;
                        var productFlag = data.flag === null ? 1 : data.flag;
                        var t2Arr = t2.split('_');
                        if (productFlag == 1 && index < t2Arr.length && parseInt(t2Arr[index]) == tmpItemId) {
                            flag = true;
                            return false;
                        }
                    });

                    if (flag) {
                        jm(".g_mallLabel[for='" + tmpOptionFieldKey + tmpItemId + "']").show();
                    } else {
                        jm(".g_mallLabel[for='" + tmpOptionFieldKey + tmpItemId + "']").hide();
                    }
                });
                index++;
            }
        }
    }
};

//showFrom: 从哪里点击打开购物选项面板的
export const showMallCartScreenV3 = function (moduleId, choiceCurrencyVal, mallShoppingOptions, levelDiscount, dom) {
    var panel = jm('.productMallOptionPanel');
    //为每个购物车按钮绑定事件，点击时动态生成对应的弹层dom
    jm('#module' + moduleId + ' .J_fk-mallCart')
        .off('click.cart')
        .bind('click.cart', function (e) {
            e.stopPropagation();
            var domHtml =
                dom && dom.replace
                    ? dom
                          .replace(/&nbsp;/gi, ' ')
                          .replace(/&lt;/gi, '<')
                          .replace(/&gt;/g, '>')
                          .replace(/&#92;/gi, '\\')
                          .replace(/&#39;/gi, "'")
                          .replace(/&quot;/gi, '"')
                          .replace(/<br\/>/gi, '\n')
                          .replace(/&amp;/gi, '&')
                    : dom;
            jm('#productMallOptionPanel_mcs_' + moduleId + ' .productMallOptionContent').append(domHtml);
            panel = jm('.productMallOptionPanel');

            var pid = jm(this).attr('id') || '';
            var isInCache = false; //判断缓存中是否有该产品
            var cacheInfo = {};
            var cacheHtml = '';
            var cacheScript = '';
            var cacheOtherHtml = {};
            var cacheIsPtLimitBuy = false;
            var cacheIsImLimitBuy = false;
            jm.each(Mobi.showProductCacheList, function (i, n) {
                if (n.id == pid) {
                    isInCache = true;
                    cacheInfo = n.pinfo;
                    cacheHtml = n.showOptionHtml;
                    cacheScript = n.script;
                    cacheOtherHtml = n.otherHtml;
                    cacheIsPtLimitBuy = n.isPtLimitBuy;
                    cacheIsImLimitBuy = n.isImLimitBuy;
                    return false;
                }
            });

            productShowCart();
            jm('#productMallOptionPanel_mcs_' + moduleId)
                .find('.productMallOptionPanel')
                .hide(); //没load出产品数据前先隐藏
            if (isInCache) {
                initAndShow(cacheInfo, cacheHtml, cacheScript, cacheOtherHtml, cacheIsPtLimitBuy, cacheIsImLimitBuy);
            } else {
                jm.ajax({
                    type: 'POST',
                    url: `${addRequestPrefix({
                        newPath: '/ajax',
                        oldPath: 'ajax',
                    })}/product_h.jsp?cmd=getWafNotCk_getProductInfoAndDomV3`,
                    data: {
                        pid,
                        moduleId,
                        mallShoppingOptions,
                    },
                    error() {
                        Mobi.ing(LS.js_systemError);
                    },
                    success(result) {
                        var res = jm.parseJSON(result);
                        if (res.success) {
                            var info = res.pinfo;
                            var optionHtml = res.showOptionHtml;
                            var script = res.script;
                            var otherHtml = res.otherHtml;
                            var isPtLimitBuy = res.isPtLimitBuy;
                            var isImLimitBuy = res.isImLimitBuy;
                            var isEarnestSale = res.isEarnestSale;
                            Mobi.showProductCacheList.push({
                                id: pid,
                                pinfo: info,
                                showOptionHtml: optionHtml,
                                script,
                                otherHtml,
                                isPtLimitBuy,
                                isImLimitBuy,
                                isEarnestSale,
                            });

                            initAndShow(info, optionHtml, script, otherHtml, isPtLimitBuy, isImLimitBuy, isEarnestSale);
                        }
                    },
                });
            }

            return false;
        });

    var productShowCart = initWebPage({
        triggerId: 'f' + moduleId,
        panelId: 'productMallOptionPanel_mcs_' + moduleId,
        pageBg: true,
        returnSeltFun: true,
        direction: 'bottom',
        closeWebPage: '#productMallOptionPanel_mcs_' + moduleId + ' .productDetailClose.icon-Off',
        closeCallBack() {
            jm('.productMallOptionPanel .productMallOptionContentPanel').removeClass('mallThemeProductContentPanel');
            jm(
                '#productMallOptionPanel_mcs_' + moduleId + ' .productMallOptionContent .productMallOptionHead'
            ).remove();
            jm('#productMallOptionPanel_mcs_' + moduleId + ' .productMallOptionContent .mallOptionAndNumBox').remove();
            jm('#productMallOptionPanel_mcs_' + moduleId + ' .productMallOptionContent .productMallShop').remove();
            jm('#productMallOptionPanel_mcs_' + moduleId + ' .productMallOptionContent .separatorLine').remove();
        },
    });

    function initAndShow(info, optionHtml, script, otherHtml, isPtLimitBuy, isImLimitBuy) {
        panel = jm('.productMallOptionPanel');
        var pid = info.id;
        panel.attr('id', 'productMallOptionPanel' + pid); //修改panel的id
        var mallAmountHtml = otherHtml.mallAmountHtml || '';
        var mallWeightHtml = otherHtml.mallWeightHtml || '';
        var mallPriceHtml = otherHtml.mallPriceHtml || '';
        var productMallShopHtml = otherHtml.productMallShopHtml || '';
        var siteFormPleaseSelect = otherHtml.siteFormPleaseSelect || '请选择';
        //s----为了显示价格
        var value = info.mallPrice;
        var propValue = value.toFixed(2);
        var tempSaleProValue = propValue;
        var memberMaxPrice = '';
        var memberMinPrice = '';

        if (info.openPriceCalendar) {
            panel.addClass('withPriceCalendar');
            panel.find('.productMallOptionHead .productMallOptionImage').hide();
            panel.find('.productMallOptionHead .mallOptionImg').hide();
            panel.find('.productMallOptionHead .productDetail').hide();
            panel.find('.productMallOptionHead').append("<div class='indicator'>" + siteFormPleaseSelect + '</div>');
        } else {
            panel.removeClass('withPriceCalendar');
        }

        if (mallShoppingOptions) {
            //存在购物选项时
            var minPrice = propValue;
            var maxPrice = propValue;
            var optionsAmountInfo = info.optionsAmountList || {};
            var optionsAmountList = optionsAmountInfo.d || [];
            jm.each(optionsAmountList, function (i, n) {
                var data = n;
                var oPrice = data.oPrice;
                if (oPrice == null || oPrice == '') {
                    return;
                }
                var oFlag = data.flag || 0;
                if (oFlag == 0) {
                    return;
                }
                var tmp = oPrice || 0.0;
                minPrice = minPrice < tmp ? minPrice : tmp;
                maxPrice = maxPrice > tmp ? maxPrice : tmp;
                minPrice = parseFloat(minPrice) || 0.0;
                maxPrice = parseFloat(maxPrice) || 0.0;
                if (minPrice == maxPrice) {
                    propValue = minPrice.toFixed(2);
                } else {
                    propValue = minPrice.toFixed(2) + '~' + maxPrice.toFixed(2);
                    memberMaxPrice = maxPrice.toFixed(2);
                    memberMinPrice = minPrice.toFixed(2);
                    //priceScope = minPrice + "~" + maxPrice;      报错。这段代码看起来是没用的，else逻辑用来获取最小价格和最大价格
                }
            });
        }
        if (memberMinPrice != '' || memberMaxPrice != '') {
            var maxpriceFormat = parseFloat(memberMaxPrice) * levelDiscount * 0.01;
            var minpriceFormat = parseFloat(memberMinPrice) * levelDiscount * 0.01;
            tempSaleProValue = minpriceFormat + '~' + maxpriceFormat;
        }
        jm('#choiceCurrency').text(choiceCurrencyVal);
        jm('#mallPrice').text(tempSaleProValue);

        //e----为了显示价格

        //显示其他简单信息
        panel
            .find('.mallOptionImg')
            .css('background-image', 'url(' + info.picPath + ')')
            .attr('src', info.picPath);
        panel.find('.productMallAmount').html(mallAmountHtml);
        panel.find('.productMallWeight').html(mallWeightHtml);
        panel.find('.productDetailPrice').html(mallPriceHtml);

        panel.find('.J-op-box').html(optionHtml); //显示购物选项

        panel.find('.btnGroup').html(productMallShopHtml);

        var suffix = isPtLimitBuy ? '_pt' : isImLimitBuy ? '_im' : '';
        jm('#g_increase' + suffix)
            .unbind('click.ic')
            .bind('click.ic', function () {
                Mobi.decrease2(pid, suffix);
            });
        jm('#g_decrease' + suffix)
            .unbind('click.dc')
            .bind('click.dc', function () {
                Mobi.increase2(pid, suffix);
            });

        //以生成script标签的方式执行js，完毕就remove掉标签
        var newScript = document.createElement('script');
        newScript.type = 'text/javascript';
        newScript.innerHTML = script;
        newScript.id = 'opScript_' + moduleId;
        document.getElementById('productMallOptionPanel_mcs_' + moduleId).appendChild(newScript);
        jm('#opScript_' + moduleId).remove();

        //拼团限购相关
        if (isPtLimitBuy) {
            jm('#limitAmountDiv').hide();
            jm('.J_productNumberContent_simple').hide();

            jm('#limitAmountDiv_pt').show();
            jm('.J_productNumberContent_pt').show();
        } else {
            jm('#limitAmountDiv_pt').hide();
            jm('.J_productNumberContent_pt').hide();

            jm('#limitAmountDiv').show();
            jm('.J_productNumberContent_simple').show();
        }

        //积分商城限购相关
        if (isImLimitBuy) {
            jm('#limitAmountDiv').hide();
            jm('.J_productNumberContent_simple').hide();

            jm('#limitAmountDiv_im').show();
            jm('.J_productNumberContent_im').show();
        } else {
            jm('#limitAmountDiv_im').hide();
            jm('.J_productNumberContent_im').hide();

            jm('#limitAmountDiv').show();
            jm('.J_productNumberContent_simple').show();
        }

        jm('#productMallOptionPanel_mcs_' + moduleId + ' #productBuy')
            .unbind('click.msc')
            .bind('click.msc', function () {
                Mobi.mallBuy(pid, 0, 1, moduleId, null, null, {
                    cb: function () {},
                });
            });
        jm('#productMallOptionPanel_mcs_' + moduleId + ' .J_productImmediatelyBug')
            .unbind('click.msc')
            .bind('click.msc', function () {
                Mobi.mallBuy(pid, 1, 1, moduleId);
            });

        jm('#productMallOptionPanel_mcs_' + moduleId + ' .J_productImmeSecKill')
            .unbind('click.msc')
            .bind('click.msc', function () {
                var secKillId = parseInt(jm(this).attr('secKillId') || 0);
                Mobi.mallBuy(pid, 1, null, moduleId, null, null, {
                    isSecKill: true,
                    secKillId,
                });
            });

        jm('#productMallOptionPanel_mcs_' + moduleId + ' .J_productImmeGroupBuy')
            .unbind('click.msc')
            .bind('click.msc', function () {
                var groupBuyId = parseInt(jm(this).attr('groupBuyId') || 0);
                Mobi.mallBuy(pid, 1, null, null, null, null, {
                    isGroupBuy: true,
                    groupBuyId,
                    groupBuyDetailId: 0,
                });
            });

        jm('#productMallOptionPanel_mcs_' + moduleId + ' .J_productImmeCutPrice')
            .unbind('click.msc')
            .bind('click.msc', function () {
                var cutPriceId = parseInt(jm(this).attr('cutPriceId') || 0);
                Mobi.cutPrice(cutPriceId, pid);
            });

        jm('#productMallOptionPanel_mcs_' + moduleId + ' .J_productImmeIntegralMall')
            .unbind('click.msc')
            .bind('click.msc', function () {
                var integralMallId = parseInt(jm(this).attr('integralMallId') || 0);
                Mobi.mallBuy(pid, 1, null, null, null, null, {
                    isIntegralMall: true,
                    integralMallId,
                });
            });

        jm('#productMallOptionPanel_mcs_' + moduleId + ' .J_productImmeEarnestSale')
            .unbind('click.msc')
            .bind('click.msc', function () {
                var earnestSaleId = parseInt(jm(this).attr('earnestSaleId') || 0);
                Mobi.mallBuy(pid, 1, null, null, null, null, {
                    isEarnestSale: true,
                    earnestSaleId,
                });
            });

        jm('#productMallOptionPanel_mcs_' + moduleId)
            .find('.mallOptionImg')
            .click(function () {
                var picSrc = jm(this).attr('src');
                jm('#module' + moduleId)
                    .find('#J_acct_pic')
                    .attr('src', picSrc)

                    .click();
            });

        jm('#productMallOptionPanel_mcs_' + moduleId)
            .find('.productMallOptionPanel')
            .show();

        //对于一些限购的情况，在每次进入该页面时都要调用。
        //有一种情况是起购为3，在点立即购买后返回，数量会变成1。
        //这是因为手机浏览器返回会读取缓存的视图，通过设置pageshow事件可以在每次显示页面都执行想要的代码
        var newScript2 = document.createElement('script');
        newScript2.type = 'text/javascript';
        newScript2.innerHTML = 'jm(window).bind("pageshow", function(){jm("#productNum' + suffix + '").change();});';
        newScript2.id = 'opScript2_' + moduleId;
        document.getElementById('productMallOptionPanel_mcs_' + moduleId).appendChild(newScript2);
    }
};

//添加商品或文章的新评论

export const getCommentAjaxPagenationV3 = function (pid, pageno) {
    if (!pid) return;
    var r = jm('html').css('font-size');
    r = r.substring(0, r.length - 2);
    var ajax_data =
        'pid=' + pid + '&pageno=' + pageno + '&ajaxPage=' + true + '&rem=' + r + '&loadingPath=' + Fai.top._loadingPath;
    jm.ajax({
        type: 'post',
        url: '/ajax/product_h.jsp?cmd=getWafNotCk_getProductInfoCommentV3',
        data: ajax_data,
        error() {
            Mobi.ing(LS.js_systemError);
        },
        success(result) {
            var data = jm.parseJSON(result);
            if (!data.html) data.html = '';
            jm('#tabPanel3 .commentBoxPanel').remove();
            // 3.0模块的DOM结构与2。0模块相比发生了变化，旧代码导致错误，重新修正插入评论DOM位置
            // document.getElementById("tabPanel3").insertBefore(jm(data.html)[0], jm("#tabPanel3 .userComment")[0]);
            document.getElementById('tabPanel3').insertBefore(jm(data.html)[0], jm('#tabPanel3 .userCommentBox')[0]);
            setTimeout(function () {
                bindImgLazyLoad('tabPanel3');
            }, 0);
            setCommImgBindShow();
        },
    });
};
