var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a",
    {
      attrs: {
        href: _vm.product.htmlUrl.endsWith("pd.jsp")
          ? "javascript:void(0);"
          : _vm.product.htmlUrl,
        onclick: _vm.onclickStr,
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "product_item J_product_item",
          class: _vm.productItemClass,
          attrs: { productId: _vm.product.id, productName: _vm.product.name },
        },
        [
          _c(
            "div",
            {
              staticClass: "img_panel",
              attrs: {
                id: "product" + _vm.product.id + "module" + _vm.moduleId,
              },
            },
            [
              _c("div", {
                staticClass: "photoListImg J_img_lazyload",
                style: _vm.imgStyle(_vm.product),
                attrs: { "src-original": _vm.product.newPath },
              }),
              _vm._v(" "),
              _vm.isShowQualityContentLabel
                ? _c("quality-content-label", {
                    attrs: { text: _vm.qualityContentLabelText },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "right_content" }, [
            _c("div", { staticClass: "title g_product_name", style: _vm.pn }, [
              _vm.product.isTimedAdded
                ? _c(
                    "span",
                    {
                      staticClass:
                        "g_oldThemeBgColor g_main_bgColor_v3 m_timedAddTag",
                    },
                    [_vm._v("即将开售")]
                  )
                : _vm._e(),
              _vm._v(_vm._s(_vm.product.name) + "\n            "),
            ]),
            _vm._v(" "),
            _vm.product.isShowMallPrice &&
            !_vm.product.vipName &&
            !_vm.isLinkProduct
              ? _c(
                  "div",
                  { staticClass: "g_main_color_v3 price_panel mallPrice" },
                  [
                    !_vm.siteCurrencyRight
                      ? _c("span", {
                          staticClass: "unit",
                          domProps: { textContent: _vm._s(_vm.product.unit) },
                        })
                      : _vm._e(),
                    _c("span", {
                      staticClass: "num1",
                      domProps: { textContent: _vm._s(_vm.product.num1 + ".") },
                    }),
                    _c("span", {
                      staticClass: "num2",
                      domProps: { textContent: _vm._s(_vm.product.num2) },
                    }),
                    _vm.siteCurrencyRight
                      ? _c("span", {
                          staticClass: "unit",
                          domProps: { textContent: _vm._s(_vm.product.unit) },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.product.enablePromotion && _vm.product.isSaleTypeDis
                      ? _c("div", {
                          staticClass: "linePrice",
                          domProps: {
                            textContent: _vm._s(
                              _vm.siteCurrencyRight
                                ? _vm.formatPrice(_vm.product.mallPrice) +
                                    _vm.product.unit
                                : _vm.product.unit +
                                    _vm.formatPrice(_vm.product.mallPrice)
                            ),
                          },
                        })
                      : _vm.product.isShowMarketPrice
                      ? _c("div", {
                          staticClass: "linePrice",
                          domProps: {
                            textContent: _vm._s(
                              _vm.siteCurrencyRight
                                ? _vm.formatPrice(_vm.product.mallMarketPrice) +
                                    _vm.product.unit
                                : _vm.product.unit +
                                    _vm.formatPrice(_vm.product.mallMarketPrice)
                            ),
                          },
                        })
                      : _vm._e(),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.product.isShowMallPrice && _vm.product.vipName
              ? _c(
                  "div",
                  {
                    staticClass: "g_main_color_v3 price_panel2 mallPrice",
                    class: _vm.productItemPriceClass,
                  },
                  [
                    _c("div", { staticClass: "price_wrap" }, [
                      _c("span", {
                        staticClass: "unit",
                        domProps: { textContent: _vm._s(_vm.product.unit) },
                      }),
                      _vm._v(" "),
                      _c("span", {
                        staticClass: "num1",
                        domProps: {
                          textContent: _vm._s(_vm.product.num1 + "."),
                        },
                      }),
                      _vm._v(" "),
                      _c("span", {
                        staticClass: "num2",
                        domProps: { textContent: _vm._s(_vm.product.num2) },
                      }),
                    ]),
                    _vm._v(" "),
                    _vm.product.vipName
                      ? _c(
                          "span",
                          {
                            staticClass: "vipName g_bgColor g_main_bgColor_v3",
                          },
                          [_vm._v(_vm._s(_vm.product.vipName))]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.product.isShowMarketPrice
                      ? _c("div", {
                          staticClass: "linePrice",
                          domProps: {
                            textContent: _vm._s(
                              _vm.product.unit +
                                _vm.formatPrice(_vm.product.mallMarketPrice)
                            ),
                          },
                        })
                      : _vm._e(),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.mallOpen &&
            _vm.product.isShowMallPrice &&
            !_vm.isOpenKnowPayProduct
              ? _c(
                  "div",
                  {
                    staticClass:
                      "g_main_bgColor_v3 mall_cart J_fk-mallCart f-mallCart icon-gwc",
                    attrs: { id: _vm.product.id },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.handleCart(_vm.product)
                      },
                    },
                  },
                  [_c("span", { staticClass: "faisco-icons-S000279" })]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.showBookingBtn
              ? _c(
                  "span",
                  {
                    staticClass: "g_main_bgColor_v3 g_button booking_btn",
                    class: _vm.btnClass,
                    attrs: {
                      id: "proListBookingBtn" + _vm.product.id,
                      _pid: _vm.product.id,
                    },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        return _vm.handleBookClick.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.bookingBtnText) +
                        "\n            "
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.showProductForm
              ? _c(
                  "span",
                  {
                    staticClass: "g_main_bgColor_v3 g_button booking_btn",
                    class: _vm.btnClass,
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        return _vm.handleFormClick.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.productFormText) +
                        "\n            "
                    ),
                  ]
                )
              : _vm._e(),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }