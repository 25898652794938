import { MobiModule } from '../Module';
import { MODULE_STYLE } from '@/def/module';
import PhotoListView from './module/index.vue';

class _PhotoList extends MobiModule {
    constructor(options = {}) {
        super(MODULE_STYLE.PHOTO_LIST, options);
    }

    render() {
        return PhotoListView;
    }
}

export const PhotoList = new _PhotoList();
