var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "online_map_view" }, [
    _c("div", { staticClass: "online_map_view_header" }, [
      _c(
        "svg",
        {
          staticClass: "back_arrow new_online_map_svg",
          attrs: { role: "img" },
          on: { click: _vm.back },
        },
        [_c("use", { attrs: { "xlink:href": "#jzm-v1" } })]
      ),
      _vm._v(" "),
      _c("span", { staticClass: "title" }, [
        _vm._v(_vm._s(_vm.otherInfo.title)),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "map_container", attrs: { id: "mapContainer" } }),
    _vm._v(" "),
    _c("div", { staticClass: "footer" }, [
      _c("div", { staticClass: "info" }, [
        _c("div", { staticClass: "wrap" }, [
          _c("span", { staticClass: "name" }, [_vm._v(_vm._s(_vm.name))]),
          _vm._v(" "),
          _c("span", { staticClass: "distance" }, [
            _vm._v(_vm._s(_vm.distance)),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "location", style: _vm.locationStyle }, [
          _c(
            "svg",
            {
              staticClass: "new_online_map_svg",
              style: _vm.locationSVGStyle,
              attrs: { role: "img" },
            },
            [_c("use", { attrs: { "xlink:href": "#jzm-v32" } })]
          ),
          _vm._v(" "),
          _c("span", { staticClass: "address" }, [_vm._v(_vm._s(_vm.detail))]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "action_area" }, [
        _c("div", { staticClass: "route", on: { click: _vm.routeNav } }, [
          _c(
            "svg",
            { staticClass: "new_online_map_svg", attrs: { role: "img" } },
            [_c("use", { attrs: { "xlink:href": "#jzm-v35" } })]
          ),
          _vm._v(" "),
          _c("span", [_vm._v(_vm._s(_vm.otherInfo.route))]),
        ]),
        _vm._v(" "),
        _vm.module.content.isShowTel
          ? _c("a", { staticClass: "tel", on: { click: _vm.callTel } }, [
              _c(
                "svg",
                { staticClass: "new_online_map_svg", attrs: { role: "img" } },
                [_c("use", { attrs: { "xlink:href": "#jzm-v33" } })]
              ),
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(_vm.otherInfo.call))]),
            ])
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }