var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "photoModule newCarouselMultiPhoto styleForm12 innerBox" },
    [
      _c(
        "div",
        {
          staticClass: "photoViewBox",
          attrs: { id: "photoViewBox" + _vm.module.id },
        },
        [
          _vm.render
            ? _c("new-carousel-image-item", {
                attrs: {
                  "item-index": _vm.currentIndex,
                  item: _vm.selectItem,
                  "outer-box": false,
                  "no-lazy-load": !_vm.firstLazyLoad || _vm.noLazyLoad,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "selectedImgBox",
          attrs: { id: "selectedImgBox" + _vm.id },
        },
        [
          _c(
            "div",
            {
              staticClass: "selectedImgList",
              class: _vm.selectedImgListCls,
              style: _vm.selectedImgListStyle,
              attrs: { id: "selectedImgList" + _vm.id },
              on: { touchstart: _vm.touchstart, touchend: _vm.touchend },
            },
            _vm._l(_vm.cycleList, function (item, index) {
              return _c("div", {
                key: index,
                staticClass: "selectItem J_img_lazyload",
                class: index == _vm.currentIndex ? "selected" : "",
                style:
                  "background-image:url(" +
                  (_vm.noLazyLoad ? item.picPath : _vm.options.loadingPath) +
                  ")",
                attrs: { "src-original": item.picPath },
                on: {
                  click: function ($event) {
                    return _vm.changeImgIndex(index)
                  },
                },
              })
            }),
            0
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }