var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "proImgSwipe",
      attrs: { id: "productCatagoryPdPanle" + _vm.moduleId },
    },
    [_vm._m(0)]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "proDetailImgBox" }, [
      _c("div", { staticClass: "swipImgBox" }, [
        _c("div", {
          staticClass: "proDetailImg",
          attrs: { vwidth: "450", vheight: "250" },
        }),
        _vm._v(" "),
        _c("div", { staticStyle: { display: "none" } }, [
          _c("img", { attrs: { id: "J_acct_pic", src: "" } }),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }