import { MobiModule } from '../Module';
import { MODULE_STYLE } from '@/def/module';
import PhotoGroupView from './module/photo-group.vue';

class _PhotoGroup extends MobiModule {
    constructor(options = {}) {
        super(MODULE_STYLE.PHOTO_GROUP, options);
    }

    render() {
        return PhotoGroupView;
    }
}

export const PhotoGroup = new _PhotoGroup();
