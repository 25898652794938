var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "m_mobi_form_text_descrition_wrap" }, [
    _c("div", { staticClass: "headline", style: _vm.headlineStyle }, [
      _c("div", { domProps: { innerHTML: _vm._s(_vm.headlineText) } }),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "subHeadline", style: _vm.subHeadlineStyle }, [
      _c("div", { domProps: { innerHTML: _vm._s(_vm.sublineText) } }),
    ]),
    _vm._v(" "),
    _vm.manageMode && _vm.empty
      ? _c("div", { staticClass: "empty" })
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }