var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", {
    ref: "groupBanner",
    staticClass: "group_banner J_img_lazyload",
    style: _vm.groupBannerStyle,
    attrs: { "src-original": _vm.curGroupBanner.p },
    on: {
      click: _vm.groupBannerClick,
      mouseover: _vm.groupBannerMouseOver,
      mouseout: _vm.groupBannerMouseOut,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }