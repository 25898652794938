<template>
    <div
        v-if="index === 0"
        :id="'newsLine' + info.id"
        :topClassName="_topClassName"
        :topSwitch="_topSwitch"
        :newsId="info.id"
        :newsName="info.title"
        :class="bigItemClasses"
    >
        <div :id="'lineBody' + info.id" class="lineBody" :class="[isNewAddModuleClass, isOnlyTitle]">
            <div class="mixNewsStyleImgBox4">
                <a
                    v-drag-resize="_resizeOptions"
                    class="mixNewsStyleImgBoxWrap"
                    hidefocus="true"
                    :href="_newsUrl"
                    :target="_jumpTarget"
                    :onclick="onclickStr"
                >
                    <div class="headPicTopWrap">
                        <div
                            id="newsTitlePic"
                            :src-original="info.realPicId ? info.bigPicPath : ''"
                            :alt="altName"
                            :class="bigPicClasses"
                            :style="bigPicStyle"
                            class="picScaleClass J_img_lazyload"
                        ></div>
                        <top-component v-if="_topFlagClass != '' && isNewAddModule"></top-component>
                    </div>
                    <div class="mixNewsStyleTitleContainer4" :class="isTextInner">
                        <div v-if="!isNewAddModule" :class="_topFlagClass"></div>
                        <div
                            v-if="!isNewAddModule"
                            :class="[titleClasses, titleLine]"
                            class="news_title"
                            :style="news_title_style"
                        >
                            {{ info.title }}
                        </div>
                        <div
                            v-if="isNewAddModule && module.content.ts === 0"
                            :class="[titleClasses, titleLine]"
                            class="news_title"
                        >
                            {{ info.title }}
                        </div>
                    </div>
                    <div
                        v-if="titleBottom"
                        :class="[titleClasses, titleLine, descriptionLine]"
                        class="news_title textOuter"
                    >
                        {{ info.title }}
                    </div>
                </a>
            </div>
        </div>
        <div v-if="module.content.ts == 1" class="g_separator separatorLine"></div>
    </div>
    <div
        v-else
        :id="'newsLine' + info.id"
        :topClassName="_topClassName"
        :topSwitch="_topSwitch"
        :newsId="info.id"
        :newsName="info.title"
        :class="itemClasses"
    >
        <a
            class="lineBodyLink"
            :href="_newsUrl"
            hidefocus="true"
            :target="_jumpTarget"
            style="width: auto"
            :class="isOnlyTitle"
            :onclick="onclickStr"
        >
            <div :id="'lineBody' + info.id" class="lineBody lineBodyPic">
                <div v-if="_topFlagClass != '' && !isNewAddModule" class="topFlagTableCell">
                    <div :class="_topFlagClass"></div>
                </div>
                <div
                    v-if="module.content.tPosition === 1"
                    :alt="altName"
                    class="picTableCell picTableCellLeft J_img_wrap"
                    :style="picTextStyle"
                >
                    <div
                        :id="'newsImg' + info.id"
                        v-drag-resize="_resizeOptions"
                        :alt="altName"
                        :class="picTextClasses"
                        :src-original="info.realPicId ? info.pic160Path : ''"
                        :style="picItemStyle"
                        class="picRadiusClass picScaleClass J_img_lazyload"
                    ></div>
                </div>
                <div class="fontTableCell">
                    <div class="fontHiddenTable">
                        <div v-if="!isNewAddModule" class="twoLineHidden g_link g_news_title" :style="news_title_style">
                            {{ info.title }}
                        </div>
                        <div
                            v-if="isNewAddModule"
                            class="g_link g_news_title newTitle"
                            :style="news_title_style"
                            :class="titleLine"
                        >
                            <top-component v-if="_topFlagClass != '' && isNewAddModule"></top-component>{{ info.title }}
                        </div>
                        <div v-if="isNewAddModule && !module.content.des" class="felxTextItem">
                            <div
                                :style="news_abstract_style"
                                class="twoLine articleSummray g_text description g_news_abstract newDescriptionLine"
                                :class="[descriptionLine, isOnlyTitleAndDes]"
                            >
                                {{ info.summary }}
                            </div>
                            <div
                                v-if="isNewAddModule && showOtherInfo && textPositin == 2"
                                class="newsElementsPanel g_newsElementsPanel J_newsElementsPanel"
                                @click="otherInfoClick"
                            >
                                <div v-if="_showComment" class="ne_newsComment faisco-icons-comment">
                                    <span v-if="!isNewAddModule" class="newsCommentCount">{{ info.commentCount }}</span>
                                    <span v-else class="newsCommentCount g_newsInfo"
                                        >{{ info.commentCount }}{{ module.extInfo.commentText }}</span
                                    >
                                </div>
                                <div v-if="_showAuthor" class="ne_newsAuthor" :style="authorStyle">
                                    <span class="newsAuthorName g_newsInfo">{{ info.author }}</span>
                                </div>
                                <div
                                    v-if="(_showAuthor || _showComment) && (_showSort || _showDate)"
                                    class="ne_separatorLine g_ne_separatorLine"
                                ></div>
                                <div v-if="_showSort" class="ne_newsSort g_newsInfo">
                                    {{ info.groupName }}
                                </div>
                                <div v-if="_showDate" class="ne_newsTime g_newsInfo">
                                    {{ newsDate }}
                                </div>
                            </div>
                        </div>
                        <div
                            v-if="isNewAddModule && showOtherInfo && textPositin == 2 && module.content.des"
                            class="newsElementsPanel g_newsElementsPanel J_newsElementsPanel"
                            @click="otherInfoClick"
                        >
                            <div v-if="_showComment" class="ne_newsComment faisco-icons-comment">
                                <span v-if="!isNewAddModule" class="newsCommentCount">{{ info.commentCount }}</span>
                                <span v-else class="newsCommentCount g_newsInfo"
                                    >{{ info.commentCount }}{{ module.extInfo.commentText }}
                                </span>
                            </div>
                            <div v-if="_showAuthor" class="ne_newsAuthor" :style="authorStyle">
                                <span class="newsAuthorName g_newsInfo">{{ info.author }}</span>
                            </div>
                            <div
                                v-if="(_showAuthor || _showComment) && (_showSort || _showDate)"
                                class="ne_separatorLine g_ne_separatorLine"
                            ></div>
                            <div v-if="_showSort" class="ne_newsSort g_newsInfo">
                                {{ info.groupName }}
                            </div>
                            <div v-if="_showDate" class="ne_newsTime g_newsInfo">
                                {{ newsDate }}
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 与上面的一样，只是位置不一样 -->
                <div
                    v-if="module.content.tPosition === 2"
                    :alt="altName"
                    class="picTableCell picTableCellRight J_img_wrap"
                    :style="picTextStyle"
                >
                    <div
                        :id="'newsImg' + info.id"
                        v-drag-resize="_resizeOptions"
                        :alt="altName"
                        :class="picTextClasses"
                        :src-original="info.realPicId ? info.pic160Path : ''"
                        :style="picItemStyle"
                        class="picRadiusClass picScaleClass J_img_lazyload"
                    ></div>
                </div>
            </div>
        </a>
        <div
            v-if="(!isNewAddModule && showOtherInfo) || (isNewAddModule && showOtherInfo && textPositin == 1)"
            class="newsElementsPanel g_newsElementsPanel J_newsElementsPanel"
            @click.stop="otherInfoClick"
        >
            <div v-if="_showComment" class="ne_newsComment faisco-icons-comment">
                <span v-if="!isNewAddModule" class="newsCommentCount">{{ info.commentCount }}</span>
                <span v-else class="newsCommentCount g_newsInfo"
                    >{{ info.commentCount }}{{ module.extInfo.commentText }}
                </span>
            </div>
            <div v-if="_showAuthor" class="ne_newsAuthor" :style="authorStyle">
                <span class="newsAuthorName g_newsInfo">{{ info.author }}</span>
            </div>
            <div
                v-if="(_showAuthor || _showComment) && (_showSort || _showDate)"
                class="ne_separatorLine g_ne_separatorLine"
            ></div>
            <div v-if="_showSort" class="ne_newsSort g_newsInfo">
                {{ info.groupName }}
            </div>
            <div v-if="_showDate" class="ne_newsTime g_newsInfo">
                {{ newsDate }}
            </div>
        </div>
        <div class="g_separator separatorLine"></div>
    </div>
</template>

<script>
import { mapFlag } from '@/shared/flag';
import ListItem from '../../mixins/ListItem';
import TopComponent from '../topComponent/top-component.vue';

export default {
    name: 'BigPicTextListItem',
    components: { TopComponent },
    mixins: [ListItem],
    inject: ['module', 'options', 'altName', 'ndUrl'],
    props: {
        info: {
            type: Object,
            default: function () {
                return {};
            },
        },
        index: { type: Number, default: 0 },
    },
    computed: {
        ...mapFlag(
            {
                isNewAddModule: 0x4,
            },
            'module.flag'
        ),
        isNewAddModuleClass() {
            return this.isNewAddModule ? ' isNewAddModule' : '';
        },
        bigItemClasses() {
            return `${this._lineClass} mixNewsStyleLine newsLine J_picNum`;
        },
        indexPicHeight() {
            // 首图高度
            const picHeightSet = this.module.content.indexPicHeightParam || {};
            const indexPicHeightType = picHeightSet.indexPicHeightType;
            return indexPicHeightType == 2 ? picHeightSet.indexPicHeight / 20 : 160 / 20;
        },
        indexPicWidth() {
            // 首图宽度
            const picHeightSet = this.module.content.indexPicHeightParam || {};
            const indexPicHeightType = picHeightSet.indexPicHeightType;
            return indexPicHeightType == 2 ? picHeightSet.indexPicWidth / 20 : 330 / 20;
        },
        titleClasses() {
            return this.module.content.sl ? 'mixNewsStyleTitle1' : 'mixNewsStyleTitle2';
        },
        itemClasses() {
            const firstClass = this.index === 1 ? 'firstClass' : '';
            return `newsLine line icon-gline ${firstClass} ${this._lineClass} One ${this._topFlagLineClass}`;
        },
        bigPicClasses() {
            return this.info.realPicId === '' ? 'newsTitlePic icon-noFigureB' : 'newsTitlePic';
        },
        bigPicStyle() {
            let picStyle = {};
            picStyle.height = `${this.indexPicHeight}rem`;
            if (this.info.realPicId !== '') {
                picStyle.background = `url(${
                    this.noLazyLoad ? this.info.bigPicPath : this.options.loadingPath
                }) no-repeat center /cover`;
            }
            return picStyle;
        },
        divSize() {
            if (this.isNewAddModule) {
                let divWidth = 4;
                let divHeight = 3;
                const tSize = this.module.content.tSize;
                const tSizeType = tSize.picSizeType;
                if (tSizeType == 2) {
                    divWidth = tSize.picWidth / 20;
                    divHeight = tSize.picHeight / 20;
                }
                return { divWidth, divHeight };
            } else {
                let divWidth = 50;
                let divHeight = 50;
                const tSize = this.module.content.tSize;
                const tSizeType = tSize.picSizeType;
                if (tSizeType == 2) {
                    divWidth = tSize.picWidth || 50;
                    divHeight = tSize.picHeight || 50;
                }
                return { divWidth, divHeight };
            }
        },
        picTextStyle() {
            if (!this.isNewAddModule) {
                return {
                    width: `${this.divSize.divWidth}px`,
                    height: `${this.divSize.divHeight}px`,
                };
            } else {
                return {
                    width: `${this.divSize.divWidth}rem`,
                    height: `${this.divSize.divHeight}rem`,
                };
            }
        },
        picTextClasses() {
            return this.info.realPicId === '' ? 'newsLeftImg icon-noFigureS' : 'newsLeftImg';
        },
        picItemStyle() {
            let picStyle = {};
            if (!this.isNewAddModule) {
                picStyle.width = `${this.divSize.divWidth}px`;
                picStyle.height = `${this.divSize.divHeight}px`;
            } else {
                picStyle.width = `${this.divSize.divWidth}rem`;
                picStyle.height = `${this.divSize.divHeight}rem`;
            }

            if (this.info.realPicId !== '') {
                picStyle.background = `url(${
                    this.noLazyLoad ? this.info.pic160Path : this.options.loadingPath
                }) no-repeat center /cover`;
            }
            return picStyle;
        },
        showOtherInfo() {
            return this._showDate || this._showSort || this._showAuthor || this._showComment;
        },
        authorStyle() {
            return !this._showComment ? 'margin-left:0rem;' : '';
        },
        newsDate() {
            return this._dateFormate(new Date(this.info.date), 'yyyy/MM/dd');
        },
        descriptionLine() {
            const descriptionLine = this.module.content.dsl;
            const textPosClass = this.module.content.tp == 2 ? 'newsTextPosition2' : '';
            var desClass = '';
            switch (descriptionLine) {
                case 0:
                    desClass = 'defaultDescriptionLine';
                    break;
                case 1:
                    desClass = 'descriptionLine';
                    break;
                case 2:
                    desClass = 'noDescriptionLine';
                    break;
            }
            return `${textPosClass} ${desClass}`;
        },
        titleLine() {
            const descriptionLine = this.module.content.tsl;
            var desClass = '';
            switch (descriptionLine) {
                case 0:
                    desClass = 'defaultDescriptionLine';
                    break;
                case 1:
                    desClass = 'descriptionLine';
                    break;
                case 2:
                    desClass = 'noDescriptionLine';
                    break;
            }
            return ` ${desClass}`;
        },
        titleTop() {
            if (this.isNewAddModule && this.module.content.ts === 1 && this.module.content.ptp === 1) {
                return true;
            } else {
                return false;
            }
        },
        titleBottom() {
            return this.isNewAddModule && this.module.content.ts === 1 ? true : false;
        },
        textPositin() {
            return this.module.content.tp;
        },
        isTextInner() {
            return this.isNewAddModule && this.module.content.ts === 0 ? 'textInner' : '';
        },
    },
    watch: {
        module: {
            handler: function () {
                this.noLazyLoad = true;
            },
            deep: true,
        },
    },
};
</script>

<style>
.news_list_wrap .big_pic_text_list .mixNewsStyleLine .mixNewsStyleImgBox4 {
    padding-top: 0.74rem;
}
.news_list_wrap .big_pic_text_list .mixNewsStyleLine .mixNewsStyleImgBox4 .news_title {
    font-size: 0.7rem;
}
.news_list_wrap .big_pic_text_list .mixNewsStyleLine .mixNewsStyleImgBox4 .mixNewsStyleTitleContainer4 {
    background: rgba(0, 0, 0, 0.5);
}
.isNewAddModule.news_list_wrap .big_pic_text_list .mixNewsStyleLine .mixNewsStyleImgBox4 {
    padding: 0;
    padding-top: 0.74rem;
}
.isNewAddModule.news_list_wrap .big_pic_text_list .mixNewsStyleLine .mixNewsStyleImgBox4 .news_title {
    padding: 0 0.5rem;
    font-size: 0.7rem;
    max-height: none;
    white-space: nowrap;
}
</style>
