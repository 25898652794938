var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: _vm.itemClasses,
      attrs: {
        id: "newsLine" + _vm.info.id,
        topClassName: _vm._topClassName,
        topSwitch: _vm._topSwitch,
        newsId: _vm.info.id,
        newsName: _vm.info.title,
      },
    },
    [
      _c(
        "a",
        {
          staticClass: "lineBodyLink",
          class: [_vm.isNewAddModuleClass, _vm.isOnlyTitle],
          staticStyle: { width: "auto" },
          attrs: {
            href: _vm._newsUrl,
            hidefocus: "true",
            target: _vm._jumpTarget,
            onclick: _vm.onclickStr,
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "lineBody lineBodyPic",
              attrs: { id: "lineBody" + _vm.info.id },
            },
            [
              _vm._topFlagClass != ""
                ? _c("div", { staticClass: "topFlagTableCell" }, [
                    _c("div", { class: _vm._topFlagClass }),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.module.content.tPosition === 1
                ? _c(
                    "div",
                    {
                      staticClass: "picTableCell",
                      style: _vm.picTextStyle,
                      attrs: { alt: _vm.altName },
                    },
                    [
                      _c("div", {
                        staticClass:
                          "picRadiusClass picScaleClass J_img_lazyload",
                        class: _vm.picTextClasses,
                        style: _vm.picItemStyle,
                        attrs: {
                          id: "newsImg" + _vm.info.id,
                          alt: _vm.altName,
                          "src-original": _vm.info.realPicId
                            ? _vm.info.pic160Path
                            : "",
                        },
                      }),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "fontTableCell" }, [
                _c("div", { staticClass: "fontHiddenTable" }, [
                  _c(
                    "div",
                    { staticClass: "twoLineHidden g_link newTitle" },
                    [
                      _vm._topFlagClass != "" && _vm.isNewAddModule
                        ? _c("top-component")
                        : _vm._e(),
                      _vm._v(_vm._s(_vm.info.title) + "\n                    "),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _vm.isNewAddModule && !_vm.module.content.des
                  ? _c("div", { staticClass: "felxTextItem" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "twoLine articleSummray g_text description g_news_abstract newDescriptionLine newDescriptionLine",
                          class: [_vm.descriptionLine, _vm.isOnlyTitleAndDes],
                          style: _vm.news_abstract_style,
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.info.summary) +
                              "\n                    "
                          ),
                        ]
                      ),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _vm.module.content.tPosition === 2
                ? _c(
                    "div",
                    {
                      staticClass: "picTableCell",
                      style: _vm.picTextStyle,
                      attrs: { alt: _vm.altName },
                    },
                    [
                      _c("div", {
                        staticClass:
                          "picRadiusClass picScaleClass J_img_lazyload",
                        class: _vm.picTextClasses,
                        style: _vm.picItemStyle,
                        attrs: {
                          id: "newsImg" + _vm.info.id,
                          alt: _vm.altName,
                          "src-original": _vm.info.realPicId
                            ? _vm.info.pic160Path
                            : "",
                        },
                      }),
                    ]
                  )
                : _vm._e(),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _vm.showOtherInfo
        ? _c(
            "div",
            {
              staticClass:
                "newsElementsPanel g_newsElementsPanel J_newsElementsPanel g_newsInfo",
              on: { click: _vm.otherInfoClick },
            },
            [
              _vm._showComment
                ? _c(
                    "div",
                    { staticClass: "ne_newsComment faisco-icons-comment" },
                    [
                      !_vm.isNewAddModule
                        ? _c("span", { staticClass: "newsCommentCount" }, [
                            _vm._v(_vm._s(_vm.info.commentCount)),
                          ])
                        : _c(
                            "span",
                            { staticClass: "newsCommentCount g_newsInfo" },
                            [
                              _vm._v(
                                _vm._s(_vm.info.commentCount) +
                                  _vm._s(_vm.module.extInfo.commentText)
                              ),
                            ]
                          ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm._showAuthor
                ? _c(
                    "div",
                    { staticClass: "ne_newsAuthor", style: _vm.authorStyle },
                    [
                      _c("span", { staticClass: "newsAuthorName g_newsInfo" }, [
                        _vm._v(_vm._s(_vm.info.author)),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              (_vm._showAuthor || _vm._showComment) &&
              (_vm._showSort || _vm._showDate)
                ? _c("div", {
                    staticClass: "ne_separatorLine g_ne_separatorLine",
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm._showSort
                ? _c("div", { staticClass: "ne_newsSort g_newsInfo" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.info.groupName) +
                        "\n        "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm._showDate
                ? _c("div", { staticClass: "ne_newsTime g_newsInfo" }, [
                    _vm._v(
                      "\n            " + _vm._s(_vm.newsDate) + "\n        "
                    ),
                  ])
                : _vm._e(),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "g_separator separatorLine" }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }