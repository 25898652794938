import { getPreviewObject } from '@/shared/getPreviewObject';

import { initScaleImage } from '@/shared/photo/scale';
export function initModulePhotoSwipe(moduleId, entrance) {
    var images;

    if (entrance === 'rich') {
        images = document.querySelectorAll('.' + moduleId + ' img');
    } else {
        images = document.querySelectorAll('#' + moduleId + ' img');
    }

    if (images.length < 1) return;

    var options = {
        imageScaleMethod: 'fitNoUpscale',
        getImageCaption(index, length) {
            var captionText, captionEl;

            captionText = index + '/' + length;

            captionEl = document.createElement('div');
            captionEl.appendChild(document.createTextNode(captionText));
            return captionEl;
        },
        getToolbar() {
            return (
                '<div class="ps-toolbar-close"><div class="ps-toolbar-content"></div></div>' +
                '<div class="ps-toolbar-previous"><div class="ps-toolbar-content"></div></div>' +
                '<div class="ps-toolbar-next"><div class="ps-toolbar-content"></div></div>'
            );
        },
    };

    if (VITE_APP_MODE !== 'visitor') {
        options.target = jm('#g_body')[0];
    }

    var PhotoSwipe = window.Code.PhotoSwipe;
    if (entrance !== 'rich') {
        // 修复产品详情不能打开幻灯片bug
        options.triggerEventType = 'click';
    }
    // eslint-disable-next-line no-unused-vars
    var instance = PhotoSwipe.attach(images, options);
}

export const initPhotoSwipe = function (id, nameList, switchInterval, switchAnimate, scale) {
    var imgName = getPreviewObject('imgName' + id);
    jm(imgName).html(nameList[0]);
    var swipe = getPreviewObject('photoSwipe' + id);
    if (!swipe) return;

    if (scale == 1) {
        //拉伸
        initScaleImage(swipe);
    } else if (scale == 2) {
        //默认
        initDefScaleImage(swipe);
    } else {
        //等比例
        noScaleImage(swipe);
    } // 修复多图轮播宽度获取不正常的问题 2018.10.31

    jm('#photoSwipe' + id).swipehandle(swipe, {
        auto: switchInterval,
        speed: switchAnimate,
        continuous: true,
        bulletsClick: true,
        callback: function callback(pos) {
            // jm(imgName).html(nameList[pos]);
            var liLen = bullets.length;

            while (liLen--) {
                bullets[liLen].className = ' ';
            }

            if (!bullets[pos]) {
                bullets[pos - bullets.length].className = 'on';
                jm(imgName).html(nameList[pos - bullets.length]);
            } else {
                bullets[pos].className = 'on';
                jm(imgName).html(nameList[pos]);
            }
        },
    }); //初始化好轮播多图的宽度之后，要把隐藏节点显示回来

    jm('#photoSwipe' + id)
        .find('.imageDiv')
        .show();
    if (!swipe.querySelector('#bullets' + id)) return;
    var bullets = swipe.querySelector('#bullets' + id).getElementsByTagName('li');
};

const noScaleImage = function (photoSwipe) {
    if (photoSwipe) {
        var allImageDom = photoSwipe.querySelectorAll('img'),
            viewWidth = photoSwipe.clientWidth,
            scaleImg = 0;

        if (allImageDom) {
            for (var item = 0; item < allImageDom.length; item++) {
                var vWidth = parseInt(
                        allImageDom[item].getAttribute('vwidth') ? allImageDom[item].getAttribute('vwidth') : 1
                    ),
                    vHeight = parseInt(
                        allImageDom[item].getAttribute('vheight') ? allImageDom[item].getAttribute('vheight') : 1
                    ),
                    scale = vHeight / vWidth;
                allImageDom[item].style.width = viewWidth + 'px';
                allImageDom[item].style.height = Math.floor(viewWidth * scale) + 'px'; // allImageDom[item].style.height = "100%";

                if (scale > scaleImg) {
                    scaleImg = scale;
                }
            }

            if (viewWidth * scaleImg != 0) {
                photoSwipe.style.height = Math.floor(viewWidth * scaleImg) + 'px';
            } //产品详情页 依据图片高度

            if (photoSwipe.className == 'proImgSwipe') {
                var proDetailImg = photoSwipe.querySelector('.proDetailImg');

                if (proDetailImg) {
                    photoSwipe.style.height = proDetailImg.clientHeight + 'px';
                }
            }
        }
    }
};

const initDefScaleImage = function (photoSwipe) {
    // var photoSwipe = Mobi.getPreviewObject(swipeId);console.log(swipeId)
    if (photoSwipe) {
        var allImageDom = photoSwipe.querySelectorAll('img'),
            viewWidth = photoSwipe.clientWidth,
            scaleImg = 0;
        var imageMiddleSpan = photoSwipe.querySelectorAll('.imageMiddleSpan');

        if (allImageDom) {
            for (var item = 0; item < allImageDom.length; item++) {
                var vWidth = parseInt(
                        allImageDom[item].getAttribute('vwidth') ? allImageDom[item].getAttribute('vwidth') : 1
                    ),
                    vHeight = parseInt(
                        allImageDom[item].getAttribute('vheight') ? allImageDom[item].getAttribute('vheight') : 1
                    );
                var scaleInit = vWidth / vHeight,
                    scaleLast = viewWidth / 417;

                if (scaleLast <= scaleInit) {
                    jm(allImageDom[item]).css({
                        width: scaleInit * 417 + 'px',
                        height: '417px',
                        maxWidth: 'inherit',
                        marginLeft: (viewWidth - scaleInit * 417) / 2 + 'px',
                    });
                    jm(imageMiddleSpan[item]).css('display', 'none');
                } else {
                    jm(allImageDom[item]).css({
                        height: viewWidth / scaleInit + 'px',
                        width: viewWidth + 'px',
                        marginTop: (417 - viewWidth / scaleInit) / 2 + 'px',
                    });
                    jm(imageMiddleSpan[item]).css('display', 'none');
                }
            }

            if (viewWidth * scaleImg != 0) {
                photoSwipe.style.height = Math.floor(viewWidth * scaleImg) + 'px';
            }
        }
    }
};

// 把图片高设置为最大图片容器的高
export const setPrImageMaxHeight = function (moduleId, imgContainer, isPhotoCarousel) {
    var module = jm('#module' + moduleId);
    imgContainer = module.find(imgContainer);
    var img = imgContainer.find('img');
    var maxHeight = 0;
    var imgClassH = 0;
    img.each(function () {
        imgClassH = parseInt(jm(this).height());
        if (imgClassH > maxHeight) {
            maxHeight = imgClassH;
        }
    });
    if (maxHeight != 0) {
        if (isPhotoCarousel) {
            imgContainer.css('height', '100%');
        } else {
            imgContainer.css('height', maxHeight / window._htmlFontSize + 'rem');
        }
    }
};

export const initPhotoImageSwipe = function (swipeId, scale) {
    var photoSwipe = document.getElementById(swipeId);
    if (!photoSwipe) return;
    var imageNameDom = photoSwipe.querySelector('.imgName');

    if (scale == 1) {
        initScaleImage(photoSwipe); //图片缩放为拉伸时
    }

    if (scale == 2) {
        initDefScaleImage(photoSwipe);
    } // 修复多图轮播宽度获取不正常的问题

    jm('#' + swipeId).swipehandle(photoSwipe, {
        isOpenNextAndPrevious: true,
        callback: function callback(pos, itemDom) {
            var imageDom = itemDom.querySelector('img'),
                src = imageDom.getAttribute('lazysrc');

            if (!!src && !imageDom.src) {
                imageDom.src = src;

                imageDom.onload = function () {
                    //alert(src);
                };
            } // 切换某些图片，会报错（imageDom.alt是空的，暂时性修复）

            if (imageDom.alt && imageNameDom) {
                //解决换行的<br>等标签问题
                imageNameDom.innerHTML = imageDom.alt; //Fai.top.Fai.decodeHtml();//Fai.deXX访客态访问不到
            } else {
                //修复显示图片名称时图片名称置空显示乱码问题
                imageNameDom.innerHTML = '';
            } //点击next 触发懒加载

            setTimeout(function () {
                jm(imageDom).trigger('clickPic');
            }, 300); //translate 300ms 做完
        },
    });
};
