<template>
    <div id="formSubmitSuccessTipCustom" class="form_submit_success_tip_custom_content_wrap">
        <div class="form_submit_success_tip_custom_header webHeaderBg">
            <a class="g_close icon-gClose" @click="close"></a>
        </div>
        <div class="form_submit_success_tip_custom_content">
            <div class="set-line" style="overflow-x: auto" v-html="content"></div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        content: {
            type: String,
            default: ``,
        },
        close: {
            type: Function,
            default: () => ({}),
        },
    },
};
</script>
<style lang="scss">
.form_submit_success_tip_custom {
    &_content_wrap {
        position: fixed;
        z-index: 9999;
        width: 100%;
        height: 100%;
        background-color: #fff;
        top: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
        .icon-gClose {
            margin-left: 0.45rem;
            &:before {
                color: #3c414a;
                font-size: 0.78rem;
                content: '\b0294';
            }
        }
        .icon-gHome {
            &:before {
                content: '\b0313';
                color: #3c414a;
                font-size: 0.78rem;
            }
        }
    }

    &_header {
        height: 2.15rem;
        line-height: 2.15rem;
        box-sizing: border-box;
        background: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 0.01rem solid #eeeeee;
        margin: 0;
        padding: 0;
        position: relative;
    }
    .g_close {
    }
    &_content {
        &_title {
            font-size: 0.85rem;
            color: #333;
            padding-bottom: 1.2rem;
            font-weight: bold;
        }
        &::-webkit-scrollbar {
            display: none;
        }
        background-color: #fff;
        color: #666;
        font-size: 0.65rem;
        padding: 0 0.85rem;
        word-break: break-word;
        height: calc(100% - 2.15rem);
        overflow: auto;
    }
}

@media only screen and (min-width: 1280px) {
    .form_submit_success_tip_custom_content_wrap {
        max-width: 512px;
    }
}
</style>
