import { setSingle } from '@/api/photoGroup';
import { delProduct, editProduct, setProductTop } from '@/modules/shared/product/product';
import { isFontIcon } from '@/shared/fai';
import { fileUpload2 } from '@/shared/fileUpload';
import { encodeHtmlJs } from '@/shared/util';
//产品的编辑入口
export function initModuleProductListItemManage(options) {
    if (!options) {
        return;
    }
    if (!options.moduleId) {
        return;
    }

    $('#' + options.productParent)
        .find('.' + options.product)
        .each(function () {
            var that =
                options.productChild == 'mProductTextForm'
                    ? $(this)
                    : $(this)
                          .find('.' + options.productChild)
                          .eq(0);

            $(this)
                .mouseover(function () {
                    var productId = $(this).attr('productId'),
                        productName = $(this).attr('productName'),
                        topClassName = $(this).attr('topClassName'),
                        topSwitch = $(this).attr('topSwitch');

                    var productJson = [
                        {
                            operationText: '编辑产品',
                            className: 'productListEdit',
                            onClick: () => {
                                editProduct(options.frameSrcUrl, productId);
                            },
                        },
                        {
                            operationText: '删除产品',
                            className: 'productListClose',
                            onClick: () => {
                                delProduct(`${productId}`, `${encodeHtmlJs(productName)}`, '', 0);
                            },
                        },
                    ];

                    var topOperat = true;
                    if (options.productSelect) {
                        topOperat = false;
                    }
                    if (topOperat) {
                        productJson.push({
                            operationText: topSwitch == 'off' ? '取消置顶' : '设为置顶',
                            operationTitle: topSwitch == 'off' ? '当前产品已置顶，点击取消' : '设为置顶',
                            display: topSwitch == 'off' ? 'text' : '',
                            className: '' + topClassName + '',
                            onClick: () => {
                                setProductTop(productId, `${topSwitch}`, '');
                            },
                        });
                    }
                    options.extCls = 'productListEditLayer';
                    Vue.prototype.$layer.addEditLayer(that, productJson, 6, options);
                })
                .mouseleave(function () {
                    Vue.prototype.$layer.removeEditLayer(that);
                });
        });
}

export function decrease2(productId, suffix) {
    suffix = suffix || '';
    var num = parseInt(jm('#productMallOptionPanel' + productId + ' #productNum' + suffix).val());
    if (isNaN(num)) {
        num = 1;
    }
    if (num > 99999998) {
        num = 99999998;
    }
    if (num < 0) {
        num = 0;
    }
    if (num + 1 > 1) {
        jm('#productMallOptionPanel' + productId + ' #g_decrease' + suffix).removeClass('g_opacity50');
    }
    jm('#productMallOptionPanel' + productId + ' #productNum' + suffix).val(num + 1);
}

export function increase2(productId, suffix) {
    suffix = suffix || '';
    var num = parseInt(jm('#productMallOptionPanel' + productId + ' #productNum' + suffix).val());
    if (isNaN(num)) {
        num = 1;
    }
    if (num < 2) {
        num = 2;
    }

    if (num - 1 === 1) {
        jm('#productMallOptionPanel' + productId + ' #g_decrease' + suffix).addClass('g_opacity50');
    }

    jm('#productMallOptionPanel' + productId + ' #productNum' + suffix).val(num - 1);
}

export function productGroupBannerUpload(moduleId, index) {
    var module = window.$store.state.currentPageModuleIdMap[moduleId];
    let recommandSize;
    if (module.content.s == 3 || module.content.s == 5 || module.content.s == 7) {
        recommandSize = '510*180';
    } else if (module.content.s == 4 || module.content.s == 6) {
        recommandSize = '680*180';
    }

    var fileUpload2_settings1 = {
        title: '添加图片',
        recommandSize,
        type: ['jpg', 'jpeg', 'bmp', 'png', 'gif'],
        imgMode: 2,
        maxChoiceList: 1,
        netFileMode: false,
        openIcon: true,
        tabIndex: 1,
    };

    fileUpload2(false, fileUpload2_settings1, function (back) {
        if (!back) {
            return;
        }

        var banner = module.content.ap[index];
        var data = $.parseJSON(back).data;
        var fileId = data[0].fileId;
        var isIcon = isFontIcon(fileId);
        var filePath = data[0].filePath;
        var imgPath = isIcon ? '' : filePath;

        banner.p = imgPath;
        banner.pi = fileId;
        Vue.prototype.$designer.onContentChange(moduleId);
    });
}

export function productGroupItemUpload(moduleId, groupId) {
    var upload_setting = {
        title: '添加图片',
        maxSize: Fai.top._uploadImgSizeLimit ? Fai.top._uploadImgSizeLimit : 5,
        type: ['jpg', 'jpeg', 'bmp', 'png', 'gif'],
        imgMode: 2,
        maxChoiceList: 1,
        netFileMode: true,
        openIcon: true,
        tabIndex: 0,
        group: 'thumbImg',
    };

    var module = window.$store.state.currentPageModuleIdMap[moduleId];
    fileUpload2('', upload_setting, addIconCallback);

    function addIconCallback(result) {
        if (result) {
            var data = $.parseJSON(result).data;
            var filePath = data[0].filePath;
            var fileId = data[0].fileId;
            var fileWidth = data[0].fileWidth;
            var fileHeight = data[0].fileHeight;
            setShowIcon(groupId, fileId, filePath, fileWidth, fileHeight);
        }
    }

    function setShowIcon(groupId, fileId, filePath, fileWidth, fileHeight) {
        var datas = module.groupListOptions.initGroupIconDatas;
        var productList = JSON.parse(JSON.stringify(module.groupListOptions.productGroupList));

        setSingle({
            id: groupId,
            mIcon: fileId,
            isPg: true,
        })
            .then((data) => {
                if (data.success) {
                    for (var index in datas) {
                        if (Object.hasOwnProperty.call(datas, index)) {
                            var groupData = datas[index];
                            if (groupData['id'] == groupId) {
                                groupData['mIcon'] = fileId;
                                groupData['ip'] = filePath;
                            }
                        }
                    }

                    for (let index in productList) {
                        if (Object.hasOwnProperty.call(productList, index)) {
                            let groupData = productList[index];

                            if (groupData['id'] == groupId) {
                                groupData['mIcon'] = fileId;
                                groupData['iconPath'] = filePath;
                                if (groupData.fileInfo == null) {
                                    groupData.fileInfo = {};
                                }
                                var fileInfo = groupData['fileInfo'];
                                fileInfo.width = fileWidth;
                                fileInfo.height = fileHeight;
                            }
                        }
                    }
                    module.groupListOptions.productGroupList = productList;
                    Vue.prototype.$designer.onContentChange(moduleId);
                }
            })
            .catch((error) => {
                console.error(error);
                Fai.ing('服务繁忙，请稍候重试', false);
            });
    }
}
