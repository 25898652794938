var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "photoImageItem photoSwipeItem J_also_resize",
      attrs: { "data-picId": _vm.item.picId || _vm.item.pic },
    },
    [
      _vm.outerBox
        ? _c(
            "a",
            {
              staticClass: "photoImageItemContent",
              class: _vm.cardItemCls,
              style: _vm.cardItemContentStyle,
              attrs: {
                href: _vm.imgHref,
                rel: _vm.imgNofollow,
                hidefocus: "true",
                onclick: _vm.jumpOnclickStr,
              },
              on: { click: _vm.photoSlidesshow },
            },
            [
              _vm.textPos == 1 && !_vm.hideDescBox
                ? _c(
                    "div",
                    { staticClass: "imgDescBox", class: _vm.imgDescBoxCls },
                    [
                      _vm.itemNameShow
                        ? _c("div", {
                            staticClass: "imgName g_carouselPhoto_nameLink",
                            class: _vm.imgNameCls,
                            style: _vm.imgNameStyle,
                            domProps: { innerHTML: _vm._s(_vm.itemName) },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.itemDescShow
                        ? _c("div", {
                            staticClass: "imgDesc g_carouselPhoto_link",
                            class: _vm.imgDescCls,
                            style: _vm.imgDescStyle,
                            domProps: { innerHTML: _vm._s(_vm.itemDesc) },
                          })
                        : _vm._e(),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("div", {
                directives: [
                  {
                    name: "drag-resize",
                    rawName: "v-drag-resize",
                    value: _vm.resizeOptions,
                    expression: "resizeOptions",
                  },
                ],
                staticClass: "imgLink J_img_lazyload",
                class: _vm.imgCls,
                style: _vm.imgCardStyle,
                attrs: { "src-original": _vm.item.picPath },
              }),
              _vm._v(" "),
              _vm.textPos == 0 && !_vm.hideDescBox
                ? _c(
                    "div",
                    { staticClass: "imgDescBox", class: _vm.imgDescBoxCls },
                    [
                      _vm.itemNameShow
                        ? _c("div", {
                            staticClass: "imgName g_carouselPhoto_nameLink",
                            class: _vm.imgNameCls,
                            style: _vm.imgNameStyle,
                            domProps: { innerHTML: _vm._s(_vm.itemName) },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.itemDescShow
                        ? _c("div", {
                            staticClass: "imgDesc g_carouselPhoto_link",
                            class: _vm.imgDescCls,
                            style: _vm.imgDescStyle,
                            domProps: { innerHTML: _vm._s(_vm.itemDesc) },
                          })
                        : _vm._e(),
                    ]
                  )
                : _vm._e(),
            ]
          )
        : _c(
            "div",
            {
              staticClass: "photoImageItemContent",
              class: _vm.itemCls,
              style: _vm.itemContentStyle,
            },
            [
              _c(
                "a",
                {
                  directives: [
                    {
                      name: "drag-resize",
                      rawName: "v-drag-resize",
                      value: _vm.resizeOptions,
                      expression: "resizeOptions",
                    },
                  ],
                  staticClass: "imgLink J_img_lazyload",
                  class: _vm.imgCls,
                  style: _vm.imgStyle,
                  attrs: {
                    href: _vm.imgHref,
                    rel: _vm.imgNofollow,
                    "src-original": _vm.item.picPath,
                    hidefocus: "true",
                    onclick: _vm.jumpOnclickStr,
                  },
                  on: { click: _vm.photoSlidesshow },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "imgCover",
                      class: _vm.imgCoverCls,
                      style: _vm.imgCover,
                    },
                    [
                      _vm.itemNameShow || _vm.itemDescShow
                        ? _c(
                            "div",
                            {
                              staticClass: "imgDescBox",
                              class: _vm.imgDescBoxCls,
                            },
                            [
                              _vm.itemNameShow
                                ? _c("div", {
                                    staticClass:
                                      "imgName g_carouselPhoto_nameLink",
                                    style: _vm.imgNameStyle,
                                    domProps: {
                                      innerHTML: _vm._s(_vm.itemName),
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.itemDescShow
                                ? _c("div", {
                                    staticClass: "imgDesc g_carouselPhoto_link",
                                    style: _vm.imgDescStyle,
                                    domProps: {
                                      innerHTML: _vm._s(_vm.itemDesc),
                                    },
                                  })
                                : _vm._e(),
                            ]
                          )
                        : _vm._e(),
                    ]
                  ),
                ]
              ),
            ]
          ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }