var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "mProductTileForm cardWrap J_product_item",
      class: [
        _vm.newProductTileFormClass,
        _vm._contentStyle,
        _vm._wrapBorderRadiusClass,
      ],
      style: _vm._warpProRadiusStyle,
      attrs: {
        id: _vm.itemId,
        topClassName: _vm._topInfo.topName,
        topSwitch: _vm._topInfo.topSwitch,
        productId: _vm.info.id,
        productName: _vm.info.name,
        title: _vm._title2,
      },
    },
    [
      _c(
        "a",
        { attrs: { href: _vm.info.extInfo.href, onclick: _vm.onclickStr } },
        [
          _c(
            "div",
            {
              staticClass: "imgClass_160",
              style: _vm.img160Style,
              attrs: { id: _vm.imgWrapId },
            },
            [
              _c("span", { staticClass: "imageMiddle" }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "fk-productListImgWrap",
                  class: _vm.picScaleClass,
                },
                [
                  _vm.manageMode && _vm.info.top != 0 && !_vm._productSelect
                    ? _c("div", { staticClass: "f-productListTopFlag" }, [
                        _vm._v(" 置顶 "),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.info.extInfo.salePromotionOpen
                    ? _c("sale-flag", { attrs: { info: _vm.info } })
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.isImg
                    ? _c("div", {
                        directives: [
                          {
                            name: "drag-resize",
                            rawName: "v-drag-resize",
                            value: _vm._resizeOptions,
                            expression: "_resizeOptions",
                          },
                        ],
                        staticClass:
                          "style1Img photoListImg imgItem J_img_lazyload",
                        class: [_vm._imgScaleClass, _vm._imgBorderRadiusClass],
                        style: _vm.proPhotoStyle,
                        attrs: {
                          title: _vm.info.name,
                          "src-original": _vm._originImgSrc,
                        },
                      })
                    : _c("img", {
                        directives: [
                          {
                            name: "drag-resize",
                            rawName: "v-drag-resize",
                            value: _vm._resizeOptions,
                            expression: "_resizeOptions",
                          },
                          { name: "ban-copy", rawName: "v-ban-copy" },
                        ],
                        staticClass: "style1Img photoListImg J_img_lazyload",
                        class: _vm._imgBorderRadiusClass,
                        style: _vm.imgPhotoStyle,
                        attrs: {
                          alt: _vm.info.name,
                          "src-original": _vm._originImgSrc,
                          src: _vm.noLazyLoad
                            ? _vm._originImgSrc
                            : _vm.options.loadingPath,
                        },
                      }),
                  _vm._v(" "),
                  _vm._isShowQualityContentLabel
                    ? _c("quality-content-label", {
                        attrs: { text: _vm._knowPayLabelText },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _vm.module.content.showProName &&
          _vm.module.content.picSetting.picSizeSet == 1
            ? _c("product-name", { attrs: { info: _vm.info } })
            : _vm._e(),
          _vm._v(" "),
          !_vm.isNewAddModule &&
          _vm._showMallCart.mallCart2 &&
          _vm.module.content.picSetting.picSizeSet == 1
            ? _c("mall-cart", {
                attrs: { info: _vm.info, holder: _vm._showMallCartHolder },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("prop-panel", {
            attrs: {
              info: _vm.info,
              list: _vm._showPropList,
              "ext-info": _vm._extInfo,
              "show-mall-cart": _vm._showMallCart,
              "text-align-class": _vm.textAlignClass,
              "no-name-wrap": _vm.noNameWrap,
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }