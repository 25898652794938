var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", {
    ref: "dateContainer",
    staticClass: "m_mobi_form_date",
    class: _vm.classes,
    on: {
      click: function ($event) {
        $event.stopPropagation()
        return _vm.handlerClick.apply(null, arguments)
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }