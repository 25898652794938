var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "fk-productListText mProductList",
      class: _vm._listClass,
      attrs: { id: _vm._listId, mType: _vm.module.content.t },
    },
    [
      _vm._l(_vm.productList, function (info, index) {
        return [
          _c("text-item", {
            key: "p_" + info.id,
            attrs: { info: info, index: index, "no-lazy-load": _vm.noLazyLoad },
          }),
          _vm._v(" "),
          !_vm.isNewAddModule
            ? _c("div", {
                key: "p1_" + info.id,
                staticClass: "g_separator separatorLine f-productListSep",
              })
            : _vm._e(),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }