var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.isRecommendEmptyList
    ? _c("module-frame", { attrs: { "module-id": _vm.moduleId } }, [
        _c(
          "div",
          {
            staticClass: "product-list-wrap",
            class: [
              _vm.isNewAddModuleClass,
              _vm.colmumTypeClass,
              _vm.borderRadiusClass,
            ],
          },
          [
            (!_vm.choiceProductEmpty && !_vm.productEmpty) ||
            (!_vm.manageMode && !_vm.choiceProductEmpty)
              ? [
                  _vm.componentRender
                    ? _c(_vm.styleComponent(), {
                        tag: "component",
                        attrs: {
                          "product-list": _vm.productList,
                          "no-lazy-load": _vm.noLazyLoad,
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.module.content.ml || _vm.module.content.bk
                    ? [
                        !_vm.openThemeV3 && _vm.cartPanelRender
                          ? _c("cart-panel", {
                              attrs: {
                                "mall-shopping-options":
                                  _vm.module.extInfo.mallShoppingOptions,
                                "module-id": _vm.module.id,
                                "choice-currency-val":
                                  _vm.module.extInfo.choiceCurrencyVal,
                                "level-discount":
                                  _vm.module.extInfo.levelDiscount,
                                "mall-cart-count-text":
                                  _vm.module.extInfo.mallCartCount,
                              },
                            })
                          : _vm.openThemeV3 && _vm.cartPanelRender
                          ? _c("cart-panel-v3", {
                              attrs: {
                                "mall-shopping-options":
                                  _vm.module.extInfo.mallShoppingOptions,
                                "module-id": _vm.module.id,
                                "choice-currency-val":
                                  _vm.module.extInfo.choiceCurrencyVal,
                                "level-discount":
                                  _vm.module.extInfo.levelDiscount,
                                "mall-cart-count-text":
                                  _vm.module.extInfo.mallCartCount,
                              },
                            })
                          : _vm._e(),
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.showPagenation
                    ? _c("pagenation", {
                        attrs: {
                          "module-id": _vm.module.id,
                          "total-size": _vm.module.extInfo.totalSize,
                          "page-size": _vm.module.content.c,
                          pageno: _vm.pageno,
                          "open-theme-v3": _vm.openThemeV3,
                          "ajax-pagenation": true,
                        },
                        on: { "on-change": _vm.changePage },
                      })
                    : _vm._e(),
                ]
              : _vm.productEmpty && _vm.manageMode && _vm.choiceProductEmpty
              ? [
                  _c(
                    "div",
                    { staticClass: "empty_tips_panel" },
                    [
                      _c("div", { staticClass: "text" }, [
                        _vm._v("您还没添加任何产品，赶紧添加吧~"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "button-component",
                        {
                          attrs: { active: "" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.addNewProduct.apply(null, arguments)
                            },
                          },
                        },
                        [_vm._v("添加产品")]
                      ),
                    ],
                    1
                  ),
                ]
              : _vm.manageMode && _vm.choiceProductEmpty
              ? _c(
                  "div",
                  { staticClass: "empty_tips_panel" },
                  [
                    _c("div", { staticClass: "text" }, [
                      _vm._v("请从右侧面板中选择产品~"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "button-component",
                      {
                        attrs: { active: "" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.addNewProduct.apply(null, arguments)
                          },
                        },
                      },
                      [_vm._v("添加产品")]
                    ),
                    _vm._v(" "),
                    _c(
                      "button-component",
                      {
                        staticStyle: { "margin-left": "20px" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.openDesigner.apply(null, arguments)
                          },
                        },
                      },
                      [_vm._v("选择产品")]
                    ),
                  ],
                  1
                )
              : !_vm.manageMode && _vm.choiceProductEmpty
              ? [
                  _c("div", { style: _vm.noProductStyle }, [
                    _c("div", [_vm._v("没有选择产品")]),
                  ]),
                ]
              : _vm._e(),
          ],
          2
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }