<template>
    <module-frame :module-id="moduleId">
        <div
            v-if="isManageMode && isLoadAllScriptAndHasModuleManageAuth"
            class="simpleText manageSimpleText"
            :class="'J_simpleText_' + moduleId"
            @dblclick="openDesigner"
        >
            <div
                class="fk-editor simpleText fk-editor-break-word"
                :class="writingModeClass"
                :style="sizeStyle"
                :_autowidth="autoWidth"
                :_autoheight="autoHeight"
                v-html="preProcessContent"
            ></div>
        </div>
        <div
            v-else
            :class="'fk-editor simpleText fk-editor-break-word ' + writingModeClass + ' ' + simpleTextCursor"
            :style="sizeStyle"
            v-html="preProcessContent"
        ></div>
        <!-- 用于链接控件 -->
        <div v-if="isManageMode" style="display: none">
            <input :id="'simpleTextLinkInputVal-' + moduleId" maxlength="255" />
        </div>
    </module-frame>
</template>

<script>
import ModuleFrame from '@/modules/frame/index.vue';
import { mapGetters } from 'vuex';
import { initSimpleText, initSimpleTextContent } from '../method';

import { setHrefEventHasReqArgs } from '@/modules/comm';
export default {
    name: 'SimpleText',
    components: {
        ModuleFrame,
    },
    props: {
        moduleId: {
            type: Number,
            default: -1,
        },
    },
    data() {
        return {};
    },
    computed: {
        ...mapGetters(['getModuleById']),
        module() {
            return this.getModuleById(this.moduleId);
        },
        moduleContent() {
            return this.module.content || {};
        },
        pattern() {
            return this.module.pattern;
        },
        moduleWidth() {
            return this.pattern.w;
        },
        moduleHeight() {
            return this.pattern.h;
        },
        autoWidth() {
            return this.moduleWidth > 0 ? 0 : 1;
        },
        autoHeight() {
            return this.moduleHeight > 0 ? 0 : 1;
        },
        // 模块扩展数据
        extInfo() {
            return this.module.extInfo;
        },
        // 光标
        simpleTextCursor() {
            if (this.isManageMode && this.isLoadAllScriptAndNoModuleManageAuth) {
                return 'simpleTextCursor';
            } else {
                return '';
            }
        },
        preProcessContent() {
            return this.extInfo.preProcessContent;
        },
        initOptions() {
            return this.extInfo.initOptions;
        },
        writingMode() {
            return this.extInfo.writingMode;
        },
        writingModeClass() {
            if (this.writingMode === 1) {
                return 'fk-editor-vertical-rl';
            } else if (this.writingMode === 2) {
                return 'fk-editor-vertical-lr';
            }
            return '';
        },
        htmlFontSize() {
            return this.moduleInPack.htmlFontSize || this.extInfo.htmlFontSize;
        },
        sizeStyle() {
            let styleObj = {};
            if (this.writingMode === 1) {
                if (this.moduleHeight > 0) {
                    styleObj['height'] = this.pxToRem(this.moduleHeight);
                }

                styleObj['width'] = 'auto';
                styleObj['position'] = 'absolute';
            } else if (this.writingMode === 2) {
                if (this.moduleHeight > 0) {
                    styleObj['height'] = this.pxToRem(this.moduleHeight);
                }
                styleObj['width'] = 'auto';
                styleObj['position'] = 'absolute';
            } else {
                if (this.moduleWidth > 0) {
                    styleObj['width'] = this.pxToRem(this.moduleWidth);
                }
                styleObj['height'] = 'auto';
            }

            return styleObj;
        },
        hasModuleManageAuth() {
            return this.extInfo.hasModuleManageAuth;
        },
        onlyLoadVisitScript() {
            return this.extInfo.onlyLoadVisitScript;
        },
        // 有模块管理权限并且加载全部脚本
        isLoadAllScriptAndHasModuleManageAuth() {
            return this.hasModuleManageAuth && !this.onlyLoadVisitScript;
        },
        // 无模块管理权限并且加载全部脚本
        isLoadAllScriptAndNoModuleManageAuth() {
            return !this.hasModuleManageAuth && !this.onlyLoadVisitScript;
        },
        renderOptions() {
            return this.module.renderOptions || {};
        },
        moduleInPack() {
            return this.renderOptions.moduleInPack || {};
        },
        isManageMode() {
            return VITE_APP_MODE !== 'visitor';
        },
    },
    watch: {
        preProcessContent(val) {
            if (this.isManageMode) {
                this.module.content.text = val;
                let moduleAttrList = window.$store.state.manage.moduleAttr || {};
                let moduleAttr = moduleAttrList[`module${this.moduleId}`] || {};
                moduleAttr.contentChange = true;
            }
        },
    },
    mounted() {
        // 管理态特殊结构
        if (this.isManageMode && this.isLoadAllScriptAndHasModuleManageAuth) {
            initSimpleText(this.initOptions);
        }
        initSimpleTextContent(this.moduleId, this.writingMode, this.htmlFontSize, this.isManageMode);
        setHrefEventHasReqArgs($(`#module${this.moduleId}`));
        // 对编辑器和插入的HTML片段进行处理
        this.initJumpWxApp();
    },
    methods: {
        initJumpWxApp() {
            if (this.isManageMode) {
                // 选择了跳转小程序的，文本中的a标签不让跳转
                $(`#module${this.module.id}`)
                    .on('click.jumpWxApp', 'a', (e) => {
                        if ($(`#fkEditor-${this.moduleId}`).attr('contenteditable') === 'true') {
                            return;
                        }
                        if (this.moduleContent.jumpMode === 1) {
                            Fai.ing('请在微信端打开链接', true);
                            e.stopPropagation();
                            e.preventDefault();
                        }
                    })
                    .on('click.manageModeTips', () => {
                        // 防止双击进入编辑状态时弹出提示
                        if (this.dbClickTimer) {
                            window.clearTimeout(this.dbClickTimer);
                        }
                        this.dbClickTimer = setTimeout(() => {
                            this.dbClickTimer = void 0;
                            if ($(`#fkEditor-${this.moduleId}`).attr('contenteditable') === 'true') {
                                return;
                            }
                            if (this.moduleContent.jumpMode === 1) {
                                // Fai.top.Fai.ing('请在微信端打开链接', true);
                            }
                        }, 500);
                    });
            }
            if (this.moduleContent.jumpMode !== 1) {
                return;
            }
            Mobi.initJumpWxApp(this.moduleId, this.moduleContent.jumpWxAppData);
        },
        pxToRem(pxVal) {
            return `${pxVal / this.htmlFontSize}rem`;
        },
        openDesigner() {
            this.$designer.open({
                panelType: 'pattern simple-text',
                moduleId: this.module.id,
                styleId: 52,
            });
        },
    },
};
</script>

<style lang="scss">
.fk-editor {
    position: relative;
    left: 0px;
    top: 0px;
    width: 180px;
    height: 30px;
    margin: 0;
    word-wrap: break-word;
    word-break: break-all;
    clear: both;
    overflow: hidden;
    font-size: 0.7rem;
    color: #666;
    font-family: '微软雅黑';
    // pointer-events: none;
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-size: inherit;
        font-weight: inherit;
        padding: 0;
        margin: 0;
        display: inline-block;
    }
}

.fk-editor-break-word {
    word-break: normal;
}

.formStyle52.form {
    overflow: initial;
}
.formStyle52.form .f-handleHover .ui-resizable-icon3 {
    background: transparent url(/image/resizable/blueCircle.png?v=201711250607) no-repeat 0 0;
}
.formStyle52.form .ui-resizable-icon3 {
    background: none;
}

.form .fk-editor.fk-editor-vertical-rl {
    writing-mode: tb-rl;
    writing-mode: vertical-rl;
    height: 100%;
    word-break: break-all;
    width: auto;
    max-width: 1000px;
    min-width: 40px;
    min-height: 10px;
}

.form .fk-editor.fk-editor-vertical-lr {
    writing-mode: tb-lr;
    writing-mode: vertical-lr;
    height: 100%;
    word-break: break-all;
    width: auto;
    max-width: 1000px;
    min-width: 40px;
    min-height: 10px;
}
.webContainerBox .formStyle52.form,
.webContainerBox .formStyle52.form .formMiddle,
.jz_popupZone_wrap .formStyle52.form,
.jz_popupZone_wrap .formStyle52.form .formMiddle {
    background: none;
    border: none;
    margin: 0;
    padding: 0;
    box-shadow: none;
    border-radius: 0;
}
/* 因为子元素没有继承父元素u标签的text-decoration样式，导致检测text-decoration 样式的时候没有检测到，编辑文本的下划线不是选中状态 */
.formStyle52 .fkEditor-wrap u span {
    text-decoration: underline;
}
</style>
