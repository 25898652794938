import { MobiModule } from '../Module';
import { MODULE_STYLE } from '@/def/module';
import FileDownloadView from './module/file-download.vue';

class _FileDownload extends MobiModule {
    constructor(options = {}) {
        super(MODULE_STYLE.FILE_DOWNLOAD, options);
    }

    render() {
        return FileDownloadView;
    }
}

export const FileDownload = new _FileDownload();
