var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "u_custom_search_entry--multiLine_wrap" },
    [
      _c("textArea", {
        staticClass:
          "u_custom_search_entry--textArea u_custom_search_entry--text",
        attrs: { maxlength: _vm.maxlength },
        on: { input: _vm.inputItemText },
        model: {
          value: _vm.formItem.value,
          callback: function ($$v) {
            _vm.$set(_vm.formItem, "value", $$v)
          },
          expression: "formItem.value",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }