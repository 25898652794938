var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "imgBulletGroup", class: _vm.imgBulletGroupCls },
    [
      _vm.bulletGroupStyle == 1
        ? [
            _vm._l(_vm.bulletList, function (item, index) {
              return [
                _c(
                  "transition",
                  { key: index, attrs: { name: "bulletFade" } },
                  [
                    _vm.imgBulletShow(index)
                      ? _c(
                          "span",
                          {
                            staticClass: "imgBullet",
                            class: _vm.offsetNum(index),
                            on: {
                              click: function ($event) {
                                return _vm.clickHandler(index)
                              },
                            },
                          },
                          [
                            _c("span", {
                              staticClass: "imgBulletBall",
                              class:
                                index == _vm.currentIndex
                                  ? "active g_oldThemeBgColor g_main_bgColor_v3"
                                  : "",
                            }),
                          ]
                        )
                      : _vm._e(),
                  ]
                ),
              ]
            }),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.bulletGroupStyle == 2
        ? [
            _vm._l(_vm.bulletList, function (item, index) {
              return [
                _c(
                  "transition",
                  { key: index, attrs: { name: "bulletFade" } },
                  [
                    _vm.imgBulletShow(index)
                      ? _c(
                          "span",
                          {
                            staticClass: "imgBullet",
                            class: _vm.offsetNum(index),
                            on: {
                              click: function ($event) {
                                return _vm.clickHandler(index)
                              },
                            },
                          },
                          [
                            _c("span", {
                              staticClass: "imgBulletBall",
                              class:
                                index == _vm.currentIndex
                                  ? "active g_oldThemeBgColor g_main_bgColor_v3"
                                  : "",
                            }),
                          ]
                        )
                      : _vm._e(),
                  ]
                ),
              ]
            }),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.bulletGroupStyle == 3
        ? [
            _vm._l(_vm.bulletList, function (item, index) {
              return [
                _c(
                  "transition",
                  { key: index, attrs: { name: "bulletFade" } },
                  [
                    _vm.imgBulletShow(index)
                      ? _c(
                          "span",
                          {
                            staticClass: "imgBullet",
                            class: _vm.offsetNum(index),
                            on: {
                              click: function ($event) {
                                return _vm.clickHandler(index)
                              },
                            },
                          },
                          [
                            _c("span", {
                              staticClass: "imgBulletBall",
                              class:
                                index == _vm.currentIndex
                                  ? "active g_oldThemeBgColor g_main_bgColor_v3"
                                  : "",
                            }),
                          ]
                        )
                      : _vm._e(),
                  ]
                ),
              ]
            }),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.bulletGroupStyle == 4
        ? [
            _vm._l(_vm.bulletList, function (item, index) {
              return [
                _c(
                  "transition",
                  { key: index, attrs: { name: "bulletFade" } },
                  [
                    _vm.imgBulletShow(index)
                      ? _c(
                          "span",
                          {
                            staticClass: "imgBullet",
                            class: _vm.offsetNum(index),
                            on: {
                              click: function ($event) {
                                return _vm.clickHandler(index)
                              },
                            },
                          },
                          [
                            _c("span", {
                              staticClass: "imgBulletBall",
                              class:
                                index == _vm.currentIndex
                                  ? "active g_oldThemeBgColor g_main_bgColor_v3"
                                  : "",
                            }),
                          ]
                        )
                      : _vm._e(),
                  ]
                ),
              ]
            }),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.bulletGroupStyle == 5
        ? [
            _c("div", { staticClass: "bulletBox" }, [
              _c("div", { staticClass: "bulletText" }, [
                _vm._v(_vm._s(_vm.currentIndex + 1)),
                _c("span", { staticClass: "bulletTextSep" }, [_vm._v("/")]),
                _vm._v(_vm._s(_vm.bulletLength)),
              ]),
            ]),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }