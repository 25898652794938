var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "lineHeightStyle",
      class: _vm.getItemClasses,
      style: _vm.getColumnStyle,
      attrs: {
        id: "newsLine" + _vm.info.id,
        topClassName: _vm._topClassName,
        topSwitch: _vm._topSwitch,
        newsId: _vm.info.id,
        newsName: _vm.info.title,
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "lineBody lineBodyTable",
          class: [
            _vm.isNewAddModuleClass,
            _vm.isOnlyTitle,
            _vm.lineHeightStyle,
          ],
          attrs: { id: "lineBody" + _vm.info.id },
        },
        [
          _vm._topFlagClass != "" && !_vm.isNewAddModule
            ? _c("div", { staticClass: "wholeLineTFTableCell" }, [
                _c("div", { class: _vm._topFlagClass }),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "linkTableCell" }, [
            _c("div", { staticClass: "linkTable" }, [
              _vm.isNewAddModule
                ? _c("div", [
                    _c("div", { staticClass: "tableTitle" }, [
                      _c(
                        "a",
                        {
                          staticClass:
                            "J_linkH g_link g_news_title news_title newTitle",
                          class: [_vm.titleLine, _vm.noDes],
                          style: _vm.news_title_style,
                          attrs: {
                            href: _vm._newsUrl,
                            hidefocus: "true",
                            target: _vm._jumpTarget,
                            onclick: _vm.onclickStr,
                          },
                        },
                        [
                          _vm._topFlagClass != "" && _vm.isNewAddModule
                            ? _c("top-component")
                            : _vm._e(),
                          _vm._v(_vm._s(_vm.info.title)),
                        ],
                        1
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              !_vm.isNewAddModule
                ? _c("div", { staticClass: "tableTitle" }, [
                    _c(
                      "a",
                      {
                        staticClass:
                          "J_linkH g_link g_news_title news_title newTitle",
                        class: [_vm.titleLine, _vm.noDes],
                        style: _vm.news_title_style,
                        attrs: {
                          href: _vm._newsUrl,
                          hidefocus: "true",
                          target: _vm._jumpTarget,
                          onclick: _vm.onclickStr,
                        },
                      },
                      [
                        _vm._topFlagClass != "" && _vm.isNewAddModule
                          ? _c("top-component")
                          : _vm._e(),
                        _vm._v(_vm._s(_vm.info.title)),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.isNewAddModule && _vm.showDes
                ? _c("div", [
                    _c(
                      "div",
                      {
                        staticClass:
                          "g_news_abstract g_text newDescriptionLine",
                        class: [_vm.descriptionLine, _vm.isOnlyTitleAndDes],
                        style: _vm.news_abstract_style,
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.info.summary) +
                            "\n                    "
                        ),
                      ]
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.isNewAddModule && _vm.showOtherInfo
                ? _c(
                    "a",
                    {
                      staticClass:
                        "newsElementsPanel g_newsElementsPanel J_newsElementsPanel newTextInfo",
                      class: _vm.hasDes,
                      attrs: { href: _vm._newsUrl, onclick: _vm.onclickStr },
                    },
                    [
                      _vm._showComment
                        ? _c(
                            "div",
                            { staticClass: "ne_newsComment g_newsInfo" },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(_vm.info.commentCount) +
                                  _vm._s(_vm.module.extInfo.commentText) +
                                  "\n                    "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm._showAuthor && _vm.info.author != ""
                        ? _c(
                            "div",
                            { staticClass: "ne_newsAuthor g_newsInfo" },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(_vm.info.author) +
                                  "\n                    "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm._showSort
                        ? _c("div", { staticClass: "ne_newsSort g_newsInfo" }, [
                            _vm._v(
                              "\n                        " +
                                _vm._s(_vm.info.groupName) +
                                "\n                    "
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm._showDate
                        ? _c("div", { staticClass: "ne_newsTime g_newsInfo" }, [
                            _vm._v(
                              "\n                        " +
                                _vm._s(_vm.newsDate) +
                                "\n                    "
                            ),
                          ])
                        : _vm._e(),
                    ]
                  )
                : _vm._e(),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      !_vm.isNewAddModule && _vm.showOtherInfo
        ? _c(
            "a",
            {
              staticClass:
                "newsElementsPanel g_newsElementsPanel J_newsElementsPanel",
              staticStyle: { display: "block" },
              attrs: { href: _vm._newsUrl, onclick: _vm.onclickStr },
            },
            [
              _vm._showComment
                ? _c(
                    "div",
                    { staticClass: "ne_newsComment faisco-icons-comment" },
                    [
                      !_vm.isNewAddModule
                        ? _c("span", { staticClass: "newsCommentCount" }, [
                            _vm._v(_vm._s(_vm.info.commentCount || 0)),
                          ])
                        : _c(
                            "span",
                            { staticClass: "newsCommentCount g_newsInfo" },
                            [
                              _vm._v(
                                _vm._s(_vm.info.commentCount) +
                                  _vm._s(_vm.module.extInfo.commentText)
                              ),
                            ]
                          ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm._showAuthor
                ? _c(
                    "div",
                    { staticClass: "ne_newsAuthor", style: _vm.authorStyle },
                    [
                      _c("span", { staticClass: "newsAuthorName g_newsInfo" }, [
                        _vm._v(_vm._s(_vm.info.author)),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              (_vm._showAuthor || _vm._showComment) &&
              (_vm._showSort || _vm._showDate)
                ? _c("div", {
                    staticClass: "ne_separatorLine g_ne_separatorLine",
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm._showSort
                ? _c("div", { staticClass: "ne_newsSort g_newsInfo" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.info.groupName) +
                        "\n        "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm._showDate
                ? _c("div", { staticClass: "ne_newsTime g_newsInfo" }, [
                    _vm._v(
                      "\n            " + _vm._s(_vm.newsDate) + "\n        "
                    ),
                  ])
                : _vm._e(),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }