var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "m_mobi_form_message_code",
      class: _vm.classes,
      style: _vm._mixinsInputStyle1,
    },
    [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.msgCode,
            expression: "msgCode",
          },
        ],
        staticClass: "m_mobi_form_input_text m_mobi_form_message_code_input",
        style: _vm._mixinsInputStyle2,
        attrs: { type: "number", placeholder: _vm.inputPlaceHolder },
        domProps: { value: _vm.msgCode },
        on: {
          input: [
            function ($event) {
              if ($event.target.composing) return
              _vm.msgCode = $event.target.value
            },
            _vm.handleMsgCodeChange,
          ],
        },
      }),
      _vm._v(" "),
      _c("div", {
        staticClass: "form_message_code_cn_warp",
        class: _vm.messageCodeColor,
        style: _vm.canClick,
        domProps: { textContent: _vm._s(_vm.showText) },
        on: {
          click: function ($event) {
            $event.preventDefault()
            return _vm.getMessageCode.apply(null, arguments)
          },
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }