var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "m_mobi_form_upload_wrap" },
    [
      !_vm.newFileUploadStyle
        ? [
            _c("div", { staticClass: "m_mobi_form_upload_container" }, [
              _c("input", {
                staticClass: "m_mobi_form_upload_input m_mobi_form_input_text",
                style: _vm._mixinsInputStyle,
                attrs: {
                  readonly: "readonly",
                  type: "text",
                  placeholder: _vm.module.extInfo.placeSelectFileUpload,
                },
                domProps: { value: _vm.file.tmpFileName },
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  ref: "uploadBtn",
                  staticClass: "m_mobi_form_upload_btn m_mobi_form_input_text",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.uploadClick.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "uploadify-button" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "uploadify_button_decorate" }, [
                    _vm._v(_vm._s(_vm.module.extInfo.clickToUpload)),
                  ]),
                ]
              ),
            ]),
            _vm._v(" "),
            !_vm.newFileUploadStyle && _vm.manageMode && _vm.fileNumLimit > 1
              ? _c("div", { staticClass: "auth_tips form_upload_tips" }, [
                  _c("span", [_vm._v("上传多个文件需")]),
                  _vm._v(" "),
                  _c("span", { on: { click: _vm.toNewFileUploadStyle } }, [
                    _vm._v("切换新样式"),
                  ]),
                ])
              : _vm._e(),
          ]
        : [
            _c("file-upload", {
              ref: "fileUpload",
              attrs: {
                "content-id": _vm.module.id,
                "form-item": _vm.formItem,
                "ext-info": _vm.extInfo,
                "log-event-config": _vm.logEventConfig,
                "initial-value": _vm.initialValue,
              },
              on: {
                "upload-success": _vm.uploadSuccess,
                "del-success": _vm.delSuccess,
              },
            }),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }