var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("module-frame", { attrs: { "module-id": _vm.moduleId } }, [
    _c(
      "div",
      { staticClass: "sortPanelList", attrs: { id: _vm.id } },
      [
        _vm._l(_vm.list, function (item, index) {
          return _c(
            "div",
            { key: index, staticClass: "line j-lineH J_lineH icon-gline" },
            [
              _c(
                "a",
                {
                  staticClass: "pgName",
                  attrs: { href: `${item.pgrUrl}`, target: "_self" },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "navLineTitle g_photoGroupNav_link",
                      style: _vm.photoGroupNav_link_style,
                    },
                    [_vm._v(_vm._s(item.name))]
                  ),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "g_separator separatorLine" }),
            ]
          )
        }),
        _vm._v(" "),
        !_vm.list.length && _vm.manageMode
          ? [
              _c(
                "div",
                { staticClass: "empty_tips_panel" },
                [
                  _c("div", { staticClass: "text" }, [
                    _vm._v("请在右侧面板添加分类"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "button-component",
                    {
                      attrs: { active: "" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.addPhotoGroupNav.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("添加分类")]
                  ),
                ],
                1
              ),
            ]
          : _vm._e(),
        _vm._v(" "),
        !_vm.list.length && !_vm.manageMode
          ? [
              _c("div", { staticClass: "cus_Empty_tips_panel" }, [
                _c("div", { staticClass: "text" }, [_vm._v("没有添加分类")]),
              ]),
            ]
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }