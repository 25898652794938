var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "photoModule newCarouselMultiPhoto styleForm10",
      class: _vm.photoSwipeCls,
    },
    [
      _c(
        "div",
        {
          staticClass: "photoSwipe",
          style: _vm.itemWidth,
          attrs: { id: "photoSwipe" + _vm.module.id },
        },
        [
          _c(
            "div",
            {
              ref: "photoSwipeBox",
              staticClass: "photoSwipeBox",
              style: _vm.photoSwipeStyle,
              attrs: { id: "photoSwipeBox" },
              on: {
                touchstart: _vm.touchstart,
                touchmove: _vm.touchmove,
                touchend: _vm.touchend,
              },
            },
            _vm._l(_vm.cycleList, function (item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "itemOuterBox",
                  class: _vm.itemPos(index),
                  style: _vm.itemOuterStyle(index),
                },
                [
                  _c("new-carousel-image-item", {
                    ref: "photoImageItem",
                    refInFor: true,
                    attrs: {
                      "item-index": index,
                      item: item,
                      "outer-box": _vm.content.td == 0,
                      "text-pos": _vm.content.itp,
                      "no-lazy-load": _vm.noLazyLoad,
                    },
                  }),
                ],
                1
              )
            }),
            0
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }