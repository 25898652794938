<template>
    <module-frame v-if="!isRecommendEmptyList" :module-id="moduleId">
        <div class="product-list-wrap" :class="[isNewAddModuleClass, colmumTypeClass, borderRadiusClass]">
            <template v-if="(!choiceProductEmpty && !productEmpty) || (!manageMode && !choiceProductEmpty)">
                <component
                    :is="styleComponent()"
                    v-if="componentRender"
                    :product-list="productList"
                    :no-lazy-load="noLazyLoad"
                ></component>
                <template v-if="module.content.ml || module.content.bk">
                    <cart-panel
                        v-if="!openThemeV3 && cartPanelRender"
                        :mall-shopping-options="module.extInfo.mallShoppingOptions"
                        :module-id="module.id"
                        :choice-currency-val="module.extInfo.choiceCurrencyVal"
                        :level-discount="module.extInfo.levelDiscount"
                        :mall-cart-count-text="module.extInfo.mallCartCount"
                    ></cart-panel>
                    <cart-panel-v3
                        v-else-if="openThemeV3 && cartPanelRender"
                        :mall-shopping-options="module.extInfo.mallShoppingOptions"
                        :module-id="module.id"
                        :choice-currency-val="module.extInfo.choiceCurrencyVal"
                        :level-discount="module.extInfo.levelDiscount"
                        :mall-cart-count-text="module.extInfo.mallCartCount"
                    ></cart-panel-v3>
                    <!-- 疑似无用节点，暂时去掉 -->
                    <!-- <img-swipe :module-id="module.id"></img-swipe> -->
                </template>
                <pagenation
                    v-if="showPagenation"
                    :module-id="module.id"
                    :total-size="module.extInfo.totalSize"
                    :page-size="module.content.c"
                    :pageno="pageno"
                    :open-theme-v3="openThemeV3"
                    :ajax-pagenation="true"
                    @on-change="changePage"
                >
                </pagenation>
            </template>
            <template v-else-if="productEmpty && manageMode && choiceProductEmpty">
                <div class="empty_tips_panel">
                    <div class="text">您还没添加任何产品，赶紧添加吧~</div>
                    <button-component active @click.stop="addNewProduct">添加产品</button-component>
                </div>
            </template>
            <div v-else-if="manageMode && choiceProductEmpty" class="empty_tips_panel">
                <div class="text">请从右侧面板中选择产品~</div>
                <button-component active @click.stop="addNewProduct">添加产品</button-component>
                <button-component style="margin-left: 20px" @click.stop="openDesigner">选择产品</button-component>
            </div>
            <template v-else-if="!manageMode && choiceProductEmpty">
                <div :style="noProductStyle">
                    <div>没有选择产品</div>
                </div>
            </template>
        </div>
    </module-frame>
</template>

<script>
import MutiColumn from './components/MutiColumn/muti-column.vue';
import PicTextList from './components/PicText/pic-text-list.vue';
import BigPicList from './components/BigPic/big-pic-list.vue';
import TwoColumnList from './components/TwoColumn/two-column-list.vue';
import WaterfallList from './components/Waterfall/waterfall-list.vue';
import SlideList from './components/Slide/slide-list.vue';
import TextList from './components/Text/text-list.vue';
import CartPanel from './components/comm/cart-panel.vue';
import CartPanelV3 from './components/comm/cart-panel-v3.vue';
import ImgSwipe from './components/comm/img-swipe.vue';
import Pagenation from '@/components/pagenation.vue';
import ModuleFrame from '@/modules/frame/index.vue';

import { mapFlag } from '@/shared/flag';

import { getSiteMultiLanguageUrl } from '@/shared/url';
import { bindJumpModuleEvent, removeJumpModuleInfo, loadImgAlone, getJumpModulePageCookie } from '../../comm';
import { bindImgLazyLoad } from '@/shared/imageLazyload';
import { initModulePhotoSwipe } from '@/modules/shared/photo';

import { hideParamPadding, removeMarginBottom, getProductListPageData } from '@/modules/shared/moduleProduct';
import {
    productCrossedSlide,
    waterFall,
    initProductSwipe1,
    productCrossedSlideSec,
    productCrossedSlideSecSwipe,
} from '@/modules/shared/product/renderStyle';
import { initModuleProductListItemManageV2, fixParamAreaHeight, addProductV2 } from '@/modules/shared/product/product';

import { isRecommendProductListModule } from '@/modules/type-mapping';

import { mapState } from 'vuex';

const manageMode = VITE_APP_MODE !== 'visitor';
export default {
    name: 'ProductList',
    components: {
        MutiColumn,
        PicTextList,
        BigPicList,
        TwoColumnList,
        WaterfallList,
        SlideList,
        TextList,
        Pagenation,
        CartPanel,
        CartPanelV3,
        ImgSwipe,
        ModuleFrame,
    },
    provide() {
        return {
            module: this.module,
            options: this.options,
        };
    },
    props: {
        moduleId: {
            type: Number,
            default: -1,
        },
    },
    data() {
        let currentPageModuleIdMap = this.$store.state.currentPageModuleIdMap;
        let module = currentPageModuleIdMap[this.moduleId];
        let options = module.renderOptions || {};

        return {
            componentRender: true, // 刷新整个模块，主要是切换样式时清掉样式，或者重新计算图片高度
            pageno: module?.extInfo?.pageno,
            cartPanelRender: false,
            successPages: [module?.extInfo?.pageno], // 分页后成功请求回来的page索引
            noLazyLoad: options.isFirstScreen,
            isCookieOnload: false, // 浏览器返回效果优化，由于产品展示分页数据比较特殊，特殊处理
            manageMode,
            module,
            options,
        };
    },
    computed: {
        ...mapFlag({
            isNewAddModule: 0x4,
        }),
        ...mapState(['product']),
        ...mapState('app', ['openThemeV3']),
        isNewAddModuleClass() {
            return this.isNewAddModule ? 'isNewAddModule' : '';
        },
        type: function () {
            return this.module.content.t;
        },
        colmumType: function () {
            return this.module.content.ct;
        },
        colmumTypeClass() {
            return ' colmum' + this.colmumType;
        },
        _isTransparentStyle() {
            return (
                (this.isNewAddModule && this.module.content.proStyle == 11) ||
                this.module.content.proStyle == 3 ||
                this.module.content.proStyle == 7
            );
        },
        borderRadiusClass() {
            return this._isTransparentStyle ? 'radiusInImg' : 'radiusInwrap';
        },
        _isBigCol: function () {
            if (this.isNewAddModule) {
                return this.type == '1' && this.colmumType == 1;
            } else {
                return this.module.content.t == '33';
            }
        },
        _isTwoCol: function () {
            if (this.isNewAddModule) {
                return this.type == '1' && this.colmumType == 2;
            } else {
                return this.module.content.t == '1';
            }
        },
        _isThreeCol: function () {
            if (this.isNewAddModule) {
                return this.type == '1' && this.colmumType == 3;
            } else {
                return this.module.content.t == '31';
            }
        },
        productList() {
            let list = [];
            if (!manageMode) {
                // 访客态
                list = this.module?.extInfo?.productList || [];
            } else {
                // 管理态
                let _productList = this.product.productList;
                let productList = [];
                this.module.extInfo.productIdList.forEach((id) => {
                    let info =
                        _productList.find((pInfo) => {
                            return pInfo.id == id;
                        }) || {};
                    if (info) productList.push(info);
                });
                list = productList;
            }
            // 分页
            const pageSize = this.module.content.c;
            const start = Math.max(pageSize * (this.pageno - 1), 0);
            const end = start + pageSize;
            list = list.slice(start, end);

            // 把空的去掉
            return list.filter((item) => {
                return !!item.addedTime;
            });
        },
        choiceProductEmpty() {
            const productList = this.productList;
            return productList.length < 1;
        },
        productEmpty() {
            let count = 0;
            if (manageMode) {
                count = this.product.allProductCount;
            }
            return count < 1;
        },
        proStyle() {
            return this.module.content.proStyle;
        },
        isCardStyle() {
            return (!this.isNewAddModule && this.proStyle == 5) || (this.isNewAddModule && this.proStyle == 11);
        },
        isBorderStyle() {
            return (!this.isNewAddModule && this.proStyle == 6) || (this.isNewAddModule && this.proStyle == 9);
        },
        productListWrapClasses() {
            let type = this.module.content.t;
            const proStyle = this.module.content.proStyle;
            var tmpType = this.module.content.t;
            if (this.isNewAddModule) {
                if (this._isBigCol) {
                    tmpType = '33';
                } else if (this._isThreeCol) {
                    tmpType = '31';
                }
            }
            let classes = ` fk-mProductList${tmpType} `;
            if (this._isTwoCol) {
                if (this.isCardStyle) {
                    classes += ' jz_cardStyle ';
                } else if (this.isBorderStyle) {
                    classes += ' jz_borderStyle ';
                } else if (proStyle == 7) {
                    classes += ' jz_mergeBorderStyle ';
                }
            }

            if (type != 7 && type != 8) {
                classes += ' fk-productListTitle ';
            }

            if (type == 8) {
                classes += ' styleForm8 ';
            } else if (this._isBigCol) {
                classes += ' styleForm33 ';
            }

            if (this._isTwoCol || this._isThreeCol) {
                classes += this.module.content.bk && this.module.extInfo.bookingOpen ? ' jz_bookingOpen ' : '';
            }

            return classes;
        },
        showPagenation() {
            const content = this.module.content;
            return content.pageIcon && content.t != '7' && content.t != '9' && !this._isBigCol;
        },
        productPrice: function () {
            return this.module.content.mhighs.pprice;
        },
        productMallPrice: function () {
            return this.module.content.mhighs.pmp;
        },
        newProductName: function () {
            return this.module.content.mhighs.npn;
        },
        newProductParam: function () {
            return this.module.content.mhighs.npp;
        },
        noProductStyle() {
            return {
                'text-align': 'center',
                'padding-top': '40px',
                'padding-bottom': '40px',
            };
        },
        isRecommendEmptyList() {
            if (isRecommendProductListModule(this.module.id)) {
                let { emptyRecommendList = false, extInfo = { totalSize: 0 } } = this.module;
                return emptyRecommendList === true || extInfo.totalSize == 0;
            } else {
                return false;
            }
        },
    },
    watch: {
        'module.content': {
            deep: true,
            handler: function () {
                if (manageMode) {
                    this.noLazyLoad = true;
                    if (this.module.content.mo.s) {
                        // 显示更多
                        if (this.module.content.moreLinkType == 0) {
                            // 默认链接
                            this.options.args.bannerMore = `pr.jsp?moduleId=${this.module.id}`;
                        } else {
                            if (this.module.content.jumpColId == 103 && this.module.content.jumpCusUrl != '') {
                                this.options.args.bannerMore =
                                    'javascript:top.Fai.ing("管理态不支持跳转外部链接，请通过“预览”进行访问", true);';
                            } else {
                                this.options.args.bannerMore = this.module.extInfo.href || 'javascript:;';
                                this.options.args.reqArgs = this.module.extInfo.reqArgs || '';
                            }
                        }
                    } else {
                        this.options.args.bannerMore = '';
                    }
                    if (this.isNewAddModule) {
                        this.styleComponent();
                        this.$nextTick(function () {
                            this.bindEvent();
                        });
                    }
                }
            },
        },
        'module.extInfo.totalSize': function () {
            // 管理态重新筛选了产品时，需要跳回第一页
            this.pageno = 1;
            this.successPages = [1];
        },
        'module.content.nameWrap': function () {
            if (this.module.content.t == '7') {
                const proSize = this.module.content.proSize;
                const wrap = this.module.content.nameWrap ? 'paramWrap' : 'paramNowrap';
                if (manageMode) {
                    productCrossedSlide(
                        `mProductList${this.module.id}`,
                        proSize.proWidth,
                        proSize.proHeight,
                        wrap,
                        this.isNewAddModule
                    ); //改变宽高比JS
                }
            }
        },
        'module.content.ct': function () {
            this.$nextTick(function () {
                this.bindEvent();
            });
        },
        'module.content.t': function () {
            this.$nextTick(function () {
                this.bindEvent();
            });
        },
        'module.content.ma.mt': function () {
            this.$nextTick(function () {
                this.bindManageEvent();
            });
        },
        'module.content.proStyle': function () {
            // 改变参数样式
            this.refreshModule();
        },
        'module.content.proSize': {
            deep: true,
            handler: function () {
                // 改变图片尺寸
                this.refreshModule();
            },
        },
        'module.extInfo.hook': function () {
            // 改变产品筛选条件
            this.refreshModule();
        },
        'module.content.commonSize': {
            deep: true,
            handler: function () {
                // 改变图片比例
                this.refreshModule();
            },
        },
        'module.content.endsSize': {
            deep: true,
            handler: function () {
                // 改变图片比例
                this.refreshModule();
            },
        },
        'module.extInfo.refreshModuleHook': function () {
            this.refreshModule();
        },
        'module.extInfo.productHook': function () {
            this.refreshModule(false);
        },
        'module.content.c': function () {
            this.pageno = 1;
            this.successPages = [1];
        },
    },
    mounted() {
        let isRecommendProductModule = isRecommendProductListModule(this.module.id);
        let recommendProductCookieOption = isRecommendProductModule
            ? {
                  isRecommendProductModule,
                  pid: this.module?.extInfo?.recommendFromPid,
              }
            : {};
        let cookiePage = getJumpModulePageCookie(this.module.id, recommendProductCookieOption) || 1;
        if (cookiePage != this.pageno && !manageMode) {
            // 场景：线上访客态页面缓存导致返回时数据是旧的，没走到inc那边，需重新获取数据
            this.changePage(parseInt(cookiePage));
        } else {
            // 放在nextTick里面是为了避免Fai.top._htmlFontSize还未被初始化。
            this.$nextTick(() => {
                this.bindEvent();
                removeJumpModuleInfo(this.module.id, recommendProductCookieOption);
                this.isCookieOnload = this.module.extInfo.pageno > 1;
            });
        }
        if (this.openThemeV3 && isRecommendProductListModule(this.module.id)) {
            if (jm('#productBottomBox').length > 0 && jm('#productBottomBox').css('display') != 'none') {
                let bottom = parseInt(jm('#productBottomBox .productMallShop').offset().height);
                if (bottom > 0) {
                    jm('#webModuleContainer').css('margin-bottom', bottom + 'px');
                    // productBottomBox高度之前是为了不被底部导航遮拦，在有推荐产品后应当去掉；内部节点是通过fixed方式
                    jm('#productBottomBox').height(0);
                }
            }
        }
    },
    created() {
        if (manageMode) {
            this.$store.commit('batchUpdateProductList', this.module.extInfo.productList);
        }
    },
    methods: {
        styleComponent() {
            var type = this.module.content.t;
            if (!this.isNewAddModule) {
                switch (type) {
                    case '1': //两列
                    case '31':
                        return 'MutiColumn'; //三列
                    case '3':
                        return 'PicTextList'; //左右图文
                    case '33':
                        return 'BigPicList'; //大图
                    case '7':
                        return 'TwoColumnList'; //横向两列
                    case '8':
                        return 'WaterfallList'; //瀑布流
                    case '9':
                        return 'SlideList'; //左右滑动
                    case '32':
                        return 'TextList'; // 纯文字
                }
            } else {
                var colomumType = this.module.content.ct;
                if (type == 1) {
                    if (colomumType == 1) {
                        type = '33';
                    } else if (colomumType == 3) {
                        type = '31';
                    }
                }
                switch (type) {
                    case '1': //两列
                    case '31':
                        return 'MutiColumn'; //三列
                    case '3':
                        return 'PicTextList'; //左右图文
                    case '33':
                        return 'BigPicList'; //大图
                    case '7':
                        return 'TwoColumnList'; //横向两列
                    case '8':
                        return 'WaterfallList'; //瀑布流
                    case '9':
                        return 'SlideList'; //左右滑动
                    case '32':
                        return 'TextList'; // 纯文字
                }
            }
        },
        async changePage(pageno) {
            Fai.top.productListPageno = pageno;
            // 如果当前页已经是请求成功过，则不请求了
            if (this.successPages.includes(pageno)) {
                this.pageno = pageno;
                this.$nextTick(() => {
                    this.bindEvent();
                });
                return;
            }

            let data = await getProductListPageData({
                module: this.module,
                moduleId: this.module.id,
                pageno: pageno,
                pid: this.module?.extInfo?.recommendFromPid,
            });

            let { productList } = data;
            const pageSize = this.module.content.c;
            if (manageMode) {
                this.$store.commit('batchUpdateProductList', productList);
            }

            // 找到对应的位置插入，没有请求过的页面，先用 -1 占位，确保 productIdList 的顺序是正确的。
            let start = Math.max(pageSize * (pageno - 1), 0);
            if (manageMode) {
                // 管理态
                if (pageno * pageSize > this.module.extInfo.productIdList.length) {
                    let addLength = pageno * pageSize - this.module.extInfo.productIdList.length;
                    for (let i = 0; i <= addLength; i++) {
                        this.module.extInfo.productIdList.push(-1);
                    }
                }
                productList.forEach((productInfo, index) => {
                    this.module.extInfo.productIdList.splice(start + index, 1, productInfo.id);
                });
            } else {
                // 访客态
                if (pageno * pageSize > this.module.extInfo.productList.length) {
                    let addLength = pageno * pageSize - this.module.extInfo.productList.length;
                    for (let i = 0; i <= addLength; i++) {
                        this.module.extInfo.productList.push({});
                    }
                }
                productList.forEach((productInfo, index) => {
                    this.module.extInfo.productList.splice(start + index, 1, productInfo);
                });
            }

            this.$nextTick(() => {
                this.bindEvent();
                this.successPages.push(pageno);
            });
            this.pageno = pageno;
        },
        bindEvent(imgLoad = true) {
            const type = this.module.content.t;
            if (this._isTwoCol || this._isThreeCol) {
                if ([5, 6, 7].includes(this.module.content.proStyle)) {
                    fixParamAreaHeight(this.module.id);
                }
            }

            if (type == '7') {
                const wrap = this.module.content.nameWrap ? 'paramWrap' : 'paramNowrap';
                let proSize = this.module.content.proSize;
                let proScaleWidth = this.module.content.proSize.proWidth;
                let proScaleHeight = this.module.content.proSize.proHeight;
                if (proSize.proSizeType == 1) {
                    proScaleWidth = 1;
                    proScaleHeight = 1;
                }

                hideParamPadding(`mProductList${this.module.id}`);
                removeMarginBottom(`mProductList${this.module.id}`, 'styleSeven');
                productCrossedSlide(
                    `mProductList${this.module.id}`,
                    proScaleWidth,
                    proScaleHeight,
                    wrap,
                    this.isNewAddModule
                ); //改变宽高比JS
                initProductSwipe1(`productSwipe${this.module.id}`, this.isNewAddModule); //左右滑动js,懒加载注意：refresh模块时要重新绑定再trigger
            } else if (type == '8') {
                const wrap = this.module.content.nameWrap ? 'paramWrap' : 'paramNowrap';

                //普通产品图默认宽高比3:4
                const commonSize = this.module.content.commonSize;
                const commSizeType = commonSize.commonSizeType;
                const comScaleWidth = commSizeType == 1 ? 3 : commonSize.commonWidth;
                const comScaleHeight = commSizeType == 1 ? 4 : commonSize.commonHeight;

                //首尾产品图默认宽高比1:1
                const endsSize = this.module.content.endsSize;
                const endsType = endsSize.endsSizeType;
                const endsScaleWidth = endsType == 1 ? 1 : endsSize.endsWidth;
                const endsScaleHeight = endsType == 1 ? 1 : endsSize.endsHeight;

                let productSalesPromotionInfo = {};
                this.productList.forEach((info) => {
                    productSalesPromotionInfo[info.id] = info.salePromotionParam;
                });

                hideParamPadding(`mProductList${this.module.id}`);
                removeMarginBottom(`mProductList${this.module.id}`, 'styleEight');
                //改变宽高比JS
                waterFall(
                    this.productList,
                    `mProductList${this.module.id}`,
                    comScaleWidth,
                    comScaleHeight,
                    endsScaleWidth,
                    endsScaleHeight,
                    wrap,
                    productSalesPromotionInfo,
                    this.isNewAddModule
                );
            } else if (type == '9') {
                //首尾产品图默认宽高比1:1
                const proSize = this.module.content.proSize;
                const proSizeType = proSize.proSizeType;
                let proScaleWidth = proSizeType == 1 ? 1 : proSize.proWidth;
                let proScaleHeight = proSizeType == 1 ? 1 : proSize.proHeight;

                const wrap = this.module.content.nameWrap ? 'paramWrap' : 'paramNowrap';
                hideParamPadding(`mProductList${this.module.id}`);
                removeMarginBottom(`mProductList${this.module.id}`, 'styleNine');
                //改变宽高比JS
                productCrossedSlideSec(
                    `mProductList${this.module.id}`,
                    proScaleWidth,
                    proScaleHeight,
                    wrap,
                    this.isNewAddModule
                );
                //左右滑动js
                productCrossedSlideSecSwipe(
                    `productSlideList${this.module.id}`,
                    `productSlide${this.module.id}`,
                    'productList',
                    this.isNewAddModule
                );
            }

            if (type != '32' && imgLoad) {
                loadImgAlone(this.module.id);
            }

            this.cartPanelRender = false;
            this.$nextTick(() => {
                this.cartPanelRender = true;
            });

            initModulePhotoSwipe(`productCatagoryPdPanle${this.module.id}`);
            this.bindManageEvent();
            if (!this.noLazyLoad || !manageMode) {
                if (!this.options.isFirstScreen) {
                    bindImgLazyLoad(`module${this.module.id}`);
                }
            }

            let context = {
                pageno: this.pageno,
            };
            if (isRecommendProductListModule(this.module.id)) {
                context.pid = this.module.extInfo.recommendFromPid;
                context.isRecommendProductModule = true;
            }
            bindJumpModuleEvent(this.module.id, this.module.style, context);
        },
        bindManageEvent: function () {
            if (manageMode && this.module.extInfo.productManageAuth) {
                let productSelect = isRecommendProductListModule(this.module.id)
                    ? this.module.extInfo.productSelect
                    : this.module.content.ma.mt != 1;
                const type = parseInt(this.module.content.t);
                let options = {
                    productParent: `mProductList${this.module.id}`,
                    product: 'productPicListForm',
                    productChild: 'tableBox',
                    moduleId: this.module.id,
                    productSelect,
                    frameSrcUrl:
                        getSiteMultiLanguageUrl() +
                        '/manage_v2/productEdit.jsp?isFromProductMouleV2=true&ram=' +
                        Math.random(),
                };

                if (this._isTwoCol || this._isThreeCol) {
                    options.product = 'mProductTileForm';
                    options.productChild = 'imgClass_160';
                } else if (this._isBigCol) {
                    options.product = 'jz_productBigPicListForm';
                    options.productChild = 'jz_productPho';
                } else if (type == 7) {
                    options.parent = `mProductList${this.module.id} .swipe .productSwipeContainer .productSwipeItem .productSwipeDiv`;
                    options.product = 'productCrossedSlide';
                    options.productChild = 'crossedSlideImg';
                } else if (type == 8) {
                    options.product = 'productWaterFall';
                    options.productChild = 'waterFallImg';
                } else if (type == 9) {
                    options.parent = `mProductList${this.module.id} .productSlide .productSlideList`;
                    options.product = 'productCrossedSlideSec';
                    options.productChild = 'CrossedSlideSecImg';
                } else if (type == 32) {
                    options.product = 'mProductTextForm';
                    options.productChild = 'mProductTextForm';
                }

                initModuleProductListItemManageV2(options);
                Vue.prototype.$layer.updateMoveFrame('module' + this.module.id);
                // this.refreshModule();
            }
        },
        refreshModule: function () {
            this.componentRender = false; // 主要是为了清除 JS 样式,或者重新计算图片的高度
            this.$nextTick(function () {
                this.componentRender = true;
                this.$nextTick(function () {
                    this.bindEvent();
                });
            });
        },
        openDesigner(event, noAnimation = false) {
            if (manageMode) {
                this.$designer.open({
                    panelType: 'pattern productList basis',
                    styleId: this.module.style,
                    moduleId: this.module.id,
                    noAnimation,
                });
            }
        },
        addNewProduct() {
            addProductV2(this.module.id);
        },
    },
};
</script>

<style lang="scss">
.formStyle3 .product-list-wrap .imageMiddle {
    display: none;
}
.fk-mProductList1 .imgClass_160,
.fk-mProductList31 .imgClass_160 {
    display: flex;
    align-items: flex-end;
}

.product-list-wrap .fk-mProductList33 .mProductTileForm {
    display: block;
    width: inherit;
}
.fk-mProductList9 .f-mallCart::before {
    vertical-align: middle;
}
.product-list-wrap .empty_tips_panel {
    text-align: center;
    padding-top: 40px;
    padding-bottom: 55px;
}
.product-list-wrap .empty_tips_panel .text {
    color: #666;
    font-size: 14px;
    margin-bottom: 20px;
}
.form .formMiddle .jz_borderStyle .mProductTileForm .style1Img,
.form .formMiddle .jz_cardStyle .mProductTileForm .style1Img {
    border-radius: 0;
}
.fk-mProductList7,
.fk-mProductList9 {
    padding-top: 0.45rem;
}
.fk-mProductList8 {
    margin-top: 0.45rem;
}
.fk-mProductList1 {
    padding-top: 0.2rem;
}
.fk-mProductList3 .proListBookingBtn,
.fk-mProductList3 .f-mallPriceWrap .proListBookingBtn,
.fk-mProductList7 .proListBookingBtn,
.fk-mProductList7 .f-mallPriceWrap .proListBookingBtn,
.fk-mProductList1 .proListBookingBtn,
.fk-mProductList1 .f-mallPriceWrap .proListBookingBtn {
    top: -0.2rem;
}
.product-list-wrap .mProductTileForm {
    overflow: hidden;
}

.form .formMiddle .isNewAddModule.radiusInImg .mProductList .photoListImg {
    border-radius: 0.2rem;
}
.form .formMiddle .isNewAddModule.radiusInwrap .mProductList .photoListImg {
    border-radius: 0;
}
.form .formMiddle .isNewAddModule.radiusInwrap .mProductList .imgBorderRadius {
    border-radius: 0.2rem;
}
.form .isNewAddModule .mProductList .g_productName {
    font-size: 0.6rem;
    font-family: PingFang SC;
    font-weight: bold;
    color: rgba(34, 34, 34, 1);
    word-break: break-word;
    margin-bottom: 0;
}
.isNewAddModule .mProductList.fk-mProductList33 .g_productName {
    font-size: 0.65rem;
}
.form .isNewAddModule .mProductList .productParam {
    font-size: 0.45rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: rgba(121, 121, 121, 1);
    line-height: normal;
    text-overflow: ellipsis;
    overflow: hidden;
    width: auto;
    flex-shrink: 0;
    height: auto;
    margin-top: 0.1rem;
    margin-bottom: 0.1rem;
    white-space: nowrap;
}
.jz_theme_1022 .form .isNewAddModule .mProductList .transparentStyle .productParam,
.jz_theme_1022 .form .isNewAddModule .mProductList .jz_productBigPicSimpleListForm .productParam,
.jz_theme_1022 .form .isNewAddModule .mProductList.fk-mProductList3 .productParam,
.jz_theme_1022 .form .isNewAddModule .mProductList.fk-mProductList32 .productParam {
    color: #ababab;
}
.jz_theme_1022 .form .isNewAddModule .mProductList .transparentStyle .paramName,
.jz_theme_1022 .form .isNewAddModule .mProductList .jz_productBigPicSimpleListForm .paramName,
.jz_theme_1022 .form .isNewAddModule .mProductList.fk-mProductList3 .paramName,
.jz_theme_1022 .form .isNewAddModule .mProductList.fk-mProductList32 .paramName {
    color: #fff;
}
.isNewAddModule.colmum3 .transparentStyle .paramCollection {
    padding-top: 0.35rem;
}
.isNewAddModule .mProductList .jz_productScr {
    padding-top: 0.575rem;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
}
.isNewAddModule .mProductList .jz_productBigPicListForm {
    margin-top: 0.75rem;
}
.form .isNewAddModule .mProductList .shadowStyle {
    box-shadow: 0px 0px 14px 2px rgba(0, 0, 0, 0.08);
    background-color: #fff;
}
.form .isNewAddModule .mProductList .borderStyle {
    border: 1px solid rgba(238, 238, 238, 1);
    background-color: #fff;
}
.form .isNewAddModule .mProductList .backgroundStyle {
    background: rgba(246, 246, 246, 1);
}
.form .isNewAddModule .mProductList .transparentStyle {
    background: transparent;
}

.isNewAddModule .mProductList .shadowStyle .needPaddingWrap,
.isNewAddModule .mProductList .backgroundStyle .needPaddingWrap,
.isNewAddModule .mProductList .borderStyle .needPaddingWrap,
.isNewAddModule .mProductList .shadowStyle .jz_productScr,
.isNewAddModule .mProductList .backgroundStyle .jz_productScr,
.isNewAddModule .mProductList .borderStyle .jz_productScr {
    padding-left: 0.4rem;
    padding-bottom: 0.5rem;
    padding-right: 0.4rem;
    padding-top: 0.35rem;
    box-sizing: border-box;
    width: 100%;
    flex-grow: 1;
}
.isNewAddModule .mProductList .shadowStyle .jz_productScr,
.isNewAddModule .mProductList .backgroundStyle .jz_productScr,
.isNewAddModule .mProductList .borderStyle .jz_productScr {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}
.isNewAddModule .mProductList .transparentStyle .needPaddingWrap {
    padding-left: 0;
}

.isNewAddModule .mProductList .shadowStyle .jz_productScr,
.isNewAddModule .mProductList .borderStyle .jz_productScr {
    background-color: #fff;
}
.isNewAddModule .mProductList .backgroundStyle .jz_productScr {
    background-color: rgba(246, 246, 246, 1);
}
/* 填充 */
.isNewAddModule .mProductList .J_img_lazyload.coverSize {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
/* 等比例 */
.isNewAddModule .mProductList .J_img_lazyload.containSize {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}
/* 拉伸 */
.isNewAddModule .mProductList .J_img_lazyload.stretchSize {
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
}
/* 不平铺 */
.isNewAddModule .mProductList .J_img_lazyload.unsetSize {
    background-size: unset;
    background-position: center;
    background-repeat: no-repeat;
}
.isNewAddModule .mProductList .jz_productBigPicListForm {
    margin: 0.75rem;
}
.isNewAddModule .mProductList .jz_productBigPicItem {
    width: 100%;
}

.isNewAddModule .mProductList .defaultProName {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
}
.isNewAddModule .mProductList .wrapProName {
    white-space: normal;
}
.isNewAddModule .mProductList .noWrapProName {
    word-break: break-all;
    white-space: nowrap;
}

.isNewAddModule .mProductList .separatorLine {
    border-top-width: 1px;
    border-top-style: solid;
    border-top-color: rgba(240, 240, 240, 1);
    height: 0px;
}

.isNewAddModule .jz_cardStyle .paramCollectionWrap {
    background: transparent;
}
/* 圆角、边框、阴影 时候 图片下圆角为0 */
.form .formMiddle .isNewAddModule .mProductList .shadowStyle .imgBorderRadius,
.form .formMiddle .isNewAddModule .mProductList .borderStyle .imgBorderRadius,
.form .formMiddle .isNewAddModule .mProductList .backgroundStyle .imgBorderRadius {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

/* 修复bug 1017429 */
.jz_oldTheme .fk-formFold .fk-mProductList31 .mProductTileForm,
.jz_newTheme .fk-formFold .fk-mProductList31 .mProductTileForm {
    width: 30%;
}
.jz_theme_1030 .fk-formFold .productCrossedSlide,
.jz_theme_1028 .fk-formFold .productCrossedSlide,
.jz_theme_1020 .fk-formFold .productCrossedSlide {
    width: 6.8rem;
}

.form .isNewAddModule .mProductList a {
    width: 100%;
    display: flex;
    flex-direction: column;
}
.isNewAddModule .mProductTileForm .paramCollection {
    margin-top: 0;
    margin: 0;
}
.isNewAddModule .mProductList .blankDiv {
    opacity: 0;
}
.isNewAddModule .mProductList .m_timedAddTag {
    display: inline-block;
    vertical-align: middle;
}
.webContainerBox .isNewAddModule .mProductList .bullets li:not(.on) {
    background: #d9d9d9;
}
.webContainerBox .isNewAddModule .mProductList .bullets li {
    width: 0.2rem;
    height: 0.2rem;
}
.webContainerBox .isNewAddModule .mProductList .bullets li.on {
    width: 0.7rem;
    height: 0.2rem;
    border-radius: 0.3rem;
    display: inline-block;
    cursor: pointer;
    transform-origin: 50% 50%;
    opacity: 1;
    background: #d9d9d9;
}
.form .formMiddle .isNewAddModule .jz_mergeBorderStyle .imgItem {
    width: 5.25rem;
    padding-top: 5.25rem;
    height: auto;
}

/*********************  产品展示两列样式 end ****************************/

.fk-productListImgWrap .f-productListTopFlag {
    z-index: 10;
    position: absolute;
    top: 0;
    right: 0;
    width: 36px;
    height: 20px;
    line-height: 20px;
    font-size: 12px;
    text-align: center;
    color: #fff;
    background-color: #fa3b00;
}
.f-productListTopPromotion {
    z-index: 10;
    position: absolute;
    top: 0;
    left: 0;
    width: auto;
    height: 20px;
    line-height: 20px;
    font-size: 12px;
    text-align: center;
    color: #fff;
    background-color: #fa3b00;
    padding-left: 5px;
    padding-right: 5px;
}

.productHotTextListTable div.f-productListTopFlag,
.productTextListTable div.f-productListTopFlag {
    display: inline-block;
    width: 36px;
    margin-right: 4px;
    line-height: 20px;
    text-align: center;
    color: #fff;
    background-color: #fa3b00;
    font-size: 12px;
}
.fk-productListImgWrap {
    display: inline-block;
    position: relative;
    vertical-align: top;
}
.fk-productListTitle .fk-productListImgWrap {
    vertical-align: bottom;
}
.productWaterFall .fk-productListImgWrap {
    display: block;
}

.productCrossedSlide {
    display: inline-block;
    margin-bottom: 0.5rem;
    width: 7.1rem;
    line-height: 0.9rem;
    border: 1px solid #ddd;
    border-radius: 0.2rem;
    overflow: hidden;
    background: #fff;
}
.style7Img {
    width: 7.1rem;
    height: 7.1rem;
}
.productCrossedSlide:nth-child(2n + 1) {
    float: left;
    margin-left: 0.5rem;
}
.productCrossedSlide:nth-child(2n) {
    float: right;
    margin-right: 0.5rem;
}
.productSwipeContainer {
    overflow: hidden;
    position: relative;
    height: 100%;
}

.productSwipeContainer .productSwipeItem .productSwipeDiv .paramName {
    color: #000;
    font-size: 0.6rem;
}

.productSwipeContainer .productSwipeItem .productSwipeDiv .paramPadding .productParam {
    color: #999;
}
.paramPadding .g_link.productParam.proParamNoWrap {
    color: #999;
}

.productWaterFall .paramPadding .paramName {
    color: #000;
    font-size: 0.6rem;
    margin-bottom: 0.1rem;
}

.productWaterFall .paramPadding .pramaWrap.productParam {
    color: #999;
}

.mProductList .paramPadding .proParamNoWrap {
    word-break: break-all;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 0.85rem;
}

.productSlideList .productCrossedSlideSec .paramPadding .paramName {
    color: #000;
    font-size: 0.6rem;
}

.productSlideList .productCrossedSlideSec .paramPadding .productParam {
    color: #999;
}

.webContainerBox .bannerBullet {
    position: absolute;
    height: 18px;
    bottom: 0px;
    width: 100%;
}

.webContainerBox .productBullet {
    height: 0.65rem;
    line-height: 0px;
}

.webContainerBox .bullets li.on {
    background: #000;
}

.productSwipeContainer img {
    display: inline-block;
    width: 100%;
    height: 100%;
    font-size: 0;
    line-height: 0;
    vertical-align: middle;
    background-size: 100%;
    background-position: 50% 50%;
    background-repeat: no-repeat;
}
.productSwipeContainer > div {
    float: left;
    width: 100%;
    position: relative;
    height: 100%;
    backface-visibility: hidden;
    perspective: 1000;
}

.paramPadding .mallPrice {
    font-weight: normal;
    font-size: 0.7rem;
}

.prContainer .prVipName {
    display: inline-block;
    border-radius: 0.1rem;
    font-size: 0.45rem;
    color: rgba(255, 255, 255, 1);
    text-align: center;
    padding: 0 0.25rem;
    margin-left: 0.25rem;
}

.paramPadding .productParam {
    color: #999;
    font-size: 0.6rem;
}

.paramPadding .paramLine {
    border-top: 1px #ddd dashed;
    margin: 0.2rem 0.1rem;
}

.productCrossedSlideSec,
.photoCrossedSlideSec {
    margin-bottom: 0.5rem;
    margin-left: 0.5rem;
    width: 5.95rem; /*这里每个块等宽是计算外层容器宽度的前提（用于产品展示、列表多图等左右滑动单列样式）20170628*/
    line-height: 0.9rem;
    border: 1px solid #ddd;
    border-radius: 0.2rem;
    overflow: hidden;
    float: left;
    background: #fff;
    margin-top: 1px;
}

.style9Img {
    width: 5.95rem;
    /*height:6.1rem;*/
}
.productSlideList,
.photoSlideList {
    overflow: hidden; /* 修复安卓机拖动不灵敏的问题 */
}
/*****************waterFall and cross slip. -----end***********************/
@media only screen and (min-width: 480px) {
    select {
        background-position: 98% 50%;
    }
}

/*************waterFall and cross slip. ----start********************/
.productWaterFall {
    margin-bottom: 0.5rem;
    width: 44.4%;
    line-height: 0.9rem;
    border: 1px solid #ddd;
    box-sizing: border-box;
    border-radius: 0.2rem;
    background: #fff;
}

.productWaterFall .waterFallImg {
    width: 100%;
    overflow: hidden;
    border-radius: 0.2rem 0.2rem 0 0;
    position: relative;
}

.formStyle3 .productWaterFall {
    display: inline-block;
}
.productWaterFall .g_text.paramName.proNameToNewStyle {
    color: #000;
}
.styleForm8 {
    position: relative;
}
.style8Img {
    width: 100%;
    /*width:7.1rem;*/
    /*height:7.1rem;*/
}
</style>
