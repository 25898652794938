import { MobiModule } from '../Module';
import { MODULE_STYLE } from '@/def/module';
import OnlineVideoView from './module/online-video.vue';

class _OnlineVideo extends MobiModule {
    constructor(options = {}) {
        super(MODULE_STYLE.ONLINE_VIDEO, options);
    }

    render() {
        return OnlineVideoView;
    }
}

export const OnlineVideo = new _OnlineVideo();
