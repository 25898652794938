var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticStyle: { display: "none" },
      attrs: { id: "productMallOptionPanel_mcs_" + _vm.moduleId },
    },
    [
      _c(
        "div",
        {
          staticClass: "productMallOptionPanel",
          attrs: { moduleId: _vm.moduleId },
        },
        [_vm._m(0)]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "productMallOptionContentPanel" }, [
      _c("div", { staticClass: "productMallOptionContent" }, [
        _c("div", {
          staticClass:
            "productDetailClose icon-Off g_iconMiddle icon-defaultColor",
          staticStyle: { cursor: "default", "z-index": "2" },
        }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }