import { MobiModule } from '../Module';
import { MODULE_STYLE } from '@/def/module';
import CalculationQuotationView from './module/calculationQuotation.vue';

class _CalculationQuotation extends MobiModule {
    constructor(options = {}) {
        super(MODULE_STYLE.CALCULATION_QUOTATION, options);
    }

    render() {
        return CalculationQuotationView;
    }
}

export const CalculationQuotation = new _CalculationQuotation();
