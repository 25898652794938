<template>
    <div
        v-if="(projectID != -1 && projectExist) || useManageMode"
        :id="'customSearchContainer' + module.id"
        :class="panelCls"
    >
        <template v-if="projectID == -1 || !projectExist">
            <div class="empty_tips_panel">
                <div class="text">请在右侧面板添加查询项目</div>
                <button-component active @click.stop="addSearch">添加查询</button-component>
            </div>
        </template>
        <template v-if="projectID != -1 && projectExist">
            <div class="m_custom_search">
                <div v-if="!hideTitle" class="m_custom_search_projectName">{{ project.name }}</div>
                <div v-if="!hideTips" class="m_custom_search_projectTips">{{ project.tip }}</div>
                <div class="m_custom_search_field">
                    <div v-for="item in itemListCondited" :key="item.id" class="m_custom_search_field_item">
                        <div class="m_custom_search_field_label"
                            ><span v-if="item.isRequired" class="m_custom_search_star">*</span
                            >{{ item.itemName.includes('：') ? item.itemName : item.itemName + ':' }}</div
                        >
                        <CustomSearchItem
                            :item="item"
                            :form-info="formInfo"
                            :module="module"
                            :func-type="funcType"
                            :mobile-ct-list="mobileCtList"
                            :select-place-holder="selectPlaceHolder('')"
                            :input-place-holder="inputPlaceHolder('')"
                            @on-input="onInput"
                        />
                    </div>
                </div>
                <div class="m_custom_search_submit_wrap">
                    <button
                        class="m_custom_search_submit g_main_bgColor_v3 g_oldThemeBgColor J_customSearchSubmit"
                        @click="onSubmit"
                        >{{ module.content.btnName }}</button
                    >
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import { showTopManageFrame } from '@/shared/frame';
import { ing } from '@/shared/tips';
import { logDog } from '@/api/logDog';
import { preprocessUrl } from '@/shared/url';
import { changeHref } from '@/features/router/universal';
import { getSearchProjectList } from '@/api/module/customSearch';
import { customSearchMixin } from '../../customSearchMixin.js';
import { mapState } from 'vuex';

/* eslint-disable no-duplicate-case */
export default {
    mixins: [customSearchMixin],
    data() {
        return {};
    },
    computed: {
        ...mapState('app', ['isOpenMResp']),
        isResponsiveIndependentMobi() {
            return this.isOpenMResp;
        },
        panelCls() {
            return this.projectID == -1 || !this.projectExist ? 'noProjecrtContainer' : '';
        },
        projectID() {
            return this.module.content.proId;
        },
        projectExist() {
            // 自适应开启独立mobi后，需要过滤掉表单类型的查询
            if (this.isOpenMResp && this.project?.funcType === 1) {
                return false;
            }
            return this.project.id != undefined;
        },
        projectOpen() {
            return this.checkBit(this.project.flag, 0x1);
        },
        hideTitle() {
            return this.module.content.htit;
        },
        hideTips() {
            return this.module.content.htips;
        },
    },
    watch: {
        projectID() {
            this.getProject();
        },
    },
    methods: {
        addSearch() {
            showTopManageFrame('manageFrameSearchProjectAdd', Fai.top.backUrl, Fai.top.lanCode);
            logDog(200873, 1);
        },
        inputPlaceHolder(name) {
            return this.openThemeV3
                ? this.lanConf.mobi_member_PleaseInput + name
                : this.lanConf.mobi_member_PleaseInput;
        },
        selectPlaceHolder(name) {
            return this.openThemeV3 ? this.lanConf.siteFormPleaseSelect + name : this.lanConf.siteFormPleaseSelect;
        },
        getProject() {
            const self = this;

            getSearchProjectList(this.projectID)
                .then(({ data: { data: project } }) => {
                    self.module.args.project = project;
                })
                .catch(function () {
                    ing(LS.js_systemError);
                });
        },

        async onSubmit() {
            if (!Fai.top._openMsgSearch) {
                //没有开通查询功能
                ing('功能不可用，如需继续使用请联系网站管理员');
                return;
            }
            if (
                this.funcType &&
                this.renderOptionsArgs?.formSearchVerAuth &&
                !this?.renderOptionsArgs?.formSearchVerAuth?.allow
            ) {
                if (this.useManageMode) {
                    if (this.oem) {
                        Fai.ing('表单查询为网站至尊版功能。');
                    } else {
                        Fai.ing(
                            "表单查询为网站皇冠版功能。<a target='_blank' style='color:red' href='" +
                                this.renderOptionsArgs?.formSearchVerAuth?.updateVerUrl +
                                "' >立即升级</a>"
                        );
                    }
                } else {
                    ing('功能不可用，如需继续使用请联系网站管理员');
                }
                return;
            }

            if (this.useManageMode) {
                let checkRes = false;
                try {
                    checkRes = await this.$designer.promiseCheckSaveBar();
                } catch (e) {
                    console.warn(e);
                }
                if (!checkRes) {
                    return;
                }
            }

            if (!this.projectOpen) {
                ing(LS.js_customSearchNoOpen);
                return;
            }
            let { canCommit } = this.getSubmitInfo();
            if (!canCommit) {
                return;
            }
            this.jumpToDetail();
            logDog(201006, 3);
        },
        getSubmitInfo() {
            var condition = [];
            var canCommit = true;
            this.itemListCondited.forEach((item) => {
                var type = item.type,
                    isRequired = item.isRequired,
                    propId = item.id,
                    value = item.value,
                    _value = '',
                    typeName = this.getSearchType(type);

                switch (typeName) {
                    case 'singleLine':
                    case 'phone':
                    case 'mail':
                    case 'identity':
                    case 'dropdown':
                    case 'radio':
                    case 'multiLine':
                    case 'address':
                    case 'date':
                    case 'phone':
                    case 'number':
                        _value = value;
                        if (value == '') {
                            if (isRequired) {
                                ing(LS.customSearchEmpty);
                                canCommit = false;
                            }
                        }
                        break;
                    case 'multipleChoice':
                        _value = value.join('\n');
                        if (value.length < 1) {
                            if (isRequired) {
                                ing(LS.customSearchEmpty);
                                canCommit = false;
                            }
                        }
                        break;
                    case 'multiDropdown': // 多级下拉
                        {
                            const {
                                firstLevel = {},
                                secondLevel = {},
                                thirdLevel = {},
                                fourthLevel = {},
                            } = value || {};
                            let result = '';
                            result = firstLevel.val || '';
                            if (result.length < 1) {
                                if (isRequired) {
                                    ing(LS.customSearchEmpty);
                                    canCommit = false;
                                }
                            }
                            !!secondLevel.val && (result += `-${secondLevel.val}`);
                            !!thirdLevel.val && (result += `-${thirdLevel.val}`);
                            !!fourthLevel.val && (result += `-${fourthLevel.val}`);
                            _value = result;
                        }
                        break;
                }

                if (_value && _value.length > 0) {
                    condition.push({
                        prop: 'prop' + propId,
                        type: type,
                        value: _value,
                    });
                }
            });
            if (this.funcType && !condition.length) {
                if (this.itemListCondited.length > 1) {
                    ing(LS.js_customSearch_condition, false);
                    canCommit = false;
                } else {
                    this.itemListCondited.length &&
                        ing(this.format(LS.siteFormSubmitInputIsEmpty, this.itemListCondited[0].name));
                    canCommit = false;
                }
            }
            return {
                condition,
                canCommit,
            };
        },
        jumpToDetail() {
            let { condition } = this.getSubmitInfo();

            var param = {};
            param.searchId = this.projectID;
            param.condition = condition;
            changeHref(
                `${preprocessUrl({ path: '', oldPath: '' })}csr.jsp?id=${param.searchId}&mId=${
                    this.id
                }&searchData=${encodeURIComponent(JSON.stringify(param))}`
            );
        },
        checkBit(flag, bitFlag) {
            var bit_31 = true;
            //31位皆置1为：2147483647
            if (flag > 2147483647 || flag < 0 || bitFlag > 2147483647 || bitFlag < 0) {
                bit_31 = false;
            }
            if (bit_31) {
                return (flag & bitFlag) == bitFlag;
            }
            var flagBinary = flag.toString(2);
            var bitFlagBinary = bitFlag.toString(2);
            if (flagBinary.length > 62 || bitFlagBinary.length > 62) {
                alert(
                    'Does not support more than 62 bit. flagBinary.length=' +
                        flagBinary.length +
                        ',bitFlagBinary.length' +
                        bitFlagBinary.length +
                        '.'
                );
                return false;
            }
            var flagLow;
            var bitFlagHight;
            var bitFlagLow;
            var flagHight;
            var lowStr;
            var hightStr;
            flagHight = flagLow = bitFlagHight = bitFlagLow = 0;
            //拆分高低位处理
            if (flagBinary.length > 31) {
                hightStr = flagBinary.slice(0, flagBinary.length - 31);
                lowStr = flagBinary.slice(flagBinary.length - 31);
                flagHight = parseInt(hightStr, '2');
                flagLow = parseInt(lowStr, '2');
            } else {
                flagLow = parseInt(flagBinary.slice(0, flagBinary.length), '2');
            }
            if (bitFlagBinary.length > 31) {
                hightStr = bitFlagBinary.slice(0, bitFlagBinary.length - 31);
                lowStr = bitFlagBinary.slice(bitFlagBinary.length - 31);
                bitFlagHight = parseInt(hightStr, '2');
                bitFlagLow = parseInt(lowStr, '2');
            } else {
                bitFlagLow = parseInt(bitFlagBinary.slice(0, bitFlagBinary.length), '2');
            }

            var result = (flagLow & bitFlagLow) == bitFlagLow;
            if (result) {
                result = (flagHight & bitFlagHight) == bitFlagHight;
            }
            return result;
        },
        changeValidator() {
            this.validatorImgSrc = `validateCode.jsp?random=${parseInt(Math.random() * 1000)}&vCodeId=${
                this.module.id
            }`;
        },
    },
};
</script>

<style lang="scss">
.m_custom_search .select_style .select_list_wrap {
    position: relative;
}
.m_custom_search .select_style .select_item {
    height: unset;
    padding: 0;
}
.m_custom_search .select_style .select_item:hover {
    color: #333 !important;
}

.jz_themeV2 .m_custom_search .select_style .select_item {
    line-height: normal;
}

.noProjecrtContainer .empty_tips_panel {
    text-align: center;
    padding-top: 1.7rem;
    padding-bottom: 2.4rem;
}

.noProjecrtContainer .empty_tips_panel .text {
    color: #666;
    font-size: 14px;
    margin-bottom: 0.9rem;
}

.m_custom_search {
    font-size: 0.6rem;
    line-height: 1;
    padding: 1rem 0.8rem;
}

.m_custom_search_projectName {
    color: #333;
    font-size: 0.78rem;
    text-align: center;
    max-width: 100%;
    word-break: break-word;
}
.m_custom_search_projectTips {
    margin-top: 0.8rem;
    color: #666;
    font-size: 0.65rem;
    text-align: center;
    max-width: 100%;
    word-break: break-word;
}
.m_custom_search_field {
    margin: 0.75rem auto 0;
}
.m_custom_search_field_item {
    width: 100%;
    margin-bottom: 0.5rem;
}
.m_custom_search_field_item:last-child {
    margin-bottom: 0;
}
.jz_screen_mobi .m_custom_search_field_item {
    width: 100%;
    min-width: auto;
}
.m_custom_search_field_item:after {
    content: '';
    display: block;
    height: 0;
    line-height: 0;
    clear: both;
}
.m_custom_search_field_label {
    color: #666;
    font-size: 0.62rem;
    line-height: 1.35rem;
}
.m_custom_search_field_content {
    font-size: 0;
}
.m_custom_search_star {
    color: #f13a3a;
    font-size: 0.7rem;
    line-height: 1.4rem;
    margin-right: 0.25rem;
}
.m_custom_search_submit_wrap {
    margin: 1.3rem 0 0;
    text-align: center;
}
.m_custom_search_submit {
    width: 100%;
    height: 2rem;
    border: 0;
    text-align: center;
    color: #fff;
    border-radius: 2px;
    cursor: pointer;
    font-size: 0.7rem;
    outline: none;
}
.m_custom_search .item_type_input {
    display: inline-block;
    width: 100%;
}
.m_custom_search_validator_wrap .m_custom_search_validator_img {
    height: 1.8rem;
    cursor: pointer;
}
.m_custom_search_validator_wrap .item_type_input {
    width: 30%;
}
</style>
