var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "progress_ring_wrap" }, [
    _c(
      "svg",
      { attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: _vm.viewBox } },
      [
        _c("circle", {
          attrs: {
            cx: _vm.radius,
            cy: _vm.radius,
            r: _vm.normalizedRadius,
            fill: "transparent",
            stroke: _vm.bottomRingStrokeColor,
            "stroke-miterlimit": "10",
            "stroke-dashoffset": "0",
            "stroke-width": _vm.strokeWidth,
            "stroke-dasharray": _vm.strokeDasharray,
          },
        }),
        _vm._v(" "),
        _c("circle", {
          staticClass: "progress_ring",
          style: { strokeDashoffset: _vm.strokeDashoffset },
          attrs: {
            cx: _vm.radius,
            cy: _vm.radius,
            r: _vm.normalizedRadius,
            fill: "transparent",
            stroke: _vm.upRingStrokeColor,
            "stroke-miterlimit": "10",
            "stroke-width": _vm.strokeWidth,
            "stroke-dasharray": _vm.strokeDasharray,
          },
        }),
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "progress_ring_text_wrap" }, [
      _c(
        "span",
        { staticClass: "progress_ring_top", style: _vm.topTextStyle },
        [_vm._v(_vm._s(_vm.numberText))]
      ),
      _vm._v(" "),
      _c(
        "span",
        { staticClass: "progress_ring_bottom", style: _vm.bottomStyle },
        [_vm._v(_vm._s(_vm.unitText))]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }