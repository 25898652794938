import { SystemModules, SystemComponents, SystemColModules } from '@/system/visitor-interface';

import { AdPicture } from './adPicture/module';
import { Button } from './button/module';
import { CalculationQuotation } from './calculationQuotation/module';
import { CarouselMultiPhoto } from './carouselMultiPhoto/module';
import { CountDown } from './countDown/module';
import { CubeNavigation } from './cubeNavigation/module';
import { CustomSearch } from './customSearch/module';
import { CustomSearchResult } from './customSearchResult/module';
import { FileDownload } from './fileDownload/module';
import { FloatImg } from './floatImg/module';
import { FoldContainer } from './foldContainer/module';
import { InsertCode } from './InsertCode/module';
import { Form } from './mobiForm/module';
import { MultiPhotoList } from './multiPhotoList/module';
import { PhotoList } from './photoList/module';
import { NewsList } from './news/module';
import { NewsGroup } from './newsGroup/module';
import { OnlineMap } from './onlineMap/module';
import { OnlineVideo } from './onlineVideo/module';
import { Pack } from './pack/module';
import { PhotoGroup } from './photoGroup/module';
import { PhotoGroupNav } from './photoGroupNav/module';
import { ProductGroup } from './productGroup/module';
import { ProductList } from './productList/module';
import { ProductSearch } from './ProductSearch/module';
import { Rich } from './rich/module';
import { ScrollNotice } from './scrollNotice/module';
import { SimpleText } from './simpleText/module';
import { TabContainer } from './tabContainer/module';
import { TotalSearch } from './totalSearch/module';
import { SearchResult } from './searchResult/module';
import { MODULE_STYLE } from '@/def/module';
import { Vote } from './vote/module';
import { WxAddFans } from './wxAddFans/module';
import { ModuleLink } from './moduleLink/visitor-module';
import { PhotoCarousel } from './photoCarousel/module';

export const MobiModules = {
    [MODULE_STYLE.AD_PICTURE]: AdPicture,
    [MODULE_STYLE.FLOAT_BTN]: Button,
    [MODULE_STYLE.PACK]: Pack,
    [MODULE_STYLE.SIMPLE_TEXT]: SimpleText,
    [MODULE_STYLE.FLOAT_IMG]: FloatImg,
    [MODULE_STYLE.MOBI_FORM]: Form,
    [MODULE_STYLE.HORIZON_TAB]: TabContainer,
    [MODULE_STYLE.INSERT_CODE]: InsertCode,
    [MODULE_STYLE.FOLD]: FoldContainer,
    [MODULE_STYLE.NEWS_LIST]: NewsList,
    [MODULE_STYLE.PRODUCT_LIST]: ProductList,
    [MODULE_STYLE.CAROUSEL_MULTI_PHOTO]: CarouselMultiPhoto,
    [MODULE_STYLE.CALCULATION_QUOTATION]: CalculationQuotation,
    [MODULE_STYLE.COUNT_DOWN]: CountDown,
    [MODULE_STYLE.CUBE_NAVIGATION]: CubeNavigation,
    [MODULE_STYLE.CUSTOM_SEARCH]: CustomSearch,
    [MODULE_STYLE.SYS_CUSTOM_SEARCH_RESULT]: CustomSearchResult,
    [MODULE_STYLE.FILE_DOWNLOAD]: FileDownload,
    [MODULE_STYLE.MULTI_PHOTO_LIST]: MultiPhotoList,
    [MODULE_STYLE.NEWS_GROUP]: NewsGroup,
    [MODULE_STYLE.ONLINE_MAP]: OnlineMap,
    [MODULE_STYLE.ONLINE_VIDEO]: OnlineVideo,
    [MODULE_STYLE.PHOTO_GROUP]: PhotoGroup,
    [MODULE_STYLE.PHOTO_GROUP_NAV]: PhotoGroupNav,
    [MODULE_STYLE.PRODUCT_GROUP]: ProductGroup,
    [MODULE_STYLE.PRODUCT_SEARCH]: ProductSearch,
    [MODULE_STYLE.RICH]: Rich,
    [MODULE_STYLE.SCROLL_NOTICE]: ScrollNotice,
    [MODULE_STYLE.TOTAL_SEAECH]: TotalSearch,
    [MODULE_STYLE.TOTAL_SEARCH_RESULT]: SearchResult, // 全站搜索结果
    [MODULE_STYLE.VOTE]: Vote,
    [MODULE_STYLE.WX_ADD_FANS]: WxAddFans,
    [MODULE_STYLE.PHOTO_LIST]: PhotoList,
    [MODULE_STYLE.LINK]: ModuleLink,
    [MODULE_STYLE.PHOTO_CAROUSEL]: PhotoCarousel,
    /** 系统模块 */
    ...SystemColModules,
};

export const componentsTable = {};
Object.keys(MobiModules).forEach((style) => {
    componentsTable[style] = MobiModules[style].render();
});

export const components = Object.values(componentsTable);

export { SystemModules, SystemComponents };
