<template>
    <div
        :id="'module' + id"
        ref="moduleFrame"
        :class="moduleCls"
        :_moduleStyle="styleId"
        :_autoHeight="autoHeightComp"
        :_height="moduleInfo.pattern.h || 0"
        :_autoWidth="autoWidthComp"
        :_width="moduleInfo.pattern.w || 0"
        :_inpack="inpackComp"
        :_inPopupZone="moduleInfo.inPopup || 0"
        :_inTab="moduleInfo.inTab || 0"
        :_inFold="moduleInfo.inFold || 0"
        :_infloatzone="moduleInfo.inFloatZone || 0"
        :_bHeight="moduleInfo.pattern.bh || 0"
        _headerHiden="1"
    >
        <div class="formMiddle" :class="'formMiddle' + id">
            <div class="middleLeft" :class="'middleLeft' + id" />
            <div class="middleCenter" :class="'middleCenter' + id">
                <div
                    class="formMiddleContent moduleContent"
                    :class="'formMiddleContent' + id"
                    :_innerType="moduleInfo.pattern.i.y || 0"
                >
                    <div class="jz_popupZoneWrap">
                        <div class="J_formPopupZoneContent f-formPopupZoneContent">
                            <div class="fk-elemZoneBg J_zoneContentBg" :class="zoneBgClass">
                                <!-- 子模块内容 -->
                                <template v-for="children in childrenList">
                                    <component
                                        :is="childrenComponent(children)"
                                        :key="createKeyTime(children.id)"
                                        :module-id="children.id"
                                        :style="childrenComponentStyle(children)"
                                    ></component>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="middleRight" :class="'middleRight' + id" />
        </div>
    </div>
</template>
<script>
import ModuleFrame from '@/modules/frame/index.vue';
import { mapGetters, mapState } from 'vuex';
export default {
    name: 'PopupZone',
    components: {
        ModuleFrame,
    },
    inject: ['componentsTable'],
    props: {
        moduleId: {
            type: [Number, String],
            default: -1,
        },
    },
    data() {
        return {
            time: new Date().getTime(),
        };
    },
    computed: {
        ...mapGetters(['getModuleById']),
        ...mapState('editMode', ['modules', 'hideModules', 'sortModules']),
        ...mapState('popupZone', ['renderCount']),
        id() {
            return this.moduleId;
        },
        moduleInfo() {
            return this.getModuleById(this.moduleId);
        },
        styleId() {
            return this.moduleInfo.style;
        },
        zoneBgClass() {
            return [`elemZoneBg${this.moduleId}`];
        },
        childrenList() {
            // 通过 sortModules 进行排序处理
            let childrenList = [];
            this.sortModules.forEach((id) => {
                let info = this.moduleInfo.children.find((child) => child.id == id);
                if (info != null) {
                    childrenList.push(info);
                }
            });
            return childrenList;
        },
        moduleCls() {
            let classList = ['form', 'Handle', `module${this.moduleId}`, `formStyle${this.styleId}`];
            return classList;
        },
        autoHeightComp() {
            let autoHeight = 1;
            let height = this.moduleInfo.pattern.h || 0;

            if (height > 0) {
                autoHeight = 0; // 0 为固定值，1 为自动
            }

            return autoHeight;
        },
        autoWidthComp() {
            let autoWidth = 1;
            let width = this.moduleInfo.pattern.w || 0;

            if (width > 0) {
                autoWidth = 0;
            }

            return autoWidth;
        },
        inpackComp() {
            let moduleInPack = this.moduleInfo.ip || 0;

            if (moduleInPack) {
                return moduleInPack;
            }

            return false;
        },
    },
    watch: {
        modules: {
            handler() {
                this.$nextTick(() => {
                    // this.time = new Date().getTime();
                    this.dealModuleTips();
                });
            },
            deep: true,
        },
    },
    methods: {
        childrenComponent(children) {
            let style = this.getModuleById(children.id).style;
            return this.componentsTable[style];
        },
        dealModuleTips() {
            let module = $(`#module${this.moduleId}`);
            let html = '';
            let forms = Array.from(module.find('.form'));
            forms = forms.filter((form) => {
                return !$(form).find('.f-fonticon').length;
            });
            let hasModuleShow = forms.some((form) => {
                let display = $(form).css('display');
                return display != 'none';
            });

            if (hasModuleShow) {
                module.find('.J_addContent').remove();
                return;
            }

            html += "<div class='J_addContent popupAddContent'>";
            html += '<span>拖动模块到这里<span>';
            html += '</div>';

            $(html).appendTo(module);
        },
        createKeyTime(id) {
            return id + this.time + id;
        },
        childrenComponentStyle(children) {
            let style = {};
            if (this.hideModules.includes(children.id)) {
                style['display'] = 'none';
            }
            return style;
        },
    },
};
</script>
<style lang="scss">
/**************************** 弹窗容器 因为编辑态是最高状态（暂时的）所以这里可以用！important ****************************/
.jz_popupZoneEdit .g_web > div {
    display: none !important;
}
.jz_popupZoneEdit .g_web .webModuleContainer > div {
    display: none !important;
}
.jz_popupZoneEdit .g_web .webModuleContainer > span {
    display: none !important;
}
.jz_popupZoneEdit .g_web .webContainerBox {
    display: block !important;
    position: fixed !important;
    width: 100%;
    top: 0;
    left: 0;
    padding: 0;
    margin: 0;
    transform: none;
    transition: unset;
}
.jz_popupZoneEdit .g_web .webModuleContainer .jz_popupZone_module {
    display: block !important;
}

/* 使弹窗自由容器编辑区域置顶 */
.jz_popupZoneEdit {
    overflow: hidden !important;
}
.jz_popupZoneEdit .fk-sortModuleStyle.formStyle51.fk-canConnectModule {
    height: 0 !important;
}
.jz_popupZoneEdit .jz_popupZone_module.formStyle51.fk-canConnectModule {
    height: 100% !important;
}

.jz_popupZoneEdit .g_web,
.jz_popupZoneEdit .webContainerBox,
.jz_popupZoneEdit .webModuleContainer,
.jz_popupZoneEdit .jz_popupZone_module,
.jz_popupZoneEdit .jz_popupZone_module .formMiddle,
.jz_popupZoneEdit .jz_popupZone_module .middleCenter,
.jz_popupZoneEdit .jz_popupZone_module .formMiddleContent,
.jz_popupZoneEdit .jz_popupZone_module .fk-formPack,
.jz_popupZoneEdit .jz_popupZone_module .f-packContentList,
.jz_popupZoneEdit .jz_popupZone_module .fk-packSortContainer {
    height: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
}

.jz_popupZoneShow .moveFrame {
    display: none;
}
#g_body.jz_popupZoneShow {
    overflow: hidden;
}
.jz_popupZoneEdit div.jz_popupZone_module > div.formMiddle {
    background: url(/image/module/popupZone/bg.png?v=201809101152) repeat;
}
</style>
