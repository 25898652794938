import { salePromotionDetail } from './var';
function arrHasSameBegin(longerArr, lowerArr) {
    var flag = true;
    for (var i = 0; i < lowerArr.length; i++) {
        if (lowerArr[i] != longerArr[i]) {
            flag = false;
        }
    }
    return flag;
}

export function initProductOptions(options) {
    options = options || {};
    var optionsAmountList = options.optionsAmountList;
    var mkt_optionsAmountList = options.mkt_optionsAmountList;
    var mkt_defaultPrice = options.mkt_defaultPrice;
    var isRelateAmount = options.isRelateAmount;
    var limitAmount_options = options.limitAmount_options;
    var productRestrictInfo = options.productRestrictInfo;
    var choiceCurrencyVal = options.choiceCurrencyVal;
    var entry = options.entry;
    var productId = options.productId;
    var weightTypeStr = options.weightTypeStr;
    var isCutPrice = options.isCutPrice;
    var cpMinPrice = options.cpMinPrice;
    var isSecKill = options.isSecKill;
    var secKillPrice = options.secKillPrice;
    var fromCommunityGroup = options.fromCommunityGroup; //是否来自社区团购
    var communityCustomPrice = options.communityCustomPrice; //社区团购价
    var isCombinedPurchase = options.isCombinedPurchase;
    var openPriceCalendar = options.openPriceCalendar;
    var themeV3 = Fai.top._openThemeV3;

    //兼容旧数据 type --> type2
    //entry = 1,从产品展示入口进入
    var oType2Exist = false;
    jm.each(optionsAmountList, function (i, n) {
        if (n.t == null) {
            oType2Exist = true;
            return false;
        }
    });

    if (weightTypeStr == null) {
        weightTypeStr = 'kg';
    }

    var optionBox = jm('.J-op-box'),
        optionImg = jm('.mallOptionImg'),
        optionlines = optionBox.find('.J-op'),
        _panelId = '';
    if (entry == 1) {
        optionBox = jm('#productMallOptionPanel' + productId + ' .J-op-box');
        (optionImg = jm('#productMallOptionPanel' + productId + ' .mallOptionImg')),
            (optionlines = optionBox.find('.J-op'));
        _panelId = '#productMallOptionPanel' + productId;
    }

    var emptyDataList = [];
    var initPrice = jm('#mallPrice').html(); //记录初始的价格
    var initAmount = jm('#mallAmount').html(); //记录初始的库存
    var initWeight = jm('#mallWeight').html(); //记录初始的重量

    var pdPic = '';
    var pdPicid = '';
    if (optionImg.length > 0) {
        var urlEnd = optionImg.css('background-image').lastIndexOf('"');
        pdPic = optionImg.attr('src') || optionImg.css('background-image').substring(5, urlEnd) || ''; //记录初始产品图
        pdPicid = optionImg.attr('picid') || ''; //记录初始产品id
    }

    if (!openPriceCalendar) {
        if (initAmount <= 0) {
            //如果初始库存（即总库存）为0，则禁用所有购物选项
            setEmptyAmountStyle(optionlines.find('label'));
            optionlines.find('label').attr('isEmptyAmount', '1');
        } else {
            //初始化购物选项时，当某个购物选项库存为0时作处理
            jm.each(optionsAmountList, function (i, n) {
                if (n.oAmount <= 0 && optionlines.length <= 1) {
                    //库存为0 且 只有一个购物选项时
                    //购物选项变灰，且禁止事件(立个flag，bind事件时无效)
                    var temItem = optionlines.find("label[data='" + n.t2 + "']");
                    setEmptyAmountStyle(temItem);
                    jm(temItem).attr('isEmptyAmount', '1');
                } else if (n.oAmount <= 0) {
                    emptyDataList.push(n.t2);
                }
            });
        }
    }
    var beforePicArr = [];
    jm(optionlines).each(function () {
        var option = jm(this);
        var selectedClass = themeV3 ? 'optionSelected isSelected g_main_bgColor_v3' : 'optionSelected isSelected';
        option.find('label').click(function () {
            if (jm(this).attr('isEmptyAmount') == '1') {
                //前面立的flag，为1说明这个购物选项0库存，点击无效
                return;
            }

            var currentOpAmountList = [];
            if (Mobi.isMarketingBtn && mkt_optionsAmountList.length > 0) {
                currentOpAmountList = mkt_optionsAmountList;
            } else {
                currentOpAmountList = optionsAmountList;
            }

            if (openPriceCalendar) {
                Mobi.priceCalendar.reset();
            }

            var label = jm(this);
            var itemId = label.parent().parent().attr('data');
            if (!label.hasClass('isSelected')) {
                //正常选择
                if (label.parent().find('.optionSelected').length > 0) {
                    label.parent().find('.optionSelected').removeClass(selectedClass);
                }
                label.addClass(selectedClass);

                var item_data = label.attr('data');
                option.attr('option_data', item_data);
            } else {
                label.parent().find('.optionSelected').removeClass(selectedClass);
                jm('#' + label.attr('for')).prop('checked', false);
                label.parents('.J-op').removeAttr('option_data');

                //恢复库存、价格、重量初始值
                if (Mobi.isMarketingBtn && mkt_optionsAmountList.length > 0) {
                    jm('#mallPrice').html(mkt_defaultPrice);
                } else {
                    jm('#mallPrice').html(initPrice);
                }
                jm('#mallAmount').html(initAmount);
                jm('#mallWeight').html(initWeight);

                //解除限购限制
                if (limitAmount_options) {
                    jm('#g_increase').removeClass('g_opacity50');
                    jm('#limitAmountDiv').text('');
                    jm('#limitAmountDiv').parent().css('line-height', '');
                    jm('#g_increase').unbind('click.ic2');
                    jm('#g_decrease').unbind('click.dc2');
                }
            }

            //每选中一个购物选项都计算剩余库存
            if (optionlines.length > 1 && isRelateAmount) {
                //总共2个或以上才实时计算库存
                //获取已选购物选项str
                var typeForCalAmount = ''; //如：2_x_3
                var amountForCalAmount = 0;
                optionlines.each(function (i) {
                    if (jm(optionlines[i]).find('.optionSelected').length <= 0) {
                        typeForCalAmount += 'x_';
                    } else {
                        typeForCalAmount += jm(optionlines[i]).find('.optionSelected').attr('data') + '_';
                    }
                });
                typeForCalAmount = typeForCalAmount.substring(0, typeForCalAmount.length - 1);
                jm.each(currentOpAmountList, function (i, n) {
                    //找出剩下未选购物选项组合的总库存
                    if (isHasNotSelect(typeForCalAmount, n.t2)) {
                        if (n.flag != 0 && !isNaN(n.oAmount)) {
                            //为0表示禁用购物选项，不参与计算总库存
                            amountForCalAmount += n.oAmount;
                        }
                    }
                });
                jm(_panelId + ' #mallAmount').text(amountForCalAmount);
            }

            var optionsSelect = optionBox.find('.optionSelected');

            //独立选项切换图片
            if (optionImg.length > 0) {
                var indeptpic = label.attr('_indeptPic');
                var picid = label.attr('picid');
                if (indeptpic == null) {
                    if (beforePicArr.length > 0) {
                        for (var j = 0; j < beforePicArr.length; j++) {
                            indeptpic = beforePicArr[beforePicArr.length - 1].path;
                            if (beforePicArr[j].itemId == itemId) {
                                //当前行
                                beforePicArr.pop();
                                if (beforePicArr.length > 0) {
                                    indeptpic = beforePicArr[beforePicArr.length - 1].path;
                                }
                            }
                        }
                    } else {
                        indeptpic = pdPic;
                    }
                    picid = pdPicid;
                } else {
                    beforePicArr.push({
                        itemId,
                        path: indeptpic,
                    });
                }
                if (Fai.top._openThemeV3) {
                    optionImg.css('background-image', 'url(' + indeptpic + ')');
                } else {
                    optionImg.attr('src', indeptpic);
                }
                jm('.J_mallOptionImg').attr('picid', picid).attr('src', indeptpic).attr('isOptImg', true);
            }

            if (optionlines.length == optionsSelect.length) {
                var type = '',
                    price,
                    amount,
                    minAmount,
                    maxAmount,
                    weight;

                jm(optionsSelect).each(function () {
                    if (oType2Exist) {
                        type += jm(this).attr('data') + '_';
                    } else {
                        type += jm(this).attr('data');
                    }
                });
                jm.each(currentOpAmountList, function (i, n) {
                    var t2 = n.t2;

                    if (n.t == type) {
                        price = n.oPrice;
                        amount = n.oAmount;
                        minAmount = n.minAmount;
                        maxAmount = n.maxAmount;
                        weight = n.w;
                    } else if (t2 + '_' == type) {
                        if (n.flag == 0) {
                            jm('#mallPrice').html(initPrice); //禁用购物选项显示区间价格
                        } else {
                            price = n.oPrice;
                        }
                        amount = n.oAmount;
                        minAmount = n.minAmount;
                        maxAmount = n.maxAmount;
                        weight = n.w;
                    }
                });
                if (fromCommunityGroup) {
                    price = communityCustomPrice;
                }
                //根据不同购物选项变更起购量和限购量
                if (limitAmount_options) {
                    jm(_panelId + ' #limitAmountDiv').text('');
                    var buyedCount = 0; //已经购买的数量
                    var optionsCount = productRestrictInfo['optionsCount'];
                    if (optionsCount != null && optionsCount['d'] != null) {
                        for (var i = 0; i < optionsCount['d'].length; i++) {
                            if (optionsCount['d'][i].t + '_' == type) {
                                buyedCount = optionsCount['d'][i].c;
                            }
                        }
                    }
                    var allowAmount = 0; //可购量
                    allowAmount = maxAmount - buyedCount;
                    if (jm.fkEval(allowAmount) < 0) {
                        allowAmount = -1;
                    }
                    var min = 1;
                    var max = -1;
                    if (minAmount > 1 && maxAmount != 0) {
                        jm(_panelId + ' #limitAmountDiv')
                            .parent()
                            .attr('style', 'line-height:1.5rem;');
                        jm(_panelId + ' #limitAmountDiv').text(
                            '( ' + LS.minAmount + ' : ' + minAmount + ' ; ' + LS.maxAmount + ' : ' + allowAmount + ' )'
                        );
                        min = minAmount;
                        max = allowAmount;
                    } else if (minAmount == 1 && maxAmount != 0) {
                        jm(_panelId + ' #limitAmountDiv')
                            .parent()
                            .attr('style', 'line-height:1.5rem;');
                        jm(_panelId + ' #limitAmountDiv').text('( ' + LS.maxAmount + ' : ' + allowAmount + ' )');
                        max = allowAmount;
                    } else if (minAmount > 1 && maxAmount == 0) {
                        jm(_panelId + ' #limitAmountDiv')
                            .parent()
                            .attr('style', 'line-height:1.5rem;');
                        jm(_panelId + ' #limitAmountDiv').text('( ' + LS.minAmount + ' : ' + minAmount + ' )');
                        min = minAmount;
                    }
                    jm(_panelId + ' #productNum').val(min);
                    jm(_panelId + ' #productNum').unbind('change');
                    jm(_panelId + ' #productNum').bind('change', function () {
                        var countStr = jm(_panelId + ' #productNum').val();
                        var count = 1;
                        if (jm.isInteger(countStr)) {
                            count = parseInt(countStr);
                        }
                        if (count < min || count == min) {
                            count = min;
                            jm(_panelId + ' #g_decrease , .g_decrease').addClass('g_opacity50');
                            jm(_panelId + ' #g_increase , .g_increase').removeClass('g_opacity50');
                        } else if (max != -1 && (count > max || count == max)) {
                            count = max;
                            jm(_panelId + ' #g_decrease , .g_decrease').removeClass('g_opacity50');
                            jm(_panelId + ' #g_increase , .g_increase').addClass('g_opacity50');
                        } else if (count > 9999998) {
                            count = 9999999;
                            jm(_panelId + ' #g_decrease , .g_decrease').removeClass('g_opacity50');
                            jm(_panelId + ' #g_increase , .g_increase').addClass('g_opacity50');
                        } else {
                            jm(_panelId + ' #g_decrease , .g_decrease').removeClass('g_opacity50');
                            jm(_panelId + ' #g_increase , .g_increase').removeClass('g_opacity50');
                        }
                        jm(_panelId + ' #productNum').val(count);
                    });
                    jm(_panelId + ' #productNum').change();

                    jm(_panelId + ' #g_decrease , .g_decrease').unbind('click.dc2');
                    jm(_panelId + ' #g_increase , .g_increase').unbind('click.ic2');
                    jm(_panelId + ' #g_decrease , .g_decrease').bind('click.dc2', function () {
                        var countStr = jm(_panelId + ' #productNum').val();
                        var count = 1;
                        if (jm.isInteger(countStr)) {
                            count = parseInt(countStr);
                        }
                        if (count < min + 1) {
                            jm(_panelId + ' #productNum').val(min);
                        }
                        jm(_panelId + ' #productNum').change();
                    });
                    jm(_panelId + ' #g_increase , .g_increase').bind('click.ic2', function () {
                        var countStr = jm(_panelId + ' #productNum').val();
                        var count = 1;
                        if (jm.isInteger(countStr)) {
                            count = parseInt(countStr);
                        }
                        if (count > max - 1 && max != -1) {
                            if (max > min) {
                                jm(_panelId + ' #productNum').val(max);
                            } else {
                                jm(_panelId + ' #productNum').val(min);
                            }
                        }
                        jm(_panelId + ' #productNum').change();
                    });
                }
                // 关联情况下 才动态修改相应库存量
                if (isRelateAmount) {
                    jm(_panelId + ' #mallAmount').text(amount);
                }

                if (!fromCommunityGroup) {
                    onlyChangeSalePrice(price, choiceCurrencyVal, entry, productId);
                }

                if (weight != null) {
                    jm(_panelId + ' #mallWeight').text(weight + weightTypeStr);
                }
            }

            if (optionlines.length != optionsSelect.length && optionlines.length != 1) {
                // 购物选项都选好了就不恢复，还有一种情况是只有一个购物选项的，也不恢复
                //先恢复被禁止的购物选项
                reSetEmptyAmountStyle();
            }
            if ((optionlines.length - 1 <= optionsSelect.length && optionlines.length != 1) || isCombinedPurchase) {
                //如果只剩下最后一项没选 或全选了。还有一种情况是只有一个购物选项的不考虑

                var selectType = ''; //没选的用x表示,比如：1_x_1
                optionlines.each(function (i) {
                    if (jm(optionlines[i]).find('.isSelected').length <= 0) {
                        selectType += 'x_';
                    } else {
                        selectType += jm(optionlines[i]).find('.isSelected').attr('data') + '_';
                    }
                });
                if (selectType != '') {
                    //去掉最后一个"_"
                    selectType = selectType.substring(0, selectType.length - 1);
                }

                var neverSelectItem = '';
                //遍历，找出未select的optionlines,存进neverSelectItem
                optionlines.each(function (i) {
                    var tmpItemSelected = jm(optionlines[i]).find('.isSelected');
                    if (tmpItemSelected.length <= 0) {
                        neverSelectItem = jm(optionlines[i]);
                    }
                });

                if (neverSelectItem != '') {
                    //如果neverSelectItem存在(即存在未选的购物选项)
                    /**思路：遍历当前未选的购物选项的label（tmpJItem）,将selectType的x替换成当前tmpJItem的data，生成tmpSelectType
                     **		判断这个tmpSelectType是否在emptyDataList中，
                     **		在的话就设置不可选，不在的话就可选(因为有可能之前被设置了不开选，所以需要重置一下)
                     **/
                    var tmpJItem = neverSelectItem.find('label');
                    tmpJItem.each(function (i) {
                        var tmpSelectType = selectType.replace('x', jm(tmpJItem[i]).attr('data'));
                        if (jm.inArray(tmpSelectType, emptyDataList) >= 0) {
                            setEmptyAmountStyle(tmpJItem[i]);
                            jm(tmpJItem[i]).attr('isEmptyAmount', '1');
                        } else {
                            reSetOneEmptyAmountStyle(tmpJItem[i]);
                        }
                    });
                } else {
                    //如果全选了
                    /**思路：遍历optionItemWrap,再遍历每个optionlines中的未选中的label
                     **		再将当前label的data替换到selectType，生成newSelectType，判断这个newSelectType是否在emptyDataList中，
                     **		在的话就设置不可选，不在的话就可选(因为有可能之前被设置了不开选，所以需要重置一下)
                     **/
                    optionlines.each(function (i) {
                        var tmpItem = jm(optionlines[i]).find('label');
                        tmpItem.each(function (i2) {
                            if (jm(tmpItem[i2]).hasClass('isSelected')) {
                                return;
                            }
                            var tmpArr = selectType.split('_');
                            tmpArr[i] = jm(tmpItem[i2]).attr('data');
                            var newSelectType = tmpArr.join('_');
                            if (jm.inArray(newSelectType, emptyDataList) >= 0) {
                                setEmptyAmountStyle(tmpItem[i2]);
                                jm(tmpItem[i2]).attr('isEmptyAmount', '1');
                            } else {
                                reSetOneEmptyAmountStyle(tmpItem[i2]);
                            }
                        });
                    });
                }
            }

            if (optionsSelect.length == 0) {
                //如果取消所有购物选项，展示回产品图
                // eslint-disable-next-line no-redeclare
                var indeptpic = pdPic;
                // eslint-disable-next-line no-redeclare
                var picid = pdPicid;
                if (Fai.top._openThemeV3) {
                    optionImg.css('background-image', 'url(' + indeptpic + ')');
                } else {
                    optionImg.attr('src', indeptpic);
                }
                jm('.J_mallOptionImg').attr('picid', picid).attr('src', indeptpic).attr('isOptImg', true);
            }

            //初始化购物选项停启用
            var selectArr = [];
            for (let i = 0; i < optionlines.length; i++) {
                var optionItem = optionlines.eq('' + i + '');
                if (optionItem.find('.optionSelected').length > 0) {
                    selectArr.push(optionItem.find('.optionSelected').attr('data'));
                } else {
                    break;
                }
            }

            var flag = false;
            //检查所选组合是否启用,裁剪selectArr到合理长度（存在的组合）
            //处理前：["0", "1", "0", "2", "0"]
            //处理后：["0", "1", "0"]
            if (selectArr.length <= optionlines.length && selectArr.length > 1 && !openPriceCalendar) {
                var selectArrLen = selectArr.length;
                for (let i = 0; i < selectArrLen; i++) {
                    jm.each(currentOpAmountList, function (j, n) {
                        var t2 = n.t2;

                        var t2Arr = t2.split('_');
                        if (n.flag == undefined) {
                            n.flag = 1;
                        }

                        if (n.flag == 1 && arrHasSameBegin(t2Arr, selectArr)) {
                            flag = true;
                            return false;
                        }
                    });
                    if (!flag) {
                        var items = optionlines.eq(selectArr.length - 1).find('label');
                        optionlines.eq(selectArr.length - 1).removeAttr('option_data');
                        items.removeClass(selectedClass);
                        var lastData = selectArr.pop();
                        items.each(function () {
                            if (jm(this).attr('data') == lastData) {
                                jm(this).hide();
                            }
                        });
                    } else {
                        break;
                    }
                }
            }
            //根据用户点购物选项，裁剪selectArr长度
            var opIndx = optionlines.index(option);
            if (!openPriceCalendar) {
                // eslint-disable-next-line no-redeclare
                for (var j = opIndx; j < selectArr.length; j++) {
                    var subSelectArr = selectArr.slice(0, j + 1);
                    /**
	                    经过处理的selectArr已经是存在的组合，下面处理后续购物选项值的display
	                */
                    flag = false;
                    for (let i = subSelectArr.length; i < optionlines.length; i++) {
                        var optionItemUl = optionlines.eq('' + i + '');
                        optionItemUl.find('label').each(function () {
                            var data = jm(this).attr('data');
                            flag = false;
                            jm.each(currentOpAmountList, function (j, n) {
                                var t2 = n.t2;

                                var t2Arr = t2.split('_');
                                if (n.flag == undefined) {
                                    n.flag = 1;
                                }
                                if (data == t2Arr[i] && n.flag == 1 && arrHasSameBegin(t2Arr, subSelectArr)) {
                                    flag = true;
                                    return false;
                                }
                            });
                            if (!flag) {
                                jm(this).hide();
                            } else {
                                jm(this).show();
                            }
                        });
                    }
                }
            }

            if (isCutPrice) {
                jm('#mallPrice').html(cpMinPrice);
            } else if (isSecKill) {
                jm('#mallPrice').html(secKillPrice);
            } else if (isCombinedPurchase) {
                Mobi.cpPdOtionSelectType['pid' + productId] = selectType;
                if (selectType && !selectType.includes('x')) {
                    var optionAmountInfo = Mobi.cachePdOptionInfo['optionsAmountInfo' + productId] || {};
                    var optionAmountData = optionAmountInfo.d || [];
                    jm.each(optionAmountData, function (index, item) {
                        if (item.t2 == selectType) {
                            jm('#targetAmount' + productId).html(item.oAmount || 0);
                            jm('#productOriginalPrice' + productId).html(
                                '原价' + Fai.top.choiceCurrencyVal + parseFloat(item.oPrice).toFixed(2)
                            );
                        }
                    });
                }
            }

            if (Mobi.productNewDetailIScrollObj != null) {
                //购买弹层那里的iscroll对象刷新（因为购物选项点击有可能会改变窗体高度导致iscoll不能适应）
                Mobi.productNewDetailIScrollObj.refresh();
            }
        });
    });

    //设置购物选项库存为0时的样式
    function setEmptyAmountStyle(obj) {
        jm(obj).css('border-style', 'dashed').css('color', '#CDCDCD');
        jm('#' + jm(obj).attr('for')).attr('disabled', true);
    }

    //恢复某个购物选项样式
    function reSetOneEmptyAmountStyle(obj) {
        jm(obj).css('border-style', '').css('color', '');
        jm(obj).attr('isEmptyAmount', '');
        jm('#' + jm(obj).attr('for')).removeAttr('disabled');
    }

    //恢复所有购物选项样式
    function reSetEmptyAmountStyle() {
        jm('.J-op-box label').css('border-style', '').css('color', '');
        jm('.J-op-box label').attr('isEmptyAmount', '');
        jm(".J-op-box input[type='radio']").removeAttr('disabled');
    }

    //xOpStr是否属于opStr
    //例：xOpStr=2_x_3;opStr=2_1_3;则返回true(x代表未知,x可以多个，比如2_x_x_3_x_6)
    function isHasNotSelect(xOpStr, opStr) {
        if (xOpStr == '') return false;
        var res = true;
        var tmpArr1 = xOpStr.split('_');
        var tmpArr2 = opStr.split('_');
        if (tmpArr1.length != tmpArr2.length) return false;
        jm.each(tmpArr1, function (i) {
            if (tmpArr1[i] == 'x') return;
            if (tmpArr1[i] != tmpArr2[i]) res = false;
        });
        return res;
    }
}

function onlyChangeSalePrice(prePrice, choiceCurrencyVal, entry, productId) {
    prePrice = parseFloat(prePrice);
    if (isNaN(prePrice)) {
        return;
    }
    var jmMallPrice = jm('#mallPrice');
    //entry == 1 ： 表示在产品展示模块，添加购物车，0则表示在产品详情页
    if (entry == 1) jmMallPrice = jm('#productMallOptionPanel' + productId + ' #mallPrice');
    var salePromotionParam = salePromotionDetail.salePromotionParam;
    if (typeof salePromotionParam == 'undefined' || salePromotionParam == null || salePromotionParam == '') {
        jmMallPrice.html(prePrice.toFixed(2));
        return;
    }

    if (salePromotionDetail.showType == '1') {
        jmMallPrice.html(prePrice.toFixed(2));
        return;
    }

    if (salePromotionParam.saleType == '1') {
        var number = parseFloat(salePromotionParam.other.ruleData.d[0].m);
        var salePrice = 0.0;
        if (salePromotionParam.other.ruleData.s == '1') {
            salePrice = prePrice * (number / 10.0);
        } else {
            salePrice = prePrice - number;
        }
        if (salePrice < 0) {
            salePrice = 0.0;
        }

        // 判断是不是新的模板
        if (jm('.J_saleProductNew').css('width').length == 0 && entry != 1 && typeof entry != 'undefined') {
            // 旧模板
            jmMallPrice.html(prePrice.toFixed(2));
            jm('.J_salePriceSet').html(choiceCurrencyVal + salePrice.toFixed(2));
        } else {
            // 新模板
            jmMallPrice.html(salePrice.toFixed(2));
            jm('.J_salePriceSet').html(choiceCurrencyVal + salePrice.toFixed(2));
        }
    } else {
        jmMallPrice.html(prePrice.toFixed(2));
    }
}
export function initShowMallOptionImg(moduleId) {
    var module = jm('#module' + moduleId);
    var mallOptionImg = module.find('.J_mallOptionImg');
    if (mallOptionImg.length > 0) {
        jm(mallOptionImg).click(function () {
            var picId = jm(this).attr('picid');
            var isOptImg = jm(this).attr('isoptimg') == 'true';
            if (isOptImg) {
                jm('#J_acct_' + Fai.top._aid + '_opt_pic_' + picId).click();
            } else {
                jm('#J_acct_' + Fai.top._aid + '_pic_' + picId).click();
            }
        });
    }
}
