var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "photoModule styleForm6" }, [
    _c(
      "div",
      {
        staticClass: "photoSwipe",
        attrs: { id: "photoSwipe" + _vm.module.id },
      },
      [
        _c(
          "div",
          { staticClass: "photoSwipeBox" },
          _vm._l(_vm.cycleList, function (item, index) {
            return _c(
              "div",
              {
                key: item.picId,
                staticClass: "swipImgArea imageDiv",
                class: _vm.imageDivStyle,
                attrs: { "data-picId": item.picId || item.pic },
              },
              [
                _vm.content.link === 0
                  ? _c(
                      "a",
                      {
                        staticClass: "imgHref",
                        staticStyle: { cursor: "default" },
                        attrs: { href: item.hrefStr2 },
                        on: {
                          click: function ($event) {
                            return _vm.photoSlidesshow(index, item)
                          },
                        },
                      },
                      [
                        _c("img", {
                          staticClass: "J_img_lazyload",
                          class: _vm.stretchImgClass,
                          attrs: {
                            id: item.picId,
                            "src-original": item.picPath,
                            src: _vm.imgSrc(item),
                            vwidth: item.rect_640_width,
                            vheight: item.rect_640_height,
                            alt: item.name,
                            photoIndex: index,
                          },
                        }),
                      ]
                    )
                  : _vm.content.link === 1
                  ? _c(
                      "a",
                      {
                        staticClass: "imgHref",
                        attrs: { hidefocus: "true" },
                        on: {
                          click: function ($event) {
                            return _vm.photoSlidesshow(index, item)
                          },
                        },
                      },
                      [
                        _c("img", {
                          staticClass: "J_noOpenDesigner J_img_lazyload",
                          class: _vm.stretchImgClass,
                          attrs: {
                            id: item.picId,
                            "src-original": item.picPath,
                            src: _vm.imgSrc(item),
                            vwidth: item.rect_640_width,
                            vheight: item.rect_640_height,
                            alt: item.name,
                            photoIndex: index,
                          },
                        }),
                      ]
                    )
                  : _vm.content.link === 2 &&
                    _vm.content.apw === 1 &&
                    item.linkType == 2 &&
                    item.jumpInfo.u != ""
                  ? _c(
                      "a",
                      {
                        staticClass: "imgHref",
                        attrs: { hidefocus: "true", href: item.jumpInfo.u },
                        on: {
                          click: function ($event) {
                            return _vm.photoSlidesshow(index, item)
                          },
                        },
                      },
                      [
                        _c("img", {
                          staticClass: "J_noOpenDesigner J_img_lazyload",
                          class: _vm.stretchImgClass,
                          attrs: {
                            id: item.picId,
                            "src-original": item.picPath,
                            src: _vm.imgSrc(item),
                            vwidth: item.rect_640_width,
                            vheight: item.rect_640_height,
                            alt: item.name,
                            photoIndex: index,
                          },
                        }),
                      ]
                    )
                  : _vm.content.link === 2 && item.jumpInfo.u != ""
                  ? _c(
                      "a",
                      {
                        staticClass: "imgHref",
                        attrs: { hidefocus: "true", href: item.jumpInfo.u },
                        on: {
                          click: function ($event) {
                            return _vm.photoSlidesshow(index, item)
                          },
                        },
                      },
                      [
                        _c("img", {
                          staticClass: "J_noOpenDesigner J_img_lazyload",
                          class: _vm.stretchImgClass,
                          attrs: {
                            id: item.picId,
                            "src-original": item.picPath,
                            src: _vm.imgSrc(item),
                            vwidth: item.rect_640_width,
                            vheight: item.rect_640_height,
                            alt: item.name,
                            photoIndex: index,
                          },
                        }),
                      ]
                    )
                  : _c(
                      "a",
                      {
                        staticClass: "imgHref",
                        attrs: { hidefocus: "true" },
                        on: {
                          click: function ($event) {
                            return _vm.photoSlidesshow(index, item)
                          },
                        },
                      },
                      [
                        _c("img", {
                          staticClass: "J_noOpenDesigner J_img_lazyload",
                          class: _vm.stretchImgClass,
                          attrs: {
                            id: item.picId,
                            "src-original": item.picPath,
                            src: _vm.imgSrc(item),
                            vwidth: item.rect_640_width,
                            vheight: item.rect_640_height,
                            alt: item.name,
                            photoIndex: index,
                          },
                        }),
                      ]
                    ),
              ]
            )
          }),
          0
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "photoBullet",
            class: _vm.showNameClass,
            style: _vm.photoBulletStyle,
            attrs: { id: "photoBullet" + _vm.module.id },
          },
          [
            _c("table", { staticClass: "tableFix", class: _vm.tabaleBgColor }, [
              _c("tbody", [
                _c("tr", [
                  _vm.content.showName === true
                    ? _c(
                        "td",
                        {
                          class: _vm.imgNameClass,
                          staticStyle: { display: "block" },
                        },
                        [
                          _c("div", {
                            staticClass: "imgName g_carouselPhoto_link",
                            style: _vm.link_style,
                            attrs: { id: "imgName" + _vm.module.id },
                          }),
                        ]
                      )
                    : _c("td", { class: _vm.imgNameClass }, [
                        _c("div", {
                          staticClass: "imgName g_carouselPhoto_link",
                          style: _vm.link_style,
                          attrs: { id: "imgName" + _vm.module.id },
                        }),
                      ]),
                  _vm._v(" "),
                  _vm.content.showName === true
                    ? _c(
                        "td",
                        {
                          staticClass: "tdUl",
                          staticStyle: { display: "block" },
                        },
                        [
                          _c(
                            "ul",
                            {
                              staticClass: "bullets f-bullets J_noOpenDesigner",
                              class: _vm.bulletsHideClass,
                              attrs: { id: "bullets" + _vm.module.id },
                            },
                            _vm._l(_vm.cycleList, function (item, index) {
                              return _c("li", {
                                key: index,
                                staticClass: "J_noOpenDesigner",
                                class: _vm.onClass(index),
                              })
                            }),
                            0
                          ),
                        ]
                      )
                    : _vm.content.showName === false
                    ? _c("td", { staticClass: "tdUl" }, [
                        _c(
                          "ul",
                          {
                            staticClass: "bullets f-bullets J_noOpenDesigner",
                            class: _vm.bulletsHideClass,
                            attrs: { id: "bullets" + _vm.module.id },
                          },
                          _vm._l(_vm.cycleList, function (item, index) {
                            return _c("li", {
                              key: index,
                              staticClass: "J_noOpenDesigner",
                              class: _vm.onClass(index),
                            })
                          }),
                          0
                        ),
                      ])
                    : _vm._e(),
                ]),
              ]),
            ]),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }