var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("module-frame", { attrs: { "module-id": _vm.moduleId } }, [
    _c(
      "div",
      { class: _vm.wrapClasses },
      [
        _vm.manageMode
          ? [
              _c(
                "a",
                {
                  ref: "btnRef",
                  staticClass: "m_button_link J_floatBtn middle floatBtn",
                  class: _vm.aClasses,
                  style: _vm.aStyle,
                  attrs: {
                    id: _vm.wrapId,
                    contenteditable: _vm.contenteditable,
                    rel: _vm.rel,
                    onclick: _vm.jumpOnclickStr,
                    href: _vm.href,
                  },
                  on: {
                    click: _vm.aClick,
                    dblclick: _vm.aDblClick,
                    blur: _vm.aBlur,
                  },
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.moduleContent.bs.v) +
                      "\n            "
                  ),
                ]
              ),
            ]
          : [
              _c(
                "a",
                {
                  ref: "btnRef",
                  staticClass: "m_button_link J_floatBtn middle floatBtn",
                  class: _vm.aClasses,
                  style: _vm.aStyle,
                  attrs: {
                    id: _vm.wrapId,
                    href: _vm.href,
                    contenteditable: _vm.contenteditable,
                    rel: _vm.rel,
                    onclick: _vm.jumpOnclickStr,
                  },
                  on: {
                    click: _vm.aClick,
                    dblclick: _vm.aDblClick,
                    blur: _vm.aBlur,
                  },
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.moduleContent.bs.v) +
                      "\n            "
                  ),
                ]
              ),
            ],
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }