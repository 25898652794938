import calculationModalFrameComponent from './components/calculationModalFrame.vue';
import { logDog } from '@/shared/log';
export const calculationModalFrame = {
    component: calculationModalFrameComponent,
    render(calculateReultList, variableData, resultMsg) {
        if (this.vm) {
            this.vm.calculateReultList = calculateReultList;
            this.vm.variableData = variableData;
            this.vm.resultMsg = resultMsg;
        }
        $("<div id='calculationModalFrame'></div>").appendTo('#g_body');
        this.component.data = {
            calculateReultList,
            variableData,
            resultMsg,
        };
        this.vm = new Vue(this.component);
    },
    destroy() {
        if (this.vm) {
            $(this.vm.$el).remove();
            this.vm && this.vm.$destroy();
            this.vm = null;
        }
    },
};

export function CalculationModuleBtnManage(moduleId) {
    window.$store.commit('manage/calculationQuotation/updateCalcBtnItemEdit', {
        moduleId,
    });

    logDog(201352, 21);

    Vue.prototype.$designer.open({
        panelType: 'JzCalculationQuotationEditBtn',
    });
}
