<template>
    <div v-if="showNewTop" class="newTopStyle" :class="newTopStyleType">
        <svg v-if="topType == 3" role="img" class="jzm-v29">
            <use xlink:href="#jzm-v29"></use>
        </svg>
        <svg v-if="topType == 1" role="img" class="jzm-v30">
            <use xlink:href="#jzm-v30"></use>
        </svg>
        <svg v-if="topType == 2" role="img" class="jzm-v31">
            <use xlink:href="#jzm-v31"></use>
        </svg>
        <div v-if="topType == 6" class="topContentBox"></div>
        <div v-if="topType == 4" class="topContentBox"></div>
        <div v-if="topType == 5" class="topContentBox"></div>
    </div>
</template>

<script>
import ListItem from '../../mixins/ListItem';
import { mapFlag } from '@/shared/flag';
import { isRecommendedNewsListModule } from '@/modules/type-mapping';

export default {
    name: 'NewInfo',
    mixins: [ListItem],
    inject: ['module', 'options'],
    props: {
        info: {
            type: Object,
            default: function () {
                return {};
            },
        },
        index: { type: Number, default: 0 },
    },
    computed: {
        ...mapFlag(
            {
                isNewAddModule: 0x4,
            },
            'module.flag'
        ),
        showNewTop() {
            return !this.module.content.tfs.ih && this.isNewAddModule && !this.smartSelect;
        },
        topType() {
            return this.module.content.tfs.tt;
        },
        newTopStyleType() {
            return 'newTopStyle' + this.module.content.tfs.tt;
        },
        recommendModule() {
            return isRecommendedNewsListModule(this.module.id);
        },
        smartSelect() {
            if (this.recommendModule) {
                return this.module.content.newsSelectType == 2;
            } else {
                return false;
            }
        },
    },
    methods: {},
};
</script>

<style></style>
