var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("module-frame", { attrs: { "module-id": _vm.moduleId } }, [
    _c(
      "div",
      { staticClass: "linkArea" },
      [
        _vm._l(_vm.dataList, function (item, index) {
          return [
            _vm.isHText
              ? _c("a", {
                  key: index,
                  staticClass: "link-h-container",
                  style: _vm.textStyle,
                  attrs: {
                    href: item.linkHref,
                    target: _vm.linkTarget(item),
                    onclick: item.onclickStr,
                  },
                  domProps: { innerHTML: _vm._s(_vm.linkText(item)) },
                })
              : _vm.isVText
              ? _c("div", { key: index, staticClass: "lineBody" }, [
                  _c("div", { staticClass: "linkTableCell" }, [
                    _c("div", { staticClass: "J_lineH j-lineH" }, [
                      _c("a", {
                        staticClass: "link-v-container",
                        style: _vm.textStyle,
                        attrs: {
                          href: item.linkHref,
                          target: _vm.linkTarget(item),
                          onclick: item.onclickStr,
                        },
                        domProps: { innerHTML: _vm._s(_vm.linkText(item)) },
                      }),
                    ]),
                  ]),
                ])
              : _c(
                  "a",
                  {
                    key: index,
                    staticClass: "link-p-container",
                    attrs: {
                      href: item.linkHref,
                      target: _vm.linkTarget(item),
                      onclick: item.onclickStr,
                    },
                  },
                  [
                    _c("div", { staticClass: "imgClass_160" }, [
                      _c("span", { staticClass: "imageMiddle" }),
                      _vm._v(" "),
                      _c("img", {
                        staticClass: "style1Img photoListImg",
                        attrs: { src: item.newPath, alt: _vm.linkText(item) },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("div", {
                      style: _vm.textStyle,
                      domProps: { innerHTML: _vm._s(_vm.linkText(item)) },
                    }),
                  ]
                ),
          ]
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }