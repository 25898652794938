<template>
    <div
        :id="'newsLine' + info.id"
        :topClassName="_topClassName"
        :topSwitch="_topSwitch"
        :newsId="info.id"
        :newsName="info.title"
        :class="bigItemClasses"
        :picnum="3"
    >
        <div
            v-if="!isNewAddModule || (isNewAddModule && module.content.tPosition == 1)"
            :id="'lineBody' + info.id"
            class="lineBody"
            :class="[isNewAddModuleClass, isOnlyTitle]"
        >
            <div class="mixNewsStyleImgBox1">
                <a
                    v-if="!isNewAddModule"
                    class="mixNewsStyleImgBoxWrap1"
                    hidefocus="true"
                    :href="_newsUrl"
                    :target="_jumpTarget"
                    :onclick="onclickStr"
                >
                    <div v-if="info.realPicId === ''" class="icon-noFigureM"></div>
                    <img v-else :src="info.pic160Path" :alt="altName" />
                </a>
                <a
                    v-if="isNewAddModule"
                    class="mixNewsStyleImgBoxWrap1"
                    hidefocus="true"
                    :href="_newsUrl"
                    :target="_jumpTarget"
                    :onclick="onclickStr"
                >
                    <div
                        v-drag-resize="_resizeOptions"
                        class="head_word_pic picScaleClass J_img_lazyload"
                        :src-original="info.realPicId ? info.bigPicPath : ''"
                        :alt="altName"
                        :style="picStyle"
                        :class="bigPicClasses"
                    ></div>
                </a>
            </div>
            <div class="mixNewsStyleTitleContainer">
                <div :class="_topFlagClass"></div>
                <a
                    hidefocus="true"
                    :class="[titleClasses, titleLine]"
                    class="g_news_title news_title newTitle"
                    :style="news_title_style"
                    :target="_jumpTarget"
                    :href="_newsUrl"
                    :onclick="onclickStr"
                    ><top-component v-if="_topFlagClass != '' && isNewAddModule"></top-component>{{ info.title }}</a
                >
            </div>
            <new-info v-if="isNewAddModule && !showDes" :info="info" :index="index" />
            <a
                v-if="!isNewAddModule"
                :href="_newsUrl"
                class="mixNewsStyleSummary g_text g_news_abstract"
                :style="news_abstract_style"
                :target="_jumpTarget"
                hidefocus="true"
                :onclick="onclickStr"
                >{{ info.summary || '' }}</a
            >
            <a
                v-if="isNewAddModule && showDes"
                :href="_newsUrl"
                class="mixNewsStyleSummary g_text g_news_abstract newDescriptionLine"
                :class="[descriptionLine, isOnlyTitleAndDes]"
                :style="news_abstract_style"
                :target="_jumpTarget"
                hidefocus="true"
                :onclick="onclickStr"
                >{{ info.summary || '' }}</a
            >
        </div>

        <div
            v-if="isNewAddModule && module.content.tPosition == 2"
            :id="'lineBody' + info.id"
            class="lineBody imgRight"
            :class="[isNewAddModuleClass, isOnlyTitle]"
        >
            <div class="imgRightText">
                <div class="mixNewsStyleTitleContainer">
                    <div :class="_topFlagClass"></div>
                    <a
                        hidefocus="true"
                        :class="[titleClasses, titleLine]"
                        class="g_news_title news_title newTitle"
                        :target="_jumpTarget"
                        :href="_newsUrl"
                        :onclick="onclickStr"
                        ><top-component v-if="_topFlagClass != '' && isNewAddModule" @click.stop=""></top-component
                        >{{ info.title }}</a
                    >
                </div>
                <new-info v-if="isNewAddModule && !showDes" :info="info" :index="index" />
                <a
                    v-if="showDes"
                    :href="_newsUrl"
                    class="mixNewsStyleSummary g_text g_news_abstract newDescriptionLine"
                    :class="[descriptionLine, isOnlyTitleAndDes]"
                    :style="news_abstract_style"
                    :target="_jumpTarget"
                    hidefocus="true"
                    :onclick="onclickStr"
                    >{{ info.summary || '' }}</a
                >
            </div>
            <div class="mixNewsStyleImgBox1" @click.stop="">
                <a
                    v-if="isNewAddModule"
                    class="mixNewsStyleImgBoxWrap1"
                    hidefocus="true"
                    :href="_newsUrl"
                    :target="_jumpTarget"
                    :onclick="onclickStr"
                >
                    <div
                        v-drag-resize="_resizeOptions"
                        class="head_word_pic picScaleClass J_img_lazyload"
                        :src-original="info.realPicId ? info.bigPicPath : ''"
                        :alt="altName"
                        :style="picStyle"
                        :class="bigPicClasses"
                    ></div>
                </a>
            </div>
        </div>
        <new-info v-if="isNewAddModule && showDes" :info="info" :index="index" />
    </div>
</template>

<script>
import ListItem from '../../mixins/ListItem';
import { mapFlag } from '@/shared/flag';
import TopComponent from '../topComponent/top-component.vue';
import NewInfo from '../picTextList/new-info.vue';

export default {
    name: 'HeadWordPicItem',
    components: { NewInfo, TopComponent },
    mixins: [ListItem],
    inject: ['module', 'options', 'altName', 'ndUrl'],
    props: {
        info: {
            type: Object,
            default: function () {
                return {};
            },
        },
        index: { type: Number, default: 0 },
    },
    computed: {
        ...mapFlag(
            {
                isNewAddModule: 0x4,
            },
            'module.flag'
        ),
        isNewAddModuleClass() {
            return this.isNewAddModule ? ' isNewAddModule' : '';
        },
        bigItemClasses() {
            return `${this._lineClass} mixNewsStyleLine picNum1 newsLine J_picNum`;
        },
        indexPicWidth() {
            // 首图宽度
            const picHeightSet = this.module.content.indexPicHeightParam || {};
            const indexPicHeightType = picHeightSet.indexPicHeightType;
            const indexPicWidthRem = picHeightSet.indexPicWidth / 20;
            return indexPicHeightType == 2 ? indexPicWidthRem : 6.5;
        },
        indexPicHeight() {
            // 首图高度
            const picHeightSet = this.module.content.indexPicHeightParam || {};
            const indexPicHeightType = picHeightSet.indexPicHeightType;
            const indexPicHeightRem = picHeightSet.indexPicHeight / 20;
            return indexPicHeightType == 2 ? indexPicHeightRem : 4.9;
        },
        titleClasses() {
            return this.module.content.sl ? 'mixNewsStyleTitle1' : 'mixNewsStyleTitle2 g_link';
        },
        bigPicClasses() {
            return this.info.realPicId === '' ? 'newsTitlePic icon-noFigureB' : 'newsTitlePic';
        },
        bigPicStyle() {
            let picStyle = {};
            picStyle.height = `${this.indexPicHeight}rem`;
            if (this.info.realPicId !== '') {
                picStyle.background = `url(${
                    this.noLazyLoad ? this.info.bigPicPath : this.options.loadingPath
                }) no-repeat center /cover`;
            }
            return picStyle;
        },
        descriptionLine() {
            const descriptionLine = this.module.content.dsl;
            const textPosClass = this.module.content.tp == 2 ? 'newsTextPosition2' : '';
            var desClass = '';
            switch (descriptionLine) {
                case 0:
                    desClass = 'defaultDescriptionLine';
                    break;
                case 1:
                    desClass = 'descriptionLine';
                    break;
                case 2:
                    desClass = 'noDescriptionLine';
                    break;
            }
            return `${textPosClass} ${desClass}`;
        },
        titleLine() {
            const descriptionLine = this.module.content.tsl;
            var desClass = '';
            switch (descriptionLine) {
                case 0:
                    desClass = 'defaultDescriptionLine';
                    break;
                case 1:
                    desClass = 'descriptionLine';
                    break;
                case 2:
                    desClass = 'noDescriptionLine';
                    break;
            }
            return ` ${desClass}`;
        },
        picStyle() {
            let picStyle = {};
            if (this.info.realPicId !== '') {
                picStyle.background = `url(${this.info.bigPicPath}) no-repeat center /cover`;
            }
            if (this.isNewAddModule) {
                picStyle.width = `${this.indexPicWidth}rem`;
                picStyle.height = `${this.indexPicHeight}rem`;
            }
            return picStyle;
        },
        showDes() {
            if (!this.isNewAddModule) {
                return false;
            }
            return this.isNewAddModule && !this.module.content.des ? true : false;
        },
    },
};
</script>

<style>
.news_list_wrap .head_word_pic_text_list .lineBody {
    padding-top: 0.24rem;
}
.isNewAddModule.news_list_wrap .head_word_pic_text_list .lineBody {
    padding-bottom: 0.3rem;
    padding-top: 0.7rem;
}
.news_list_wrap .head_word_pic_text_list .mixNewsStyleLine .mixNewsStyleImgBox1 {
    padding-right: 0.52rem;
}

.news_list_wrap .head_word_pic_text_list .lineBody .mixNewsStyleSummary {
    color: #999;
}
.isNewAddModule.news_list_wrap .head_word_pic_text_list .lineBody .mixNewsStyleSummary {
    color: #848e98;
}
.news_list_wrap .head_word_pic_text_list .mixNewsStyleTitleContainer .news_title {
    padding-left: 0;
}
</style>
