export class CountDown {
    constructor(params) {
        this.params = params;
        this.params.interval = params.interval || 1000;
        this.ms = 0;
        this.count = 0;
        this.count2 = 0;
        this.runStartTime = 0;
    }
    static parse(str) {
        if (/^\d{10}$/.test(str)) {
            return new Date(str * 1000);
        } else if (/^\d{13}$/.test(str)) {
            return new Date(str * 1);
        }
        str = CountDown.trim(str);
        var reg = /^(\d{4})-(\d{1,2})-(\d{1,2}) (\d{1,2}):(\d{1,2}):(\d{1,2})$/;
        var m = str.match(reg);
        if (m) {
            var year = m[1];
            var month = parseInt(m[2] - 1, 10);
            var day = parseInt(m[3], 10);
            var hour = parseInt(m[4], 10);
            var minutes = parseInt(m[5], 10);
            var seconds = parseInt(m[6], 10);
            return new Date(year, month, day, hour, minutes, seconds);
        } else {
            return '';
        }
    }
    static trim(str) {
        return str.replace(/(^\s*)|(\s*$)/g, '');
    }
    static getTimeObject(ms) {
        let ss = 1000;
        let mi = ss * 60;
        let hh = mi * 60;
        let dd = hh * 24;
        let day = parseInt(ms / dd);
        let hour = parseInt((ms - day * dd) / hh);
        let minute = parseInt((ms - day * dd - hour * hh) / mi);
        let second = parseInt((ms - day * dd - hour * hh - minute * mi) / ss);
        let milliSecond = ms - day * dd - hour * hh - minute * mi - second * ss;
        let strDay = day;
        let strHour = hour;
        let strMinute = minute;
        let strSecond = second;
        let strMilliSecond = milliSecond < 10 ? '0' + milliSecond : '' + milliSecond;
        strMilliSecond = milliSecond < 100 ? '0' + strMilliSecond : '' + strMilliSecond;

        let timeObj = {};

        timeObj.day = strDay;
        timeObj.hour = strHour;
        timeObj.minute = strMinute;
        timeObj.second = strSecond;
        timeObj.millisecond = strMilliSecond;

        return timeObj;
    }
    init() {
        let { params } = this;
        let { nowTime, endTime, interval } = params;
        let nowTimeObj = nowTime && CountDown.parse(nowTime);
        let endTimeObj = CountDown.parse(endTime);

        if (!nowTimeObj) {
            nowTimeObj = new Date();
        }

        if (!endTimeObj) {
            throw new Error('请输入截止时间');
        }
        this.ms = endTimeObj.getTime() - nowTimeObj.getTime();

        if (this.ms < 0) {
            this.ms = 0;
        }

        if (this.ms >= 0) {
            this.timeCounter = setTimeout(this.countDownStart.bind(this), interval);
        }

        this.runStartTime = new Date().getTime();
    }
    stopCountDown() {
        clearTimeout(this.timeCounter);
    }
    countDownStart() {
        let { params } = this;
        let { interval } = params;

        this.count++;
        let offset = new Date().getTime() - (this.runStartTime + this.count * interval);

        const MAX_DELAY = 10000;
        // 偏差过大,特殊修正
        if (offset > MAX_DELAY) {
            this.count2 = ~~(offset / interval);
            this.count = this.count + this.count2;
            this.ms = this.ms - this.count2 * interval;
        }

        let nextTime = interval - offset;
        if (nextTime < 0) {
            nextTime = 0;
        }

        this.ms -= interval;

        if (this.ms < 0) {
            clearTimeout(this.timeCounter);
            params.end && params.end();
        } else {
            params.oneTick && params.oneTick(this.ms);
            this.timeCounter = setTimeout(this.countDownStart.bind(this), nextTime);
        }
    }
}
