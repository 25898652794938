<template>
    <ModuleFrame :module-id="moduleId">
        <Entrance :module-id="moduleId"></Entrance>
    </ModuleFrame>
</template>

<script>
import ModuleFrame from '@/modules/frame/index.vue';
import Entrance from './Entrance.vue';

export default {
    name: 'CustomSearch',
    components: {
        ModuleFrame,
        Entrance,
    },
    props: {
        moduleId: {
            type: Number,
            required: true,
        },
    },
};
</script>

<style></style>
