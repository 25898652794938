<template>
    <div>
        <div :id="_listId" class="mProductList" :class="_listClass" :mType="module.content.t">
            <template v-for="(info, index) in productList">
                <pic-text-list-item :key="'p_' + info.id" :info="info" :index="index" :no-lazy-load="noLazyLoad" />
                <div v-if="!isNewAddModule" :key="'p1_' + info.id" class="g_separator separatorLine"></div>
            </template>
        </div>
    </div>
</template>

<script>
import PicTextListItem from './pic-text-list-item.vue';
import productMixin from '../../mixins/productMixin';
import { mapFlag } from '@/shared/flag';

export default {
    name: 'PicTextList',
    components: { PicTextListItem },
    mixins: [productMixin],
    inject: ['module', 'options'],
    props: ['productList', 'noLazyLoad'],
    computed: {
        ...mapFlag({
            hasEditTwoColumnStyle: 0x2,
            isNewAddModule: 0x4,
        }),
    },
};
</script>

<style></style>
