<template>
    <div class="text_panel">
        <a v-for="item in list" :key="item.id" :href="prUrl + '?groupId=' + item.id">
            <div class="text_item">
                <div class="text first_groupname g_productSort_name" :style="textStyle(item)">{{ item.name }}</div>
            </div>
        </a>
    </div>
</template>

<script>
export default {
    name: 'GroupText',
    inject: ['module', 'options'],
    props: {
        list: {
            type: Array,
            default: () => [],
        },
        prUrl: {
            type: String,
            default: '',
        },
    },
    mounted() {},
    methods: {
        textStyle() {
            let style = {};
            // 自定义图片尺寸时，文字宽度固定
            if (this.module.content.gps.t == 1) {
                style.width = `${this.module.content.gps.w}rem`;
            }

            if (!(VITE_APP_MODE !== 'visitor')) {
                return style;
            }

            var productSortName = this.module.content.mhighs.psn;
            if (productSortName.type == 1) {
                if (productSortName.nfs) {
                    style.fontSize = productSortName.nfs + 'rem';
                }

                if (productSortName.nfct == 1) {
                    style.color = productSortName.nfc;
                }
            }

            return style;
        },
    },
};
</script>

<style>
.fk-productGroup .text_panel {
    padding: 0 0.75rem;
}
.fk-productGroup .text_panel .text_item {
    height: 2rem;
    line-height: 2rem;
    border-bottom: 1px solid rgba(235, 235, 235, 1);
}
.fk-productGroup .text {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
