var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "item_type_input" }, [
    _c("input", {
      staticClass: "input_style u_custom_search_entry--input_line jz_focus",
      attrs: { maxlength: _vm.maxlength, placeholder: _vm.placeHolder },
      domProps: { value: _vm.value },
      on: { input: _vm.inputItemText },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }