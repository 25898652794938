var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("module-frame", { attrs: { "module-id": _vm.moduleId } }, [
    _c(
      "div",
      {
        staticClass: "fk-productGroup J_style",
        class: _vm.moduleClasses,
        attrs: { _style: _vm.moduleStyle },
      },
      [
        _vm.emptyShow
          ? _c("EmptyText", {
              attrs: {
                text: _vm.showText,
                "button-text": _vm.showButtonText,
                "manage-mode": _vm.manageMode,
              },
              on: { "add-click": _vm.handleEmptyClick },
            })
          : _vm.showFirstLevStyle
          ? [
              _vm.moduleStyle == 9
                ? _c("GridPhoto", {
                    attrs: {
                      list: _vm.firstLevList,
                      "no-lazy-load": _vm.noLazyLoad,
                      "pr-url": _vm.prUrl,
                      "res-root": _vm.resRoot,
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.moduleStyle == 10
                ? _c("GroupText", {
                    attrs: {
                      list: _vm.firstLevList,
                      "pr-url": _vm.prUrl,
                      "res-root": _vm.resRoot,
                    },
                  })
                : _vm._e(),
            ]
          : [
              _vm.showNav
                ? _c("ProductGroupNav", {
                    attrs: {
                      "module-id": _vm.moduleId,
                      "p-type": _vm.navSelType,
                      list: _vm.firstLevList,
                      "search-id": _vm.searchId,
                      "search-name": _vm.searchName,
                    },
                    on: { select: _vm.handleHasProductClick },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.showGroupPhotoPanel
                ? _c("ProductPhotoGroupingPanel", {
                    attrs: {
                      "module-id": _vm.moduleId,
                      "pr-url": _vm.prUrl,
                      list: _vm.photoGroupPanelList,
                      "catalog-name": _vm.catalogName,
                      "max-product-group": _vm.maxProductGroup,
                      "module-style": _vm.moduleStyle,
                      "res-root": _vm.resRoot,
                      "nav-index": _vm.navIndex,
                      "no-lazy-load": _vm.noLazyLoad,
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.showLev == 3 &&
              (_vm.moduleStyle == 5 ||
                _vm.moduleStyle == 6 ||
                _vm.moduleStyle == 7)
                ? [
                    _c("ProductPhotoPanel", {
                      attrs: {
                        "module-id": _vm.moduleId,
                        "pr-url": _vm.prUrl,
                        "module-style": _vm.moduleStyle,
                        "max-product-group": _vm.maxProductGroup,
                        "search-id": _vm.searchId,
                        "search-name": _vm.searchName,
                        "second-group-map": _vm.productPhotoPanelList,
                        "res-root": _vm.resRoot,
                        "nav-index": _vm.navIndex,
                        "no-lazy-load": _vm.noLazyLoad,
                      },
                    }),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  class: _vm.productListClass,
                  attrs: { id: "productList" + _vm.moduleId },
                },
                [
                  _vm.loading
                    ? _c("ProductGroupLoading", {
                        attrs: { height: _vm.grouplistHeight },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.showOldStyle
                    ? [
                        _vm.showProductItem
                          ? _c(
                              "div",
                              {
                                staticClass: "J_groupItem",
                                attrs: { gcn: _vm.catalogName },
                              },
                              [
                                _c("GroupBanner", {
                                  attrs: {
                                    "nav-index": _vm.navIndex,
                                    "no-lazy-load": _vm.noLazyLoad,
                                  },
                                }),
                                _vm._v(" "),
                                _vm.showLev == 1
                                  ? [
                                      !_vm.searchList ||
                                      _vm.searchList.length == 0
                                        ? _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "font-size": "0.6rem",
                                              },
                                            },
                                            [_vm._v(_vm._s(_vm.noProductText))]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm._l(
                                        _vm.catalogGroupList,
                                        function (item) {
                                          return _c("ProductItem", {
                                            key: item.id,
                                            attrs: {
                                              "module-id": _vm.moduleId,
                                              "mall-open": _vm.mallOpen,
                                              "booking-open": _vm.bookingOpen,
                                              "product-form-open":
                                                _vm.productFormOpen,
                                              "booking-btn-text":
                                                _vm.bookingBtnText,
                                              product: item,
                                              pn: _vm.product_name_style,
                                              "module-style": _vm.moduleStyle,
                                              "no-lazy-load": _vm.noLazyLoad,
                                            },
                                            on: { clickCart: _vm.handleCart },
                                          })
                                        }
                                      ),
                                    ]
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.showLev == 2
                                  ? [
                                      _vm._l(
                                        _vm.catalogGroupingListData,
                                        function (item) {
                                          return [
                                            _c("div", {
                                              key: item.name,
                                              staticClass: "group_name",
                                              domProps: {
                                                textContent: _vm._s(item.name),
                                              },
                                            }),
                                            _vm._v(" "),
                                            !_vm.searchList ||
                                            _vm.searchList.length == 0
                                              ? _c(
                                                  "div",
                                                  {
                                                    key: item.name,
                                                    staticStyle: {
                                                      "font-size": "0.6rem",
                                                      "margin-top": "0.2rem",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                    " +
                                                        _vm._s(
                                                          _vm.noProductText
                                                        ) +
                                                        "\n                                "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _vm._l(
                                              _vm.filterMaxProduct(
                                                item.catalogGroupItemList
                                              ),
                                              function (childItem) {
                                                return _c("ProductItem", {
                                                  key: childItem.id,
                                                  attrs: {
                                                    "module-id": _vm.moduleId,
                                                    "mall-open": _vm.mallOpen,
                                                    "booking-open":
                                                      _vm.bookingOpen,
                                                    "product-form-open":
                                                      _vm.productFormOpen,
                                                    "booking-btn-text":
                                                      _vm.bookingBtnText,
                                                    product: childItem,
                                                    pn: _vm.product_name_style,
                                                    "module-style":
                                                      _vm.moduleStyle,
                                                    "no-lazy-load":
                                                      _vm.noLazyLoad,
                                                  },
                                                  on: {
                                                    clickCart: _vm.handleCart,
                                                  },
                                                })
                                              }
                                            ),
                                          ]
                                        }
                                      ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            )
                          : _vm._e(),
                      ]
                    : _vm._e(),
                ],
                2
              ),
              _vm._v(" "),
              _vm.showOldStyle
                ? [
                    _vm.module.content.type == 2
                      ? [
                          _vm._l(_vm.catalogList, function (item, index) {
                            return _c("ProductGroupCatalog", {
                              key: index,
                              attrs: {
                                "item-data": item,
                                "module-id": _vm.moduleId,
                                "module-style": _vm.moduleStyle,
                                "lan-code": _vm.lanCode,
                                pn: _vm.product_sort_style,
                                "npgc-url": _vm.npgcUrl,
                              },
                              on: { itemClick: _vm.catalogProductItemClick },
                            })
                          }),
                          _vm._v(" "),
                          _c("div", {
                            staticClass: "g_separator separatorLine",
                          }),
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.module.content.type == 0
                      ? [
                          _vm._l(_vm.pgListV1, function (item) {
                            return _c("ProductGroupAll", {
                              key: item.id,
                              attrs: {
                                "item-data": item,
                                "module-style": _vm.moduleStyle,
                                "new-npgc-url": _vm.newNpgcUrl,
                                "pr-url": _vm.prUrl,
                                "module-id": _vm.moduleId,
                                pn: _vm.product_sort_style,
                              },
                              on: { itemClick: _vm.pgListLv1ItemClick },
                            })
                          }),
                          _vm._v(" "),
                          _c("div", {
                            staticClass: "g_separator separatorLine",
                          }),
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.module.content.type == 1
                      ? [
                          _vm._l(_vm.chooseMap, function (item, index) {
                            return _c(
                              "div",
                              {
                                key: index,
                                staticClass: "line j-lineH J_lineH icon-gline",
                              },
                              [
                                _c(
                                  "a",
                                  {
                                    staticClass: "pgName paNameFillWidth",
                                    attrs: {
                                      href:
                                        _vm.prUrl +
                                        "?mid=" +
                                        _vm.moduleId +
                                        "&groupId=" +
                                        item.id,
                                      target: "_self",
                                    },
                                  },
                                  [
                                    _c("span", {
                                      staticClass:
                                        "navLineTitle g_productSort_name",
                                      style: _vm.product_sort_style,
                                      domProps: {
                                        textContent: _vm._s(item.name),
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            )
                          }),
                          _vm._v(" "),
                          _c("div", {
                            staticClass: "g_separator separatorLine",
                          }),
                        ]
                      : _vm._e(),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.moduleStyle == 8
                ? [
                    _c("product-group-text-style", {
                      attrs: { "data-map": _vm.productGrouptextStyleData },
                      on: { itemClick: _vm.pgListLv1ItemClick },
                    }),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticStyle: { display: "none" },
                  attrs: { id: "productMallOptionPanel_mcs_" + _vm.moduleId },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "productMallOptionPanel",
                      attrs: { moduleId: _vm.moduleId },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "productMallOptionContentPanel" },
                        [
                          _c(
                            "div",
                            { staticClass: "productMallOptionContent" },
                            [
                              _c("div", {
                                staticClass:
                                  "productDetailClose icon-Off g_iconMiddle icon-defaultColor",
                                staticStyle: {
                                  cursor: "default",
                                  "z-index": "2",
                                },
                              }),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "proImgSwipe",
                  attrs: { id: "productCatagoryPdPanle" + _vm.moduleId },
                },
                [
                  _c("div", { staticClass: "proDetailImgBox" }, [
                    _c("div", { staticClass: "swipImgBox" }, [
                      _c("div", {
                        staticClass: "proDetailImg",
                        attrs: { vwidth: "450", vheight: "250" },
                      }),
                      _vm._v(" "),
                      _c("div", { staticStyle: { display: "none" } }, [
                        _c("img", { attrs: { id: "J_acct_pic" } }),
                      ]),
                    ]),
                  ]),
                ]
              ),
            ],
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }