<template>
    <module-frame :module-id="moduleId">
        <div v-if="manageMode && photoGroupListEmpty && render" class="empty_tips_panel">
            <div class="text">您还没有添加任何图册，赶紧添加吧~</div>
            <button-component active @click.stop="addPhotoGroup">添加图册</button-component>
        </div>
        <div v-else-if="manageMode && choicePhotoGroupEmpty && render" class="empty_tips_panel">
            <div class="text">请从右侧面板中选择图册~</div>
            <button-component active @click.stop="addPhotoGroup">添加图册</button-component>
            <button-component style="margin-left: 20px" @click.stop="openDesigner">选择图册</button-component>
        </div>
        <div
            v-if="!manageMode && photoGroupListEmpty && render"
            style="text-align: center; padding-top: 40px; padding-bottom: 40px"
        >
            <div>没有添加图册</div>
        </div>
        <div
            v-else-if="!manageMode && choicePhotoGroupEmpty && render"
            style="text-align: center; padding-top: 40px; padding-bottom: 40px"
        >
            <div>没有选择图册</div>
        </div>
        <div v-else-if="render" :id="`photoGroupAll${moduleId}`" :class="photoGroupAllClass">
            <div v-if="module.photoGroupList.length > 0" class="f-photoGroupAll-wrap J_photoGroupAll-wrap">
                <template v-if="!isNewStyle345">
                    <div
                        v-for="item in module.photoGroupList"
                        :id="`photoGroup${item.id}`"
                        :key="item.id"
                        :class="photoGroupClass"
                        :style="cusHeightStyle"
                    >
                        <a hidefocus="true" class="photoGroup-a" :style="cusLinkStyle">
                            <div
                                :id="`photoGroup${item.id}module${moduleId}`"
                                :alt="
                                    item.photoList[0] != null
                                        ? item.photoList[0].name != null
                                            ? item.photoList[0].name
                                            : item.photoList[0].id
                                        : ''
                                "
                                :datasrc="item.photoList[0] == null ? picDefaultThumPath : item.photoList[0].picPath"
                                :src-original="
                                    item.photoList[0] == null ? picDefaultThumPath : item.photoList[0].picPath
                                "
                                :title="item.name"
                                :style="photoImgStyle(item)"
                                :_groupid="item.id"
                                :fromMobi2="true"
                                class="photoGroup-img J_photoGroup-img J_img_lazyload"
                            >
                                <div :class="defCurtainClass" :style="cusCurtainStyle"></div>
                                <span
                                    class="photoGroup-title J_photoGroup-title"
                                    :style="cusTitleFontStyle"
                                    v-text="item.name"
                                ></span>
                                <span
                                    class="photoGroup-photoNum"
                                    :style="cusPhotoNumFontStyle"
                                    v-text="item.photoList.length + module.mobiPiece"
                                ></span>
                            </div>
                        </a>
                    </div>
                </template>
                <template v-else>
                    <div
                        v-for="item in module.photoGroupList"
                        :id="`photoGroup${item.id}`"
                        :key="item.id"
                        :class="photoGroupClass"
                    >
                        <a hidefocus="true" class="f-photoGroup-a J_photoGroup-a photoGroup-a" :style="cusLinkStyle">
                            <div
                                :id="`photoGroup${item.id}module${moduleId}`"
                                :alt="
                                    item.photoList[0] != null
                                        ? item.photoList[0].name != null
                                            ? item.photoList[0].name
                                            : item.photoList[0].id
                                        : ''
                                "
                                :datasrc="item.photoList[0] == null ? picDefaultThumPath : item.photoList[0].picPath"
                                :src-original="
                                    item.photoList[0] == null ? picDefaultThumPath : item.photoList[0].picPath
                                "
                                :title="item.name"
                                :style="photoImgStyle(item)"
                                :_groupid="item.id"
                                :fromMobi2="true"
                                class="f-photoGroup-img J_photoGroup-img photoGroup-img J_img_lazyload"
                            >
                                <span
                                    class="f-photoGroup-photoNum"
                                    :style="cusPhotoNumFontStyle"
                                    v-text="item.photoList.length + module.mobiPiece"
                                ></span>
                            </div>
                            <div class="f-photoGroup-descWrap">
                                <h3
                                    class="f-photoGroup-title photoGroup-title J_photoGroup-title"
                                    :style="cusTitleFontStyle"
                                    v-text="item.name"
                                ></h3>
                                <p v-if="showDetail" class="f-photoGroup-detail J_photoGroup-detail">
                                    <span class="f-photoGroup-detailText" v-html="item.groupDetail"></span>
                                </p>
                            </div>
                        </a>
                    </div>
                </template>
            </div>
        </div>
        <pagenation
            v-if="pageDisplayType && isStyle123 && !choicePhotoGroupEmpty"
            :module-id="moduleId"
            :total-size="module.totalSize"
            :page-size="module.content.pagePhotoSize"
            :pageno="module.pageno"
            :open-theme-v3="openThemeV3"
            :ajax-pagenation="true"
            @on-change="changePage"
            @click.native.stop=""
        >
        </pagenation>
    </module-frame>
</template>
<script>
import ModuleFrame from '@/modules/frame/index.vue';
import Pagenation from '@/components/pagenation.vue';
import { bindJumpModuleEvent, removeJumpModuleInfo } from '@/modules/comm';
import { bindImgLazyLoad } from '@/shared/imageLazyload';
import { mapGetters, mapState } from 'vuex';
import { linstenDocTouchMove, photoGroup } from '../photo-group';
import { bindTapEvent } from '@/shared/event';
import { getSiteMultiLanguageUrl } from '@/shared/url';
import { ing } from '@/shared/fai';
import popupWindow from '@/shared/popupWindow';

import { initPictureSlidesV4 } from '@/shared/photo/slides';
import { request } from '@/api/request';
export default {
    name: 'PhotoGroup',
    components: {
        Pagenation,
        ModuleFrame,
    },
    props: {
        moduleId: {
            type: Number,
            default: -1,
        },
    },
    data() {
        return {
            render: true,
            noLazyLoad: false,
            manageMode: VITE_APP_MODE !== 'visitor',
        };
    },
    computed: {
        ...mapGetters(['getModuleById']),
        ...mapState('app', ['openThemeV3']),
        module() {
            return this.getModuleById(this.moduleId);
        },
        options() {
            return this.module.renderOptions;
        },
        photoGroupStyle: function () {
            return this.module.content.style;
        },

        isNewStyle345: function () {
            return this.photoGroupStyle == 3 || this.photoGroupStyle == 4 || this.photoGroupStyle == 5;
        },

        isStyle123: function () {
            return this.photoGroupStyle == 1 || this.photoGroupStyle == 2 || this.photoGroupStyle == 3;
        },

        photoGroupAllClass: function () {
            return this.isNewStyle345
                ? [
                      'photoGroupAll',
                      'J_photoGroupAll',
                      'fk-photoGroupAll' + this.photoGroupStyle,
                      'J_photoGroupAll' + this.photoGroupStyle,
                  ]
                : ['photoGroupAll', 'J_photoGroupAll'];
        },

        photoGroupClass: function () {
            return this.isNewStyle345
                ? ['photoGroup', 'J_photoGroupStyle', 'fk-photoGroupStyle', 'fk-photoGroupStyle' + this.photoGroupStyle]
                : [
                      'photoGroup',
                      'J_photoGroupStyle',
                      'J_photoGroupStyle' + this.photoGroupStyle,
                      'fk-photoGroupStyle' + this.photoGroupStyle,
                  ];
        },

        photoGroupCatalog: function () {
            return this.module.content.photoGroupCatalog;
        },

        groupIdList: function () {
            return this.module.content.groupIdList;
        },

        groupSortIdList: function () {
            return this.module.content.groupSortIdList;
        },

        cusHeightStyle: function () {
            return this.module.content.coverHeightType == 1 ? { height: this.module.content.coverHeight + 'px' } : {};
        },

        borderType: function () {
            return this.module.content.borderType;
        },
        border: function () {
            return this.module.content.border;
        },

        titleFontType: function () {
            return this.module.content.titleFontType;
        },
        titleFont: function () {
            return this.module.content.titleFont;
        },

        radiusType: function () {
            return this.module.content.radiusType;
        },
        radius: function () {
            return this.module.content.radius;
        },

        shadowType: function () {
            return this.module.content.shadowType;
        },
        shadow: function () {
            return this.module.content.shadow;
        },

        curtainType: function () {
            return this.module.content.curtainType;
        },
        curtain: function () {
            return this.module.content.curtain;
        },

        photoNumFontType: function () {
            return this.module.content.photoNumFontType;
        },
        photoNumFont: function () {
            return this.module.content.photoNumFont;
        },

        detailDisplayType: function () {
            return this.module.content.detailDisplayType;
        },
        pageDisplayType: function () {
            return this.module.content.pageDisplayType;
        },

        cusCurtainStyle: function () {
            let obj = {};
            if (this.curtainType == 1) {
                obj.display = 'none';
            } else if (this.curtainType == 2) {
                obj.height = `${this.curtain.height}px`;
                if (this.curtain.color.indexOf('linear-gradient') != -1) {
                    obj.background = this.curtain.color;
                } else {
                    obj.backgroundColor = this.curtain.color;
                }
            }
            return obj;
        },

        cusTitleFontStyle: function () {
            let obj = {};
            if (this.titleFontType == 1) {
                if (this.titleFont.colorType == 1) {
                    obj.color = this.titleFont.color;
                }
                obj.fontSize = `${this.titleFont.size}px`;
            }
            return obj;
        },

        cusPhotoNumFontStyle: function () {
            let obj = {};
            if (this.module.content.photoGroupNumShow == false) {
                obj.display = 'none';
            } else if (this.photoNumFontType == 2) {
                if (this.photoNumFont.colorType == 1) {
                    obj.color = this.photoNumFont.color;
                }
                obj.fontSize = `${this.photoNumFont.size}px`;
            }
            return obj;
        },

        defCurtainClass: function () {
            return this.curtainType == 0 ? ['photoGroup-curtain', 'photoGroup-curtainDefault'] : ['photoGroup-curtain'];
        },

        showDetail: function () {
            if (this.detailDisplayType == 0) {
                if (this.noDetailNum.length == this.module.photoGroupList.length) {
                    return false;
                }
                return true;
            } else {
                return false;
            }
        },

        detailList: function () {
            if (this.module.photoGroupList != null) {
                return this.module.photoGroupList.map((item) => item.groupDetail);
            } else {
                return [];
            }
        },

        noDetailNum: function () {
            return this.detailList.filter((detail) => {
                return detail == this.module.mobiDefaultText;
            });
        },

        isLimitDisplay: function () {
            return this.photoGroupStyle == 1 || this.photoGroupStyle == 2 || this.photoGroupStyle == 3;
        },

        pagePhotoSize: function () {
            return this.module.content.pagePhotoSize;
        },

        photoGroupCount: function () {
            return this.module.content.photoGroupCount;
        },

        picDefaultThumPath: function () {
            return this.module.picDefaultThumPath;
        },

        clickPopup: function () {
            return this.module.content.clickPopup;
        },

        cusLinkStyle: function () {
            let obj = {};
            if (this.borderType == 1) {
                obj.border = 'none';
            } else if (this.borderType == 2) {
                obj.border = `${this.border.size}px solid ${this.border.color}`;
            }

            if (!this.isNewStyle345) {
                if (this.shadowType == 1) {
                    obj.boxShadow = 'none';
                } else if (this.shadowType == 2) {
                    if (this.shadow.color == null || this.shadow.color == '') {
                        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                        this.shadow.color = '#ccc';
                    }
                    obj.boxShadow = `${this.shadow.distanceX}px ${this.shadow.distanceY}px ${this.shadow.misty}px ${this.shadow.color}`;
                }
            }

            if (this.radiusType == 1) {
                obj.borderRadius = '0';
            } else if (this.radiusType == 2) {
                obj.borderRadius = `${this.radius.leftTop}px ${this.radius.rightTop}px ${this.radius.rightBottom}px ${this.radius.leftBottom}px`;
            }

            return obj;
        },

        photoGroupListEmpty: function () {
            return this.module.photoGroupCount == 0;
        },

        choicePhotoGroupEmpty: function () {
            return this.module.photoGroupList.length == 0;
        },
    },
    watch: {
        clickPopup: {
            handler: function () {
                this.bindEvent();
            },
        },

        photoGroupStyle: {
            handler: function () {
                this.ajaxGetPageData();
            },
        },
        'module.content.photoGroupCatalog': {
            handler: function () {
                this.ajaxGetPageData();
                this.$nextTick(() => {
                    this.bindEvent();
                });
            },
        },

        detailDisplayType: {
            handler: function () {
                this.$nextTick(() => {
                    this.bindEvent();
                });
            },
        },

        'module.photoGroupList': {
            handler: function () {
                this.render = false;
                this.$nextTick(() => {
                    this.render = true;
                    this.$nextTick(() => {
                        this.bindEvent();
                    });
                });
            },
        },

        'module.content.groupIdList': {
            handler: function () {
                this.ajaxGetPageData();
            },
            deep: true,
        },

        'module.content.pageDisplayType': {
            handler: function () {
                this.ajaxGetPageData();
            },
        },

        'module.content.pagePhotoSize': {
            handler: function () {
                this.ajaxGetPageData();
            },
        },

        'module.content.photoGroupCount': {
            handler: function () {
                this.ajaxGetPageData();
            },
        },

        'module.content.groupSortIdList': {
            handler: function () {
                if (VITE_APP_MODE !== 'visitor') {
                    this.ajaxGetPageData();
                }
            },
            deep: true,
        },
        'options.isFirstScreen': {
            handler(val) {
                this.noLazyLoad = val;
            },
        },
    },
    mounted() {
        photoGroup.init();
        jm(linstenDocTouchMove);
        this.$nextTick(() => {
            this.bindEvent(true);
            removeJumpModuleInfo(this.module.id);
        });
    },
    methods: {
        changePage(val) {
            this.ajaxGetPageData(val);
        },

        photoImgStyle(item) {
            let style = {};
            let photoGroupPic = item.photoList[0] == null ? this.picDefaultThumPath : item.photoList[0].picPath;
            style.backgroundImage = `url(${this.noLazyLoad ? photoGroupPic : this.options.loadingPath})`;
            return style;
        },

        bindEvent(init) {
            let context = this;
            let dom = '#module' + context.moduleId;
            let $module = jm(`#photoGroupAll${context.moduleId}`);
            if (!init) {
                this.noLazyLoad = true;
            }

            this.$nextTick(() => {
                if (photoGroup != null && (this.photoGroupStyle == 4 || this.photoGroupStyle == 5)) {
                    photoGroup.pokerSlider.destory(jm(dom));
                    photoGroup.zoomSlider.destory(jm(dom));
                    // 去除样式4之前留下dom相关属性
                    jm(dom).find('.f-photoGroupAll-wrap').removeAttr('style');
                    jm(dom).find('.J_photoGroupStyle').removeClass('f-active');

                    if (this.photoGroupStyle == 4) {
                        photoGroup.zoomSlider.create(jm(dom));
                    } else if (this.photoGroupStyle == 5) {
                        photoGroup.pokerSlider.create(jm(dom));
                        // 如果没有打开详情的话，高度小一点
                        let $wraper = jm(dom).find('.J_photoGroupAll-wrap');
                        let $first = $wraper.find('.J_photoGroupStyle').find('.J_photoGroup-a');
                        let height = $first.height() + 'px';

                        if (!this.showDetail) {
                            $wraper.css({
                                height: height,
                                transform: 'translateY(0.725rem)',
                            }); //修正垂直距离
                        }
                    }
                    // }
                } else {
                    // 移除从样式4/5切换到1/2/3的事件
                    jm(dom).find('.f-photoGroupAll-wrap').removeAttr('style');
                    jm(dom).find('.J_photoGroup-img-mask').remove();
                    photoGroup.pokerSlider.destory(jm(dom));
                    photoGroup.zoomSlider.destory(jm(dom));
                }

                // 如果是幻灯片，则进行初始化幻灯片
                if (context.clickPopup == 1) {
                    // 移除href属性
                    $module.find('.photoGroup-a').each(function (index, el) {
                        jm(el).removeAttr('href');
                    });

                    context.module.photoGroupList.forEach((group) => {
                        if (group.photoList != null) {
                            let photoJson = group.photoList.map((photo) => {
                                let obj = {};
                                obj.width = photo.width || 0;
                                obj.height = photo.height || 0;
                                obj.src = photo.picPath;
                                obj.title = photo.name == null ? '' : photo.name;
                                obj.desc = photo.basic;
                                obj.detail = photo.mobiDetail == 1 ? true : false;
                                obj.groupId = photo.groupId == '' ? 0 : photo.groupId;
                                obj.pid = photo.id == '' ? 0 : photo.id;
                                obj.detailContent = photo.detailContent || '';
                                return obj;
                            });
                            if (photoJson.length > 0) {
                                setTimeout(() => {
                                    initPictureSlidesV4(
                                        `#photoGroupAll${context.moduleId} #photoGroup${group.id}`,
                                        photoJson,
                                        {
                                            captionDetailClass: 'caption_detail-photoGroup',
                                            contentTerminal: 'mobi-图片目录-图片',
                                        }
                                    );
                                }, 0);
                            }
                        }
                    });
                } else {
                    // 图片详情，跳转，通过jq添加href属性
                    // :href='`por.jsp?id=${item.id}`'
                    context.module.photoGroupList.forEach((group) => {
                        bindTapEvent(`#photoGroupAll${context.moduleId} #photoGroup${group.id}`, 'tap', function () {});
                    });

                    $module.find('.photoGroup-a').each(function (index, el) {
                        let temp = context.module.photoGroupList[index];
                        if (temp != null) {
                            jm(el).attr('href', `${temp.href}`);
                        }
                    });

                    // 图片链接才进行存储分页信息
                    bindJumpModuleEvent(context.module.id, context.module.style, { pageno: context.module.pageno });
                }

                if (VITE_APP_MODE !== 'visitor' && this.options.designMode) {
                    photoGroup.bindInteractive(jm(dom));
                }
                jm(dom).find('.empty_tips_panel').removeAttr('style');

                if (!context.noLazyLoad) {
                    bindImgLazyLoad(`module${context.module.id}`);
                }
            });
        },

        ajaxGetPageData(page = 1, isManage = false) {
            let self = this;
            let paramData = {
                groupIdList: JSON.stringify(this.photoGroupCatalog == 0 ? this.groupIdList : this.groupSortIdList),
                pageno: page,
                size: this.pageDisplayType == 0 ? this.photoGroupCount : this.pagePhotoSize,
                catalog: this.photoGroupCatalog,
                isPageDisplay: this.pageDisplayType,
                moduleStyle: this.photoGroupStyle,
            };
            if (isManage) {
                paramData.isManageMode = isManage;
            }

            request
                .post('/ajax/module_h.jsp?cmd=getWafNotCk_getPhotoGroupListData', {
                    data: paramData,
                })
                .catch(function () {
                    if (VITE_APP_MODE !== 'visitor') {
                        Fai.top.Fai.ing('系统繁忙，请稍候重试', false);
                    } else {
                        ing('系统繁忙，请稍候重试', false);
                    }
                })
                .then(function (response) {
                    let { data } = response;
                    if (data.success) {
                        self.module.totalSize = data.totalSize;
                        self.module.photoGroupCount = data.photoGroupCount;
                        self.module.pageno = parseInt(page);
                        self.module.photoGroupList = data.groupList;
                        if (isManage) {
                            self.module.photoGroupSortList = data.photoGroupSortList;
                        }
                    } else {
                        if (VITE_APP_MODE !== 'visitor') {
                            Fai.ing('系统繁忙，请稍候重试', false);
                        } else {
                            ing('系统繁忙，请稍候重试', false);
                        }
                    }
                });
        },

        openDesigner(event, noAnimation = false) {
            if (VITE_APP_MODE !== 'visitor') {
                this.$designer.open({
                    panelType: 'pattern photo-group basis',
                    moduleId: this.module.id,
                    styleId: this.module.style,
                    noAnimation,
                });
            }
        },

        addPhotoGroup() {
            popupWindow({
                title: '添加图册',
                frameSrcUrl:
                    getSiteMultiLanguageUrl() +
                    `/manage_v2/photoGroupEdit.jsp?_pmid=${
                        this.module.id
                    }&src=1&mobiSettingState=1&ram=${Math.random()}&fromMobi2=true`,
                width: '860',
                height: '682',
                saveBeforePopup: true,
                version: 2,
            });
        },
    },
};
</script>
<style>
/*图册目录模块*/
.photoGroupAll {
    overflow: hidden;
    padding: 0.5rem;
}
.photoGroup {
    height: 8rem;
    margin-top: 2%;
}
.photoGroupAll .photoGroup .photoGroup-a {
    width: 100%;
    height: 100%;
    display: block;
    position: relative;
    color: #ffffff;
    font-family: 微软雅黑;
    overflow: hidden;
    z-index: 3;
    box-sizing: border-box;
    text-align: center;
}
.photoGroup-img {
    width: 100%;
    height: 100%;
    vertical-align: middle;
    background-color: #ffffff;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.photoGroup-curtain {
    width: 100%;
    position: absolute;
    bottom: 0;
    z-index: 1;
}
.photoGroup-curtainDefault {
    background-color: rgba(0, 0, 0, 0.4);
    height: 1.3rem;
}

.photoGroup-FontDiv {
    width: 100%;
    position: absolute;
    bottom: 0;
    z-index: 2;
}
.photoGroup-title {
    text-align: left;
    width: 80%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: absolute;
    left: 2%;
    bottom: 2%;
    z-index: 2;
    font-size: 0.7rem;
}
.photoGroup-photoNum {
    text-align: right;
    width: 34%;
    position: absolute;
    bottom: 2%;
    right: 2%;
    z-index: 2;
    font-size: 0.6rem;
}

.fk-photoGroupStyle2 {
    float: left;
    width: 49%;
}
.fk-photoGroupStyle2:nth-child(1) {
    margin-top: 0;
}
.fk-photoGroupStyle2:nth-child(2) {
    margin-top: 0;
}
.fk-photoGroupStyle2:nth-child(2n + 2) {
    margin-left: 2%;
}

.fk-photoGroupStyle1:nth-child(1) {
    margin-top: 0;
}

/*图册目录样式3、4、5 by jser 2017-5-26*/
.fk-photoGroupStyle {
    width: 100%;
    height: auto;
}
.fk-photoGroupStyle .f-photoGroup-a {
    display: block;
    border: solid #e6e6e6 1px;
    background: #fff;
    user-select: none;
}
.fk-photoGroupStyle .f-photoGroup-descWrap {
    padding: 0 0.9rem;
}
.fk-photoGroupStyle .f-photoGroup-title {
    position: static;
    display: block;
    width: auto;
    height: 2rem;
    line-height: 2rem;
    font-size: 0.7rem;
    color: #666;
    text-align: center;
    font-weight: normal;
}
.fk-photoGroupStyle .f-photoGroup-detail {
    border-top: solid #ededed 1px;
    padding: 0.8rem 0;
    line-height: 0.8rem;
    font-size: 0.6rem;
    color: #999;
    white-space: nowrap; /*中文强制换行*/
    text-align: left;
    box-sizing: border-box;
}
.fk-photoGroupStyle .f-photoGroup-detailText {
    height: 1.6rem;
    display: block;
    overflow: hidden; /*在不支持盒子模型时，默认指定block*/
    text-overflow: ellipsis;
}
.fk-photoGroupStyle .f-photoGroup-img {
    height: 8.63rem;
    position: relative;
}
.fk-photoGroupStyle .f-photoGroup-photoNum {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1.1rem;
    line-height: 1.1rem;
    background: rgba(0, 0, 0, 0.2);
    font-size: 0.6rem;
    text-align: right;
    color: #fff;
}
.fk-photoGroupAll4 .f-photoGroup-img-mask,
.fk-photoGroupAll5 .f-photoGroup-img-mask {
    position: absolute;
    left: 0;
    top: 0;
    zindex: 1;
    width: 100%;
}
.fk-photoGroupAll3 .f-photoGroup-a,
.fk-photoGroupAll4 .f-photoGroup-a,
.fk-photoGroupAll5 .f-photoGroup-a {
    border: solid #e6e6e6 1px;
}
.fk-photoGroupAll3 .f-photoGroup-photoNum,
.fk-photoGroupAll4 .f-photoGroup-photoNum,
.fk-photoGroupAll5 .f-photoGroup-photoNum {
    /*样式3、4、5统一数值文本的间隙*/
    box-sizing: border-box;
    padding-right: 0.25rem;
}
/*样式3*/
.fk-photoGroupAll3 .fk-photoGroupStyle {
    margin: 1rem 0.5rem;
    width: auto;
    margin-bottom: 0;
}
.fk-photoGroupAll3 .f-photoGroupAll-wrap {
    padding-bottom: 0.5rem;
}
.fk-photoGroupAll3 .fk-photoGroupStyle:first-child {
    margin-top: 0.5rem;
}
/*样式4*/
.fk-photoGroupAll4 .f-photoGroup-a {
    box-sizing: border-box;
    width: 14.5rem;
}
.fk-photoGroupAll4 .f-photoGroupAll-wrap {
    font-size: 0;
    width: 14.5rem;
    max-height: 15rem;
    padding: 0.5rem 1rem 0.5rem 1rem;
    white-space: nowrap;
    transition: transform 0.3s;
    box-sizing: border-box;
}
.fk-photoGroupAll4 .fk-photoGroupStyle {
    display: inline-block;
    vertical-align: middle;
    width: 12.5rem;
    font-size: 0.6rem;
    margin-top: 0rem;
    box-sizing: border-box;
    transform-origin: center center;
    transform: scale(0.86, 0.86);
}
.fk-photoGroupAll4 .f-small {
    transition: transform 0.3s;
}
.fk-photoGroupAll4 .f-active {
    transform: scale(1, 1);
}
/*样式5*/
.fk-photoGroupAll5 .fk-photoGroupStyle {
    display: none;
    width: 13.5rem;
    max-height: 14.5rem;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: block;
    margin: auto;
    z-index: 1;
    transition: transform 0.3s;
    transform-origin: center center;
}
.fk-photoGroupAll5 .f-photoGroupAll-wrap {
    height: 15.2rem;
    position: relative;
    width: auto;
    padding: 0.5rem 0 0.75rem 0;
    user-select: none;
}
.fk-photoGroupAll5 .f-photoGroup-img {
    height: 9.55rem;
}
.fk-photoGroupAll5 .fk-photoGroupStyle {
    display: none;
}
.fk-photoGroupAll5 .fk-photoGroupStyle:nth-child(1) {
    z-index: 4;
    transform: translate(0, -0.325rem);
    display: block;
} /*.a.a.a是加强选择器的优先级别，和!importan作用相似，但不会影响行内style，不可滥用，这里仅仅为了实现过渡的效果*/
.fk-photoGroupAll5 .f-toOne.f-toOne.f-toOne {
    z-index: 3;
    transform: translate(0, -0.325rem);
    width: 13.5rem;
}
.fk-photoGroupAll5 .fk-photoGroupStyle:nth-child(2) {
    transform: translate(0, 0);
    width: 12.9rem;
    z-index: 2;
    display: block;
}
.fk-photoGroupAll5 .f-toTwo.f-toTwo.f-toTwo {
    transform: translate(0, 0);
    width: 12.9rem;
    z-index: 2;
}
.fk-photoGroupAll5 .fk-photoGroupStyle:nth-child(3) {
    transform: translate(0, 0.325rem);
    width: 12.3rem;
    z-index: 1;
    display: block;
}
.fk-photoGroupAll5 .f-toThree.f-toThree.f-toThree {
    transform: translate(0, 0.325rem);
    width: 12.3rem;
    z-index: 1;
}
.fk-photoGroupAll5 .fk-photoGroupStyle:nth-child(4) {
    transform: translate(0, 0.325rem);
    width: 12.3rem;
    z-index: 1;
    display: block;
} /*实现第四张卡片被抽出的效果*/
.fk-photoGroupAll5 .f-toLeft.f-toLeft.f-toLeft {
    transform: translate(-150%, 0) rotate(-10deg);
} /*左切换*/
.fk-photoGroupAll5 .f-toRight.f-toRight.f-toRight {
    transform: translate(150%, 0) rotate(10deg);
} /*右切换*/
.fk-photoGroupAll5 .fk-photoGroupStyle .photoGroup-a {
    height: auto;
    border-radius: 0.2rem;
}

.ps-active {
    overflow-y: hidden !important; /*position:fixed;*/
}
</style>
