<template>
    <div class="item_type_radio">
        <ul class="radio_style">
            <li
                v-for="item in options"
                :key="item"
                class="radio_item J_noOpenDesigner"
                :class="{ radio_active: currentVal === item }"
                @click="selectItem(item)"
            >
                <span class="radio_inner"></span>
                <span class="radio_text">{{ item }}</span>
            </li>
        </ul>
    </div>
</template>

<style lang="scss"></style>

<script>
export default {
    name: 'CustomSearchRadio',
    props: {
        options: Array,
        value: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            currentVal: '',
        };
    },
    methods: {
        selectItem: function (item) {
            this.currentVal = item;
            this.$emit('input', item);
        },
    },
};
</script>
