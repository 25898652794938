<template>
    <div
        :id="'newsLine' + info.id"
        :topClassName="_topClassName"
        :topSwitch="_topSwitch"
        :newsId="info.id"
        :newsName="info.title"
        :class="wrapClasses"
    >
        <a
            class="lineBodyLink"
            hidefocus="true"
            :href="_newsUrl"
            :target="_jumpTarget"
            style="width: auto"
            :class="[isNewAddModuleClass, isOnlyTitle]"
            :onclick="onclickStr"
        >
            <div :id="'lineBody' + info.id" class="lineBody lineBodyPic">
                <div class="articleFlexWrap">
                    <div class="picAndTextFlex">
                        <template v-if="module.content.tPosition == 1">
                            <span
                                v-if="_topFlagClass != '' && !isNewAddModule"
                                class="newStyleTop"
                                :class="topFlagClass"
                            ></span>
                            <div
                                key="wrap1"
                                class="J_img_wrap"
                                :class="[imgWrapClasses, noMargin]"
                                :style="imgWrapStyle"
                            >
                                <div
                                    :id="'newsImg' + info.id"
                                    v-drag-resize="_resizeOptions"
                                    :alt="altName"
                                    :class="picTextClasses"
                                    :src-original="info.realPicId ? info.pic160Path : ''"
                                    :style="picItemStyle"
                                    class="picRadiusClass picScaleClass J_img_lazyload"
                                ></div>
                            </div>
                            <div key="textFlexWrap" class="textFlexWrap" :class="desClasses">
                                <div class="felxTextItem">
                                    <div
                                        class="twoLine g_link articleTitle g_news_title newTitle"
                                        :style="news_title_style"
                                        :class="[titleClasses, titleLine2]"
                                    >
                                        <top-component v-if="_topFlagClass != '' && isNewAddModule"></top-component
                                        >{{ info.title }}
                                    </div>
                                </div>
                                <div v-if="!module.content.des" class="felxTextItem">
                                    <div
                                        class="twoLine articleSummray g_text description g_news_abstract newDescriptionLine newDescriptionLine"
                                        :style="news_abstract_style"
                                        :class="[descriptionLine, isOnlyTitleAndDes]"
                                        >{{ info.summary }}</div
                                    >
                                </div>
                                <new-info v-if="module.content.tp == 2" :info="info" :index="index" />
                            </div>
                        </template>
                        <template v-else>
                            <div key="textFlexWrap" class="textFlexWrap" :class="desClasses">
                                <div class="felxTextItem">
                                    <span
                                        v-if="_topFlagClass != '' && !isNewAddModule"
                                        class="newStyleTop"
                                        :class="topFlagClass"
                                    ></span>
                                    <div
                                        class="twoLine g_link articleTitle g_news_title newTitle"
                                        :style="news_title_style"
                                        :class="[titleClasses, titleLine2]"
                                    >
                                        <top-component v-if="_topFlagClass != '' && isNewAddModule"></top-component
                                        >{{ info.title }}
                                    </div>
                                </div>
                                <div v-if="!module.content.des" class="felxTextItem">
                                    <div
                                        :style="news_abstract_style"
                                        class="twoLine articleSummray g_text description g_news_abstract newDescriptionLine newDescriptionLine"
                                        :class="[descriptionLine, isOnlyTitleAndDes]"
                                        >{{ info.summary }}</div
                                    >
                                </div>
                                <new-info v-if="module.content.tp == 2" :info="info" :index="index" />
                            </div>
                            <div
                                key="wrap2"
                                class="J_img_wrap"
                                :class="[imgWrapClasses, noMargin]"
                                :style="imgWrapStyle"
                            >
                                <div
                                    :id="'newsImg' + info.id"
                                    v-drag-resize="_resizeOptions"
                                    :alt="altName"
                                    :class="picTextClasses"
                                    :src-original="info.realPicId ? info.pic160Path : ''"
                                    :style="picItemStyle"
                                    class="picRadiusClass picScaleClass J_img_lazyload"
                                ></div>
                            </div>
                        </template>
                    </div>
                    <new-info v-if="module.content.tp == 1" :info="info" :index="index" />
                </div>
            </div>
        </a>
    </div>
</template>

<script>
import { mapFlag } from '@/shared/flag';
import NewInfo from './new-info.vue';
import TopComponent from '../topComponent/top-component.vue';
import ListItem from '../../mixins/ListItem';

export default {
    name: 'HeadPicItem',
    components: { NewInfo, TopComponent },
    mixins: [ListItem],
    inject: ['module', 'options', 'altName', 'ndUrl'],
    props: {
        info: {
            type: Object,
            default: function () {
                return {};
            },
        },
        index: { type: Number, default: 0 },
    },
    data() {
        return {
            imgStyle: `clip-path:url(#clippath${this.module.id}${this.index}`,
        };
    },
    computed: {
        ...mapFlag(
            {
                cusNewsListPic: 0x8,
            },
            'info.flag'
        ),
        ...mapFlag(
            {
                isNewAddModule: 0x4,
            },
            'module.flag'
        ),
        isNewAddModuleClass() {
            return this.isNewAddModule ? ' isNewAddModule' : '';
        },
        wrapClasses() {
            return `${this._lineClass}One ${this._topFlagLineClass} articleStyle2 icon-gline line newsLine J_picNum`;
        },
        titleClasses() {
            if (this.module.content.des) {
                return `${this.onlyTitle} ${this.titleLine}`;
            } else {
                return `${this.titleLine}`;
            }
        },
        titleLine() {
            return this.module.content.sl ? 'titleLine' : 'noTitleLine';
        },
        newsDate() {
            return this._dateFormate(new Date(this.info.date), 'yyyy/MM/dd');
        },
        picTextClasses() {
            return this.info.realPicId === '' ? 'newsLeftImg icon-noFigureS' : 'newsLeftImg';
        },
        noMargin() {
            return this.module.content.tp == 2 ? 'noMargin' : '';
        },
        divSize() {
            const tSize = this.module.content.tSize;
            const tSizeType = tSize.picSizeType;
            let divWidth = 160 / 40;
            let divHeight = 120 / 40;
            if (tSizeType == 2) {
                divWidth = (tSize.picWidth || 160) / 20;
                divHeight = (tSize.picHeight || 120) / 20;
            }
            return { divWidth, divHeight };
        },
        imgWrapStyle() {
            return {
                width: `${this.divSize.divWidth}rem`,
                height: `${this.divSize.divHeight}rem`,
            };
        },
        picItemStyle() {
            let picStyle = {
                width: `${this.divSize.divWidth}rem`,
                height: `${this.divSize.divHeight}rem`,
            };
            if (this.info.realPicId) {
                picStyle.background = `url(${
                    this.noLazyLoad ? this.info.pic160Path : this.options.loadingPath
                }) no-repeat center /cover`;
            }
            return picStyle;
        },
        showOtherInfo() {
            return this._showDate || this._showSort || this._showAuthor || this._showComment;
        },
        desClasses() {
            return this.showOtherInfo && this.module.content.des ? 'hasSettingNoDescription' : '';
        },
        onlyTitle() {
            return !this.showOtherInfo && this.module.content.des ? 'onlyTitle' : '';
        },
        descriptionLine() {
            const descriptionLine = this.module.content.dsl;
            const textPosClass = this.module.content.tp == 2 ? 'newsTextPosition2' : '';
            var desClass = '';
            switch (descriptionLine) {
                case 0:
                    desClass = 'defaultDescriptionLine';
                    break;
                case 1:
                    desClass = 'descriptionLine';
                    break;
                case 2:
                    desClass = 'noDescriptionLine';
                    break;
            }
            return `${textPosClass} ${desClass}`;
        },
        titleLine2() {
            const descriptionLine = this.module.content.tsl;
            var desClass = '';
            switch (descriptionLine) {
                case 0:
                    desClass = 'defaultDescriptionLine';
                    break;
                case 1:
                    desClass = 'descriptionLine';
                    break;
                case 2:
                    desClass = 'noDescriptionLine';
                    break;
            }
            return ` ${desClass}`;
        },
        imgWrapClasses() {
            return this.module.content.tPosition == 2 ? 'picTableCell imgRight' : 'imgLeft';
        },
        topFlagClass() {
            return `${this._topFlagClass} ${this.onlyTitle}`;
        },
    },
};
</script>

<style></style>
