<template>
    <div class="m_mobi_form_text_descrition_wrap">
        <div class="headline" :style="headlineStyle"><div v-html="headlineText"></div></div>
        <div class="subHeadline" :style="subHeadlineStyle"><div v-html="sublineText"></div></div>
        <div v-if="manageMode && empty" class="empty"></div>
    </div>
</template>

<script>
import { fontStyle } from '../utils';
import Mixins from '../mixins';
export default {
    name: 'FormSelect',
    mixins: [Mixins],
    inject: ['module', 'options'],
    props: {
        formItem: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            manageMode: VITE_APP_MODE !== 'visitor',
        };
    },
    computed: {
        headlineStyle() {
            return fontStyle(this.module.content.mhighs.tmn);
        },
        subHeadlineStyle() {
            return fontStyle(this.module.content.mhighs.tsn);
        },
        headlineText() {
            return this.encodeHtml(this.formItem.headline);
        },
        sublineText() {
            return this.encodeHtml(this.formItem.input);
        },
        empty() {
            return this.sublineText.trim() === '' && this.headlineText.trim() === '';
        },
    },
    methods: {
        validate() {
            return true;
        },
    },
};
</script>

<style>
.m_mobi_form_text_descrition_wrap .headline {
    margin-bottom: 0.45rem;
}
.m_mobi_form_text_descrition_wrap .subHeadline {
    color: rgba(153, 153, 153, 1);
    line-height: 1.15rem;
}
.m_mobi_form_text_descrition_wrap .empty {
    height: 0.5rem;
}
</style>
