<template>
    <div :id="_listId" class="mProductList styleForm7" :class="_listClass" :mType="module.content.t">
        <div :id="'productSwipe' + module.id" class="swipe productSwipe">
            <div class="productSwipeContainer">
                <div
                    v-for="(group, idx) in list"
                    :id="'productSwipeItem' + (2 * idx + 1)"
                    :key="'group_' + idx"
                    class="productSwipeItem"
                >
                    <div class="productSwipeDiv">
                        <template v-for="(info, index) in group">
                            <two-column-item
                                :key="'p_' + info.id"
                                :info="info"
                                :index="index"
                                :style="marginStyle(index)"
                                :class="needMargin(index)"
                                :no-lazy-load="noLazyLoad"
                            />
                        </template>
                        <div
                            v-if="needBlankDiv(idx)"
                            class="blankDiv productCrossedSlide"
                            :style="marginStyle(1)"
                            :class="needMargin(1)"
                        >
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="productList.length > 2" id="productBullet" class="productBullet">
                <ul id="bullets" class="bullets">
                    <li v-for="(group, idx) in list" :key="'bullet_' + idx" :class="{ on: idx == 0 }"></li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import TwoColumnItem from './two-column-item.vue';
import productMixin from '../../mixins/productMixin';
export default {
    name: 'TwoColumnList',
    components: { TwoColumnItem },
    mixins: [productMixin],
    inject: ['module', 'options'],
    props: ['productList', 'noLazyLoad'],
    computed: {
        list: function () {
            // 把 productList [1,2,3,4,5] 分成 [[1, 2], [3,4], [5]]
            let list = [];
            const data = this.productList;
            const chunk = 2; //每2个分一组
            for (let i = 0, j = data.length; i < j; i += chunk) {
                list.push(data.slice(i, i + chunk));
            }
            return list;
        },
    },
    mounted() {},
    methods: {
        needBlankDiv(index) {
            var listLength = this.list.length;
            return this.isNewAddModule && index == listLength - 1 && this.list[listLength - 1].length % 2 != 0;
        },
        marginStyle(index) {
            var marginStyle = {};
            if (index % 2 == 0) return;
            var proMarginType = this.module.content.proMargin.proMarginType;
            var proMarginX = this.module.content.proMargin.proMarginX;
            if (proMarginType == 1) {
                marginStyle.marginLeft = proMarginX + 'px';
            }
            return marginStyle;
        },
        needMargin: function (index) {
            return index % 2 != 0 ? 'needMarginClass' : '';
        },
    },
};
</script>

<style></style>
