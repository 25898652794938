var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._l(_vm.newsList, function (info, index) {
        return [
          _c("horizon-slide-item", {
            key: "n_" + info.id,
            attrs: { info: info, index: index, "no-lazy-load": _vm.noLazyLoad },
          }),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }