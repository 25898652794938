var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "photoModule newCarouselMultiPhoto styleForm9",
      class: _vm.photoSwipeCls,
      attrs: { id: "photoModule" + _vm.module.id },
    },
    [
      _c(
        "div",
        {
          staticClass: "photoSwipe",
          attrs: { id: "photoSwipe" + _vm.module.id },
        },
        [
          _c(
            "div",
            {
              ref: "photoSwipeBox",
              staticClass: "photoSwipeBox",
              class: _vm.photoSwipeReset,
              style: _vm.photoSwipeBoxStyle,
              attrs: { id: "photoSwipeBox" + _vm.module.id },
              on: {
                touchstart: _vm.touchstart,
                touchmove: _vm.touchmove,
                touchend: _vm.touchend,
              },
            },
            [
              _vm._l(_vm.cycleList, function (item, index) {
                return _c("new-carousel-image-item", {
                  key: item.picId,
                  style: _vm.itemStyle,
                  attrs: {
                    item: item,
                    "item-index": index,
                    "outer-box": _vm.content.td == 0,
                    "text-pos": _vm.content.itp,
                    "no-lazy-load": _vm.noLazyLoad,
                  },
                })
              }),
              _vm._v(" "),
              _c("new-carousel-image-item", {
                style: _vm.itemStyle,
                attrs: {
                  item: _vm.cycleList[0],
                  "item-index": 0,
                  "outer-box": _vm.content.td == 0,
                  "text-pos": _vm.content.itp,
                  "no-lazy-load": _vm.noLazyLoad,
                },
              }),
              _vm._v(" "),
              _vm.cycleList.length > 1
                ? _c("new-carousel-image-item", {
                    style: _vm.itemStyle,
                    attrs: {
                      item: _vm.cycleList[1],
                      "item-index": -1,
                      "outer-box": _vm.content.td == 0,
                      "text-pos": _vm.content.itp,
                      "no-lazy-load": _vm.noLazyLoad,
                    },
                  })
                : _vm._e(),
            ],
            2
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }