var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("module-frame", { attrs: { "module-id": _vm.moduleId } }, [
    _c("div", { staticClass: "wx_addFans" }, [
      !_vm.isCustomizedIMG
        ? _c("svg", { staticClass: "default_icon icon", style: _vm.imgObj }, [
            _c("use", { attrs: { "xlink:href": "#jzm-v42" } }),
          ])
        : _c("div", {
            staticClass: "default_img icon J_img_lazyload",
            style: _vm.customImgObj,
            attrs: { "src-original": _vm.imgSrc },
          }),
      _vm._v(" "),
      _c("div", { staticClass: "account_information" }, [
        _vm.isShowName
          ? _c("span", {
              staticClass: "account_information_name",
              style: _vm.nameStyle,
              domProps: { innerHTML: _vm._s(_vm.name) },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.isShowAccount
          ? _c("span", {
              staticClass: "account_information_account",
              style: _vm.accountStyle,
              domProps: { innerHTML: _vm._s(_vm.account) },
            })
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("div", {
        ref: "btn",
        staticClass: "add_button g_main_bgColor_v3 g_oldThemeBgColor",
        attrs: { id: "wx_addFans" + _vm.module.id },
        domProps: { innerHTML: _vm._s(_vm.btnText) },
        on: { click: _vm.jump },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }