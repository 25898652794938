<template>
    <div class="line icon-gline j-lineH J_lineH">
        <div
            v-if="(moduleStyle == 0 || moduleStyle == 8) && item.hasChildren"
            class="pgLinkWrap clearFix"
            @click="pgListLv1ItemClick"
        >
            <div class="pgName g_productSort_name" :style="pn" v-text="item.name"></div>
            <div class="pgMark"></div>
        </div>
        <a
            v-if="moduleStyle != 8 && moduleStyle != 0 && item.hasChildren"
            class="pgName"
            :href="newNpgcUrl + '?groupId=' + item.id + '&pgt=' + 0 + '&pgs=' + moduleStyle"
            target="_self"
        >
            <span class="navLineTitle g_productSort_name" :style="pn" v-text="item.name"></span>
        </a>
        <a
            v-if="!item.hasChildren"
            class="pgLinkWrap"
            :href="prUrl + '?mid=' + moduleId + '&groupId=' + item.id"
            target="_self"
        >
            <span class="pgName g_productSort_name" :style="pn" v-text="item.name"></span>
        </a>
    </div>
</template>
<script>
export default {
    name: 'ProductGroupAll',
    props: {
        itemData: {
            type: Object,
            default: function () {
                return {};
            },
        },
        moduleStyle: {
            type: Number,
            require: true,
            default: -1,
        },
        prUrl: {
            type: String,
            require: true,
            default: '',
        },
        newNpgcUrl: {
            type: String,
            require: true,
            default: '',
        },
        moduleId: {
            type: Number,
            require: true,
            default: -1,
        },
        pn: {
            type: String,
            default: '',
        },
    },
    computed: {
        item: function () {
            return this.itemData;
        },
    },
    methods: {
        pgListLv1ItemClick() {
            this.$emit('itemClick', this.itemData);
        },
    },
};
</script>
