<template>
    <div
        :id="photoItem.picId"
        v-drag-resize="_resizeOptions"
        class="photoHoverContainer"
        :style="photoHoverContainerStyle"
        :data-picid="photoItem.picId"
    >
        <a
            class="photoHoverContent"
            :href="imgHref"
            :rel="photoItem.nf ? 'nofollow' : ''"
            :onclick="jumpOnclickStr"
            @click="photoSlidesshow"
        >
            <div
                class="photoHoverImg J_img_lazyload"
                :src-original="photoItem.picThumbPath"
                :style="photoItemImgStyle"
            ></div>
            <div class="photoHover" :style="photoItemHoverStyle"></div>
            <div class="photoHoverText">
                <div v-show="showTitle" class="hoverTitle" :style="titleWrapStyle" v-html="titleData"></div>
                <div v-show="showDesc" class="hoverDesc" :style="descWrapStyle" v-html="descData"></div>
            </div>
        </a>
    </div>
</template>
<script>
import { photoMixins } from '../../mixins';
import { photoImgScaleStyleMap } from '../../utils';
export default {
    name: 'PhotoHover',
    mixins: [photoMixins],
    props: {
        listLength: {
            type: Number,
            default: 0,
        },
    },
    computed: {
        photoItemImgStyle() {
            let style = {};
            style['background-image'] = `url(${this.noLazyLoad ? this.photoItem.picThumbPath : this.loadingPath})`;
            // Object.assign(style, photoImgScaleStyleMap[this.picScaleType]);
            // Object.assign(style, this.borderRadiusStyle);
            style = {
                ...style,
                ...photoImgScaleStyleMap[this.picScaleType],
                ...this.borderRadiusStyle,
            };
            style['height'] = this.picSize.htype == 1 ? `${this.picSize.rh}rem` : '7.75rem';
            return style;
        },

        photoItemHoverColor() {
            return this.moduleContent.mhighs.ihover;
        },

        photoItemHoverStyle() {
            let style = {};
            switch (this.photoItemHoverColor.type) {
                case 0:
                    style['background'] = '#333';
                    style['opacity'] = '0.5';
                    break;
                case 1:
                    style['background'] = this.photoItemHoverColor.color;
                    break;
                case 2:
                    style['display'] = 'none';
                    break;
            }
            // Object.assign(style, this.borderRadiusStyle);
            style = { ...style, ...this.borderRadiusStyle };
            return style;
        },

        titleWrapStyle() {
            let style = {};
            // Object.assign(style, photoTitleStyleMap[this.columnsType]);
            if (this.titleLink.type == 0) {
                style['color'] = '#fff';
                style['font-size'] = '0.7rem';
            }
            // Object.assign(style, this.wrapStyle(this.titleIsWrap, this.moduleStyle));
            // Object.assign(style, this.textLinkStyle(this.titleLink));
            let titleWrapStyle = this.wrapStyle(this.titleIsWrap, this.moduleStyle) || {};
            let textLinkStyle = this.textLinkStyle(this.titleLink);

            style = { ...style, ...titleWrapStyle, ...textLinkStyle };

            style['text-align'] = this.textAlignStyle;
            if (this.photoItem.imgName == '' || this.photoItem.imgName == null) {
                style['margin-bottom'] = 0;
            }
            return style;
        },

        descWrapStyle() {
            let style = {};
            if (this.nameLink.type == 0) {
                style['color'] = '#fff';
                style['font-size'] = '0.55rem';
            }
            style['text-align'] = this.textAlignStyle;
            // Object.assign(style, this.wrapStyle(this.descIsWrap, this.moduleStyle));
            // Object.assign(style, this.textLinkStyle(this.nameLink));
            let wrapStyle = this.wrapStyle(this.descIsWrap, this.moduleStyle) || {};
            let nameLinkStyle = this.textLinkStyle(this.nameLink);

            style = { ...style, ...wrapStyle, ...nameLinkStyle };
            return style;
        },

        borderRadiusStyle() {
            let style = {};
            if (this.photoRadius.type == 1) {
                style['border-radius'] = `${this.photoRadius.size / this.htmlFontSize}rem`;
            } else {
                style['border-radius'] = '0.2rem';
            }
            return style;
        },
        photoHoverContainerStyle() {
            let style = {};
            if (this.picSpace.type == 1) {
                style['padding-left'] = `${this.picSpace.horizontal / this.htmlFontSize}rem`;
                style['padding-right'] = `${this.picSpace.horizontal / this.htmlFontSize}rem`;
                if (this.imgIndex < this.listLength) {
                    style['margin-bottom'] = `${this.picSpace.vertical / this.htmlFontSize}rem`;
                }
            }

            if (this.picSize.htype == 1) {
                style['height'] = `${this.picSize.rh}rem`;
            }
            return style;
        },
    },
};
</script>
<style>
.photoHoverContainer {
    width: 100%;
    height: 7.75rem;
    box-sizing: border-box;
    margin-bottom: 1rem;
}

.photoHoverContainer:first-child {
    margin-top: 0.75rem;
}

.photoHoverContainer .photoHoverContent {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
}

.photoHoverContainer .photoHoverContent .photoHoverText {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 0 1.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}

.photoHoverContainer .photoHoverContent .photoHover {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
}

.photoHoverText .hoverTitle {
    width: 100%;
    margin-bottom: 0.5rem;
    font-weight: bold;
}
.photoHoverText .hoverDesc {
    width: 100%;
    opacity: 0.8;
}
</style>
