var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "m_form_item", class: _vm.itemClass }, [
      !_vm.hidden
        ? _c(
            "label",
            {
              staticClass: "form_item_label",
              class: { form_item_require: _vm.isRequired },
              style: _vm.formItemStyle,
            },
            [_vm._v(_vm._s(_vm.formItem.name))]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form_item_content", style: _vm.formItemContentStyle },
        [
          _c("FormPhone", {
            attrs: { "form-item": _vm.formItem },
            on: { chanage: _vm.handlePhoneChange },
          }),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _vm.verionControl && !_vm.inMemberDetailEdit
      ? _c(
          "div",
          { staticClass: "m_form_item item_wrap", class: _vm.itemClass },
          [
            _c(
              "div",
              {
                staticClass: "form_item_content",
                style: _vm.formItemContentStyle,
              },
              [
                _c("FormMessageCode", {
                  attrs: {
                    "form-item": _vm.formItem,
                    disabled: _vm.msgDisabled,
                    phone: _vm.phoneNumber,
                  },
                  on: { change: _vm.getMsgCode },
                }),
              ],
              1
            ),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }