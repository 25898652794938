var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: _vm.wrapClasses,
      attrs: {
        id: "newsLine" + _vm.info.id,
        topClassName: _vm._topClassName,
        topSwitch: _vm._topSwitch,
        newsId: _vm.info.id,
        newsName: _vm.info.title,
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "lineBody",
          class: [_vm.isNewAddModuleClass, _vm.isOnlyTitle],
          attrs: { id: "lineBody" + _vm.info.id },
        },
        [
          _c("div", { staticClass: "mixNewsStyleImgBox6" }, [
            _c(
              "a",
              {
                directives: [
                  {
                    name: "drag-resize",
                    rawName: "v-drag-resize",
                    value: _vm._resizeOptions,
                    expression: "_resizeOptions",
                  },
                ],
                staticClass: "f_mixNewsStyleImgBox6_link picRadiusClass",
                attrs: {
                  hidefocus: "true",
                  href: _vm._newsUrl,
                  target: _vm._jumpTarget,
                  onclick: _vm.onclickStr,
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "headPicTopWrap" },
                  [
                    _vm._topFlagClass != "" && _vm.isNewAddModule
                      ? _c("top-component")
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", {
                      staticClass:
                        "picRadiusClass picScaleClass J_img_lazyload",
                      class: _vm.titlePicClass,
                      style: _vm.picStyle,
                      attrs: {
                        id: "newsTitlePic",
                        alt: _vm.altName,
                        "src-original": _vm.info.realPicId
                          ? _vm.info.bigPicPath
                          : "",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "mixNewsStyleTitleContainer6 picRadiusClass",
                    style: _vm.maskBg,
                  },
                  [
                    _c("div", { staticClass: "mixNewsStyleTitlePanel" }, [
                      !_vm.isNewAddModule && _vm._showSort
                        ? _c("div", { staticClass: "newsSortPanel" }, [
                            _c("div", { staticClass: "newsSort" }, [
                              _vm._v(_vm._s(_vm.info.groupName)),
                            ]),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "g_news_title",
                          class: [_vm.titleClasses, _vm.titleLine],
                          style: _vm.news_title_style,
                        },
                        [
                          _c("div", { class: _vm._topFlagClass }),
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.info.title) +
                              "\n                        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      (_vm.isNewAddModule && !_vm.module.content.des) ||
                      !_vm.isNewAddModule
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "mixNewsStyleSummary g_news_abstract",
                              class: [
                                _vm.isNewAddModule && _vm.descriptionLine,
                                _vm.isOnlyTitleAndDes,
                              ],
                              style: _vm.news_abstract_style,
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.info.summary) +
                                  "\n                        "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    !_vm.isNewAddModule && _vm.showOtherInfo
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "newsElementsPanel g_newsElementsPanel J_newsElementsPanel g_newsInfo",
                            on: { click: _vm.otherInfoClick },
                          },
                          [
                            _vm._showComment
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "ne_newsComment faisco-icons-comment",
                                  },
                                  [
                                    !_vm.isNewAddModule
                                      ? _c(
                                          "span",
                                          { staticClass: "newsCommentCount" },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.info.commentCount)
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "span",
                                          {
                                            staticClass:
                                              "newsCommentCount g_newsInfo",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.info.commentCount) +
                                                " " +
                                                _vm._s(
                                                  _vm.module.extInfo.commentText
                                                )
                                            ),
                                          ]
                                        ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm._showAuthor && _vm._showComment
                              ? _c("div", {
                                  staticClass:
                                    "ne_separatorLine g_ne_separatorLine",
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm._showAuthor
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "ne_newsAuthor",
                                    style: _vm.authorStyle,
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "newsAuthorName g_newsInfo",
                                      },
                                      [_vm._v(_vm._s(_vm.info.author))]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm._showDate
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "ne_newsTime g_newsInfo",
                                    class: _vm.fixClass,
                                  },
                                  [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(_vm.newsDate) +
                                        "\n                        "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        )
                      : _vm._e(),
                  ]
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _vm.isNewAddModule && _vm.showOtherInfo
            ? _c(
                "div",
                {
                  staticClass:
                    "newsElementsPanel g_newsElementsPanel J_newsElementsPanel g_newsInfo",
                  on: { click: _vm.otherInfoClick },
                },
                [
                  _vm._showComment
                    ? _c(
                        "div",
                        { staticClass: "ne_newsComment faisco-icons-comment" },
                        [
                          !_vm.isNewAddModule
                            ? _c("span", { staticClass: "newsCommentCount" }, [
                                _vm._v(_vm._s(_vm.info.commentCount)),
                              ])
                            : _c(
                                "span",
                                { staticClass: "newsCommentCount g_newsInfo" },
                                [
                                  _vm._v(
                                    _vm._s(_vm.info.commentCount) +
                                      _vm._s(_vm.module.extInfo.commentText)
                                  ),
                                ]
                              ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._showSort
                    ? _c("div", { staticClass: "ne_newsSort g_newsInfo" }, [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.info.groupName) +
                            "\n            "
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._showAuthor
                    ? _c(
                        "div",
                        {
                          staticClass: "ne_newsAuthor",
                          style: _vm.authorStyle,
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "newsAuthorName g_newsInfo" },
                            [_vm._v(_vm._s(_vm.info.author))]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._showDate
                    ? _c(
                        "div",
                        {
                          staticClass: "ne_newsTime g_newsInfo",
                          class: _vm.fixClass,
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.newsDate) +
                              "\n            "
                          ),
                        ]
                      )
                    : _vm._e(),
                ]
              )
            : _vm._e(),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }