var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("module-frame", { attrs: { "module-id": _vm.moduleId } }, [
    _c(
      "div",
      { class: _vm.manageMode ? "scriptModuleMinH" : "" },
      [
        _vm.code == "" && _vm.manageMode
          ? [
              _c(
                "div",
                { staticClass: "empty_tips_panel" },
                [
                  _c("div", { staticClass: "text" }, [
                    _vm._v("请在右侧面板中添加代码"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "button-component",
                    {
                      attrs: { active: "" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.addCode.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v(" 添加代码 ")]
                  ),
                ],
                1
              ),
            ]
          : _vm.code == "" && !_vm.manageMode
          ? [
              _c("div", { staticClass: "cus_Empty_tips_panel" }, [
                _c("div", { staticClass: "text" }, [_vm._v("没有添加代码")]),
              ]),
            ]
          : [
              _vm.type == 0 && _vm._allowScript && _vm.$isServer
                ? _c("div", {
                    ref: "scriptNode",
                    domProps: { innerHTML: _vm._s(_vm.showCode) },
                  })
                : _vm.type == 0 && _vm._allowScript
                ? _c("div", { ref: "scriptNode" })
                : _vm._e(),
            ],
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }