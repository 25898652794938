<template>
    <div
        class="g_main_bgColor_v3 g_bgColor f-productListTopPromotion"
        :style="textStyle"
        >{{ info.extInfo.saleFlagName }}</div
    >
</template>

<script>
export default {
    name: 'SaleFlag',
    inject: ['module', 'options'],
    props: ['info'],
    computed: {
        textStyle() {
            if (this.module.content.t == '32') {
                return 'position:relative;display:inline-block;';
            }
            return '';
        },
    },
};
</script>
