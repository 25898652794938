import { encodeHtml } from '@/shared/util';
import { initPictureSlidesV4 } from '@/shared/photo/slides';

export const highLightKeyword = function (str = '', keyword) {
    if (keyword == null) {
        return str;
    }
    return String.prototype.replaceAll
        ? String(str).replaceAll(keyword, `<span class='highlightText'>${encodeHtml(keyword)}</span>`)
        : str;
};

export const getGroupNameLs = function (index, LS) {
    const titleArr = {
        1: LS.product,
        2: LS.news,
        3: LS.photo,
        7: LS.page2,
        5: LS.photoGroup,
        6: LS.files,
        4: `${LS.page2}-${LS.richModule}`,
    };
    return titleArr[index];
};

export const groupIndexArr = {
    1: 'sbProduct',
    2: 'sbNews',
    3: 'sbPhoto',
    4: 'sbColRich',
    5: 'sbPhotoGroup',
    6: 'sbFileList',
    7: 'sbCol',
};

export const newSearchResultSlide = function (moduleId, json) {
    if (json == null || json.length == 0) return;
    var options = {
            fromCurentInx: true,
            contentTerminal: 'mobi-全站搜索-图片',
        },
        images;
    var selector = '#module' + moduleId + ' img';

    images = dataHandler();
    initPictureSlidesV4(selector, images, options);

    function dataHandler() {
        var images = [];

        jm(selector).forEach(function (el, index) {
            var openLink = false;

            if (
                el.parentNode.tagName === 'A' &&
                el.parentNode.href !== '' &&
                !el.parentNode.href.startsWith('javascript:void(0)')
            ) {
                openLink = true;
            }

            let imageId = 'photoswipe_' + index;
            images.push({
                src: json[index].src,
                width: json[index].width,
                height: json[index].height,
                title: json[index].title || '',
                desc: '',
                detail: false,
                id: imageId,
                openLink,
            });

            el.setAttribute('data-picId', imageId);
        });
        return images;
    }
};
