<template>
    <fold-container :module-id="moduleId" />
</template>

<script>
import FoldContainer from './entrance.vue';
import { componentsTable } from '@/modules/visitor-interface';

export default {
    name: 'FoldContainerVisitor',
    components: {
        FoldContainer,
    },
    provide() {
        return {
            componentsTable: this.componentsTable,
        };
    },
    props: {
        moduleId: {
            type: Number,
            default: -1,
        },
    },
    data() {
        return {
            componentsTable,
        };
    },
};
</script>

<style></style>
