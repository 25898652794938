var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "mProductList styleForm7",
      class: _vm._listClass,
      attrs: { id: _vm._listId, mType: _vm.module.content.t },
    },
    [
      _c(
        "div",
        {
          staticClass: "swipe productSwipe",
          attrs: { id: "productSwipe" + _vm.module.id },
        },
        [
          _c(
            "div",
            { staticClass: "productSwipeContainer" },
            _vm._l(_vm.list, function (group, idx) {
              return _c(
                "div",
                {
                  key: "group_" + idx,
                  staticClass: "productSwipeItem",
                  attrs: { id: "productSwipeItem" + (2 * idx + 1) },
                },
                [
                  _c(
                    "div",
                    { staticClass: "productSwipeDiv" },
                    [
                      _vm._l(group, function (info, index) {
                        return [
                          _c("two-column-item", {
                            key: "p_" + info.id,
                            class: _vm.needMargin(index),
                            style: _vm.marginStyle(index),
                            attrs: {
                              info: info,
                              index: index,
                              "no-lazy-load": _vm.noLazyLoad,
                            },
                          }),
                        ]
                      }),
                      _vm._v(" "),
                      _vm.needBlankDiv(idx)
                        ? _c("div", {
                            staticClass: "blankDiv productCrossedSlide",
                            class: _vm.needMargin(1),
                            style: _vm.marginStyle(1),
                          })
                        : _vm._e(),
                    ],
                    2
                  ),
                ]
              )
            }),
            0
          ),
          _vm._v(" "),
          _vm.productList.length > 2
            ? _c(
                "div",
                {
                  staticClass: "productBullet",
                  attrs: { id: "productBullet" },
                },
                [
                  _c(
                    "ul",
                    { staticClass: "bullets", attrs: { id: "bullets" } },
                    _vm._l(_vm.list, function (group, idx) {
                      return _c("li", {
                        key: "bullet_" + idx,
                        class: { on: idx == 0 },
                      })
                    }),
                    0
                  ),
                ]
              )
            : _vm._e(),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }