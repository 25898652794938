var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("module-frame", { attrs: { "module-id": _vm.moduleId } }, [
    _vm.render
      ? _c(
          "div",
          {
            staticClass: "J_tabModule fk-formTab formTab2",
            class: _vm.tabStyleClass,
            attrs: { _arrowOpen: _vm.showArrow },
          },
          [
            _c(
              "div",
              {
                staticClass: "J_formTabModuleContent f-formTabModuleContent",
                class: _vm.wrapOpenCls,
              },
              [
                _c(
                  "div",
                  { staticClass: "J_formTabButtonWrap f-formTabButtonWrap" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "J_formTabButtonList f-formTabButtonList",
                        style: _vm.buttonListStyle,
                      },
                      [
                        _vm._l(_vm.labelList, function (item, index) {
                          return _c(
                            "div",
                            {
                              key: "label_item_" + index,
                              staticClass:
                                "J_formTabButton f-formTabButton g_label_title",
                              class: _vm.formTabButtonClass(index, item.id),
                              attrs: {
                                id: "formTabButton" + item.id,
                                tabModuleId: item.id,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.tabButtonClickHanle(index, $event)
                                },
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "J_formTabMiddle f-formTabMiddle",
                                  class: _vm.formTabMiddleClass(index),
                                  style: _vm.formTabMiddleStyle(index),
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "f-formTabMiddleBox" },
                                    [
                                      _vm.buttonTextRender
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "f-formTabButtonText",
                                              style:
                                                _vm.label_button_style(index),
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.itemLabelName(
                                                    item.labelName,
                                                    item.id
                                                  )
                                                )
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.showUnderLine(index)
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "f-formTabButtonUnderLine",
                                            },
                                            [
                                              _c("span", {
                                                staticClass: "buttonLine",
                                                class:
                                                  index == _vm.currentIndex
                                                    ? "g_main_bdColor_v3 g_oldThemeBdColor"
                                                    : "",
                                                style:
                                                  _vm.bottomLineStyle(index),
                                              }),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          )
                        }),
                        _vm._v(" "),
                        _vm.showDefaultLine
                          ? _c(
                              "span",
                              {
                                staticClass:
                                  "style2DefaultLine g_label_deFault_label",
                              },
                              [
                                _c("span", {
                                  staticClass: "buttonLine",
                                  style: _vm.defaultLineStyle,
                                }),
                              ]
                            )
                          : _vm._e(),
                      ],
                      2
                    ),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "J_formTabContentList f-formTabContentList column",
                class: "formTabContentList" + _vm.id,
                style: _vm.formTabContentListStyle,
                attrs: { id: "formTabContentList" + _vm.id },
              },
              [
                _c(
                  "div",
                  { ref: "tabContentBox", staticClass: "tabContentBox" },
                  [
                    _vm._l(_vm.labelList, function (item, index) {
                      return [
                        item.id < 0
                          ? _c(
                              "div",
                              {
                                key: "formTabContent_" + index,
                                staticClass: "J_formTabContent",
                                class: _vm.formTabContentClass(index),
                                attrs: {
                                  id: "formTabContent" + item.id,
                                  styleId: item.style,
                                },
                              },
                              [
                                _vm.manageMode
                                  ? [
                                      _c(
                                        "div",
                                        { staticClass: "empty_tips_panel" },
                                        [
                                          _c("div", { staticClass: "text" }, [
                                            _vm._v("请将模块拖入标签区域"),
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "jz_button jz_button__active addBtn_Icon",
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  return _vm.addModuleToTab(
                                                    item.id
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("添加模块")]
                                          ),
                                        ]
                                      ),
                                    ]
                                  : [
                                      _c(
                                        "div",
                                        { staticClass: "cus_Empty_tips_panel" },
                                        [
                                          _c("div", { staticClass: "text" }, [
                                            _vm._v("没有添加内容"),
                                          ]),
                                        ]
                                      ),
                                    ],
                              ],
                              2
                            )
                          : [
                              _vm.labelCache["module" + item.id]
                                ? _c("tab-child-module", {
                                    key: "tab_child_module_" + index,
                                    class: _vm.formTabContentClass(index),
                                    attrs: {
                                      id: item.id,
                                      "style-id": item.style,
                                      "item-data":
                                        _vm.labelCache["module" + item.id],
                                      "label-data": item,
                                      "manage-mode": _vm.manageMode,
                                    },
                                  })
                                : _vm._e(),
                            ],
                      ]
                    }),
                  ],
                  2
                ),
                _vm._v(" "),
                _vm.showLoading ? _c("module-loading") : _vm._e(),
              ],
              1
            ),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }