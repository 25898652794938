var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: _vm.wrapClasses,
      attrs: {
        id: "newsLine" + _vm.info.id,
        topClassName: _vm._topClassName,
        topSwitch: _vm._topSwitch,
        newsId: _vm.info.id,
        newsName: _vm.info.title,
      },
    },
    [
      _c(
        "a",
        {
          staticClass: "lineBodyLink",
          class: [_vm.isNewAddModuleClass, _vm.isOnlyTitle],
          staticStyle: { width: "auto" },
          attrs: {
            hidefocus: "true",
            href: _vm._newsUrl,
            target: _vm._jumpTarget,
            onclick: _vm.onclickStr,
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "lineBody lineBodyPic",
              attrs: { id: "lineBody" + _vm.info.id },
            },
            [
              _c(
                "div",
                { staticClass: "articleFlexWrap" },
                [
                  _c(
                    "div",
                    { staticClass: "picAndTextFlex" },
                    [
                      _vm.module.content.tPosition == 1
                        ? [
                            _vm._topFlagClass != "" && !_vm.isNewAddModule
                              ? _c("span", {
                                  staticClass: "newStyleTop",
                                  class: _vm.topFlagClass,
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                key: "wrap1",
                                staticClass: "J_img_wrap",
                                class: [_vm.imgWrapClasses, _vm.noMargin],
                                style: _vm.imgWrapStyle,
                              },
                              [
                                _c("div", {
                                  directives: [
                                    {
                                      name: "drag-resize",
                                      rawName: "v-drag-resize",
                                      value: _vm._resizeOptions,
                                      expression: "_resizeOptions",
                                    },
                                  ],
                                  staticClass:
                                    "picRadiusClass picScaleClass J_img_lazyload",
                                  class: _vm.picTextClasses,
                                  style: _vm.picItemStyle,
                                  attrs: {
                                    id: "newsImg" + _vm.info.id,
                                    alt: _vm.altName,
                                    "src-original": _vm.info.realPicId
                                      ? _vm.info.pic160Path
                                      : "",
                                  },
                                }),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                key: "textFlexWrap",
                                staticClass: "textFlexWrap",
                                class: _vm.desClasses,
                              },
                              [
                                _c("div", { staticClass: "felxTextItem" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "twoLine g_link articleTitle g_news_title newTitle",
                                      class: [_vm.titleClasses, _vm.titleLine2],
                                      style: _vm.news_title_style,
                                    },
                                    [
                                      _vm._topFlagClass != "" &&
                                      _vm.isNewAddModule
                                        ? _c("top-component")
                                        : _vm._e(),
                                      _vm._v(
                                        _vm._s(_vm.info.title) +
                                          "\n                                "
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                !_vm.module.content.des
                                  ? _c("div", { staticClass: "felxTextItem" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "twoLine articleSummray g_text description g_news_abstract newDescriptionLine newDescriptionLine",
                                          class: [
                                            _vm.descriptionLine,
                                            _vm.isOnlyTitleAndDes,
                                          ],
                                          style: _vm.news_abstract_style,
                                        },
                                        [_vm._v(_vm._s(_vm.info.summary))]
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.module.content.tp == 2
                                  ? _c("new-info", {
                                      attrs: {
                                        info: _vm.info,
                                        index: _vm.index,
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        : [
                            _c(
                              "div",
                              {
                                key: "textFlexWrap",
                                staticClass: "textFlexWrap",
                                class: _vm.desClasses,
                              },
                              [
                                _c("div", { staticClass: "felxTextItem" }, [
                                  _vm._topFlagClass != "" && !_vm.isNewAddModule
                                    ? _c("span", {
                                        staticClass: "newStyleTop",
                                        class: _vm.topFlagClass,
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "twoLine g_link articleTitle g_news_title newTitle",
                                      class: [_vm.titleClasses, _vm.titleLine2],
                                      style: _vm.news_title_style,
                                    },
                                    [
                                      _vm._topFlagClass != "" &&
                                      _vm.isNewAddModule
                                        ? _c("top-component")
                                        : _vm._e(),
                                      _vm._v(
                                        _vm._s(_vm.info.title) +
                                          "\n                                "
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                !_vm.module.content.des
                                  ? _c("div", { staticClass: "felxTextItem" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "twoLine articleSummray g_text description g_news_abstract newDescriptionLine newDescriptionLine",
                                          class: [
                                            _vm.descriptionLine,
                                            _vm.isOnlyTitleAndDes,
                                          ],
                                          style: _vm.news_abstract_style,
                                        },
                                        [_vm._v(_vm._s(_vm.info.summary))]
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.module.content.tp == 2
                                  ? _c("new-info", {
                                      attrs: {
                                        info: _vm.info,
                                        index: _vm.index,
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                key: "wrap2",
                                staticClass: "J_img_wrap",
                                class: [_vm.imgWrapClasses, _vm.noMargin],
                                style: _vm.imgWrapStyle,
                              },
                              [
                                _c("div", {
                                  directives: [
                                    {
                                      name: "drag-resize",
                                      rawName: "v-drag-resize",
                                      value: _vm._resizeOptions,
                                      expression: "_resizeOptions",
                                    },
                                  ],
                                  staticClass:
                                    "picRadiusClass picScaleClass J_img_lazyload",
                                  class: _vm.picTextClasses,
                                  style: _vm.picItemStyle,
                                  attrs: {
                                    id: "newsImg" + _vm.info.id,
                                    alt: _vm.altName,
                                    "src-original": _vm.info.realPicId
                                      ? _vm.info.pic160Path
                                      : "",
                                  },
                                }),
                              ]
                            ),
                          ],
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _vm.module.content.tp == 1
                    ? _c("new-info", {
                        attrs: { info: _vm.info, index: _vm.index },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }