var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("module-frame", { attrs: { "module-id": _vm.moduleId } }, [
    _c("div", { staticClass: "cubeNavigationAreaBox" }, [
      _vm.render
        ? _c(
            "div",
            {
              staticClass: "cubeNavigationArea",
              class: _vm.cubeNavigationCls,
              attrs: { id: "cubeNavigation" + _vm.id, value: _vm.cubeLayoutId },
            },
            [
              _c("div", { staticClass: "cubeNavigationAreaContent" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "J_cubeNavigationAreaWrap cubeNavigationAreaWrap",
                    style: _vm.areaWrapStyle,
                    on: {
                      mouseover: _vm.clearCarousel,
                      mouseout: _vm.setCarousel,
                    },
                  },
                  [
                    _vm.column != 0
                      ? _vm._l(_vm.pageList, function (rowList, index3) {
                          return _c(
                            "div",
                            { staticClass: "rows_wrap" },
                            _vm._l(rowList, function (row, index) {
                              return _c(
                                "div",
                                {
                                  class:
                                    _vm.cubeLayoutId == 14 && index < 2
                                      ? "cubeLink_row_head"
                                      : "cubeLink_row",
                                },
                                _vm._l(row, function (link, index2) {
                                  return _c("cubelink", {
                                    key: link.id,
                                    attrs: {
                                      id: link.id,
                                      link: link,
                                      index: _vm.indexCtl(index, index2),
                                      "page-index": index3,
                                      "href-str": _vm.hrefStr,
                                      "manage-mode": _vm.manageMode,
                                      "module-manage": _vm.moduleManage,
                                      "new-add": _vm.newAdd,
                                      "data-length": _vm.dataLength,
                                      "module-id": _vm.id,
                                      "cube-layout-id": _vm.cubeLayoutId,
                                      "has-sub-title": _vm.hasSubTitle,
                                      "cube-type": _vm.cubeType,
                                      "title-style":
                                        _vm.module.content.mhighs.ts,
                                      "can-set": _vm.canSetCube,
                                      "no-lazy-load": _vm.noLazyLoad,
                                      module: _vm.module,
                                      options: _vm.options,
                                    },
                                  })
                                }),
                                1
                              )
                            }),
                            0
                          )
                        })
                      : _vm._l(_vm.showLinkList, function (link, index) {
                          return _c("cubelink", {
                            key: link.id,
                            attrs: {
                              id: link.id,
                              link: link,
                              index: index,
                              "page-index": index,
                              "href-str": _vm.hrefStr,
                              "manage-mode": _vm.manageMode,
                              "module-manage": _vm.moduleManage,
                              "new-add": _vm.newAdd,
                              "data-length": _vm.dataLength,
                              "module-id": _vm.id,
                              "cube-layout-id": _vm.cubeLayoutId,
                              "has-sub-title": _vm.hasSubTitle,
                              "cube-type": _vm.cubeType,
                              "title-style": _vm.module.content.mhighs.ts,
                              "can-set": _vm.canSetCube,
                              "no-lazy-load": _vm.noLazyLoad,
                              module: _vm.module,
                              options: _vm.options,
                            },
                          })
                        }),
                  ],
                  2
                ),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.canSetCube.effect &&
      _vm.module.content.ef == 2 &&
      _vm.pageList.length > 1 &&
      _vm.column != 0
        ? _c(
            "div",
            { staticClass: "page_wrap" },
            _vm._l(_vm.pageList.length - 1, function (item, index) {
              return _c("i", {
                staticClass: "pageno",
                style: _vm.pagenoStyle(index),
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.gotoPage(index + 1)
                  },
                },
              })
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.canSetCube.effect &&
      _vm.module.content.ef == 1 &&
      _vm.pageList.length > 1 &&
      _vm.column != 0
        ? _c("div", { staticClass: "progress_wrap" }, [
            _c(
              "div",
              { staticClass: "progress_wrap2", style: _vm.progressStyle },
              [
                _c("div", {
                  staticClass: "progress_thume",
                  style: _vm.thumeStyle,
                }),
              ]
            ),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }