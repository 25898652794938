<template>
    <div :id="'photoSlide' + moduleId" class="photoSlide">
        <template v-if="isMarqueeShow">
            <div :class="'J_marqueeContainer' + moduleId + ' fk-marqueeContainer'">
                <div :class="'J_marqueePanel' + moduleId">
                    <div :id="'photoSlideList' + moduleId" :style="slideItemContainerStyle">
                        <SlideItem
                            v-for="(item, index) in contentList"
                            :key="item.picId"
                            :photo-item="item"
                            :module-id="module.id"
                            :module-content="module.content"
                            :link-type="linkType"
                            :img-href="imgHref(item.jumpUrl)"
                            :img-index="index + 1"
                            :is-empty="isEmptyText"
                            :slides-index="item.index"
                            :max-height="imgStyleHeight(index)"
                            :loading-path="options.loadingPath"
                            :no-lazy-load="noLazyLoad"
                        />
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <div :id="'photoSlideList' + moduleId" :style="slideItemContainerStyle">
                <SlideItem
                    v-for="(item, index) in contentList"
                    :key="item.picId"
                    :photo-item="item"
                    :module-content="module.content"
                    :link-type="linkType"
                    :img-href="imgHref(item.jumpUrl)"
                    :img-index="index + 1"
                    :is-empty="isEmptyText"
                    :slides-index="item.index"
                    :max-height="imgStyleHeight(index)"
                    :loading-path="options.loadingPath"
                    :no-lazy-load="noLazyLoad"
                />
            </div>
        </template>
    </div>
</template>
<script>
import SlideItem from './slide-item.vue';
import { optionsMixins } from '../mixins/optionsMixins';

export default {
    name: 'SlideList',
    components: {
        SlideItem,
    },
    mixins: [optionsMixins],
    inject: ['module', 'options'],
    props: {
        contentList: {
            type: Array,
            default() {
                return [];
            },
        },
        photoSwipeList: {
            type: Array,
            default() {
                return [];
            },
        },
        noLazyLoad: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        moduleId() {
            return this.module.id;
        },
        isMarqueeShow() {
            return this.module.content.m.s;
        },
        columnsType() {
            return this.module.content.icolumns;
        },
        showTitle() {
            return this.module.content.showTitle;
        },
        showDesc() {
            return this.module.content.showName;
        },
        nameAndDescIsHidden() {
            return !this.showTitle && !this.showDesc;
        },
        spaceTypeContent() {
            return this.module.content.mhighs.ispace;
        },
        picScaleType() {
            return this.module.content.picScale;
        },
        slideItemContainerStyle() {
            let style = {};
            style['display'] = 'flex';
            style['paddin-left'] = '0.25rem';
            // style['padding-right'] = '0.75rem';
            // 计算宽度
            if (this.spaceTypeContent.type == 0) {
                style['width'] = this.contentList.length * (this.columnsTypeWidthLength + 0.5) + 'rem'; // 其中 0.5 为后者对前者的左边距
                style['margin-bottom'] = this.nameAndDescIsHidden ? '0.75rem' : '1rem';
            } else {
                style['width'] =
                    this.contentList.length *
                        (this.columnsTypeWidthLength + this.spaceTypeContent.horizontal / this.htmlFontSize) +
                    'rem';
                style['margin-bottom'] = this.nameAndDescIsHidden ? '0.75rem' : '1rem';
            }
            return style;
        },
        columnsTypeWidthLength() {
            let length = 6.1;
            switch (this.columnsType) {
                case 0:
                    length = 6.1;
                    break;
                case 1:
                    length = 8;
                    break;
                case 2:
                    length = 6.1;
                    break;
            }
            return length;
        },
        imgDivWidth() {
            let width = 0;
            switch (this.columnsType) {
                case 0:
                    break;
                case 1:
                    width = 8;
                    break;
                case 2:
                    width = 5.95;
                    break;
            }
            return width;
        },
        imgDivMaxHeight() {
            let maxHeight = 0;
            let self = this;
            if (self.photoSwipeList.length) {
                self.photoSwipeList.forEach(function (value) {
                    var imgHeight = 0;
                    if (value.height / self.htmlFontSize < self.imgDivWidth && self.picScaleType == 0) {
                        imgHeight = parseFloat(value.height / self.htmlFontSize);
                    } else {
                        imgHeight = parseFloat((value.height * self.imgDivWidth) / value.width);
                    }
                    if (imgHeight > maxHeight) {
                        maxHeight = imgHeight;
                    }
                });
            }
            return maxHeight;
        },
    },
    methods: {
        imgStyleHeight(index) {
            let height = this.imgDivMaxHeight;
            if (this.photoSwipeList.length) {
                let item = this.photoSwipeList[index];
                var scaleInit = Number(item.width / item.height).toFixed(5);
                var scaleLast = Number(this.imgDivWidth / this.imgDivMaxHeight).toFixed(5);
                if (this.picScaleType == 2) {
                    if (scaleLast <= scaleInit) {
                        height = this.imgDivMaxHeight;
                    } else {
                        height = this.imgDivWidth / scaleInit;
                    }
                }
            }
            return height;
        },
    },
};
</script>
