var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "photoModule styleForm6 g_background" }, [
    _c(
      "div",
      { staticClass: "photoSwipe", attrs: { id: "photoSwipe" + _vm.moduleId } },
      [
        _c(
          "div",
          { staticClass: "photoSwipeBox" },
          _vm._l(_vm.list, function (item, index) {
            return _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: index == 0,
                    expression: "index == 0",
                  },
                ],
                key: index,
                staticClass: "swipImgArea imageDiv",
              },
              [
                _c(
                  "a",
                  {
                    attrs: { href: item.href || undefined },
                    on: {
                      click: function ($event) {
                        return _vm.imgClick(index)
                      },
                    },
                  },
                  [
                    _c("span", { staticClass: "imageMiddleSpan" }),
                    _vm._v(" "),
                    _c("img", {
                      staticClass: "photoImageItem",
                      attrs: {
                        "data-picid": item.picId,
                        src: item.picThumbPath,
                        vwidth: item.vwidth,
                        vheight: item.vheight,
                        alt: item.alt,
                        photoIndex: index,
                        linkType: item.pLinkType,
                      },
                    }),
                  ]
                ),
              ]
            )
          }),
          0
        ),
        _vm._v(" "),
        _vm.showName
          ? _c(
              "div",
              {
                staticClass: "photoBullet",
                staticStyle: { height: "auto !important" },
                style: _vm.photoBulletStyle,
                attrs: { id: "photoBullet" + _vm.moduleId },
              },
              [
                _c("table", { staticClass: "tableFix" }, [
                  _c("tbody", [
                    _c("tr", [
                      _c("td", [
                        _c("div", {
                          staticClass: "imgName",
                          attrs: { id: "imgName" + _vm.moduleId },
                        }),
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "tdUl" }, [
                        _c(
                          "ul",
                          {
                            staticClass: "bullets",
                            attrs: { id: "bullets" + _vm.moduleId },
                          },
                          _vm._l(_vm.list, function (item, index) {
                            return _c("li", {
                              key: index,
                              class: index == 0 ? "on" : "",
                            })
                          }),
                          0
                        ),
                      ]),
                    ]),
                  ]),
                ]),
              ]
            )
          : _vm._e(),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }