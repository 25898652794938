export const checkNode = {
    // 默认处理的节点属性
    defaultPropertyValue: ['font-size', 'line-height', 'top', 'left', 'height', 'width', 'letter-spacing'],
    // 默认转化成rem，若为false，则转化为px
    isToRem: true,
    // _htmlFontSize, // 取管理态的
    // set自定义节点属性
    setPropertyValue(value) {
        this.defaultPropertyValue = value;
    },
    // get节点属性
    getPropertyValue() {
        return this.defaultPropertyValue;
    },
    // set自定义节点属性
    setIstoRem(value) {
        this.isToRem = value;
    },
    // get节点属性
    getIstoRem() {
        return this.isToRem;
    },
    resetValue() {
        this.defaultPropertyValue = ['font-size', 'line-height', 'top', 'left', 'height', 'width', 'letter-spacing'];
        this.isToRem = true;
    },
    returnChangeUnit(pxValue, manageFontSize) {
        var needChange = -1,
            fontSize = manageFontSize || Mobi.getRootFontSize(); //修改写死的管理态根节点fontSize23.1875，不同浏览器根节点的fontSize是不一样的
        if (this.getIstoRem()) {
            // 将px数值转换为rem
            needChange = pxValue.indexOf('px');
            return needChange >= 0 ? parseInt(pxValue) / fontSize + 'rem' : pxValue;
        } else {
            // 将rem数值转换为px
            needChange = pxValue.indexOf('rem');
            return needChange >= 0 ? Math.round(pxValue.replace('rem', '') * fontSize) + 'px' : pxValue;
        }
    },
    checkInArray(array, data) {
        for (var i = 0; i < array.length; i++) {
            if (array[i] == data) {
                return true;
            }
        }
        return false;
    },
    // 检查当前节点是否有需要转化的属性
    checkCurrentTagStyle(elem, manageFontSize) {
        var needChangeStyle = this.getPropertyValue(),
            elemStyle = '',
            elemStyleArray = [],
            itemVal = '',
            styleName = '',
            styleVal = '',
            i = 0;

        elemStyle = elem.attr('style');
        if (!elemStyle) {
            return;
        }

        elemStyleArray = elemStyle.split(';');
        if (elemStyleArray.length <= 0) {
            return;
        }

        for (i = 0; i < elemStyleArray.length; i++) {
            itemVal = elemStyleArray[i].split(':');
            if (typeof itemVal == 'undefined' || itemVal.length != 2) {
                continue;
            }
            styleName = $.trim(itemVal[0]);
            styleVal = $.trim(itemVal[1]);
            if (this.checkInArray(needChangeStyle, styleName)) {
                if (styleVal != undefined) {
                    elem.css(styleName, this.returnChangeUnit(styleVal, manageFontSize));
                }
            }
        }
    },
    // 遍历当前节点的子元素
    traverseTagStyle(elem, manageFontSize) {
        if (!elem.length) {
            return;
        } else {
            this.checkCurrentTagStyle(elem, manageFontSize);
        }
        var childElementList = elem.children(),
            i = 0;

        if (childElementList.length <= 0) {
            this.resetValue();
            return;
        }

        for (i = 0; i < childElementList.length; i++) {
            this.traverseTagStyle($(childElementList[i]), manageFontSize);
        }
    },
};

function stopOuterJump(el) {
    if (!(VITE_APP_MODE !== 'visitor')) {
        return;
    }
    $(el).on('click', 'a', function (e) {
        let url = $(this).attr('href');

        if (url == null) return;

        // 在线视频链接可能会存在http或者https，不当做外部链接处理
        if (url.indexOf('http') != -1 || url.indexOf('https') != -1) {
            // Fai.top.Fai.ing('管理态不支持跳转外部链接，请通过“预览”进行访问', true);
            console.log('管理态不支持跳转外部链接，请通过“预览”进行访问');
            e.preventDefault();
        }
    });
}

export const getEditor = (id) => {
    if (window.simpleTextList == null) {
        window.simpleTextList = { allEditors: [] };
    }
    let item = window.simpleTextList.allEditors.find((item) => {
        return item.moduleId == id;
    });
    return item.editor;
};

export const initSimpleText = (option) => {
    let { moduleId } = option;

    let module = $(`#module${moduleId}`);
    //修复复制模块，简易文本模块不是absolute,导致后面获取模块宽度异常
    if (module.css('position') != 'absolute') {
        module.css('position', 'absolute');
    }
    module.data('simpleText', 'true'); // 标记改模块为editor 模块

    let extClasses = 'fk-editor-break-word'; //额外扩展类,用于单词是否需要截断
    let editorDiv = module.find('.J_simpleText_' + moduleId);
    let initFkEditor = module.find('.fk-editor');
    let autoWidth = initFkEditor.attr('_autowidth') || 1;
    let autoHeight = initFkEditor.attr('_autoheight') || 1;

    let isVerticalRl = initFkEditor.hasClass('fk-editor-vertical-rl');
    let isVerticalLr = initFkEditor.hasClass('fk-editor-vertical-lr');
    let width = autoWidth == 1 && !isVerticalRl && !isVerticalLr ? 180 : module.width(); // 180简易文本的默认宽度
    let height = autoHeight == 1 ? 0 : module.attr('_height');

    //   let html = '';
    let initConTent = initFkEditor.html();
    initFkEditor.remove();

    // 初始化全局变量
    initFkEditorVariables();

    Vue.prototype.$editor.create(
        {
            defultVal: '',
            animation: true,
            editorWidth: width,
            editorHeight: height,
            moduleId,
            extClasses,
            isMobi: true,
            EditContentBtn: {
                name: '编辑文本',
            },
        },
        editorDiv[0]
    );

    if (isVerticalRl || isVerticalLr) {
        module
            .css({
                'min-width': '40px',
                'min-height': '10px',
                'max-height': '1200px',
            })
            .find('.fk-editor')
            .css({
                'min-width': '40px',
                'min-height': '10px',
                'max-height': '1200px',
            });
    } else {
        module
            .css({
                'min-height': '30px',
                'min-width': '10px',
                'max-width': '1200px',
            })
            .find('.fk-editor')
            .css({
                'min-height': '30px',
                'min-width': '10px',
                'max-width': '1200px',
            });
    }
    module.find('.fk-editor').html(initConTent).attr('_autowidth', autoWidth).attr('_autoheight', autoHeight);
    if (isVerticalRl) {
        module.find('.fk-editor').addClass('fk-editor-vertical-rl');
    }

    if (isVerticalLr) {
        module.find('.fk-editor').addClass('fk-editor-vertical-lr');
    }

    // 处理文本外链跳转
    stopOuterJump(module.find('.fk-editor'));
};

export const initSimpleTextContent = (id, writingMode, newHtmlFontSize, manageMode) => {
    let module = jm('#module' + id),
        fkEditor = module.find('.fkEditor'),
        simpletext = module.find('.simpleText'),
        manageFontSize = newHtmlFontSize,
        width = module.width(),
        autoWidth = 0,
        manageModeScript = manageMode,
        element = '',
        AJump = fkEditor.find('a');

    if (typeof width === 'string' && width.indexOf('rem') >= 0) {
        width = width.replace('rem', '');
    }

    if (manageMode) {
        var pZone = Fai.top.Mobi.getPopupZone();
        if (pZone && pZone.enterState == 'preview') {
            manageModeScript = false;
        }
    }

    element = manageModeScript ? fkEditor : simpletext;
    if (writingMode != 1 && writingMode != 2) {
        if (module.is(':visible')) {
            // mobi访客态下，这个判断一直是false
            module.css('height', 'auto');
            module.css('height', module.height());
        } else {
            if (manageModeScript) {
                autoWidth = parseInt(fkEditor.attr('_autowidth') || 1);
                // 180 是文本默认宽度
                autoWidth && (width = parseInt(fkEditor.attr('_width') || 180) / manageFontSize);
            } else {
                parseInt(module.attr('_autowidth') || 1) && (width = 180 / manageFontSize);
            }

            module.find('.fk-editor').css({ height: 'auto', width: width + 'rem' });
            module.css({ height: 'auto', width: width + 'rem' });
            if (manageModeScript) {
                module.find('.fk-editor').css('height', module.height());
            }
        }
    } else {
        if (manageModeScript && (writingMode == 1 || writingMode == 2)) {
            autoWidth = parseInt(fkEditor.attr('_autowidth') || 1);
            if (autoWidth == 1) {
                module.find('.fk-editor').css('width', 'auto');
            }
        }
        !manageModeScript && module.css('overflow', 'visible') && module.find('.formMiddle').css('overflow', 'visible');
    }
    if (manageModeScript) {
        //----------------获取文本的url在管理态下判断是否是外链，并返回管理态不支持跳转外部链接的提示--zhj
        AJump.each(function (i, v) {
            var url = jm(v).attr('href');
            if (url) {
                // 在线视频链接可能会存在http或者https，不当做外部链接处理
                if (
                    url.indexOf('Mobi.openLinkFlv') == -1 &&
                    (url.indexOf('http') != -1 || url.indexOf('https') != -1)
                ) {
                    jm(v).click(function () {
                        Fai.ing('管理态不支持跳转外部链接，请通过“预览”进行访问', true);
                        return false;
                    });
                }
            }
        });
    } else {
        module.find('.fk-editor a').each(function (index, ele) {
            var _href = jm(ele).attr('_href');

            if (_href) {
                jm(ele).attr('href', _href);
            }
        });
    }

    checkNode.traverseTagStyle(element, manageFontSize);
};

function initFkEditorVariables() {
    if (window.undoCacheEditContent == null) {
        window.undoCacheEditContent = {};
    }
    if (window.fkEditor == null) {
        window.fkEditor = {
            events: {},
            methods: {},
        };
    }
    if (window.simpleTextList == null) {
        window.simpleTextList = {
            allEditors: [],
            chooseEditor: {},
            colorToolShow: false,
            checking: false,
            checkStyleDesign: false,
        };
    }
}
