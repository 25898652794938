var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    { staticClass: "newTextGroup" },
    [
      _vm.content.po == 1
        ? [
            _c("div", { staticClass: "searchResultLine prefixOpenLine" }, [
              _c("strong", { staticClass: "prefixOpenName" }, [
                _vm._v("[" + _vm._s(_vm.groupName) + "]"),
              ]),
            ]),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.isClassifiedWrap
        ? _c(
            "div",
            { staticClass: "classifiedWrap" },
            _vm._l(_vm.item.list, function (list, index) {
              return _c("new-text-item", {
                key: `group_${index}`,
                attrs: { item: list },
              })
            }),
            1
          )
        : _vm._l(_vm.item.list, function (list, index) {
            return _c("new-text-item", {
              key: `group_${index}`,
              attrs: { item: list },
            })
          }),
      _vm._v(" "),
      !_vm.last ? _c("div", { staticClass: "searchSeparation" }) : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }