<template>
    <div class="line icon-gline j-lineH J_lineH">
        <div v-if="moduleStyle == 8 || moduleStyle == 0" class="clearFix" @click="catalogProductItemClick">
            <div class="pgName g_productSort_name" :style="pn" v-text="item.name"></div>
            <div class="pgMark"></div>
        </div>
        <a
            v-if="moduleStyle != 8 && moduleStyle != 0"
            class="pgName"
            target="_self"
            :href="
                lanCode +
                npgcUrl +
                '?mId=' +
                moduleId +
                '&pgt=' +
                2 +
                '&pgs=' +
                moduleStyle +
                '&selectCata=' +
                item.name
            "
        >
            <span class="navLineTitle g_productSort_name" :style="pn" v-text="item.name"></span>
        </a>
    </div>
</template>
<script>
export default {
    name: 'ProductGroupCatalog',
    props: {
        itemData: {
            type: Object,
            default: function () {
                return {};
            },
        },
        moduleId: {
            type: Number,
            require: true,
            default: -1,
        },
        moduleStyle: {
            type: Number,
            require: true,
            default: -1,
        },
        lanCode: {
            type: String,
            require: true,
            default: '',
        },
        npgcUrl: {
            type: String,
            require: true,
            default: '',
        },
        pn: {
            type: String,
            default: '',
        },
    },
    computed: {
        item: function () {
            return this.itemData;
        },
    },
    methods: {
        catalogProductItemClick() {
            this.$emit('itemClick', this.item);
        },
    },
};
</script>
