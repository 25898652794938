import { ing } from '@/shared/fai';
import { closeLoading } from '@/preview';
import { bindImgLazyLoad } from '@/shared/imageLazyload';
import { initModulePhotoSwipe } from './shared/photo';
import { getPreviewObject } from '@/shared/getPreviewObject';
import { getPreviewScrollDom } from '@/shared/dom';
import { request } from '@/api/request';
import { switchJump } from '@/shared/linkSetting';
import { videoQuit } from '@/features/global-mobi-function/methods/share';
import { changeHref } from '@/features/router/universal';
import { isRecommendedNewsListModule } from './type-mapping';
import { popupWindowVersionTwo } from '@/shared/fai';
import { logJZFdp } from '@/api/logDog';
const argCookieKey = '_reqArgs';
const MODULE_MAP = {
    PRODUCT_LIST: 3,
    NEWS_LIST: 6,
    PRODUCT_GROUP: 25,
    HORIZON_TAB: 50,
    PHOTO_GROUP: 34,
    FOLD: 58,
};

export const TAB_MODULE_ID = '_tabModuleId';
export const FOLD_MODULE_ID = '_foldModuleId';
export const SEARCH_ID = '_searchId';
export const SEARCH = '_search';
export const PAGE = '_page';

export let jumpModuleUtils = {
    modules: null,
    cookieMap: {},
    COOKIE_MAP_KEY: 'cookie_map_key',
    init() {
        if (this.modules == null) {
            this.modules = this.getDataFromLocal(this.getCurrentModuleId());
            if (this.modules == null) {
                this.modules = {
                    id: this.getCurrentModuleId(),
                    colId: Fai.top._colInfo.id,
                    moduleList: {},
                    scrollY: 0,
                    time: Date.now(),
                    expires: 1000 * 60 * 60 * 6, // 6小时缓存时间
                };
            }
        }

        this.cookieMap = this.getDataFromLocal(this.COOKIE_MAP_KEY) || {};
    },
    // 保存、删除、获取sessionItem相关
    getDataFromLocal(key) {
        let data = sessionStorage.getItem(key);
        return data != null ? JSON.parse(data) : null;
    },
    setDataToLocal(key, value) {
        value.time = Date.now(); // 更新时间
        sessionStorage.setItem(key, JSON.stringify(value));
    },
    removeDataFromLocal(val) {
        sessionStorage.removeItem(val);
    },

    // 模块信息相关
    addModule(moduleId, info) {
        this.init();
        this.modules.moduleList[moduleId] = info;
        this.modules.scrollY = jm('html,body')[0].scrollTop || 0;

        this.setDataToCookie(info);
        this.setDataToLocal(this.modules.id, this.modules);
        this.setDataToLocal(this.COOKIE_MAP_KEY, this.cookieMap);

        if (VITE_APP_MODE !== 'visitor') {
            this.setDataToLocal('scrollPage', {
                colId: Fai.top._colId,
                scrollTop: this.modules.scrollY || 0,
            });
        }
    },
    getModuleInfoById(id) {
        this.init();
        if (this.modules != null) {
            return this.modules.moduleList[id];
        }
    },

    removeModuleInfoById(id, option = {}) {
        this.init();
        if (this.modules != null) {
            const { isRecommendProductModule = false, pid } = option;
            if (isRecommendProductModule) {
                this.removeRecommendProductDataCookie(id, pid);
                this.setDataToLocal(this.COOKIE_MAP_KEY, this.cookieMap);
            } else {
                this.modules.moduleList[id] = null;
                this.setDataToLocal(this.modules.id, this.modules);
                this.removeDataFromCookie(id);
                this.setDataToLocal(this.COOKIE_MAP_KEY, this.cookieMap);
            }
        }
    },
    getCurrentModuleId() {
        let colInfo = Fai.top._colInfo;
        let id = colInfo.aid;
        return id;
    },

    // cookie 相关
    setDataToCookie(value, hours) {
        hours = hours || 12; // 默认cookie半天
        let selectData = value.data;
        let tempDate = new Date();
        tempDate.setTime(tempDate.getTime() + hours * 60 * 60);
        if (value.style == 25) {
            let isSearchId = value.data.pType == 'id';
            jm.cookie('module' + value.moduleId + SEARCH_ID, isSearchId, {
                expires: tempDate,
                path: '/',
                domain: location.host,
            });
            jm.cookie('module' + value.moduleId + SEARCH, isSearchId ? selectData.id : selectData.name, {
                expires: tempDate,
                path: '/',
                domain: location.host,
            });
            this.cookieMap[value.moduleId] = [
                'module' + value.moduleId + SEARCH_ID,
                'module' + value.moduleId + SEARCH,
            ];
        } else if (value.style == 6) {
            let cookieKey = '';
            if (isRecommendedNewsListModule(value.moduleId)) {
                cookieKey = `module${value.moduleId}_${value.data.newsId}${PAGE}`;
                this.cookieMap[`${value.moduleId}_${value.data.newsId}`] = [cookieKey];
            } else {
                cookieKey = `module${value.moduleId}${PAGE}`;
                this.cookieMap[value.moduleId] = [cookieKey];
            }
            jm.cookie(cookieKey, value.data.page, {
                expires: tempDate,
                path: '/',
                domain: location.host,
            });
        } else if (value.style == 50) {
            jm.cookie('module' + value.moduleId + TAB_MODULE_ID, value.data.currentModuleId, {
                expires: tempDate,
                path: '/',
                domain: location.host,
            });
            this.cookieMap[value.moduleId] = ['module' + value.moduleId + TAB_MODULE_ID];
        } else if (value.style == MODULE_MAP.PRODUCT_LIST) {
            let { moduleId, data } = value;
            let { page, pid } = data;
            let cookieOption = {
                expires: tempDate,
                path: '/',
                domain: location.host,
            };
            let cookieName = `module${moduleId}${PAGE}`;
            if (pid != null) {
                // 推荐产品
                let key = `${cookieName}_pid${pid}`;
                jm.cookie(key, page, cookieOption);
                this.cookieMap[moduleId] = [key];
            } else {
                // 普通产品展示
                jm.cookie(cookieName, page, cookieOption);
                this.cookieMap[moduleId] = [cookieName];
            }
        } else if (value.style == 34) {
            jm.cookie('module' + value.moduleId + PAGE, value.data.page, {
                expires: tempDate,
                path: '/',
                domain: location.host,
            });
            this.cookieMap[value.moduleId] = ['module' + value.moduleId + PAGE];
        } else if (value.style == 58) {
            jm.cookie('module' + value.moduleId + FOLD_MODULE_ID, value.data.currentModuleId, {
                expires: tempDate,
                path: '/',
                domain: location.host,
            });
            this.cookieMap[value.moduleId] = ['module' + value.moduleId + FOLD_MODULE_ID];
        }
    },
    removeDataFromCookie(id) {
        let cookieList = this.cookieMap[id] || [];
        cookieList.forEach(function (item) {
            jm.cookie(item, null, {
                expires: -2,
                path: '/',
                domain: location.host,
            });
        });
        this.cookieMap[id] = [];
    },
    removeRecommendProductDataCookie(id, pid) {
        let cookieList = this.cookieMap[id] || [];
        let item = cookieList.find((item) => {
            let itemPid = parseInt(item.split('pid')[1] || 0);
            return itemPid == pid;
        });
        if (item != null) {
            jm.cookie(item, null, {
                expires: -2,
                path: '/',
                domain: location.host,
            });
            this.cookieMap[id] = [];
        }
    },
};

// 获取当前模块的跳转信息
export function getJumpModuleInfo(moduleId) {
    if (moduleId == null) return;
    let data = null;
    let moduleJumpInfo = jumpModuleUtils.getModuleInfoById(moduleId);
    if (moduleJumpInfo != null) {
        data = moduleJumpInfo.data;
        jumpModuleUtils.removeModuleInfoById(moduleId); // 初始化及清除该模块跳转信息
    }
    return data;
}

export function getJumpModuleCookieByType(moduleId, type = PAGE) {
    return jm.getCookie(`module${moduleId}${type}`);
}

export function getJumpModulePageCookie(moduleId, option = {}) {
    const { type = PAGE, pid, isRecommendProductModule = false } = option;
    let page = 0;
    if (isRecommendProductModule) {
        page = parseInt(jm.getCookie(`module${moduleId}${type}_pid${pid}`) || 1);
    } else {
        page = parseInt(jm.getCookie(`module${moduleId}${type}`) || 1);
    }
    return page;
}

// 移除该模块对应的跳转信息
export function removeJumpModuleInfo(moduleId, option) {
    if (moduleId == null) return;
    jumpModuleUtils.removeModuleInfoById(moduleId, option);
}

// 添加当前模块的跳转信息
function addJumpModuleInfo(moduleId, moduleStyle, context) {
    if (moduleId == null || moduleStyle == null || context == null) return;

    let data = {};

    if (moduleStyle == MODULE_MAP.PRODUCT_GROUP) {
        data.pType = context.pType;
        data.id = context.id;
        data.name = context.name;
    } else if (moduleStyle == MODULE_MAP.NEWS_LIST) {
        data.page = context.pageno || 1;
        if (context.newsId && context.newsId !== -1) {
            data.newsId = context.newsId;
        }
    } else if (moduleStyle == MODULE_MAP.HORIZON_TAB) {
        data.currentModuleId = context.moduleId; // 存储当前子模块的id，用于判断是第几个
    } else if (moduleStyle == MODULE_MAP.PRODUCT_LIST) {
        data.page = context.pageno || 1;
        if (context.isRecommendProductModule) {
            data.pid = context.pid || 0;
        }
    } else if (moduleStyle == MODULE_MAP.PHOTO_GROUP) {
        data.page = context.pageno || 1;
    } else if (moduleStyle == MODULE_MAP.FOLD) {
        data.currentModuleId = context.moduleId;
    }

    jumpModuleUtils.addModule(moduleId, {
        moduleId: moduleId,
        style: moduleStyle,
        data: data,
    });
}

function isContainer(moduleStyle) {
    return moduleStyle == MODULE_MAP.FOLD || moduleStyle == MODULE_MAP.HORIZON_TAB;
}

function getTabOrFoldId(moduleId, type = 'tab') {
    if (moduleId == null) return;

    let $module = jm('#module' + moduleId);

    if (type == 'fold') {
        let $foldModule = $module.parents('.J_formFoldContentList');
        let inFold = 0;
        if ($foldModule.length > 0) {
            inFold = parseInt($foldModule.attr('id').split('formFoldContentList')[1]);
        }
        if (inFold > 0) return inFold;
    } else if (type == 'tab') {
        let $tabModule = $module.parents('.J_formTabContentList');
        let inTab = 0;
        if ($tabModule.length > 0) {
            inTab = parseInt($tabModule.attr('id').split('formTabContentList')[1]);
        }
        if (inTab > 0) return inTab;
    } else {
        return 0;
    }
}

export function bindJumpModuleEvent(moduleId, moduleStyle, context) {
    if (moduleId == null || moduleStyle == null) return;

    if (!isContainer(moduleStyle) && context == null) return;

    let tabId = getTabOrFoldId(moduleId);
    let foldId = getTabOrFoldId(moduleId, 'fold');
    switch (parseInt(moduleStyle)) {
        case MODULE_MAP.NEWS_LIST:
        case MODULE_MAP.PRODUCT_GROUP:
        case MODULE_MAP.PHOTO_GROUP:
        case MODULE_MAP.PRODUCT_LIST:
            jm('#module' + moduleId + ' a')
                .unbind('click.jumpInfo')
                .bind('click.jumpInfo', function () {
                    addJumpModuleInfo(moduleId, moduleStyle, context);
                    // 记录在标签模块里的位置
                    if (tabId > 0) {
                        addJumpModuleInfo(tabId, MODULE_MAP.HORIZON_TAB, {
                            moduleId: moduleId,
                        });
                    }
                    if (foldId > 0) {
                        addJumpModuleInfo(foldId, MODULE_MAP.FOLD, {
                            moduleId: moduleId,
                        });
                    }
                });
            break;
        case MODULE_MAP.HORIZON_TAB:
            jm('#module' + moduleId + ' .J_formTabButton')
                .unbind('click.jumpInfo')
                .bind('click.jumpInfo', function () {
                    let idStr = jm(this).attr('id');
                    if (idStr != null) {
                        const id = idStr.split('formTabButton')[1] || 0;
                        const tabId = moduleId;
                        id != 0 &&
                            addJumpModuleInfo(tabId, MODULE_MAP.HORIZON_TAB, {
                                moduleId: id,
                            });
                    }
                });
            break;
        case MODULE_MAP.FOLD:
            jm('#module' + moduleId + ' .J_formFoldItem')
                .unbind('click.jumpInfo')
                .bind('click.jumpInfo', function () {
                    const idStr = jm(this).attr('id') || 0;
                    const foldId = moduleId;
                    if (idStr != null) {
                        const id = idStr.split('formFoldItem')[1] || 0;
                        id != 0 &&
                            addJumpModuleInfo(foldId, MODULE_MAP.FOLD, {
                                moduleId: id,
                            });
                    }
                });
            break;
        default:
            break;
    }
}

export function getIsOpenRemoveUrlArg() {
    return Fai.top._openRemoveUrlArgs;
}

export function createUrlArgsOnclickStr(args, _openRemoveUrlArgs = false, hashRemoved = false) {
    if (!args) return null;
    const argsStr = typeof args === 'string' ? args : JSON.stringify(args);

    if (_openRemoveUrlArgs || hashRemoved) {
        return `Fai.top.JZ.setUrlArgToCookie(this, ${argsStr}); return false;`;
    }
    return null;
}

function getIsSetReqHttpOnlyCookie() {
    return Fai.top._setReqHttpOnlyCookie;
}

export function handleSetUrlArgToCookie(args) {
    return new Promise((resolve, reject) => {
        try {
            if (typeof args === 'object') {
                args = JSON.stringify(args);
            }
        } catch (err) {
            console.error(err);
            resolve();
        }
        if (getIsSetReqHttpOnlyCookie()) {
            request
                .post('/ajax/setCookie_h.jsp', {
                    data: {
                        cmd: 'setWafCk_setReqArgsCookie',
                        _reqArgs: args,
                    },
                })
                .catch(function (err) {
                    reject(err);
                })
                .then(function () {
                    resolve();
                });
        } else {
            jm.cookie(argCookieKey, args, { path: '/' });
            resolve();
        }
    });
}

export function setUrlArgToCookie(element, args) {
    const href = element.href || '';
    if (!href) return;
    if (VITE_APP_MODE !== 'visitor') {
        if (Vue.prototype.$designer.checkSaveBar({ fun: setUrlCookie }, true)) {
            setUrlCookie();
        }
    } else {
        setUrlCookie();
    }

    function setUrlCookie() {
        handleSetUrlArgToCookie(args)
            .then(() => {
                let cloneA = element.cloneNode();
                cloneA.setAttribute('onclick', '');
                cloneA.click();
                cloneA = null;
            })
            .catch((err) => console.error(err));
    }

    return false;
}

export function setHrefEventHasReqArgs(dom) {
    if (!getIsOpenRemoveUrlArg()) return;
    if (!dom) return;
    let $dom = dom;
    try {
        if (dom instanceof Node || dom instanceof NodeList) {
            $dom = $(dom);
        }
    } catch (err) {
        console.error(err);
    }
    if ($dom.length === 0) return;
    if ($dom.prop('setReqArgsClick') == '1') {
        return;
    }
    $dom.prop('setReqArgsClick', '1');
    $dom.off('click.setReqArgs').on('click.setReqArgs', 'a', function (event) {
        const $a = $(this);
        const _reqArgs = $a.attr('_reqargs');
        if (!_reqArgs) return;
        event.preventDefault();
        setUrlArgToCookie($a[0], _reqArgs);
    });
}

export function setUrlArgToCookieAndJump(url, args) {
    if (getIsOpenRemoveUrlArg() && args) {
        handleSetUrlArgToCookie(args).then(() => {
            changeHref(url);
        });
    } else {
        changeHref(url);
    }
}

/*
type 1:电话服务、2:信息服务
*/
export const triggerServiceNumber = function (type, number) {
    if (jm.os.supportsTouch) {
        if (type == 1) {
            // 这里进行 百度小程序兼容， swan 来自引入的脚本
            const swan = window.swan;
            var isBaiduMiniAppEnv = window.navigator.userAgent.indexOf('swan/') != -1;
            if (typeof swan != 'undefined' && isBaiduMiniAppEnv) {
                swan.webView.getEnv(function (res) {
                    if (res.smartprogram) {
                        //判断是否是小程序环境
                        swan.makePhoneCall({
                            phoneNumber: number,
                        });
                    } else {
                        window.location.href = 'tel:' + number; //web环境
                    }
                });
            } else {
                window.location.href = 'tel:' + number;
            }
        } else if (type == 2) {
            window.location.href = 'sms:' + number;
        }
    } else {
        ing('该功能无法在桌面浏览器中启动');
    }
};

let ajaxDone = true;
export function createFullScreenDiv(divId, cmd, datas) {
    if (!ajaxDone) {
        return;
    }
    if (!cmd) {
        ing(LS.mallStlSubmitError);
        return;
    }

    if (!datas) {
        ing(LS.mallStlSubmitError);
        return;
    }

    if (jm('#' + divId).length > 0) {
        jm('#' + divId).remove();
    }
    jm('#fullScreenDivCotainer').append(
        "<div id='" +
            divId +
            "' class='fullScreenDiv webBackground webContainerBox moduleContent categoryModel refreshBase'></div>"
    );
    jm('#' + divId).css('background-image', 'none');
    jm('#' + divId).css('background-image', jm('#webBodyBackground').css('background-image'));
    //Mobi.showLoading();
    var loadingPath = '';
    if (cmd == 'getWafNotCk_productDetail') {
        loadingPath = '&loadingPath=' + Fai.top._loadingPath;
    }
    request
        .post('/ajax/other_h.jsp?cmd=' + cmd + loadingPath, {
            data: datas,
        })
        .catch(function () {
            closeLoading();
            ing(LS.js_systemError);
            ajaxDone = true;
        })
        .then(function (response) {
            const { data } = response;

            if (data.success) {
                jm('#' + divId).append(data.html);
                jm('#' + divId).addClass('showFullScreen');
                getPreviewObject(divId).focus();
                switchJump(); // 详情内容切换成mobi的跳转链接
                initModulePhotoSwipe(divId);
                if (cmd == 'getWafNotCk_productDetail') {
                    bindImgLazyLoad('productRemarkDiv');
                }
            }
            ajaxDone = true;
            closeLoading();
        });
}

// 单独绑定懒加载
export const loadImgAlone = function (id, other) {
    //other参数可不填，可以填除id之外，还需要的其他特别参数
    var $moduleDom = jm('#module' + id),
        windowHeight = jm(window).height(),
        mType = -1;

    if ($moduleDom.hasClass('formStyle31')) {
        //魔方导航
        lazyloadCubeNavigation(windowHeight, id);
    } else if ($moduleDom.hasClass('formStyle5')) {
        //产品筛选结果
        mType = parseInt(other.type);
        if (mType == 3) {
            //产品筛选结果样式二
            lazyLoadProductList_2(windowHeight, id, 2);
        }
    }
};

//产品样式二 | 产品展示样式一和六
function lazyLoadProductList_2(windowHeight, id, type) {
    //type,1:产品展示样式一和六，2：产品展示样式二
    var $imgDom;
    if (!windowHeight || typeof windowHeight != 'number') {
        windowHeight = jm(window).height();
    }
    if (id) {
        if (type == 2) {
            $imgDom = jm('#module' + id).find('.mProductList img.style3Img');
        } else {
            $imgDom = jm('#module' + id).find('.mProductList img.style1Img');
        }
    }

    //产品展示样式2
    $imgDom.lazyload({
        threshold: windowHeight,
        load: null,
    });
}

// 魔方导航
function lazyloadCubeNavigation(windowHeight, id) {
    var $imgDom;
    if (!windowHeight || typeof windowHeight != 'number') {
        windowHeight = jm(window).height();
    }
    if (id) {
        $imgDom = jm('#module' + id).find('.cubeNavigationArea div.J_cubeLink_bg');
    } else {
        $imgDom = jm('.cubeNavigationArea div.J_cubeLink_bg');
    }
    $imgDom.lazyload({
        threshold: parseInt(windowHeight / 2),
        loadingClass: '',
        load: null,
    });
}

// 滚动到对应模块
export function scrollToModule(id) {
    var module = jm('#module' + id),
        webHeaderBox = jm('.webHeaderBox'),
        $gWeb = jm('#g_web'),
        headerHeight = 0;

    if (typeof module == 'undefined' || module.length < 1) {
        return;
    }

    if (webHeaderBox.css('position') == 'fixed') {
        headerHeight = parseInt(webHeaderBox.height());
    }

    let scrollElem = document.getElementById('g_web');
    if (window._openThemeV3) {
        let headerBoxHeight = parseInt(jm('#webHeader_v3').height() || 0);
        let bannerBoxHeight = parseInt(jm('#navbar').height() || 0);
        scrollElem.scrollTop =
            module.offset().top - headerBoxHeight - bannerBoxHeight - $gWeb.offset().top + scrollElem.scrollTop;
    } else {
        scrollElem.scrollTop = module.offset().top - headerHeight - $gWeb.offset().top + scrollElem.scrollTop;
    }
}

export const setTempDataToLinkSetting = function () {
    var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    Fai.top._tempDataToLinkSetting = data;
};

//新增面板的动画
export const addModuleAnmaition = function () {
    var $modulePanel = $('.module-add-panel');
    $modulePanel.parent().css('height', '100%');
    $modulePanel.parent().parent().css('height', '150%');

    $modulePanel.animate({ top: '120px' }, 100, function () {
        $modulePanel.delay(100);
        $modulePanel.animate({ top: '0' }, 300, function () {
            $modulePanel.parent().css('height', '');
            $modulePanel.parent().parent().css('height', '100%');
        });
    });
};

export const resetModuleAddState = function (modulePosition, inContainerId, childPosIndex) {
    window.$store.dispatch('manage/moduleAdd/changeModulePosition', modulePosition);
    window.$store.dispatch('manage/moduleAdd/changeInContainerId', inContainerId);
    window.$store.dispatch('manage/moduleAdd/changeChildPosIndex', childPosIndex);
};

export const scrollFadeInV3 = function (fadeInPanel, paddingPanel, pageStyle) {
    paddingPanel = document.getElementById(paddingPanel);
    fadeInPanel = document.getElementById(fadeInPanel);

    const scrollDom = getPreviewScrollDom();

    var g_body = scrollDom;
    if (!fadeInPanel) {
        return;
    }

    const roundIcon = jm(fadeInPanel).find('.g_round');

    jm(scrollDom).off('touchstart.hfi').on('touchstart.hfi', productHeadFadeInScroll);
    jm(scrollDom).off('touchmove.hfi').on('touchmove', productHeadFadeInScroll);
    jm(scrollDom).off('touchend.hfi').on('touchend', productHeadFadeInScroll);
    jm(scrollDom).off('touchcancel.hfi').on('touchcancel.hfi', productHeadFadeInScroll);
    jm(scrollDom).off('scroll.hfi').on('scroll.hfi', productHeadFadeInScroll);

    productHeadFadeInScroll();
    function productHeadFadeInScroll() {
        var fadeInPanelClientHeigth = fadeInPanel && fadeInPanel.clientHeight,
            paddingPanelClientHeight = paddingPanel && paddingPanel.clientHeight;
        var scrollTop = getScrollTop();
        if (pageStyle == 1) {
            return;
        }

        if (scrollTop < paddingPanelClientHeight + 1) {
            var opacity = (scrollTop * 1) / (paddingPanelClientHeight - fadeInPanelClientHeigth * 2);
            fadeInPanel.style.background = 'rgba(255,255,255,' + opacity + ')';
            fadeInPanel.style.borderBottom = '';
            var rounIconOpacity = (1 - opacity * 3) * 0.4;
            // if(scrollTop > 0){
            // 	roundIcon.addClass("drop");
            // }else{
            // 	roundIcon.removeClass("drop");
            // }
            var num = parseInt(255 * (1 - opacity));
            if (num < 51) {
                num = 51;
            }
            roundIcon.css('background', 'rgba(0,0,0,' + rounIconOpacity + ')');
            roundIcon.css('color', 'rgb(' + num + ',' + num + ',' + num + ')');
        }

        if (scrollTop > paddingPanelClientHeight - fadeInPanelClientHeigth * 2) {
            //不知道为什么，透明度设置成1时，在较为旧版的360浏览器中区域底部会出现一截黑色的直线，只能改成0.99
            fadeInPanel.style.background = 'rgba(255,255,255,0.99)';
            fadeInPanel.style.borderBottom = '1px solid #e5e5e5';
        }
    }

    function getScrollTop() {
        var dom = g_body;
        // w3c的标准是用document.documentElement获取滚动条高度，但是低版本chrom不兼容

        if (document.getElementById('g_web') && document.getElementById('g_web').scrollTop > 0) {
            dom = document.getElementById('g_web');
        }

        return typeof dom.scrollTop != 'undefined' ? dom.scrollTop : 0;
    }
};

export const scrollFadeIn = function (fadeInPanel, paddingPanel, pageStyle) {
    paddingPanel = document.getElementById(paddingPanel);
    fadeInPanel = document.getElementById(fadeInPanel);
    var g_body = document.getElementById('g_body'),
        fadeInPanelClientHeigth = fadeInPanel && fadeInPanel.clientHeight,
        paddingPanelClientHeight = paddingPanel && paddingPanel.clientHeight,
        isTabUlFix = false,
        isNavbarFix = false,
        isNewProductDetail = jm(fadeInPanel).parent().hasClass('productDetail_v3');

    document.addEventListener(
        'touchstart',
        function () {
            productHeadFadeInScroll();
            productTabUlScroll();
        },
        false
    );
    document.addEventListener(
        'touchmove',
        function () {
            productHeadFadeInScroll();
            productTabUlScroll();
        },
        false
    );
    document.addEventListener(
        'touchend',
        function () {
            productHeadFadeInScroll();
            productTabUlScroll();
        },
        false
    );
    document.addEventListener(
        'touchcancel',
        function () {
            productHeadFadeInScroll();
            productTabUlScroll();
        },
        false
    );
    document.getElementById('g_web').addEventListener(
        'scroll',
        function () {
            productHeadFadeInScroll();
            productTabUlScroll();
        },
        false
    );

    productHeadFadeInScroll();
    function productHeadFadeInScroll() {
        var scrollTop = getScrollTop();
        if (pageStyle == 1) {
            return;
        }

        if (scrollTop < paddingPanelClientHeight + 1) {
            var opacity = (scrollTop * 1) / paddingPanelClientHeight;
            if (fadeInPanel) {
                fadeInPanel.style.background = 'rgba(255,255,255,' + opacity + ')';
                fadeInPanel.style.borderBottom = '';
            }
            isNavbarFix = true;
        }

        if (scrollTop > paddingPanelClientHeight && isNavbarFix) {
            //不知道为什么，透明度设置成1时，在较为旧版的360浏览器中区域底部会出现一截黑色的直线，只能改成0.99
            if (fadeInPanel) {
                fadeInPanel.style.background = 'rgba(255,255,255,0.99)';
                fadeInPanel.style.borderBottom = '1px solid #ddd';
            }
            isNavbarFix = false;
        }
    }

    //如果header 下面的导航不存在则下面固定导航条的代码不执行
    window.productTabUl = document.getElementById('productTabUl');

    // eslint-disable-next-line no-undef
    if (productTabUl != null && productTabUl != undefined) {
        // eslint-disable-next-line no-undef
        var productTabUlClientHeight = productTabUl.clientHeight,
            // eslint-disable-next-line no-undef
            productTabUlOffsetTop = productTabUl.offsetTop;

        Mobi.productTabUlScroll = productTabUlScroll;
    }
    function productTabUlScroll() {
        var scrollTop = getScrollTop();
        if (scrollTop > productTabUlOffsetTop - fadeInPanelClientHeigth && !isTabUlFix) {
            // eslint-disable-next-line no-undef
            jm(productTabUl).addClass('tabUl_fix');
            isTabUlFix = true;
            if (isNewProductDetail) {
                fadeInPanel.style.borderBottom = '';
            }
            if (jm('.productVideoMask').length != 0) {
                videoQuit();
            }
        }

        if (scrollTop + productTabUlClientHeight < productTabUlOffsetTop && isTabUlFix) {
            // eslint-disable-next-line no-undef
            jm(productTabUl).removeClass('tabUl_fix');
            isTabUlFix = false;
            if (isNewProductDetail) {
                fadeInPanel.style.borderBottom = '1px solid #ddd';
            }
        }
    }

    function getScrollTop() {
        var dom = g_body;
        // w3c的标准是用document.documentElement获取滚动条高度，但是低版本chrom不兼容
        if (document.getElementById('g_web') && document.getElementById('g_web').scrollTop > 0) {
            dom = document.getElementById('g_web');
        }

        return typeof dom.scrollTop != 'undefined' ? dom.scrollTop : 0;
    }
};

export const uploadProgress = function (options) {
    //导入进度显示
    //options.status
    //start:开始
    if (options.status == 'start') {
        var tr2 = [
            '<div id="progressBody_' + options.id + '" class="bodyDisable"></div>',
            '<div id="progressWrap_' + options.id + '" class="bodyProgressWrap">',
            '<div class="progressCenter"></div>',
            '<div class="progressIngBody">',
            '<div id="progressTitle' + options.id + '" class="progressIngTitle">' + options.title + '</div>',
            '<div class="progressIngMission">',
            '<div class="mission"><div id="progress' + options.id + '" class="progress" style="width:1%;"></div></div>',
            '<div id="progressNum' + options.id + '" class="progressNum">0%</div>',
            '</div>',
            '<div class="progressInfo"><span id="progressTips' +
                options.id +
                '" class="progressFileSize"></span><a class="progressCancel" href="javascript:uploadCancel(\'' +
                options.id +
                '\');">取消</a></div>',
            '</div>',
            '</div>',
        ];
        jm('body').append(tr2.join(''));
    } else if (options.status == 'ing') {
        jm('#progressTitle' + options.id).text(options.title);
        jm('#progress' + options.id).css('width', Math.round(options.percent) + '%');
        jm('#progressNum' + options.id).html(Math.round(options.percent) + '%');
    } else if (options.status == 'end') {
        jm('#progressTitle' + options.id).text(options.title);
        jm('#progressBody_' + options.id).remove();
        jm('#progressWrap_' + options.id).remove();
    } else if (options.status == 'error') {
        jm('#progress' + options.id).addClass('progressError');
    }
};

export function openPluginPopup() {
    popupWindowVersionTwo.createPopupWindow({
        title: '插入网站插件代码',
        frameSrcUrl: `/manage_v2/mobiPlugInCodeEdit.jsp?&ram=${Math.random()}&fromPopup=true`,
        frameScrolling: 'no',
        width: 530,
        height: 495,
        version: 2,
        saveBeforePopup: false,
    });
}

export function initModuleTransformTrack(moduleId, moduleStyle, tts) {
    const $module = jm(`#module${moduleId}`);
    if (tts == null) {
        console.info(`模块${moduleId}中tts内容为空`);
        return;
    }
    const { t, al = [], e } = tts;
    // 符合版本条件 && 开关开启 && （含有付费域名 || 内部账号 || 模板账号)
    if (
        window.$store.state?.app?.realNameAuth?.allowShowMessage &&
        window?.transformatinTrackingGlobalModel?.openTransformationTracking
    ) {
        if (moduleStyle == 54) {
            // 按钮模块
            let $btn = $module.find('a');
            t == 0
                ? initTransformTrackAttr($btn, al, '按钮模块')
                : initTransformTrackEvent($btn, moduleId, e, '按钮模块');
        } else if (moduleStyle == 13 || moduleStyle == 84) {
            // 表单模块
            let $btn = $module.find('.submit_btn');
            t == 0
                ? initTransformTrackAttr($btn, al, '表单提交按钮模块')
                : initTransformTrackEvent($btn, moduleId, e, '表单提交按钮模块');
        }
    }
}

function initTransformTrackAttr($el, al = [], moduleName) {
    al.forEach((item) => {
        $el.attr(item.k, item.v);
    });
    if (al.length) {
        $el.on('click.transformTrack', function () {
            transformTrackFdpLog(moduleName);
        });
    }
}

function initTransformTrackEvent($el, moduleId, eventStr, moduleName) {
    if (eventStr == null) {
        console.warn(`${moduleId} 的事件为空`);
        return;
    }
    let matchList = eventStr.match(/(function)([ \t])([^\\(]+)/);
    if (matchList == null || (matchList != null && (matchList[3] == null || matchList[3] == ''))) {
        console.log(`模块${moduleId}的事件名不存在, ${eventStr}`);
    } else {
        try {
            let fnName = matchList[3];
            // 挂载script
            const scriptEl = document.createElement('script');
            scriptEl.type = 'text/javascript';
            scriptEl.innerHTML = eventStr;
            scriptEl.id = `module${moduleId}_track`;
            $('body').append(scriptEl);
            // 事件
            $el.on('click', function () {
                try {
                    transformTrackFdpLog(moduleName);
                    window[fnName] && window[fnName]();
                } catch (err) {
                    console.log(err);
                }
            });
        } catch (err) {
            console.log(err);
        }
    }
}

function transformTrackFdpLog(moduleName) {
    logJZFdp('jz_transformation_tracking_trigger', {
        jz_content_terminal: 'mobi',
        jz_free_text1: moduleName,
    });
}
