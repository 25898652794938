var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "photo_wf_container", style: _vm.photoSpaceStyle },
    [
      _c(
        "div",
        { staticClass: "photo_wf_columns", style: _vm.columnsLeftSpaceStyle },
        _vm._l(_vm.waterFallItemList.oneList, function (item, index) {
          return _c("WaterFallItem", {
            key: item.picId,
            attrs: {
              "photo-item": item,
              "link-type": _vm.linkType,
              "img-href": _vm.imgHref(item.jumpUrl),
              "module-content": _vm.module.content,
              "is-head-or-tail": item.isHeadOrTail,
              "is-empty": _vm.isEmptyText,
              "loading-path": _vm.options.loadingPath,
              "no-lazy-load": _vm.noLazyLoad,
              "is-left": true,
              "img-index": index + 1,
              "slides-index": item.index,
              "module-id": _vm.moduleId,
              "list-length": _vm.waterFallItemList.oneList.length,
            },
          })
        }),
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "photo_wf_columns", style: _vm.columnsRightSpaceStyle },
        _vm._l(_vm.waterFallItemList.twoList, function (item, index) {
          return _c("WaterFallItem", {
            key: item.picId,
            attrs: {
              "module-id": _vm.moduleId,
              "photo-item": item,
              "link-type": _vm.linkType,
              "img-href": _vm.imgHref(item.jumpUrl),
              "module-content": _vm.module.content,
              "is-head-or-tail": item.isHeadOrTail,
              "is-empty": _vm.isEmptyText,
              "loading-path": _vm.options.loadingPath,
              "no-lazy-load": _vm.noLazyLoad,
              "is-left": false,
              "slides-index": item.index,
              "img-index": index + 1,
              "list-length": _vm.waterFallItemList.twoList.length,
            },
          })
        }),
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }