var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("module-frame", { attrs: { "module-id": _vm.moduleId } }, [
    !_vm.module.content.vs.nt &&
    _vm.module.content.t === 0 &&
    _vm.module.content.iu != ""
      ? _c("div", { attrs: { id: "video" + _vm.id } }, [
          _c(
            "video",
            {
              staticClass: "mobiVideo",
              staticStyle: { "object-fit": "scale-down" },
              attrs: {
                id: _vm.videoTagId,
                loop: _vm.loop,
                poster: _vm.videoFirstImgUrl,
                controls: "",
                "webkit-playsinlin": "",
                playsinline: "",
                "data-original": _vm.module.content.ip,
                src: _vm.isJzCdnFlowLimit ? "" : _vm.module.content.ip,
              },
            },
            [_vm._v("\n            抱歉，您的浏览器不能查看该视频。\n        ")]
          ),
        ])
      : !_vm.module.content.vs.nt &&
        _vm.module.content.t != 0 &&
        _vm.module.content.iu != ""
      ? _c("div", { attrs: { id: "video" + _vm.id } }, [
          _c(
            "div",
            { staticClass: "mobiVideoOnline", attrs: { id: "video" + _vm.id } },
            [
              _vm.illnormalHttp && !_vm.module.content.urlIdErr
                ? _c("div", { staticClass: "moduleHttpTip" }, [
                    _vm._v(
                      "\n                在线视频存在非HTTPS链接，该链接无法正常加载显示，请替换成HTTPS链接或使用非HTTPS方式访问\n            "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.urlDomainYouku && !_vm.module.content.urlIdErr
                ? _c("iframe", {
                    ref: "youku",
                    staticClass: "mobiVideoOnlineIframe",
                    attrs: {
                      frameborder: "0",
                      src: "//player.youku.com/embed/" + _vm.urlId + "?",
                      allowfullscreen: "",
                    },
                  })
                : _vm.urlDomainYoukuPlayer && !_vm.module.content.urlIdErr
                ? _c("iframe", {
                    ref: "domainYouku",
                    staticClass: "mobiVideoOnlineIframe",
                    attrs: {
                      frameborder: "0",
                      src: "//player.youku.com/embed/" + _vm.urlId,
                      allowfullscreen: "",
                    },
                  })
                : _vm.urlDomainQQ && !_vm.module.content.urlIdErr
                ? _c("iframe", {
                    ref: "domainQQ",
                    staticClass: "mobiVideoOnlineIframe",
                    attrs: {
                      frameborder: "0",
                      src:
                        "//v.qq.com/iframe/player.html?vid=" +
                        _vm.urlId +
                        "&tiny=0&auto=0",
                      allowfullscreen: "",
                    },
                  })
                : _vm.canOtherSiteVideo && _vm.module.content.t == 2
                ? [
                    _c("iframe", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isiFrameAddress,
                          expression: "isiFrameAddress",
                        },
                      ],
                      ref: "other",
                      staticClass: "mobiVideoOnlineIframe",
                      attrs: {
                        frameborder: "0",
                        src: _vm.otherSiteVideo,
                        allowfullscreen: "",
                      },
                    }),
                    _vm._v(" "),
                    _c("video", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.isiFrameAddress,
                          expression: "!isiFrameAddress",
                        },
                      ],
                      staticClass: "mobiVideo",
                      staticStyle: { "object-fit": "scale-down" },
                      attrs: {
                        "data-original": _vm.module.content.iu,
                        src: _vm.isJzCdnFlowLimit ? "" : _vm.module.content.iu,
                        controls: "",
                        "webkit-playsinlin": "",
                        playsinline: "",
                      },
                    }),
                  ]
                : _vm.canOtherSiteVideo && _vm.module.content.t == 1
                ? _c("video", {
                    staticClass: "mobiVideo",
                    staticStyle: { "object-fit": "scale-down" },
                    attrs: {
                      "data-original": _vm.module.content.iu,
                      src: _vm.isJzCdnFlowLimit ? "" : _vm.module.content.iu,
                      controls: "",
                    },
                  })
                : _c(
                    "video",
                    {
                      staticClass: "mobiVideo",
                      staticStyle: { "object-fit": "scale-down" },
                      attrs: {
                        id: _vm.videoTagId,
                        controls: "",
                        "data-original": _vm.module.content.iu,
                        src: _vm.isJzCdnFlowLimit ? "" : _vm.module.content.iu,
                      },
                    },
                    [
                      _vm._v(
                        "\n                抱歉，您的浏览器不能查看该视频。\n            "
                      ),
                    ]
                  ),
            ],
            2
          ),
        ])
      : _vm.module.content.vs.nt && _vm.module.content.iu != ""
      ? _c("div", { staticClass: "videoDiv", on: { click: _vm.videoDialog } }, [
          _c("div", { staticClass: "videoPlay faisco-icons-" }),
          _vm._v(" "),
          _c("div", {
            staticClass: "videoPoster J_img_lazyload",
            style: _vm.videoPosterStyle,
            attrs: { "src-original": _vm.videoFirstImgUrl },
          }),
        ])
      : _vm.module.content.iu == "" && _vm.manageMode
      ? _c(
          "div",
          { staticClass: "empty_tips_panel" },
          [
            _c("div", { staticClass: "text" }, [
              _vm._v("请在右侧面板添加视频"),
            ]),
            _vm._v(" "),
            _c(
              "button-component",
              {
                attrs: { active: "" },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.addVideo.apply(null, arguments)
                  },
                },
              },
              [_vm._v("添加视频")]
            ),
          ],
          1
        )
      : _vm.module.content.iu == "" && !_vm.manageMode
      ? _c("div", { staticClass: "cus_Empty_tips_panel" }, [
          _c("div", { staticClass: "text" }, [_vm._v("没有添加视频")]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }