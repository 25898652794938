var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "horizonContainer",
      attrs: { id: _vm.photoItem.picId, "data-picid": _vm.photoItem.picId },
    },
    [
      _c("div", { staticClass: "horizonContent", style: _vm.horizonContent }, [
        _c(
          "a",
          {
            staticClass: "J_img_warp",
            style: _vm.picLinkStyle,
            attrs: {
              rel: _vm.photoItem.nf ? "nofollow" : "",
              href: _vm.imgHref,
              onclick: _vm.jumpOnclickStr,
            },
            on: { click: _vm.photoSlidesshow },
          },
          [
            _c("div", {
              directives: [
                {
                  name: "drag-resize",
                  rawName: "v-drag-resize",
                  value: _vm._resizeOptions,
                  expression: "_resizeOptions",
                },
              ],
              staticClass: "horizon_img J_img_lazyload",
              style: _vm.photoItemImgStyle,
              attrs: { "src-original": _vm.photoItem.picThumbPath },
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "horizion_text", style: _vm.picTextStyle },
              [
                _c("div", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showTitle,
                      expression: "showTitle",
                    },
                  ],
                  class: _vm.titleClass,
                  style: _vm.titleWrapStyle,
                  domProps: { innerHTML: _vm._s(_vm.titleData) },
                }),
                _vm._v(" "),
                _c("div", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showDesc,
                      expression: "showDesc",
                    },
                  ],
                  class: _vm.descClass,
                  style: _vm.descWrapStyle,
                  domProps: { innerHTML: _vm._s(_vm.descData) },
                }),
              ]
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _vm.showDivider ? _c("div", { style: _vm.dividerStyle }) : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }