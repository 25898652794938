var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { style: _vm.columnsStyle },
    _vm._l(_vm.contentList, function (item, index) {
      return _c("VerticalItem", {
        key: item.picId,
        attrs: {
          "module-content": _vm.module.content,
          "module-id": _vm.module.id,
          "photo-item": item,
          "link-type": _vm.linkType,
          "img-href": _vm.imgHref(item.jumpUrl),
          "img-index": index + 1,
          "is-empty": _vm.isEmptyText,
          "slides-index": item.index,
          "max-height": _vm.imgStyleHeight(index),
          "loading-path": _vm.options.loadingPath,
          "no-lazy-load": _vm.noLazyLoad,
          "list-length": _vm.contentList.length,
        },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }