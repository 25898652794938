var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("input", {
    ref: "inputRef",
    staticClass: "m_mobi_form_input m_mobi_form_input_text",
    style: _vm._mixinsInputStyle,
    attrs: { type: "text", placeholder: _vm.formItem.placeholder },
    domProps: { value: _vm.showValue },
    on: {
      input: _vm.inputHandler,
      focus: _vm.focusHandler,
      blur: _vm.blurHandler,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }