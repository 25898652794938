var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showNewTop
    ? _c("div", { staticClass: "newTopStyle", class: _vm.newTopStyleType }, [
        _vm.topType == 3
          ? _c("svg", { staticClass: "jzm-v29", attrs: { role: "img" } }, [
              _c("use", { attrs: { "xlink:href": "#jzm-v29" } }),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.topType == 1
          ? _c("svg", { staticClass: "jzm-v30", attrs: { role: "img" } }, [
              _c("use", { attrs: { "xlink:href": "#jzm-v30" } }),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.topType == 2
          ? _c("svg", { staticClass: "jzm-v31", attrs: { role: "img" } }, [
              _c("use", { attrs: { "xlink:href": "#jzm-v31" } }),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.topType == 6
          ? _c("div", { staticClass: "topContentBox" })
          : _vm._e(),
        _vm._v(" "),
        _vm.topType == 4
          ? _c("div", { staticClass: "topContentBox" })
          : _vm._e(),
        _vm._v(" "),
        _vm.topType == 5
          ? _c("div", { staticClass: "topContentBox" })
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }