<template>
    <module-frame :module-id="moduleId">
        <div class="calculationQuotation" :class="moduleStyle">
            <div
                v-for="(variable, index) in variableList"
                class="variable_item"
                :style="itemMarginStyle"
                :class="variableCls(variable)"
                @click.stop="manageItem(variable, index)"
            >
                <div
                    v-if="!variableHide"
                    class="variable_name"
                    :style="variableFontStyle"
                    v-html="encodeHtml(variable.name)"
                ></div>
                <div v-if="variable.type == 0" class="variable_content">
                    <variable-input
                        v-model="variable.value"
                        :place-holder="variable.rt"
                        :input-style="inputFontStyle"
                        :input-box-style="inputBoxStyle"
                    ></variable-input>
                    <div
                        v-if="variable.uHide == 0"
                        class="variable_unit"
                        :style="unitFontStyle"
                        v-html="unitText(variable.uType, variable.unit)"
                    ></div>
                </div>
                <div v-if="variable.type == 1" class="variable_content">
                    <variable-select
                        v-model="variable.valueData"
                        :list="variable.ol"
                        :place-holder="variable.rt"
                        :input-style="inputFontStyle"
                        :input-box-style="inputBoxStyle"
                        :option-font-style="optionFontStyle"
                    ></variable-select>
                </div>
            </div>
            <div
                :id="'calculateModuleBtn' + moduleId"
                class="calculation_submit_wrap"
                @mousemove="mousemoveHandler"
                @mouseleave="mouseleaveHandler"
            >
                <button
                    :style="calcBtnStyle"
                    class="calculation_submit g_main_bgColor_v3 g_oldThemeBgColor"
                    @click="onSubmit"
                    v-html="encodeHtml(btnText)"
                ></button>
            </div>
        </div>
    </module-frame>
</template>

<script>
import VariableInput from './components/VariableInput.vue';
import VariableSelect from './components/VariableSelect.vue';
import { mapGetters } from 'vuex';

import { ing } from '@/shared/tips';
import { logDog } from '@/shared/log';
import { calculationModalFrame } from './service';
import ModuleFrame from '@/modules/frame/index.vue';
import { logJZFdp } from '@/api/logDog';
import { removeCtrlStyleCssList2, setCtrlStyleCssList } from '@/shared/style';
import { CalculationModuleBtnManage } from '@/modules/calculationQuotation/module/service';
import { encodeHtml } from '@/shared/util';
const manageMode = VITE_APP_MODE !== 'visitor';

export default {
    name: 'CalculationQuotation',
    components: {
        VariableInput,
        VariableSelect,
        ModuleFrame,
    },
    props: {
        moduleId: {
            type: Number,
            default: -1,
        },
    },
    data() {
        return {
            unitGroup: [
                {
                    text: '价格',
                    options: ['元', '角', '分'],
                },
                {
                    text: '数量',
                    options: ['个', '支', '只', '对'],
                },
                {
                    text: '重量',
                    options: ['kg', 'g', 'mg'],
                },
                {
                    text: '容积',
                    options: ['ml', 'l', 'gal'],
                },
                {
                    text: '长度',
                    options: ['m', 'dm', 'cm', 'mm'],
                },
                {
                    text: '面积',
                    options: ['㎡', 'd㎡', 'c㎡', 'm㎡'],
                },
                {
                    text: '体积',
                    options: ['m³', 'dm³', 'cm³', 'mm³'],
                },
            ],
            submitData: [],
        };
    },
    computed: {
        ...mapGetters(['getModuleById']),
        options() {
            return this.module.renderOptions;
        },
        module() {
            return this.getModuleById(this.moduleId);
        },
        content() {
            return this.module.content;
        },
        btnText() {
            return this.module.content.bs.bt;
        },
        moduleStyle() {
            let style = this.module.content.style;
            if (style > 0) {
                return 'moduleStyle' + (this.module.content.style + 1);
            } else {
                return '';
            }
        },
        variableList() {
            return this.module.content.vl;
        },
        variableHide() {
            return this.module.content.vh == 1;
        },
        variableFontStyle() {
            let cusFontStyle = this.transformDataToStyleObj(this.content.vf);
            let type = this.content.vf.t;
            return type ? cusFontStyle : {};
        },
        optionFontStyle() {
            let cusFontStyle = this.transformDataToStyleObj(this.content.of);
            let type = this.content.of.t;
            return type ? cusFontStyle : {};
        },
        unitFontStyle() {
            let cusFontStyle = this.transformDataToStyleObj(this.content.uf);
            let type = this.content.uf.t;
            return type ? cusFontStyle : {};
        },
        inputFontStyle() {
            let cusFontStyle = this.transformDataToStyleObj(this.content.ibf);
            let type = this.content.ibf.t;
            return type ? cusFontStyle : {};
        },
        inputBoxStyle() {
            let inputBoxBg = this.content.ibg;
            let inputBoxBorder = this.content.ibbor;
            let style = {};

            if (inputBoxBg.t) {
                style['background-color'] = inputBoxBg.c;
            }
            if (inputBoxBorder.t) {
                style['border'] = '1px solid ' + inputBoxBorder.c;
            }
            return style;
        },
        itemMarginStyle() {
            let margin = this.content.m;
            let style = {};
            if (margin.t) {
                style['margin-bottom'] = margin.m + 'rem';
            }
            return style;
        },
        calcBtnStyle() {
            let style = {};
            let bs = this.content.bs;
            let cusFontStyle = this.transformDataToStyleObj(this.content.bs.bf);
            let type = this.content.bs.bf.t;
            if (bs.bct) {
                style['background-color'] = bs.bc;
            }
            if (type) {
                style = { ...style, ...cusFontStyle };
            }
            return style;
        },
        moduleManage() {
            return manageMode;
        },
    },
    watch: {
        'content.ibf': {
            handler() {
                this.setInputPlaceHolerColor();
            },
            deep: true,
        },
    },
    mounted() {
        this.setInputPlaceHolerColor();
    },
    methods: {
        variableCls(variable) {
            let variableCls = {};
            if (variable.type == 1) {
                variableCls['variable_item_select'] = true;
            } else {
                if (variable.uHide == 0) {
                    variableCls['itemUnitShow'] = true;
                }
            }
            if (this.content.ibf.t && this.content.ibf.iu) {
                variableCls['holderUnderLine'] = true;
            }
            if (manageMode) {
                variableCls['manage_mode_wrap'] = true;
            }
            return variableCls;
        },
        manageItem(variable, index) {
            if (!manageMode) {
                return;
            }

            this.$store.commit('manage/calculationQuotation/updateVariableItemEdit', {
                moduleId: this.module.id,
                itemId: index,
                formModulePanel: false,
            });

            this.$designer.open({
                panelType: 'JzCalculationQuotationEditVariable',
            });
        },
        mousemoveHandler() {
            if (manageMode && this.moduleManage && this.module.extInfo.isAuthAllow) {
                let linkJson = [
                    {
                        operationText: '编辑按钮',
                        className: 'edit',
                        onClick: () => {
                            CalculationModuleBtnManage(this.moduleId);
                        },
                    },
                ];
                this.$layer.addEditLayer('calculateModuleBtn' + this.moduleId, linkJson, 106);
            }
        },
        mouseleaveHandler() {
            if (manageMode && this.moduleManage && this.module.extInfo.isAuthAllow) {
                this.$layer.removeEditLayer('calculateModuleBtn' + this.moduleId, null, 106);
            }
        },
        unitText(uType, unit) {
            return this.unitGroup[uType].options[unit];
        },
        onSubmit() {
            calculate(this);
        },
        transformDataToStyleObj(data) {
            const { t: type } = data;

            const DEFAULT_FONT_SIZE = 0.512;

            const FONT_SIZE = data.fs ? data.fs : DEFAULT_FONT_SIZE;

            let configStyle = {
                'font-size': `${FONT_SIZE}rem`,
                color: data.c,
                'font-style': data.it ? 'italic' : 'normal',
                'font-weight': data.ib ? 'bold' : 'normal',
                'text-decoration': data.iu ? 'underline' : 'none',
                'line-height': data.lh ? `${data.lh}rem` : 'normal',
            };

            const IS_DEFAULT_STYLE = type === 0;
            return IS_DEFAULT_STYLE ? {} : configStyle;
        },
        encodeHtml(html) {
            return encodeHtml(html);
        },
        setInputPlaceHolerColor() {
            if (!manageMode) {
                return;
            }
            let inputType = this.content.ibf.t;
            // eslint-disable-next-line no-unused-vars
            let inputColor = this.content.ibf.c;
            removeCtrlStyleCssList2('specialModuleStyle', 'module' + this.moduleId, [
                {
                    cls: '.variable_input input::-webkit-input-placeholder',
                    key: 'color',
                },
                {
                    cls: '.variable_input input::-moz-input-placeholder',
                    key: 'color',
                },
                {
                    cls: '.variable_input input::-ms-input-placeholder',
                    key: 'color',
                },
                {
                    cls: '.variable_input input::-o-input-placeholder',
                    key: 'color',
                },
            ]);
            if (inputType) {
                setCtrlStyleCssList('specialModuleStyle', 'module' + this.moduleId, [
                    {
                        cls: '.variable_input input::-webkit-input-placeholder',
                        key: 'color',
                        value: inputColor,
                    },
                    {
                        cls: '.variable_input input::-moz-input-placeholder',
                        key: 'color',
                        value: inputColor,
                    },
                    {
                        cls: '.variable_input input::-ms-input-placeholder',
                        key: 'color',
                        value: inputColor,
                    },
                    {
                        cls: '.variable_input input::-o-input-placeholder',
                        key: 'color',
                        value: inputColor,
                    },
                ]);
            }
        },
    },
};

function logCalcByJz(str) {
    if (!(VITE_APP_MODE !== 'visitor')) {
        logJZFdp('jz_c_cal', {
            jz_free_text1: str,
            jz_version: Fai.top.jzVersion,
        });
    }
}

function calculate(context) {
    let variableList = context.variableList;
    let formulaList = context.module.content.fl;
    let variableData = [];
    let calculateReultList = [];
    if (!Fai.top.isCalculationQuotationAuthAllow) {
        ing('功能已失效，请联系管理员。');
        return;
    }
    for (let i = 0; i < variableList.length; i++) {
        let tmpProp = context.variableList[i];
        let tmpVariable = {};
        if (tmpProp.type == 0 && !tmpProp.value) {
            ing(LS.calculation_empty_tips + encodeHtml(tmpProp.name));
            logCalcByJz('文案提示');
            return;
        } else if (tmpProp.type == 1 && !tmpProp.valueData) {
            ing(LS.calculation_empty_tips + encodeHtml(tmpProp.name));
            logCalcByJz('文案提示');
            return;
        }
        tmpVariable = {
            id: tmpProp.id,
            name: tmpProp.name,
            type: tmpProp.type,
            uHide: tmpProp.uHide,
            unit: context.unitGroup[tmpProp.uType].options[tmpProp.unit],
        };
        if (tmpProp.type == 0) {
            tmpVariable.value = tmpProp.value;
        } else if (tmpProp.type == 1 && tmpProp.valueData) {
            tmpVariable.value = tmpProp.valueData.value;
            tmpVariable.valueKey = tmpProp.valueData.key;
        }
        variableData.push(tmpVariable);
    }
    for (let j = 0; j < formulaList.length; j++) {
        let tmpFormula = formulaList[j];
        let tmpcalcStr = tmpFormula.fstr;
        tmpcalcStr = tmpcalcStr.replace(/prop(\d+)/g, (...args) => {
            for (let i = 0; i < variableData.length; i++) {
                let tmpVariable = variableData[i];
                let tmpProp = 'prop' + tmpVariable.id;
                if (tmpProp == args[0]) {
                    if (tmpVariable.value != '') {
                        return tmpVariable.value;
                    } else {
                        return 0;
                    }
                }
            }
        });
        calculateReultList.push({
            name: tmpFormula.name,
            value: jm.fkEval(tmpcalcStr),
            uHide: tmpFormula.uHide,
            unit: context.unitGroup[tmpFormula.uType].options[tmpFormula.unit],
        });
    }
    logDog(201353, 0);

    logCalcByJz('输出计算结果');

    let calculateReult = context.content.cr;
    calculationModalFrame.render(calculateReultList, variableData, calculateReult);
}
</script>

<style lang="scss">
.calculationQuotation {
    margin: 0.75rem;
}
.calculationQuotation .variable_item {
    margin-bottom: 0.75rem;
}
.calculationQuotation .variable_item.manage_mode_wrap:hover {
    outline: 1px dashed #999999;
}
.variable_item .variable_name {
    font-size: 0.62rem;
    margin-bottom: 0.6rem;
    color: #333;
}
.variable_item .variable_content {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    background-color: transparent;
}
.variable_item .variable_input {
    border-radius: 0.1rem;
    background: rgba(246, 246, 246, 1);
    color: #333;
    flex: 1;
    box-sizing: border-box;
    height: 1.8rem;
}

.variable_item .variable_input input {
    color: #333;
    width: 100%;
    height: 100%;
    line-height: 1.2rem;
    box-sizing: border-box;
    background: none;
    border-radius: 0;
    font-size: 0.6rem;
    -webkit-appearance: none;
    padding: 0 0.5rem;
    outline: 0;
    border: none;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.variable_select input {
    cursor: pointer;
}
.variable_select select:focus {
    outline: none;
}
.variable_input input::-webkit-input-placeholder {
    color: #888888;
}
.variable_input input::-moz-input-placeholder {
    color: #888888;
}
.variable_input input::-ms-input-placeholder {
    color: #888888;
}
.variable_input input::-o-input-placeholder {
    color: #888888;
}
.variable_input input:focus::-webkit-input-placeholder {
    color: transparent;
}
.variable_input input:focus:-moz-placeholder {
    color: transparent;
}
.variable_input input:focus::-moz-placeholder {
    color: transparent;
}
.variable_input input:focus:-ms-input-placeholder {
    color: transparent;
}
.holderUnderLine .variable_input input::-webkit-input-placeholder {
    text-decoration: underline;
}
.holderUnderLine .variable_input input::-moz-input-placeholder {
    text-decoration: underline;
}
.holderUnderLine .variable_input input::-ms-input-placeholder {
    text-decoration: underline;
}
.holderUnderLine .variable_input input::-o-input-placeholder {
    text-decoration: underline;
}
.variable_item .variable_unit {
    font-size: 0.6rem;
    align-items: center;
    position: absolute;
    right: 0.6rem;
    top: 0.45rem;
}
.calculation_submit_wrap {
    margin: 1.3rem 0 0;
    text-align: center;
}
.variable_select .mobileSelect {
    padding: 0 0.725rem;
    width: 100%;
    height: 1.8rem;
    line-height: 1.9rem;
    border: unset;
    font-size: 0.6rem;
    box-sizing: border-box;
    border-radius: 0.086rem;
    -webkit-appearance: none;
    color: #888888;
    background: unset;
    text-indent: unset;
    padding: 0 1.5rem 0rem 0.5rem;
}
.calculation_submit {
    width: 100%;
    height: 2rem;
    border: 0;
    text-align: center;
    color: #fff;
    border-radius: 2px;
    cursor: pointer;
    font-size: 0.7rem;
    white-space: nowrap;
    padding: 0 0.5rem;
    box-sizing: border-box;
    overflow: hidden;
    text-overflow: ellipsis;
    outline: none;
}
.variable_select {
    width: 100%;
    position: relative;
}
.variable_select .variable_select_input {
    display: flex;
    align-items: center;
}
.select_list_wrap {
    margin-top: 10px;
    padding: 8px 6px;
    box-sizing: border-box;
    background: #fff;
    border: 1px solid #eee;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    z-index: 999;
    position: absolute;
}

.select_list_wrap .select_list {
    overflow: hidden;
    max-height: 10.5rem;
    overflow-y: visible;
}
.select_list_wrap .select_item {
    font-size: 0.6rem;
    color: #333;
    box-sizing: border-box;
    cursor: pointer;
    padding: 0.45rem 0 0.45rem 0.6rem;
    line-height: normal;
    height: 1.75rem;
}

/*样式2*/
.calculationQuotation.moduleStyle2 .variable_input {
    background-color: transparent;
    border: 1px solid #e3e2e8;
}

/*样式3*/
.calculationQuotation.moduleStyle3 .variable_input {
    background-color: transparent;
    border-bottom: 1px solid #e3e2e8;
}
.moduleStyle3 .variable_item .variable_input input {
    padding: 0 0.5rem 0 0;
}
.variable_item_select.variable_item .variable_input input,
.itemUnitShow.variable_item .variable_input input {
    padding-right: 1.5rem;
}
</style>
