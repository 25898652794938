import { getChildModuleData } from '@/api/module/tabContainer';
import { ing } from '@/shared/tips';
import { panelDisableArea } from '@/modules/shared/panelDisableArea';
import { addModuleAnmaition, resetModuleAddState } from '@/modules/comm';

import { globalSortDataApi } from '@/features/sortable/sortData';
import { moveSpecialModuleInNewPack } from '@/manage/utils/pack';

const manageMode = VITE_APP_MODE !== 'visitor';
export let inFoldModuleEdit = {};
inFoldModuleEdit.inFoldModuleSortable = function (module) {
    var foldSortable = module.find('.J_foldSortContainer');

    foldSortable.sortable('destroy');
    foldSortable.sortable({
        distance: 3,
        connectWith: '#webModuleContainer',
        cursorAt: { top: 0, left: 0 },
        create() {},
        beforeStart() {},
        start() {},
        over() {
            Vue.prototype.$layer.removeAllEditLayer();
        },
        out() {},
        sort() {},
        receive(event, ui) {
            sortable_receive_normal(ui, event, this);
        },
        update() {},
        beforeStop() {},
        stop() {
            globalSortDataApi.reset();
        },
        remove() {},
    });
};

// 向模块组内添加模块
inFoldModuleEdit.addModuleToFold = async function (foldId, moduleId) {
    let foldModule = window.$store.getters.currentModuleForId(foldId);
    var fModule = $('#module' + foldId);
    let moduleInfo;

    $(`#module${moduleId}`).hide();

    let params = {
        moduleId,
        _colId: Fai.top._colId,
        _extId: Fai.top._extId,
    };
    let result = await getChildModuleData(params);
    let { data, success, msg } = result;
    if (success) {
        moduleInfo = data.moduleInfo;
    } else {
        ing(msg, false);
        return;
    }

    //如果是当前的子模块重新排序，需要保留defaultOpen的状态
    var defaultOpen = false;
    if (fModule.find('.foldLabelItem' + moduleId).length) {
        defaultOpen = Boolean(fModule.find('.foldLabelItem' + moduleId).attr('defaultopen'));
    }

    if (moduleInfo != null) {
        if (foldModule != null) {
            foldModule._inTabOfFold = true;
        }
        var { renderOptions } = foldModule;
        if (Fai.top._foldChildModulePos) {
            if (Fai.top._foldChildModulePos.indexOf('label') != -1) {
                var labelId = Fai.top._foldChildModulePos.replace('label_', '');
                $(foldModule.content.labelList).each(function (index, value) {
                    if (value.id == labelId) {
                        value.id = moduleInfo.id;
                        value.labelName = value.labelName || moduleInfo.name;
                        value.style = moduleInfo.style;
                        value.do = defaultOpen;
                    }
                });
                Vue.prototype.$designer.onContentChange(foldId);
            } else if (Fai.top._foldChildModulePos.indexOf('pos') != -1) {
                var pos = Fai.top._foldChildModulePos.replace('pos_', '');
                let labelListClone = JSON.parse(JSON.stringify(foldModule.content.labelList));
                let tmpLabel = {};
                tmpLabel = {
                    id: moduleInfo.id,
                    labelName: moduleInfo.name,
                    do: defaultOpen,
                    style: moduleInfo.style,
                };

                labelListClone.splice(pos, 0, tmpLabel);
                foldModule.content.labelList = labelListClone;
                Vue.prototype.$designer.onContentChange(foldId);
            }
        } else {
            let labelListClone = JSON.parse(JSON.stringify(foldModule.content.labelList));
            let tmpLabel = {};
            tmpLabel = {
                id: moduleInfo.id,
                labelName: moduleInfo.name,
                do: defaultOpen,
                style: moduleInfo.style,
            };
            labelListClone.push(tmpLabel);
            foldModule.content.labelList = labelListClone;
            Vue.prototype.$designer.onContentChange(foldId);
        }

        updateLabelModule(moduleInfo.id, moduleInfo.style, foldId, 'add');

        var tmpCache = {
            sbm: `<div id='module${moduleInfo.id}'></div>`,
            load: true,
        };
        renderOptions.args.labelCache[`module${moduleInfo.id}`] = tmpCache;

        window.$store.dispatch('manage/addPanel/addMyModule', {
            module: moduleInfo,
            parentId: foldId,
        });
        window.$store.dispatch('setModule', {
            moduleId,
            data: {
                inFold: foldId,
            },
        });

        setTimeout(function () {
            inFoldModuleEdit.openFoldItem(foldId, moduleInfo.id);
            jm('#module' + moduleInfo.id).attr('_infold', foldId);
        }, 300);

        resetModuleAddState();
        Fai.top._foldChildModulePos = 0;
    }
};

// 删除模块组内模块
inFoldModuleEdit.removeModuleInFold = function (foldId, moduleId, hideModule, moduleStyle) {
    if (typeof foldId == 'undefined' || moduleId == 'undefined') {
        return;
    }

    var foldModule = window.$store.state.currentPageModuleIdMap[foldId];
    var labelList = JSON.parse(JSON.stringify(foldModule.content.labelList));
    // qjie 该模块为2.0则设置属性 _inTabOfFold 用于面板动态响应标题栏隐藏
    var module = window.$store.state.currentPageModuleIdMap[moduleId];
    var style = moduleStyle || 0;
    if (module != null) {
        module._inTabOfFold = false;
        style = module.style;
    }

    $(labelList).each(function (index, value) {
        if (value.id == moduleId) {
            if (labelList.length > 1) {
                labelList.splice(index, 1);
            } else {
                value.id = -1;
                value.labelName = '标签1';
                value.style = -1;
                value.do = false;
            }
        }
    });
    foldModule.content.labelList = labelList;
    updateLabelModule(moduleId, style, foldId, 'hide');
    if (!hideModule) {
        setTimeout(function () {
            jm('#module' + moduleId).attr('_infold', 0);
        }, 200);
    }

    Vue.prototype.$designer.onContentChange(foldId);
};

// 通过标签模块内的添加按钮添加模块
inFoldModuleEdit.addModuleToFoldByAddBtn = function (foldId, labelId, event) {
    var options = {
        btnClass: 'addBtn_Icon',
        disableSeveralModuleList: ['horizonTab', 'fold'],
    };

    // 切换添加模块的面板
    let { addPanel } = window.$store.state.manage;
    let { activeTab, activeModuleTab } = addPanel;
    let addModulePanelActive = activeTab === 'module' && activeModuleTab === 'add';
    if (activeTab !== 'module') {
        window.$store.dispatch('manage/addPanel/changeActiveTab', 'module');
    }
    if (activeModuleTab !== 'add') {
        window.$store.dispatch('manage/addPanel/changeActiveModuleTab', 'add');
    }

    var addBtn = $('#formFoldContent' + labelId).find('.addBtn_Icon');
    let refreshModuleAddState = false;
    if (addModulePanelActive) {
        // 禁止某些模块拖入标签模块
        panelDisableArea.disableFunc('fold', event, foldId, options, labelId); //与横向标签一致
        if (addBtn.length > 0 && !!addBtn.attr('_status')) {
            // 此时是添加状态，需要记录位置
            // Fai.top.preModulePosition = 'inFold_' + foldId;
            // Fai.top._foldChildModulePos = 'label_' + labelId;
            refreshModuleAddState = true;
            addModuleAnmaition();
        }
    } else {
        setTimeout(function () {
            // 禁止某些模块拖入标签模块
            panelDisableArea.disableFunc('fold', event, foldId, options, labelId); //与横向标签一致
        }, 100);
        if (addBtn.length > 0 && !!addBtn.attr('_status')) {
            // 此时是添加状态，需要记录位置
            refreshModuleAddState = true;
            // Fai.top.preModulePosition = 'inFold_' + foldId;
            // Fai.top._foldChildModulePos = 'label_' + labelId;
        }
    }
    if (refreshModuleAddState) {
        window.$store.dispatch('manage/moduleAdd/changeModulePosition', 'inFold');
        window.$store.dispatch('manage/moduleAdd/changeInContainerId', foldId);
        window.$store.dispatch('manage/moduleAdd/changeChildPosIndex', labelId);
    }
};

// 通过模块面板添加模块
inFoldModuleEdit.addModuleToFoldByModulePanel = function (moduleInfo) {
    //处理新增子模块的数据
    let { moduleAdd } = window.$store.state.manage;
    let { inContainerId, childPosIndex } = moduleAdd;
    var foldId = inContainerId;
    var foldModule = window.$store.getters.currentModuleForId(foldId);
    var { renderOptions } = foldModule;
    let labelIds = foldModule.content.labelList.map((item) => item.id);
    if (childPosIndex != null) {
        let labelId = childPosIndex;
        if (labelIds.includes(labelId)) {
            $(foldModule.content.labelList).each(function (index, value) {
                if (value.id == labelId) {
                    value.id = moduleInfo.id;
                    value.labelName = value.labelName || moduleInfo.name;
                    value.style = moduleInfo.style;
                    value.do = typeof value.do == 'undefined' ? false : value.do;
                }
            });
        } else {
            let labelListClone = JSON.parse(JSON.stringify(foldModule.content.labelList));
            let tmpLabel = {};
            tmpLabel = {
                id: moduleInfo.id,
                labelName: moduleInfo.name,
                do: false,
                style: moduleInfo.style,
            };
            labelListClone.splice(labelId, 0, tmpLabel);
            foldModule.content.labelList = labelListClone;
        }
        Vue.prototype.$designer.onContentChange(foldId);
    } else {
        let labelListClone = JSON.parse(JSON.stringify(foldModule.content.labelList));
        let tmpLabel = {};
        tmpLabel = {
            id: moduleInfo.id,
            labelName: moduleInfo.name,
            do: false,
            style: moduleInfo.style,
        };

        labelListClone.push(tmpLabel);
        foldModule.content.labelList = labelListClone;
        Vue.prototype.$designer.onContentChange(foldId);
    }
    //新增模块缓存到标签模块里面
    var tmpCache = {
        sbm: `<div id='module${moduleInfo.id}'></div>`,
        //moduleScript: moduleJs,
        load: true,
    };
    renderOptions.args.labelCache[`module${moduleInfo.id}`] = tmpCache;
    if (moduleInfo.style == 51) {
        var packModuleList = moduleInfo.content.moduleList;
        var childModuleList = [];
        if (packModuleList.length) {
            packModuleList.forEach(function (val) {
                var data = $.parseJSON(val);
                childModuleList.push(data.id);
            });
        }
        updateLabelModule(moduleInfo.id, moduleInfo.style, foldId, 'add', childModuleList);
    } else {
        updateLabelModule(moduleInfo.id, moduleInfo.style, foldId, 'add');
    }

    setTimeout(function () {
        inFoldModuleEdit.openFoldItem(foldId, moduleInfo.id);
        jm('#module' + moduleInfo.id).attr('_infold', foldId);
    }, 300);

    resetModuleAddState();
};

inFoldModuleEdit.addFoldEmptyLabel = function (foldId) {
    var foldModule = window.$store.state.currentPageModuleIdMap[foldId];
    var minLabelId = 0;
    $(foldModule.content.labelList).each(function (index, value) {
        if (minLabelId > value.id) {
            minLabelId = value.id;
        }
    });
    var labelId = (minLabelId -= 1);
    var labelListClone = JSON.parse(JSON.stringify(foldModule.content.labelList));
    var tmpLabel = {};
    tmpLabel = {
        id: labelId,
        labelName: '标签' + Math.abs(parseInt(labelId)),
        do: false,
        style: -1,
    };

    labelListClone.push(tmpLabel);
    foldModule.content.labelList = labelListClone;
    Vue.prototype.$designer.onContentChange(foldId);
    setTimeout(function () {
        inFoldModuleEdit.openFoldItem(foldId, labelId);
    }, 300);
};

// 生成标签模块的Sortable容器
inFoldModuleEdit.createFoldSortContainer = function ($container) {
    $container.find('.formStyle58').each(function (index, el) {
        var module = $(el),
            moduleId = module.attr('id').replace('module', ''),
            sortContainer = module.find('.J_foldSortContainer'); // 这里可以用elem.data()来缓存sortContainer，提高性能

        if (sortContainer.length < 1) {
            module.append("<div moduleId='" + moduleId + "' class='J_foldSortContainer fk-foldSortContainer'></div>");
            sortContainer = module
                .find('.J_foldSortContainer')
                .append(module.find('.formBannerTitle' + moduleId).clone());
        }

        // 是否创建了sortable（属性检测）
        if (typeof sortContainer.sortable('option').disabled == 'undefined') {
            inFoldModuleEdit.inFoldModuleSortable(module); // 绑定模块标签Sortable
        }
    });
};
//展开一项
inFoldModuleEdit.openFoldItem = function (moduleId, labelId) {
    var module = $('#module' + moduleId);
    if (module.length <= 0) {
        return;
    }
    var item = module.find('.foldLabelItem' + labelId);
    var content = item.find('.J_formFoldContent');
    var itemButton = item.find('.J_formFoldButton');
    var styleId = module.find('.J_formFoldContentList').attr('styleId');
    var height = content.find('.form').length
        ? parseInt(content.find('.form')[0].offsetHeight)
        : parseInt(content.find('.emptyLabel')[0].offsetHeight);
    var moveFrameHeight = height + parseInt(module.height());

    itemButton.addClass('active');
    content.css('height', height + 'px');
    if (styleId == 2) {
        itemButton.addClass('g_main_bgColor_v3');
    } else {
        itemButton.addClass('g_main_color_v3');
    }

    //处理管理态下的，模块的蓝色虚线没有跟着模块高度变化而变化
    if (manageMode) {
        Vue.prototype.$layer.removeMoveFrame('module' + moduleId);
        Vue.prototype.$layer.addMoveFrame('module' + moduleId, undefined, moveFrameHeight);
    }
    content.one('transitionend', fixHeight);
    content.one('webkitTransitionEnd', fixHeight);
    function fixHeight() {
        if (itemButton.hasClass('active')) {
            content.off('transitionend', fixHeight);
            content.off('webkitTransitionEnd', fixHeight);
            content.css('height', 'auto');
        }
    }
};

inFoldModuleEdit.initFold = function () {};

function sortable_receive_normal(ui, event, This) {
    // 标签模块接收模块
    var module = ui.item,
        moduleId = module.attr('id').replace('module', ''),
        foldId = $(This).attr('moduleId'),
        msg;

    globalSortDataApi.setReceiveId(`module${foldId}`);
    globalSortDataApi.setReceive('fold');
    globalSortDataApi.setReceiveParent($('#module' + foldId));

    var isCommonModule =
        module.hasClass('formStyle52') || module.hasClass('formStyle53') || module.hasClass('formStyle54') || false;
    if (module.hasClass('formStyle50') || module.hasClass('formStyle58')) {
        msg = '添加失败，横向标签模块内无法添加排版模块';
        msg = isCommonModule ? '添加失败，横向标签模块内无法添加常用模块' : msg;
        Fai.top.Fai.ing(msg, true);
        ui.sender.sortable('cancel');
        return;
    }

    $('#webModuleContainer').find('>.form').removeClass('fk-sortModuleStyle');
    if (isCommonModule) {
        Fai.top.preModulePosition = 'inFold_' + foldId;
        moveSpecialModuleInNewPack(parseInt(moduleId), foldId, {
            originalParentId: parseInt(module.attr('_inpack')),
        }).then(({ parent }) => {
            inFoldModuleEdit.addModuleToFold(foldId, parent.id);
        });
    } else {
        inFoldModuleEdit.addModuleToFold(foldId, moduleId);
    }
}

function updateLabelModule(id, style, labelModuleId, type, childModuleList) {
    if (type == 'add') {
        let labelModuleData = Fai.top.labelModuleData['module' + labelModuleId];
        labelModuleData.push({
            id,
            inTab: labelModuleId,
        });

        if (style == 51) {
            if (childModuleList && childModuleList.length) {
                childModuleList.forEach(function (val) {
                    labelModuleData.push({
                        id: val,
                        inPack: id,
                    });
                });
            } else {
                $('#module' + id)
                    .find('.form')
                    .each(function (index, val) {
                        var childId = parseInt($(val).attr('id').replace('module', ''));
                        labelModuleData.push({
                            id: childId,
                            inPack: id,
                        });
                    });
            }
        }
    } else if (type == 'hide') {
        let labelModuleData = Fai.top.labelModuleData['module' + labelModuleId];
        Fai.top.labelModuleData['module' + labelModuleId] = labelModuleData.filter(function (val) {
            if (val.id == id) {
                return false;
            } else if (style == 51 && val.inPack && val.inPack == id) {
                return false;
            } else {
                return true;
            }
        });
    }
}
