<template>
    <module-frame :module-id="moduleId">
        <div v-if="render && (dataList.length || manageMode)" :id="'noticeContainer' + module.id" :class="panelCls">
            <template v-if="!dataList.length && manageMode">
                <div class="empty_tips_panel">
                    <div class="text">请在右侧面板添加公告</div>
                    <button-component active @click.stop="addNotice">添加公告</button-component>
                </div>
            </template>
            <template v-if="dataList.length">
                <div v-if="showNewPane" :class="newPaneCls" :style="newStyle"></div>
                <template v-if="direction === 'left' || direction === 'right'">
                    <div
                        v-if="noticeType == 5 && isFontIcon"
                        class="noticeFontIcon"
                        :class="classname"
                        :style="'color:' + color"
                    ></div>
                    <img
                        v-if="noticeType == 5 && !isFontIcon"
                        class="J_img_lazyload noticeImg"
                        :src-original="filePath"
                        :src="loadingPath"
                        alt=""
                    />
                    <div v-if="noticeType == 10 && !isFontIcon" class="newNoticeImg"
                        ><img class="J_img_lazyload" :src-original="filePath" :src="loadingPath" alt=""
                    /></div>
                    <div
                        v-if="noticeType == 10 && isFontIcon"
                        class="newNoticeIcon"
                        :class="classname"
                        :style="'color:' + color"
                        style="font-size: 1.05rem"
                    ></div>
                    <div :id="'noticeMarquee' + module.id" class="noticeMarquee" style="position: relative">
                        <div class="noticeMarqueeDiv">
                            <div class="noticeMarqueeRow">
                                <template v-for="item in dataList">
                                    <span>&nbsp;</span>
                                    <span v-if="item.o === 1" nowrap class="g_notice_text" :style="notice_text_style">{{
                                        item.t
                                    }}</span>
                                    <span v-if="item.o === 0" nowrap
                                        ><a
                                            class="g_notice_link"
                                            :style="notice_link_style"
                                            hidefocus="true"
                                            :href="item.jumpUrl"
                                            :target="item.p ? '_self' : '_blank'"
                                            :rel="item.nf ? 'nofollow' : ''"
                                            @click="jumpOnclickStr(item)"
                                            >{{ item.t }}</a
                                        ></span
                                    >
                                    <span>&nbsp;</span>
                                </template>
                            </div>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div
                        v-if="noticeType == 5 && isFontIcon"
                        class="noticeFontIcon"
                        :class="classname"
                        :style="'color:' + color"
                        style="margin-left: -21px"
                    ></div>
                    <img
                        v-if="noticeType == 5 && !isFontIcon"
                        class="noticeImg J_img_lazyload"
                        :src-original="filePath"
                        style="margin-left: -21px"
                        :src="loadingPath"
                        alt=""
                    />
                    <div v-if="noticeType == 10 && !isFontIcon" class="newNoticeImg"
                        ><img class="J_img_lazyload" :src-original="filePath" :src="loadingPath" alt=""
                    /></div>
                    <div
                        v-if="noticeType == 10 && isFontIcon"
                        class="newNoticeIcon"
                        :class="classname"
                        :style="'color:' + color"
                        style="font-size: 1.05rem"
                    ></div>
                    <div :id="'noticeMarquee' + module.id" class="noticeMarqueeUD" :class="newStyleCls">
                        <ul :id="'noticeScrollbar' + module.id" class="noticeScrollbar">
                            <a style="text-decoration: none">&nbsp;</a
                            >&nbsp;
                            <template v-if="moduleStyle === 2">
                                <template v-for="item in dataList">
                                    <li v-if="item.o === 1" class="scrollbarLi g_notice_text" :style="notice_text_style"
                                        >{{ item.t }}<a style="text-decoration: none">&nbsp;</a></li
                                    >
                                    <li v-if="item.o === 0" class="scrollbarLi">
                                        <a
                                            :class="
                                                noticeType >= 6 && noticeType <= 10
                                                    ? 'noticeNewStyleContentText g_notice_link'
                                                    : 'g_notice_link'
                                            "
                                            :style="notice_link_style"
                                            hidefocus="true"
                                            :href="item.jumpUrl"
                                            :target="item.p ? '_self' : '_blank'"
                                            :rel="item.nf ? 'nofollow' : ''"
                                            @click="jumpOnclickStr(item)"
                                        >
                                            <span
                                                v-show="noticeType >= 6 && noticeType <= 10"
                                                class="listStyleCircle"
                                            ></span
                                            >{{ item.t }} </a
                                        >&nbsp;
                                    </li>
                                </template>
                            </template>
                            <template v-else-if="moduleStyle === 1">
                                <template v-if="dataList.length === 1">
                                    <li v-if="dataList[0].o === 1" class="scrollbarLi oneNoticeScrollbar">
                                        <div class="noticeListTopMargin"></div>
                                        <p class="noticeNewStyleContentText g_notice_text" :style="notice_text_style">
                                            <span class="listStyleCircle"></span>{{ dataList[0].t }}
                                        </p>
                                        <div class="noticeListBottomMargin"></div>
                                    </li>
                                    <li v-if="dataList[0].o === 0" class="scrollbarLi oneNoticeScrollbar">
                                        <div class="noticeListTopMargin"></div>
                                        <a
                                            hidefocus="true"
                                            :href="dataList[0].jumpUrl"
                                            :target="dataList[0].p ? '_self' : '_blank'"
                                            class="noticeNewStyleContentText g_notice_link"
                                            :style="notice_link_style"
                                            :rel="dataList[0].nf ? 'nofollow' : ''"
                                            @click="jumpOnclickStr(dataList[0])"
                                        >
                                            <span class="listStyleCircle"></span>{{ dataList[0].t }}
                                        </a>
                                        <div class="noticeListBottomMargin"></div>
                                    </li>
                                </template>
                                <template v-else>
                                    <li v-for="item in cloneList" class="scrollbarLi">
                                        <template v-for="(child, cIndex) in item">
                                            <p
                                                v-if="child.o === 1 && cIndex % 2 === 0"
                                                class="noticeNewStyleContentText twoNoticeContentText mode1first g_notice_text"
                                                :style="notice_text_style"
                                            >
                                                <span class="listStyleCircle"></span>{{ child.t }}
                                            </p>
                                            <p
                                                v-if="child.o === 1 && cIndex % 2 === 1"
                                                class="noticeNewStyleContentText twoNoticeContentText mode1sec g_notice_text"
                                                :style="notice_text_style"
                                            >
                                                <span class="listStyleCircle"></span>{{ child.t }}
                                            </p>
                                            <a
                                                v-if="child.o === 0 && cIndex % 2 === 0"
                                                class="noticeNewStyleContentText twoNoticeContentText mode0first g_notice_link"
                                                :style="notice_link_style"
                                                hidefocus="true"
                                                :href="child.jumpUrl"
                                                :target="child.p ? '_self' : '_blank'"
                                                :rel="child.nf ? 'nofollow' : ''"
                                                @click="jumpOnclickStr(child)"
                                            >
                                                <span class="listStyleCircle"></span>{{ child.t }}
                                            </a>
                                            <a
                                                v-if="child.o === 0 && cIndex % 2 === 1"
                                                class="noticeNewStyleContentText twoNoticeContentText mode0sec g_notice_link"
                                                :style="notice_link_style"
                                                hidefocus="true"
                                                :href="child.jumpUrl"
                                                :target="child.p ? '_self' : '_blank'"
                                                :rel="child.nf ? 'nofollow' : ''"
                                                @click="jumpOnclickStr(child)"
                                            >
                                                <span class="listStyleCircle"></span>{{ child.t }}
                                            </a>
                                        </template>
                                    </li>
                                </template>
                            </template>
                        </ul>
                    </div>
                </template>
            </template>
        </div>
    </module-frame>
</template>

<script>
import { createUrlArgsOnclickStr } from '../../comm';
import { mapGetters } from 'vuex';
import ModuleFrame from '@/modules/frame/index.vue';
import { noticeLeftRightMarquee, noticeMarqueeUpDown, revertIcon } from './service';
import { bindImgLazyLoad } from '@/shared/imageLazyload';
import { MODULE_STYLE } from '@/def/module';

export default {
    name: 'ScrollNotice',
    components: {
        ModuleFrame,
    },
    props: {
        moduleId: {
            type: Number,
            default: -1,
        },
    },
    data() {
        return {
            render: true,
            manageMode: VITE_APP_MODE !== 'visitor',
        };
    },
    computed: {
        ...mapGetters(['getModuleById']),
        ...mapGetters('app', ['hashRemoved']),
        options() {
            return this.module.renderOptions;
        },
        noLazyLoad() {
            return this.options.isFirstScreen;
        },
        module() {
            return this.getModuleById(this.moduleId);
        },
        id() {
            return this.module.id;
        },
        styleId() {
            return this.module.style;
        },
        loadingPath() {
            return this.noLazyLoad ? this.filePath : this.options.loadingPath;
        },
        panelCls() {
            let content = this.module.content;
            let cls = {};

            cls['noticeContainer'] = true;

            if (this.dataList.length && content.type != 4) {
                if ('top' === content.marquee.d || 'bottom' === content.marquee.d) {
                    cls['notice-ico'] = true;
                }

                if (content.type < 4) {
                    cls['notice-image' + (content.type - 1)] = true;
                }
            }

            return cls;
        },
        // eslint-disable-next-line vue/return-in-computed-property
        showNewPane() {
            var content = this.module.content;

            if (content.type >= 6 && content.type <= 9) {
                return true;
            }
        },
        newPaneCls() {
            var content = this.module.content;
            var type = content.type;
            var cls = {};

            cls['noticeNewStyleIconClass'] = true;
            cls['icon-noticeNewStyleIconClass-' + type] = true;

            if (type === 6) {
                cls['g_main_bgColor_v3'] = true;
            } else if (type === 7) {
                cls['g_main_bgColor_v3'] = true;
            } else if (type === 8) {
                cls['g_main_color_v3'] = true;
                cls['g_main_bdColor_v3'] = true;
            } else if (type === 9) {
                cls['g_main_color_v3'] = true;
            }

            return cls;
        },
        newStyleCls() {
            var cls = {};

            if (this.noticeType === 10) {
                cls['newStyleImgNotice'] = true;
            }

            if (this.moduleStyle === 10) {
                cls['newStyleNotice'] = true;
            }

            return cls;
        },
        newStyle() {
            var content = this.module.content;
            var color = content.color;
            var type = content.type;
            var style = {};

            if (color) {
                if (type === 6 || type === 7) {
                    style.backgroundColor = color;
                } else if (type === 8) {
                    style.color = color;
                    style.borderColor = color;
                } else if (type === 9) {
                    style.color = color;
                }
            }

            return style;
        },
        direction() {
            return this.module.content.marquee.d;
        },
        classname() {
            return this.module.content.customize.c;
        },
        color() {
            return this.module.content.customize.o;
        },
        isFontIcon() {
            return this.module.content.customize.i;
        },
        filePath() {
            return this.module.content.customize.filePath;
        },
        noticeType() {
            return this.module.content.type;
        },
        moduleStyle() {
            return this.module.content.moduleStyle;
        },
        dataList() {
            return this.module.content.data;
        },
        inTab() {
            return this.module.inTab || 0;
        },
        cloneList() {
            var twoDimensionalArr = [];
            var arrTmp = [];

            if (this.dataList.length < 2) return [];

            if (this.dataList.length % 2 != 0) {
                arrTmp = this.dataList.concat(this.dataList);
            } else {
                arrTmp = this.dataList;
            }

            for (var i = 0; i < arrTmp.length; i += 2) {
                twoDimensionalArr.push(arrTmp.slice(i, i + 2));
            }

            return twoDimensionalArr;
        },
        notice_text_style() {
            if (!(VITE_APP_MODE !== 'visitor')) {
                return '';
            }
            var contentStyle = this.module.content.mhighs.cs;
            var style = '';
            if (contentStyle.type == 1) {
                if (contentStyle.nfs) {
                    style += 'font-size:' + contentStyle.nfs + 'rem;';
                } else {
                    style += 'font-size:12px;';
                }
                if (contentStyle.nfct == 1) {
                    style += 'color:' + contentStyle.nfc + ';';
                }
            }
            return style;
        },
        notice_link_style() {
            if (!(VITE_APP_MODE !== 'visitor')) {
                return '';
            }
            var contentStyle = this.module.content.mhighs.cs;
            var style = '';
            if (contentStyle.type == 1) {
                if (contentStyle.lfs) {
                    style += 'font-size:' + contentStyle.lfs + 'rem;';
                } else {
                    style += 'font-size:12px;';
                }
                if (contentStyle.lfct == 1) {
                    style += 'color:' + contentStyle.lfc + ';';
                }
                if (contentStyle.lfd == 1) {
                    style += 'text-decoration:underline;';
                } else {
                    style += 'text-decoration:none;';
                }
            }
            return style;
        },
    },
    watch: {
        module: {
            handler: function () {
                if (VITE_APP_MODE !== 'visitor') {
                    // this.noLazyLoad = true;
                }
                if (!this.dataList.length) return;

                this.render = false;

                this.$nextTick(() => {
                    this.render = true;

                    this.$nextTick(() => {
                        bindEvent(this);
                    });
                });
            },
            deep: true,
        },
    },
    mounted() {
        if (!this.dataList.length) return;

        bindEvent(this);
    },
    methods: {
        addNotice() {
            if (Fai.top._onlyHasBannerEditAuth) {
                return;
            }
            this.$designer.open({
                panelType: 'pattern scrollNoticeEditPanel basis',
                styleId: MODULE_STYLE.SCROLL_NOTICE,
                moduleId: this.id,
            });
        },
        jumpOnclickStr(item) {
            if (VITE_APP_MODE !== 'visitor') {
                const { u: jumpUrl = '', t: type } = item.jumpInfo;
                if (jumpUrl.indexOf('http') === 0 || type === 103) {
                    Fai.ing('管理态不支持跳转外部链接，请通过“预览”进行访问', true);
                    return;
                }
            }
            return createUrlArgsOnclickStr(
                item.jumpInfo.reqArgs || '',
                this.module._openRemoveUrlArgs,
                this.hashRemoved
            );
        },
    },
};

function bindEvent(context) {
    var marquee = context.module.content.marquee;
    var mqSpeed = marquee.s;
    var moveout = marquee.m_out;
    var mqScrolling = marquee.i;
    var moduleId = context.module.id;
    var stopTime = marquee.t;

    if (context.direction === 'left' || context.direction === 'right') {
        var funcOptions = {
            id: moduleId,
            direction: context.direction,
            loop: 'infinite',
            speed: mqSpeed,
            moveout: moveout,
            isScrolling: mqScrolling,
            noticeType: context.noticeType,
        };

        noticeLeftRightMarquee(funcOptions);
    } else {
        funcOptions = {
            id: moduleId,
            direction: context.direction,
            speed: mqSpeed,
            stopTime: stopTime * 1000,
        };

        noticeMarqueeUpDown(funcOptions);
    }

    if (context.noticeType === 5) {
        revertIcon(moduleId);
    }
    if (!context.noLazyLoad) {
        bindImgLazyLoad(`module${context.module.id}`);
    }
}
</script>

<style lang="scss">
.noticeMarquee {
    position: absolute;
    height: auto;
}

.noticeMarquee .noticeMarqueeRow {
    display: inline-block;
}

.noticeMarquee .noticeMarqueeDiv .noticeMarqueeRow span {
    white-space: nowrap;
    float: left;
}

.noticeContainer {
    position: relative;
    width: 100%;
    overflow: hidden;
    margin: 0.25rem 0;
    display: flex;
    align-items: center;
}

.notice-image0 {
    background: url(/image/mobi/noticeIco1.gif?v=201711250607) no-repeat left center;
}

.notice-image1 {
    background: url(/image/mobi/noticeIco2.gif?v=201711250607) no-repeat left center;
}

.notice-image2 {
    background: url(/image/mobi/noticeIco3.gif?v=201711250607) no-repeat left center;
}

/*滚动公告新增样式  start*/
.noticeNewStyleIconClass {
    float: left;
    width: 1.75rem;
    height: 1.75rem;
    box-sizing: border-box;
    margin-left: -0.6rem;
}

.icon-noticeNewStyleIconClass-6 {
    background-color: #f5bd30;
    position: relative;
    border-radius: 0.1rem;
}

.icon-noticeNewStyleIconClass-7 {
    background-color: #f5bd30;
    position: relative;
    border-radius: 0.1rem;
}

.icon-noticeNewStyleIconClass-8 {
    background-color: #fff;
    position: relative;
    border-radius: 0.1rem;
    border: 0.05rem solid #f5bd30;
}

.icon-noticeNewStyleIconClass-9 {
    background-color: transparent;
    position: relative;
    border-radius: 0.1rem;
}

.noticeNewStyleIconClass::before {
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 0.7rem;
    line-height: 1;
}

.icon-noticeNewStyleIconClass-6::before {
    content: '公告';
    text-align: center;
    color: #fff;
    font-family: 微软雅黑;
}

.icon-noticeNewStyleIconClass-7::before {
    content: '\a0567';
    text-align: center;
    color: #fff;
    font-size: 0.9rem;
}

.icon-noticeNewStyleIconClass-8::before {
    content: '公告';
    text-align: center;
    color: inherit;
    font-family: 微软雅黑;
}

.icon-noticeNewStyleIconClass-9::before {
    content: '\a0570';
    text-align: center;
    color: inherit;
    font-size: 1.05rem;
}

/*新样式的图片样式*/
.newNoticeImg {
    float: left;
    width: 1.75rem;
    height: 1.75rem;
    box-sizing: border-box;
    margin-left: -0.6rem;
    margin-top: 0.22rem;
    position: relative;
}

/*新样式的字体图标*/
.newNoticeIcon {
    float: left;
    width: 1.75rem;
    height: 1.75rem;
    box-sizing: border-box;
    margin-top: 0.25rem;
    margin-left: -0.6rem;
    margin-top: 0.15rem;
    position: relative;
}

.newNoticeIcon::before {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    text-align: center;
}

/*新增样式的文字左边的小圆点*/
.listStyleCircle {
    display: inline-block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    width: 0.15rem;
    height: 0.15rem;
    border-radius: 50%;
    background: #999;
}

.noticeNewStyleContentText {
    display: inline-block;
    color: #333;
    font-size: 0.6rem;
    position: relative;
    line-height: normal;
    padding-left: 0.34rem;
    margin-left: 0.21rem;
    width: 250px;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 12.5rem;
}

/*新样式的左边区域*/

/*新样式下的自定义图片*/
.newNoticeImg img {
    width: 100%;
    height: 100%;
    display: inline-block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    text-align: center;
}

/*新样式下的自定义图片的文字*/
.newStyleImgNotice .noticeNewStyleContentText {
    margin-left: 0.28rem;
}

.noticeListTopMargin {
    height: 0.38rem;
}

.noticeListBottomMargin {
    height: 0.4rem;
}

.twoNoticeContentText {
    display: block;
    padding: 0.15rem 0 0.15rem 0.35rem;
    color: #333;
}

.oneNoticeScrollbar {
    color: #333;
}

/*如果不跳转，链接应该是指针状态*/
.cursorAuto {
    cursor: auto;
}

.cursorPointer {
    cursor: pointer;
}

/*滚动公告新增样式  end*/

.notice-ico {
    padding-left: 1.1rem;
}

.noticeMarqueeUD {
    position: relative;
    overflow: hidden;
    height: 1.3rem;
}

.noticeScrollbar {
    margin: 0;
    padding: 0.25rem 0 0.25rem 0;
    position: relative;
    overflow: hidden;
    width: 100%;
}

.noticeScrollbar .scrollbarLi {
    padding: 0.25rem 0.25rem;
    white-space: nowrap;
}

.noticeFontIcon {
    position: absolute;
}

.noticeImg {
    position: absolute;
    width: 0rem;
    height: 0rem;
}

.noticeImg img {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
}

.noticeContainer .empty_tips_panel {
    width: 100%;
}

.noticeContainer .empty_tips_panel {
    text-align: center;
    padding-top: 40px;
    padding-bottom: 55px;
}

.mobi_notice_right_to_left_normal {
    animation: mobi_notice_right_to_left 20s linear 1s infinite; /* IE 10+ */
}
.mobi_notice_right_to_left_slow {
    animation: mobi_notice_right_to_left 30s linear 1s infinite; /* IE 10+ */
}
.mobi_notice_right_to_left_fast {
    animation: mobi_notice_right_to_left 15s linear 1s infinite; /* IE 10+ */
}
@keyframes mobi_notice_right_to_left {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-100%);
    }
}
.mobi_notice_right_to_left_50_fast {
    animation: mobi_notice_right_to_left_50 7.5s linear 1s infinite; /* IE 10+ */
}
.mobi_notice_right_to_left_50_slow {
    animation: mobi_notice_right_to_left_50 15s linear 1s infinite; /* IE 10+ */
}
.mobi_notice_right_to_left_50_normal {
    animation: mobi_notice_right_to_left_50 10s linear 1s infinite; /* IE 10+ */
}
@keyframes mobi_notice_right_to_left_50 {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-50%);
    }
}

.mobi_notice_left_to_right_normal {
    animation: mobi_notice_left_to_right 20s linear 1s infinite; /* IE 10+ */
}
.mobi_notice_left_to_right_slow {
    animation: mobi_notice_left_to_right 30s linear 1s infinite; /* IE 10+ */
}
.mobi_notice_left_to_right_fast {
    animation: mobi_notice_left_to_right 15s linear 1s infinite; /* IE 10+ */
}
@keyframes mobi_notice_left_to_right {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(100%);
    }
}

.mobi_notice_left_to_right_50_fast {
    animation: mobi_notice_left_to_right_50 7.5s linear 1s infinite; /* IE 10+ */
}
.mobi_notice_left_to_right_50_slow {
    animation: mobi_notice_left_to_right_50 15s linear 1s infinite; /* IE 10+ */
}
.mobi_notice_left_to_right_50_normal {
    animation: mobi_notice_left_to_right_50 10s linear 1s infinite; /* IE 10+ */
}
@keyframes mobi_notice_left_to_right_50 {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(50%);
    }
}
</style>
