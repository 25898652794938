var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "item_type_checkbox" }, [
    _c(
      "ul",
      { staticClass: "checkbox_style" },
      _vm._l(_vm.options, function (item) {
        return _c(
          "li",
          {
            key: item.id,
            staticClass: "checkbox_item",
            class: { checkbox_active: _vm.isChecked(item) },
            on: {
              click: function ($event) {
                return _vm.toggleItem(item)
              },
            },
          },
          [
            _c("span", { staticClass: "checkbox_inner faisco-icons-S000121" }),
            _vm._v("\n            " + _vm._s(item) + "\n        "),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }