var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("module-frame", { attrs: { "module-id": _vm.moduleId } }, [
    _vm.render
      ? _c(
          "div",
          { staticClass: "newsGroupPanel" },
          [
            !_vm.dataList.length && _vm.manageMode
              ? [
                  _c(
                    "div",
                    { staticClass: "empty_tips_panel" },
                    [
                      _c("div", { staticClass: "text" }, [
                        _vm._v("请在右侧面板中添加分类~"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "button-component",
                        {
                          attrs: { active: "" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.addGroup.apply(null, arguments)
                            },
                          },
                        },
                        [_vm._v("马上添加")]
                      ),
                    ],
                    1
                  ),
                ]
              : !_vm.dataList.length && !_vm.manageMode
              ? [
                  _c("div", { staticClass: "cus_Empty_tips_panel" }, [
                    _c("div", { staticClass: "text" }, [
                      _vm._v("没有添加分类"),
                    ]),
                  ]),
                ]
              : [
                  _c(
                    "div",
                    {
                      staticClass: "sortPanelList",
                      attrs: { id: "pgList" + _vm.id },
                    },
                    [
                      _vm._l(_vm.dataList, function (item, index) {
                        return [
                          _c(
                            "div",
                            {
                              key: item.name,
                              staticClass: "line icon-gline j-lineH J_lineH",
                            },
                            [
                              !item.children ||
                              (item.children && !item.children.length)
                                ? [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "pgName g_news_sort_name",
                                        style: _vm.new_sort_style,
                                        attrs: { href: item.href },
                                      },
                                      [_vm._v(_vm._s(item.name))]
                                    ),
                                  ]
                                : _c(
                                    "div",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.MobipgItemJump(item, index)
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "pgName g_news_sort_name",
                                          style: _vm.new_sort_style,
                                        },
                                        [_vm._v(_vm._s(item.name))]
                                      ),
                                    ]
                                  ),
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _c("div", {
                            key: item.name + "sep",
                            staticClass: "g_separator separatorLine",
                          }),
                        ]
                      }),
                    ],
                    2
                  ),
                ],
          ],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }