var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: ["first_panel", "groupList" + _vm.moduleId],
      attrs: { id: "groupList" + _vm.moduleId, _prUrl: _vm.prUrl },
    },
    [
      _c("GroupBanner", {
        attrs: { "nav-index": _vm.navIndex, "no-lazy-load": _vm.noLazyLoad },
      }),
      _vm._v(" "),
      _vm._l(_vm.list, function (item, index) {
        return _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isSelect(item, _vm.catalogName),
                expression: "isSelect(item, catalogName)",
              },
            ],
            key: index,
            staticClass: "J_secondGroupPanel second_group_panel",
            class: { selected: _vm.isSelect(item, _vm.catalogName) },
            attrs: { id: "sed_" + item.name, gcn: item.name },
          },
          [
            _c(
              "div",
              {
                class: [
                  "second_groupitem",
                  "second_groupitem_" + _vm.moduleStyle,
                ],
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "group_img_wrap clearFix",
                    staticStyle: { "margin-top": "0" },
                  },
                  _vm._l(
                    _vm.lessThanMaxProductGroup(item.groupList),
                    function (childItem, childIndex) {
                      return _c(
                        "div",
                        {
                          key: childIndex,
                          staticClass: "J_groupItem f-groupImgContainer",
                          class: _vm.secondGroupClass,
                          attrs: { "data-id": childItem.id },
                        },
                        [
                          _vm.moduleStyle == 5 || _vm.moduleStyle == 6
                            ? _c(
                                "a",
                                {
                                  staticClass: "f-imgContainer",
                                  attrs: {
                                    id: "imgContainer" + childItem.id,
                                    href: "pr.jsp?groupId=" + childItem.id,
                                    target: "_self",
                                  },
                                  on: {
                                    mouseover: function ($event) {
                                      return _vm.grouItemMouseOver(
                                        $event,
                                        childItem
                                      )
                                    },
                                    mouseout: function ($event) {
                                      return _vm.groupItemMouseOut($event)
                                    },
                                  },
                                },
                                [
                                  childItem.mIcon != "" &&
                                  _vm.isFontIconId(childItem.mIcon)
                                    ? _c("span", {
                                        class: [
                                          "J_itemImage",
                                          "isFontIcon",
                                          "f-itemImg",
                                          childItem.iconPath,
                                        ],
                                        style: _vm.fontIconStyle,
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  childItem.mIcon != "" &&
                                  !_vm.isFontIconId(childItem.mIcon) &&
                                  childItem.fileInfo != null
                                    ? _c("div", {
                                        staticClass:
                                          "J_itemImage f-itemImg J_img_lazyload",
                                        style: _vm.imgStyle(childItem),
                                        attrs: {
                                          "src-original": childItem.iconPath,
                                          "data-w": childItem.fileInfo.w,
                                          "data-h": childItem.fileInfo.h,
                                        },
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  childItem.mIcon != "" &&
                                  !_vm.isFontIconId(childItem.mIcon) &&
                                  childItem.fileInfo == null
                                    ? _c("span", {
                                        staticClass:
                                          "J_itemImage notImage faisco-icons-pic",
                                        style: _vm.fontIconStyle,
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  childItem.mIcon == ""
                                    ? _c("div", {
                                        staticClass:
                                          "J_itemImage notImage J_img_lazyload",
                                        style: _vm.defaultImgStyle,
                                        attrs: {
                                          "src-original": _vm.defaultImg,
                                        },
                                      })
                                    : _vm._e(),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "pr.jsp?groupId=" + childItem.id,
                                target: "_self",
                              },
                            },
                            [
                              _c("p", {
                                staticClass: "f-itemImgName sed_groupname",
                                style: _vm.sedGroupNameStyle,
                                domProps: {
                                  textContent: _vm._s(childItem.name),
                                },
                              }),
                            ]
                          ),
                        ]
                      )
                    }
                  ),
                  0
                ),
              ]
            ),
          ]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }