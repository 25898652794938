<template>
    <div class="photoModule newCarouselMultiPhoto styleForm12 innerBox">
        <div :id="'photoViewBox' + module.id" class="photoViewBox">
            <new-carousel-image-item
                v-if="render"
                :item-index="currentIndex"
                :item="selectItem"
                :outer-box="false"
                :no-lazy-load="!firstLazyLoad || noLazyLoad"
            ></new-carousel-image-item>
        </div>
        <div :id="'selectedImgBox' + id" class="selectedImgBox">
            <div
                :id="'selectedImgList' + id"
                class="selectedImgList"
                :class="selectedImgListCls"
                :style="selectedImgListStyle"
                @touchstart="touchstart"
                @touchend="touchend"
            >
                <div
                    v-for="(item, index) in cycleList"
                    :key="index"
                    class="selectItem J_img_lazyload"
                    :class="index == currentIndex ? 'selected' : ''"
                    :src-original="item.picPath"
                    :style="'background-image:url(' + (noLazyLoad ? item.picPath : options.loadingPath) + ')'"
                    @click="changeImgIndex(index)"
                ></div>
            </div>
        </div>
    </div>
</template>

<script>
import { getPreviewObject } from '@/shared/getPreviewObject';
import NewCarouselImageItem from '../components/NewCarouselImageItem.vue';

export default {
    name: 'CarouselType12',
    components: {
        NewCarouselImageItem,
    },
    inject: ['module', 'options'],
    // eslint-disable-next-line vue/require-prop-types
    props: ['photoSwipeList', 'nameList', 'cycleList', 'noLazyLoad'],
    data() {
        return {
            id: this.module.id,
            styleId: this.module.style,
            currentIndex: 0,
            swipeIndex: 0,
            touchHasEnd: true,
            render: true,
            firstLazyLoad: true,
        };
    },
    computed: {
        content() {
            return this.module.content;
        },
        mhighs() {
            return this.content.mhighs;
        },
        picScale() {
            return this.content.picScale;
        },
        selectedImgListStyle() {
            var style = {};
            if (this.cycleList.length > 5) {
                style['width'] = this.cycleList.length * 20 + '%';
            }
            if (this.mhighs.carouselSet.carouselType != 2) {
                style['transform'] = 'translate(' + (-this.swipeIndex * 100) / this.cycleList.length + '%)';
            }
            return style;
        },
        selectItem() {
            return this.cycleList[this.currentIndex];
        },
        selectedImgListCls() {
            return this.touchHasEnd ? 'touchEnd' : '';
        },
    },
    watch: {
        selectItem() {
            this.firstLazyLoad = false;
            this.render = false;
            this.$nextTick(function () {
                this.render = true;
            });
        },
    },
    mounted: function () {
        this.setAutoplay();
        bindEvent(this);
    },
    methods: {
        setAutoplay: function () {
            var _this = this;
            window.clearInterval(this.timer);
            if (this.mhighs.carouselSet.carouselType != 2 && this.cycleList.length > 2) {
                var aniTime = this.carouselType == 0 ? 4000 : this.mhighs.carouselSet.aniTime;
                var switchTime = this.carouselType == 0 ? 500 : this.mhighs.carouselSet.switchTime;
                var delay = aniTime + switchTime;
                this.timer = window.setInterval(function () {
                    _this.next();
                }, delay);
            }
        },
        cleanAutoPlay: function () {
            window.clearInterval(this.timer);
        },
        touchstart() {
            this.touchHasEnd = false;
            this.cleanAutoPlay();
        },
        touchend() {
            this.touchHasEnd = true;
            this.setAutoplay();
        },
        changeImgIndex: function (index) {
            this.currentIndex = index;
        },
        next: function () {
            var imgLength = this.cycleList.length;
            var maxSwipeIndex = this.cycleList.length - 2;
            var tmpIndex = this.currentIndex + 1;
            if (tmpIndex == this.cycleList.length) {
                tmpIndex = 0;
            }
            if (imgLength > 5) {
                if (tmpIndex == 0) {
                    this.swipeIndex = 0;
                } else if (tmpIndex > 2 && tmpIndex < maxSwipeIndex) {
                    this.swipeIndex += 1;
                }
            }
            this.currentIndex = tmpIndex;
        },
    },
};

function bindEvent(context) {
    var itemContainerDom = getPreviewObject('selectedImgList' + context.id),
        photoDom = getPreviewObject('selectedImgBox' + context.id),
        phoItemsWidth = itemContainerDom.offsetWidth,
        centerWidth = photoDom.offsetWidth;
    if (phoItemsWidth > centerWidth && jm.os.supportsTouch) {
        new iScroll(photoDom, {
            scrollX: true,
            scrollY: false,
            vScroll: false,
            mouseWheel: true,
            remarkXCoordinate: true,
        });
        // 水平时候禁止侧滑，返回上下页   ------zhj
        var isVer,
            oldX,
            oldY,
            currX,
            currY,
            $photoSlide = jm(photoDom),
            $photoSlideList = jm(itemContainerDom);

        //水平滑动，禁止文档移动
        $photoSlide.off('touchstart.photoCross').on('touchstart.photoCross', function (event) {
            var touch = event.touches.length > 0 ? event.touches[0] : event.chengedTouches[0];
            oldX = touch.pageX;
            oldY = touch.pageY;

            $photoSlide.off('touchmove.photoCross').on('touchmove.photoCross', photoListTouchMove);
            $photoSlide.off('touchend.photoCross').on('touchend.photoCross', photoListTouchEnd);
        });

        // eslint-disable-next-line no-inner-declarations
        function photoListTouchMove(event) {
            var touch = event.touches.length > 0 ? event.touches[0] : event.chengedTouches[0];
            currX = touch.pageX;
            currY = touch.pageY;

            if (isVer === undefined) {
                if (oldX !== undefined && oldY !== undefined) {
                    isVer = isVertical(oldX, oldY, currX, currY); //获取当前滑动状态
                    if (isVer === false) {
                        event.preventDefault();
                    }
                    $photoSlide.off('touchmove.prod');
                }
            }

            oldX = currX;
            oldY = currY;
        }

        // eslint-disable-next-line no-inner-declarations
        function photoListTouchEnd() {
            isVer = oldX = oldY = currX = currY = undefined;
            //事件解绑
            $photoSlide.off('touchmove.photoCross', photoListTouchMove);
            $photoSlide.off('touchend.photoCross', photoListTouchEnd);
        }

        // eslint-disable-next-line no-inner-declarations
        function isVertical(x1, y1, x2, y2) {
            var dx = Math.abs(x1 - x2),
                dy = Math.abs(y1 - y2);

            //避免分母为0
            if (dx === 0 && dy === 0) {
                return false;
            }
            if (dx === 0 && dy > 0) {
                return true;
            }
            if (dy === 0 && dx > 0) {
                return false;
            }
            return dy / dx > 1 ? true : false;
        }

        //在子节点上添加事件，利用事件冒泡在垂直滑动的时候，阻止iscroll滑动
        $photoSlideList
            .children()
            .off('touchmove.banIScroll')
            .on('touchmove.banIScroll', function (event) {
                if (isVer === true) {
                    event.stopPropagation();
                }
            });
    }
}
</script>

<style lang="scss">
.formStyle42 .newCarouselMultiPhoto.styleForm12 .photoViewBox {
    display: block;
    margin: 0.75rem 0.75rem 0.55rem 0.75rem;
}
.formStyle42 .innerBox.styleForm12 .photoImageItem .photoImageItemContent {
    border-radius: 0.2rem;
}
.formStyle42 .newCarouselMultiPhoto.styleForm12 .photoViewBox .imgLink {
    height: 7.75rem;
    display: block;
    position: relative;
    overflow: hidden;
}
.formStyle42 .newCarouselMultiPhoto.styleForm12 .selectedImgBox {
    height: 2.2rem;
    display: block;
    margin: 0 0.75rem 1rem 0.65rem;
    overflow: hidden;
    padding-left: 0.1rem;
}
.formStyle42 .newCarouselMultiPhoto.styleForm12 .selectedImgList {
    width: 100%;
    height: 2.2rem;
    display: flex;
    align-items: center;
}

.formStyle42 .newCarouselMultiPhoto.styleForm12 .selectedImgList.touchEnd {
    transition: 0.3s all !important;
}

.formStyle42 .newCarouselMultiPhoto.styleForm12 .selectedImgList .selectItem {
    width: 16.875%;
    height: 2.05rem;
    margin-right: 0.2rem;
    cursor: pointer;
    border-radius: 0.1rem;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}
.formStyle42 .newCarouselMultiPhoto.styleForm12 .selectedImgList .selectItem:last-child {
    margin: 0;
}
.formStyle42 .newCarouselMultiPhoto.styleForm12 .selectedImgList .selectItem.selected {
    cursor: pointer;
    transform: scale(1.05);
}
</style>
