var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "text_panel" },
    _vm._l(_vm.list, function (item) {
      return _c(
        "a",
        { key: item.id, attrs: { href: _vm.prUrl + "?groupId=" + item.id } },
        [
          _c("div", { staticClass: "text_item" }, [
            _c(
              "div",
              {
                staticClass: "text first_groupname g_productSort_name",
                style: _vm.textStyle(item),
              },
              [_vm._v(_vm._s(item.name))]
            ),
          ]),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }