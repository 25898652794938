<template>
    <module-frame :module-id="moduleId">
        <div
            :id="'adsPicture' + module.id"
            :class="'adsPicture styleForm40 adsPicture' + module.id"
            @dblclick="openPattern"
        >
            <a :href="href" :class="{ adsPictureCusor: pointer }" :rel="nf" :onclick="jumpOnclickStr">
                <img
                    v-if="renderImg"
                    class="J_img_lazyload"
                    :src-original="imgSrc"
                    :src="proxySrc"
                    :alt="imgAlt"
                    :style="imgStyle"
                    @click.stop.prevent="adsJump"
                />
            </a>
        </div>
    </module-frame>
</template>
<script>
import ModuleFrame from '@/modules/frame/index.vue';
import { bindImgLazyLoad } from '@/shared/imageLazyload';

import { mapGetters } from 'vuex';
import { createUrlArgsOnclickStr } from '@/modules/comm';
export default {
    name: 'AdversitingPicture',
    components: {
        ModuleFrame,
    },
    props: {
        moduleId: {
            type: Number,
            default: -1,
        },
    },
    data: function () {
        return {
            render: true,
            renderImg: true,
        };
    },
    computed: {
        ...mapGetters(['getModuleById']),
        ...mapGetters('app', ['hashRemoved']),
        module() {
            return this.getModuleById(this.moduleId);
        },
        options() {
            return this.module.renderOptions;
        },
        styleId() {
            return this.module.style;
        },
        pointer: function () {
            if (this.module.content.ji != '') {
                return false;
            } else {
                if (this.href == 'javascript:;' || this.href == '') {
                    return true;
                }
                return false;
            }
        },
        href: function () {
            return this.module.adverPic.href;
        },
        nf: function () {
            return this.module.adverPic.nf ? 'nofollow' : '';
        },
        imgSrc: function () {
            return this.module.adverPic.imgSrc;
        },
        imgAlt: function () {
            return this.module.adverPic.imgAlt;
        },
        imgStyle: function () {
            return {
                marginTop: '-1px',
            };
        },
        jumpOnclickStr() {
            return (
                this.href &&
                createUrlArgsOnclickStr(
                    this.module.content.reqArgs || '',
                    this.module._openRemoveUrlArgs,
                    this.hashRemoved
                )
            );
        },
        proxySrc() {
            return this.options.isFirstScreen ? this.imgSrc : this.options.loadingPath;
        },
    },
    watch: {
        imgSrc() {
            var self = this;
            self.renderImg = false;
            self.$nextTick(() => {
                self.renderImg = true;
                self.$nextTick(() => {
                    bindImgLazyLoad(`module${this.moduleId}`);
                });
            });
        },
    },
    mounted() {
        if (!this.options.isFirstScreen) {
            bindImgLazyLoad(`module${this.moduleId}`);
        }
    },
    methods: {
        adsJump() {
            if (VITE_APP_MODE !== 'visitor' && this.module.content.ji == '') {
                if (this.href != 'javascript:;' && this.href != '') {
                    Fai.ing('管理态不支持跳转外部链接，请通过“预览”进行访问', true);
                } else if (this.href == 'javascript:;' || this.href == 'javascript:void(0)') {
                    this.$designer.open('pattern adversiting-picture', this.moduleId, this.styleId, 0, false, true);
                }
            } else {
                if (this.href != 'javascript:void(0)') {
                    let a = jm('#adsPicture' + this.moduleId)
                        .find('a')
                        .get(0);
                    jm(a).get(0).click();
                } else if (VITE_APP_MODE !== 'visitor' && this.href == 'javascript:void(0)') {
                    this.$designer.open('pattern adversiting-picture', this.moduleId, this.styleId, 0, false, true);
                }
            }
        },
        openPattern() {
            var childTab = Fai.top._openThemeV3 ? 'module-skin' : 'set-pattern';
            this.$designer.open('pattern ' + childTab, this.moduleId, this.styleId, 0);
        },
    },
};
</script>
<style lang="scss" scoped>
.adsPictureCusor {
    cursor: default;
}

.adsPicture a {
    display: block;
    font-size: 0;
    overflow: hidden;
}
.formStyle40 .adsPicture a img {
    width: 100%;
    float: left;
}
</style>
