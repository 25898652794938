var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "photoSlide", attrs: { id: "photoSlide" + _vm.moduleId } },
    [
      _vm.isMarqueeShow
        ? [
            _c(
              "div",
              {
                class:
                  "J_marqueeContainer" + _vm.moduleId + " fk-marqueeContainer",
              },
              [
                _c("div", { class: "J_marqueePanel" + _vm.moduleId }, [
                  _c(
                    "div",
                    {
                      style: _vm.slideItemContainerStyle,
                      attrs: { id: "photoSlideList" + _vm.moduleId },
                    },
                    _vm._l(_vm.contentList, function (item, index) {
                      return _c("SlideItem", {
                        key: item.picId,
                        attrs: {
                          "photo-item": item,
                          "module-id": _vm.module.id,
                          "module-content": _vm.module.content,
                          "link-type": _vm.linkType,
                          "img-href": _vm.imgHref(item.jumpUrl),
                          "img-index": index + 1,
                          "is-empty": _vm.isEmptyText,
                          "slides-index": item.index,
                          "max-height": _vm.imgStyleHeight(index),
                          "loading-path": _vm.options.loadingPath,
                          "no-lazy-load": _vm.noLazyLoad,
                        },
                      })
                    }),
                    1
                  ),
                ]),
              ]
            ),
          ]
        : [
            _c(
              "div",
              {
                style: _vm.slideItemContainerStyle,
                attrs: { id: "photoSlideList" + _vm.moduleId },
              },
              _vm._l(_vm.contentList, function (item, index) {
                return _c("SlideItem", {
                  key: item.picId,
                  attrs: {
                    "photo-item": item,
                    "module-content": _vm.module.content,
                    "link-type": _vm.linkType,
                    "img-href": _vm.imgHref(item.jumpUrl),
                    "img-index": index + 1,
                    "is-empty": _vm.isEmptyText,
                    "slides-index": item.index,
                    "max-height": _vm.imgStyleHeight(index),
                    "loading-path": _vm.options.loadingPath,
                    "no-lazy-load": _vm.noLazyLoad,
                  },
                })
              }),
              1
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }