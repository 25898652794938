var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "multi_photo_item multi_vertical_item",
      style: _vm.photoItemStyle,
      attrs: { id: _vm.photoItem.picId, "data-picid": _vm.photoItem.picId },
    },
    [
      _c("div", { style: _vm.photoItemLinkStyle }, [
        _c(
          "a",
          {
            attrs: {
              hidefocus: _vm.linkFocus,
              href: _vm.imgHref,
              rel: _vm.photoItem.nf ? "nofollow" : "",
              onclick: _vm.jumpOnclickStr,
            },
            on: { click: _vm.photoSlidesshow },
          },
          [
            _c("div", {
              directives: [
                {
                  name: "drag-resize",
                  rawName: "v-drag-resize",
                  value: _vm._resizeOptions,
                  expression: "_resizeOptions",
                },
              ],
              staticClass: "J_img_lazyload",
              style: _vm.photoItemImgStyle,
              attrs: { "src-original": _vm.photoItem.picThumbPath },
            }),
            _vm._v(" "),
            _c("div", { style: _vm.verticalTextStyle }, [
              _c("div", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showTitle,
                    expression: "showTitle",
                  },
                ],
                staticClass: "vertical_title",
                style: _vm.titleWrapStyle,
                domProps: { innerHTML: _vm._s(_vm.titleData) },
              }),
              _vm._v(" "),
              _c("div", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showDesc,
                    expression: "showDesc",
                  },
                ],
                staticClass: "vertical_desc",
                style: _vm.descWrapStyle,
                domProps: { innerHTML: _vm._s(_vm.descData) },
              }),
            ]),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }