<template>
    <div :id="_listId" class="mProductList" :class="_listClass" :mType="module.content.t">
        <div :id="'productSlide' + module.id" class="productSlide">
            <div :id="'productSlideList' + module.id" class="productSlideList">
                <template v-for="(info, index) in productList">
                    <slide-item :key="'p_' + info.id" :info="info" :index="index" :no-lazy-load="noLazyLoad" />
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import SlideItem from './slide-item.vue';
import productMixin from '../../mixins/productMixin';
export default {
    name: 'SlideList',
    components: { SlideItem },
    mixins: [productMixin],
    inject: ['module', 'options'],
    props: ['productList', 'noLazyLoad'],
};
</script>

<style></style>
