var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "line icon-gline j-lineH J_lineH" }, [
    _vm.moduleStyle == 8 || _vm.moduleStyle == 0
      ? _c(
          "div",
          {
            staticClass: "clearFix",
            on: { click: _vm.catalogProductItemClick },
          },
          [
            _c("div", {
              staticClass: "pgName g_productSort_name",
              style: _vm.pn,
              domProps: { textContent: _vm._s(_vm.item.name) },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "pgMark" }),
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.moduleStyle != 8 && _vm.moduleStyle != 0
      ? _c(
          "a",
          {
            staticClass: "pgName",
            attrs: {
              target: "_self",
              href:
                _vm.lanCode +
                _vm.npgcUrl +
                "?mId=" +
                _vm.moduleId +
                "&pgt=" +
                2 +
                "&pgs=" +
                _vm.moduleStyle +
                "&selectCata=" +
                _vm.item.name,
            },
          },
          [
            _c("span", {
              staticClass: "navLineTitle g_productSort_name",
              style: _vm.pn,
              domProps: { textContent: _vm._s(_vm.item.name) },
            }),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }