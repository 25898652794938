var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("module-frame", { attrs: { "module-id": _vm.moduleId } }, [
    _c(
      "div",
      { staticClass: "fk-floatImgContainer J_floatImgContainer" },
      [
        _c(
          "a",
          {
            class: _vm.AClassList,
            attrs: {
              hidefocus: "true",
              rel: _vm.notFollow,
              onclick: _vm.jumpOnclickStr,
            },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.picLinkClick($event)
              },
            },
          },
          [
            _c(
              "div",
              {
                staticClass: "floatImgWrap J_floatImgWrap",
                style: _vm.floatImgWrapStyle,
                attrs: { "data-picid": _vm.imgId },
                on: {
                  click: _vm.openPictureSlidesV4,
                  dblclick: _vm.doubleEdit,
                },
              },
              [
                !_vm.isFontIcon
                  ? _c("img", {
                      directives: [{ name: "ban-copy", rawName: "v-ban-copy" }],
                      class: _vm.imgClass,
                      style: _vm.imgStyle,
                      attrs: {
                        src: _vm.imgSrc,
                        "src-original": _vm.picThumbPath,
                        usemap: _vm.picMap,
                        alt: _vm.alt,
                      },
                      on: { click: _vm.uploadPic },
                    })
                  : _c("div", {
                      class: _vm.fontClassList,
                      style: _vm.fontIconStyle,
                    }),
                _vm._v(" "),
                _vm.hotAreaList.length > 0 && _vm.isHotArea
                  ? _c(
                      "map",
                      { attrs: { id: _vm.hotAreaName, name: _vm.hotAreaName } },
                      _vm._l(_vm.hotAreaList, function (item, index) {
                        return _c("area", {
                          key: "hotArea_" + index,
                          attrs: {
                            shape: "rect",
                            hidefocus: "true",
                            onfocus: "this.blur()",
                            coords: _vm.getAreaPosition(item),
                            href: _vm.getAreaLink(item),
                            title: item.tip,
                            onclick: _vm.hotAreaOnclickStr(item),
                          },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.areaClick($event, item)
                            },
                          },
                        })
                      }),
                      0
                    )
                  : _vm._e(),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _vm.showMobiViewer
          ? _c("image-viewer", {
              attrs: {
                "z-index": _vm.zIndex,
                "initial-index": _vm.initialIndex,
                "on-close": _vm.onClose,
                "url-list": _vm.urlList,
                "is-mobi": true,
                "content-terminal": "mobi",
                "manage-mode": _vm.manageMode,
                "log-event-config": _vm.logEventConfig,
                "document-event-el": _vm.documentEventEl,
                "append-to-container-id": "g_body",
                "append-to-body": false,
                "disable-long-press": _vm.banCopyTextImg,
              },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }