var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "m_custom_search_result J_customSearch",
      attrs: { id: "customSearchContainer" + _vm.module.id },
    },
    [
      _vm.showModule
        ? [
            _vm.render
              ? [
                  !_vm.hideEntry
                    ? [
                        _c(
                          "div",
                          {
                            staticClass:
                              "m_custom_search m_custom_search_entrance",
                          },
                          [
                            !_vm.hideTitle
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "m_custom_search_projectName",
                                  },
                                  [_vm._v(_vm._s(_vm.project.name))]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.hideTips
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "m_custom_search_projectTips",
                                  },
                                  [_vm._v(_vm._s(_vm.project.tip))]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "m_custom_search_field" },
                              _vm._l(_vm.itemListCondited, function (item) {
                                return _c(
                                  "div",
                                  {
                                    key: item.id,
                                    staticClass: "m_custom_search_field_item",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "m_custom_search_field_label",
                                      },
                                      [
                                        item.isRequired
                                          ? _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "m_custom_search_star",
                                              },
                                              [_vm._v("*")]
                                            )
                                          : _vm._e(),
                                        _vm._v(
                                          _vm._s(
                                            item.itemName.includes("：")
                                              ? item.itemName
                                              : item.itemName + ":"
                                          )
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("CustomSearchItem", {
                                      attrs: {
                                        item: item,
                                        "form-info": _vm.formInfo,
                                        module: _vm.module,
                                        "func-type": _vm.funcType,
                                        "mobile-ct-list": _vm.mobileCtList,
                                        "select-place-holder":
                                          _vm.selectPlaceHolder(""),
                                        "input-place-holder":
                                          _vm.inputPlaceHolder(""),
                                      },
                                      on: { "on-input": _vm.onInput },
                                    }),
                                  ],
                                  1
                                )
                              }),
                              0
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "m_custom_search_submit_wrap" },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "m_custom_search_submit g_main_bgColor_v3 g_oldThemeBgColor J_customSearchSubmit",
                                    on: { click: _vm.onSubmit },
                                  },
                                  [_vm._v(_vm._s(_vm.lanConf.customSearchText))]
                                ),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", {
                          staticClass: "m_custom_search_result_result_split",
                          staticStyle: {
                            "background-color": "#f5f5f5",
                            height: "0.8rem",
                          },
                        }),
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "m_custom_search_result_result" }, [
                    _c(
                      "div",
                      { staticClass: "m_custom_search_result_result_title" },
                      [_vm._v(_vm._s(_vm.lanConf.customSearchResult))]
                    ),
                    _vm._v(" "),
                    !_vm.hideResultExplain && _vm.module.isNotAuth
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "m_custom_search_result_result_explain",
                          },
                          [_vm._v(_vm._s(_vm.project.resultExplain))]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.totalSize > 0
                      ? _c("div", {
                          staticClass:
                            "m_custom_search__result_result_projectDataTips",
                          domProps: {
                            innerHTML: _vm._s(_vm.totalSizeTips(_vm.totalSize)),
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "m_custom_search_result_result_area" },
                      [
                        _vm.resultInfo.length < 1
                          ? _c(
                              "div",
                              { staticClass: "m_custom_search_result_empty" },
                              [
                                _c("div", {
                                  staticClass:
                                    "m_custom_search_result_empty_icon",
                                }),
                                _vm._v(" "),
                                _vm.resultTips
                                  ? _c("div", {
                                      staticClass:
                                        "m_custom_search_result_empty_tips",
                                      domProps: {
                                        innerHTML: _vm._s(_vm.resultTips),
                                      },
                                      on: { click: _vm.handleLogin },
                                    })
                                  : _vm._e(),
                              ]
                            )
                          : _vm._l(_vm.resultInfo, function (itemData) {
                              return _c(
                                "div",
                                {
                                  key: itemData.id,
                                  staticClass:
                                    "m_custom_search_result_result_data",
                                },
                                _vm._l(
                                  _vm.filterPhotoItem(itemData),
                                  function (data, index) {
                                    return _c(
                                      "div",
                                      {
                                        key: index,
                                        staticClass:
                                          "m_custom_search_result_result_item",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "m_custom_search_result_result_label",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                data.type == 5 &&
                                                  _vm.funcType == 1
                                                  ? data.headline
                                                  : data.itemName
                                              )
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        data.type === 4 && _vm.funcType == 0
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "m_custom_search_result_result_value m_custom_search_result_result_photo_wrap",
                                              },
                                              [
                                                _c("div", {
                                                  staticClass:
                                                    "m_custom_search_result_result_photo",
                                                  class: _vm.photoScaleClass,
                                                  style:
                                                    _vm.genPhotoStyle(data),
                                                  attrs: {
                                                    "data-picid": data.value,
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.openPictureSlide(
                                                        itemData,
                                                        data.id
                                                      )
                                                    },
                                                  },
                                                }),
                                              ]
                                            )
                                          : data.type == 5 && _vm.funcType == 0
                                          ? _c("file-view", {
                                              attrs: {
                                                "img-class":
                                                  _vm.photoScaleClass,
                                                "img-style": {
                                                  "background-image":
                                                    "url(" + data.path + "})",
                                                },
                                                "file-item": data,
                                              },
                                              on: {
                                                "img-click": function ($event) {
                                                  return _vm.openPictureSlide(
                                                    itemData,
                                                    data.id
                                                  )
                                                },
                                              },
                                            })
                                          : data.type == 7 && _vm.funcType == 1
                                          ? [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "m_custom_search_result_result_value m_custom_search_result_result_photo_wrap",
                                                },
                                                [
                                                  _vm._l(
                                                    data.fileList,
                                                    function (fileItem) {
                                                      return [
                                                        fileItem.isImage
                                                          ? _c(
                                                              "div",
                                                              {
                                                                key: fileItem.name,
                                                                staticClass:
                                                                  "m_custom_search_result_result_ellipsis",
                                                                class:
                                                                  _vm.photoScaleClass,
                                                                attrs: {
                                                                  "data-picid":
                                                                    fileItem.value,
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.openPictureSlide(
                                                                        itemData,
                                                                        data.id
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    fileItem.fileName
                                                                  )
                                                                ),
                                                              ]
                                                            )
                                                          : _c(
                                                              "a",
                                                              {
                                                                key: fileItem.name,
                                                                staticClass:
                                                                  "m_custom_search_result_result_ellipsis",
                                                                attrs: {
                                                                  hidefocus:
                                                                    "true",
                                                                  rel: "nofollow",
                                                                  target:
                                                                    "_blank",
                                                                  href: fileItem.isPdf
                                                                    ? fileItem.path
                                                                    : fileItem.downloadPath,
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                                            " +
                                                                    _vm._s(
                                                                      fileItem.fileName
                                                                    ) +
                                                                    "\n                                        "
                                                                ),
                                                              ]
                                                            ),
                                                      ]
                                                    }
                                                  ),
                                                ],
                                                2
                                              ),
                                            ]
                                          : (data.type == 5 ||
                                              data.type == 1) &&
                                            _vm.funcType == 1
                                          ? _c("div", {
                                              staticClass:
                                                "m_custom_search_result_result_value",
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  _vm.encodeHtml(
                                                    data.type == 5 &&
                                                      _vm.funcType == 1
                                                      ? data.input
                                                      : data.value
                                                  )
                                                ),
                                              },
                                            })
                                          : _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "m_custom_search_result_result_value",
                                              },
                                              [_vm._v(_vm._s(data.value))]
                                            ),
                                      ],
                                      2
                                    )
                                  }
                                ),
                                0
                              )
                            }),
                        _vm._v(" "),
                        _vm._m(0),
                      ],
                      2
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.totalSize > 5
                    ? _c("pagenation", {
                        staticClass: "m_custom_search_result_result_pagenation",
                        attrs: {
                          "module-id": _vm.id,
                          "total-size": _vm.totalSize,
                          "page-size": 5,
                          pageno: _vm.pageno,
                          "open-theme-v3": _vm.openThemeV3,
                          "ajax-pagenation": true,
                        },
                        on: { "on-change": _vm.changePage },
                      })
                    : _vm._e(),
                ]
              : [
                  _c("div", { staticClass: "m_custom_search_no_project_box" }, [
                    _c("div", {
                      staticClass:
                        "m_custom_search_no_project_icon m_custom_search_no_project",
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "m_custom_search_no_project_tip" },
                      [_vm._v(_vm._s(_vm.LS.mobiSearchResult))]
                    ),
                  ]),
                ],
          ]
        : _c("div", { staticStyle: { height: "180px" } }, [_c("loading")], 1),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "m_custom_search_result_result_pageNation" },
      [
        _c("span", { staticClass: "m_custom_search_result_result_prev" }),
        _vm._v(" "),
        _c("span", { staticClass: "m_custom_search_result_result_next" }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }