var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "cityContainer", staticClass: "m_mobi_form_city" }, [
    _c("input", {
      staticClass: "m_mobi_form_input_text m_mobi_form_city",
      attrs: {
        id: _vm.id,
        type: "text",
        placeholder: _vm.placeHolder,
        readonly: "true",
      },
      domProps: { value: _vm.currentValue },
      on: { input: _vm.input },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }