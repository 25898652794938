<template>
    <div
        :id="itemId"
        :topClassName="_topInfo.topName"
        :topSwitch="_topInfo.topSwitch"
        :productId="info.id"
        :productName="info.name"
        class="productWaterFall J_product_item"
        :class="[itemClasses, contentStyleWrap]"
        :title="_title2"
        :style="waterFallMarginStyle(index)"
    >
        <a
            :href="info.extInfo.href"
            :class="[_contentStyle, _wrapBorderRadiusClass]"
            :style="_warpProRadiusStyle"
            :onclick="onclickStr"
        >
            <div :id="'waterFallImg' + info.id + 'module' + module.id" class="waterFallImg">
                <div
                    v-drag-resize="_resizeOptions"
                    :alt="info.name"
                    class="J_img_lazyload style8Img loading-for-lazyload"
                    :class="[imgClasses, _imgScaleClass, _imgBorderRadiusClass]"
                    :src-original="_imgSrc"
                    :style="proPicStyle"
                ></div>
                <div v-if="manageMode && info.top != 0 && !_productSelect" class="f-productListTopFlag">置顶</div>
                <sale-flag v-if="info.extInfo.salePromotionOpen" :info="info"></sale-flag>
                <quality-content-label
                    v-if="_isShowQualityContentLabel"
                    :text="_knowPayLabelText"
                ></quality-content-label>
            </div>
            <product-name
                v-if="module.content.showProName && module.content.picSetting.picSizeSet == 1"
                :info="info"
            ></product-name>
            <mall-cart
                v-if="!isNewAddModule && _showMallCart.mallCart2 && module.content.picSetting.picSizeSet == 1"
                :info="info"
                :holder="_showMallCartHolder"
            ></mall-cart>
            <prop-panel
                :info="info"
                :list="_showPropList"
                :ext-info="_extInfo"
                :show-mall-cart="_showMallCart"
                :text-align-class="textAlignClass"
                :no-name-wrap="noNameWrap"
            >
            </prop-panel>
        </a>
    </div>
</template>

<script>
import productItemMixin from '../../mixins/productItemMixin';
import saleFlag from '../comm/sale-flag.vue';
import propPanel from '../comm/prop-panel.vue';
import mallCart from '../comm/mall-cart.vue';
import qualityContentLabel from '../comm/quality-content-label.vue';

const manageMode = VITE_APP_MODE !== 'visitor';
export default {
    name: 'WaterfallItem',
    components: { saleFlag, mallCart, propPanel, qualityContentLabel },
    mixins: [productItemMixin],
    inject: ['module', 'options'],
    props: ['info', 'index', 'len'],
    data() {
        return {
            itemId: `productWaterFall${this.info.id}_module${this.module.id}`,
            imgWrapId: `imgClass${this.info.id}_module${this.module.id}`,
            manageMode,
        };
    },
    computed: {
        //已抢光标志图片
        soldOutImg() {
            return `${this.options.resRoot}/image/soldOut${this.module.extInfo.isCN ? '' : '_en'}.png`;
        },
        proPicStyle() {
            if (this.isNewAddModule) {
                if (this._isTransparentStyle) {
                    return { ...this._imgBgStyle, ...this._proRadiusStyle };
                } else {
                    return { ...this._imgBgStyle, ...this._fixProRadiusStyle };
                }
            } else {
                return this._imgBgStyle;
            }
        },
        itemClasses() {
            let classes = '';
            if (this.len == 1) {
                classes += 'leftPosition';
            } else if (this.len == 2 && this.index == 0) {
                classes += 'leftPosition';
            } else if (this.len == 2 && this.index == 1) {
                classes = 'rightPosition';
            }
            return classes;
        },
        imgClasses() {
            let classes = '';
            if (this.index == 0 || (this.index + 1 == this.len && this.len != 2)) {
                classes = 'firstLastPro';
            } else if (this.index % 2 == 1) {
                classes = 'commonProRight';
            }
            return classes;
        },
        textAlignClass() {
            let textAlign = this.module.content.ta == 0 ? 'textAlign_center' : 'textAlign_left';
            return textAlign;
        },
        wrapClass() {
            return this.module.content.nameWrap ? 'paramWrap' : 'paramNowrap';
        },
        noNameWrap() {
            return 'paramWrap';
        },
        itemStyle() {
            var styles = {};
            if (this.index > 1) {
                styles.display = 'none';
            }
            return styles;
        },
        contentStyleWrap() {
            var proStyle = this.module.content.proStyle;
            if (proStyle == 11) {
                return 'transparentBg';
            } else if (proStyle == 10) {
                return 'backgroundBg';
            } else {
                return 'whiteBg';
            }
        },
    },
    methods: {
        waterFallMarginStyle(index) {
            if (!this.isNewAddModule) return;
            var waterFallMarginStyle = {};
            var proMarginX =
                this.module.content.proMargin.proMarginType == 0 ? 15 : this.module.content.proMargin.proMarginX;
            if (index % 2 == 0) {
                waterFallMarginStyle.paddingRight = proMarginX / 2 + 'px';
            } else {
                waterFallMarginStyle.paddingLeft = proMarginX / 2 + 'px';
            }
            return waterFallMarginStyle;
        },
    },
};
</script>
<style lang="scss">
.isNewAddModule .fk-mProductList8 {
    margin: 0.75rem 0.75rem 0;
}
.isNewAddModule .fk-mProductList8 .productWaterFall {
    border: none;
    width: 50%;
    background-color: transparent;
    display: inline-block;
}
.isNewAddModule .fk-mProductList8 .productWaterFall:nth-child(2n + 1) {
    margin-left: 0;
    left: 0;
}
.isNewAddModule .fk-mProductList8 .productWaterFall:nth-child(2n) {
    margin-right: 0;
    right: 0;
}
.form .isNewAddModule .fk-mProductList8 .g_productName {
    height: auto !important;
}
.form .isNewAddModule .fk-mProductList8 a {
    display: inline-block;
}
.form .isNewAddModule .fk-mProductList8 .transparentBg .paramCollection {
    background: transparent;
}
.form .isNewAddModule .fk-mProductList8 .whiteBg .paramCollection {
    background: #ffffff;
}
.form .isNewAddModule .fk-mProductList8 .backgroundBg .paramCollection {
    background: rgba(246, 246, 246, 1);
}
.form .isNewAddModule .fk-mProductList8 a {
    overflow: hidden;
}
</style>
