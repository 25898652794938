<template>
    <div :id="'productCatagoryPdPanle' + moduleId" class="proImgSwipe">
        <div class="proDetailImgBox">
            <div class="swipImgBox">
                <div vwidth="450" vheight="250" class="proDetailImg"></div>
                <div style="display: none"><img id="J_acct_pic" src="" /></div>
            </div>
        </div>
    </div>
</template>

<script>
import { initModulePhotoSwipe } from '@/modules/shared/photo';
export default {
    name: 'ImgSwipe',
    props: ['moduleId'],
    mounted: function () {
        initModulePhotoSwipe(`productCatagoryPdPanle${this.moduleId}`);
    },
};
</script>
