<template>
    <div>
        <template v-for="(info, index) in newsList">
            <horizon-slide-item :key="'n_' + info.id" :info="info" :index="index" :no-lazy-load="noLazyLoad" />
        </template>
    </div>
</template>

<script>
import HorizonSlideItem from './horizon-slide-item.vue';

export default {
    name: 'MaskList',
    components: { HorizonSlideItem },
    inject: ['module', 'options'],
    props: ['newsList', 'noLazyLoad'],
};
</script>

<style></style>
