<template>
    <div class="count_down_time_item">
        <div :style="textStyle">
            <div ref="numberText" :style="lastNumberStyle">{{ numberText }}</div>
            <div :style="lastUnitStyle">{{ unitText }}</div>
        </div>
        <div :style="signStyle" class="count_down_sign">:</div>
    </div>
</template>

<script>
export default {
    name: 'TimeItem',
    props: {
        isUnitDownstyle: {
            type: Boolean,
            default: true,
        },
        openNumberBg: {
            type: Boolean,
            default: false,
        },
        numberBgColor: {
            type: String,
            default: '#f2f2f2',
        },
        showSign: {
            type: Boolean,
            default: true,
        },
        numberStyle: {
            type: Object,
            default() {
                return {};
            },
        },
        unitStyle: {
            type: Object,
            default() {
                return {};
            },
        },
        unitText: {
            type: String,
            default: '',
        },
        numberText: {
            type: String,
            default: '',
        },
    },
    data() {
        return {};
    },
    computed: {
        textStyle() {
            const unitDownstyle = {
                display: 'flex',
                'flex-direction': 'column',
                'justify-content': 'center',
            };

            const unitSideStyle = {
                display: 'flex',
                'align-items': 'center',
            };

            return this.isUnitDownstyle ? unitDownstyle : unitSideStyle;
        },
        innerNumberStyle() {
            const unitDownstyle = {
                // "margin-bottom": "0.45rem",
                display: 'flex',
                'align-items': 'center',
                'justify-content': 'center',
            };

            const unitSideStyle = {
                display: 'flex',
                'align-items': 'center',
                'justify-content': 'center',
            };
            let style = this.isUnitDownstyle ? unitDownstyle : unitSideStyle;

            if (this.isUnitDownstyle && this.openNumberBg) {
                Object.assign(style, {
                    padding: '0.1rem 0.15rem',
                    'margin-bottom': '0.25rem',
                    'background-color': this.numberBgColor,
                    'border-radius': '0.1rem',
                });
            }

            if (!this.isUnitDownstyle && this.openNumberBg) {
                Object.assign(style, {
                    padding: '0.1rem 0.15rem',
                    'background-color': this.numberBgColor,
                    'border-radius': '0.1rem',
                });
            }

            return style;
        },
        innerUnitStyle() {
            const unitDownstyle = {
                display: 'flex',
                'align-items': 'center',
                'justify-content': 'center',
            };

            const unitSideStyle = {
                margin: '0 0.4rem',
                display: 'flex',
                'align-items': 'center',
                'justify-content': 'center',
            };

            let style = this.isUnitDownstyle ? unitDownstyle : unitSideStyle;

            if (!this.isUnitDownstyle && this.openNumberBg) {
                Object.assign(style, {
                    margin: '0 0.25rem',
                });
            }

            return style;
        },
        signStyle() {
            const unitDownstyle = {
                margin: '0 0.3rem',
            };

            const unitSideStyle = {
                display: 'none',
            };

            let style = this.isUnitDownstyle ? unitDownstyle : unitSideStyle;
            style = {
                ...this.numberStyle,
                ...style,
            };
            if (this.isUnitDownstyle && this.openNumberBg) {
                Object.assign(style, {
                    'padding-top': '0.1rem',
                });
            }

            if (!this.showSign) {
                Object.assign(style, {
                    display: 'none',
                });
            }

            if (Object.keys(this.lastNumberStyle).length) {
                const NumberfontSize = +this.lastNumberStyle['font-size'].replace('rem', '');
                style['font-size'] = `${NumberfontSize * 0.9}rem`;
            }

            style['line-height'] = `1.3`;

            return style;
        },
        lastNumberStyle() {
            const DEAULT_LINE_HEIGHT = 1.3;
            const defaultStyle = {
                'font-size': '1rem',
            };

            const lastStyle = {
                ...defaultStyle,
                ...this.numberStyle,
                ...this.innerNumberStyle,
            };

            // 保持文字正方形
            const fontSize = +lastStyle['font-size'].replace('rem', '');
            const height = DEAULT_LINE_HEIGHT * fontSize;
            lastStyle['line-height'] = `${DEAULT_LINE_HEIGHT}`;
            lastStyle.width = `${height}rem`;

            return { ...lastStyle };
        },
        lastUnitStyle() {
            const defaultStyle = {
                'font-size': '.5rem',
            };
            return {
                ...defaultStyle,
                ...this.unitStyle,
                ...this.innerUnitStyle,
            };
        },
    },
};
</script>

<style>
.count_down_time_item {
    display: flex;
    align-items: flex-start;
}

.count_down_time_item:last-child .count_down_sign {
    display: none;
}
</style>
