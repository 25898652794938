<template>
    <div class="m_form_item" :class="itemClass">
        <label v-if="!hidden" class="form_item_label" :class="{ form_item_require: isRequired }" :style="formItemStyle">
            {{ formItem.name }}
        </label>
        <div class="form_item_content" :style="formItemContentStyle">
            <slot></slot>
        </div>
    </div>
</template>

<script>
import { fontStyle, parseInputBdCss } from '../utils';

export default {
    name: 'FormItem',
    inject: ['module', 'options'],
    props: {
        formItem: { type: Object, default: () => ({}) },
    },
    computed: {
        itemClass() {
            return [`form_item_wrap_${this.formItem.type}`];
        },
        hidden() {
            return this.module.content.inh || typeof this.formItem.name === 'undefined' || this.formItem.type === 5;
        },
        formItemStyle() {
            return fontStyle(this.module.content.mhighs.fin);
        },
        formItemContentStyle() {
            if (this.module.content.ms == 3) {
                return parseInputBdCss(this.module.content.mhighs.ibd);
            }
            return '';
        },
        isRequired() {
            return this.formItem.must;
        },
    },
    mounted() {
        this.dispatch('MobiForm', 'on-form-item-add', this.$children[0]);
    },
    methods: {
        dispatch(componentName, eventName, params) {
            let parent = this.$parent || this.$root;
            let name = parent.$options.name;

            while (parent && (!name || name !== componentName)) {
                parent = parent.$parent;

                if (parent) {
                    name = parent.$options.name;
                }
            }
            if (parent) {
                parent.$emit.apply(parent, [eventName].concat(params));
            }
        },
    },
};
</script>

<style>
.m_form_item .form_item_require:after {
    content: '*';
    color: red;
    margin-left: 0.2rem;
    vertical-align: middle;
    line-height: 1;
}

.m_form_item .form_item_content {
    margin-top: 0.55rem;
}

.mobi_form_style_3 .m_form_item .form_item_content {
    margin-top: 0.075rem;
}

.m_form_item .form_item_message_content {
    margin-top: 0.55rem;
}

.mobi_form_style_3 .m_form_item .form_item_message_content {
    margin-top: 0.075rem;
}
</style>
