<template>
    <div class="head_pic_text_list">
        <head-pic-item v-if="!!headPicItemInfo" :info="headPicItemInfo" :no-lazy-load="noLazyLoad" />
        <div v-if="!isNewAddModule" class="g_separator separatorLine"></div>
        <text-list :news-list="newsList2"></text-list>
    </div>
</template>

<script>
import HeadPicItem from './head-pic-item.vue';
import TextList from '../textList/text-list.vue';
import { mapFlag } from '@/shared/flag';

export default {
    name: 'HeadPicTextList',
    components: { HeadPicItem, TextList },
    inject: ['module', 'options'],

    props: ['newsList', 'noLazyLoad'],
    computed: {
        ...mapFlag(
            {
                isNewAddModule: 0x4,
            },
            'module.flag'
        ),
        headPicItemInfo() {
            return !!this.newsList && this.newsList[0];
        },
        newsList2() {
            return this.newsList ? this.newsList.slice(1, this.newsList.length) : [];
        },
    },
};
</script>

<style></style>
