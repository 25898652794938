var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "big_pic_text_list" },
    [
      _vm._l(_vm.newsList, function (info, index) {
        return [
          _c("big-pic-text-list-item", {
            key: "n_" + info.id,
            attrs: { info: info, index: index, "no-lazy-load": _vm.noLazyLoad },
          }),
          _vm._v(" "),
          index === 0 && !_vm.isNewAddModule
            ? _c("div", {
                key: "s_" + info.id,
                staticClass: "g_separator separatorLine",
              })
            : _vm._e(),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }