<template>
    <div class="mobi_form_checkbox choice_box_wrap">
        <label
            v-for="name in checkboxGroup"
            :key="radioKey(name)"
            class="m_checkbox_wrap choice_box"
            :class="{
                m_checkbox_checked: currentValue.includes(name),
                form_item_vertical: module.content.od == 1,
                disabled: isZeroResidue(name),
            }"
        >
            <span class="m_checkbox">
                <span
                    class="m_form_checkbox_inner m_checkbox_inner icon-"
                    :class="{ active: currentValue.includes(name) }"
                ></span>
                <input
                    class="m_checkbox_input"
                    type="checkbox"
                    :checked="currentValue.includes(name)"
                    :value="name"
                    :disabled="isZeroResidue(name)"
                    @change="change"
                />
            </span>
            <span :style="fontStyle"> {{ name }}</span>
            <span v-if="getResidueText(name) != ''" :style="fontStyle" class="residue_text">{{
                getResidueText(name)
            }}</span>
        </label>
    </div>
</template>
<script>
import { fontStyle } from '../utils';
import { ing } from '@/shared/tips';
import { encodeHtml } from '@/shared/util';
import { residueMixins } from '../mixins/residue.js';
import formDetailEditMixin from '../mixins/formDetailEdit.js';

export default {
    name: 'FormCheckbox',
    mixins: [residueMixins(), formDetailEditMixin],
    inject: ['module', 'options'],
    props: {
        formItem: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            currentValue: [],
        };
    },
    computed: {
        checkboxGroup() {
            return (this.formItem.input || '').split('\n').filter((formItem) => formItem);
        },
        fontStyle() {
            return fontStyle(this.module.content.mhighs.oin);
        },
        submitVal() {
            return this.currentValue.join('\n');
        },
    },
    mounted() {
        this.initDefaultValue();
    },
    methods: {
        change(event) {
            let value = event.target.value;
            let valueIndex = this.currentValue.indexOf(value);
            if (valueIndex > -1) {
                this.currentValue.splice(valueIndex, 1);
            } else {
                this.currentValue.push(event.target.value);
            }
            this.$emit('selectChange', {
                value: this.currentValue,
                formItem: this.formItem,
            });
        },
        validate() {
            if (this.formItem.must && this.currentValue.length === 0) {
                ing(jm.format(LS.siteFormSubmitInputIsEmpty, encodeHtml(this.formItem.name)));
                return false;
            }

            return true;
        },
        radioKey(name) {
            return `${name}`;
        },
        resetValue() {
            this.initDefaultValue();
        },
        async initDefaultValue() {
            const initialValue = this.inMemberDetailEdit ? this.getEditingItemSubmitValue() : this.formItem.dtm;
            if (typeof initialValue === 'string' && initialValue !== '') {
                const values = initialValue.split('\n').filter((item) => {
                    return !this.isZeroResidue(item);
                });
                this.currentValue = values;
                await this.$nextTick(); // 保证前面的修改完成
                this.$emit('selectChange', {
                    value: this.currentValue,
                    formItem: this.formItem,
                });
            } else {
                this.currentValue = [];
            }
        },
    },
};
</script>

<style>
.m_form_checkbox_inner {
    background-color: #fff;
}

.m_form_checkbox_inner.active {
    background-color: #292929;
    color: #fff;
}

.mobi_form_checkbox .m_checkbox_wrap {
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    pointer-events: auto;
    margin-right: 1rem;
}

.mobi_form_checkbox .m_checkbox {
    display: inline-block;
    margin-right: 0.2rem;
    white-space: nowrap;
    outline: 0;
    position: relative;
    line-height: 1;
    vertical-align: -0.2em;
    cursor: pointer;
}

.mobi_form_checkbox .m_checkbox_inner {
    position: relative;
    display: inline-block;
    width: 0.8rem;
    height: 0.8rem;
    top: 0;
    left: 0;
    border: 1px solid rgba(216, 216, 216, 1);
    border-radius: 0.13rem;
    font-size: 0.375rem;
    box-sizing: border-box;
}

.mobi_form_checkbox .m_checkbox_checked .m_checkbox_inner:after {
    content: '\b0335';
    position: absolute;
    left: 50%;
    top: 50%;
    border-radius: 0;
    transform: translate(-50%, -50%);
    font-size: 0.6rem;
}

.mobi_form_checkbox .m_checkbox_input {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}
.choice_box_wrap .disabled {
    cursor: not-allowed;
    opacity: 0.6;
}
.choice_box_wrap .residue_text {
    color: #999;
}
</style>
