<template>
    <div class="item_type_checkbox">
        <ul class="checkbox_style">
            <li
                v-for="item in options"
                :key="item.id"
                class="checkbox_item"
                :class="{ checkbox_active: isChecked(item) }"
                @click="toggleItem(item)"
            >
                <span class="checkbox_inner faisco-icons-S000121"></span>
                {{ item }}
            </li>
        </ul>
    </div>
</template>
<script>
export default {
    name: 'CustomSearchCheckbox',
    props: {
        options: {
            type: Array,
            default: () => {},
        },
        value: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            currentVal: this.value,
        };
    },
    methods: {
        isChecked(item) {
            return this.currentVal.includes(item);
        },
        toggleItem(item) {
            let newVal = this.currentVal.slice();

            if (this.isChecked(item)) {
                newVal = newVal.filter((val) => val !== item);
            } else {
                newVal = this.options.filter((option) => {
                    return this.isChecked(option) || option === item;
                });
            }
            this.currentVal = newVal;
            this.$emit('input', newVal);
        },
    },
};
</script>
