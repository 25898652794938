import { MobiModule } from '../Module';
import { MODULE_STYLE } from '@/def/module';
import InsertCodeView from './module/insert-code.vue';

class _InsertCode extends MobiModule {
    constructor(options = {}) {
        super(MODULE_STYLE.INSERT_CODE, options);
    }

    render() {
        return InsertCodeView;
    }
}

export const InsertCode = new _InsertCode();
