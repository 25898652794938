var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "u_custom_search_entry--mail_wrap" }, [
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.formItem.value,
          expression: "formItem.value",
        },
      ],
      staticClass: "u_custom_search_entry--input u_custom_search_entry--text",
      attrs: { type: "text" },
      domProps: { value: _vm.formItem.value },
      on: {
        input: [
          function ($event) {
            if ($event.target.composing) return
            _vm.$set(_vm.formItem, "value", $event.target.value)
          },
          _vm.handleInput,
        ],
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }