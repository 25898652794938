var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "J_product_item",
      class: _vm.itemWrapClasses,
      style: _vm._proMarginStyle(_vm.index),
      attrs: {
        id: "productPicListForm" + _vm.info.id + "_module" + _vm.module.id,
        topClassName: _vm._topInfo.topName,
        topSwitch: _vm._topInfo.topSwitch,
        productId: _vm.info.id,
        productName: _vm.info.name,
        title: _vm._title2,
      },
    },
    [
      _c(
        "a",
        { attrs: { href: _vm.info.extInfo.href, onclick: _vm.onclickStr } },
        [
          _c(
            "div",
            {
              staticClass: "jz_productBigPicItem",
              class: [_vm._contentStyle, _vm._wrapBorderRadiusClass],
              style: _vm._warpProRadiusStyle,
            },
            [
              _c(
                "div",
                { staticClass: "J_productPho jz_productPho" },
                [
                  _vm.manageMode && _vm.info.top != 0 && !_vm._productSelect
                    ? _c("div", { staticClass: "f-productListTopFlag" }, [
                        _vm._v("置顶"),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.info.extInfo.salePromotionOpen
                    ? _c("sale-flag", { attrs: { info: _vm.info } })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", {
                    directives: [
                      {
                        name: "drag-resize",
                        rawName: "v-drag-resize",
                        value: _vm._resizeOptions,
                        expression: "_resizeOptions",
                      },
                    ],
                    staticClass: "J_img_lazyload style33Img J_style33Img",
                    class: [_vm._imgScaleClass, _vm._imgBorderRadiusClass],
                    style: _vm.proPhotoStyle,
                    attrs: {
                      alt: _vm.info.name,
                      "src-original": _vm.info.extInfo.imgPath,
                    },
                  }),
                  _vm._v(" "),
                  _vm._isShowQualityContentLabel
                    ? _c("quality-content-label", {
                        attrs: { text: _vm._knowPayLabelText },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _vm.showContent(_vm.info)
                ? _c(
                    "div",
                    {
                      staticClass: "jz_productScr",
                      class: _vm.contentBgClass,
                      style: _vm._contentBgStyle,
                    },
                    [
                      _vm.showProName
                        ? _c("div", { class: _vm.proNameClasses }, [
                            _c(
                              "p",
                              {
                                staticClass:
                                  "g_link paramName g_productName g_newProductName",
                                class: _vm._proNameWrapClass,
                                style: _vm.productName_style,
                              },
                              [
                                _vm.module.content.showProName
                                  ? [
                                      _vm.info.isTimedAdded
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "g_oldThemeBgColor m_timedAddTag g_main_bgColor_v3",
                                            },
                                            [_vm._v("即将开售")]
                                          )
                                        : _vm._e(),
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(_vm.info.name) +
                                          "\n                        "
                                      ),
                                    ]
                                  : _vm._e(),
                                _vm._v(" "),
                                !_vm.isNewAddModule &&
                                _vm._showMallCart.mCart &&
                                !_vm._extInfo.hasMallPrice &&
                                _vm.module.content.proStyle != 3
                                  ? _c("mall-cart", {
                                      attrs: { info: _vm.info },
                                    })
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("prop-panel", {
                        attrs: {
                          info: _vm.info,
                          list: _vm._showPropList,
                          "ext-info": _vm._extInfo,
                          "show-mall-cart": _vm._showMallCart,
                          "text-align-class": _vm.textAlignClass,
                          "no-name-wrap": _vm.noNameWrap,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }