<template>
    <div :id="'formTabContent' + id" class="J_formTabContent f-formTabContent">
        <component :is="componentsTable[childStyle]" :module-id="childId" :children="children" />
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { inTabMaqueeHelper } from '../../util';

export default {
    name: 'TabChildModule',
    inject: ['componentsTable'],
    props: {
        id: [String, Number],
        itemData: Object,
        labelData: Object,
        manageMode: Boolean,
    },
    data() {
        return {};
    },
    computed: {
        ...mapState(['columnModuleHiddenList']),
        ...mapGetters(['getModuleById']),
        childId() {
            return this.labelData.id;
        },
        child() {
            return this.getModuleById(this.childId);
        },
        childStyle() {
            return this.labelData.style;
        },
        children() {
            if (this.child && Array.isArray(this.child.children)) {
                return this.child.children.filter((child) => this.columnModuleHiddenList.includes(child.id) === false);
            } else {
                return [];
            }
        },
    },
    mounted() {
        this.initModule();
    },
    methods: {
        initModule: function () {
            if (this.itemData.load && this.itemData) {
                if (VITE_APP_MODE !== 'visitor') {
                    if (this.id > 0) {
                        if (this.getModuleById(this.id)) {
                            jm('#module' + this.id).removeClass('fk-unConnectModule');
                        } else {
                            if (this.getModuleById(this.id)) {
                                this.getModuleById(this.id).name = this.labelData.labelName;
                            }
                            inTabMaqueeHelper.runInTabMarquee(this.id);
                        }
                    }
                } else {
                    inTabMaqueeHelper.runInTabMarquee(this.id);
                }
            }
        },
    },
};
</script>
