<template>
    <div
        :id="itemId"
        :topClassName="_topInfo.topName"
        :topSwitch="_topInfo.topSwitch"
        :productId="info.id"
        :productName="info.name"
        class="productCrossedSlide J_product_item"
        :title="_title2"
        :style="_warpProRadiusStyle"
        :class="[_contentStyle, _wrapBorderRadiusClass]"
    >
        <a :href="info.extInfo.href" :onclick="onclickStr">
            <div class="fk-productListImgWrap">
                <div v-if="manageMode && info.top != 0 && !_productSelect" class="f-productListTopFlag">置顶</div>
                <sale-flag v-if="info.extInfo.salePromotionOpen" :info="info"></sale-flag>
                <div :id="'crossedSlideImg' + info.id + 'module' + module.id" class="crossedSlideImg">
                    <div
                        v-drag-resize="_resizeOptions"
                        :alt="info.name"
                        class="J_img_lazyload style7Img"
                        :src-original="_imgSrc"
                        :style="proPicStyle"
                        :class="[_imgScaleClass, _imgBorderRadiusClass]"
                    ></div>
                </div>
                <quality-content-label
                    v-if="_isShowQualityContentLabel"
                    :text="_knowPayLabelText"
                ></quality-content-label>
            </div>
            <product-name
                v-if="module.content.showProName && module.content.picSetting.picSizeSet == 1"
                :info="info"
            ></product-name>
            <mall-cart
                v-if="!isNewAddModule && _showMallCart.mallCart2 && module.content.picSetting.picSizeSet == 1"
                :info="info"
                :holder="_showMallCartHolder"
            ></mall-cart>
            <prop-panel
                :info="info"
                :list="_showPropList"
                :ext-info="_extInfo"
                :show-mall-cart="_showMallCart"
                :text-align-class="textAlignClass"
                :no-name-wrap="noNameWrap"
            >
            </prop-panel>
        </a>
    </div>
</template>

<script>
import productItemMixin from '../../mixins/productItemMixin';
import saleFlag from '../comm/sale-flag.vue';
import propPanel from '../comm/prop-panel.vue';
import mallCart from '../comm/mall-cart.vue';
import qualityContentLabel from '../comm/quality-content-label.vue';

const manageMode = VITE_APP_MODE !== 'visitor';

export default {
    name: 'TwoColumnItem',
    components: { saleFlag, mallCart, propPanel, qualityContentLabel },
    mixins: [productItemMixin],
    inject: ['module', 'options'],
    props: ['info', 'index'],
    data() {
        return {
            itemId: `productCrossedSlide${this.info.id}_module${this.module.id}`,
            imgWrapId: `imgClass${this.info.id}_module${this.module.id}`,
            manageMode,
        };
    },
    computed: {
        //已抢光标志图片
        soldOutImg() {
            return `${this.options.resRoot}/image/soldOut${this.module.extInfo.isCN ? '' : '_en'}.png`;
        },
        proPicStyle() {
            if (this._isTransparentStyle) {
                return { ...this._imgBgStyle, ...this._proRadiusStyle };
            } else {
                return { ...this._imgBgStyle, ...this._fixProRadiusStyle };
            }
        },
        textAlignClass() {
            let textAlign = this.module.content.ta == 0 ? 'textAlign_center' : 'textAlign_left';
            return textAlign;
        },
        wrapClass() {
            return this.module.content.nameWrap ? 'paramWrap' : 'paramNowrap';
        },
        noNameWrap() {
            return 'paramWrap';
        },
    },
};
</script>
<style lang="scss">
.isNewAddModule .fk-mProductList7 .productCrossedSlide:nth-child(2n) {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}
.isNewAddModule .fk-mProductList7 .productCrossedSlide:nth-child(2n + 1) {
    margin-left: 0.5rem;
}

.isNewAddModule .fk-mProductList7 .fk-productListImgWrap,
.isNewAddModule .fk-mProductList7 .crossedSlideImg,
.isNewAddModule .fk-mProductList7 .style7Img {
    width: 100%;
}
.isNewAddModule .fk-mProductList7 .transparentStyle .style7Img,
.isNewAddModule .fk-mProductList8 .transparentStyle .style7Img,
.isNewAddModule .fk-mProductList9 .transparentStyle .style7Img {
    border-bottom-left-radius: 0.2rem;
    border-bottom-right-radius: 0.2rem;
}
.isNewAddModule .fk-mProductList7 .productSwipeDiv {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
}
.isNewAddModule .fk-mProductList7 .productCrossedSlide {
    border: none;
    display: flex;
}
.isNewAddModule .mProductList.fk-mProductList7 .defaultProName {
    white-space: unset;
    overflow: hidden;
}
.form .isNewAddModule .mProductList.fk-mProductList7 .g_productName,
.form .isNewAddModule .mProductList.fk-mProductList8 .g_productName,
.form .isNewAddModule .mProductList.fk-mProductList9 .g_productName {
    font-weight: normal;
}
</style>
