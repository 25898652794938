var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "drag-resize",
          rawName: "v-drag-resize",
          value: _vm._resizeOptions,
          expression: "_resizeOptions",
        },
      ],
      staticClass: "photoHoverContainer",
      style: _vm.photoHoverContainerStyle,
      attrs: { id: _vm.photoItem.picId, "data-picid": _vm.photoItem.picId },
    },
    [
      _c(
        "a",
        {
          staticClass: "photoHoverContent",
          attrs: {
            href: _vm.imgHref,
            rel: _vm.photoItem.nf ? "nofollow" : "",
            onclick: _vm.jumpOnclickStr,
          },
          on: { click: _vm.photoSlidesshow },
        },
        [
          _c("div", {
            staticClass: "photoHoverImg J_img_lazyload",
            style: _vm.photoItemImgStyle,
            attrs: { "src-original": _vm.photoItem.picThumbPath },
          }),
          _vm._v(" "),
          _c("div", {
            staticClass: "photoHover",
            style: _vm.photoItemHoverStyle,
          }),
          _vm._v(" "),
          _c("div", { staticClass: "photoHoverText" }, [
            _c("div", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showTitle,
                  expression: "showTitle",
                },
              ],
              staticClass: "hoverTitle",
              style: _vm.titleWrapStyle,
              domProps: { innerHTML: _vm._s(_vm.titleData) },
            }),
            _vm._v(" "),
            _c("div", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showDesc,
                  expression: "showDesc",
                },
              ],
              staticClass: "hoverDesc",
              style: _vm.descWrapStyle,
              domProps: { innerHTML: _vm._s(_vm.descData) },
            }),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }