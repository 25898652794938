import { MobiModule } from '../Module';
import { MODULE_STYLE } from '@/def/module';
import WxAddFansView from './module/wxAddFans.vue';

class _WxAddFans extends MobiModule {
    constructor(options = {}) {
        super(MODULE_STYLE.WX_ADD_FANS, options);
    }

    render() {
        return WxAddFansView;
    }
}

export const WxAddFans = new _WxAddFans();
